/* eslint-disable @typescript-eslint/ban-types */
import type { UrlBrowsingMultiple } from 'core/types';
import { ReduxAction } from 'global';

import type { ViewFrequencyInputValues } from '@components/Targeting/FrequencySettings/Target/ViewFrequency/ViewFrequency';
import type { OperatingSystemInputValues } from '@components/Targeting/Audience/Target/OperatingSystem/OperatingSystem';
import type { GeoLocatedInputValues } from '@components/Targeting/Audience/Target/GeoLocated/GeoLocated';
import type { TrafficSourceInputValues } from '@components/Targeting/Audience/Target/TrafficSource/TrafficSource';
import type { HtmlTargetingInputValues } from '@components/Targeting/Audience/Target/HtmlTargeting/HtmlTargeting';
import type { CookieTargetingInputValues } from '@components/Targeting/Audience/Target/CookieTargeting/CookieTargeting';
import type { SiteDataTargetingInputValues } from '@components/Targeting/Audience/Target/SiteDataTargeting/SiteDataTargeting';
import type { AfterXSecondsInputValues } from '@components/Targeting/UserBehavior/Target/AfterXSeconds/AfterXSeconds';
import type { AfterScrollingInputValues } from '@components/Targeting/UserBehavior/Target/AfterScrolling/AfterScrolling';
import type { OnExitIntentInputValues } from '@components/Targeting/UserBehavior/Target/OnExitIntent/OnExitIntent';
import type { InActivityModeInputValues } from '@components/Targeting/UserBehavior/Target/InActivityMode/InActivityMode';
import type { VisitorDeviceInputValues } from '@components/Targeting/Audience/Target/VisitorDevice/VisitorDevice';
import type { OnclickInputValues } from '@components/Targeting/UserBehavior/Target/OnClick/OnClick';
import type { DateValue } from '@elements/DateRangePicker/DateRangePicker';

import {
  UPDATE_TARGETING,
  UPDATE_TARGETING_SUCCESS,
  DELETE_TARGETING,
  DELETE_TARGETING_SUCCESS,
  SET_TARGETING,
  SET_TARGETING_SUCCESS,
  DELETE_ERROR,
  UPDATE_ERROR,
  DELETE_ERROR_SUCCESS,
  UPDATE_ERROR_SUCCESS,
  TARGETING_FAILURE,
} from './constants';
import { RESET_STORE } from '@connectors/actions';
import { ShopifyTargetingValues } from '@components/Targeting/Audience/Target/ShopifyTargeting/types';

export type TargetsKey =
  | 'displayFrequency'
  | 'schedule'
  | 'operatingSystem'
  | 'newOrReturningVisitors'
  | 'browserLanguage'
  | 'geoLocated'
  | 'trafficSource'
  | 'htmlTargeting'
  | 'cookieTargeting'
  | 'siteDataTargeting'
  | 'afterXSeconds'
  | 'afterScrollingXAmount'
  | 'onExitIntent'
  | 'urlBrowsing'
  | 'inActivityMode'
  | 'onClick'
  | 'AdBlockDetection'
  | 'visitorDevice'
  | 'targetSmartMode';

export type IsValid = {
  [key in TargetsKey]?: boolean;
};

export interface Targets {
  displayFrequency?: ViewFrequencyInputValues;
  schedule?: DateValue[];
  operatingSystem?: OperatingSystemInputValues;
  newOrReturningVisitors?: string;
  browserLanguage?: Array<string>;
  geoLocated?: GeoLocatedInputValues;
  trafficSource?: TrafficSourceInputValues[];
  htmlTargeting?: HtmlTargetingInputValues[];
  cookieTargeting?: CookieTargetingInputValues[];
  siteDataTargeting?: SiteDataTargetingInputValues[];
  afterXSeconds?: AfterXSecondsInputValues;
  afterScrollingXAmount?: AfterScrollingInputValues;
  onExitIntent?: OnExitIntentInputValues;
  urlBrowsing?: UrlBrowsingMultiple;
  inActivityMode?: InActivityModeInputValues;
  onClick?: string;
  visitorDevice?: VisitorDeviceInputValues;
  onclickTargeting?: OnclickInputValues;
  targetSmartMode?: boolean;
  shopifyTargeting?: ShopifyTargetingValues;
}

export interface TargetsState {
  targets?: Targets;
  isValid: IsValid;
  status: 'success' | 'errored' | 'loading' | 'initialized';
}

const initialState: TargetsState = {
  targets: {},
  isValid: {},
  status: 'initialized',
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case SET_TARGETING:
      return { ...state, status: 'loading' };

    case SET_TARGETING_SUCCESS:
      return {
        ...state,
        targets: {
          ...action.payload,
        },
        status: 'success',
      };

    case UPDATE_TARGETING:
      return { ...state, status: 'loading' };

    case UPDATE_TARGETING_SUCCESS:
      return {
        ...state,
        targets: {
          ...state.targets,
          ...action.payload,
        },
        status: 'success',
      };

    case DELETE_TARGETING:
      return { ...state, status: 'loading' };

    case DELETE_TARGETING_SUCCESS:
      return {
        ...state,
        targets: {
          ...action.payload,
        },
        status: 'success',
      };

    case UPDATE_ERROR:
      return { ...state, status: 'loading' };

    case UPDATE_ERROR_SUCCESS:
      return {
        ...state,
        isValid: {
          ...state.isValid,
          ...action.payload,
        },
        status: 'success',
      };

    case DELETE_ERROR:
      return { ...state, status: 'loading' };

    case DELETE_ERROR_SUCCESS:
      return {
        ...state,
        isValid: {
          ...action.payload,
        },
        status: 'success',
      };

    case TARGETING_FAILURE:
      return { ...state, status: 'errored' };

    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
