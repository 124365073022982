import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';
import { ReactElement } from 'react';
import { createSelector } from 'reselect';

import { CLEAR_ALL_DRAWER, HIDE_DRAWER, SHOW_DRAWER } from './constants';

export type Drawer = {
  id: string;
  data?: { [key: string]: string | number | boolean | object | null };
  header?: ReactElement | string;
  footer?: ReactElement | string;
};

type DrawerState = {
  drawers: Drawer[];
};

const initialState: DrawerState = {
  drawers: [],
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case SHOW_DRAWER:
      return {
        ...state,
        drawers: [
          ...state.drawers,
          {
            id: action.payload.id,
            data: action.payload.data,
            header: action.payload.header,
            footer: action.payload.footer,
          },
        ],
      };
    case HIDE_DRAWER: {
      let drawers = state.drawers;
      drawers = drawers.filter(
        (drawer: Drawer) => drawer.id !== action.payload,
      );
      return { ...state, drawers };
    }
    case CLEAR_ALL_DRAWER:
      return { ...initialState };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}

const getDrawersSelector = (state: any) => state.drawer.drawers;
export const selectDrawer = createSelector(
  getDrawersSelector,
  (state: any, id: string) => id,
  (drawers, id) => drawers.find((drawer: Drawer) => drawer.id === id),
);
