import React from 'react';
import { useSelector } from 'react-redux';
import WarningCircleIcon from '@assets/icons/warning-circle.svg';
import styles from './CampaignStatusWarningModal.module.scss';
import PSButton from '@elements/PSButton';
import { makeSelectModalById } from '@connectors/modal/selectors';
import { Tooltip } from 'ui';
import Favicon from '@components/Favicon';
import { Domain, EmailAutomation } from '@generated/graphql';

interface CampaignInfoProps {
  header: string;
  name: string;
  subname?: string;
  domains: Domain[];
}

const DomainList = ({ domains }: { domains: Domain[] }) => (
  <div className={styles.domainWrapper}>
    {domains.slice(0, 5).map(({ url }) => (
      <Tooltip
        key={url}
        className={styles.domainIcon}
        contentClassName="!-translate-x-[50%] -translate-y-full"
        content={url}
      >
        <a href={`//${url}`} target="_blank" rel="noreferrer">
          <Favicon url={url} className="text-[#777777]" isSmall={true} />
        </a>
      </Tooltip>
    ))}
  </div>
);

const CampaignInfoDisplay: React.FC<CampaignInfoProps> = ({
  header,
  name,
  subname,
  domains,
}) => (
  <div className="mt-[20px] w-full">
    <div className={styles.campaignInfo}>
      <div className={styles.header}>{header}</div>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <div className={styles.campaignContainer}>
            <div className={styles.campaignName}>{name}</div>
            {subname && <div className={styles.campaignSubname}>{subname}</div>}
            <DomainList domains={domains} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const CampaignStatusWarningModal = () => {
  const modal = useSelector(makeSelectModalById('CampaignStatusWarningModal'));

  const domains = modal?.data?.domains as Domain[];
  const isPublishing = modal?.data?.isPublishing as boolean;
  const automation = modal?.data?.emailAutomation as EmailAutomation;
  const header = isPublishing
    ? 'Before publishing campaign'
    : 'Unpublish campaign';
  const text = isPublishing
    ? 'When you publish your popup campaign, it will be published in the email automation as well. Do you confirm?'
    : 'If you unpublish the popup campaign, it will also be unpublished in your email automation. Do you confirm?';

  return (
    <div className="bg-white rounded-lg p-[40px]">
      <div className="flex flex-col items-center">
        {!isPublishing && <WarningCircleIcon />}
        <h3 className="mt-[15px] font-bold text-lg text-black">{header}</h3>
        <p className="mt-[10px] text-xs text-black text-center">{text}</p>
        <CampaignInfoDisplay
          header="Your popup campaign"
          name={automation?.Campaign?.Name || ''}
          domains={domains}
        />

        <CampaignInfoDisplay
          header="Email Automation"
          name={automation?.name}
          subname={automation?.Flow?.name}
          domains={domains}
        />
        <div className="flex items-center justify-center mt-[20px] px-2">
          <PSButton
            size="xs"
            textClassName="text-xs text-[#777]"
            variant="second"
            onClick={modal?.data?.onCancel as any}
          >
            Cancel
          </PSButton>
          <PSButton
            className="ml-2"
            textClassName="text-xs"
            size="xs"
            variant="primary"
            onClick={modal?.data?.onPublishChanges as any}
          >
            {isPublishing ? 'Publish' : 'Yes, unpublish'}
          </PSButton>
        </div>
      </div>
    </div>
  );
};

export default CampaignStatusWarningModal;
