import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  EMAILOCTOPUS_GET_FIELDS,
  EMAILOCTOPUS_GET_FIELDS_SUCCESS,
  EMAILOCTOPUS_GET_FIELDS_FAILURE,
  EMAILOCTOPUS_GET_CONNECTIONS,
  EMAILOCTOPUS_GET_CONNECTIONS_SUCCESS,
  EMAILOCTOPUS_GET_CONNECTIONS_FAILURE,
  EMAILOCTOPUS_GET_LIST,
  EMAILOCTOPUS_GET_LIST_SUCCESS,
  EMAILOCTOPUS_GET_LIST_FAILURE,
  EMAILOCTOPUS_SAVE,
  EMAILOCTOPUS_SAVE_SUCCESS,
  EMAILOCTOPUS_SAVE_FAILURE,
  EMAILOCTOPUS_DELETE,
  EMAILOCTOPUS_DELETE_FAILURE,
  EMAILOCTOPUS_CLEAR_STATE,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import graphqlFetch from '@utils/graphqlFetch';
import {
  CreateEmailOctopus,
  DeleteEmailOctopusIntegrationCampaign,
  GetEmailOctopusFields,
  GetEmailOctopusIntegrationsByAccountId,
  GetEmailOctopusIntegrationsByCampaignId,
  GetEmailOctopusLists,
  UpdateEmailOctopus,
} from './graphql';
import { EmailOctopusIntegrationCampaignResponse } from '@generated/graphql';

function* handleGetConnections() {
  try {
    const result: {
      data: { getEmailOctopusIntegrationsByAccountId: ApiConnectionObject };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetEmailOctopusIntegrationsByAccountId(),
    });

    yield put({
      type: EMAILOCTOPUS_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getEmailOctopusIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: EMAILOCTOPUS_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, listId, onSuccess } = action.payload;
  try {
    const result: {
      data: { getEmailOctopusFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetEmailOctopusFields(apiKey, listId),
    });
    onSuccess && onSuccess();
    yield put({
      type: EMAILOCTOPUS_GET_FIELDS_SUCCESS,
      payload: result.data.getEmailOctopusFields,
    });
  } catch (error) {
    yield put({ type: EMAILOCTOPUS_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;

  try {
    const result: {
      data: { getEmailOctopusLists: ApiListObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetEmailOctopusLists(apiKey),
    });

    onSuccess && onSuccess();
    yield put({
      type: EMAILOCTOPUS_GET_LIST_SUCCESS,
      payload: result.data.getEmailOctopusLists,
    });
  } catch (error) {
    yield put({ type: EMAILOCTOPUS_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      // eslint-disable-next-line @typescript-eslint/ban-types
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateEmailOctopus(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateEmailOctopus({ ...formData, status: true }),
      });
    }

    const integrations: {
      data: {
        getEmailOctopusIntegrationsByCampaignId: EmailOctopusIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetEmailOctopusIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getEmailOctopusIntegrationsByCampaignId,
        type: IntegrationEnums.emailOctopus,
      }),
    );

    yield put({ type: EMAILOCTOPUS_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: EMAILOCTOPUS_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteEmailOctopusIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.emailOctopus,
      }),
    );
    yield put({ type: EMAILOCTOPUS_CLEAR_STATE });
  } catch (error) {
    yield put({ type: EMAILOCTOPUS_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(EMAILOCTOPUS_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(EMAILOCTOPUS_GET_FIELDS, handleGetFields);
  yield takeLatest(EMAILOCTOPUS_GET_LIST, handleGetList);
  yield takeLatest(EMAILOCTOPUS_SAVE, handleSave);
  yield takeLatest(EMAILOCTOPUS_DELETE, handleDelete);
}
