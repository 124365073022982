import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import graphqlFetch from '@utils/graphqlFetch';

import {
  GOOGLE_FONTS_GET,
  ADD_FONT_TO_SELECTBOX,
  ADD_FONT_TO_SELECTBOX_SUCCESS,
  FONTS_MODAL_SHOW,
  FONTS_MODAL_SHOW_SUCCESS,
  FONTS_MODAL_HIDE,
  FONTS_MODAL_HIDE_SUCCESS,
  FONTS_FAILURE,
} from './constants';

interface Font {
  family: string;
  variants: string[];
  subsets: string[];
  version: string;
  lastModified: string;
  category: string;
}

interface GoogleFontsResponse {
  data: {
    googleFonts: {
      fonts: Font[];
    };
  };
}

export const GetGoogleFontsQuery = () => ({
  operationName: 'googleFonts',
  query: `query googleFonts {
    googleFonts {
      fonts {
        family
        variants
        subsets
        version
        lastModified
        category
      }
    }
  }`,
});
function* handleGetFonts() {
  const queryPayload = GetGoogleFontsQuery();

  try {
    const result: GoogleFontsResponse = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: queryPayload,
    });

    yield put({
      type: 'GOOGLE_FONTS_GET_SUCCESS',
      payload: result.data.googleFonts.fonts,
    });
  } catch (error) {
    yield put({
      type: 'FONTS_FAILURE',
      payload: 'Unknown error',
    });
  }
}

function* handleShowFontModal() {
  try {
    yield put({ type: FONTS_MODAL_SHOW_SUCCESS });
  } catch (error) {
    yield put({ type: FONTS_FAILURE });
  }
}

function* handleHideFontModal() {
  try {
    yield put({ type: FONTS_MODAL_HIDE_SUCCESS });
  } catch (error) {
    yield put({ type: FONTS_FAILURE });
  }
}

function* handleAddFontToSelectbox(payload: ReduxAction) {
  const { item } = payload;
  try {
    yield put({ type: ADD_FONT_TO_SELECTBOX_SUCCESS, payload: item });
  } catch (error) {
    yield put({ type: FONTS_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(GOOGLE_FONTS_GET, handleGetFonts);
  yield takeLatest(FONTS_MODAL_SHOW, handleShowFontModal);
  yield takeLatest(FONTS_MODAL_HIDE, handleHideFontModal);
  yield takeLatest(ADD_FONT_TO_SELECTBOX, handleAddFontToSelectbox);
}
