import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import CodeIcon from '@assets/icons/code.svg';

export const renderHtmlTargeting = (
  htmlTargeting: Targets['htmlTargeting'],
  translate: Function,
) => {
  const getQuery = (query: string) => {
    switch (query) {
      case 'MATCHES':
        return translate('common:matches');
      case 'DOES_NOT_MATCH':
        return translate('common:doesNotMatch');
      case 'CONTAINS':
        return translate('common:contains');
      case 'LESS_THAN':
        return translate('common:lessThan');
      case 'LESS_THAN_EQUAL':
        return translate('common:lessThanEqual');
      case 'GREATER_THAN':
        return translate('common:greaterThan');
      case 'GREATER_THAN_EQUAL':
        return translate('common:greaterThanEqual');
      default:
        return query;
    }
  };
  if (htmlTargeting && !!htmlTargeting.length) {
    return (
      <>
        <TimelineContent
          icon={<CodeIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate('sections:targeting-summary-html-targeting:message')}
        >
          {htmlTargeting.map((source, index) => (
            <span
              className="flex items-start font-inter text-xs"
              key={source.value}
            >
              {index > 0 && (
                <span className="font-semibold text-bluesmart mr-1"> OR</span>
              )}
              {translate('sections:targeting-summary-html-targeting:value', {
                element: source.element,
                query: getQuery(source.query),
                value: source.value,
              })}
            </span>
          ))}
        </TimelineContent>
      </>
    );
  }
};
