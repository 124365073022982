import { ThemeData } from 'core';
import type {
  Template,
  TemplateFilter,
  CampaignGoal,
  TemplateTag,
  UpdateTemplatesSelectedDeviceTypeAction,
  UpdateTemplatesSelectedPageIndexAction,
  SetSelectedTemplateThemeAction,
} from './types';

import {
  GET_CAMPAIGNGOALS_FAILURE,
  GET_CAMPAIGNGOALS_REQUEST,
  GET_CAMPAIGNGOALS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TEMPLATES_FAILURE,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  RESET_SELECTED,
  RESET_TEMPLATES_SELECTED_PAGE_AND_DEVICE_TYPE,
  SET_SELECTED_TEMPLATE_THEME,
  SET_SELECTED_TEMPLATES,
  UPDATE_TEMPLATES_SELECTED_DEVICE_TYPE,
  UPDATE_TEMPLATES_SELECTED_PAGE_ID,
} from './constants';
import DeviceTypes from '@enums/DeviceTypes';

export function getTemplates(payload?: TemplateFilter) {
  return {
    type: GET_TEMPLATES_REQUEST,
    payload,
  };
}

export function getTemplatesSuccess({ data }: { data: Template[] }) {
  return {
    type: GET_TEMPLATES_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getTemplatesError() {
  return {
    type: GET_TEMPLATES_FAILURE,
  };
}
export function updateTemplateSelectedDeviceType(
  deviceType: DeviceTypes,
): UpdateTemplatesSelectedDeviceTypeAction {
  return {
    type: UPDATE_TEMPLATES_SELECTED_DEVICE_TYPE,
    payload: {
      deviceType,
    },
  };
}

export function updateTemplateSelectedPageId(
  pageId: string,
): UpdateTemplatesSelectedPageIndexAction {
  return {
    type: UPDATE_TEMPLATES_SELECTED_PAGE_ID,
    payload: {
      pageId,
    },
  };
}

export function resetTemplatesPageAndDeviceType() {
  return {
    type: RESET_TEMPLATES_SELECTED_PAGE_AND_DEVICE_TYPE,
  };
}

export function resetSelectedTemplate() {
  return {
    type: RESET_SELECTED,
  };
}

export function getCampaignGoals() {
  return {
    type: GET_CAMPAIGNGOALS_REQUEST,
  };
}

export function getCampaignGoalsSuccess({ data }: { data: CampaignGoal[] }) {
  return {
    type: GET_CAMPAIGNGOALS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getCampaignGoalsError() {
  return {
    type: GET_CAMPAIGNGOALS_FAILURE,
  };
}

export function getTags() {
  return {
    type: GET_TAGS_REQUEST,
  };
}

export function getTagsSuccess({ data }: { data: TemplateTag[] }) {
  return {
    type: GET_TAGS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getTagsError() {
  return {
    type: GET_TAGS_FAILURE,
  };
}

export function setSelectedTemplateTheme(
  code: string,
  theme: ThemeData,
): SetSelectedTemplateThemeAction {
  return {
    type: SET_SELECTED_TEMPLATE_THEME,
    payload: { code, theme },
  };
}

export function setSelectedTemplate(template: Template) {
  return {
    type: SET_SELECTED_TEMPLATES,
    payload: {
      template,
    },
  };
}
