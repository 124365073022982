export const webhookServiceURl = 'api/integrations/webhook';

export const WEBHOOK_GET_CONNECTIONS =
  'builder/integrations/WEBHOOK_GET_CONNECTIONS';
export const WEBHOOK_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/WEBHOOK_GET_CONNECTIONS_SUCCESS';
export const WEBHOOK_GET_CONNECTIONS_FAILURE =
  'builder/integrations/WEBHOOK_GET_CONNECTIONS_FAILURE';

export const WEBHOOK_SAVE = 'builder/integrations/WEBHOOK_SAVE';
export const WEBHOOK_SAVE_SUCCESS = 'builder/integrations/WEBHOOK_SAVE_SUCCESS';
export const WEBHOOK_SAVE_FAILURE = 'builder/integrations/WEBHOOK_SAVE_FAILURE';

export const WEBHOOK_DELETE = 'builder/integrations/WEBHOOK_DELETE';
export const WEBHOOK_DELETE_SUCCESS =
  'builder/integrations/WEBHOOK_DELETE_SUCCESS';
export const WEBHOOK_DELETE_FAILURE =
  'builder/integrations/WEBHOOK_DELETE_FAILURE';

export const WEBHOOK_CLEAR_STATE = 'builder/integrations/WEBHOOK_CLEAR_STATE';
