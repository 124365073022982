import {
  ADD_CAMPAIGN_CONTENT_ELEMENT,
  ADD_CAMPAIGN_PAGE,
  CHANGE_CAMPAIGN_TEMPLATE,
  CHANGE_CAMPAIGN_TEMPLATE_ERROR,
  CHANGE_CAMPAIGN_TEMPLATE_SUCCESS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_ERROR,
  GET_CAMPAIGN_SUCCESS,
  GET_NEW_PAGE_TEMPLATE,
  GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE,
  GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_ERROR,
  GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_SUCCESS,
  INCREASE_PUBLISH_VERSION,
  REMOVE_CAMPAIGN_CONTENT_ELEMENT,
  REMOVE_CAMPAIGN_PAGE,
  REORDER_PAGE_STEP,
  RESET,
  REVERSE_CAMPAIGN_CHILD_ELEMENTS,
  SAVE_CAMPAIGN,
  SAVE_CAMPAIGN_ERROR,
  SAVE_CAMPAIGN_SUCCESS,
  TOGGLE_CANVAS_DRAG_DROP_STATUS,
  UPDATE_CAMPAIGN_ALL_PAGE_CANVAS_ELEMENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_CONTENT_ELEMENT,
  UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE,
  UPDATE_CAMPAIGN_CURRENT_PAGE_ID,
  UPDATE_CAMPAIGN_DOMAIN_VERIFIED_STATUS,
  UPDATE_CAMPAIGN_PAGE_ATTRIBUTE,
  UPDATE_CAMPAIGN_PAGE_COLOR,
  UPDATE_CAMPAIGN_PAGE_FONT_FAMILY,
  UPDATE_CAMPAIGN_PREVIEW,
  UPDATE_CAMPAIGN_TEASER_CANVAS_ELEMENT_ATTRIBUTE,
  UPDATE_HIGHLIGHT_MODE,
  UPDATE_INLINE_EDITOR,
  UPDATE_VISITOR_DEVICE,
  UPDATE_PUBLISH_LOADING_STATUS,
  SET_EMAIL_AUTOMATION_EDIT,
  SET_TOTAL_CAMPAIGN_COUNT,
} from './constants';
import {
  AddCampaignContentElementAction,
  AddCampaignPageAction,
  GetCampaignAction,
  GetCampaignErrorAction,
  GetCampaignSuccessAction,
  HighlighMode,
  PageStep,
  RemoveCampaignContentElementAction,
  RemoveCampaignPageAction,
  ReorderPageStepAction,
  ReverseCampaignChildElementsAction,
  UpdateCampaignContentAttributeAction,
  UpdateCampaignContentElementAction,
  UpdateCampaignContentElementAttributeAction,
  UpdateCampaignCurrentDeviceTypeAction,
  UpdateCampaignCurrentPageIdAction,
  UpdateCampaignPageAttributeAction,
  UpdateCampaignPageColorAction,
  UpdateCampaignPageFontFamilyAction,
  UpdateCampaignPreviewAction,
  UpdateHighlighModeAction,
} from './types';
import { Campaign, Node, Content, Attributes } from './campaign';
import DeviceTypes from '@enums/DeviceTypes';
import {
  MOVE_CAMPAIGN_CONTENT_ELEMENT,
  UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE,
} from './customize/constants';
import { Template } from '@connectors/templates/types';

export function reset() {
  return {
    type: RESET,
  };
}

export function getCampaign({
  id,
  loadTargetsCopy,
  loadCustomizeCopy,
  getActiveTemplate,
}: {
  id: number;
  loadTargetsCopy?: boolean;
  loadCustomizeCopy?: boolean;
  getActiveTemplate?: boolean;
}): GetCampaignAction {
  return {
    type: GET_CAMPAIGN,
    payload: { id, loadTargetsCopy, loadCustomizeCopy, getActiveTemplate },
  };
}

export function getCampaignSuccess({
  data,
  loadTargetsCopy,
  loadCustomizeCopy,
}: {
  data: Campaign;
  loadTargetsCopy?: boolean;
  loadCustomizeCopy?: boolean;
}): GetCampaignSuccessAction {
  return {
    type: GET_CAMPAIGN_SUCCESS,
    payload: { data, loadTargetsCopy, loadCustomizeCopy },
  };
}

export function getCampaignError(): GetCampaignErrorAction {
  return {
    type: GET_CAMPAIGN_ERROR,
  };
}

//update data->layout->contents->[id=pageId] atrributes
export function updateCampaignPageAttribute(
  attributePath: string,
  value: string | number | boolean,
  pageId?: string,
): UpdateCampaignPageAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_ATTRIBUTE,
    payload: {
      attributePath,
      value,
      pageId,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType] attributes
export function updateCampaignContentAttribute(
  attributePath: string,
  value: string | number | boolean | object | Array<string> | Array<object>,
): UpdateCampaignContentAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE,
    payload: {
      attributePath,
      value,
    },
  };
}

export function addCampaignContentElement(
  id: string,
  value: Node,
  index: number,
): AddCampaignContentElementAction {
  return {
    type: ADD_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      value,
      index,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType] childNode with id
export function updateCampaignContentElement(
  id: string,
  value: Node[] | string[],
): UpdateCampaignContentElementAction {
  return {
    type: UPDATE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      value,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType] childNode attributes with id
export function updateCampaignContentElementAttribute(
  id: string,
  attributePath: string,
  value: string | number | boolean | object,
): UpdateCampaignContentElementAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE,
    payload: {
      id,
      attributePath,
      value,
    },
  };
}

export function updateCampaignAllPageCanvasElementAttribute(
  newAttributes: Attributes,
) {
  return {
    type: UPDATE_CAMPAIGN_ALL_PAGE_CANVAS_ELEMENT_ATTRIBUTE,
    payload: {
      newAttributes,
    },
  };
}
export function updateCampaignTeaserCanvasElementAttribute(
  newAttributes: Attributes,
) {
  return {
    type: UPDATE_CAMPAIGN_TEASER_CANVAS_ELEMENT_ATTRIBUTE,
    payload: {
      newAttributes,
    },
  };
}
export function moveCampaignContentElement(
  id: string,
  toId: string,
  index: number,
) {
  return {
    type: MOVE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      toId,
      index,
    },
  };
}

//remove data->layout->contents->[id=pageId]->content->[deviceType] childNode with id
export function removeCampaignContentElement(
  id: string,
): RemoveCampaignContentElementAction {
  return {
    type: REMOVE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
    },
  };
}

//update currentDeviceType
export function updateCampaignCurrentDeviceType(
  deviceType: DeviceTypes,
): UpdateCampaignCurrentDeviceTypeAction {
  return {
    type: UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE,
    payload: {
      deviceType,
    },
  };
}
//update currentPageId
export function updateCampaignCurrentPageId(
  pageId: string,
): UpdateCampaignCurrentPageIdAction {
  return {
    type: UPDATE_CAMPAIGN_CURRENT_PAGE_ID,
    payload: {
      pageId,
    },
  };
}

export function addCampaignPage({
  title,
  pageId,
  contents,
}: {
  title: string;
  pageId?: string;
  contents?: Content[];
}): AddCampaignPageAction {
  return {
    type: ADD_CAMPAIGN_PAGE,
    payload: {
      title,
      pageId,
      contents,
    },
  };
}

export function removeCampaignPage(pageId: string): RemoveCampaignPageAction {
  return {
    type: REMOVE_CAMPAIGN_PAGE,
    payload: {
      pageId,
    },
  };
}

export function updateCampaignPreview(
  isActive: boolean,
): UpdateCampaignPreviewAction {
  return {
    type: UPDATE_CAMPAIGN_PREVIEW,
    payload: {
      isActive,
    },
  };
}

export function toggleCanvasDragDropStatus(isActive: boolean) {
  return {
    type: TOGGLE_CANVAS_DRAG_DROP_STATUS,
    payload: {
      isActive,
    },
  };
}

export function updateVisitorDevice({
  mobile,
  desktop,
}: {
  mobile: boolean;
  desktop: boolean;
}) {
  return {
    type: UPDATE_VISITOR_DEVICE,
    payload: {
      mobile,
      desktop,
    },
  };
}

export function getNewPageTemplate(pageName: string, templateId: number) {
  return {
    type: GET_NEW_PAGE_TEMPLATE,
    payload: {
      pageName,
      templateId,
    },
  };
}

export function getNewPageTemplateError() {
  return {
    type: GET_NEW_PAGE_TEMPLATE,
  };
}

export function reverseCampaignChildElements(
  id: string,
): ReverseCampaignChildElementsAction {
  return {
    type: REVERSE_CAMPAIGN_CHILD_ELEMENTS,
    payload: {
      id,
    },
  };
}

export function updateCampaignPageFontFamily(
  value: string,
  pageId?: string,
): UpdateCampaignPageFontFamilyAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_FONT_FAMILY,
    payload: {
      value,
      pageId,
    },
  };
}

export function updateCampaignPageColor(
  oldValue: string,
  value: string,
  pageId?: string,
): UpdateCampaignPageColorAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_COLOR,
    payload: {
      oldValue,
      value,
      pageId,
    },
  };
}

export function updateInlineEditor(id?: string) {
  return {
    type: UPDATE_INLINE_EDITOR,
    payload: {
      id,
    },
  };
}

export function saveCampaign(payload?: { onSuccess?: () => void }) {
  return {
    type: SAVE_CAMPAIGN,
    payload,
  };
}

export function saveCampaignSuccess({
  data,
  updateTargetCopy,
}: {
  data: Campaign;
  updateTargetCopy?: boolean;
}) {
  return {
    type: SAVE_CAMPAIGN_SUCCESS,
    payload: {
      data,
      updateTargetCopy,
    },
  };
}

export function saveCampaignError() {
  return {
    type: SAVE_CAMPAIGN_ERROR,
  };
}

export function changeCampaignTemplate(
  campaignId: number,
  template: Template,
  onSuccess: () => void,
) {
  return {
    type: CHANGE_CAMPAIGN_TEMPLATE,
    payload: { campaignId, template },
    onSuccess,
  };
}

export function changeCampaignTemplateSuccess() {
  return {
    type: CHANGE_CAMPAIGN_TEMPLATE_SUCCESS,
  };
}

export function changeCampaignTemplateError() {
  return {
    type: CHANGE_CAMPAIGN_TEMPLATE_ERROR,
  };
}

export function getTemplateAndUpdateCampaignTemplate(
  campaignId: number,
  templateId: number,
  newCampaign: boolean,
  onSuccess: () => void,
) {
  return {
    type: GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE,
    payload: { campaignId, templateId, newCampaign },
    onSuccess,
  };
}

export function getTemplateAndUpdateCampaignTemplateSuccess() {
  return {
    type: GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_SUCCESS,
  };
}

export function getTemplateAndUpdateCampaignTemplateError() {
  return {
    type: GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_ERROR,
  };
}

export function increasePublishVersion() {
  return {
    type: INCREASE_PUBLISH_VERSION,
  };
}

export function updatePublishLoadingStatus(
  publishLoadingStatus: 'save' | 'publishing' | 'published' | 'loading' | null,
) {
  return {
    type: UPDATE_PUBLISH_LOADING_STATUS,
    payload: { publishLoadingStatus },
  };
}

export function updateHighlighMode(
  mode: HighlighMode,
): UpdateHighlighModeAction {
  return {
    type: UPDATE_HIGHLIGHT_MODE,
    payload: {
      mode,
    },
  };
}

export function updateCampaignDomainVerifiedStatus(
  domainId: number,
  status: boolean,
) {
  return {
    type: UPDATE_CAMPAIGN_DOMAIN_VERIFIED_STATUS,
    payload: {
      domainId,
      status,
    },
  };
}

export function setEmailAutomationEdit(data: { id: number; status: boolean }) {
  return {
    type: SET_EMAIL_AUTOMATION_EDIT,
    payload: {
      data,
    },
  };
}

export function setTotalCampaignCount(count: number) {
  return {
    type: SET_TOTAL_CAMPAIGN_COUNT,
    payload: {
      count,
    },
  };
}

export function reorderPageStep(pageSteps: PageStep[]): ReorderPageStepAction {
  return {
    type: REORDER_PAGE_STEP,
    payload: {
      pageSteps,
    },
  };
}
