import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import crypto from 'crypto-js';

const ENCRYPT_SECRET_KEY = process.env.NEXT_PUBLIC_ENCRYPT_SECRET_KEY || '';

const storeCryptoPlugin = () => ({
  set: (
    super_fn: (key: string, value: string) => void,
    key: string,
    data: string,
  ) => {
    data = JSON.stringify(data);
    const value = crypto.AES.encrypt(data, ENCRYPT_SECRET_KEY);

    return super_fn(key, value.toString());
  },
  get: (super_fn: (key: string) => string | undefined, key: string) => {
    if (typeof window === 'undefined') return undefined;

    const value = super_fn(key);
    if (!value) return undefined;

    try {
      const data = crypto.AES.decrypt(value, ENCRYPT_SECRET_KEY).toString(
        crypto.enc.Utf8,
      );
      return JSON.parse(data);
    } catch (error) {
      window.localStorage.clear();
      return undefined;
    }
  },
});

const storages = [localStorage];
const plugins = [storeCryptoPlugin];
const store = engine.createStore(storages, plugins);

// Extend the store with the custom method using Object.assign
const customStore = Object.assign(store, {
  clearTemps: () => {
    store.remove('user');
    store.remove('intercom.intercom-state');
  },
});

export default customStore;
