export const UPDATE_SHOPIFY_INTEGRATIONS_CAMPAIGN =
  'builder/integration/UPDATE_SHOPIFY_INTEGRATIONS_CAMPAIGN';

export const ADD_SHOPIFY_INTEGRATION_CAMPAIGN =
  'builder/integration/ADD_SHOPIFY_INTEGRATION_CAMPAIGN';

export const UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN =
  'builder/integration/UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN';

export const UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS =
  'builder/integration/UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS';

export const DELETE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS =
  'builder/integration/DELETE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS';

export const CLEAR_SHOPIFY_INTEGRATIONS =
  'builder/integration/CLEAR_SHOPIFY_INTEGRATIONS';
