import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import fetch from '@utils/fetch';
import {
  customerIoServiceURl,
  CUSTOMERIO_GET_FIELDS,
  CUSTOMERIO_GET_FIELDS_SUCCESS,
  CUSTOMERIO_GET_FIELDS_FAILURE,
  CUSTOMERIO_GET_CONNECTIONS,
  CUSTOMERIO_GET_CONNECTIONS_SUCCESS,
  CUSTOMERIO_GET_CONNECTIONS_FAILURE,
  CUSTOMERIO_GET_LIST,
  CUSTOMERIO_GET_LIST_SUCCESS,
  CUSTOMERIO_GET_LIST_FAILURE,
  CUSTOMERIO_SAVE,
  CUSTOMERIO_SAVE_SUCCESS,
  CUSTOMERIO_SAVE_FAILURE,
  CUSTOMERIO_DELETE,
  CUSTOMERIO_DELETE_FAILURE,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import { clearCustomerIoState } from '@connectors/integration/customerIo/action';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import { IntegrationSaveResult } from '../type';
import graphqlFetch from '@utils/graphqlFetch';
import {
  CreateCustomerIo,
  DeleteCustomerIoIntegrationCampaign,
  GetCustomerIoIntegrationsByAccountId,
  GetCustomerIoIntegrationsByCampaignId,
  UpdateCustomerIo,
} from './graphql';
import { CustomerIoIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/CustomerIoResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getCustomerIoIntegrationsByAccountId: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetCustomerIoIntegrationsByAccountId(),
    });

    yield put({
      type: CUSTOMERIO_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getCustomerIoIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: CUSTOMERIO_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, listId, onSuccess } = action.payload;
  try {
    const result: ApiFieldObject[] = yield call(
      fetch,
      `${customerIoServiceURl}/lists/${apiKey}/${listId}/mergeFields`,
      {
        method: 'GET',
      },
    );
    onSuccess && onSuccess();
    yield put({ type: CUSTOMERIO_GET_FIELDS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CUSTOMERIO_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;
  try {
    const result: ApiListObject[] = yield call(
      fetch,
      `${customerIoServiceURl}/lists/${apiKey}`,
      {
        method: 'GET',
      },
    );
    onSuccess && onSuccess();
    yield put({ type: CUSTOMERIO_GET_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CUSTOMERIO_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  let result = {} as IntegrationSaveResult;
  if (isEdit) {
    try {
      const formData = {
        id: listSettings.id,
        campaignId,
        status: true,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateCustomerIo(listSettings.id, formData),
      });
      yield put({ type: CUSTOMERIO_SAVE_SUCCESS, payload: result });
    } catch (error) {
      yield put({ type: CUSTOMERIO_SAVE_FAILURE });
    }
  } else {
    const formData = {
      id: connectionSettings.id,
      name: connectionSettings.name,
      apiKey: connectionSettings.apiKey,
      siteId: connectionSettings.siteId,
      integrationCampaigns: fieldList
        ? [
            {
              campaignId,
              status: true,
              integrationId: connectionSettings.id,
              integrationFields: fieldList,
              id: 0,
            },
          ]
        : [],
    };
    formData.integrationCampaigns.forEach((element) => {
      element.integrationFields.forEach((field: ApiFieldObject) => {
        field.id = 0;
      });
    });
    try {
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateCustomerIo({ ...formData, status: true }),
      });

      const integrations: {
        data: {
          getCustomerIoIntegrationsByCampaignId: CustomerIoIntegrationCampaignResponse[];
        };
      } = yield call(graphqlFetch, ``, {
        method: 'POST',
        data: GetCustomerIoIntegrationsByCampaignId(campaignId),
      });
      yield put(
        setIntegration({
          datas: integrations.data.getCustomerIoIntegrationsByCampaignId,
          type: IntegrationEnums.customerIo,
        }),
      );
      yield put({ type: CUSTOMERIO_SAVE_SUCCESS, payload: result });
    } catch (error) {
      yield put({ type: CUSTOMERIO_SAVE_FAILURE });
    }
  }
}
function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteCustomerIoIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.customerIo,
      }),
    );
    yield put(clearCustomerIoState());
  } catch (error) {
    yield put({ type: CUSTOMERIO_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(CUSTOMERIO_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(CUSTOMERIO_GET_FIELDS, handleGetFields);
  yield takeLatest(CUSTOMERIO_GET_LIST, handleGetList);
  yield takeLatest(CUSTOMERIO_SAVE, handleSave);
  yield takeLatest(CUSTOMERIO_DELETE, handleDelete);
}
