import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';
import {
  GOOGLE_FONTS_GET,
  GOOGLE_FONTS_GET_SUCCESS,
  ADD_FONT_TO_SELECTBOX,
  ADD_FONT_TO_SELECTBOX_SUCCESS,
  FONTS_MODAL_SHOW,
  FONTS_MODAL_SHOW_SUCCESS,
  FONTS_MODAL_HIDE,
  FONTS_MODAL_HIDE_SUCCESS,
  FONTS_FAILURE,
} from './constants';
import { webSafeFonts } from 'core/constants/webSafeFonts';

export interface FontsState {
  allFonts: Array<{
    family: string;
    subsets: Array<string>;
    version: string;
    category: string;
    variants: Array<string>;
  }>;
  selectboxList: Array<{ value: string; label: string }>;
  fontsModalOpen: boolean;
  status: 'success' | 'errored' | 'loading' | 'initialized';
}
export const initialState: FontsState = {
  allFonts: [],
  selectboxList: [
    { value: 'Inter', label: 'Inter' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Raleway', label: 'Raleway' },
    ...webSafeFonts.map((font) => ({ value: font, label: font })),
  ],
  fontsModalOpen: false,
  status: 'initialized',
};

export default function reducer(
  state = initialState,
  action: ReduxAction,
): FontsState {
  switch (action.type) {
    case GOOGLE_FONTS_GET:
      return { ...state, status: 'loading' };
    case GOOGLE_FONTS_GET_SUCCESS:
      return {
        ...state,
        allFonts: [
          ...action.payload,
          ...webSafeFonts.map((font) => ({
            family: font,
            variants: ['400', '700'],
          })),
        ],
        status: 'success',
      };

    case ADD_FONT_TO_SELECTBOX:
      return { ...state, status: 'loading' };
    case ADD_FONT_TO_SELECTBOX_SUCCESS:
      return {
        ...state,
        selectboxList: [...state.selectboxList, action.payload],
        status: 'success',
      };

    case FONTS_MODAL_SHOW:
      return { ...state, status: 'loading' };
    case FONTS_MODAL_SHOW_SUCCESS:
      return {
        ...state,
        fontsModalOpen: true,
        status: 'success',
      };

    case FONTS_MODAL_HIDE:
      return { ...state, status: 'loading' };
    case FONTS_MODAL_HIDE_SUCCESS:
      return {
        ...state,
        fontsModalOpen: false,
        status: 'success',
      };

    case FONTS_FAILURE:
      return { ...state, status: 'errored' };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
