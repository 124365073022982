import { ReactElement } from 'react';

import IncreaseSalesConversionIcon from '@assets/icons/increase_sales_conversion.svg';
import ReduceCartAbandonmentIcon from '@assets/icons/reduce_cart_abandonment.svg';
import PromoteProductsOffersIcon from '@assets/icons/promote_products_offers.svg';
import CollectFormSubmissionIcon from '@assets/icons/collect_form_submission.svg';
import GetMorePhoneCallsIcon from '@assets/icons/get_more_phone_calls.svg';
import GrowYourEmailListIcon from '@assets/icons/grow_your_email_list.svg';
import GamifyYourCampaignIcon from '@assets/icons/gamify_your_campaign.svg';
import MakeAnnouncementsIcon from '@assets/icons/make_announcements.svg';
import IncreaseUserEngagementIcon from '@assets/icons/increase_user_engagement.svg';
import CollectFeedbackSurveysIcon from '@assets/icons/collect_feedback_surveys.svg';
import FacilitateSocialSharingIcon from '@assets/icons/facilitate_social_sharing.svg';
import ShopifyTag from '@assets/icons/shopify_tag.svg';
import BlankTag from '@assets/icons/blank_tag.svg';
import AutomotiveIcon from '@assets/icons/automotive-icon.svg';
import ShoppingCartIcon from '@assets/icons/shopping_cart_black.svg';
import TravelIcon from '@assets/icons/travel-icon.svg';
import HealthIcon from '@assets/icons/health-icon.svg';
import FoodIcon from '@assets/icons/food.svg';
import School from '@assets/icons/school.svg';

export type IconsType = {
  [x: string]: ReactElement;
};

export const ShowcaseIcons: IconsType = {
  increase_sales_conversion: (
    <IncreaseSalesConversionIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  reduce_cart_abandonment: (
    <ReduceCartAbandonmentIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  promote_products_offers: (
    <PromoteProductsOffersIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  collect_form_submission: (
    <CollectFormSubmissionIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  get_more_phone_calls: (
    <GetMorePhoneCallsIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  grow_your_email_list: (
    <GrowYourEmailListIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  gamify_your_campaign: (
    <GamifyYourCampaignIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  make_announcements: (
    <MakeAnnouncementsIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  increase_user_engagement: (
    <IncreaseUserEngagementIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  collect_feedback_surveys: (
    <CollectFeedbackSurveysIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  facilitate_social_sharing: (
    <FacilitateSocialSharingIcon width="16" height="16" viewBox="0 0 32 32" />
  ),
  shopify_tag: <ShopifyTag width="16" height="16" viewBox="0 0 16 16" />,
  blank_tag: <BlankTag width="16" height="16" viewBox="0 0 16 16" />,
  automotive: <AutomotiveIcon width="16" height="16" viewBox="0 0 16 16" />,
  e_commerce: <ShoppingCartIcon width="16" height="16" viewBox="0 0 18 18" />,
  travel: <TravelIcon width="16" height="16" viewBox="0 0 16 16" />,
  health_and_beauty: <HealthIcon width="16" height="16" viewBox="0 0 16 16" />,
  food_and_beverage: <FoodIcon width="16" height="16" viewBox="0 0 16 16" />,
  education: <School width="16" height="16" viewBox="0 0 16 16" />,
};
