import {
  MailerLiteInput,
  MailerLiteIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/MailerLiteResolver.types';

export const GetMailerLiteIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetMailerLiteIntegrationsByCampaignId',
  query: `query GetMailerLiteIntegrationsByCampaignId($campaignId: Int!) {
    getMailerLiteIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integration {
        apiKey
        id
        accountId
        name
        status
      }
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
      name
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetMailerLiteIntegrationsByAccountId = () => ({
  operationName: 'GetMailerLiteIntegrationsByAccountId',
  query: `query GetMailerLiteIntegrationsByAccountId {
    getMailerLiteIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
        name
      }
      apiKey
    }
  }`,
});
export const GetMailerLiteLists = (apiKey: string) => ({
  operationName: 'GetMailerLiteLists',
  query: `query GetMailerLiteLists($apiKey: String!) {
    getMailerLiteLists(apiKey: $apiKey) {
      id
      name
    }
  }`,
  variables: {
    apiKey,
  },
});
export const GetMailerLiteFields = (apiKey: string) => ({
  operationName: 'GetMailerLiteFields',
  query: `query GetMailerLiteFields($apiKey: String!) {
    getMailerLiteFields(apiKey: $apiKey) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    apiKey,
  },
});
export const CreateMailerLite = (input: MailerLiteInput) => ({
  operationName: 'CreateMailerLiteIntegration',
  query: `mutation CreateMailerLiteIntegration($input: MailerLiteInput!) {
    createMailerLiteIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateMailerLite = (
  id: number,
  input: MailerLiteIntegrationCampaignInput,
) => ({
  operationName: 'UpdateMailerLiteIntegrationCampaign',
  query: `mutation UpdateMailerLiteIntegrationCampaign($input: MailerLiteIntegrationCampaignInput!, $updateMailerLiteIntegrationCampaignId: Int!) {
    updateMailerLiteIntegrationCampaign(input: $input, id: $updateMailerLiteIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateMailerLiteIntegrationCampaignId: id,
  },
});

export const DeleteMailerLiteIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteMailerLiteIntegrationCampaign',
  query: `mutation DeleteMailerLiteIntegrationCampaign($deleteMailerLiteIntegrationCampaignId: Int!) {
    deleteMailerLiteIntegrationCampaign(id: $deleteMailerLiteIntegrationCampaignId)
  }`,
  variables: {
    deleteMailerLiteIntegrationCampaignId: id,
  },
});
