import type { Targets } from '@connectors/targets/reducer';

import React from 'react';
import dayjs from 'dayjs';

import TimelineContent from '../TimelineContent';

import ScheduleIcon from '@assets/icons/schedule.svg';

export const renderSchedule = (
  schedule: Targets['schedule'],
  translate: Function,
) => {
  if (schedule && !!schedule?.length) {
    return (
      <>
        <TimelineContent
          icon={<ScheduleIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={
            <>{translate('sections:targeting-summary-schedule:message')}</>
          }
        >
          {schedule.map((source, index) => (
            <span key={index} className="flex items-start font-inter text-xs">
              {index > 0 && (
                <span className="font-semibold text-bluesmart mr-1">
                  {translate('common:or').toUpperCase()}
                </span>
              )}
              {`${dayjs(source.startDate).format('DD/MM/YYYY HH:mm')} - ${dayjs(
                source.endDate,
              ).format('DD/MM/YYYY HH:mm')}`}
            </span>
          ))}
        </TimelineContent>
      </>
    );
  }
};
