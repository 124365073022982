import cn from 'classnames';
import { FC } from 'react';

export const TabIconLabel: FC<{
  icon: JSX.Element;
  text: string;
  tabId?: string;
  activeTabId?: string;
  activeClassName?: string;
  activeIcon?: JSX.Element;
  isSmall?: boolean;
}> = ({
  icon,
  text,
  tabId,
  activeTabId,
  activeClassName,
  activeIcon,
  isSmall,
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center gap-[5px] py-[10px] px-[12.5px]',
        {
          [`${activeClassName}`]: tabId === activeTabId,
          [`!pt-0 !pr-[5px] !pb-[10px] !pl-[5px]`]: isSmall,
        },
      )}
    >
      {activeIcon && tabId === activeTabId ? activeIcon : icon}
      <label className="cursor-pointer">{text}</label>
    </div>
  );
};
