import React, { Children, FC, ReactElement, useEffect, useState } from 'react';
import type { TabsProps } from './types';
import styles from './Tabs.module.scss';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

const ImageCardTooltip = dynamic(
  () => import('@components/ImageCardTooltip/ImageCardTooltip'),
);

const Tabs: FC<TabsProps> = ({
  children,
  defaultActiveKey = '',
  activeKey = '',
  headerClassName,
  headlineClassName,
  clickable = true,
  onClickEvent,
  labelSize = 'md',
  activeTabClassName,
}) => {
  const [activeTab, setActiveTab] = useState<string | number>('');
  useEffect(() => {
    if (defaultActiveKey === '') {
      setActiveTab(activeKey);
    } else if (activeKey === '') {
      setActiveTab(defaultActiveKey);
    }
  }, [activeKey, defaultActiveKey]);

  const onClickTab = (id: string | number) => {
    clickable && setActiveTab(id);
    onClickEvent && onClickEvent(id);
  };

  const ListElement: FC<{ child: ReactElement }> = ({ child }) => {
    return (
      <li
        className={classNames({
          [styles.active]: child.props.id === activeTab && !activeTabClassName,
          [styles.unClickable]: child.props.id !== activeTab && !clickable,
          [styles.small]: labelSize === 'sm' || labelSize === 'color-sm',
          [styles.colorSmall]: labelSize === 'color-sm',
          'active-item': child.props.id === activeTab,
          [activeTabClassName || '']:
            !!activeTabClassName && child.props.id === activeTab,
        })}
        id={child.props.id}
        data-testid={child?.props?.testId ?? ''}
        key={child.props.id.toString()}
        onClick={() => onClickTab(child.props.id)}
      >
        {React.isValidElement(child.props.label) ? (
          React.cloneElement(child.props.label, {
            activeTabId: activeTab,
            isSmall: labelSize === 'sm',
          })
        ) : (
          <span>{child.props.label}</span>
        )}
      </li>
    );
  };

  const SingleListElement: FC<{ child: ReactElement }> = ({ child }) => {
    return (
      <li
        className={classNames({
          [styles.active]: child.props.id === activeTab && !activeTabClassName,
          [styles.unClickable]: child.props.id !== activeTab && !clickable,
          [styles.small]: labelSize === 'sm',
          [activeTabClassName || '']: !!activeTabClassName,
        })}
        id={child.props.id}
        key={child.props.id.toString()}
        onClick={() => onClickTab(child.props.id)}
      >
        {child.props.label}
      </li>
    );
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.headline, headlineClassName)}>
        <ul className={classNames(headerClassName)}>
          {Array.isArray(children)
            ? children.map(
                (child: ReactElement, index) =>
                  !child.props.hide &&
                  (() => {
                    const tabIsActive = child.props.id === activeTab;
                    const imageCardTooltipProps =
                      child?.props?.imageCardTooltip;

                    return imageCardTooltipProps ? (
                      <ImageCardTooltip
                        key={`${child.props.id}-${index}`}
                        image={imageCardTooltipProps.image}
                        description={imageCardTooltipProps.description}
                        hide={tabIsActive}
                      >
                        <ListElement child={child} />
                      </ImageCardTooltip>
                    ) : (
                      <ListElement
                        key={`${child.props.id}-${index}`}
                        child={child}
                      />
                    );
                  })(),
              )
            : (() => {
                const tabIsActive = children.props.id === activeTab;
                const imageCardTooltipProps = children?.props?.imageCardTooltip;

                return imageCardTooltipProps ? (
                  <ImageCardTooltip
                    image={imageCardTooltipProps.image}
                    description={imageCardTooltipProps.description}
                    hide={tabIsActive}
                  >
                    <SingleListElement child={children} />
                  </ImageCardTooltip>
                ) : (
                  <SingleListElement child={children} />
                );
              })()}
        </ul>
      </div>
      {Children.map(children, (child: ReactElement) =>
        React.cloneElement(child, { activeTab }),
      )}
    </div>
  );
};

export default Tabs;
