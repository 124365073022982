import {
  ADD_LEAD_EMAIL_REQUEST,
  GET_LEAD_EMAILS_REQUEST,
  DELETE_LEAD_EMAIL_REQUEST,
  VERIFY_LEAD_EMAIL_REQUEST,
} from './constants';

export function addLeadEmail(payload: any) {
  return {
    type: ADD_LEAD_EMAIL_REQUEST,
    payload,
  };
}
export function getLeadEmails(payload: any) {
  return {
    type: GET_LEAD_EMAILS_REQUEST,
    payload,
  };
}
export function deleteLeadEmail(payload: any) {
  return {
    type: DELETE_LEAD_EMAIL_REQUEST,
    payload,
  };
}
export function verifyLeadEmail(payload: any) {
  return {
    type: VERIFY_LEAD_EMAIL_REQUEST,
    payload,
  };
}
