import { PositionItem } from './types';

const positions: PositionItem[] = [
  {
    value: 'top-left',
    css: {
      marginTop: '20px',
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: '20px',
      justifyContent: 'flex-start',
      alignItems: 'start',
      objectPosition: 'top left',
    },
  },
  {
    value: 'top-center',
    css: {
      marginTop: '20px',
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'start',
      objectPosition: 'top center',
    },
  },
  {
    value: 'top-right',
    css: {
      marginTop: '20px',
      marginRight: '20px',
      marginBottom: 'auto',
      marginLeft: 'auto',
      justifyContent: 'flex-end',
      alignItems: 'start',
      objectPosition: 'top right',
    },
  },
  {
    value: 'center-left',
    css: {
      marginTop: 'auto',
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: '20px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      objectPosition: 'center left',
    },
  },
  {
    value: 'center-center',
    css: {
      marginTop: 'auto',
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      objectPosition: 'center center',
    },
  },
  {
    value: 'center-right',
    css: {
      marginTop: 'auto',
      marginRight: '20px',
      marginBottom: 'auto',
      marginLeft: 'auto',
      justifyContent: 'flex-end',
      alignItems: 'center',
      objectPosition: 'center right',
    },
  },
  {
    value: 'bottom-left',
    css: {
      marginTop: 'auto',
      marginRight: 'auto',
      marginBottom: '20px',
      marginLeft: '20px',
      justifyContent: 'flex-start',
      alignItems: 'end',
      objectPosition: 'bottom left',
    },
  },
  {
    value: 'bottom-center',
    css: {
      marginTop: 'auto',
      marginRight: 'auto',
      marginBottom: '20px',
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'end',
      objectPosition: 'bottom center',
    },
  },
  {
    value: 'bottom-right',
    css: {
      marginTop: 'auto',
      marginRight: '20px',
      marginBottom: '20px',
      marginLeft: 'auto',
      justifyContent: 'flex-end',
      alignItems: 'end',
      objectPosition: 'bottom right',
    },
  },
];

export { positions };
