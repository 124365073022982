import ShopifyIcon from '@assets/icons/shopify-icon.svg';

import PlatformBadge from '@components/ModalEmbedCode/PlatformBadge';
import { useTranslation } from 'react-i18next';

const ShopifyWebsiteOption = () => {
  const { t } = useTranslation();
  const handleOnClickGoToShopifyAppButton = () => {
    window.open('https://apps.shopify.com/popupsmart', '_blank');
  };

  return (
    <PlatformBadge
      cmsIcon={ShopifyIcon}
      title={t('sections:unverified-modal:shopifyAppText')}
      overlayBackgroundColor="rgba(149, 191, 71, 0.3)"
      buttonBackgroundColor="shopify"
      button={{
        onClick: handleOnClickGoToShopifyAppButton,
        text: t('sections:unverified-modal:shopifyAppButton'),
      }}
    />
  );
};

export default ShopifyWebsiteOption;
