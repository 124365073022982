import { SHOW_DRAWER, HIDE_DRAWER, CLEAR_ALL_DRAWER } from './constants';
import type { Drawer } from './reducer';

export function showDrawer(payload: Drawer) {
  return {
    type: SHOW_DRAWER,
    payload,
  };
}

export function hideDrawer(payload: string) {
  return {
    type: HIDE_DRAWER,
    payload,
  };
}

export function clearAllDrawer() {
  return {
    type: CLEAR_ALL_DRAWER,
  };
}
