import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  KLAVIYO_GET_FIELDS,
  KLAVIYO_GET_FIELDS_SUCCESS,
  KLAVIYO_GET_FIELDS_FAILURE,
  KLAVIYO_GET_CONNECTIONS,
  KLAVIYO_GET_CONNECTIONS_SUCCESS,
  KLAVIYO_GET_CONNECTIONS_FAILURE,
  KLAVIYO_GET_LIST,
  KLAVIYO_GET_LIST_SUCCESS,
  KLAVIYO_GET_LIST_FAILURE,
  KLAVIYO_SAVE,
  KLAVIYO_SAVE_SUCCESS,
  KLAVIYO_SAVE_FAILURE,
  KLAVIYO_CHANGESTATUS,
  KLAVIYO_CHANGESTATUS_FAILURE,
  KLAVIYO_CHANGESTATUS_SUCCESS,
  KLAVIYO_DELETE,
  KLAVIYO_DELETE_FAILURE,
  KLAVIYO_DELETE_SUCCESS,
  KLAVIYO_CLEAR_STATE,
} from './constants';
import { KlaviyoState } from './type';
import produce, { Draft } from 'immer';

const initialState: KlaviyoState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<KlaviyoState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case KLAVIYO_GET_FIELDS:
      draft.fields = { items: [], status: ReduxStatus.loading };
      return;
    case KLAVIYO_GET_FIELDS_SUCCESS:
      draft.fields = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case KLAVIYO_GET_FIELDS_FAILURE:
      draft.fields = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;
    case KLAVIYO_GET_LIST:
      draft.list = {
        items: [],
        status: ReduxStatus.loading,
      };
      return;
    case KLAVIYO_GET_LIST_SUCCESS:
      draft.list = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case KLAVIYO_GET_LIST_FAILURE:
      draft.list = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;

    case KLAVIYO_GET_CONNECTIONS:
      draft.connection = { items: [], status: ReduxStatus.loading };
      return;
    case KLAVIYO_GET_CONNECTIONS_SUCCESS:
      draft.connection = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case KLAVIYO_GET_CONNECTIONS_FAILURE:
      draft.connection = { items: [], status: ReduxStatus.errored };
      return;

    case KLAVIYO_SAVE:
      draft.status = ReduxStatus.loading;
      return;
    case KLAVIYO_SAVE_SUCCESS:
      draft.list = { items: [], status: ReduxStatus.initialized };
      draft.fields = { items: [], status: ReduxStatus.initialized };
      draft.status = ReduxStatus.success;
      return;
    case KLAVIYO_SAVE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case KLAVIYO_DELETE:
      draft.status = ReduxStatus.loading;
      return;
    case KLAVIYO_DELETE_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case KLAVIYO_DELETE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case KLAVIYO_CHANGESTATUS:
      draft.status = ReduxStatus.loading;
      return;
    case KLAVIYO_CHANGESTATUS_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case KLAVIYO_CHANGESTATUS_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case KLAVIYO_CLEAR_STATE:
      return initialState;
    default:
      return;
  }
}, initialState);

export default reducer;
