export const GET_CAMPAIGN_REQUEST = 'GET_CAMPAIGN_REQUEST';
export const CAMPAIGN_SUCCESS = 'CAMPAIGN_SUCCESS';
export const CAMPAIGN_FAILURE = 'CAMPAIGN_FAILURE';
export const CAMPAIGN_INVALIDATE = 'CAMPAIGN_INVALIDATE';

export const CAMPAIGN_GET = 'CAMPAIGN_GET';
export const CAMPAIGN_GET_SUCCESS = 'CAMPAIGN_GET_SUCCESS';

export const CAMPAIGN_LAYOUT_GET = 'CAMPAIGN_LAYOUT_GET';
export const CAMPAIGN_LAYOUT_GET_SUCCESS = 'CAMPAIGN_LAYOUT_GET_SUCCESS';

export const CAMPAIGN_DETAILS_GET = 'CAMPAIGN_DETAILS_GET';
export const CAMPAIGN_DETAILS_GET_SUCCESS = 'CAMPAIGN_DETAILS_GET_SUCCESS';

export const AUTO_SAVE = 'AUTO_SAVE';
export const AUTO_SAVE_SUCCESS = 'AUTO_SAVE_SUCCESS';

export const SAVE_LAYOUT = 'SAVE_LAYOUT';
export const SAVE_LAYOUT_SUCCESS = 'SAVE_LAYOUT_SUCCESS';

export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN';
export const CLEAR_CAMPAIGN_SUCCESS = 'CLEAR_CAMPAIGN_SUCCESS';

export const SAVE_PAGE_INDEX = 'SAVE_PAGE_INDEX';
export const SAVE_PAGE_INDEX_SUCCESS = 'SAVE_PAGE_INDEX_SUCCESS';

export const PREVIEW_ACTIVE = 'PREVIEW_ACTIVE';
export const PREVIEW_ACTIVE_SUCCESS = 'PREVIEW_ACTIVE_SUCCESS';

export const SAVE_ACTIVE_DEVICE = 'SAVE_ACTIVE_DEVICE';
export const SAVE_ACTIVE_DEVICE_SUCCESS = 'SAVE_ACTIVE_DEVICE_SUCCESS';

export const PUBLISH = 'PUBLISH';
export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS';
export const PUBLISH_FAILURE = 'PUBLISH_FAILURE';

export const DEFAULT_PUBLISH = 'DEFAULT_PUBLISH';

export const DISABLE_EDIT_MOBILE_MODAL_REQUEST =
  'DISABLE_EDIT_MOBILE_MODAL_REQUEST';

export const DISABLE_EDIT_MOBILE_MODAL_SUCCESS =
  'DISABLE_EDIT_MOBILE_MODAL_SUCCESS';

export const DOMAIN_DELETE = 'DOMAIN_DELETE';
export const DOMAIN_DELETE_SUCCESS = 'DOMAIN_DELETE_SUCCESS';

export const DOMAIN_SELECT = 'DOMAIN_SELECT';
export const DOMAIN_SELECT_SUCCESS = 'DOMAIN_SELECT_SUCCESS';

export const DOMAIN_GET = 'DOMAIN_GET';
export const DOMAIN_GET_SUCCESS = 'DOMAIN_GET_SUCCESS';

export const SEND_EMBED_CODE_REQUEST = 'SEND_EMBED_CODE_REQUEST';
export const SEND_EMBED_CODE_SUCCESS = 'SEND_EMBED_CODE_SUCCESS';

export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';

export const SET_SETTINGS_REQUEST = 'SET_SETTINGS_REQUEST';
export const SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS';
export const SET_SETTINGS_FAILURE = 'SET_SETTINGS_FAILURE';

export const LOCAL_UPDATE_CAMPAIGN_REQUEST = 'LOCAL_UPDATE_CAMPAIGN_REQUEST';
export const LOCAL_UPDATE_CAMPAIGN_SUCCESS = 'LOCAL_UPDATE_CAMPAIGN_SUCCESS';
export const LOCAL_UPDATE_CAMPAIGN_FAILURE = 'LOCAL_UPDATE_CAMPAIGN_FAILURE';

export const TAKE_SCREENSHOT_REQUEST = 'TAKE_SCREENSHOT_REQUEST';
export const TAKE_SCREENSHOT_SUCCESS = 'TAKE_SCREENSHOT_SUCCESS';
export const TAKE_SCREENSHOT_FAILURE = 'TAKE_SCREENSHOT_FAILURE';

export const UPDATE_AUTO_RESPONDER_REQUEST = 'UPDATE_AUTO_RESPONDER_REQUEST';
export const UPDATE_AUTO_RESPONDER_SUCCESS = 'UPDATE_AUTO_RESPONDER_SUCCESS';
export const UPDATE_AUTO_RESPONDER_FAILURE = 'UPDATE_AUTO_RESPONDER_FAILURE';
