import React, { FC } from 'react';
import styles from './ShopifyConnectedStoreModal.module.css';
import CheckIcon from '@assets/icons/check-inside-circle.svg';
import ShopifyIcon from '@assets/icons/shopify-icon.svg';
import { ShopifyConnectedStoreModalProps } from './types';

const ShopifyConnectedStoreModal: FC<ShopifyConnectedStoreModalProps> = ({
  title,
  website,
  onClickOkay,
}) => {
  const handleClickButton = () => {
    onClickOkay && onClickOkay();
  };
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <ShopifyIcon width="28" height="28" viewBox="0 0 18 18" />
      </div>
      <h1>{title ?? 'Successfully connected'}</h1>
      <div className={styles.connectedStore}>
        <CheckIcon />
        <span>{website}</span>
      </div>
      <button onClick={handleClickButton} className={styles.button}>
        Okay
      </button>
    </div>
  );
};

export default ShopifyConnectedStoreModal;
