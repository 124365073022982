import {
  SHOW_MODAL,
  HIDE_MODAL,
  RESET_MODAL,
  UPDATE_MODAL_ON_CLOSE,
} from './constants';
import { Modal } from './reducer';

export function showModal(payload: Modal) {
  return {
    type: SHOW_MODAL,
    payload,
  };
}

export function hideModal(payload: string) {
  return {
    type: HIDE_MODAL,
    payload,
  };
}

export function updateModalOnClose(payload: {
  id: string;
  onClose: () => void;
}) {
  return {
    type: UPDATE_MODAL_ON_CLOSE,
    payload,
  };
}
export function resetModal() {
  return {
    type: RESET_MODAL,
  };
}
