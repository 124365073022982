import {
  BrevoInput,
  BrevoIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/BrevoResolver.types';

export const GetBrevoIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetBrevoIntegrationsByCampaignId',
  query: `query GetBrevoIntegrationsByCampaignId($campaignId: Int!) {
    getBrevoIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integration {
        apiKey
        id
        accountId
        name
        status
      }
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
      name
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetBrevoIntegrationsByAccountId = () => ({
  operationName: 'GetBrevoIntegrationsByAccountId',
  query: `query GetBrevoIntegrationsByAccountId {
    getBrevoIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
      }
      apiKey
    }
  }`,
});
export const GetBrevoLists = (apiKey: string) => ({
  operationName: 'GetBrevoLists',
  query: `query GetBrevoLists($apiKey: String!) {
    getBrevoLists(apiKey: $apiKey) {
      id
      name
    }
  }`,
  variables: {
    apiKey,
  },
});
export const GetBrevoFields = (apiKey: string) => ({
  operationName: 'GetBrevoFields',
  query: `query GetBrevoFields($apiKey: String!) {
    getBrevoFields(apiKey: $apiKey) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    apiKey,
  },
});
export const CreateBrevo = (input: BrevoInput) => ({
  operationName: 'CreateBrevoIntegration',
  query: `mutation CreateBrevoIntegration($input: BrevoInput!) {
    createBrevoIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateBrevo = (
  id: number,
  input: BrevoIntegrationCampaignInput,
) => ({
  operationName: 'UpdateBrevoIntegrationCampaign',
  query: `mutation UpdateBrevoIntegrationCampaign($input: BrevoIntegrationCampaignInput!, $updateBrevoIntegrationCampaignId: Int!) {
    updateBrevoIntegrationCampaign(input: $input, id: $updateBrevoIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateBrevoIntegrationCampaignId: id,
  },
});

export const DeleteBrevoIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteBrevoIntegrationCampaign',
  query: `mutation DeleteBrevoIntegrationCampaign($deleteBrevoIntegrationCampaignId: Int!) {
    deleteBrevoIntegrationCampaign(id: $deleteBrevoIntegrationCampaignId)
  }`,
  variables: {
    deleteBrevoIntegrationCampaignId: id,
  },
});
