import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  GET_INVITATIONS_REQUEST,
  GET_INVITATIONS_SUCCESS,
  GET_INVITATIONS_FAILURE,
  CREATE_INVITATION_REQUEST,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_FAILURE,
  DELETE_INVITATION_REQUEST,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_USERS_REQUEST,
  GET_ACCOUNTS_USERS_SUCCESS,
  GET_ACCOUNTS_USERS_FAILURE,
  CHANGE_ACCOUNT_REQUEST,
} from './constants';

import { IAccount, IInvitation, IAccountUser } from './reducer';

import fetch from '@utils/fetch';
import store from '@utils/store';
import { setAuthUser } from '@connectors/auth/actions';

export interface ITokenResponse {
  token: string;
  refreshToken: string;
  tokenExpireDate: Date;
}

function* getInvitationsSaga() {
  try {
    const data: IInvitation = yield call(fetch, `api/Invitation`, {
      method: 'GET',
    });
    if (data) {
      yield put({ type: GET_INVITATIONS_SUCCESS, payload: data });
    } else {
      yield put({ type: GET_INVITATIONS_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_INVITATIONS_FAILURE });
  }
}

function* createInvitationSaga({ payload }: ReduxAction) {
  try {
    const data: IInvitation = yield call(fetch, `api/Invitation`, {
      method: 'POST',
      data: {
        email: payload.email,
        role: {
          roleId: payload.roleId,
          campaignIds: payload.campaignIds,
        },
      },
    });

    if (data) {
      yield put({ type: CREATE_INVITATION_SUCCESS, payload: data });
    } else {
      throw new Error('Invitation not created');
    }
  } catch (error) {
    yield put({ type: CREATE_INVITATION_FAILURE });
  }
}

function* deleteInvitationSaga({ payload }: ReduxAction) {
  const invitationId = payload;
  try {
    yield call(fetch, `api/Invitation/${invitationId}`, {
      method: 'DELETE',
    });

    yield put({ type: DELETE_INVITATION_SUCCESS, payload: invitationId });
  } catch (error) {
    yield put({ type: DELETE_INVITATION_FAILURE });
  }
}

function* getAccountsSaga() {
  try {
    const data: IAccount[] = yield call(fetch, `api/Account/UsersAccounts`, {
      method: 'GET',
    });

    //We'll use the user to set default account to user's account
    const user = store.get('user');

    if (data) {
      yield put({
        type: GET_ACCOUNTS_SUCCESS,
        payload: { user, accounts: data },
      });
    } else {
      throw new Error(`Couldn't get accounts`);
    }
  } catch (error) {
    yield put({ type: GET_ACCOUNTS_FAILURE });
  }
}

function* getAccountsUsersSaga() {
  try {
    const data: IAccountUser[] = yield call(
      fetch,
      `api/Account/AccountsUsers`,
      {
        method: 'GET',
      },
    );
    if (data) {
      yield put({ type: GET_ACCOUNTS_USERS_SUCCESS, payload: data });
    } else {
      throw new Error(`Couldn't get subaccounts.`);
    }
  } catch (error) {
    yield put({ type: GET_ACCOUNTS_USERS_FAILURE });
  }
}

function* changeAccountSaga(action: ReduxAction) {
  const { payload, onSuccess } = action;

  yield put(
    setAuthUser({
      token: payload.accessToken,
      refreshToken: payload.refreshToken,
    }),
  );
  onSuccess();
}

export default function* sagas() {
  yield takeLatest(GET_INVITATIONS_REQUEST, getInvitationsSaga);
  yield takeLatest(CREATE_INVITATION_REQUEST, createInvitationSaga);
  yield takeLatest(DELETE_INVITATION_REQUEST, deleteInvitationSaga);
  yield takeLatest(GET_ACCOUNTS_REQUEST, getAccountsSaga);
  yield takeLatest(GET_ACCOUNTS_USERS_REQUEST, getAccountsUsersSaga);
  yield takeLatest(CHANGE_ACCOUNT_REQUEST, changeAccountSaga);
}
