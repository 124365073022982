import FloatingDefaultIcon from '@assets/icons/floating-default-icon.svg';
import FloatingInlineIcon from '@assets/icons/floating-inline-icon.svg';

import FullLeftIcon from '@assets/icons/full-left-direction.svg';
import FullRightIcon from '@assets/icons/full-right-direction.svg';
import FullTopIcon from '@assets/icons/full-top.svg';
import FullBottomIcon from '@assets/icons/full-bottom.svg';
import FullContentIcon from '@assets/icons/full-content.svg';
import FullImageIcon from '@assets/icons/full-image.svg';
import FullVideoIcon from '@assets/icons/full-video.svg';
import FullThreeBlock from '@assets/icons/full-three-block.svg';

import LightTopToBottomIcon from '@assets/icons/light-top-to-bottom.svg';
import LightContentIcon from '@assets/icons/light-just-content.svg';
import LightLeftIcon from '@assets/icons/light-left-direction.svg';
import LightRightIcon from '@assets/icons/light-right-direction.svg';
import LightTopIcon from '@assets/icons/light-top-direction.svg';
import LightBottomIcon from '@assets/icons/light-bottom-direction.svg';
import LightImageIcon from '@assets/icons/light-full-image.svg';
import LightVideoIcon from '@assets/icons/light-full-video.svg';
import LightThreeColumn from '@assets/icons/light-three-column.svg';
import TeaserRightDirection from '@assets/icons/teaser-right-direction.svg';
import TeaserLeftDirection from '@assets/icons/teaser-left-direction.svg';
import TeaserBottomDirection from '@assets/icons/teaser-bottom-direction.svg';
import TeaserTopDirection from '@assets/icons/teaser-top-direction.svg';
import TeaserOnlyText from '@assets/icons/teaser-only-text.svg';
import TeaserOnlyImage from '@assets/icons/teaser-only-image.svg';
import TeaserCircleText from '@assets/icons/teaser-circle-text.svg';
import TeaserCircleImage from '@assets/icons/teaser-circle-image.svg';
import TeaserCornerImage from '@assets/icons/teaser-corner-image.svg';
import { LayoutType } from '@components/AddNewStepSidebar/PopupLayout/types';
import { PopupTypes } from '@components/AddNewStepSidebar/types';
import DeviceTypes from '@enums/DeviceTypes';

const Layouts: LayoutType = {
  light: [
    {
      key: 'light-right-image',
      icon: <LightRightIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '50% 50%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '760px',
                minHeight: '380px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-left-image',
      icon: <LightLeftIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '50% 50%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '760px',
                minHeight: '380px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-top-image',
      icon: <LightTopIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '420px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '70px',
                    paddingBottom: '70px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-bottom-image',
      icon: <LightBottomIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '420px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '70px',
                    paddingBottom: '70px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-top-to-bottom-image',
      icon: <LightTopToBottomIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'block',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '30px',
                paddingBottom: '30px',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '20px',
                    marginBottom: '20px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'headline',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-19i1d2k13mzk',
                  className: 'text-cn0dmpkb62w0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                ],
                id: 'cn0dxzwun9c0',
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cn0dmr5c1i80',
                  className: 'text-cn0dmr5c3bs0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                ],
                id: 'cn0dmr5c5a00',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: 'max-content', //Don't delete
                    justifyContent: 'flex-start',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'block',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '50px',
                paddingBottom: '50px',
                paddingLeft: '50px',
                paddingRight: '50px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '420px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '20px',
                    marginBottom: '20px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'headline',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-19i1d2k13mzk',
                  className: 'text-cn0dmpkb62w0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                ],
                id: 'cn0dxzwun9c0',
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cn0dmr5c1i80',
                  className: 'text-cn0dmr5c3bs0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                ],
                id: 'cn0dmr5c5a00',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: 'max-content', //Don't delete
                    justifyContent: 'flex-start',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '15px',
                    marginRight: '15px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-full-content',
      icon: <LightContentIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '30px',
                paddingBottom: '30px',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
                alignContent: 'safe center',
              },
            },
            childNodes: [
              {
                nodeName: 'headline',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-19i1d2k13mzk',
                  className: 'text-cn0dmpkb62w0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                ],
                id: 'cn0dxzwun9c0',
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cn0dmr5c1i80',
                  className: 'text-cn0dmr5c3bs0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                ],
                id: 'cn0dmr5c5a00',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: 'max-content', //Don't delete
                    justifyContent: 'flex-start',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: 'auto',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    color: '#ffffff',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '90px',
                paddingBottom: '90px',
                paddingLeft: '50px',
                paddingRight: '50px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '420px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                minHeight: '420px',
                verticalAlign: 'middle',
                alignContent: 'safe center',
              },
            },
            childNodes: [
              {
                nodeName: 'headline',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-19i1d2k13mzk',
                  className: 'text-cn0dmpkb62w0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                ],
                id: 'cn0dxzwun9c0',
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cn0dmr5c1i80',
                  className: 'text-cn0dmr5c3bs0',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                ],
                id: 'cn0dmr5c5a00',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: 'max-content', //Don't delete
                    justifyContent: 'flex-start',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-full-image',
      icon: <LightImageIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'block',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '70%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '15px',
                    marginRight: '15px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'block',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '70%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '15px',
                    marginRight: '15px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-full-video',
      icon: <LightVideoIcon />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'block',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '70%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'ct77dueiers0',
                nodeName: 'youtube',
                attributes: {
                  url: 'https://youtu.be/iAfZQbS8ay0',
                  autoPlay: false,
                  isCover: true,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                    overflow: 'hidden',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'youtube-ct77dueiers0',
                  className: 'youtube-p_ct77dueiers0',
                },
                childNodes: [],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '15px',
                    marginRight: '15px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'block',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '70%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'ct77dueiers0',
                nodeName: 'youtube',
                attributes: {
                  url: 'https://youtu.be/iAfZQbS8ay0',
                  autoPlay: false,
                  isCover: true,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                    overflow: 'hidden',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'youtube-ct77dueiers0',
                  className: 'youtube-p_ct77dueiers0',
                },
                childNodes: [],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '15px',
                    marginRight: '15px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'light-three-column',
      icon: <LightThreeColumn />,
      layout: {
        title: 'New Step',
        type: 'LIGHT',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '85%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-d1l4h4dxrjs0',
                  className: 'block-p_d1l4h4dxrjs0',
                },
                childNodes: [
                  {
                    id: 'd1l4h4dxkew0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '32px',
                        marginBottom: '0px',
                        transform: 'scale(100%)',
                        position: 'relative',
                        left: '0%',
                        top: '0%',
                      },
                      id: 'image-d1l4h4dxkew0',
                      className: 'image-p_d1l4h4dxkew0',
                    },
                  },
                  {
                    id: 'd1l4h4dxdk00',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '0px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4h4dxdk00',
                      className: 'headline-p_d1l4h4dxdk00',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxn000',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '0px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4h4dxn000',
                      className: 'text-p_d1l4h4dxn000',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxe000',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4h4dxe000',
                      className: 'button-wrapper-p_d1l4h4dxe000',
                    },
                    childNodes: [
                      {
                        id: '1ayig41e69mo',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '32px',
                            marginRight: '32px',
                            marginTop: '16px',
                            marginBottom: '32px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-1ayig41e69mo',
                          className: 'button-p_1ayig41e69mo',
                        },
                        childNodes: [
                          '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum</span></p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4h4dxrjs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-d1l4h4dxd400',
                  className: 'block-p_d1l4h4dxd400',
                },
                childNodes: [
                  {
                    id: 'd1l4h4dxn4o0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '32px',
                        marginBottom: '0px',
                        paddingLeft: '0px',
                      },
                      id: 'image-d1l4h4dxn4o0',
                      className: 'image-p_d1l4h4dxn4o0',
                    },
                  },
                  {
                    id: '4p1uesxt9t8',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '0px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                      },
                      id: 'headline-4p1uesxt9t8',
                      className: 'headline-p_4p1uesxt9t8',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxm940',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '0px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4h4dxm940',
                      className: 'text-p_d1l4h4dxm940',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxemw0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4h4dxemw0',
                      className: 'button-wrapper-p_d1l4h4dxemw0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4h4dxm000',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '32px',
                            marginRight: '32px',
                            marginTop: '16px',
                            marginBottom: '32px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4h4dxm000',
                          className: 'button-p_d1l4h4dxm000',
                        },
                        childNodes: [
                          '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum</span></p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4h4dxd400',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-d1l4h4dxrj40',
                  className: 'block-p_d1l4h4dxrj40',
                },
                childNodes: [
                  {
                    id: 'd1l4h4dxots0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '32px',
                        marginBottom: '0px',
                      },
                      id: 'image-d1l4h4dxots0',
                      className: 'image-p_d1l4h4dxots0',
                    },
                  },
                  {
                    id: 'd1l4h4dxkco0',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '0px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4h4dxkco0',
                      className: 'headline-p_d1l4h4dxkco0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxcxk0',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '0px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                        paddingBottom: '0px',
                      },
                      showOnHover: false,
                      id: 'text-d1l4h4dxcxk0',
                      className: 'text-p_d1l4h4dxcxk0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxteo0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4h4dxteo0',
                      className: 'button-wrapper-p_d1l4h4dxteo0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4h4dxjk00',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '32px',
                            marginRight: '32px',
                            marginTop: '16px',
                            marginBottom: '32px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4h4dxjk00',
                          className: 'button-p_d1l4h4dxjk00',
                        },
                        childNodes: [
                          '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum</span></p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4h4dxrj40',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                  id: 'close-button-d1l4h4dy3jc0',
                  className: 'close-button-p_d1l4h4dy3jc0',
                },
                childNodes: [],
                id: 'd1l4h4dy3jc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: 'repeat(3, 1fr)',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '760px',
                minHeight: '380px',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  id: 'block-d1l4h4dxrjs0',
                  className: 'block-p_d1l4h4dxrjs0',
                },
                childNodes: [
                  {
                    id: 'd1l4h4dxkew0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '32px',
                        marginRight: '16px',
                        marginTop: '32px',
                        marginBottom: '16px',
                        transform: 'scale(100%)',
                        position: 'relative',
                        left: '0%',
                        top: '0%',
                      },
                      id: 'image-d1l4h4dxkew0',
                      className: 'image-p_d1l4h4dxkew0',
                    },
                  },
                  {
                    id: 'd1l4h4dxdk00',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '16px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4h4dxdk00',
                      className: 'headline-p_d1l4h4dxdk00',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxn000',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '32px',
                        marginRight: '16px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4h4dxn000',
                      className: 'text-p_d1l4h4dxn000',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxe000',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4h4dxe000',
                      className: 'button-wrapper-p_d1l4h4dxe000',
                    },
                    childNodes: [
                      {
                        id: '1ayig41e69mo',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '32px',
                            marginRight: '16px',
                            marginTop: '16px',
                            marginBottom: '32px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-1ayig41e69mo',
                          className: 'button-p_1ayig41e69mo',
                        },
                        childNodes: [
                          '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum</span></p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4h4dxrjs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  id: 'block-d1l4h4dxd400',
                  className: 'block-p_d1l4h4dxd400',
                },
                childNodes: [
                  {
                    id: 'd1l4h4dxn4o0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '24px',
                        marginRight: '24px',
                        marginTop: '32px',
                        marginBottom: '16px',
                        paddingLeft: '0px',
                      },
                      id: 'image-d1l4h4dxn4o0',
                      className: 'image-p_d1l4h4dxn4o0',
                    },
                  },
                  {
                    id: '4p1uesxt9t8',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '24px',
                        marginRight: '24px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                      },
                      id: 'headline-4p1uesxt9t8',
                      className: 'headline-p_4p1uesxt9t8',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxm940',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '24px',
                        marginRight: '24px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4h4dxm940',
                      className: 'text-p_d1l4h4dxm940',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxemw0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4h4dxemw0',
                      className: 'button-wrapper-p_d1l4h4dxemw0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4h4dxm000',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '24px',
                            marginRight: '24px',
                            marginTop: '16px',
                            marginBottom: '32px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4h4dxm000',
                          className: 'button-p_d1l4h4dxm000',
                        },
                        childNodes: [
                          '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum</span></p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4h4dxd400',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  id: 'block-d1l4h4dxrj40',
                  className: 'block-p_d1l4h4dxrj40',
                },
                childNodes: [
                  {
                    id: 'd1l4h4dxots0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '16px',
                        marginRight: '32px',
                        marginTop: '32px',
                        marginBottom: '16px',
                      },
                      id: 'image-d1l4h4dxots0',
                      className: 'image-p_d1l4h4dxots0',
                    },
                  },
                  {
                    id: 'd1l4h4dxkco0',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '16px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4h4dxkco0',
                      className: 'headline-p_d1l4h4dxkco0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxcxk0',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '16px',
                        marginRight: '32px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                        paddingBottom: '0px',
                      },
                      showOnHover: false,
                      id: 'text-d1l4h4dxcxk0',
                      className: 'text-p_d1l4h4dxcxk0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4h4dxteo0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4h4dxteo0',
                      className: 'button-wrapper-p_d1l4h4dxteo0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4h4dxjk00',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '16px',
                            marginRight: '32px',
                            marginTop: '16px',
                            marginBottom: '32px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4h4dxjk00',
                          className: 'button-p_d1l4h4dxjk00',
                        },
                        childNodes: [
                          '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum</span></p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4h4dxrj40',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                  id: 'close-button-d1l4h4dy3jc0',
                  className: 'close-button-p_d1l4h4dy3jc0',
                },
                childNodes: [],
                id: 'd1l4h4dy3jc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
  ],
  fullscreen: [
    {
      key: 'fullscreen-right-image',
      icon: <FullRightIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: 'minmax(200px, 50%) 1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '50% 50%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '520px',
                    overflow: 'visible',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-left-image',
      icon: <FullLeftIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: 'minmax(200px, 50%) 1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',

                    display: 'grid',
                    gridTemplateColumns: '100%',

                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',

                    display: 'grid',
                    gridTemplateColumns: '100%',

                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '50% 50%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '520px',
                    overflow: 'visible',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-top-image',
      icon: <FullTopIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: 'minmax(200px, 50%) 1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: 'fit-content',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: 'minmax(200px, 50%) 1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: 'fit-content',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '520px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    overflow: 'visible',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-bottom-image',
      icon: <FullBottomIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: '1fr minmax(200px, 50%)',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: '0px',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: 'fit-content',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: '1fr minmax(200px, 50%)',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '520px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    overflow: 'visible',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: 'fit-content',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-full-content',
      icon: <FullContentIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    alignContent: 'center',
                    marginTop: '0px',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: 'auto',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: '100%',

                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '90px',
                    paddingBottom: '90px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '520px',
                    overflow: 'visible',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dllh37ao0',
                  className: 'block-19i1cyjphvy8',
                },
                childNodes: [
                  {
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-19i1d2k13mzk',
                      className: 'text-cn0dmpkb62w0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet</p>',
                    ],
                    id: 'cn0dxzwun9c0',
                  },
                  {
                    nodeName: 'text',
                    attributes: {
                      style: {
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'text-cn0dmr5c1i80',
                      className: 'text-cn0dmr5c3bs0',
                    },
                    childNodes: [
                      '<p style="text-align: left;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                    ],
                    id: 'cn0dmr5c5a00',
                  },
                  {
                    id: '19i1d31yb3r1',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: 'max-content', //Don't delete
                        justifyContent: 'flex-start',
                      },
                    },
                    childNodes: [
                      {
                        id: '19i1d31yb3r4',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            paddingTop: '9px',
                            paddingBottom: '9px',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'inherit',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-cn0dmujj4rs0',
                          className: 'button-19i1d31yc3q8',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'cn0dllh4zzs0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-full-image',
      icon: <FullImageIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'flex',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: '1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: 'fit-content',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'flex',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: '1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'cq5bjhah05xc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        type: undefined,
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: 'fit-content',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-full-video',
      icon: <FullVideoIcon />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'flex',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: '1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'ct77dueiers0',
                    nodeName: 'youtube',
                    attributes: {
                      url: 'https://youtu.be/iAfZQbS8ay0',
                      autoPlay: false,
                      isCover: true,
                      style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'auto',
                        overflow: 'hidden',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'youtube-ct77dueiers0',
                      className: 'youtube-p_ct77dueiers0',
                    },
                    childNodes: [],
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'flex',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: '1fr',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    alignContent: 'center',
                  },
                  id: 'block-cn0dlm4pd8w0',
                  className: 'block-cn0dlm4phh40',
                },
                childNodes: [
                  {
                    id: 'ct77dueiers0',
                    nodeName: 'youtube',
                    attributes: {
                      url: 'https://youtu.be/iAfZQbS8ay0',
                      autoPlay: false,
                      isCover: true,
                      style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'auto',
                        overflow: 'hidden',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'youtube-ct77dueiers0',
                      className: 'youtube-p_ct77dueiers0',
                    },
                    childNodes: [],
                  },
                ],
                id: 'cn0dlm4pfk00',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'fullscreen-three-block',
      icon: <FullThreeBlock />,
      layout: {
        title: 'New Step',
        type: 'FULLSCREEN',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                gridTemplateRows: 'repeat(3, 1fr)',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'auto',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-d1l4ke5bbj40',
                  className: 'block-p_d1l4ke5bbj40',
                },
                childNodes: [
                  {
                    id: 'd1l4ke5baa00',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '25px',
                      },
                      id: 'image-d1l4ke5baa00',
                      className: 'image-p_d1l4ke5baa00',
                    },
                  },
                  {
                    id: 'd1l4ke5b3c80',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4ke5b3c80',
                      className: 'headline-p_d1l4ke5b3c80',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5az6o0',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4ke5az6o0',
                      className: 'text-p_d1l4ke5az6o0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span><span style="background-color: rgb(255, 255, 255);"> </span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5b4mg0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4ke5b4mg0',
                      className: 'button-wrapper-p_d1l4ke5b4mg0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4ke5baxk0',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '12px',
                            marginRight: '12px',
                            marginTop: '0px',
                            marginBottom: '24px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4ke5baxk0',
                          className: 'button-p_d1l4ke5baxk0',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4ke5bbj40',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-d1l4ke5b7oo0',
                  className: 'block-p_d1l4ke5b7oo0',
                },
                childNodes: [
                  {
                    id: 'd1l4ke5b1mw0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '25px',
                      },
                      id: 'image-d1l4ke5b1mw0',
                      className: 'image-p_d1l4ke5b1mw0',
                    },
                  },
                  {
                    id: 'd1l4ke5b3y00',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4ke5b3y00',
                      className: 'headline-p_d1l4ke5b3y00',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5baxc0',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4ke5baxc0',
                      className: 'text-p_d1l4ke5baxc0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span><span style="background-color: rgb(255, 255, 255);"> </span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5bfcg0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4ke5bfcg0',
                      className: 'button-wrapper-p_d1l4ke5bfcg0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4ke5b0iw0',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '12px',
                            marginRight: '12px',
                            marginTop: '0px',
                            marginBottom: '24px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4ke5b0iw0',
                          className: 'button-p_d1l4ke5b0iw0',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4ke5b7oo0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: '100%',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                  },
                  id: 'block-d1l4ke5ayqw0',
                  className: 'block-p_d1l4ke5ayqw0',
                },
                childNodes: [
                  {
                    id: 'd1l4ke5bc8o0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '25px',
                      },
                      id: 'image-d1l4ke5bc8o0',
                      className: 'image-p_d1l4ke5bc8o0',
                    },
                  },
                  {
                    id: '1ayigftbxark',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-1ayigftbxark',
                      className: 'headline-p_1ayigftbxark',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: '1ayigftbx1ts',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                        paddingLeft: '0px',
                      },
                      showOnHover: false,
                      id: 'text-1ayigftbx1ts',
                      className: 'text-p_1ayigftbx1ts',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span><span style="background-color: rgb(255, 255, 255);"> </span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5b5q80',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4ke5b5q80',
                      className: 'button-wrapper-p_d1l4ke5b5q80',
                    },
                    childNodes: [
                      {
                        id: 'd1l4ke5b93c0',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '12px',
                            marginRight: '12px',
                            marginTop: '0px',
                            marginBottom: '24px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4ke5b93c0',
                          className: 'button-p_d1l4ke5b93c0',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4ke5ayqw0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '20px',
                    marginRight: '20px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                  id: 'close-button-d1l4ke5bf2w0',
                  className: 'close-button-p_d1l4ke5bf2w0',
                },
                childNodes: [],
                id: 'd1l4ke5bf2w0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: 'repeat(3, 1fr)',
                paddingTop: '180px',
                paddingBottom: '180px',
                paddingLeft: '180px',
                paddingRight: '180px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: '100%',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    justifyContent: 'center',
                  },
                  id: 'block-d1l4ke5bbj40',
                  className: 'block-p_d1l4ke5bbj40',
                },
                childNodes: [
                  {
                    id: 'd1l4ke5baa00',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '25px',
                      },
                      id: 'image-d1l4ke5baa00',
                      className: 'image-p_d1l4ke5baa00',
                    },
                  },
                  {
                    id: 'd1l4ke5b3c80',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4ke5b3c80',
                      className: 'headline-p_d1l4ke5b3c80',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5az6o0',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4ke5az6o0',
                      className: 'text-p_d1l4ke5az6o0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span><span style="background-color: rgb(255, 255, 255);"> </span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5b4mg0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4ke5b4mg0',
                      className: 'button-wrapper-p_d1l4ke5b4mg0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4ke5baxk0',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '12px',
                            marginRight: '12px',
                            marginTop: '0px',
                            marginBottom: '24px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4ke5baxk0',
                          className: 'button-p_d1l4ke5baxk0',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4ke5bbj40',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    justifyContent: 'center',
                  },
                  id: 'block-d1l4ke5b7oo0',
                  className: 'block-p_d1l4ke5b7oo0',
                },
                childNodes: [
                  {
                    id: 'd1l4ke5b1mw0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '25px',
                      },
                      id: 'image-d1l4ke5b1mw0',
                      className: 'image-p_d1l4ke5b1mw0',
                    },
                  },
                  {
                    id: 'd1l4ke5b3y00',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-d1l4ke5b3y00',
                      className: 'headline-p_d1l4ke5b3y00',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5baxc0',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      showOnHover: false,
                      id: 'text-d1l4ke5baxc0',
                      className: 'text-p_d1l4ke5baxc0',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span><span style="background-color: rgb(255, 255, 255);"> </span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5bfcg0',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4ke5bfcg0',
                      className: 'button-wrapper-p_d1l4ke5bfcg0',
                    },
                    childNodes: [
                      {
                        id: 'd1l4ke5b0iw0',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '12px',
                            marginRight: '12px',
                            marginTop: '0px',
                            marginBottom: '24px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4ke5b0iw0',
                          className: 'button-p_d1l4ke5b0iw0',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4ke5b7oo0',
              },
              {
                nodeName: 'block',
                attributes: {
                  style: {
                    position: 'relative',
                    display: 'grid',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    overflow: 'visible',
                    marginBottom: '0px',
                    justifyContent: 'center',
                  },
                  id: 'block-d1l4ke5ayqw0',
                  className: 'block-p_d1l4ke5ayqw0',
                },
                childNodes: [
                  {
                    id: 'd1l4ke5bc8o0',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '25px',
                      },
                      id: 'image-d1l4ke5bc8o0',
                      className: 'image-p_d1l4ke5bc8o0',
                    },
                  },
                  {
                    id: '1ayigftbxark',
                    nodeName: 'headline',
                    attributes: {
                      style: {
                        lineHeight: '120%',
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                      },
                      id: 'headline-1ayigftbxark',
                      className: 'headline-p_1ayigftbxark',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet</span></p>',
                    ],
                  },
                  {
                    id: '1ayigftbx1ts',
                    nodeName: 'text',
                    attributes: {
                      style: {
                        color: '#000000',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '0px',
                        marginBottom: '24px',
                        width: '100%',
                        height: 'fit-content',
                        wordBreak: 'break-word',
                        paddingLeft: '0px',
                      },
                      showOnHover: false,
                      id: 'text-1ayigftbx1ts',
                      className: 'text-p_1ayigftbx1ts',
                    },
                    childNodes: [
                      '<p style="text-align: center;"><span style="background-color: rgb(248, 249, 251);">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span><span style="background-color: rgb(255, 255, 255);"> </span></p>',
                    ],
                  },
                  {
                    id: 'd1l4ke5b5q80',
                    nodeName: 'button-wrapper',
                    attributes: {
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        gridTemplateColumns: '1fr',
                        justifyContent: 'center',
                      },
                      id: 'button-wrapper-d1l4ke5b5q80',
                      className: 'button-wrapper-p_d1l4ke5b5q80',
                    },
                    childNodes: [
                      {
                        id: 'd1l4ke5b93c0',
                        nodeName: 'button',
                        attributes: {
                          type: 'primary',
                          action: {
                            type: 'close',
                            value: '',
                            pageName: '',
                            countAsInteraction: true,
                          },
                          style: {
                            marginLeft: '12px',
                            marginRight: '12px',
                            marginTop: '0px',
                            marginBottom: '24px',
                            width: '100%',
                            height: 'fit-content',
                          },
                          id: 'button-d1l4ke5b93c0',
                          className: 'button-p_d1l4ke5b93c0',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lorem ipsum</p>',
                        ],
                      },
                    ],
                  },
                ],
                id: 'd1l4ke5ayqw0',
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                  id: 'close-button-d1l4ke5bf2w0',
                  className: 'close-button-p_d1l4ke5bf2w0',
                },
                childNodes: [],
                id: 'd1l4ke5bf2w0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'center-center',
          customCss: '',
          size: 'md',
        },
      },
    },
  ],
  floating: [
    {
      key: 'inline-floating',
      icon: <FloatingDefaultIcon />,
      tooltipText: 'Inline',
      layout: {
        title: 'New Step',
        type: 'FLOATING',
        isInline: true,
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '40px',
                paddingBottom: '40px',
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '0px',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
                alignContent: 'center',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cndztsm8vog0',
                  className: 'text-19jeds281leo',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet.</p>',
                ],
                id: 'cndztsmc3c80',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: '1fr', //Don't delete
                    justifyContent: 'center',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns:
                  'fit-content(25%) fit-content(25%) fit-content(25%) fit-content(25%)',
                gap: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginTop: '0px',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
                alignContent: 'center',
                placeContent: 'center',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cndztsm8vog0',
                  className: 'text-19jeds281leo',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet.</p>',
                ],
                id: 'cndztsmc3c80',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: '1fr', //Don't delete
                    justifyContent: 'center',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'top-center',
          customCss: '',
          size: 'md',
        },
      },
    },
    {
      key: 'default-floating',
      tooltipText: 'Floating',
      icon: <FloatingInlineIcon />,
      layout: {
        title: 'New Step',
        type: 'FLOATING',
        content: {
          mobile: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns: '100%',
                paddingTop: '40px',
                paddingBottom: '40px',
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '0px',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
                alignContent: 'center',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cndztsm8vog0',
                  className: 'text-19jeds281leo',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet.</p>',
                ],
                id: 'cndztsmc3c80',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: '1fr', //Don't delete
                    justifyContent: 'center',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
          desktop: {
            id: 'canvas',
            nodeName: 'block',
            attributes: {
              style: {
                display: 'grid',
                position: 'relative',
                gridTemplateColumns:
                  'fit-content(25%) fit-content(25%) fit-content(25%) fit-content(25%)',
                gap: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginTop: '0px',
                marginBottom: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                width: '100%',
                overflow: 'hidden',
                height: 'auto',
                verticalAlign: 'middle',
                alignContent: 'center',
                placeContent: 'center',
              },
            },
            childNodes: [
              {
                id: 'cq5bjhah05xc',
                nodeName: 'image',
                childNodes: [],
                attributes: {
                  src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
                  alt: 'Popup Image',
                  position: 'center-center',
                  imageLinking: false,
                  action: {
                    type: undefined,
                    value: '',
                    pageName: '',
                    countAsInteraction: false,
                  },
                  style: {
                    display: 'flex',
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                  id: 'image-cq5bjhah05xc',
                  className: 'image-p_cq5bjhah05xc',
                },
              },
              {
                nodeName: 'text',
                attributes: {
                  style: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    height: 'fit-content',
                    wordBreak: 'break-word',
                  },
                  id: 'text-cndztsm8vog0',
                  className: 'text-19jeds281leo',
                },
                childNodes: [
                  '<p style="text-align: left;">Lorem ipsum dolor sit amet.</p>',
                ],
                id: 'cndztsmc3c80',
              },
              {
                id: '19i1d31yb3r1',
                nodeName: 'button-wrapper',
                attributes: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    gridTemplateColumns: '1fr', //Don't delete
                    justifyContent: 'center',
                  },
                },
                childNodes: [
                  {
                    id: '19i1d31yb3r4',
                    nodeName: 'button',
                    attributes: {
                      type: 'primary',
                      action: {
                        type: 'close',
                        value: '',
                        pageName: '',
                        countAsInteraction: true,
                      },
                      style: {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        textAlign: 'inherit',
                        width: '100%',
                        height: 'fit-content',
                      },
                      id: 'button-cn0dmujj4rs0',
                      className: 'button-19i1d31yc3q8',
                    },
                    childNodes: [
                      '<p style="text-align: center;">Lorem ipsum</p>',
                    ],
                  },
                ],
              },
              {
                nodeName: 'close-button',
                attributes: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    right: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '12px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderBottomRightRadius: '50px',
                    strokeWidth: '2px',
                    transform: 'none',
                  },
                },
                childNodes: [],
                id: 'cm9w85il0fc0',
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'top-center',
          customCss: '',
          size: 'md',
        },
      },
    },
  ],
  teaser: [
    {
      key: 'teaser-right-direction',
      icon: <TeaserRightDirection />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '260px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr 80px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '260px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-luo2e4ni58tl',
                          className: 'text-87h8me08r9z1',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'r6h0mj2fprce',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-nkkflw2f1aza',
                          className: 'subtext-s3hooa6sx75i',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'khztl6pff9mp',
                      },
                    ],
                    id: 'bfe8vzsyku1i',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '390px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr 80px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '390px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-luo2e4ni58tl',
                          className: 'text-87h8me08r9z1',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'r6h0mj2fprce',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-nkkflw2f1aza',
                          className: 'subtext-s3hooa6sx75i',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'khztl6pff9mp',
                      },
                    ],
                    id: 'bfe8vzsyku1i',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-right-direction-centered',
      icon: <TeaserRightDirection />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '200px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: 'minmax(60px, auto)',
                    gridTemplateRows: '80px 1fr',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '200px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',
                        transform: 'rotate(-90deg)',
                        width: '0px',
                        minWidth: '100%',
                        aspectRatio: '1/1',
                        height: 'max-content',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',

                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        letterSpacing: 'initial',
                        maxHeight: '260px',
                        minHeight: '100px',
                        transform: 'scale(-1, -1)',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-luo2e4ni58tl',
                          className: 'text-87h8me08r9z1',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'r6h0mj2fprce',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-nkkflw2f1aza',
                          className: 'subtext-s3hooa6sx75i',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'khztl6pff9mp',
                      },
                    ],
                    id: 'bfe8vzsyku1i',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '390px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: 'minmax(60px, auto)',
                    gridTemplateRows: '80px 1fr',

                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '390px',
                    width: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',

                        transform: 'rotate(-90deg)',
                        width: '0px',
                        minWidth: '100%',

                        aspectRatio: '1/1',
                        height: 'max-content',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',

                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        letterSpacing: 'initial',
                        maxHeight: '290px',
                        minHeight: '100px',
                        transform: 'scale(-1, -1)',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-luo2e4ni58tl',
                          className: 'text-87h8me08r9z1',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'r6h0mj2fprce',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-nkkflw2f1aza',
                          className: 'subtext-s3hooa6sx75i',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'khztl6pff9mp',
                      },
                    ],
                    id: 'bfe8vzsyku1i',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-left-direction',
      icon: <TeaserLeftDirection />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '290px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '80px 1fr',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '290px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'cn0dlm4pfk01',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'cndztsmc3c81',
                      },
                    ],
                    id: 'bgu42mohnuq7',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '390px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '80px 1fr',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '390px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'cn0dlm4pfk01',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'cndztsmc3c81',
                      },
                    ],
                    id: 'bgu42mohnuq7',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-left-direction-centered',
      icon: <TeaserLeftDirection />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '200px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: 'minmax(60px, auto)',
                    gridTemplateRows: '1fr 60px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '200px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',

                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        letterSpacing: 'initial',
                        maxHeight: '260px',
                        minHeight: '100px',
                        transform: 'scale(-1, -1)',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-luo2e4ni58tl',
                          className: 'text-87h8me08r9z1',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'r6h0mj2fprce',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-nkkflw2f1aza',
                          className: 'subtext-s3hooa6sx75i',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'khztl6pff9mp',
                      },
                    ],
                    id: 'bfe8vzsyku1i',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',
                        transform: 'rotate(-90deg)',
                        width: '0px',
                        minWidth: '100%',
                        aspectRatio: '1/1',
                        height: 'max-content',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '390px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: 'minmax(60px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '390px',
                    width: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',

                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        letterSpacing: 'initial',
                        maxHeight: '290px',
                        minHeight: '100px',
                        transform: 'scale(-1, -1)',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-luo2e4ni58tl',
                          className: 'text-87h8me08r9z1',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: 'r6h0mj2fprce',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-nkkflw2f1aza',
                          className: 'subtext-s3hooa6sx75i',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: 'khztl6pff9mp',
                      },
                    ],
                    id: 'bfe8vzsyku1i',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',

                        transform: 'rotate(-90deg)',
                        width: '0px',
                        minWidth: '100%',

                        aspectRatio: '1/1',
                        height: 'max-content',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-bottom-direction',
      icon: <TeaserBottomDirection />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '200px',
                minHeight: '181px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateRows: 'minmax(66px, min-content) 1fr',
                    gridTemplateColumns: 'minmax(93px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '200px',
                    minHeight: '181px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Join the club</p>',
                        ],
                        id: 'cndztsmc3c80',
                      },
                    ],
                    id: 'fy2ei49d3nzi',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        minWidth: '100%',
                        width: '0',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: '6g5kkx2ej9oi',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '315px',
                minHeight: '181px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateRows: 'minmax(66px, min-content) 1fr',
                    gridTemplateColumns: 'minmax(93px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '315px',
                    minHeight: '181px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Join the club</p>',
                        ],
                        id: 'cndztsmc3c80',
                      },
                    ],
                    id: 'fy2ei49d3nzi',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '0',
                        minWidth: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: '6g5kkx2ej9oi',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-top-direction',
      icon: <TeaserTopDirection />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '200px',
                minHeight: '182px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateRows: '1fr minmax(66px, min-content)',
                    gridTemplateColumns: 'minmax(93px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '200px',
                    minHeight: '182px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '0',
                        minWidth: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'bottom-left',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'gml4zwh5v614',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Join the club</p>',
                        ],
                        id: 'cndztsmc3c80',
                      },
                    ],
                    id: 'rvc6wa2bhicm',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '315px',
                minHeight: '182px',
                width: 'auto',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateRows: '1fr minmax(66px, min-content)',
                    gridTemplateColumns: 'minmax(93px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '315px',
                    minHeight: '182px',
                    width: 'auto',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '0',
                        minWidth: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'bottom-left',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'gml4zwh5v614',
                  },
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Join the club</p>',
                        ],
                        id: 'cndztsmc3c80',
                      },
                    ],
                    id: 'rvc6wa2bhicm',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-only-text',
      icon: <TeaserOnlyText />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '200px',
                minHeight: '70px',
                minWidth: '200px',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr',
                    gridTemplateRows: 'minmax(70px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '200px',
                    minHeight: '70px',
                    minWidth: '200px',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: '5cp0lzkozenf',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: '2cpgqv49itzx',
                      },
                    ],
                    id: '4oyyjmzw4j0v',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '310px',
                minHeight: '70px',
                minWidth: '200px',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr',
                    gridTemplateRows: 'minmax(70px, auto)',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '310px',
                    minHeight: '70px',
                    minWidth: '200px',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: '5cp0lzkozenf',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: '2cpgqv49itzx',
                      },
                    ],
                    id: '4oyyjmzw4j0v',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-only-text-centered',
      icon: <TeaserOnlyText />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '200px',
                minHeight: '70px',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxHeight: '200px',
                    minWidth: '70px',
                    minHeight: '200px',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: 'minmax(150px, auto)',
                        paddingTop: '25px',
                        paddingBottom: '25px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        marginBottom: '0px',
                        alignContent: 'center',

                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        letterSpacing: 'initial',
                        maxHeight: '200px',
                        minHeight: '100px',
                        transform: 'scale(-1, -1)',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: '5cp0lzkozenf',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: '2cpgqv49itzx',
                      },
                    ],
                    id: '4oyyjmzw4j0v',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '310px',
                minHeight: '70px',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxHeight: '310px',
                    minWidth: '70px',
                    minHeight: '200px',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: 'minmax(150px, auto)',
                        paddingTop: '25px',
                        paddingBottom: '25px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        alignContent: 'center',

                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        letterSpacing: 'initial',
                        maxHeight: '310px',

                        minHeight: '100px',
                        transform: 'scale(-1, -1)',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;"><strong>Join the club</strong></p>',
                        ],
                        id: '5cp0lzkozenf',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: left;">Lets try</p>',
                        ],
                        id: '2cpgqv49itzx',
                      },
                    ],
                    id: '4oyyjmzw4j0v',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },

    {
      key: 'teaser-only-image',
      icon: <TeaserOnlyImage />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'flex',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '234px',
                maxHeight: '62px',
                width: '100%',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr',
                    gridTemplateRows: '70px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '234px',
                    maxHeight: '62px',
                    width: '100%',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    id: 'khygszj0rtzv',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        maxHeight: '70px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'flex',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '234px',
                maxHeight: '62px',
                width: '100%',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr',
                    gridTemplateRows: '70px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '234px',
                    maxHeight: '62px',
                    width: '100%',
                    height: 'auto',
                    verticalAlign: 'middle',
                  },
                },
                childNodes: [
                  {
                    id: 'khygszj0rtzv',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        maxHeight: '70px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-only-image-centered',
      icon: <TeaserOnlyImage />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '310px',
                minHeight: '70px',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxHeight: '310px',
                    minWidth: '70px',
                    minHeight: '200px',
                    height: 'auto',
                    verticalAlign: 'middle',
                    maxWidth: '62px',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',

                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',

                        transform: 'rotate(-90deg)',
                        scale: '3',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                maxWidth: '62px',
                minHeight: '70px',
                height: 'auto',
                verticalAlign: 'middle',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxHeight: '310px',
                    minWidth: '70px',
                    minHeight: '233px',
                    height: 'auto',
                    verticalAlign: 'middle',
                    maxWidth: '62px',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        overflow: 'hidden',
                        marginBottom: '0px',
                        alignContent: 'center',

                        transform: 'rotate(-90deg)',
                        scale: '3.34',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        id: 'cq5bjhah05xc',
                        nodeName: 'image',
                        childNodes: [],
                        attributes: {
                          src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                          alt: 'Popup Image',
                          position: 'center-center',
                          imageLinking: false,
                          action: {
                            value: '',
                            pageName: '',
                            countAsInteraction: false,
                          },
                          style: {
                            display: 'flex',
                            objectPosition: 'center center',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                          },
                          id: 'image-cq5bjhah05xc',
                          className: 'image-p_cq5bjhah05xc',
                        },
                      },
                    ],
                    id: 'jb6uibdzpnkz',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-corner-image',
      icon: <TeaserCornerImage />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '0px',
                marginLeft: '0px',
                marginRight: 'auto',
                width: '150px',
                height: '150px',
                background: 'transparent',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '150px',
                    height: '150px',
                    background: 'transparent',
                  },
                },
                childNodes: [
                  {
                    id: '59gtdigmktwc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'left center',
                        objectFit: 'cover',
                        justifyContent: 'flex-end',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '0px',
                marginLeft: '0px',
                marginRight: 'auto',
                width: '150px',
                height: '150px',
                background: 'transparent',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '150px',
                    height: '150px',
                    background: 'transparent',
                  },
                },
                childNodes: [
                  {
                    id: '59gtdigmktwc',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'left center',
                        objectFit: 'cover',
                        justifyContent: 'flex-end',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-circle-text',
      icon: <TeaserCircleText />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    minWidth: '150px',
                    minHeight: '150px',
                    maxWidth: '170px',
                    aspectRatio: '1 / 1',
                    verticalAlign: 'middle',
                    borderRadius: '50%',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '23px',
                        paddingRight: '23px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Join the club</p>',
                        ],
                        id: 'b2qp8m3kayirs',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lets try</p>',
                        ],
                        id: '5q7aexht4xla9',
                      },
                    ],
                    id: 'ggyug3jjflki',
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                display: 'grid',
                position: 'relative',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    display: 'grid',
                    position: 'relative',
                    gridTemplateColumns: '1fr',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    minWidth: '150px',
                    minHeight: '150px',
                    maxWidth: '300px',
                    aspectRatio: '1 / 1',
                    verticalAlign: 'middle',
                    borderRadius: '50%',
                  },
                },
                childNodes: [
                  {
                    nodeName: 'block',
                    attributes: {
                      style: {
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '23px',
                        paddingRight: '23px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'visible',
                        alignContent: 'center',
                      },
                      id: 'block-cn0dlm4pd8w0',
                      className: 'block-cn0dlm4phh40',
                    },
                    childNodes: [
                      {
                        nodeName: 'text',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'text-cndztsm8vog0',
                          className: 'text-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Join the club</p>',
                        ],
                        id: 'b2qp8m3kayirs',
                      },
                      {
                        nodeName: 'subtext',
                        attributes: {
                          style: {
                            marginLeft: '0px',
                            marginRight: '0px',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                            height: 'fit-content',
                            wordBreak: 'break-word',
                          },
                          id: 'subtext-cndztsm8vog0',
                          className: 'subtext-19jeds281leo',
                        },
                        childNodes: [
                          '<p style="text-align: center;">Lets try</p>',
                        ],
                        id: '5q7aexht4xla9',
                      },
                    ],
                    id: 'ggyug3jjflki',
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
    {
      key: 'teaser-circle-image',
      icon: <TeaserCircleImage />,
      layout: {
        title: 'Teaser',
        type: 'TEASER',
        content: {
          mobile: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                width: '150px',
                height: '150px',
                verticalAlign: 'middle',
                borderRadius: '50%',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '150px',
                    height: '150px',
                    verticalAlign: 'middle',
                    borderRadius: '50%',
                  },
                },
                childNodes: [
                  {
                    id: 'ebki06drj5fy',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        borderRadius: '50%',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
              },
            ],
          },
          desktop: {
            id: 'teaser',
            nodeName: 'block',
            attributes: {
              visibility: true,
              style: {
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginTop: 'auto',
                marginBottom: '20px',
                marginLeft: '20px',
                marginRight: 'auto',
                width: '150px',
                height: '150px',
                verticalAlign: 'middle',
                borderRadius: '50%',
              },
            },
            childNodes: [
              {
                id: 'teaser-container',
                nodeName: 'block',
                attributes: {
                  visibility: true,
                  style: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width: '150px',
                    height: '150px',
                    verticalAlign: 'middle',
                    borderRadius: '50%',
                  },
                },
                childNodes: [
                  {
                    id: 'ebki06drj5fy',
                    nodeName: 'image',
                    childNodes: [],
                    attributes: {
                      src: 'https://cdn.popupsmart.xyz/assets/empty.png',
                      alt: 'Popup Image',
                      position: 'center-center',
                      imageLinking: false,
                      action: {
                        value: '',
                        pageName: '',
                        countAsInteraction: false,
                      },
                      style: {
                        display: 'flex',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        borderRadius: '50%',
                      },
                      id: 'image-cq5bjhah05xc',
                      className: 'image-p_cq5bjhah05xc',
                    },
                  },
                ],
              },
            ],
          },
        },
        appearance: {
          displayEffect: {
            type: 'FADE_IN_DOWN',
          },
          backgroundOverlay: {
            show: true,
            transparency: 10,
            color: '#000',
          },
          closeWith: {
            esc: false,
            overlay: false,
          },
          position: 'bottom-left',
          customCss: '',
          size: 'md',
          showWhen: 'BEFORE_POPUP_DISPLAY_AND_AFTER_POPUP_CLOSED',
          visibility: true,
        },
      },
    },
  ],
};

export default Layouts;

const hiddenLayouts = {
  light: [],
  fullscreen: [],
  floating: [],
  teaser: [
    'teaser-right-direction-centered',
    'teaser-left-direction-centered',
    'teaser-only-text-centered',
    'teaser-only-image-centered',
  ],
};
const mobileDisabledLayouts = {
  light: ['light-right-image', 'light-left-image'],
  fullscreen: ['fullscreen-right-image', 'fullscreen-left-image'],
  floating: [],
  teaser: [],
};

export const getAvailableOptions = (type: PopupTypes, device: DeviceTypes) => {
  const allOptions = Layouts[type];
  const hiddenOptions: string[] = hiddenLayouts[type] || [];

  const filteredOptions = allOptions.filter(
    (option) => !hiddenOptions.includes(option.key),
  );
  if (device === DeviceTypes.desktop) return filteredOptions;

  const disabledOptions: string[] = mobileDisabledLayouts[type] || [];

  const options = filteredOptions.filter(
    (option) => !disabledOptions.includes(option.key),
  );

  return options;
};
