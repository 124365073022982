/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  GetStaticPropsResult,
  GetStaticPropsContext,
  GetServerSidePropsResult,
  GetServerSidePropsContext,
} from 'next';
import type { NextRouter } from 'next/router';

import { useState, useEffect } from 'react';

export const getStaticProps = (
  propsContext: GetStaticPropsContext,
): GetStaticPropsResult<any> => {
  return {
    props: {},
  };
};

export const getPublicStaticProps = (
  propsContext: GetStaticPropsContext,
): GetStaticPropsResult<any> => {
  let { props } = getStaticProps(propsContext) as any;
  props = { ...props, public: true };

  return { props };
};

export const getServerSideProps = (
  propsContext: GetServerSidePropsContext,
): GetServerSidePropsResult<any> => {
  return {
    props: {},
  };
};

export const getProtectedServerSideProps = (
  propsContext: GetServerSidePropsContext,
): GetServerSidePropsResult<any> => {
  let { props } = getServerSideProps(propsContext) as any;
  props = { ...props, protected: true };

  return { props };
};

export const useRouter = (router: NextRouter) => {
  const [isRouting, setIsRouting] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      setIsRouting(true);
    };

    const handleStop = () => {
      setIsRouting(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, []);

  return isRouting;
};
