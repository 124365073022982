import React, { FC, FocusEventHandler, useEffect, useState } from 'react';
import Select, {
  components,
  StylesConfig,
  Props,
  OptionProps,
  GroupBase,
  ValueContainerProps,
  OptionsOrGroups,
} from 'react-select';
import { FieldError } from 'react-hook-form';
import cn from 'classnames';

import styles from './Selectbox.module.scss';

import ErrorIcon from '@assets/icons/error-icon.svg';
import DisabledIcon from '@assets/icons/disabled-icon.svg';

export interface Option {
  value: string;
  label: string;
  icon?: JSX.Element;
}
export interface SelectInputProps<IsMulti extends boolean> extends Props {
  isMulti?: IsMulti;
  label?: string;
  labelTop?: string;
  error?: FieldError;
  elementSize?: 'xs' | 'sm' | 'md';
  value?: string | number | Option[] | null;
  onSelectChange?: (value: true extends IsMulti ? Option[] : Option) => void;
  menuPosition?: 'fixed' | 'absolute';
  styleType?: 'default' | 'targeting' | 'analytics' | 'segment';
  grayBackground?: boolean;
  closeMenuOnScroll?: boolean | ((event: Event) => boolean);
  isUnitSelect?: boolean;
  placeholderBlack?: boolean;
  options?: OptionsOrGroups<Option, GroupBase<Option>> | undefined;
  isTypeable?: boolean;
}

const getCustomStyle = (
  type: 'sm' | 'md',
  isUnitSelect?: boolean,
): StylesConfig => {
  return {
    menu: (base) => ({
      ...base,
      width: 'auto',
      minWidth: '100%',
      marginTop: '10px',
      marginBottom: '12px',
      borderRadius: '6px',
      zIndex: 10,
      wordBreak: 'break-all',
      ...(type === 'sm' && {
        marginTop: '8px',
      }),
    }),

    control: (base) => ({
      ...base,
      ...(isUnitSelect && {
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
      }),
    }),

    menuList: (base) => ({
      ...base,
      paddingTop: '10px',
      paddingBottom: '10px',
      borderRadius: '8px',
      border: 'none',
      ...(type === 'sm' && {
        paddingTop: '8px',
        paddingBottom: '8px',
      }),
      zIndex: 50,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#F5F7F8' : 'white',
      color: state.isSelected ? '#000' : '#717791',
      padding: '11px 15px',
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '120%',
      cursor: 'pointer',
      ...(type === 'sm' && {
        padding: '8px 12px',
        fontSize: '12px',
        lineHeight: '18px',
      }),

      ':hover': {
        backgroundColor: '#F5F7F8',
      },
    }),
  };
};

const OptionComponent: FC<OptionProps<Option>> = (props) => {
  const optionIcon = props.data.icon;
  return (
    <components.Option {...props}>
      <div
        className="flex gap-[6px]"
        style={{
          paddingLeft: '4px',
        }}
      >
        {optionIcon &&
          React.cloneElement(optionIcon, {
            color: '#777777',
          })}
        {props.label}
      </div>
    </components.Option>
  );
};
const ValueContainer: React.FC<
  ValueContainerProps<Option, false, GroupBase<Option>>
> = ({ children, ...props }) => {
  let optionIcon = null;
  const optionValue = props.selectProps.value;

  if (optionValue && 'icon' in optionValue) {
    optionIcon = optionValue.icon;
  }

  return (
    <components.ValueContainer {...props}>
      {optionIcon ? (
        <div className="flex items-center gap-[7px]">
          {optionIcon &&
            React.cloneElement(optionIcon, {
              color: '#777777',
            })}
          {children}
        </div>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

const Selectbox = <IsMulti extends boolean = false>(
  props: SelectInputProps<IsMulti>,
) => {
  const {
    placeholder = '',
    label,
    options,
    labelTop,
    id,
    name,
    value,
    onSelectChange,
    onBlur,
    onFocus,
    closeMenuOnScroll = false,
    error,
    elementSize = 'md',
    className,
    isDisabled,
    menuPosition = 'absolute',
    styleType = 'default',
    grayBackground = false,
    isUnitSelect,
    placeholderBlack,
    isTypeable = true,
    ...rest
  } = props;

  const [selectValue, setSelectValue] = useState<Option | Option[]>();
  const [IsMenuOpen, setIsMenuOpen] = useState(false);

  const onChangeInput = (
    newValue: true extends IsMulti ? Option[] : Option,
  ) => {
    setSelectValue(newValue);
    onSelectChange && onSelectChange(newValue);
  };

  const onBlurSelect: FocusEventHandler<HTMLInputElement> = (event) => {
    onBlur && onBlur(event);
  };

  const onFocusSelect: FocusEventHandler<HTMLInputElement> = (event) => {
    onFocus && onFocus(event);
  };

  useEffect(() => {
    if (value && options) {
      if (Array.isArray(value)) {
        setSelectValue(value);
      } else {
        const newValue: any = options.find(
          (option: any) => option.value === value,
        );
        setSelectValue(newValue);
      }
    }
    if (value === null) {
      setSelectValue(undefined);
    }
  }, [value, options]);

  const handleCloseMenuOnScroll = () => {
    setIsMenuOpen(false);
  };

  return (
    <div
      className={cn(styles.selectbox_wrapper, className, {
        [styles.label_select]: label,
        [styles.disabled]: isDisabled,
        [styles.small]: elementSize === 'sm',
        [styles.xsmall]: elementSize === 'xs',
        [styles.placeholderBlack]: placeholderBlack,
        [styles.targeting_style]: styleType === 'targeting',
        [styles.analytics_style]: styleType === 'analytics',
        [styles.segment]: styleType === 'segment',
        [styles.error]: !!error,
        [styles.grayBackground]: grayBackground,
        [styles.isUnitSelect]: isUnitSelect,
        [styles.isTypeable]: !isTypeable,
        // necessary for unit select
        [styles.default]: (selectValue as Option)?.value === 'auto',
      })}
    >
      {!!labelTop && <p className={styles.top_label}>{labelTop}</p>}
      <div className="relative">
        <Select<Option>
          id={id}
          instanceId="select"
          classNamePrefix="pps-select"
          name={name}
          placeholder={label || placeholder} //label input içinde göründüğü için label varsa placeholder zaten kullanılamaz ikisi üst üste gelir bu yüzden label varsa placeholder değeri yerine label gönderilir class kullanılarak opcity 0 yapılır selecbox'a width sağlanır.
          className={cn(styles.select, {
            [styles.menu_open]: IsMenuOpen,
            [styles.error]: !!error,
          })}
          options={options}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          styles={getCustomStyle(elementSize, isUnitSelect)}
          onBlur={onBlurSelect}
          onFocus={onFocusSelect}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          menuPlacement="auto"
          menuPosition={menuPosition}
          closeMenuOnScroll={(e) => {
            if (
              (e?.target as Element).classList?.contains(
                'pps-select__menu-list',
              )
            )
              return false;
            if (!closeMenuOnScroll) return false;

            handleCloseMenuOnScroll();
            return typeof closeMenuOnScroll === 'boolean'
              ? closeMenuOnScroll
              : closeMenuOnScroll(e);
          }}
          onChange={onChangeInput as Props['onChange']}
          isDisabled={isDisabled}
          value={selectValue !== undefined ? selectValue : null}
          {...rest}
          components={
            rest.components
              ? (rest.components as any)
              : rest.formatOptionLabel
              ? {}
              : { Option: OptionComponent, ValueContainer }
          }
        />

        {isDisabled && (
          <span className="absolute right-3 top-1/2 -translate-y-1/2 bg-gray-100">
            <DisabledIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              color="#D2DAE3"
            />
          </span>
        )}
      </div>
      {!!error && (
        <div className={styles.error_div}>
          <ErrorIcon
            width="18"
            height="18"
            viewBox="0 0 18 18"
            color="#EA0F0F"
          />
          <p>{error?.message || 'Invalid'}</p>
        </div>
      )}
    </div>
  );
};

export default Selectbox;
