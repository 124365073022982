export const emailOctopusServiceURl = 'api/integrations/emailOctopus';

export const EMAILOCTOPUS_GET_CONNECTIONS =
  'builder/integrations/EMAILOCTOPUS_GET_CONNECTIONS';
export const EMAILOCTOPUS_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/EMAILOCTOPUS_GET_CONNECTIONS_SUCCESS';
export const EMAILOCTOPUS_GET_CONNECTIONS_FAILURE =
  'builder/integrations/EMAILOCTOPUS_GET_CONNECTIONS_FAILURE';

export const EMAILOCTOPUS_GET_FIELDS =
  'builder/integrations/EMAILOCTOPUS_GET_FIELDS';
export const EMAILOCTOPUS_GET_FIELDS_SUCCESS =
  'builder/integrations/EMAILOCTOPUS_GET_FIELDS_SUCCESS';
export const EMAILOCTOPUS_GET_FIELDS_FAILURE =
  'builder/integrations/EMAILOCTOPUS_GET_FIELDS_FAILURE';

export const EMAILOCTOPUS_GET_LIST =
  'builder/integrations/EMAILOCTOPUS_GET_LIST';
export const EMAILOCTOPUS_GET_LIST_SUCCESS =
  'builder/integrations/EMAILOCTOPUS_GET_LIST_SUCCESS';
export const EMAILOCTOPUS_GET_LIST_FAILURE =
  'builder/integrations/EMAILOCTOPUS_GET_LIST_FAILURE';

export const EMAILOCTOPUS_SAVE = 'builder/integrations/EMAILOCTOPUS_SAVE';
export const EMAILOCTOPUS_SAVE_SUCCESS =
  'builder/integrations/EMAILOCTOPUS_SAVE_SUCCESS';
export const EMAILOCTOPUS_SAVE_FAILURE =
  'builder/integrations/EMAILOCTOPUS_SAVE_FAILURE';

export const EMAILOCTOPUS_DELETE = 'builder/integrations/EMAILOCTOPUS_DELETE';
export const EMAILOCTOPUS_DELETE_SUCCESS =
  'builder/integrations/EMAILOCTOPUS_DELETE_SUCCESS';
export const EMAILOCTOPUS_DELETE_FAILURE =
  'builder/integrations/EMAILOCTOPUS_DELETE_FAILURE';

export const EMAILOCTOPUS_CHANGESTATUS =
  'builder/integrations/EMAILOCTOPUS_CHANGESTATUS';
export const EMAILOCTOPUS_CHANGESTATUS_SUCCESS =
  'builder/integrations/EMAILOCTOPUS_CHANGESTATUS_SUCCESS';
export const EMAILOCTOPUS_CHANGESTATUS_FAILURE =
  'builder/integrations/EMAILOCTOPUS_CHANGESTATUS_FAILURE';

export const EMAILOCTOPUS_CLEAR_TEST_RESULT =
  'builder/integrations/EMAILOCTOPUS_CLEAR_TEST_RESULT';
export const EMAILOCTOPUS_CLEAR_STATE =
  'builder/integrations/EMAILOCTOPUS_CLEAR_STATE';
