import { ReduxAction } from 'global';
import { takeLatest, put, select } from 'redux-saga/effects';

import {
  UPDATE_TARGETING,
  UPDATE_TARGETING_SUCCESS,
  DELETE_TARGETING_SUCCESS,
  DELETE_TARGETING,
  SET_TARGETING,
  SET_TARGETING_SUCCESS,
  DELETE_ERROR,
  UPDATE_ERROR,
  DELETE_ERROR_SUCCESS,
  UPDATE_ERROR_SUCCESS,
  TARGETING_FAILURE,
} from './constants';
import type { IsValid, Targets, TargetsKey } from './reducer';

function* handleSetTargeting(payload: ReduxAction) {
  const { targets } = payload;
  try {
    yield put({
      type: SET_TARGETING_SUCCESS,
      payload: targets,
    });
  } catch (error) {
    yield put({ type: TARGETING_FAILURE });
  }
}

function* handleUpdateTargeting(payload: ReduxAction) {
  const { targets } = payload;
  try {
    yield put({
      type: UPDATE_TARGETING_SUCCESS,
      payload: targets,
    });
  } catch (error) {
    yield put({ type: TARGETING_FAILURE });
  }
}

function* handleDeleteTargeting(payload: ReduxAction) {
  const { target } = payload;
  const campaignTargets: Targets = yield select(
    (state) => state.targets?.targets,
  );
  const targets = { ...campaignTargets };

  delete targets[target as keyof Targets];

  try {
    yield put({
      type: DELETE_TARGETING_SUCCESS,
      payload: targets,
    });
  } catch (error) {
    yield put({ type: TARGETING_FAILURE });
  }
}

function* handleUpdateError(payload: ReduxAction) {
  const { targets } = payload;
  try {
    yield put({
      type: UPDATE_ERROR_SUCCESS,
      payload: targets,
    });
  } catch (error) {
    yield put({ type: TARGETING_FAILURE });
  }
}

function* handleDeleteError(payload: ReduxAction) {
  const { target } = payload;
  const validationTargets: IsValid = yield select(
    (state) => state.targets?.isValid,
  );
  const isValid = { ...validationTargets };

  delete isValid[target as TargetsKey];

  try {
    yield put({
      type: DELETE_ERROR_SUCCESS,
      payload: isValid,
    });
  } catch (error) {
    yield put({ type: TARGETING_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(SET_TARGETING, handleSetTargeting);
  yield takeLatest(UPDATE_TARGETING, handleUpdateTargeting);
  yield takeLatest(DELETE_TARGETING, handleDeleteTargeting);
  yield takeLatest(UPDATE_ERROR, handleUpdateError);
  yield takeLatest(DELETE_ERROR, handleDeleteError);
}
