import React, { FC, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import styles from './SmartTagList.module.scss';
import {
  shopifyAddToCartActionFields,
  shopifyCollectionFields,
  shopifyCustomerFields,
  shopifyProductFields,
  systemFields,
} from '../common/StaticVariables';
import { SmartTagListProps, tabType } from './types';
import { fieldListGenerator } from '../common/Functions';
import { Option } from '@elements/PSForm';
import { useSelector } from 'react-redux';
import { makeSelectCampaignLayout } from '@connectors/builder/selectors';
import { useClickOutsideHandler } from '@utils/hooks';
import Input from '@elements/Input';
import PSButton from '@elements/PSButton';
import { makeSelectRelatedCampaignFormInputs } from '@connectors/emailEditor/selectors';

const SmartTagList: FC<SmartTagListProps> = ({
  size = 'md',
  setIsShow,
  setText,
  isShow,
  buttonRef,
  customPositions,
  usePortal = true,
  onClickOutside,
  hideTabs = [],
  initialActiveTab,
}) => {
  const [selectedTab, setSelectedTab] = useState<tabType>(
    initialActiveTab ? initialActiveTab : 'system',
  );
  const [jsApiValue, setJsApiValue] = useState('');
  const layout = useSelector(makeSelectCampaignLayout);
  const emailAutomationFormInputs = useSelector(
    makeSelectRelatedCampaignFormInputs,
  );
  const listRef = useClickOutsideHandler({
    onClose: (event) => {
      const eventTargetElement = event.target as HTMLElement;
      const tagButton = buttonRef?.current;

      if (tagButton?.contains(eventTargetElement.parentElement as Node)) {
        return;
      }

      if (onClickOutside) {
        onClickOutside(event);
        return;
      }

      setIsShow(false);
    },
  });

  const generateButton = (option: Option, index: number) => {
    return (
      <button
        key={index}
        type="button"
        className={styles.tabListItem}
        onClick={() => setText(`{{${option.value.toString()}}}`)}
      >
        {option.label}
      </button>
    );
  };
  const usedPrefillFormNodeNames = [
    'text-input',
    'phone-input',
    'email-input',
    'long-input',
    'coupon-code',
  ];
  const usedPrefillFormNodeNamesForEa = [
    'full-name-input',
    ...usedPrefillFormNodeNames,
  ];

  const generateTabList = () => {
    switch (selectedTab) {
      case 'system':
        return systemFields.map((option, index) =>
          generateButton(option, index),
        );
      case 'form':
        if (emailAutomationFormInputs) {
          return emailAutomationFormInputs
            .filter((option) =>
              usedPrefillFormNodeNamesForEa.includes(option.nodeName),
            )
            .map((option, index) => generateButton(option, index));
        }
        return fieldListGenerator(layout?.contents || [])
          .filter((option) =>
            usedPrefillFormNodeNames.includes(option.nodeName),
          )
          .map((option, index) => generateButton(option, index));
      case 'shopify':
        return [
          ...shopifyCustomerFields,
          ...shopifyCollectionFields,
          ...shopifyProductFields,
          ...shopifyAddToCartActionFields,
        ].map((option, index) => generateButton(option, index));
      case 'jsApi':
        return (
          <>
            <Input
              className="mx-4 my-2"
              id="button-element-content"
              name="button-element-content"
              elementSize="sm"
              placeholder="JS API value"
              value={jsApiValue}
              onChange={(e) => {
                setJsApiValue(e.target.value);
              }}
            />
            <div className="mx-4 flex justify-end">
              <PSButton
                className="!text-[12px] !leading-[16px] !font-medium !mb-[16px]"
                type="button"
                size="sm"
                onClick={() => {
                  setText(`{{${jsApiValue.toString()}}}`);
                  setJsApiValue('');
                }}
              >
                Add
              </PSButton>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };
  const SMART_TAG_LIST_HEIGHT = 212;
  const position = useMemo(() => {
    if (!isShow) return;
    const tagButton = buttonRef?.current;
    const tagButtonPosition = tagButton?.getBoundingClientRect();

    let buttonPositions: typeof customPositions = {
      top: (tagButtonPosition?.top ?? 0) + 28,
      left: tagButtonPosition?.left,
    };

    if (customPositions) {
      buttonPositions = customPositions;
    }

    const isSmartTagListOverflow =
      window.innerHeight < (buttonPositions?.top ?? 0) + SMART_TAG_LIST_HEIGHT;
    if (isSmartTagListOverflow && tagButtonPosition) {
      buttonPositions.top = tagButtonPosition?.top - SMART_TAG_LIST_HEIGHT - 10;
    }
    return buttonPositions;
  }, [isShow, buttonRef]);

  const list = (
    <div
      ref={listRef}
      style={{ ...position }}
      className={cn(styles.container, {
        [styles.small]: size === 'sm',
        [styles.visible]: isShow,
      })}
    >
      <div className={styles.tabButtonGroupContainer}>
        <div className={styles.tabButtonContainer}>
          {!hideTabs.includes('system') ? (
            <button
              type="button"
              onClick={() => setSelectedTab('system')}
              className={
                selectedTab === 'system'
                  ? styles.selectedTabButton
                  : styles.tabButton
              }
            >
              System
            </button>
          ) : null}

          {!hideTabs.includes('shopify') ? (
            <button
              type="button"
              onClick={() => setSelectedTab('shopify')}
              className={
                selectedTab === 'shopify'
                  ? styles.selectedTabButton
                  : styles.tabButton
              }
            >
              Shopify
            </button>
          ) : null}

          {!hideTabs.includes('jsApi') ? (
            <button
              type="button"
              onClick={() => setSelectedTab('jsApi')}
              className={
                selectedTab === 'jsApi'
                  ? styles.selectedTabButton
                  : styles.tabButton
              }
            >
              JS API
            </button>
          ) : null}
          {!hideTabs.includes('form') ? (
            <button
              type="button"
              onClick={() => setSelectedTab('form')}
              className={
                selectedTab === 'form'
                  ? styles.selectedTabButton
                  : styles.tabButton
              }
            >
              Form
            </button>
          ) : null}
        </div>
      </div>

      <div className={styles.tabListContainer}>{generateTabList()}</div>
    </div>
  );

  const container = useMemo(
    () =>
      document?.querySelector('#__next') ||
      document?.querySelector('#email-editor-page'),
    [],
  );

  return (
    <>
      {container && usePortal
        ? createPortal(<div className="tag-list-portal">{list}</div>, container)
        : list}
    </>
  );
};

export default SmartTagList;
export type { SmartTagListProps };
