import React, { AriaAttributes, FC, MouseEvent, SVGAttributes } from 'react';
import classNames from 'classnames';
import Spinner from '@assets/icons/spinner.svg';
import type { PSButtonProps } from './types';
import styles from './PSButton.module.scss';
import { Tooltip } from 'ui';

const PSButton: FC<PSButtonProps> = ({
  children,
  className,
  textClassName,
  type = 'button',
  block = false,
  variant = 'primary',
  onClick,
  icon,
  size = 'md',
  loading = false,
  disabled = false,
  iconProps,
  color,
  tooltip,
  tooltipStyle,
  id,
  testId = '',
  iconClassName,
}) => {
  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    if (type === 'button' && onClick) {
      onClick(e);
    }
  };

  return (
    <button
      data-testid={testId}
      type={type}
      onClick={handleClickButton}
      className={classNames(
        {
          [styles.psButton]: true,
          [styles.second]: variant === 'second',
          [styles.danger]: variant === 'danger',
          [styles.text]: variant === 'text',
          [styles.outline]: variant === 'outline',
          [styles.ghost]: variant === 'ghost',
          [styles.block]: block,
          [styles.small]: size === 'sm',
          [styles.extraSmall]: size === 'xs',
          [styles.gray]: color === 'gray',
          [styles.black]: color === 'black',
          [styles.shopify]: color === 'shopify',
          [styles.haveIcon]: !!icon,
          [styles.tooltip]: !!tooltip,
          [styles.outlineDisabled]: variant === 'outline' && disabled,
        },
        className,
      )}
      disabled={disabled}
      id={id}
    >
      {loading && (
        <div className={styles.spinnerWrapper}>
          <Spinner width={18} height={18} />
        </div>
      )}
      {icon && (
        <div className={classNames(styles.icon, iconClassName)}>
          {React.createElement<SVGAttributes<AriaAttributes>>(icon, iconProps)}
        </div>
      )}
      {tooltip ? (
        <Tooltip
          style={tooltipStyle}
          contentStyle={{ position: 'initial' }}
          content={children}
        >
          <span className={`${textClassName}`}>{children}</span>
        </Tooltip>
      ) : (
        <span className={`${textClassName}`}>{children}</span>
      )}
    </button>
  );
};

export default PSButton;
