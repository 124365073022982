import { RootState } from '@connectors/reducers';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { FlowType } from '@generated/graphql';
import memoize from 'proxy-memoize';
import isEqual from 'lodash/isEqual';
import { EmailContentItem, Step } from './types';
import { EditorSidebarSteps } from '@enums/EmailEditorSidebarTypes';

const selectBuilder = (state: RootState) => state.editor || initialState;

const makeSelectAutomationOrjData = createSelector(
  selectBuilder,
  (editorState) => editorState?.orjData,
);

const makeSelectCopyIsChanged = memoize((state: RootState) => {
  const { orjData, data } = state.editor;

  if (!(orjData && data)) return false;

  const orjFlow = orjData?.automation?.flow || {};
  const flow = data?.automation?.flow || {};

  return !isEqual(
    JSON.parse(JSON.stringify(orjFlow)),
    JSON.parse(JSON.stringify(flow)),
  );
});

const makeSelectAutomationData = createSelector(
  selectBuilder,
  (editorState) => editorState?.data,
);

const makeSelectFlow = createSelector(
  selectBuilder,
  (editorState) => editorState?.data?.automation?.flow,
);

const makeSelectAutomationCampaignId = createSelector(
  selectBuilder,
  (editorState) => editorState?.data?.automation?.campaignId,
);

const makeSelectAutomationInfo = createSelector(
  selectBuilder,
  (editorState) => {
    return {
      id: editorState?.data?.automation?.id,
      name: editorState?.data?.automation?.name,
      status: editorState?.data?.automation?.status,
    };
  },
);

const makeSelectFlowSteps = createSelector(
  selectBuilder,
  (editorState) => editorState?.data?.automation?.flow?.steps as Step[],
);

const makeSelectFlowRelation = createSelector(selectBuilder, (editorState) => {
  if (editorState?.data?.automation?.flow?.type === FlowType.FORM_SUBMISSION) {
    return editorState?.data?.automation?.campaignId;
  }
  if (editorState?.data?.automation?.flow?.type === FlowType.SHOPIFY) {
    return editorState?.data?.automation?.store?.domain;
  }
  return editorState?.data?.automation?.campaignId;
});
const makeSelectIsFlowShopify = createSelector(selectBuilder, (editorState) => {
  return editorState?.data?.automation?.flow?.type === FlowType.SHOPIFY;
});

const makeSelectNextFlowStep = createSelector(selectBuilder, (editorState) => {
  const steps = editorState?.data?.automation?.flow?.steps || [];
  const activeStepId = editorState?.editorSidebar.activeStepId;

  if (activeStepId === 'trigger') {
    return steps.length > 0 ? steps[0].type : null;
  }
  const activeStepIndex = steps.findIndex((step) => step.type === activeStepId);
  if (activeStepIndex === -1 || activeStepIndex === steps.length - 1) {
    return null;
  }

  return steps[activeStepIndex + 1]?.type || null;
});

const makeSelectPrevFlowStep = createSelector(selectBuilder, (editorState) => {
  const { automation } = editorState?.data || {};
  const activeStepId = editorState?.editorSidebar.activeStepId;

  if (!automation || !activeStepId || activeStepId === 'trigger') {
    return null;
  }

  const activeStepIndex = automation.flow?.steps.findIndex(
    (step) => step.type === activeStepId,
  );

  if (activeStepIndex === -1) {
    return null;
  }

  return activeStepIndex === 0
    ? 'trigger'
    : automation.flow.steps[activeStepIndex - 1]?.type || null;
});

const makeSelectActiveFlowStepId = createSelector(
  selectBuilder,
  (editorState) => editorState?.editorSidebar.activeStepId,
);

const makeSelectSidebarStepState = createSelector(
  selectBuilder,
  (editorState) => {
    const sidebar = editorState?.editorSidebar;
    const activeStep = editorState?.editorSidebar.activeStepId;
    if (!sidebar || !activeStep) return null;

    if (activeStep in sidebar.stepState) {
      return sidebar.stepState[activeStep];
    }
    return null;
  },
);

const makeSelectStepStateTab = createSelector(selectBuilder, (editorState) => {
  const sidebar = editorState?.editorSidebar;
  const activeStep = editorState?.editorSidebar.activeStepId;
  if (!sidebar || !activeStep) return null;

  if (activeStep in sidebar.stepState) {
    return sidebar.stepState[activeStep]?.activeTab;
  }
  return null;
});

const makeSelectActiveFlowStepInfo = createSelector(
  selectBuilder,
  (editorState) => {
    const steps = editorState?.data?.automation?.flow?.steps || [];
    const activeStepId = editorState?.editorSidebar.activeStepId;

    const activeStepIndex =
      activeStepId === 'trigger'
        ? 0
        : steps.findIndex((step) => step.type === activeStepId) + 1 || 0;

    return {
      index: activeStepIndex,
      total: steps.length,
    };
  },
);

const makeSelectFlowStep = ({ id }: { id: string }) => {
  return createSelector(selectBuilder, (editorState) => {
    if (!(editorState.data && editorState.data.automation)) return null;
    const currentStep = editorState.data.automation.flow.steps.find(
      (x) => x.type === id,
    );
    return currentStep;
  });
};

const makeSelectTrigger = createSelector(selectBuilder, (editorState) => {
  return editorState.data?.automation?.flow?.Trigger;
});

const makeSelectTriggers = createSelector(selectBuilder, (editorState) => {
  return editorState.triggers;
});

const makeSelectRelatedCampaignFormInputs = createSelector(
  selectBuilder,
  (editorState) => {
    return editorState.relatedCampaign?.formInputs;
  },
);

const makeSelectEmailContent = createSelector(selectBuilder, (editorState) => {
  if (!editorState.data?.automation?.flow?.steps) return [];

  const mailStep = editorState.data.automation.flow.steps.find(
    (step) => step.type === EditorSidebarSteps.Mail,
  );

  if (!mailStep?.documents) return [];

  return mailStep.documents as EmailContentItem[];
});

const makeSelectEmailContentItem = ({ key }: { key: string }) => {
  return createSelector(selectBuilder, (editorState) => {
    if (!editorState.data?.automation?.flow?.steps) return null;

    const mailStep = editorState.data.automation.flow.steps.find(
      (step) => step.type === EditorSidebarSteps.Mail,
    );

    if (!mailStep?.documents) return null;

    const item = mailStep.documents.find((item) => item.key === key);

    return item as EmailContentItem;
  });
};

const makeSelectEmailContentItemElement = ({
  parentKey,
  key,
  id,
}: {
  parentKey: string;
  key: string;
  id?: string;
}) => {
  return createSelector(selectBuilder, (editorState) => {
    if (!editorState.data?.automation?.flow?.steps) return null;

    const mailStep = editorState.data.automation.flow.steps.find(
      (step) => step.type === EditorSidebarSteps.Mail,
    );

    if (!mailStep?.documents) return null;

    const item = mailStep.documents.find((item) => item.key === parentKey);
    const element = item?.elements?.find((element) => {
      if (id) {
        return element.id === id;
      }
      return element.key === key;
    });
    return element as EmailContentItem;
  });
};

const makeSelectEmailTemplate = createSelector(selectBuilder, (editorState) => {
  if (!editorState.data?.automation?.flow?.steps) return null;

  const emailTemplate = editorState.data.automation.flow.EmailTemplate;

  return emailTemplate;
});

const makeSelectIsPlainText = createSelector(selectBuilder, (editorState) => {
  if (!editorState.data?.automation?.flow?.isPlainText) return null;

  const isPlainText = editorState.data.automation.flow.isPlainText;

  return isPlainText;
});

const makeSelectPlainTextBody = createSelector(selectBuilder, (editorState) => {
  if (!editorState.data?.automation?.flow?.plainTextBody) return null;

  const plainTextBody = editorState.data.automation.flow.plainTextBody;

  return plainTextBody;
});

const makeSelectPlainTextFormError = createSelector(
  selectBuilder,
  (editorState) => {
    return editorState.hasPlainTextFormError;
  },
);

export {
  makeSelectAutomationOrjData,
  makeSelectCopyIsChanged,
  makeSelectAutomationData,
  makeSelectFlow,
  makeSelectFlowSteps,
  makeSelectActiveFlowStepId,
  makeSelectSidebarStepState,
  makeSelectStepStateTab,
  makeSelectFlowStep,
  makeSelectTrigger,
  makeSelectTriggers,
  makeSelectNextFlowStep,
  makeSelectPrevFlowStep,
  makeSelectActiveFlowStepInfo,
  makeSelectFlowRelation,
  makeSelectIsFlowShopify,
  makeSelectAutomationInfo,
  makeSelectAutomationCampaignId,
  makeSelectRelatedCampaignFormInputs,
  makeSelectEmailContent,
  makeSelectEmailContentItem,
  makeSelectEmailContentItemElement,
  makeSelectEmailTemplate,
  makeSelectIsPlainText,
  makeSelectPlainTextBody,
  makeSelectPlainTextFormError,
};
