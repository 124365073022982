import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import Airtable from 'airtable';
import { toast } from 'react-toastify';

import store from '@utils/store';
import fetch from '@utils/fetch';

import {
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  REGISTER_WITH_INVITE_REQUEST,
  REGISTER_WITH_INVITE_SUCCESS,
  REGISTER_WITH_INVITE_FAILURE,
  LOGIN_WITH_GOOGLE_REQUEST,
  ADD_TOKEN_MANUALLY_REQUEST,
  ADD_TOKEN_MANUALLY_SUCCESS,
  ADD_TOKEN_MANUALLY_FAILURE,
  LOGIN_BACKOFFICE_REQUEST,
  GET_AUTH_USER,
  GET_AUTH_USER_SUCCESS,
  GET_AUTH_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_FAILURE,
  DELETE_SURVEY_REQUEST,
  DELETE_SURVEY_FAILURE,
  USER_LOGOUT,
  DELETE_SURVEY_SUCCESS,
  CHANGE_SUBACCOUNT_SEEN_REQUEST,
  CHANGE_SUBACCOUNT_SEEN_SUCCESS,
  CHANGE_SUBACCOUNT_SEEN_FAILURE,
  LOGIN_FAILURE,
} from './constants';

import { User } from './reducer';
import { GET_CAMPAIGNS_COUNTS } from '@connectors/campaigns/constants';
import { GET_WEBSITES_REQUEST } from '@connectors/websites/constants';
import { GET_PAGE_VIEW_REQUEST } from '@connectors/accounts/constants';
import { setAuthUser } from './actions';
import graphqlFetch from '@utils/graphqlFetch';

interface ILoginResponse {
  token: string;
  refreshToken: string;
  tokenExpireDate: Date;
}

interface IRegisterResponse {
  id?: number;
  email?: string;
  token?: string;
  refreshToken?: string;
  tokenExpireDate: Date;
}

export function updateUserProfile(data: any) {
  return {
    operationName: 'updateUserProfile',
    query: `mutation updateUserProfile($name: String
      $surname: String
      $phone_number: String
      $systemLanguage: String) {
        updateUserProfile(name: $name
          surname: $surname
          phone_number: $phone_number
          systemLanguage: $systemLanguage)
    }`,
    variables: {
      name: data.name,
      surname: data.surname,
      phone_number: data.phone_number,
      systemLanguage: data.systemLanguage,
    },
  };
}
export function invitedUserRegister(data: any) {
  return {
    operationName: 'invitedUserRegister',
    query: `mutation invitedUserRegister(
      $code: String!
      $password: String!
      $domain: String
      $lastName: String
      $firstName: String
    ) {
      invitedUserRegister(
        code: $code
        password: $password
        domain: $domain
        lastName: $lastName
        firstName: $firstName
      ) {
        refreshToken
        token
        tokenExpireDate
      }
    }`,
    variables: {
      code: data.code,
      password: data.password,
      domain: data.domain,
      lastName: data.lastName,
      firstName: data.firstName,
    },
  };
}

function* getAuthUserSaga() {
  try {
    const user: User = store.get('user');

    yield put({
      type: GET_AUTH_USER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    yield put({ type: GET_AUTH_USER_FAILURE });
  }
}

function* loginSaga({ payload, onSuccess, onError }: ReduxAction) {
  try {
    const res: ILoginResponse = yield call(fetch, `api/auth/customer-login`, {
      method: 'POST',
      data: payload,
    });
    yield put(setAuthUser(res));
    onSuccess();
  } catch (error) {
    onError();
  }
}

function* registerSaga({ payload, onSuccess, onError }: ReduxAction) {
  try {
    const data: IRegisterResponse = yield call(fetch, `api/User/register`, {
      method: 'POST',
      data: payload,
    });
    yield put(
      setAuthUser({
        token: data.token,
        refreshToken: data.refreshToken,
        tokenExpireDate: data.tokenExpireDate,
      }),
    );
    onSuccess();
  } catch (error) {
    onError();
    yield put({ type: REGISTER_FAILURE, payload: (error as Error).message });
  }
}

function* loginWithGoogleSaga({ payload, onSuccess, onError }: ReduxAction) {
  try {
    const data: ILoginResponse = yield call(
      fetch,
      `api/Auth/customer-google-login`,
      {
        method: 'POST',
        data: payload,
      },
    );
    yield put(setAuthUser(data));
    onSuccess();
  } catch (error) {
    onError();
    yield put({ type: LOGIN_FAILURE, payload: (error as Error).message });
  }
}

function* registerWithInviteSaga({
  payload,
  onSuccess,
  onError,
}: ReduxAction): Generator<any, void, any> {
  try {
    const data = yield call(graphqlFetch, '', {
      method: 'POST',
      data: invitedUserRegister({
        code: payload.code,
        password: payload.password,
        domain: payload.domain,
        lastName: payload.lastname,
        firstName: payload.firstname,
      }),
    });

    if (data) {
      const auth = { ...data, ...payload };
      yield put(setAuthUser(auth));
      //Won't catch REGISTER_WITH_INVITE_SUCCESS in reducer, this is only for redux to have better log
      yield put({ type: REGISTER_WITH_INVITE_SUCCESS, payload: auth });
      onSuccess();
      return;
    }
    throw new Error('Register with invite failed');
  } catch (error) {
    yield put({ type: REGISTER_WITH_INVITE_FAILURE });
    onError();
  }
}

function* forgotPasswordSaga({ payload }: ReduxAction) {
  try {
    yield call(fetch, `api/Auth/forgot-password`, {
      method: 'POST',
      data: payload,
    });

    yield put({ type: FORGOT_PASSWORD_SUCCESS });
    yield call(toast.success, `Password reset link is sent to your email.`);
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_FAILURE, payload: error });
  }
}

function* resetPasswordSaga({ payload }: ReduxAction) {
  try {
    yield call(fetch, `api/Auth/reset-password`, {
      method: 'POST',
      data: payload,
    });

    yield put({ type: RESET_PASSWORD_SUCCESS });
    //Fire success toastr here, and redirection to /login will be handled in the page
    yield call(
      toast.success,
      `Your password is changed successfully, now redirecting to our login
    page.`,
    );
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILURE });
  }
}

function* getUserSaga() {
  try {
    const data: User = yield call(fetch, `api/User/me`, {
      method: 'GET',
    });

    const user = store.get('user');
    const auth = { ...user, ...data };
    yield put(setAuthUser(auth));
    //Won't catch GET_USER_SUCCESS in reducer, this is only for redux to have better log
    yield put({ type: GET_USER_SUCCESS, payload: auth });
  } catch (err) {
    yield put({
      type: GET_USER_FAILURE,
    });
  }
}

function* editUserWithoutPasswordSaga({ payload, successFunc }: ReduxAction) {
  //This saga is used when user doesn't change password
  try {
    yield call(graphqlFetch, '', {
      method: 'POST',
      data: updateUserProfile({
        name: payload['firstname'],
        surname: payload['lastname'],
        phone_number: payload['phone_number'],
        systemLanguage: payload['systemLanguage'],
      }),
    });

    const user = store.get('user');
    const auth = {
      ...user,
      userName: payload['firstname'],
      userSurname: payload['lastname'],
      userPhone: payload['phone_number'],
    };
    yield put(setAuthUser(auth));
    if (successFunc) successFunc();
    //Won't catch EDIT_USER_SUCCESS in reducer, this is only for redux to have better log
    yield put({ type: EDIT_USER_SUCCESS, payload: auth });
  } catch (error) {
    yield put({ type: EDIT_USER_FAILURE });
  }
}

function* deleteUserSaga() {
  try {
    yield put({ type: USER_LOGOUT });
  } catch (error) {
    yield put({ type: DELETE_USER_FAILURE });
  }
}

function* deleteSurveySaga({ payload }: ReduxAction) {
  try {
    const base = new Airtable({
      apiKey: process.env.NEXT_PUBLIC_AIRTABLE_API_KEY,
    }).base(process.env.NEXT_PUBLIC_AIRTABLE_APP_KEY as string); //as string important

    const sendToAirtable = () => {
      base('users').create([{ fields: payload }]);
    };

    sendToAirtable();
    yield put({ type: DELETE_SURVEY_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_SURVEY_FAILURE });
  }
}

function* addTokenManuallySaga(action: ReduxAction) {
  const { payload, onSuccess } = action;
  try {
    const user: User = yield call(graphqlFetch, '', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
      data: {
        operationName: 'Me',
        query: `query Me {
          me {
            id
            name
            surname
            email
            phone_number
            apiToken
            isPageViewBlocked
            isShopifyUser
            emailVerify
            accountId
            accountUseProxiedFonts
            isOwner
            accountIsGettingStartedCompleted
            isMigrated
            paymentProvider
            baseShopifyStore
            isShopify
            userHasShopifyWebsite
            accountPlan {
              activePopupCount
              description
              displayCount
              domainCount
              id
              impressionCount
              interactionCount
              name
              popupCount
              price
              stripeId
              type
            }
          }
        }`,
      },
    });

    if (user) {
      const auth = { ...payload, ...user };
      yield put(setAuthUser(auth));
      //Won't catch ADD_TOKEN_MANUALLY_SUCCESS in reducer, this is only for redux to have better log
      yield put({ type: ADD_TOKEN_MANUALLY_SUCCESS, payload: auth });
      yield put({ type: GET_WEBSITES_REQUEST });
      yield put({ type: GET_PAGE_VIEW_REQUEST });
      yield put({ type: GET_CAMPAIGNS_COUNTS });
      onSuccess?.();
    } else {
      throw new Error('Add token manually failed');
    }
  } catch (err) {
    yield put({
      type: ADD_TOKEN_MANUALLY_FAILURE,
    });
  }
}

function* loginBackofficeSaga(action: ReduxAction) {
  const { payload, onSuccess } = action;
  try {
    yield put({ type: USER_LOGOUT });
    yield put({
      type: ADD_TOKEN_MANUALLY_REQUEST,
      payload: { token: payload.token },
      onSuccess,
    });
  } catch (err) {
    yield put({
      type: ADD_TOKEN_MANUALLY_FAILURE,
    });
  }
}

function* changeUserSubAccountSeen() {
  try {
    yield call(fetch, `api/Account/ChangeSubaccountsSeenState`, {
      method: 'PATCH',
    });
    yield put({
      type: CHANGE_SUBACCOUNT_SEEN_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: CHANGE_SUBACCOUNT_SEEN_FAILURE,
    });
  }
}

export default function* sagas() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(REGISTER_REQUEST, registerSaga);
  yield takeLatest(LOGIN_WITH_GOOGLE_REQUEST, loginWithGoogleSaga);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(REGISTER_WITH_INVITE_REQUEST, registerWithInviteSaga);
  yield takeLatest(GET_USER_REQUEST, getUserSaga);
  yield takeLatest(EDIT_USER_REQUEST, editUserWithoutPasswordSaga);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(DELETE_SURVEY_REQUEST, deleteSurveySaga);
  yield takeLatest(ADD_TOKEN_MANUALLY_REQUEST, addTokenManuallySaga);
  yield takeLatest(LOGIN_BACKOFFICE_REQUEST, loginBackofficeSaga);
  yield takeLatest(GET_AUTH_USER, getAuthUserSaga);
  yield takeLatest(CHANGE_SUBACCOUNT_SEEN_REQUEST, changeUserSubAccountSeen);
}
