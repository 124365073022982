import {
  CAMPAIGN_GET,
  CAMPAIGN_LAYOUT_GET,
  DOMAIN_DELETE,
  DOMAIN_SELECT,
  DOMAIN_GET,
  PUBLISH,
  AUTO_SAVE,
  CREATE_CAMPAIGN_REQUEST,
  DEFAULT_PUBLISH,
  DISABLE_EDIT_MOBILE_MODAL_REQUEST,
  SAVE_LAYOUT,
  SEND_EMBED_CODE_REQUEST,
  SET_SETTINGS_REQUEST,
  CLEAR_CAMPAIGN,
  PREVIEW_ACTIVE,
  SAVE_PAGE_INDEX,
  SAVE_ACTIVE_DEVICE,
  TAKE_SCREENSHOT_REQUEST,
  UPDATE_AUTO_RESPONDER_REQUEST,
  CAMPAIGN_DETAILS_GET,
} from './constants';

export function getCampaign(payload: any) {
  const { campaignId } = payload;
  return {
    type: CAMPAIGN_GET,
    campaignId,
  };
}
export function createCampaign(payload: any) {
  const { name, website, onSuccess } = payload;
  return {
    type: CREATE_CAMPAIGN_REQUEST,
    name,
    website,
    onSuccess,
  };
}

export function deleteDomain(payload: any) {
  const { campaignId, domainId } = payload;

  return {
    type: DOMAIN_DELETE,
    domainId,
    campaignId,
  };
}

export function clearCampaign() {
  return {
    type: CLEAR_CAMPAIGN,
  };
}

export function selectDomain(payload: any) {
  const { campaignId, domainId } = payload;

  return {
    type: DOMAIN_SELECT,
    domainId,
    campaignId,
  };
}

export function getCampaignLayout(payload: any) {
  const { templateId, imageUrl, name, isTemplateSelected } = payload;
  return {
    type: CAMPAIGN_LAYOUT_GET,
    templateId,
    imageUrl,
    name,
    isTemplateSelected,
  };
}

export function getCampaignDetails(payload: { templateId: number }) {
  const { templateId } = payload;
  return {
    type: CAMPAIGN_DETAILS_GET,
    templateId,
  };
}

export function autosave(payload: any) {
  const { campaignId, content, isTemplateSelected } = payload;

  return {
    type: AUTO_SAVE,
    campaignId,
    content,
    isTemplateSelected,
  };
}

export function saveLayout(payload: any) {
  const { content } = payload;
  return {
    type: SAVE_LAYOUT,
    content,
  };
}

export function saveActivePageIndex(payload: any) {
  const { pageIndex } = payload;
  return {
    type: SAVE_PAGE_INDEX,
    pageIndex,
  };
}

export function savePreviewActive(payload: any) {
  const { showSidebar } = payload;
  return {
    type: PREVIEW_ACTIVE,
    showSidebar,
  };
}

export function saveActiveDevice(payload: any) {
  const { activeDevice } = payload;
  return {
    type: SAVE_ACTIVE_DEVICE,
    activeDevice,
  };
}

export function publish(payload: any) {
  const { campaignId, content } = payload;

  return {
    type: PUBLISH,
    campaignId,
    content,
  };
}
export function returnDefaultPublishStatus() {
  return {
    type: DEFAULT_PUBLISH,
  };
}

export function disableEditMobileModal() {
  return {
    type: DISABLE_EDIT_MOBILE_MODAL_REQUEST,
  };
}

export function getDomains() {
  return {
    type: DOMAIN_GET,
  };
}

export function sendEmbedCode(payload: any) {
  return {
    type: SEND_EMBED_CODE_REQUEST,
    payload,
  };
}

export function setSettings(payload: any) {
  const { campaignId, settings } = payload;
  return {
    type: SET_SETTINGS_REQUEST,
    campaignId,
    settings,
  };
}
export function takeScreenshot(payload: any) {
  return {
    type: TAKE_SCREENSHOT_REQUEST,
    payload,
  };
}

export function updateAutoResponder(payload: any) {
  return {
    type: UPDATE_AUTO_RESPONDER_REQUEST,
    payload,
  };
}
