import { FC, ChangeEvent, useEffect, useState, CSSProperties } from 'react';
import cn from 'classnames';

import ErrorIcon from '@assets/icons/error-icon.svg';
import CopyIcon from '@assets/icons/copy.svg';
import type { PSTextboxProps } from './types';

import styles from './Textbox.module.scss';
import { Tooltip } from 'ui';
import HelpIcon from '@assets/icons/help-info-icon.svg';
import Skeleton from 'react-loading-skeleton';

const PSTextbox: FC<PSTextboxProps> = ({
  label,
  placeholder,
  placeholderColor = '#d2dae3',
  field: { value, name, onChange },
  fieldState: { error },
  testid,
  hoverEffectNone = false,
  tooltip,
  disabled,
  readonly = false,
  copy = false,
  suffix,
  prefix,
  type = 'text',
  normalize,
  className,
  contentClassName,
  elementSize,
  errorContainerStyle = {
    onError: {},
    initial: {},
  },
  prefixStatus,
  onChangeEvent,
  loading = false,
  defaultValue,
  placeholderLabel,
  required = false,
  labelClassName = 'font-medium text-xs leading-4 mb-2.5',
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (value !== '') {
      onChangeEvent && onChangeEvent(value);
    }
  }, []);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    onChangeEvent && onChangeEvent(e.target.value);
  };
  const handleBlurValue = (e: ChangeEvent<HTMLInputElement>) => {
    let { value }: { value: string | number | boolean } = e.target;
    if (normalize) {
      value = normalize(value);
    }
    onChange(value);
    onChangeEvent && onChangeEvent(value);
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (readonly) event.target.select();
  };

  const handleClickCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 750);
  };

  const loadingSkeleton = () => {
    return (
      <div className="flex flex-col">
        <Skeleton
          height={18}
          count={1}
          baseColor="#f5f5f5"
          highlightColor="#fefefe"
          borderRadius={6}
          width={65}
        />
        <Skeleton
          height={38}
          count={1}
          baseColor="#f5f5f5"
          highlightColor="#fefefe"
          borderRadius={6}
          width={400}
        />
      </div>
    );
  };
  return (
    <div className={cn(styles.container, className)}>
      <div
        className={cn('textbox-input-wrapper', {
          [styles.inputWrapper]: !prefix,
          [styles.inputWrapperRelative]: disabled || copy,
          [styles.inputWrapperPrefix]: prefix,
          [styles.xs]: elementSize === 'xs',
        })}
      >
        {loading ? (
          loadingSkeleton()
        ) : (
          <>
            {label && (
              <label
                className={labelClassName}
                data-testid="textfield-label"
                htmlFor={name}
              >
                <>
                  {label + (required ? ' *' : '')}

                  {tooltip && (
                    <Tooltip
                      style={{
                        zIndex: '99999',
                        position: 'absolute',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                        height: 'max-content',
                        width: '240px',
                        whiteSpace: 'normal',
                        left: '58px',
                      }}
                      content={tooltip}
                    >
                      <HelpIcon
                        className="ml-1 translate-y-1 text-gray-700"
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                      />
                    </Tooltip>
                  )}
                </>
              </label>
            )}
            {prefix && (
              <div
                className={cn('textbox-prefix', {
                  [styles.prefix]: true,
                  [styles.error]: prefixStatus === 'error',
                  [styles.success]: prefixStatus === 'success',
                })}
              >
                {prefix}
              </div>
            )}
            <div className="relative w-full">
              <input
                id={name}
                className={cn(contentClassName, {
                  [styles.input]: true,
                  [styles.error]: !!error,
                  [styles.copying]: isCopied,
                  [styles.hoverEffectNone]: hoverEffectNone,
                })}
                data-testid={testid ?? name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                name={name}
                onChange={handleChangeValue}
                onBlur={handleBlurValue}
                value={value}
                autoComplete="off"
                readOnly={readonly}
                onFocus={handleFocus}
                style={
                  {
                    '--placeholderColor': placeholderColor,
                  } as CSSProperties
                }
                defaultValue={defaultValue}
              />
              {placeholderLabel && (
                <label className={styles.placeholderLabel}>
                  {placeholderLabel}
                </label>
              )}
            </div>
            {copy && (
              <span className={styles.copyButton} onClick={handleClickCopy}>
                {isCopied ? (
                  <span className="text-sm text-black bg-white rounded-md px-1">
                    Copied
                  </span>
                ) : (
                  <CopyIcon
                    data-testid="textfield-disabled-icon"
                    width="18"
                    height="18"
                    className={styles.copyIcon}
                  />
                )}
              </span>
            )}
            {suffix && <div className={styles.suffix}>{suffix}</div>}
          </>
        )}
      </div>
      {!readonly && (
        <div
          style={
            error ? errorContainerStyle.onError : errorContainerStyle.initial
          }
          className={styles.errorContainer}
          data-testid="textfield-error"
        >
          {!!error && (
            <>
              <span>
                <ErrorIcon width="18px" height="18px" className={styles.icon} />
              </span>
              <span>
                <p className={styles.message}>{error?.message || 'Invalid'}</p>
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PSTextbox;
