import React, { FC } from 'react';
import cn from 'classnames';

import type { TabProps } from './types';

import styles from './Tabs.module.scss';

const Tab: FC<TabProps> = ({
  className,
  children,
  id,
  activeTab,
  testId = '',
}) => {
  if (activeTab === id) {
    return (
      <div
        data-testid={testId}
        className={cn(styles.container, className)}
        id={id}
      >
        {children}
      </div>
    );
  }

  return null;
};

export default Tab;
