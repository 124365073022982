enum IntegrationEnums {
  activeCampaign = 'integrationActiveCampaignCampaigns',
  customerIo = 'integrationCustomerIoCampaigns',
  drip = 'integrationDripCampaigns',
  emailOctopus = 'integrationEmailOctopusCampaigns',
  hubspot = 'integrationHubSpotCampaigns',
  klaviyo = 'integrationKlaviyoCampaigns',
  mailchimp = 'integrationMailchimpCampaigns',
  mailerlite = 'integrationMailerliteCampaigns',
  sendgrid = 'integrationSendgridCampaigns',
  sendinBlue = 'integrationSendinBlueCampaigns',
  sendLoop = 'integrationSendLoopCampaigns',
  webhook = 'integrationWebhooks',
  shopify = 'integrationShopify',
}

export enum IntegrationModalTypes {
  mailChimp = 'mailChimpModal',
  activeCampaign = 'activeCampaignModal',
  customerIo = 'customerIoModal',
  drip = 'dripModal',
  emailOctopus = 'emailOctopusModal',
  klaviyo = 'klaviyoModal',
  hubSpot = 'hubSpotModal',
  mailerlite = 'mailerliteModal',
  sendgrid = 'sendgridModal',
  sendinblue = 'sendinblueModal',
  webhook = 'webhookModal',
  shopify = 'shopifyIntegrationModal',
}

export const integrationModalIds = {
  mailchimpCampaigns: IntegrationModalTypes.mailChimp,
  activeCampaignCampaigns: IntegrationModalTypes.activeCampaign,
  customerIoCampaigns: IntegrationModalTypes.customerIo,
  dripCampaigns: IntegrationModalTypes.drip,
  hubSpotCampaigns: IntegrationModalTypes.hubSpot,
  klaviyoCampaigns: IntegrationModalTypes.klaviyo,
  emailOctopusCampaigns: IntegrationModalTypes.emailOctopus,
  mailerliteCampaigns: IntegrationModalTypes.mailerlite,
  sendgridCampaigns: IntegrationModalTypes.sendgrid,
  sendinBlueCampaigns: IntegrationModalTypes.sendinblue,
  webhooks: IntegrationModalTypes.webhook,
  shopify: IntegrationModalTypes.shopify,
};

export default IntegrationEnums;
