import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@connectors/reducers';
import config from '@utils/config';
import { useAuth } from '@utils/useAuth';
import i18n from '../../i18n';

const BootstrapProvider = () => {
  const { auth } = useAuth();
  const { items: websites } = useSelector((state: RootState) => state.websites);
  useEffect(() => {
    if (auth.systemLanguage) {
      i18n.changeLanguage(auth.systemLanguage);
    }
  }, [auth.systemLanguage]);

  useEffect(() => {
    if (
      !config.isDev &&
      auth.accountId &&
      auth.accountId != '' &&
      auth.id != 0 &&
      auth.isOwner
    ) {
      if (config.isProd) {
        const accountId = Number(auth.accountId);
        window?._cio?.identify({
          id: accountId,
          email: auth.email,
          websites: websites.length ? websites : undefined,
          isShopify: auth.isShopify,
        });
        window?.dataLayer?.push({
          userId: auth.id,
          accountId,
          email: auth.email,
        });
      }

      window.lc = window.lc || {};
      window.lc.identity = {
        distinctId: String(auth.id),
        email: auth.email,
        name: auth.name,
        phone: auth.phone_number,
      };
      window.lc.debug = false;
    }
  }, [
    auth.accountId,
    auth.email,
    auth.id,
    auth.isOwner,
    websites,
    auth.isShopify,
  ]);
  return <></>;
};
export default BootstrapProvider;
