import { Targets } from '@connectors/builder/campaign';
import {
  CREATE_SEGMENT,
  CREATE_SEGMENT_ERROR,
  CREATE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_ERROR,
  DELETE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_TARGET_ERROR,
  DELETE_TARGET_SEGMENT_TARGETS_COPY,
  GET_ALL_SEGMENTS,
  GET_ALL_SEGMENTS_ERROR,
  GET_ALL_SEGMENTS_SUCCESS,
  GET_ACTIVE_SEGMENT,
  GET_ACTIVE_SEGMENT_ERROR,
  GET_ACTIVE_SEGMENT_SUCCESS,
  RESET,
  RESET_SEGMENT_TARGETS_COPY,
  RESET_ACTIVE_SEGMENT,
  SEGMENT_TARGETS_COPY_TO_SEGMENT_TARGETS,
  SEGMENT_TARGETS_TO_SEGMENT_TARGETS_COPY,
  SELECT_ACTIVE_SEGMENT,
  SELECT_ACTIVE_SEGMENT_ERROR,
  SET_SEGMENT_TARGETS_COPY,
  SET_SEGMENT_TARGET_ERROR,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_ERROR,
  UPDATE_SEGMENT_NAME,
  UPDATE_SEGMENT_NAME_ERROR,
  UPDATE_SEGMENT_NAME_SUCCESS,
  UPDATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT_TARGETS_COPY,
  UPDATE_CHANGE_VIEW_VISIBILITY,
  RESET_SELECTED_SEGMENT,
  SET_SELECTED_SEGMENT,
  SET_SELECTED_SEGMENT_SUCCESS,
  SET_SELECTED_SEGMENT_ERROR,
  UPDATE_CHANGE_VIEW_VISIBILITY_SUCCESS,
  SET_SELECTED_SEGMENT_AS_SMART_MODE,
} from './constants';
import {
  CreateSegmentAction,
  CreateSegmentErrorAction,
  CreateSegmentSuccessAction,
  DeleteSegmentErrorAction,
  DeleteSegmentSuccessAction,
  GetAllSegmentAction,
  GetAllSegmentErrorAction,
  GetAllSegmentSuccessAction,
  GetActiveSegmentAction,
  GetActiveSegmentErrorAction,
  GetActiveSegmentSuccessAction,
  Segment,
  SelectActiveSegmentAction,
  ActiveSegment,
  UpdateSegmentAction,
  UpdateSegmentErrorAction,
  UpdateSegmentNameAction,
  UpdateSegmentNameErrorAction,
  UpdateSegmentNameSuccessAction,
  UpdateSegmentSuccessAction,
  UpdateChangeViewVisibilityAction,
  ResetSelectedSegmentAction,
  SetSelectedSegmentAction,
  SetSelectedSegmentErrorAction,
  SetSelectedSegmentSuccessAction,
  SetSelectedSegmentAsSmartModeAction,
  UpdateChangeViewVisibilitySuccessAction,
} from './types';
import { TargetsKey } from '@connectors/builder/types';

export function resetSegment() {
  return {
    type: RESET,
  };
}

export function getAllSegments({ id }: { id: number }): GetAllSegmentAction {
  return {
    type: GET_ALL_SEGMENTS,
    payload: { id },
  };
}

export function getAllSegmentsSuccess({
  data,
}: {
  data: Segment[];
}): GetAllSegmentSuccessAction {
  return {
    type: GET_ALL_SEGMENTS_SUCCESS,
    payload: { data },
  };
}

export function getAllSegmentsError(): GetAllSegmentErrorAction {
  return {
    type: GET_ALL_SEGMENTS_ERROR,
  };
}

export function getActiveSegment({
  id,
}: {
  id: number;
}): GetActiveSegmentAction {
  return {
    type: GET_ACTIVE_SEGMENT,
    payload: { id },
  };
}

export function getActiveSegmentSuccess({
  data,
}: {
  data: ActiveSegment;
}): GetActiveSegmentSuccessAction {
  return {
    type: GET_ACTIVE_SEGMENT_SUCCESS,
    payload: { data },
  };
}

export function getActiveSegmentError(): GetActiveSegmentErrorAction {
  return {
    type: GET_ACTIVE_SEGMENT_ERROR,
  };
}

export function deleteSegmentSuccess({
  id,
}: {
  id: number;
}): DeleteSegmentSuccessAction {
  return {
    type: DELETE_SEGMENT_SUCCESS,
    payload: { id },
  };
}

export function deleteSegmentError(): DeleteSegmentErrorAction {
  return {
    type: DELETE_SEGMENT_ERROR,
  };
}

export function selectActiveSegment({
  id,
  handleOnSuccess,
}: {
  id: number;
  handleOnSuccess?: () => void;
}): SelectActiveSegmentAction {
  return {
    type: SELECT_ACTIVE_SEGMENT,
    payload: { id, handleOnSuccess },
  };
}

export function selectActiveSegmentError() {
  return {
    type: SELECT_ACTIVE_SEGMENT_ERROR,
  };
}

export function setSegmentTargetsCopy(targets: Targets) {
  return {
    type: SET_SEGMENT_TARGETS_COPY,
    payload: {
      targets,
    },
  };
}

export function segmentsTargetsCopyToActiveTargets() {
  return {
    type: SEGMENT_TARGETS_COPY_TO_SEGMENT_TARGETS,
  };
}

export function activeTargetsToSegmentsTargetsCopy() {
  return {
    type: SEGMENT_TARGETS_TO_SEGMENT_TARGETS_COPY,
  };
}

export function resetSegmentTargetsCopy() {
  return {
    type: RESET_SEGMENT_TARGETS_COPY,
  };
}

export function resetActiveSegment() {
  return {
    type: RESET_ACTIVE_SEGMENT,
  };
}

export function updateSegmentTargetsCopy(data: Targets) {
  return {
    type: UPDATE_SEGMENT_TARGETS_COPY,
    payload: { data },
  };
}
export function deleteTargetSegmentTargetsCopy(target: TargetsKey) {
  return {
    type: DELETE_TARGET_SEGMENT_TARGETS_COPY,
    payload: { target },
  };
}

export function setSegmentTargetError(error: { [key in TargetsKey]: boolean }) {
  return {
    type: SET_SEGMENT_TARGET_ERROR,
    payload: { error },
  };
}

export function deleteSegmentTargetError(targetName: TargetsKey) {
  return {
    type: DELETE_SEGMENT_TARGET_ERROR,
    payload: { targetName },
  };
}

export function updateSegmentName({
  id,
  name,
  description,
  isActive,
}: {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
}): UpdateSegmentNameAction {
  return {
    type: UPDATE_SEGMENT_NAME,
    payload: { id, name, description, isActive },
  };
}

export function updateSegmentNameSuccess({
  data,
  isActive,
}: {
  data: ActiveSegment;
  isActive: boolean;
}): UpdateSegmentNameSuccessAction {
  return {
    type: UPDATE_SEGMENT_NAME_SUCCESS,
    payload: { data, isActive },
  };
}

export function updateSegmentNameError(): UpdateSegmentNameErrorAction {
  return {
    type: UPDATE_SEGMENT_NAME_ERROR,
  };
}

export function updateSegment({
  id,
  name,
  description,
  targets,
  reset,
  onSuccess,
}: {
  id: number;
  name: string;
  description: string;
  targets: Targets;
  reset: boolean;
  onSuccess(): void;
}): UpdateSegmentAction {
  return {
    type: UPDATE_SEGMENT,
    payload: { id, name, description, targets, reset, onSuccess },
  };
}

export function updateSegmentSuccess({
  data,
}: {
  data: ActiveSegment;
}): UpdateSegmentSuccessAction {
  return {
    type: UPDATE_SEGMENT_SUCCESS,
    payload: { data },
  };
}

export function updateSegmentError(): UpdateSegmentErrorAction {
  return {
    type: UPDATE_SEGMENT_ERROR,
  };
}

export function createSegment({
  name,
  description,
  targets,
  onSuccess,
}: {
  name: string;
  description: string;
  targets: Targets;
  onSuccess?: (segment: ActiveSegment) => void;
}): CreateSegmentAction {
  return {
    type: CREATE_SEGMENT,
    payload: { name, description, targets, onSuccess },
  };
}

export function createSegmentSuccess({
  data,
}: {
  data: ActiveSegment;
}): CreateSegmentSuccessAction {
  return {
    type: CREATE_SEGMENT_SUCCESS,
    payload: { data },
  };
}

export function createSegmentError(): CreateSegmentErrorAction {
  return {
    type: CREATE_SEGMENT_ERROR,
  };
}

export function updateChangeViewVisibility({
  isVisible,
  updateSelected,
}: {
  isVisible: boolean;
  updateSelected: boolean | number;
}): UpdateChangeViewVisibilityAction {
  return {
    type: UPDATE_CHANGE_VIEW_VISIBILITY,
    payload: { isVisible, updateSelected },
  };
}

export function updateChangeViewVisibilitySuccess({
  isVisible,
}: {
  isVisible: boolean;
}): UpdateChangeViewVisibilitySuccessAction {
  return {
    type: UPDATE_CHANGE_VIEW_VISIBILITY_SUCCESS,
    payload: { isVisible },
  };
}

export function setSelectedSegment({
  id,
}: {
  id: number;
}): SetSelectedSegmentAction {
  return {
    type: SET_SELECTED_SEGMENT,
    payload: { id },
  };
}

export function setSelectedSegmentSuccess({
  data,
}: {
  data: ActiveSegment;
}): SetSelectedSegmentSuccessAction {
  return {
    type: SET_SELECTED_SEGMENT_SUCCESS,
    payload: { data },
  };
}

export function setSelectedSegmentError(): SetSelectedSegmentErrorAction {
  return {
    type: SET_SELECTED_SEGMENT_ERROR,
  };
}

export function setSelectedSegmentAsSmartMode({
  status,
}: {
  status: boolean;
}): SetSelectedSegmentAsSmartModeAction {
  return {
    type: SET_SELECTED_SEGMENT_AS_SMART_MODE,
    payload: { status },
  };
}

export function resetSelectedSegment(): ResetSelectedSegmentAction {
  return {
    type: RESET_SELECTED_SEGMENT,
  };
}
