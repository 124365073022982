import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import { isEmpty } from '@utils/object';
import TimelineContent from '../TimelineContent';
import { capitalize } from 'core/helpers/string';

import OSIcon from '@assets/icons/os.svg';

type osType = 'windows' | 'macOs' | 'linux' | 'chromium' | 'android' | 'ios';

const OsArr: osType[] = [
  'windows',
  'macOs',
  'linux',
  'chromium',
  'android',
  'ios',
];

export const renderOperatingSystem = (
  operatingSystem: Targets['operatingSystem'],
  translate: Function,
) => {
  if (isEmpty(operatingSystem)) return;
  if (
    operatingSystem?.android &&
    operatingSystem?.chromium &&
    operatingSystem?.ios &&
    operatingSystem?.linux &&
    operatingSystem?.macOs &&
    operatingSystem?.windows &&
    !operatingSystem?.isSelected
  )
    return;

  let operatingSystemNames = '';
  OsArr.forEach((os: osType) => {
    if (operatingSystem && operatingSystem[os]) {
      operatingSystemNames += `${capitalize(os)}, `;
    }
  });

  if (operatingSystemNames !== '') {
    return (
      <>
        <TimelineContent
          icon={<OSIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={
            <>
              {translate('sections:targeting-summary-operating-system:message')}{' '}
              {operatingSystemNames}
            </>
          }
        />
      </>
    );
  }
};
