import ReduxStatus from '@enums/ReduxStatus';
import { AutomationState, EmailEditorState, Step } from './types';
import {
  EmailEditorSidebarTypes,
  EditorSidebarSteps,
  MailSidebarTabs,
} from '@enums/EmailEditorSidebarTypes';
import produce, { Draft } from 'immer';
import { ReduxAction } from 'global';
import {
  GET_AUTOMATION_SUCCESS,
  GET_AUTOMATION_ERROR,
  ORJ_DATA_TO_DATA,
  RESET,
  UPDATE_EDITOR_SIDEBAR,
  UPDATE_FLOW_STEP_ATTRIBUTE,
  UPDATE_TRIGGER,
  UPDATE_SHOPIFY_FLOW_WEBSITE,
  SET_AUTOMATION_CAMPAIGN,
  UPDATE_FREQUENCY_STATUS,
  SET_FLOW,
  SET_AUTOMATION_STATUS,
  GET_TRIGGERS_SUCCESS,
  SET_AUTOMATION_NAME,
  SET_RELATED_CAMPAIGN,
  UPDATE_EMAIL_CONTENT_ITEM_ATTRIBUTE,
  UPDATE_EMAIL_CONTENT_ITEM_ELEMENT_ATTRIBUTE,
  UPDATE_EMAIL_CONTENT_ITEM_VISIBILITY,
  ADD_EMAIL_CONTENT_ITEM_ELEMENT,
  MOVE_EMAIL_CONTENT_ITEM_ELEMENT,
  REMOVE_EMAIL_CONTENT_ITEM_ELEMENT,
  UPDATE_ISPLAINTEXT,
  UPDATE_PLAINTEXT_BODY,
  SAVE_EMAIL_TEMPLATE,
  UPDATE_STEPS,
  UPDATE_PLAINTEXT_FORM_ERROR,
} from './constants';
import cloneDeep from 'lodash/cloneDeep';

export const initialState: EmailEditorState = {
  status: ReduxStatus.initialized,
  hasPlainTextFormError: false,
  data: {
    automation: {} as AutomationState,
  },
  orjData: {
    automation: {} as AutomationState,
  },
  editorSidebar: {
    type: EmailEditorSidebarTypes.step,
    activeStepId: EditorSidebarSteps.Trigger,
    stepState: {
      [EditorSidebarSteps.Mail]: {
        activeTab: MailSidebarTabs.general,
        activeItemKey: null,
      },
    },
  },

  triggers: [],
};

const reducer = produce(
  (draft: Draft<EmailEditorState>, action: ReduxAction) => {
    const { type } = action;

    switch (type) {
      case GET_AUTOMATION_SUCCESS: {
        const { payload } = action;
        let { data } = payload;
        const { Flow: flow, ...rest } = data;
        const steps = JSON.parse(flow.steps);

        const newData = {
          flow: { ...flow, steps },
          ...rest,
        };

        if (!(draft.orjData && draft.data)) return;
        draft.orjData.automation = newData;
        draft.data.automation = newData;
        return;
      }
      case GET_AUTOMATION_ERROR: {
        draft.status = ReduxStatus.errored;
        return;
      }
      case UPDATE_SHOPIFY_FLOW_WEBSITE: {
        const { payload } = action;
        const { domain } = payload;
        if (
          !(
            draft.data &&
            draft.data?.automation &&
            draft.data.automation.store &&
            domain
          )
        )
          return;
        draft.data.automation.store.domain = domain;
        return;
      }
      case SET_AUTOMATION_CAMPAIGN: {
        const { payload } = action;
        const { campaignId } = payload;
        if (!(draft.data && draft.data?.automation)) return;
        draft.data.automation.campaignId = campaignId;
        return;
      }
      case SET_RELATED_CAMPAIGN: {
        const { payload } = action;
        const { relatedCampaign } = payload;
        draft.relatedCampaign = relatedCampaign;
        return;
      }

      case SET_AUTOMATION_NAME: {
        const { payload } = action;
        const { name } = payload;
        if (!(draft.data && draft.data?.automation)) return;
        draft.data.automation.name = name;
        return;
      }

      case UPDATE_FREQUENCY_STATUS: {
        const { payload } = action;
        const { frequencyStatus } = payload;
        if (!(draft.data && draft.data?.automation)) return;
        draft.data.automation.flow.frequencyStatus = frequencyStatus;
        return;
      }

      case SET_FLOW: {
        const { payload } = action;
        const { flow } = payload;

        const newFlow = { ...flow, steps: JSON.parse(flow.steps) };

        if (!(draft.data && draft.orjData)) return;
        draft.orjData.automation.flow = newFlow;
        return;
      }
      case SET_AUTOMATION_STATUS: {
        const { payload } = action;
        const { status } = payload;
        if (
          !(draft.data && draft.data?.automation) ||
          !(draft.orjData && draft.orjData?.automation)
        )
          return;
        draft.data.automation.status = status;
        draft.orjData.automation.status = status;

        return;
      }
      case ORJ_DATA_TO_DATA: {
        const { orjData } = draft;
        if (orjData) draft.data = orjData;
        return;
      }

      case UPDATE_EDITOR_SIDEBAR: {
        const { stepId, args } = action.payload;
        draft.editorSidebar.activeStepId = stepId;
        if (
          stepId === EditorSidebarSteps.Mail &&
          !draft.editorSidebar.stepState[stepId]?.activeTab &&
          !args.activeTav
        ) {
          args.activeTab = MailSidebarTabs.general;
        }
        const isFull =
          args.activeItemKey === 'productCards' ||
          args.activeItemKey === 'socialItem';
        const titles = {
          productCards: 'Product Cards',
          socialItem: 'Social Item',
        };
        const activeSidebarTitle =
          args.activeSidebarTitle ||
          titles[args.activeItemKey as keyof typeof titles];
        draft.editorSidebar.stepState = {
          [stepId]: { ...args, activeSidebarTitle, isFull },
        };
        return;
      }

      case UPDATE_EMAIL_CONTENT_ITEM_VISIBILITY: {
        const { key, state: newState } = action.payload;
        const mailStep = draft.data?.automation.flow.steps.find(
          (step) => step.type === EditorSidebarSteps.Mail,
        );
        if (!mailStep || !mailStep.documents) {
          return;
        }
        let document = mailStep.documents.find((doc) => doc.key === key);
        if (!document) {
          return;
        }
        document.attributes.isHidden = newState;
        return;
      }

      case UPDATE_FLOW_STEP_ATTRIBUTE: {
        const { payload } = action;
        const { attributePath, value } = payload;
        const activeStepId = draft.editorSidebar.activeStepId;

        const stepToUpdate = draft.data?.automation?.flow?.steps.find(
          (step) => step.type === activeStepId,
        );
        if (stepToUpdate) {
          const attributePathArr: string[] = attributePath.split('.');

          let targetAttribute: any = cloneDeep(stepToUpdate);

          // Traverse the attribute path
          for (let i = 0; i < attributePathArr.length - 1; i++) {
            const attr = attributePathArr[i];
            if (stepToUpdate[attr] !== undefined) {
              targetAttribute = stepToUpdate[attr];
            } else {
              // Attribute path is incorrect, return without changes
              return draft;
            }
          }

          const lastAttribute = attributePathArr[attributePathArr.length - 1];
          if (targetAttribute && lastAttribute) {
            // Update the attribute with the new value based on Step type definition
            if (
              (targetAttribute as Step).attributes &&
              lastAttribute in (targetAttribute as Step).attributes!
            ) {
              // Update the attribute within attributes object
              (targetAttribute as Step).attributes![lastAttribute] = value as
                | string
                | number;
            } else {
              // Update the top-level attribute directly
              (targetAttribute as Step)[lastAttribute] = value as
                | string
                | number;
            }
          }
        }
        return;
      }

      case UPDATE_EMAIL_CONTENT_ITEM_ATTRIBUTE: {
        const { id, attributePath, value } = action.payload;
        const key =
          id ||
          draft.editorSidebar?.stepState?.[EditorSidebarSteps.Mail]
            ?.activeItemKey;
        // Find the 'mail' step in the flow
        const mailStep = draft.data?.automation.flow.steps.find(
          (step) => step.type === 'mail',
        );

        if (!mailStep || !mailStep.documents) {
          return;
        }

        // Find the document with the given id within the mail step
        const document = mailStep.documents.find((doc) => doc.key === key);
        if (!document) {
          return;
        }

        // Split the attribute path and iterate through it
        const pathSegments = attributePath.split('.');
        let currentAttribute = document;

        for (let i = 0; i < pathSegments.length - 1; i++) {
          const segment = pathSegments[i];
          if (!(segment in currentAttribute)) {
            // Initialize the segment if it does not exist
            // @ts-ignore
            currentAttribute[segment] = {};
          }
          // @ts-ignore
          currentAttribute = currentAttribute[segment];
        }

        const finalSegment = pathSegments[pathSegments.length - 1];
        // Set the value for the final attribute, adding it if it doesn't exist
        // @ts-ignore
        currentAttribute[finalSegment] = value;

        return;
      }

      case UPDATE_EMAIL_CONTENT_ITEM_ELEMENT_ATTRIBUTE: {
        const { id, attributePath, parentKey, value } = action.payload;

        // Find the 'mail' step in the flow
        const mailStep = draft.data?.automation.flow.steps.find(
          (step) => step.type === 'mail',
        );

        if (!mailStep || !mailStep.documents) {
          return;
        }

        // Find the 'productCards' document
        const productCards = mailStep.documents.find(
          (doc) => doc.key === parentKey,
        );
        if (!productCards || !productCards.elements) {
          return;
        }

        // Find the specific element within productCards elements
        const elementToUpdate = productCards.elements.find(
          (el) => el.key === id || el.id === id,
        );
        if (!elementToUpdate) {
          return;
        }

        // Split the attribute path and iterate through it
        const pathSegments = attributePath.split('.');
        let currentAttribute = elementToUpdate;

        for (let i = 0; i < pathSegments.length - 1; i++) {
          const segment = pathSegments[i];
          if (!(segment in currentAttribute)) {
            // Initialize the segment if it does not exist
            // @ts-ignore
            currentAttribute[segment] = {};
          }
          // @ts-ignore
          currentAttribute = currentAttribute[segment];
        }

        const finalSegment = pathSegments[pathSegments.length - 1];
        // Set the value for the final attribute, adding it if it doesn't exist
        // @ts-ignore
        currentAttribute[finalSegment] = value;

        return;
      }

      case ADD_EMAIL_CONTENT_ITEM_ELEMENT: {
        const { key, value, index } = action.payload;

        // Find the 'mail' step in the flow
        const mailStep = draft.data?.automation.flow.steps.find(
          (step) => step.type === 'mail',
        );

        if (!mailStep || !mailStep.documents) {
          return;
        }

        // Find the specific document by id
        const document = mailStep.documents.find((doc) => doc.key === key);
        if (!document) {
          return;
        }

        // Ensure the document has an elements array
        if (!document.elements) {
          document.elements = [];
        }

        // Check if index is provided and valid, otherwise append to the end
        if (
          typeof index === 'number' &&
          index >= 0 &&
          index <= document.elements.length
        ) {
          document.elements.splice(index, 0, value);
        } else {
          document.elements.push(value);
        }

        return;
      }
      case MOVE_EMAIL_CONTENT_ITEM_ELEMENT: {
        const { elementId, parentKey, index } = action.payload;

        // Find the 'mail' step in the flow
        const mailStep = draft.data?.automation.flow.steps.find(
          (step) => step.type === 'mail',
        );

        if (!mailStep || !mailStep.documents) {
          return;
        }

        // Find the parent document by key
        const parentDocument = mailStep.documents.find(
          (doc) => doc.key === parentKey,
        );
        if (!parentDocument || !parentDocument.elements) {
          return;
        }

        // Find the index of the element to be moved
        const elementIndex = parentDocument.elements.findIndex(
          (el) => el.id === elementId,
        );
        if (elementIndex === -1) {
          return;
        }

        // Remove the element from its current position
        const [elementToMove] = parentDocument.elements.splice(elementIndex, 1);

        // Determine the new index for inserting the element
        const newIndex =
          index >= 0 && index <= parentDocument.elements.length
            ? index
            : parentDocument.elements.length;

        // Insert the element at the new index
        parentDocument.elements.splice(newIndex, 0, elementToMove);

        return;
      }
      case REMOVE_EMAIL_CONTENT_ITEM_ELEMENT: {
        const { elementId, parentKey } = action.payload;

        // Find the 'mail' step in the flow
        const mailStep = draft.data?.automation.flow.steps.find(
          (step) => step.type === 'mail',
        );

        if (!mailStep || !mailStep.documents) {
          return;
        }

        // Find the parent document by key
        const parentDocument = mailStep.documents.find(
          (doc) => doc.key === parentKey,
        );
        if (!parentDocument || !parentDocument.elements) {
          return;
        }

        // Find the index of the element to be removed
        const elementIndex = parentDocument.elements.findIndex(
          (el) => el.id === elementId,
        );
        if (elementIndex === -1) {
          return;
        }

        // Remove the element from the array
        parentDocument.elements.splice(elementIndex, 1);

        return;
      }

      case GET_TRIGGERS_SUCCESS: {
        const { payload } = action;
        let { data } = payload;
        const { items } = data;
        draft.triggers = items;
        return;
      }
      case UPDATE_TRIGGER: {
        const { triggerId } = action.payload;

        if (!(draft.data && draft.data.automation && draft.triggers)) return;
        draft.data.automation.flow.triggerId = Number(triggerId);
        draft.data.automation.flow.Trigger = draft.triggers.find(
          (trigger) => trigger.id === triggerId,
        );
        return;
      }

      case UPDATE_ISPLAINTEXT: {
        const { isPlainText } = action.payload;
        if (!(draft.data && draft.data.automation.flow)) return;

        draft.data.automation.flow.isPlainText = isPlainText;

        if (isPlainText) {
          draft.data.automation.flow.EmailTemplate = null;
          draft.data.automation.flow.emailTemplateId = null;
        }

        return;
      }

      case UPDATE_PLAINTEXT_BODY: {
        const { plainTextBody } = action.payload;
        if (!(draft.data && draft.data.automation.flow)) return;

        draft.data.automation.flow.plainTextBody = plainTextBody;
        return;
      }

      case UPDATE_PLAINTEXT_FORM_ERROR: {
        const { hasPlainTextFormError } = action.payload;
        if (!draft) return;

        draft.hasPlainTextFormError = hasPlainTextFormError;

        return;
      }

      case SAVE_EMAIL_TEMPLATE: {
        const { payload } = action;
        const { emailTemplate } = payload;

        if (!(draft.data && draft.data.automation.flow)) return;

        draft.data.automation.flow.EmailTemplate = emailTemplate;
        draft.data.automation.flow.emailTemplateId = emailTemplate.id;

        return;
      }

      case UPDATE_STEPS: {
        const { payload } = action;
        const { steps } = payload;

        if (!(draft.data && draft.data.automation.flow)) return;

        draft.data.automation.flow.steps = JSON.parse(steps);

        return;
      }

      //#endregion
      case RESET: {
        return initialState;
      }

      default: {
        return;
      }
    }
  },
  initialState,
);

export default reducer;
