import { RESET_STORE } from '@connectors/actions';
import ReduxStatus from '@enums/ReduxStatus';
import { ReduxAction } from 'global';

import {
  ANALYTICS_GET_FAILURE,
  BROWSERS_ANALYTICS_GET,
  BROWSERS_ANALYTICS_GET_SUCCESS,
  CAMPAIGNS_FOR_ANALYTICS_GET_SUCCESS,
  DEVICES_ANALYTICS_GET,
  DEVICES_ANALYTICS_GET_SUCCESS,
  RESOLUTIONS_ANALYTICS_GET,
  RESOLUTIONS_ANALYTICS_GET_SUCCESS,
  CLEAR_ANALYTICS_CAMPAIGN,
  CLEAR_ANALYTICS_CAMPAIGN_SUCCESS,
} from './constants';

export interface Statistic {
  impressionCount: number;
  pageViewCount: number;
  interactionCount: number;
  displayCount: number;
  lastDaysImpressionCount: number;
  lastMonthsImpressionCount: number;
  lastMonthsPageViewCount: number;
  rate: string;
}

export interface Campaign {
  id: number;
  name: string;
  imageUrl: string;
  domains?: string[];
}

// TODO remove after all usages are deleted
export interface ShortAnalytics {
  pageViews: number;
  fiveDaysEarlyPageViews: number;
  pageViewPercent: number;
  popupViews: number;
  fiveDaysEarlyPopupViews: number;
  popupViewPercent: number;
  leads: number;
  fiveDaysEarlyLeads: number;
  leadPercent: number;
  clicks: number;
  fiveDaysEarlyClicks: number;
  clickPercent: number;
  convensionRate: number;
  conversionRateIncriment: number;
}

export interface Devices {
  device: string;
  count: number;
  rate: number;
}

export interface Resolutions {
  resolution: string;
  count: number;
  rate: number;
}

export interface Browser {
  browser: string;
  count: number;
  rate: number;
}

interface AnalyticsState {
  status: ReduxStatus;
  devices: {
    status: ReduxStatus;
    data: Devices[];
  };
  resolutions: {
    status: ReduxStatus;
    data: Resolutions[];
  };
  browsers: {
    status: ReduxStatus;
    data: Browser[];
  };
  campaigns: {
    status: ReduxStatus;
    data: Campaign[];
  };
}

const initialState: AnalyticsState = {
  status: ReduxStatus.loading,
  campaigns: {
    status: ReduxStatus.loading,
    data: [],
  },
  devices: {
    status: ReduxStatus.loading,
    data: [],
  },
  resolutions: {
    status: ReduxStatus.loading,
    data: [],
  },
  browsers: {
    status: ReduxStatus.initialized,
    data: [],
  },
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case DEVICES_ANALYTICS_GET:
      return { ...state, devices: { status: ReduxStatus.loading, data: [] } };
    case DEVICES_ANALYTICS_GET_SUCCESS:
      return {
        ...state,
        devices: {
          data: action.payload,
          status: ReduxStatus.success,
        },
      };

    case RESOLUTIONS_ANALYTICS_GET:
      return {
        ...state,
        resolutions: { status: ReduxStatus.loading, data: [] },
      };
    case RESOLUTIONS_ANALYTICS_GET_SUCCESS:
      return {
        ...state,
        resolutions: {
          data: action.payload,
          status: ReduxStatus.success,
        },
      };

    case BROWSERS_ANALYTICS_GET:
      return { ...state, browsers: { status: ReduxStatus.loading, data: [] } };
    case BROWSERS_ANALYTICS_GET_SUCCESS:
      return {
        ...state,
        browsers: {
          data: action.payload,
          status: ReduxStatus.success,
        },
      };
    case CAMPAIGNS_FOR_ANALYTICS_GET_SUCCESS:
      return {
        ...state,
        campaigns: {
          data: action.payload,
          status: ReduxStatus.success,
        },
      };

    case CLEAR_ANALYTICS_CAMPAIGN:
      return {
        ...state,
        campaigns: { status: ReduxStatus.initialized, data: [] },
      };
    case CLEAR_ANALYTICS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: { status: ReduxStatus.success, data: [] },
      };

    case ANALYTICS_GET_FAILURE:
      return { ...state, status: ReduxStatus.errored };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
