import {
  CustomerIoInput,
  CustomerIoIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/CustomerIoResolver.types';

export const GetCustomerIoIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetCustomerIoIntegrationsByCampaignId',
  query: `query GetCustomerIoIntegrationsByCampaignId($campaignId: Int!) {
    getCustomerIoIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
      integration {
        apiKey
        id
        accountId
        name
        status
      }
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetCustomerIoIntegrationsByAccountId = () => ({
  operationName: 'GetCustomerIoIntegrationsByAccountId',
  query: `query GetCustomerIoIntegrationsByAccountId {
    getCustomerIoIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
      }
      apiKey
      siteId
    }
  }`,
});

export const CreateCustomerIo = (input: CustomerIoInput) => ({
  operationName: 'CreateCustomerIoIntegration',
  query: `mutation CreateCustomerIoIntegration($input: CustomerIoInput!) {
    createCustomerIoIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateCustomerIo = (
  id: number,
  input: CustomerIoIntegrationCampaignInput,
) => ({
  operationName: 'UpdateCustomerIoIntegrationCampaign',
  query: `mutation UpdateCustomerIoIntegrationCampaign($input: CustomerIoIntegrationCampaignInput!, $updateCustomerIoIntegrationCampaignId: Int!) {
    updateCustomerIoIntegrationCampaign(input: $input, id: $updateCustomerIoIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateCustomerIoIntegrationCampaignId: id,
  },
});

export const DeleteCustomerIoIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteCustomerIoIntegrationCampaign',
  query: `mutation DeleteCustomerIoIntegrationCampaign($deleteCustomerIoIntegrationCampaignId: Int!) {
    deleteCustomerIoIntegrationCampaign(id: $deleteCustomerIoIntegrationCampaignId)
  }`,
  variables: {
    deleteCustomerIoIntegrationCampaignId: id,
  },
});
