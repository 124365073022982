export const customerIoServiceURl = 'api/integrations/customerio';

export const CUSTOMERIO_GET_CONNECTIONS =
  'builder/integrations/CUSTOMERIO_GET_CONNECTIONS';
export const CUSTOMERIO_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/CUSTOMERIO_GET_CONNECTIONS_SUCCESS';
export const CUSTOMERIO_GET_CONNECTIONS_FAILURE =
  'builder/integrations/CUSTOMERIO_GET_CONNECTIONS_FAILURE';

export const CUSTOMERIO_GET_FIELDS =
  'builder/integrations/CUSTOMERIO_GET_FIELDS';
export const CUSTOMERIO_GET_FIELDS_SUCCESS =
  'builder/integrations/CUSTOMERIO_GET_FIELDS_SUCCESS';
export const CUSTOMERIO_GET_FIELDS_FAILURE =
  'builder/integrations/CUSTOMERIO_GET_FIELDS_FAILURE';

export const CUSTOMERIO_GET_LIST = 'builder/integrations/CUSTOMERIO_GET_LIST';
export const CUSTOMERIO_GET_LIST_SUCCESS =
  'builder/integrations/CUSTOMERIO_GET_LIST_SUCCESS';
export const CUSTOMERIO_GET_LIST_FAILURE =
  'builder/integrations/CUSTOMERIO_GET_LIST_FAILURE';

export const CUSTOMERIO_SAVE = 'builder/integrations/CUSTOMERIO_SAVE';
export const CUSTOMERIO_SAVE_SUCCESS =
  'builder/integrations/CUSTOMERIO_SAVE_SUCCESS';
export const CUSTOMERIO_SAVE_FAILURE =
  'builder/integrations/CUSTOMERIO_SAVE_FAILURE';

export const CUSTOMERIO_DELETE = 'builder/integrations/CUSTOMERIO_DELETE';
export const CUSTOMERIO_DELETE_SUCCESS =
  'builder/integrations/CUSTOMERIO_DELETE_SUCCESS';
export const CUSTOMERIO_DELETE_FAILURE =
  'builder/integrations/CUSTOMERIO_DELETE_FAILURE';

export const CUSTOMERIO_CHANGESTATUS =
  'builder/integrations/CUSTOMERIO_CHANGESTATUS';
export const CUSTOMERIO_CHANGESTATUS_SUCCESS =
  'builder/integrations/CUSTOMERIO_CHANGESTATUS_SUCCESS';
export const CUSTOMERIO_CHANGESTATUS_FAILURE =
  'builder/integrations/CUSTOMERIO_CHANGESTATUS_FAILURE';
export const CUSTOMERIO_CLEAR_STATE =
  'builder/integrations/CUSTOMERIO_CLEAR_STATE';
