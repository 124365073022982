import { ReduxAction } from 'global';
import { takeLatest, put, call, select } from 'redux-saga/effects';
// import { getBase64FromUrl } from '@utils/urlToBase64';
import { toast } from 'react-toastify';
import store from '@utils/store';

import {
  AUTO_SAVE_SUCCESS,
  AUTO_SAVE,
  CAMPAIGN_LAYOUT_GET_SUCCESS,
  CAMPAIGN_LAYOUT_GET,
  CAMPAIGN_GET,
  CAMPAIGN_GET_SUCCESS,
  DOMAIN_DELETE,
  DOMAIN_DELETE_SUCCESS,
  DOMAIN_SELECT,
  DOMAIN_SELECT_SUCCESS,
  DOMAIN_GET,
  DOMAIN_GET_SUCCESS,
  CAMPAIGN_FAILURE,
  PUBLISH,
  PUBLISH_SUCCESS,
  PUBLISH_FAILURE,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  PREVIEW_ACTIVE,
  PREVIEW_ACTIVE_SUCCESS,
  SAVE_LAYOUT,
  SAVE_LAYOUT_SUCCESS,
  SEND_EMBED_CODE_REQUEST,
  SEND_EMBED_CODE_SUCCESS,
  SET_SETTINGS_REQUEST,
  SET_SETTINGS_SUCCESS,
  SET_SETTINGS_FAILURE,
  CLEAR_CAMPAIGN,
  CLEAR_CAMPAIGN_SUCCESS,
  SAVE_PAGE_INDEX,
  SAVE_PAGE_INDEX_SUCCESS,
  SAVE_ACTIVE_DEVICE,
  SAVE_ACTIVE_DEVICE_SUCCESS,
  TAKE_SCREENSHOT_REQUEST,
  TAKE_SCREENSHOT_SUCCESS,
  TAKE_SCREENSHOT_FAILURE,
  UPDATE_AUTO_RESPONDER_REQUEST,
  UPDATE_AUTO_RESPONDER_SUCCESS,
  UPDATE_AUTO_RESPONDER_FAILURE,
  DISABLE_EDIT_MOBILE_MODAL_REQUEST,
  DISABLE_EDIT_MOBILE_MODAL_SUCCESS,
  CAMPAIGN_DETAILS_GET,
  CAMPAIGN_DETAILS_GET_SUCCESS,
} from './constants';

import fetch from '@utils/fetch';
import { IntegrationListObject } from '@connectors/integration/reducers';

import type { TargetingState } from './reducer';
import type { Targets } from '@connectors/targets/reducer';
import { SET_TARGETING } from '@connectors/targets/constants';
import { CHANGE_CAMPAIGN_STATUS_REQUEST } from '@connectors/campaigns/constants';
import guid from '@utils/guid';

export type Contents = {
  id: string;
  template: any;
  title: string;
  type: string;
  content: {
    mobile: any;
    desktop: any;
  };
  appearance: any;
};

type Layout = {
  contents: Contents[];
};

export type Template = {
  name?: string;
  popupType?: number;
  layout: Layout;
  targets?: Targets;
  imageUrl?: string;
};

interface IAutoResponder {
  id: string;
  autoResponderReplyTo: string;
  autoResponderStatus: boolean;
  autoResponderSenderName: string;
  autoResponderSubject: string;
  autoResponderMailBody: string;
}

export interface Campaign extends IAutoResponder {
  id: string;
  name: string;
  domainId?: number;
  campaignGoalId?: number;
  jsonData?: string;
  status?: boolean;
  conversionCount?: number;
  conversionCountCurrency?: string;
  layout: Layout;
  targets: TargetingState;
  mobileScreenshot: null;
  desktopScreenshot: null;
  settings: {
    [key: string]: any;
  };
  campaignGoal?: {
    id: number;
    imageUrl: string;
    name: string;
    description: string;
  };
  domains?: {
    id: number;
    userId: number;
    url: string;
    verified: boolean;
  }[];
  impressionCount?: number;
  interactionCount?: number;
  displayCount?: number;
  rate?: string;
  integrationActiveCampaignCampaigns: IntegrationListObject[];
  integrationCustomerIoCampaigns: IntegrationListObject[];
  integrationDripCampaigns: IntegrationListObject[];
  integrationEmailOctopusCampaigns: IntegrationListObject[];
  integrationHubSpotCampaigns: IntegrationListObject[];
  integrationKlaviyoCampaigns: IntegrationListObject[];
  integrationMailchimpCampaigns: IntegrationListObject[];
  integrationMailerliteCampaigns: IntegrationListObject[];
  integrationSendgridCampaigns: IntegrationListObject[];
  integrationSendinBlueCampaigns: IntegrationListObject[];
  integrationSendLoopCampaigns: IntegrationListObject[];
  integrationWebhooks: IntegrationListObject[];
}
export interface Domain {
  id?: number;
  url?: string;
  verified?: boolean;
}

function* handleGetCampaign(payload: ReduxAction) {
  const { campaignId } = payload;

  try {
    const data: Campaign = yield call(fetch, `api/Campaign/${campaignId}`, {
      method: 'GET',
    });
    yield put({
      type: CAMPAIGN_GET_SUCCESS,
      payload: { ...data, id: campaignId },
    });
    yield put({ type: 'SET_TARGETING', targets: data.targets });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleCreateCampaign(payload: ReduxAction) {
  const { name, website, onSuccess } = payload;
  const defaultValue = {
    name,
    domains: [website.id],
    targets: {
      visitorDevice: {
        mobile: false,
        desktop: true,
      },
      newOrReturningVisitors: 'ALL',
      operatingSystem: {
        windows: true,
        macOs: true,
        linux: true,
        chromium: true,
        android: true,
        ios: true,
      },
      displayFrequency: {
        showAgain: {
          type: 'ON_EVERY_PAGE',
          options: {
            time: 'DAYS',
            value: 1,
          },
        },
        stopShowing: {
          type: 'NEVER',
          options: {
            clickClose: false,
            seenCampaign: false,
            seenCampaignValue: '1',
            signedUpOrClicked: false,
          },
        },
      },
    },
    layout: {},
    settings: {},
  };

  try {
    const data: Campaign = yield call(fetch, `/api/Campaign`, {
      method: 'POST',
      data: defaultValue,
    });

    yield put({ type: 'SET_TARGETING', targets: defaultValue.targets });
    yield put({
      type: CREATE_CAMPAIGN_SUCCESS,
      payload: { ...data, ...defaultValue, domains: [website] },
    });
    onSuccess && onSuccess(data?.id);
  } catch (error) {
    yield put({ type: CREATE_CAMPAIGN_FAILURE });
  }
}

function* handleGetCampaignLayout(payload: ReduxAction) {
  const { templateId, imageUrl, name, isTemplateSelected } = payload;

  try {
    const data: Template = yield call(
      fetch,
      `/api/Campaign/template/${templateId}`,
      { method: 'GET' },
    );

    const templateContents = data.layout.contents;
    const templateTargets = data?.targets;

    const layout: Layout = yield select((state) => state.campaign.layout);
    const campaignTargets: Targets = yield select(
      (state) => state.campaign.targets,
    );
    const campaignId: string = yield select((state) => state.campaign.id);

    const template = {
      id: templateId,
      imageUrl,
      name,
    };

    layout.contents = [...templateContents];
    if (layout.contents.length) {
      layout.contents.forEach((content) => {
        if (!content.id) content.id = guid();
        content.template = template;
      });
    }

    const targets = templateTargets?.visitorDevice
      ? { ...campaignTargets, visitorDevice: templateTargets.visitorDevice }
      : { ...campaignTargets, visitorDevice: { mobile: true, desktop: true } };

    const content = {
      layout,
      targets,
      templateId,
      popupType: data.popupType,
    };
    yield put({
      type: AUTO_SAVE,
      campaignId,
      content,
      ...(isTemplateSelected && { isTemplateSelected: true }),
    });
    yield put({
      type: SET_TARGETING,
      targets,
    });
    yield put({
      type: CAMPAIGN_LAYOUT_GET_SUCCESS,
      payload: { layout },
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleGetCampaignDetails(payload: ReduxAction) {
  const { templateId } = payload;
  try {
    const data: Template = yield call(
      fetch,
      `/api/Campaign/template/${templateId}`,
      { method: 'GET' },
    );
    yield put({
      type: CAMPAIGN_DETAILS_GET_SUCCESS,
      payload: { ...data },
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleSave(payload: ReduxAction) {
  const { campaignId, content, screenshot, isTemplateSelected } = payload;
  try {
    yield call(fetch, `/api/Campaign/${campaignId}`, {
      method: 'PUT',
      data: {
        ...content,
        ...(isTemplateSelected && { isTemplateSelected: true }),
      },
    });

    yield put({
      type: AUTO_SAVE_SUCCESS,
      payload: {
        id: campaignId,
        ...content,
        ...(screenshot && { desktopScreenshot: screenshot }),
        ...(isTemplateSelected && { isTemplateSelected: true }),
      },
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleSaveLayout(payload: ReduxAction) {
  const { content } = payload;
  try {
    yield put({
      type: SAVE_LAYOUT_SUCCESS,
      payload: { ...content },
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleSavePageIndex(payload: ReduxAction) {
  const { pageIndex } = payload;
  try {
    yield put({
      type: SAVE_PAGE_INDEX_SUCCESS,
      payload: pageIndex,
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handlePreviewActive(payload: ReduxAction) {
  const { showSidebar } = payload;
  try {
    yield put({
      type: PREVIEW_ACTIVE_SUCCESS,
      payload: showSidebar,
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleSaveActiveDevice(payload: ReduxAction) {
  const { activeDevice } = payload;
  try {
    yield put({
      type: SAVE_ACTIVE_DEVICE_SUCCESS,
      payload: activeDevice,
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleClearCampaign() {
  try {
    yield put({ type: CLEAR_CAMPAIGN_SUCCESS });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handlePublish(payload: ReduxAction) {
  try {
    const { campaignId, content } = payload;

    yield call(fetch, `/api/Campaign/${campaignId}`, {
      method: 'PUT',
      data: {
        ...content,
      },
    });
    yield call(fetch, `/api/Campaign/publish`, {
      method: 'POST',
      data: {
        campaignId,
      },
    });

    yield put({ type: PUBLISH_SUCCESS });
    //The status should be turned into true when publish is success
    //Even if it was false before the publish
    const changeStatusPayload = {
      id: Number(campaignId),
      status: true,
    };
    yield put({
      type: CHANGE_CAMPAIGN_STATUS_REQUEST,
      payload: changeStatusPayload,
    });
  } catch (error) {
    yield put({ type: PUBLISH_FAILURE });
  }
}

function* handleDeleteDomain(payload: ReduxAction) {
  const { domainId } = payload;
  try {
    yield put({ type: DOMAIN_DELETE_SUCCESS, payload: domainId });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleSelectDomain(payload: ReduxAction) {
  const { domainId, campaignId } = payload;
  try {
    const data: Campaign = yield call(fetch, `/api/Campaign/add-domain`, {
      method: 'POST',
      data: {
        domainId,
        campaignId,
      },
    });

    yield put({ type: DOMAIN_SELECT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}
function* getDomainsSaga() {
  try {
    const data: Domain[] = yield call(fetch, `api/Domain`, {
      method: 'GET',
    });
    yield put({ type: DOMAIN_GET_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* sendEmbedCodeSaga({ payload }: ReduxAction) {
  try {
    yield call(fetch, `/api/Campaign/sendembedcode`, {
      method: 'POST',
      data: {
        email: payload,
      },
    });
    yield put({ type: SEND_EMBED_CODE_SUCCESS });
    //Send a success toastr when embed code is sent to developer
    yield call(
      toast.success,
      `Embed code is successfully sent to your developer.`,
    );
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

function* handleSetSettingsSaga(payload: ReduxAction) {
  const { campaignId, settings } = payload;
  try {
    yield call(fetch, `/api/Campaign/${campaignId}`, {
      method: 'PUT',
      data: {
        settings,
      },
    });

    yield put({
      type: SET_SETTINGS_SUCCESS,
      payload: { id: campaignId, settings },
    });
  } catch (error) {
    yield put({ type: SET_SETTINGS_FAILURE });
  }
}

function* handleTakeScreenshot({ payload }: ReduxAction) {
  try {
    const { activeDevice, base64 } = payload;
    //If activeDevice is null-ish, get desktopScreenshot
    const newPayload =
      activeDevice === 'mobile'
        ? { mobileScreenshot: base64 }
        : { desktopScreenshot: base64 };

    yield put({
      type: TAKE_SCREENSHOT_SUCCESS,
      payload: newPayload,
    });
  } catch (error) {
    yield put({ type: TAKE_SCREENSHOT_FAILURE });
  }
}

function* updateAutoResponderSaga({ payload }: ReduxAction) {
  const { id } = payload;
  try {
    const data: IAutoResponder = yield call(
      fetch,
      `/api/Campaign/${id}/auto-responder`,
      {
        method: 'PATCH',
        data: payload,
      },
    );
    yield put({ type: UPDATE_AUTO_RESPONDER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_AUTO_RESPONDER_FAILURE });
  }
}

function* disableEditMobileModal() {
  try {
    store.set('editMobileModalDisabled', true);
    yield put({ type: DISABLE_EDIT_MOBILE_MODAL_SUCCESS });
  } catch (error) {
    yield put({ type: CAMPAIGN_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(CAMPAIGN_GET, handleGetCampaign);
  yield takeLatest(DOMAIN_DELETE, handleDeleteDomain);
  yield takeLatest(DOMAIN_SELECT, handleSelectDomain);
  yield takeLatest(AUTO_SAVE, handleSave);
  yield takeLatest(CAMPAIGN_LAYOUT_GET, handleGetCampaignLayout);
  yield takeLatest(CAMPAIGN_DETAILS_GET, handleGetCampaignDetails);
  yield takeLatest(DOMAIN_GET, getDomainsSaga);
  yield takeLatest(PUBLISH, handlePublish);
  yield takeLatest(CREATE_CAMPAIGN_REQUEST, handleCreateCampaign);
  yield takeLatest(SAVE_LAYOUT, handleSaveLayout);
  yield takeLatest(SEND_EMBED_CODE_REQUEST, sendEmbedCodeSaga);
  yield takeLatest(SET_SETTINGS_REQUEST, handleSetSettingsSaga);
  yield takeLatest(CLEAR_CAMPAIGN, handleClearCampaign);
  yield takeLatest(SAVE_PAGE_INDEX, handleSavePageIndex);
  yield takeLatest(PREVIEW_ACTIVE, handlePreviewActive);
  yield takeLatest(SAVE_ACTIVE_DEVICE, handleSaveActiveDevice);
  yield takeLatest(TAKE_SCREENSHOT_REQUEST, handleTakeScreenshot);
  yield takeLatest(UPDATE_AUTO_RESPONDER_REQUEST, updateAutoResponderSaga);
  yield takeLatest(DISABLE_EDIT_MOBILE_MODAL_REQUEST, disableEditMobileModal);
}
