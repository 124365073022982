import { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';

import cn from 'classnames';
import type { ElementsFilterProps } from './types.d';
import TickIcon from '@assets/icons/tick-icon-3.svg';
import NextIcon from '@assets/icons/next-light.svg';
import PrevIcon from '@assets/icons/prev-light.svg';
import styles from './ElementsFilter.module.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { deNormalizeElement } from '@utils/element';

const ElementsFilter: FC<ElementsFilterProps> = ({
  elements,
  onElementClick,
  activeElements,
  loading,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [endOverflow, setEndOverflow] = useState<boolean>(false);
  const [slideConfig, setSlideConfig] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const handleElementClick = (elementName: string) => {
    // it should update because we can't go to end if SwiperSlides are active and therefore larger
    swiper?.updateSlides();
    updateIsEnd();
    onElementClick(elementName);
  };

  const updateIsEnd = () => {
    const swiperContainer = document.querySelector('#elements-swiper');
    const swiperSlides = swiperContainer?.querySelectorAll('.swiper-slide');
    const lastSlide = swiperSlides?.[swiperSlides?.length - 1];
    const firstSlide = swiperSlides?.[0];
    if (
      !swiperContainer ||
      !swiperSlides ||
      swiperSlides.length === 0 ||
      !lastSlide ||
      !firstSlide
    ) {
      // @ts-ignore
      const isEnd = Math.abs(swiper?.translate - swiper?.maxTranslate()) > 1;
      setEndOverflow(isEnd);
      return;
    }

    const swiperContainerRight = swiperContainer.getBoundingClientRect().right;
    const lastSlideRight = lastSlide.getBoundingClientRect().right;
    const swiperContainerLeft = swiperContainer.getBoundingClientRect().left;
    const lastSlideLeft = firstSlide.getBoundingClientRect().left;

    setEndOverflow(lastSlideRight - 0.1 - swiperContainerRight > 0);

    setSlideConfig({
      ...slideConfig,
      isBeginning: lastSlideLeft - swiperContainerLeft > 0,
    });
  };

  useEffect(() => {
    if (swiper?.destroyed) return;
    updateIsEnd();
  }, [elements]);

  const isEnd =
    (!(swiper?.isEnd || slideConfig?.isEnd) ||
      (endOverflow && !swiper?.destroyed)) &&
    elements &&
    elements?.length > 0;

  return (
    <div className={styles.elementsWrapper}>
      {!(swiper?.isBeginning || slideConfig?.isBeginning) &&
        !swiper?.destroyed && (
          <div className={styles.start}>
            <>
              <button
                onClick={() => swiper?.slidePrev()}
                id="next-prev-button"
                className="absolute top-[1px] left-0 w-[30px] h-[30px] z-10 bg-white"
              >
                <PrevIcon className="pointer-events-none" />
              </button>
            </>
          </div>
        )}

      {loading && (
        <div className="flex gap-[5px] overflow-hidden">
          {Array.from(Array(30).keys()).map((elementName, index) => {
            return (
              <Skeleton
                width={90}
                height={32}
                key={index}
                className="!inline-block !rounded-[160px] bg-[#F5F5F5] border border-[#DDD] "
                containerClassName="!max-h-[32px"
              />
            );
          })}
        </div>
      )}

      {elements && elements.length > 0 && !loading && (
        <Swiper
          onSwiper={setSwiper}
          slidesPerView="auto"
          spaceBetween={6}
          onSlideChange={(swipe) => {
            setSlideConfig({
              isBeginning: swipe.isBeginning,
              isEnd: swipe.isEnd,
            });
          }}
          onSliderMove={() => {
            updateIsEnd();
          }}
          id="elements-swiper"
          onReachBeginning={() => {
            if (swiper?.destroyed) return;
            // it should update because we can't go to end if SwiperSlides are active and large
            swiper?.updateSlides();
            setSlideConfig({
              ...slideConfig,
              isBeginning: true,
            });
          }}
          onReachEnd={() => {
            setEndOverflow(false);
            if (swiper?.destroyed) return;
            // it should update because we can't go to end if SwiperSlides are active and large
            swiper?.updateSlides();
            setSlideConfig({
              ...slideConfig,
              isEnd: true,
            });
          }}
        >
          {elements?.map((elementName) => {
            const isActiveElement = activeElements.includes(
              deNormalizeElement(elementName),
            );
            return (
              <SwiperSlide key={elementName} className="!w-auto">
                <div
                  className={cn(
                    'w-min whitespace-nowrap gap-[5px] rounded-[160px] bg-[#F5F5F5] border border-[#DDD] text-xs leading-[16px] px-[12px] py-[7px] cursor-pointer text-[#777777]',
                    {
                      '!bg-[#777] !text-[#fff]': isActiveElement,
                    },
                  )}
                  onClick={() => handleElementClick(elementName)}
                >
                  <span className="flex gap-[5px]">
                    {isActiveElement && (
                      <TickIcon
                        width="16"
                        height="16"
                        viewBox="0 0 12 12"
                        fill="#000"
                      />
                    )}
                    {elementName}
                  </span>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {isEnd && (
        <div className={styles.end}>
          <>
            <button
              onClick={() => {
                swiper?.slideNext();
                swiper?.updateSlides();
              }}
              id="next-slider-button"
              className="absolute top-[1px] right-0 w-[30px] h-[30px] z-10 bg-white"
            >
              <NextIcon className="pointer-events-none" />
            </button>
          </>
        </div>
      )}
    </div>
  );
};

export default ElementsFilter;
