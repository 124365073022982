import { FC, useState } from 'react';
import cn from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import CopyIcon from '@assets/icons/duplicate-icon.svg';
import styles from './Code.module.scss';
import { CodeProps } from './types';

const Code: FC<CodeProps> = ({
  code,
  useCopy = false,
  language = 'htmlbars',
  copyButtonOnLeft = false,
  className,
  copyButtonClassName = '',
  preTagStylings = {},
}) => {
  const [copyCode, setCopyCode] = useState<boolean>(false);
  const copyEmbedCode = () => {
    setCopyCode(true);

    setTimeout(() => {
      setCopyCode(false);
    }, 750);
  };
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.code}>
        <SyntaxHighlighter
          language={language}
          style={vs2015}
          customStyle={{
            borderRadius: 8,
            fontWeight: 400,
            fontSize: 14,
            fontFamily: 'Ubuntu Mono',
            lineHeight: '16px',
            background: 'transparent',
            padding: useCopy ? '20px 20px 6px 20px' : 20,
            wordBreak: 'break-all',
            ...preTagStylings,
          }}
          wrapLongLines={true}
        >
          {code}
        </SyntaxHighlighter>
      </div>
      <div
        className={cn(styles.buttonWrapper, `${copyButtonClassName}`, {
          '!justify-start': copyButtonOnLeft,
        })}
      >
        <CopyToClipboard text={code} onCopy={copyEmbedCode}>
          <button
            type="button"
            disabled={copyCode}
            className="shadow-lg shadow-black"
          >
            {copyCode ? (
              'Copied'
            ) : (
              <>
                <div className={styles.icon}>
                  <CopyIcon width="14px" height="14px" viewBox="0 0 17 17" />
                </div>
                <div className={styles.text}>Copy to clipboard</div>
              </>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default Code;
