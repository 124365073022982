import { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import PSButton from '@elements/PSButton';
import ThemeItem from '../ThemeItem';
import ThemeList from '@components/StyleSidebar/ThemeList';
import styles from './themeSelector.module.scss';
import { ThemeSelectorProps } from './types';
import { useTranslation } from 'react-i18next';

const ThemeSelector: FC<ThemeSelectorProps> = ({
  onClose,
  onSelectTheme,
  onApplyTheme,
  menuClassName,
  modalClassName,
  selectedThemeCode,
}) => {
  const { t } = useTranslation();
  const [lastThemeCode, setLastThemeCode] = useState<string | null>(null);

  const handleApplyClick = () => {
    onApplyTheme();
    onClose && onClose();
  };

  const handleOnClickThemeItem = (code: string) => {
    onSelectTheme(code);
    setLastThemeCode(code === selectedThemeCode ? null : code);
  };

  // if theme modal is out of screen stick apply button to scroll
  useEffect(() => {
    const styleNavigationButtons = document.querySelector(
      '#style-navigation-buttons',
    ) as HTMLElement;
    const applyThemeButton = document.querySelector(
      '#apply-theme-button',
    ) as HTMLElement;
    const themesContainer = document.querySelector(
      '#themes-modal-container',
    ) as HTMLElement;

    const handleScroll = () => {
      if (styleNavigationButtons && applyThemeButton && themesContainer) {
        const containerPosition = themesContainer.getBoundingClientRect();
        const navigationPosition =
          styleNavigationButtons.getBoundingClientRect();

        if (navigationPosition.top < containerPosition.bottom) {
          applyThemeButton.style.setProperty(
            'bottom',
            `${containerPosition.bottom - navigationPosition.top - 4}px`,
          );
        } else {
          applyThemeButton.style.setProperty('bottom', `0px`);
        }
      }
    };

    const builderSidebar = document.querySelector('.builder-sidebar');
    if (builderSidebar) {
      builderSidebar.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (builderSidebar) {
        builderSidebar.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className={styles.modalWrapper}>
      <div className={cn(styles.themeModal, `${modalClassName}`)}>
        <div
          className={cn(styles.themeContainer, 'scrollbar', `${menuClassName}`)}
          id="themes-modal-container"
        >
          <div className={styles.title}>{t('common:themes')}</div>
          {ThemeList.map((theme) => {
            return (
              <ThemeItem
                key={theme.code}
                {...theme}
                onClick={handleOnClickThemeItem}
              />
            );
          })}
        </div>
        <div
          className="absolute bottom-[0px] pt-[10px] px-[20px]  bg-white  border-t border-[#EAEAEA] flex flex-col z-[10] w-full rounded-b-[15px]"
          id="apply-theme-button"
        >
          <PSButton
            type="button"
            size="sm"
            onClick={handleApplyClick}
            textClassName="truncate max-w-[168px]"
            className="h-9 !w-full !text-[12px] !leading-[16px] !font-medium !mb-[16px] disabled:!bg-[#77777780]"
            id="target-to-settings-button"
            disabled={lastThemeCode == null}
          >
            {t('sections:theme-accordion:applyTheme')}
          </PSButton>
        </div>
      </div>
    </div>
  );
};
export default ThemeSelector;
