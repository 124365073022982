import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  MAILCHIMP_GET_FIELDS,
  MAILCHIMP_GET_FIELDS_SUCCESS,
  MAILCHIMP_GET_FIELDS_FAILURE,
  MAILCHIMP_GET_CONNECTIONS,
  MAILCHIMP_GET_CONNECTIONS_SUCCESS,
  MAILCHIMP_GET_CONNECTIONS_FAILURE,
  MAILCHIMP_GET_LIST,
  MAILCHIMP_GET_LIST_SUCCESS,
  MAILCHIMP_GET_LIST_FAILURE,
  MAILCHIMP_SAVE,
  MAILCHIMP_SAVE_SUCCESS,
  MAILCHIMP_SAVE_FAILURE,
  MAILCHIMP_DELETE,
  MAILCHIMP_DELETE_FAILURE,
  MAILCHIMP_CLEAR_STATE,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import { IntegrationSaveResult } from '../type';
import {
  CreateMailChimp,
  DeleteMailChimpIntegrationCampaign,
  GetMailChimpFields,
  GetMailChimpIntegrationsByAccountId,
  GetMailChimpIntegrationsByCampaignId,
  GetMailChimpLists,
  UpdateMailChimp,
} from './graphql';
import graphqlFetch from '@utils/graphqlFetch';
import { MailChimpIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/MailChimpResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getMailChimpIntegrationsByAccountId: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailChimpIntegrationsByAccountId(),
    });

    yield put({
      type: MAILCHIMP_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getMailChimpIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: MAILCHIMP_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, listId, onSuccess } = action.payload;
  try {
    const result: {
      data: { getMailChimpFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailChimpFields(apiKey, listId),
    });
    onSuccess && onSuccess();
    yield put({
      type: MAILCHIMP_GET_FIELDS_SUCCESS,
      payload: result.data.getMailChimpFields,
    });
  } catch (error) {
    yield put({ type: MAILCHIMP_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;
  try {
    const result: {
      data: { getMailChimpLists: ApiListObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailChimpLists(apiKey),
    });

    onSuccess && onSuccess();
    yield put({
      type: MAILCHIMP_GET_LIST_SUCCESS,
      payload: result.data.getMailChimpLists,
    });
  } catch (error) {
    yield put({ type: MAILCHIMP_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  let result = {} as IntegrationSaveResult;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateMailChimp(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });

      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateMailChimp({ ...formData, status: true }),
      });
    }
    const integrations: {
      data: {
        getMailChimpIntegrationsByCampaignId: MailChimpIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailChimpIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getMailChimpIntegrationsByCampaignId,
        type: IntegrationEnums.mailchimp,
      }),
    );
    yield put({ type: MAILCHIMP_SAVE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MAILCHIMP_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteMailChimpIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.mailchimp,
      }),
    );
    yield put({ type: MAILCHIMP_CLEAR_STATE });
  } catch (error) {
    yield put({ type: MAILCHIMP_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(MAILCHIMP_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(MAILCHIMP_GET_FIELDS, handleGetFields);
  yield takeLatest(MAILCHIMP_GET_LIST, handleGetList);
  yield takeLatest(MAILCHIMP_SAVE, handleSave);
  yield takeLatest(MAILCHIMP_DELETE, handleDelete);
}
