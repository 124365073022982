export const sendgridServiceURl = 'api/integrations/sendgrid';

export const SENDGRID_GET_CONNECTIONS =
  'builder/integration/SENDGRID_GET_CONNECTIONS';
export const SENDGRID_GET_CONNECTIONS_SUCCESS =
  'builder/integration/SENDGRID_GET_CONNECTIONS_SUCCESS';
export const SENDGRID_GET_CONNECTIONS_FAILURE =
  'builder/integration/SENDGRID_GET_CONNECTIONS_FAILURE';

export const SENDGRID_GET_FIELDS = 'builder/integration/SENDGRID_GET_FIELDS';
export const SENDGRID_GET_FIELDS_SUCCESS =
  'builder/integration/SENDGRID_GET_FIELDS_SUCCESS';
export const SENDGRID_GET_FIELDS_FAILURE =
  'builder/integration/SENDGRID_GET_FIELDS_FAILURE';

export const SENDGRID_GET_LIST = 'builder/integration/SENDGRID_GET_LIST';
export const SENDGRID_GET_LIST_SUCCESS =
  'builder/integration/SENDGRID_GET_LIST_SUCCESS';
export const SENDGRID_GET_LIST_FAILURE =
  'builder/integration/SENDGRID_GET_LIST_FAILURE';

export const SENDGRID_SAVE = 'builder/integration/SENDGRID_SAVE';
export const SENDGRID_SAVE_SUCCESS =
  'builder/integration/SENDGRID_SAVE_SUCCESS';
export const SENDGRID_SAVE_FAILURE =
  'builder/integration/SENDGRID_SAVE_FAILURE';

export const SENDGRID_DELETE = 'builder/integration/SENDGRID_DELETE';
export const SENDGRID_DELETE_SUCCESS =
  'builder/integration/SENDGRID_DELETE_SUCCESS';
export const SENDGRID_DELETE_FAILURE =
  'builder/integration/SENDGRID_DELETE_FAILURE';

export const SENDGRID_CHANGESTATUS =
  'builder/integration/SENDGRID_CHANGESTATUS';
export const SENDGRID_CHANGESTATUS_SUCCESS =
  'builder/integration/SENDGRID_CHANGESTATUS_SUCCESS';
export const SENDGRID_CHANGESTATUS_FAILURE =
  'builder/integration/SENDGRID_CHANGESTATUS_FAILURE';
export const SENDGRID_CLEAR_STATE = 'builder/integration/SENDGRID_CLEAR_STATE';
