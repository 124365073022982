import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import type { AddWebSiteModalFormValues, AddWebSiteModalProps } from './types';
import styles from './add-website-modal.module.scss';

import PSButton from '@elements/PSButton';
import PSForm, { Option, PSFormField, PSSelectbox } from '@elements/PSForm';

import { selectDomain } from '@connectors/builder/settings/actions';
import { RootState } from '@connectors/reducers';
import { hideModal, showModal } from '@connectors/modal/actions';
import { Website } from '@connectors/websites/reducer';

import AddIcon from '@assets/icons/add_circle_blue_stroke.svg';
import { getWebsites } from '@connectors/websites/actions';
import {
  makeSelectOrjCampaignDetail,
  makeSelectCampaignFetchStatus,
  makeSelectOrjCampaignDomains,
} from '@connectors/builder/selectors';
import { CampaignDetail } from '@connectors/builder/types';
import ReduxStatus from '@enums/ReduxStatus';
import { useAuth } from '@utils/useAuth';
import { makeSelectModals } from '@connectors/modal/selectors';
import { useAddDomainRelationMutation } from '@generated/graphql';
import UnlockFeaturesModalTypes from '@enums/UnlockFeaturesModalEnums';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  domainId: yup.string().required('Please select a domain'),
});

const AddWebSiteModal: FC<AddWebSiteModalProps> = ({ campaignId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const websites = useSelector((state: RootState) => state.websites);

  const { auth } = useAuth();
  const campaignDetail: CampaignDetail = useSelector(
    makeSelectOrjCampaignDetail,
  );
  const domains = useSelector(makeSelectOrjCampaignDomains);
  const campaignFetchStatus = useSelector(makeSelectCampaignFetchStatus);
  const modalState = useSelector(makeSelectModals);

  const methods = useForm<AddWebSiteModalFormValues>({
    defaultValues: { domainId: 0 },
    resolver: yupResolver(schema),
  });

  const [addDomainRelation, { loading: isLoadingSaveButton }] =
    useAddDomainRelationMutation();

  const getWebsiteList = (): Option[] => {
    const allWebsite = websites.items;
    const result: Option[] = [];
    allWebsite.map((website: Website) => {
      const checkAddedWebsite = domains?.find((x) => x.url === website.url);
      if (!checkAddedWebsite) {
        result.push({
          value: website.id.toString(),
          label: website.url,
        } as Option);
      }
    });

    return result.sort(
      (a: Option, b: Option) => Number(b.value) - Number(a.value),
    );
  };

  useEffect(() => {
    if (websites.status === 'initialized') {
      dispatch(getWebsites());
    }
  }, []);

  useEffect(() => {
    if (campaignFetchStatus === ReduxStatus.loading) {
      return;
    }
    const lastWebSite = Math.max(
      ...getWebsiteList().map((o: Option) => parseInt(o.value as string, 10)),
    );
    methods.setValue('domainId', lastWebSite);
  }, [websites, domains]);

  const onClickAddNewWebSite = () => {
    if (websites.items.length >= auth.accountPlan.domainCount) {
      dispatch(hideModal('addWebsite'));
      dispatch(
        showModal({
          id: 'UnlockFeaturesModal',
          data: {
            type: UnlockFeaturesModalTypes.WEBSITE,
          },
        }),
      );
    } else {
      dispatch(
        showModal({
          id: 'addNewWebsite',
          data: {
            ...(modalState[0]?.data ?? []),
            campaignId: (campaignId || campaignDetail.id) as string,
          },
        }),
      );
      (modalState[0]?.data?.onClickAddNewWebSite as () => {})?.();
    }
  };

  const onClickCancel = () => {
    modalState[0]?.data?.noHideAddWebsiteModal !== true &&
      dispatch(hideModal('addWebsite'));
    (modalState[0]?.data?.onCanceledAddWebsiteModal as () => {})?.();
    methods.reset();
  };
  const onSubmit = async (values: AddWebSiteModalFormValues) => {
    if (isLoadingSaveButton) return;
    await addDomainRelation({
      variables: {
        campaignId: (campaignId || campaignDetail.id) as number,
        domainId: values.domainId,
      },
    }).then((res) => {
      dispatch(selectDomain(res?.data?.addDomainRelation!));
    });
    methods.reset();
    dispatch(hideModal('addWebsite'));
    (modalState[0]?.data?.onAddedDomain as () => {})?.();
  };

  const websiteList = getWebsiteList();
  const isSaveButtonDisabled = websiteList.length === 0;
  return (
    <div className={styles.addWebSiteModal}>
      <div className={styles.header}>
        {t('sections:add-website-modal:title')}
      </div>
      <div className={styles.content}>
        <PSForm
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.addWebSiteModalForm}
        >
          <PSFormField
            control={methods.control}
            component={PSSelectbox}
            name="domainId"
            label={t('sections:add-website-modal:selectDomainLabel')}
            noOptionsMessage={t('sections:add-website-modal:noDomainMessage')}
            options={websiteList}
            menuPosition="fixed"
          />
          <div className={styles.addNewWebSiteWrapper}>
            <PSButton
              type="button"
              size="sm"
              onClick={onClickAddNewWebSite}
              icon={AddIcon}
              variant="text"
              iconProps={{ width: '18', height: '18' }}
            >
              {t('sections:add-website-modal:addNewWebsite')}
            </PSButton>
          </div>
          <div className={styles.buttonContainer}>
            <PSButton
              type="button"
              size="sm"
              onClick={onClickCancel}
              variant="second"
            >
              {t('common:cancel')}
            </PSButton>
            <PSButton
              disabled={isSaveButtonDisabled}
              type="submit"
              size="sm"
              loading={isLoadingSaveButton}
            >
              {t('common:save')}
            </PSButton>
          </div>
        </PSForm>
      </div>
    </div>
  );
};

export default AddWebSiteModal;
