import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import type { FormFieldProps } from './types';

const PSFormField: FC<FormFieldProps> = ({
  control,
  name,
  component,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState }) =>
        // @ts-ignore
        React.createElement(component, {
          field,
          fieldState,
          formState,
          ...rest,
        })
      }
    />
  );
};
export default PSFormField;
