import type { IconsType, SegmentCardProps } from './types';
import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SegmentCard.module.scss';

import TrendingIcon from '@assets/icons/trending-up.svg';
import OwnSegmentIcon from '@assets/icons/own-segment.svg';
import AddIcon from '@assets/icons/add-icon.svg';
import AutoAwesomeIcon from '@assets/icons/auto_awesome_black.svg';
import AddReactionIcon from '@assets/icons/add_reaction_black.svg';
import CampaignIcon from '@assets/icons/campaign_black.svg';
import DoneAllIcon from '@assets/icons/done_all_black.svg';
import ExploreIcon from '@assets/icons/explore_black.svg';
import FavoriteBorderIcon from '@assets/icons/favorite_border_black.svg';
import GradeIcon from '@assets/icons/grade_black.svg';
import MailIcon from '@assets/icons/mail_black.svg';
import PhoneIphoneIcon from '@assets/icons/phone_iphone_black.svg';
import ShoppingCartIcon from '@assets/icons/shopping_cart_black.svg';
import BoltIcon from '@assets/icons/bolt_black.svg';
import LanguageIcon from '@assets/icons/language_black.svg';
import SelectedIcon from '@assets/icons/selected-segment-icon.svg';
import ShopifyIcon from '@assets/icons/shopify-line.svg';

import SegmentAction from '../SegmentAction';
import { showModal } from '@connectors/modal/actions';
import {
  deleteSegmentError,
  deleteSegmentSuccess,
  updateSegmentNameError,
  updateSegmentNameSuccess,
} from '@connectors/segment/actions';
import { RenameSegmentModalFormValues } from '@components/RenameSegmentModal/types';
import { makeSelectSegmentTargetsCopy } from '@connectors/segment/selectors';
import { makeSelectCampaignTargetsCopy } from '@connectors/builder/selectors';
import { saveTargets } from '@connectors/builder/target/actions';
import {
  useChangeSegmentNameMutation,
  useDeleteSegmentMutation,
} from '@generated/graphql';

export const Icons: IconsType = {
  increase: <TrendingIcon width="18" height="18" viewBox="0 0 24 24" />,
  trending_app: <TrendingIcon width="18" height="18" viewBox="0 0 24 24" />,
  'own-segment': <OwnSegmentIcon width="18" height="18" viewBox="0 0 18 18" />,
  add: <AddIcon width="18" height="18" viewBox="0 0 24 24" />,
  auto_awesome: <AutoAwesomeIcon width="18" height="18" viewBox="0 0 18 18" />,
  add_reaction: <AddReactionIcon width="18" height="18" viewBox="0 0 18 18" />,
  campaign: <CampaignIcon width="18" height="18" viewBox="0 0 18 18" />,
  done_all: <DoneAllIcon width="18" height="18" viewBox="0 0 18 18" />,
  explore: <ExploreIcon width="18" height="18" viewBox="0 0 18 18" />,
  favorite_border: (
    <FavoriteBorderIcon width="18" height="18" viewBox="0 0 18 18" />
  ),
  grade: <GradeIcon width="18" height="18" viewBox="0 0 18 18" />,
  mail: <MailIcon width="18" height="18" viewBox="0 0 18 18" />,
  phone_iphone: <PhoneIphoneIcon width="18" height="18" viewBox="0 0 18 18" />,
  shopping_cart: (
    <ShoppingCartIcon width="18" height="18" viewBox="0 0 18 18" />
  ),
  language: <LanguageIcon width="18" height="18" viewBox="0 0 18 18" />,
  shopify: <ShopifyIcon width="18" height="18" viewBox="0 0 18 18" />,
  default: <BoltIcon width="18" height="18" viewBox="0 0 18 18" />,
};

const SegmentCard: FC<SegmentCardProps> = ({
  segmentId,
  active = false,
  ownSegment,
  disabled,
  icon,
  iconStyle,
  title,
  description,
  isDeletable,
  showApplyButton = false,
  handleClickCancel,
  handleSegmentSelect,
  handleSegmentApply,
}) => {
  const dispatch = useDispatch();
  const [changeSegmentNameMutation] = useChangeSegmentNameMutation();
  const [deleteSegmentMutation] = useDeleteSegmentMutation();

  const targets = useSelector(makeSelectSegmentTargetsCopy);
  const campaignTargets = useSelector(makeSelectCampaignTargetsCopy);

  const _isDeletable = !showApplyButton && active ? false : isDeletable;

  const Icon = Icons[icon];

  const renameSegmentHandler = (values: RenameSegmentModalFormValues) => {
    changeSegmentNameMutation({
      variables: {
        changeSegmentNameId: Number(segmentId),
        name: values.name,
        description: values.description,
      },
      onCompleted: (response) => {
        const updatedData = {
          ...response.changeSegmentName,
          targets: JSON.parse(response.changeSegmentName.targets!),
        };
        dispatch(
          updateSegmentNameSuccess({
            data: updatedData,
            isActive: false,
          }),
        );
      },
      onError: () => {
        dispatch(updateSegmentNameError());
      },
    });

    if (Number(campaignTargets?.segmentId) === Number(segmentId)) {
      dispatch(
        saveTargets({
          targets: {
            ...targets,
            segmentId: Number(segmentId),
            segmentName: values.name,
            segmentDescription: values.description,
          },
          updateTargetCopy: true,
        }),
      );
    }
  };

  const onClickRename = () => {
    dispatch(
      showModal({
        id: 'renameSegmentModal',
        data: {
          name: title || '',
          description: description || '',
          onSubmit: renameSegmentHandler,
        },
      }),
    );
  };

  const onClickDelete = () => {
    if (!_isDeletable) return;

    if (active && handleClickCancel) handleClickCancel();

    deleteSegmentMutation({
      variables: {
        deleteSegmentId: Number(segmentId),
      },
      onCompleted: () => {
        dispatch(deleteSegmentSuccess({ id: Number(segmentId) }));
      },
      onError: () => {
        dispatch(deleteSegmentError());
      },
    });
  };

  const deleteTooltipText = useMemo(() => {
    if (!showApplyButton && active)
      return 'The selected segment cannot be deleted!';

    if (_isDeletable === false)
      return 'The multiple used segments cannot be deleted!';

    return undefined;
  }, [active, showApplyButton, _isDeletable]);

  return (
    <div
      className={cn(styles.card, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.icon} style={{ ...iconStyle }}>
        {Icon}
      </div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.desc}>{description}</p>
      <div className={styles.footer}>
        {showApplyButton ? (
          <>
            <button
              className={cn('fade-in', styles.button)}
              onClick={handleClickCancel}
            >
              Cancel
            </button>

            <button
              className={cn('fade-in', styles.applyButton)}
              onClick={handleSegmentApply}
            >
              Apply and Edit
            </button>
          </>
        ) : (
          <button
            className={cn('fade-in', styles.button)}
            onClick={handleSegmentSelect}
          >
            {active ? 'Selected' : 'Select'}
          </button>
        )}

        {ownSegment && (
          <SegmentAction
            id={segmentId}
            actions={[
              {
                value: 'rename',
                action: () => onClickRename(),
              },
              {
                value: 'delete',
                disable: _isDeletable === false,
                tooltip: deleteTooltipText,
                action: () => onClickDelete(),
              },
            ]}
          />
        )}
      </div>
      {active ? (
        <div className={cn('fade-in', styles.selectedIcon)}>
          <SelectedIcon width="24" height="24" viewBox="0 0 24 24" />
        </div>
      ) : null}
    </div>
  );
};
export default SegmentCard;
