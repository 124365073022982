import { FC } from 'react';
import styles from './PublishAllVariantsModal.module.scss';
import PSButton from '@elements/PSButton';
import { useDispatch } from 'react-redux';
import { hideModal } from '@connectors/modal/actions';
import { PublishAllVariantsModalProps } from './types';
import OverviewIcon from '@assets/icons/rocket-icon.svg';

const PublishAllVariantsModal: FC<PublishAllVariantsModalProps> = ({
  onPublish,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal('PublishAllVariantsModal'));
  };
  const handleSubmit = () => {
    handleClose();
    try {
      onPublish && onPublish();
    } catch (error) {
      // console.error(error);
    }
  };
  return (
    <div className={styles.modalContainer}>
      <div className={styles.icon}>
        <OverviewIcon width="32" height="32" viewBox="0 0 18 18" />
      </div>
      <div className={styles.header}>Publishing all variants </div>
      <div className={styles.description}>
        If you proceed to publish, all variants of this A/B test will be
        published.
      </div>
      <div className={styles.buttonContainer}>
        <PSButton
          type="button"
          size="sm"
          onClick={handleSubmit}
          variant="primary"
        >
          Publish
        </PSButton>
      </div>
    </div>
  );
};
export default PublishAllVariantsModal;
