import { ReduxAction } from 'global';
import {
  GET_CITIES_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
} from './contants';
import ReduxStatus from '@enums/ReduxStatus';
import { RESET_STORE } from '@connectors/actions';

type LocationState = {
  status: ReduxStatus;
  countries: Country[];
  regions: { [key: string]: Region[] };
  cities: { [key: string]: City[] };
};

export type Country = {
  code: string;
  name: string;
};
export type Region = {
  code: string;
  name: string;
};
export type City = {
  regionCode: string;
  name: string;
};
const initialState: LocationState = {
  status: ReduxStatus.initialized,
  countries: [],
  regions: {},
  cities: {},
};

export default function reducer(
  state = initialState,
  action: ReduxAction,
): LocationState {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return { ...state, status: ReduxStatus.loading };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        status: ReduxStatus.success,
        countries: action.payload,
      };
    case GET_COUNTRIES_FAILURE:
      return { ...state, status: ReduxStatus.errored, countries: [] };

    case GET_REGIONS_REQUEST:
      return { ...state, status: ReduxStatus.loading };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        status: ReduxStatus.success,
        regions: {
          ...state.regions,
          [action.payload.countryCode]: action.payload.regions,
        },
      };
    case GET_REGIONS_FAILURE:
      return { ...state, status: ReduxStatus.errored };

    case GET_CITIES_REQUEST:
      return { ...state, status: ReduxStatus.loading };
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        status: ReduxStatus.success,
        cities: {
          ...state.cities,
          [`${action.payload.countryCode}_${action.payload.regionCode}`]:
            action.payload.cities,
        },
      };
    case GET_CITIES_FAILURE:
      return { ...state, status: ReduxStatus.errored };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
