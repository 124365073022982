export const dripServiceURl = 'api/integrations/drip';

export const DRIP_GET_CONNECTIONS = 'builder/integrations/DRIP_GET_CONNECTIONS';
export const DRIP_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/DRIP_GET_CONNECTIONS_SUCCESS';
export const DRIP_GET_CONNECTIONS_FAILURE =
  'builder/integrations/DRIP_GET_CONNECTIONS_FAILURE';

export const DRIP_GET_FIELDS = 'builder/integrations/DRIP_GET_FIELDS';
export const DRIP_GET_FIELDS_SUCCESS =
  'builder/integrations/DRIP_GET_FIELDS_SUCCESS';
export const DRIP_GET_FIELDS_FAILURE =
  'builder/integrations/DRIP_GET_FIELDS_FAILURE';

export const DRIP_SAVE = 'builder/integrations/DRIP_SAVE';
export const DRIP_SAVE_SUCCESS = 'builder/integrations/DRIP_SAVE_SUCCESS';
export const DRIP_SAVE_FAILURE = 'builder/integrations/DRIP_SAVE_FAILURE';

export const DRIP_DELETE = 'builder/integrations/DRIP_DELETE';
export const DRIP_DELETE_SUCCESS = 'builder/integrations/DRIP_DELETE_SUCCESS';
export const DRIP_DELETE_FAILURE = 'builder/integrations/DRIP_DELETE_FAILURE';

export const DRIP_CHANGESTATUS = 'builder/integrations/DRIP_CHANGESTATUS';
export const DRIP_CHANGESTATUS_SUCCESS =
  'builder/integrations/DRIP_CHANGESTATUS_SUCCESS';
export const DRIP_CHANGESTATUS_FAILURE =
  'builder/integrations/DRIP_CHANGESTATUS_FAILURE';

export const DRIP_CLEAR_TEST_RESULT =
  'builder/integrations/DRIP_CLEAR_TEST_RESULT';
export const DRIP_CLEAR_STATE = 'builder/integrations/DRIP_CLEAR_STATE';
