module.exports = {
  darkMode: 'class',
  content: [
    './app/**/*.{js,ts,jsx,tsx}',
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/ui-elements/**/*.{js,ts,jsx,tsx}',
    './src/styles/**/*.scss',
    './src/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      container: {
        center: true,
        padding: '1rem',
      },
      colors: {
        white: '#FFFFFF',
        black: '#000000',
        bluesmart: '#1C86FA',
        bluecold: '#88A5C4',
        bluelight: '#EAF4FF',
        green: '#13AF4F',
        greencold: '#9FC7AF',
        greenlight: '#E8F7EE',
        red: '#EA0F0F',
        redsoft: '#eb4848',
        redcold: '#DDA7A7',
        redlight: '#FDE9E9',
        orange: '#FC933A',
        orangesoft: '#FF957D',
        orangecold: '#F4B582',
        orangelight: '#FFF5ED',
        pagebg: '#F5F5F5',
        grayborder: '#eaeaea',
        linen: '#fcf0f0',
        polar: '#f0f8fc',
        shopify: '#34785E',
        wordpress: '#464646',
        gray: {
          100: '#F5F7F8',
          200: '#E9EDF1',
          250: '#DDDDDD',
          300: '#DEE4EA',
          400: '#D2DAE3',
          500: '#B2B9C8',
          600: '#9198AC',
          700: '#777777',
          800: '#5B6078',
          900: '#444A5F',
          1000: '#2E3346',
        },
      },
      fontFamily: {
        inter: ['Inter'],
      },
      fontSize: {
        xs: '12px',
        sm: '14px',
        base: '16px',
        lg: '18px',
        xl: '20px',
        '2xl': '24px',
        '3xl': '30px',
        '4xl': '36px',
        '5xl': '48px',
        '6xl': '72px',
      },
      lineHeight: {
        md: '100%',
        lg: '120%',
        xl: '150%',
      },
      transitionProperty: {
        background: 'background',
      },
      width: {
        104: '26rem',
      },
      maxWidht: {
        640: '40rem',
      },
      maxHeight: {
        160: '40rem',
      },
      boxShadow: {
        xs: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      },
      screens: {
        '2lg': '1440px',
        '3xl': '1920px',
      },
    },
  },
  plugins: [],
};
