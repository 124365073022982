import AES from 'crypto-js/aes';
import encBase64 from 'crypto-js/enc-base64';
import encUtf8 from 'crypto-js/enc-utf8';
import CryptoMD5 from 'crypto-js/md5';

const encrypt = (value: string, secret?: string): string => {
  const secretKey =
    secret ||
    process.env.ENCRYPTION_SECRET_KEY ||
    'ed0e12e9340e4c52907ae66dfaea66e2';
  const encJson = AES.encrypt(value, secretKey).toString();
  return encBase64.stringify(encUtf8.parse(encJson));
};
const decrypt = (hash: string, secret?: string): string => {
  const secretKey =
    secret ||
    process.env.ENCRYPTION_SECRET_KEY ||
    'ed0e12e9340e4c52907ae66dfaea66e2';
  const decData = encBase64.parse(hash).toString(encUtf8);
  return AES.decrypt(decData, secretKey).toString(encUtf8);
};

const MD5 = (hash: string): string => {
  return CryptoMD5(hash).toString();
};

/**
 * @param {string} plaintext
 */
async function encryptAESGCM(plaintext: string) {
  const keyBase64 = 'AQIDBAUGBwgJCgsMDQ4PEBESExQVFhcYGRobHB0eHyA=';
  const ivBase64 = 'FSELWU1dZWFdfdKQ';

  const keyBuffer = Buffer.from(keyBase64, 'base64');
  const ivBuffer = Buffer.from(ivBase64, 'base64');

  const key = await crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-GCM' },
    false,
    ['encrypt'],
  );

  const encoder = new TextEncoder();
  const data = encoder.encode(plaintext);

  const cipherText = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: ivBuffer,
    },
    key,
    data,
  );

  return Buffer.from(new Uint8Array(cipherText)).toString('base64');
}
const Aes = { encrypt, decrypt };
export { Aes, MD5, encryptAESGCM };
