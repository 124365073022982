import { combineReducers } from 'redux';

import IntegrationReducer from './integration/reducers';
import AnalyticsReducer from './analytics/reducer';
import AuthReducer from './auth/reducer';
import CampaignReducer from './campaign/reducer';
import CampaignsReducer from './campaigns/reducer';
import FontsReducer from './fonts/reducer';
import WebsitesReducer from './websites/reducer';
import StripeReducer from './stripe/reducer';
import AccountsReducer from './accounts/reducer';
import LeadEmailsReducer from './leadEmails/reducer';
import TemplatesReducer from './templates/reducer';
import AutoResponderReducer from './autoResponder/reducer';
import LocationsReducer from './locations/reducer';
import ModalReducer from './modal/reducer';
import ProductsReducer from './products/reducer';
import DrawerReducer from './drawer/reducer';
import SubscribersReducer from './subscribers/reducer';
import TargetsReducer from './targets/reducer';
import QuillReducer from './quill/reducer';
import BuilderReducer from './builder/reducer';
import SegmentReducer from './segment/reducer';
import NewStepReducer from './newStep/reducer';
import PortalReducer from './portal/reducer';
import Editor from './emailEditor/reducer';

const rootReducers = combineReducers({
  builderIntegrations: IntegrationReducer,
  analytics: AnalyticsReducer,
  auth: AuthReducer,
  campaign: CampaignReducer,
  campaigns: CampaignsReducer,
  fonts: FontsReducer,
  websites: WebsitesReducer,
  stripe: StripeReducer,
  accounts: AccountsReducer,
  leadEmails: LeadEmailsReducer,
  templates: TemplatesReducer,
  autoResponder: AutoResponderReducer,
  locations: LocationsReducer,
  modal: ModalReducer,
  products: ProductsReducer,
  drawer: DrawerReducer,
  subscribers: SubscribersReducer,
  targets: TargetsReducer,
  quill: QuillReducer,
  builder: BuilderReducer,
  segment: SegmentReducer,
  newStep: NewStepReducer,
  portal: PortalReducer,
  editor: Editor,
});

export type RootState = ReturnType<typeof rootReducers>;
export default rootReducers;
