import { ReduxAction } from 'global';
import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';

import {
  ADD_LEAD_EMAIL_REQUEST,
  ADD_LEAD_EMAIL_SUCCESS,
  ADD_LEAD_EMAIL_FAILURE,
  DELETE_LEAD_EMAIL_REQUEST,
  DELETE_LEAD_EMAIL_SUCCESS,
  DELETE_LEAD_EMAIL_FAILURE,
  VERIFY_LEAD_EMAIL_REQUEST,
  VERIFY_LEAD_EMAIL_SUCCESS,
  VERIFY_LEAD_EMAIL_FAILURE,
  GET_LEAD_EMAILS_REQUEST,
  GET_LEAD_EMAILS_SUCCESS,
  GET_LEAD_EMAILS_FAILURE,
} from './constants';
import { LeadEmail } from './types';
import graphqlFetch from '@utils/graphqlFetch';

function* addLeadEmailSaga({ payload }: ReduxAction) {
  try {
    const graphqlQuery = {
      operationName: 'addLeadEmailAddress',
      query: `mutation addLeadEmailAddress($campaignId: Int!, $email: String!) {
        addLeadEmailAddress(campaignId: $campaignId, email: $email) {
          id
          email
          campaignId
          status
          code
        }
      }`,
      variables: {
        campaignId: parseInt(payload.campaignId, 10),
        email: payload.email,
      },
    };
    const data: {
      data: {
        addLeadEmailAddress: LeadEmail;
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    yield put({
      type: ADD_LEAD_EMAIL_SUCCESS,
      payload: {
        ...data?.data?.addLeadEmailAddress,
        campaignId: payload.campaignId,
      },
    });
  } catch (error) {
    yield put({ type: ADD_LEAD_EMAIL_FAILURE });
  }
}

function* getLeadEmailsSaga({ payload }: ReduxAction) {
  const { campaignId } = payload;
  try {
    const graphqlQuery = {
      operationName: 'getLeadEmailAddresses',
      query: `query getLeadEmailAddresses($campaignId: Int!) {
        getLeadEmailAddresses(campaignId: $campaignId) {
          id
          email
          campaignId
          status
          code
        }
      }`,
      variables: {
        campaignId: parseInt(payload.campaignId, 10),
      },
    };

    const data: {
      data: {
        getLeadEmailAddresses: LeadEmail[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    const res = data?.data?.getLeadEmailAddresses;
    const leads = res.map((emails) => {
      return { ...emails, campaignId };
    });
    yield put({ type: GET_LEAD_EMAILS_SUCCESS, payload: leads });
    payload?.onSuccess && payload?.onSuccess(leads);
  } catch (error) {
    yield put({ type: GET_LEAD_EMAILS_FAILURE });
    payload?.onError && payload?.onError();
  }
}

function* deleteLeadEmailSaga({ payload }: ReduxAction) {
  const { campaignId, emailId } = payload;
  try {
    const graphqlQuery = {
      operationName: 'deleteLeadEmailAddress',
      query: `mutation deleteLeadEmailAddress($campaignId: Int!, $emailId: Int!) {
        deleteLeadEmailAddress(campaignId: $campaignId, emailId: $emailId) 
      }`,
      variables: {
        campaignId: parseInt(campaignId, 10),
        emailId,
      },
    };

    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    yield put({ type: DELETE_LEAD_EMAIL_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_LEAD_EMAIL_FAILURE });
  }
}

function* verifyLeadEmailSaga({ payload }: ReduxAction) {
  const { code } = payload;
  try {
    const graphqlQuery = {
      operationName: 'verifyLeadEmailAddress',
      query: `mutation verifyLeadEmailAddress($code: String!) {
        verifyLeadEmailAddress(code: $code) 
      }`,
      variables: {
        code,
      },
    };
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    yield put({ type: VERIFY_LEAD_EMAIL_SUCCESS });
  } catch (error) {
    yield put({ type: VERIFY_LEAD_EMAIL_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(ADD_LEAD_EMAIL_REQUEST, addLeadEmailSaga);
  yield takeLatest(GET_LEAD_EMAILS_REQUEST, getLeadEmailsSaga);
  yield takeEvery(DELETE_LEAD_EMAIL_REQUEST, deleteLeadEmailSaga);
  yield takeLatest(VERIFY_LEAD_EMAIL_REQUEST, verifyLeadEmailSaga);
}
