import { RootState } from '@connectors/reducers';
import { createSelector } from 'reselect';

const selectWebsites = (state: RootState) => state.websites;

const makeSelectWebsites = createSelector(
  selectWebsites,
  (website) => website.items || [],
);

export { makeSelectWebsites };
