import {
  ADD_CAMPAIGN_CONTENT_ELEMENT,
  ADD_CAMPAIGN_PAGE,
  CLONE_CAMPAIGN_CONTENT_ELEMENT,
  COPY_DESKTOP_TO_MOBILE,
  GET_NEW_PAGE_TEMPLATE,
  MOVE_CAMPAIGN_CONTENT_ELEMENT,
  REMOVE_CAMPAIGN_CONTENT_ELEMENT,
  REMOVE_CAMPAIGN_PAGE,
  REVERSE_CAMPAIGN_CHILD_ELEMENTS,
  UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_PAGE_CONTENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_CONTENT_ELEMENT,
  UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE,
  UPDATE_CAMPAIGN_CURRENT_PAGE_ID,
  UPDATE_CAMPAIGN_PAGE_ATTRIBUTE,
  UPDATE_CAMPAIGN_PAGE_COLOR,
  UPDATE_CAMPAIGN_PAGE_FONT_FAMILY,
  UPDATE_CAMPAIGN_PREVIEW,
  UPDATE_CUSTOMIZE_SIDEBAR,
  UPDATE_HOVER_ID,
  UPDATE_INLINE_EDITOR,
  UPDATE_VISITOR_DEVICE,
  RESET_CUSTOMIZE_COPY,
  ORJ_DATA_TO_DATA,
  UPDATE_PAGE_CONTENT,
  RESET_CAMPAIGN_TEASER_TO_ORJDATA,
} from './constants';
import {
  AddCampaignContentElementAction,
  AddCampaignPageAction,
  CloneCampaignContentElementAction,
  RemoveCampaignContentElementAction,
  RemoveCampaignPageAction,
  ReverseCampaignChildElementsAction,
  UpdateCampaignContentAttributeAction,
  UpdateCampaignContentElementAction,
  UpdateCampaignContentElementAttributeAction,
  UpdateCampaignCurrentDeviceTypeAction,
  UpdateCampaignCurrentPageIdAction,
  UpdateCampaignPageAttributeAction,
  UpdateCampaignPageColorAction,
  UpdateCampaignPageContentAttributeAction,
  UpdateCampaignPageFontFamilyAction,
  UpdateCampaignPreviewAction,
} from './types';
import { Node, Content } from '../campaign';
import DeviceTypes from '@enums/DeviceTypes';
import CustomizeSidebarTypes from '@enums/CustomizeSidebarTypes';

export function orjDataToData() {
  return {
    type: ORJ_DATA_TO_DATA,
  };
}
export function resetCustomizeCopy() {
  return {
    type: RESET_CUSTOMIZE_COPY,
  };
}

//update data->layout->contents->[id=pageId] atrributes
export function updateCampaignPageAttribute(
  attributePath: string,
  value: string | number | boolean,
  pageId?: string,
): UpdateCampaignPageAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_ATTRIBUTE,
    payload: {
      attributePath,
      value,
      pageId,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType] attributes
export function updateCampaignContentAttribute(
  attributePath: string,
  value: string | number | boolean | object | Array<string> | Array<object>,
): UpdateCampaignContentAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE,
    payload: {
      attributePath,
      value,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType=deviceType] attributes
export function updateCampaignPageContentAttribute(
  attributePath: string,
  value: string | number | boolean | object | Array<string> | Array<object>,
  pageId?: string,
  deviceType?: DeviceTypes,
): UpdateCampaignPageContentAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_CONTENT_ATTRIBUTE,
    payload: {
      attributePath,
      value,
      pageId,
      deviceType,
    },
  };
}

export function addCampaignContentElement(
  id: string,
  value: Node,
  index: number,
  showFormEditor = false,
  switchEditFormElementSidebar = false,
): AddCampaignContentElementAction {
  return {
    type: ADD_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      value,
      index,
      showFormEditor,
      switchEditFormElementSidebar,
    },
  };
}

export function cloneCampaignContentElement(
  id: string,
  isFormElement?: boolean,
): CloneCampaignContentElementAction {
  return {
    type: CLONE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      isFormElement,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType] childNode with id
export function updateCampaignContentElement(
  id: string,
  value: Node[] | string[],
): UpdateCampaignContentElementAction {
  return {
    type: UPDATE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      value,
    },
  };
}

//update data->layout->contents->[id=pageId]->content->[deviceType] childNode attributes with id
export function updateCampaignContentElementAttribute(
  id: string,
  attributePath: string,
  value: string | number | boolean | object,
  isLinked?: boolean,
): UpdateCampaignContentElementAttributeAction {
  return {
    type: UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE,
    payload: {
      id,
      attributePath,
      value,
      isLinked,
    },
  };
}

export function moveCampaignContentElement(
  id: string,
  toId: string,
  index: number,
  topElementId?: string,
) {
  return {
    type: MOVE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
      toId,
      index,
      topElementId,
    },
  };
}

//remove data->layout->contents->[id=pageId]->content->[deviceType] childNode with id
export function removeCampaignContentElement(
  id: string,
): RemoveCampaignContentElementAction {
  return {
    type: REMOVE_CAMPAIGN_CONTENT_ELEMENT,
    payload: {
      id,
    },
  };
}

//update currentDeviceType
export function updateCampaignCurrentDeviceType(
  deviceType: DeviceTypes,
): UpdateCampaignCurrentDeviceTypeAction {
  return {
    type: UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE,
    payload: {
      deviceType,
    },
  };
}
//update currentPageId
export function updateCampaignCurrentPageId(
  pageId: string,
  clearCustomizeSidebarActiveElementId?: boolean,
  isAccordionElement?: boolean,
): UpdateCampaignCurrentPageIdAction {
  return {
    type: UPDATE_CAMPAIGN_CURRENT_PAGE_ID,
    payload: {
      pageId,
      clearCustomizeSidebarActiveElementId,
      isAccordionElement,
    },
  };
}

export function addCampaignPage({
  title,
  pageId,
  contents,
}: {
  title: string;
  pageId?: string;
  contents?: Content[];
}): AddCampaignPageAction {
  return {
    type: ADD_CAMPAIGN_PAGE,
    payload: {
      title,
      pageId,
      contents,
    },
  };
}

export function removeCampaignPage(pageId: string): RemoveCampaignPageAction {
  return {
    type: REMOVE_CAMPAIGN_PAGE,
    payload: {
      pageId,
    },
  };
}

export function updateCampaignPreview(
  isActive: boolean,
): UpdateCampaignPreviewAction {
  return {
    type: UPDATE_CAMPAIGN_PREVIEW,
    payload: {
      isActive,
    },
  };
}

export function updateVisitorDevice({
  mobile,
  desktop,
}: {
  mobile: boolean;
  desktop: boolean;
}) {
  return {
    type: UPDATE_VISITOR_DEVICE,
    payload: {
      mobile,
      desktop,
    },
  };
}

export function getNewPageTemplate(pageName: string, templateId: number) {
  return {
    type: GET_NEW_PAGE_TEMPLATE,
    payload: {
      pageName,
      templateId,
    },
  };
}

export function getNewPageTemplateError() {
  return {
    type: GET_NEW_PAGE_TEMPLATE,
  };
}

export function reverseCampaignChildElements(
  id: string,
): ReverseCampaignChildElementsAction {
  return {
    type: REVERSE_CAMPAIGN_CHILD_ELEMENTS,
    payload: {
      id,
    },
  };
}

export function updateCampaignPageFontFamily(
  value: string,
  pageId?: string,
): UpdateCampaignPageFontFamilyAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_FONT_FAMILY,
    payload: {
      value,
      pageId,
    },
  };
}

export function updateCampaignPageColor(
  oldValue: string,
  value: string,
  pageId?: string,
): UpdateCampaignPageColorAction {
  return {
    type: UPDATE_CAMPAIGN_PAGE_COLOR,
    payload: {
      oldValue,
      value,
      pageId,
    },
  };
}

export function updateCustomizeSidebar(
  type: CustomizeSidebarTypes,
  id?: string | null,
  activeElementId?: string | null,
  addElementIndex?: number,
) {
  return {
    type: UPDATE_CUSTOMIZE_SIDEBAR,
    payload: {
      type,
      id,
      activeElementId,
      addElementIndex,
    },
  };
}

export function updateHoverId(id?: string) {
  return {
    type: UPDATE_HOVER_ID,
    payload: {
      id,
    },
  };
}

export function updateInlineEditor(id?: string) {
  return {
    type: UPDATE_INLINE_EDITOR,
    payload: {
      id,
    },
  };
}

export function copyDesktopToMobile() {
  return {
    type: COPY_DESKTOP_TO_MOBILE,
  };
}

export function updatePageContent(pageId: string, content: Content) {
  return {
    type: UPDATE_PAGE_CONTENT,
    payload: {
      pageId,
      content,
    },
  };
}

export function resetTeaserToOrjData() {
  return {
    type: RESET_CAMPAIGN_TEASER_TO_ORJDATA,
  };
}
