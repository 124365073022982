import { /* ChangeEvent, */ FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopupsmartIcon from '@assets/icons/popupsmart-rounded.svg';
import UnlockIcon from '@assets/icons/unlock-icon.svg';
import { UnlockFeaturesModalProps } from './types';
import UnlockFeaturesModalTypes from '@enums/UnlockFeaturesModalEnums';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '@connectors/modal/actions';
import { useAuth } from '@utils/useAuth';
/* import Switch from '@elements/Switch'; */
import { RootState } from '@connectors/reducers';
import { PlanItem, ProductsStateType } from '@connectors/products/types';
import { PlanType } from '@features/account/billing/types';
import classNames from 'classnames';
import PSButton from '@elements/PSButton';
import {
  useCreatePortalLinkLazyQuery,
  useCreateSessionIdLazyQuery,
  useCreateShopifySubscriptionMutation,
} from '@generated/graphql';
import {
  CREATE_PORTAL_FAILURE,
  CREATE_PORTAL_SUCCESS,
} from '@connectors/stripe/constants';
import { useRouter } from 'next/navigation';

const UnlockFeaturesModal: FC<UnlockFeaturesModalProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const products = useSelector(
    (state: RootState) => state.products.products as ProductsStateType,
  );
  const stripe = useSelector((state: RootState) => state.stripe);
  const { auth } = useAuth();
  const [selectedPlanPeriod /* , setSelectedPlanPeriod */] =
    useState<PlanType>('Yearly');
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [createShopifySubscription] = useCreateShopifySubscriptionMutation({
    fetchPolicy: 'no-cache',
  });
  const [createPortalLink] = useCreatePortalLinkLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [createSessionId] = useCreateSessionIdLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const freePlanId = parseInt(
    process.env.NEXT_PUBLIC_FREE_PLAN_ID as string,
    10,
  );
  /*   useEffect(() => {
    if (auth && auth.accountPlan) {
      setSelectedPlanPeriod(auth.accountPlan.type as PlanType);
    }
  }, [auth]); */

  useEffect(() => {
    const { url } = stripe;
    if (stripe.status !== 'loading') setLoading(false);
    if (url) {
      router.push(url);
    }
  }, [stripe]);

  const planMaxVersion = useMemo(() => {
    if (selectedPlanPeriod !== 'Yearly') {
      return products.data[selectedPlanPeriod].reduce((max, item) => {
        return item.version > max ? item.version : max;
      }, -Infinity);
    }
    return 2;
  }, [products, selectedPlanPeriod]);

  const description = useMemo(() => {
    if (type === UnlockFeaturesModalTypes.LIMIT) {
      return t('sections:unlock-features-modal:limitDescription');
    } else if (type === UnlockFeaturesModalTypes.PUBLISH) {
      return t('sections:unlock-features-modal:limitDescription');
    } else if (type === UnlockFeaturesModalTypes.WEBSITE) {
      return t('sections:unlock-features-modal:websiteDescription');
    } else if (type === UnlockFeaturesModalTypes.SUB_ACCOUNT) {
      return t('sections:unlock-features-modal:subAccountDescription');
    }
    return t('sections:unlock-features-modal:description');
  }, [type]);
  /*   const onChangePlanPeriod = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectedPlanPeriod(checked ? 'Monthly' : 'Yearly');
  }; */

  const plans = useMemo(() => {
    let result: PlanItem[] = [];
    if (
      auth &&
      auth.accountPlan &&
      auth.accountPlan.id !== freePlanId &&
      auth.accountPlan.name !== 'Expert'
    ) {
      const filteredPlans = products.data[selectedPlanPeriod].filter(
        (x) => x.version === planMaxVersion,
      );
      const currentPlanIndex = filteredPlans.findIndex(
        (x) => x.name === auth.accountPlan.name,
      );

      result = filteredPlans.slice((currentPlanIndex % 3) + 1);
    } else if (auth.accountPlan.name === 'Expert') {
      result = products.data[selectedPlanPeriod].filter(
        (x) => x.version === planMaxVersion && x.name === 'Expert',
      );
    } else {
      result = products.data[selectedPlanPeriod].filter(
        (x) => x.version === planMaxVersion,
      );
    }

    if (result.length > 0) {
      setSelectedPlanId(result[0].planId);
    }
    return result;
  }, [products, selectedPlanPeriod, auth]);

  const numberFormatter = (pageViewSize: number) => {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(pageViewSize);
  };

  const addDecimalsToYearlyPrice = (price: number) => {
    if (/* plan.type === 'Yearly' */ true && !price.toString().includes('.')) {
      return `${price}.00`;
    }
    return price;
  };

  const renderPaymentButtonText = useMemo(() => {
    let buttonText = '';
    const planTypeText =
      selectedPlanPeriod === 'Yearly' ? 'Annually' : selectedPlanPeriod;

    if (!selectedPlanId) {
      buttonText = t('sections:unlock-features-modal:buttonTextOne');
    }

    if (auth.accountPlan.name === 'Expert') {
      return t('sections:unlock-features-modal:buttonTextTwo');
    }

    if (selectedPlanId) {
      const selectedPlan = plans.find((x) => x.planId === selectedPlanId);
      if (selectedPlan === undefined) return '';
      if (selectedPlanPeriod === 'Yearly') {
        buttonText = `${t('common:pay')} $${
          selectedPlan.saveText?.new || selectedPlan.price * 12
        } ${planTypeText}`;
      } else {
        buttonText = `${t('common:pay')} $${
          selectedPlan.price
        } ${planTypeText}`;
      }
    }

    if (loading) {
      buttonText = t('sections:unlock-features-modal:buttonTextThree');
    }

    return buttonText;
  }, [selectedPlanId, selectedPlanPeriod, plans, loading]);

  const handleOnClickPayButton = () => {
    if (auth.accountPlan.name === 'Expert') {
      window.open('https://popupsmart.com/enterprise-sales/', '_blank');
      dispatch(hideModal('UnlockFeaturesModal'));
      return;
    }
    setLoading(true);
    if (auth.isShopifyUser) {
      createShopifySubscription({
        variables: { planId: selectedPlanId || 0 },
        onCompleted: (res) => {
          window.location.href = res.createShopifySubscription;
        },
        onError: () => {
          setLoading(false);
        },
      });
    } else {
      if (auth.accountPlan.id === freePlanId && selectedPlanId !== null) {
        const plan = plans.find((x) => x.planId === selectedPlanId);
        if (plan) {
          createSessionId({
            variables: {
              priceId: plan.priceId,
            },
            onCompleted: (data) => {
              if (data.createSessionId.url) {
                dispatch({
                  type: CREATE_PORTAL_SUCCESS,
                  payload: data.createSessionId,
                });
              } else {
                dispatch({
                  type: CREATE_PORTAL_FAILURE,
                  payload: "Couldn't redirect to stripe",
                });
              }
            },
            onError: () => {
              dispatch({
                type: CREATE_PORTAL_FAILURE,
                payload: "Couldn't redirect to stripe",
              });
            },
          });
        }
      }
      createPortalLink({
        onCompleted: (data) => {
          if (data.createPortalLink.url) {
            dispatch({
              type: CREATE_PORTAL_SUCCESS,
              payload: data.createPortalLink,
            });
          } else {
            dispatch({
              type: CREATE_PORTAL_FAILURE,
              payload: "Couldn't redirect to stripe",
            });
          }
          setLoading(false);
        },
        onError: () => {
          dispatch({
            type: CREATE_PORTAL_FAILURE,
            payload: "Couldn't redirect to stripe",
          });
          setLoading(false);
        },
      });
    }
  };
  return (
    <div className="grid grid-flow-row auto-rows-max py-[25px] px-[30px]">
      <div className="grid grid-flow-col auto-cols-max justify-center mb-[15px]">
        <div className="border border-[#eaeaea] rounded-full p-[16px] bg-white">
          <PopupsmartIcon width="32" height="32" viewBox="0 0 48 48" />
        </div>
        <div className="border border-[#eaeaea] rounded-full p-[16px] ml-[-10px] bg-white">
          <UnlockIcon />
        </div>
      </div>
      <div className="text-lg leading-6 font-bold text-black mb-[5px] text-center">
        {t('sections:unlock-features-modal:title')}
      </div>
      <div className="text-xs leading-4 text-black mb-[10px] text-center">
        {description}
      </div>
      <div className="text-[10px] leading-[14px] text-center text-[#777777]">
        {t('sections:unlock-features-modal:note', {
          plan: auth?.accountPlan?.description,
        })}{' '}
        <span
          className="font-medium cursor-pointer text-[#000]"
          onClick={() => {
            dispatch(hideModal('UnlockFeaturesModal'));
            dispatch(
              showModal({
                id: 'CompareDetailsModal',
                data: {
                  isOpenedByUnlockModal: true,
                  type: type as string,
                },
              }),
            );
          }}
        >
          {t('sections:unlock-features-modal:compare')}
        </span>
      </div>
      {/*  {auth?.accountPlan?.name! !== 'Expert' && (
        <div className="flex justify-center items-center gap-[10px] mt-[25px]">
          <span className="font-medium text-xs leading-[18px]">
            {t('common:annually')}
          </span>
          <Switch
            elementSize="xs"
            checked={selectedPlanPeriod === 'Monthly'}
            onChange={onChangePlanPeriod}
          />
          <span className="font-medium text-xs leading-[18px]">
            {t('common:monthly')}
          </span>
        </div>
      )} */}
      <div className="flex w-full flex-col items-start gap-[10px] mt-[20px]">
        {plans.map((plan) => (
          <div
            onClick={() => setSelectedPlanId(plan.planId)}
            key={plan.planId.toString()}
            className={classNames(
              'flex justify-between transition-all duration-150 cursor-pointer w-full rounded-md bg-white border shadow-none border-[#dddddd] box-border p-[15px]',
              {
                '!border-[#1c86fa] !shadow-[0px 10px 15px rgba(0, 0, 0, 0.1)]':
                  plan.planId === selectedPlanId,
              },
            )}
          >
            <div className="flex gap-[10px]">
              <div
                className={classNames(
                  'relative box-border w-[18px] h-[18px] border border-[#dddddd] rounded-full',
                  {
                    '!border-[#1c86fa]': plan.planId === selectedPlanId,
                  },
                )}
              >
                <span
                  className={classNames(
                    'absolute inset-0 m-auto h-0 w-0 bg-transparent rounded-full',
                    {
                      'h-[8px] w-[8px] !bg-[#1c86fa]':
                        plan.planId === selectedPlanId,
                    },
                  )}
                />
              </div>
              <div>
                <div className="text-sm leading-[18px] font-semibold mb-[5px] capitalize">
                  {plan.name}
                </div>
                <div className="text-[10px] leading-[14px] font-medium">
                  <span className="font-medium">
                    {numberFormatter(plan.impressionCount)}{' '}
                    {t('common:pageviews')}
                  </span>
                  <span> / {t('common:monthly').toLowerCase()}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <span className="text-xs leading-4 font-semibold">
                  {addDecimalsToYearlyPrice(plan.price)}$
                </span>
                <span className="ml-1 text-xs leading-[16px] font-normal">
                  / {t('common:monthlyShort').toLowerCase()}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-[25px] gap-[10px]">
        <PSButton
          type="button"
          size="sm"
          variant="second"
          onClick={() => {
            dispatch(hideModal('UnlockFeaturesModal'));
          }}
          textClassName="text-xs text-[#777]"
        >
          {t('common:later')}
        </PSButton>
        <PSButton
          type="button"
          size="sm"
          loading={loading}
          onClick={handleOnClickPayButton}
          disabled={
            (selectedPlanId === null && auth.accountPlan.name !== 'Expert') ||
            loading
          }
          className="bg-bluesmart h-[38px] disabled:opacity-40 disabled:cursor-not-allowed enabled:hover:bg-[#1880ef]"
          textClassName="text-xs text-white font-semibold leading-[18px]"
        >
          {renderPaymentButtonText}
        </PSButton>
      </div>
    </div>
  );
};
export default UnlockFeaturesModal;
