import React from 'react';
import { PsLottie } from 'ui';
import cn from 'classnames';

import styles from './Loading.module.scss';
import loading from '@assets/lotties/loading.json';

interface LoadingProps {
  className?: string;
  backgroundClassName?: string;
  width?: number;
  height?: number;
  zIndexValue?: number;
}

const Loading = (props: LoadingProps) => {
  const {
    className,
    backgroundClassName,
    width,
    height,
    zIndexValue = 99995,
  } = props;
  return (
    <div
      className={cn(styles.LoadingWrapper, className)}
      style={{ zIndex: zIndexValue }}
    >
      <div className={cn(styles.background, backgroundClassName)} />
      <div className={styles.lottieWrapper}>
        <PsLottie
          style={{
            width: width || 90,
            height: height || 90,
          }}
          loop={true}
          autoplay={true}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMin slice',
          }}
          animationData={loading}
          className="my-auto mx-auto "
        />
      </div>
    </div>
  );
};
export default Loading;
