import { RootState } from '@connectors/reducers';
import { createSelector } from 'reselect';

const meDefaultValues = {
  id: 0,
  name: '',
  surname: '',
  accountId: '',
  accountIsGettingStartedCompleted: false,
  accountUseProxiedFonts: false,
  apiToken: '',
  email: '',
  emailVerify: false,
  phone_number: '',
  isOwner: false,
  isShopifyUser: false,
  isPageViewBlocked: false,
  accountPlan: {
    description: '',
    displayCount: 0,
    domainCount: 0,
    id: 0,
    impressionCount: 0,
    interactionCount: 0,
    name: '',
    popupCount: 0,
    price: 0,
    stripeId: null,
    type: '',
    activePopupCount: 0,
  },
  isMigrated: false,
  paymentProvider: 'STRIPE',
  baseShopifyStore: '',
  systemLanguage: '',
};

const selectAuth = (state: RootState) => state.auth;

const makeSelectAccountId = createSelector(
  selectAuth,
  (user) => user.accountId,
);

const makeSelectAuth = createSelector(selectAuth, (auth) => ({
  ...auth,
  meData: {
    id: auth?.meData?.id || meDefaultValues.id,
    name: auth?.meData?.name || meDefaultValues.name,
    surname: auth?.meData?.surname || meDefaultValues.surname,
    accountId: auth?.meData?.accountId || meDefaultValues.accountId,
    accountIsGettingStartedCompleted:
      auth?.meData?.accountIsGettingStartedCompleted ||
      meDefaultValues.accountIsGettingStartedCompleted,
    accountUseProxiedFonts: auth?.meData?.accountUseProxiedFonts || false,
    apiToken: auth?.meData?.apiToken || meDefaultValues.apiToken,
    email: auth?.meData?.email || meDefaultValues.email,
    emailVerify: auth?.meData?.emailVerify || meDefaultValues.emailVerify,
    phone_number: auth?.meData?.phone_number || meDefaultValues.phone_number,
    isOwner: auth?.meData?.isOwner || meDefaultValues.isOwner,
    isShopifyUser: auth?.meData?.isShopifyUser || meDefaultValues.isShopifyUser,
    isPageViewBlocked: auth?.meData?.isPageViewBlocked || false,
    accountPlan: {
      activePopupCount: auth?.meData?.accountPlan?.activePopupCount || 0,
      description:
        auth?.meData?.accountPlan?.description ||
        meDefaultValues.accountPlan.description,
      displayCount:
        auth?.meData?.accountPlan?.displayCount ||
        meDefaultValues.accountPlan.displayCount,
      domainCount:
        auth?.meData?.accountPlan?.domainCount ||
        meDefaultValues.accountPlan.domainCount,
      id: auth?.meData?.accountPlan?.id || meDefaultValues.accountPlan.id,
      impressionCount:
        auth?.meData?.accountPlan?.impressionCount ||
        meDefaultValues.accountPlan.impressionCount,
      interactionCount:
        auth?.meData?.accountPlan?.interactionCount ||
        meDefaultValues.accountPlan.interactionCount,
      name: auth?.meData?.accountPlan?.name || meDefaultValues.accountPlan.name,
      popupCount:
        auth?.meData?.accountPlan?.popupCount ||
        meDefaultValues.accountPlan.popupCount,
      price:
        auth?.meData?.accountPlan?.price || meDefaultValues.accountPlan.price,
      stripeId:
        auth?.meData?.accountPlan?.stripeId ||
        meDefaultValues.accountPlan.stripeId,
      type: auth?.meData?.accountPlan?.type || meDefaultValues.accountPlan.type,
    },
    isMigrated: auth?.meData?.isMigrated || false,
    paymentProvider: auth?.meData?.paymentProvider || 'STRIPE',
    baseShopifyStore: auth?.meData?.baseShopifyStore,
    isShopify: auth.meData?.isShopify || false,
    userHasShopifyWebsite: auth.meData?.userHasShopifyWebsite || false,
    systemLanguage: auth.meData?.systemLanguage,
    __typename: 'User',
  },
}));

export { makeSelectAccountId, makeSelectAuth };
