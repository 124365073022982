import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import SiteVariantIcon from '@assets/icons/site_variant.svg';

export const renderSiteDataTargeting = (
  siteDataTargeting: Targets['siteDataTargeting'],
  translate: Function,
) => {
  const getRule = (rule: string) => {
    switch (rule) {
      case 'IS_PRESENT':
        return translate('common:isPresent');
      case 'IS_NOT_PRESENT':
        return translate('common:isNotPresent');
      case 'MATCHES':
        return translate('common:matches');
      case 'DOES_NOT_MATCH':
        return translate('common:doesNotMatch');
      case 'CONTAINS':
        return translate('common:contains');
      case 'LESS_THAN':
        return translate('common:lessThan');
      case 'LESS_THAN_EQUAL':
        return translate('common:lessThanEqual');
      case 'GREATER_THAN':
        return translate('common:greaterThan');
      case 'GREATER_THAN_EQUAL':
        return translate('common:greaterThanEqual');
      default:
        return rule.toLowerCase().replaceAll('_', ' ');
    }
  };
  if (siteDataTargeting && !!siteDataTargeting.length) {
    return (
      <>
        <TimelineContent
          icon={<SiteVariantIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate(
            'sections:targeting-summary-site-data-targeting:message',
          )}
        >
          {siteDataTargeting.map((source, index) => {
            return (
              <span
                className="flex items-start font-inter text-xs ml-2"
                key={source.value}
              >
                {index > 0 && (
                  <span className="font-semibold text-bluesmart mr-1">
                    {translate('common:or').toUpperCase()}
                  </span>
                )}
                {translate(
                  'sections:targeting-summary-site-data-targeting:value',
                  {
                    status: source.type === 'show' ? 'Show' : 'Hide',
                    rule: getRule(source.rule),
                    keyword: source.keyword,
                    value:
                      source.value !== ''
                        ? `${getRule(source.rule)} ${source.value}`
                        : '',
                  },
                )}
              </span>
            );
          })}
        </TimelineContent>
      </>
    );
  }
};
