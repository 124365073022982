import {
  EmailOctopusInput,
  EmailOctopusIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/EmailOctopusResolver.types';

export const GetEmailOctopusIntegrationsByCampaignId = (
  campaignId: number,
) => ({
  operationName: 'GetEmailOctopusIntegrationsByCampaignId',
  query: `query GetEmailOctopusIntegrationsByCampaignId($campaignId: Int!) {
    getEmailOctopusIntegrationsByCampaignId(campaignId: $campaignId) {
      campaignId
      id
      integrationId
      listId
      status
      name
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      integration {
        accountId
        apiKey
        id
        name
        status
      }
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetEmailOctopusIntegrationsByAccountId = () => ({
  operationName: 'GetEmailOctopusIntegrationsByAccountId',
  query: `query GetEmailOctopusIntegrationsByAccountId {
    getEmailOctopusIntegrationsByAccountId {
      accountId
      apiKey
      id
      integrationCampaigns {
        integrationId
        integrationFields {
          integrationCampaignId
          id
          fieldType
          integratorFieldId
          integratorFieldName
          integratorTag
          isCustomerEmail
          popupsmartFieldName
        }
        listId
        status
        integration {
          apiKey
          accountId
          id
          name
          status
        }
        id
        campaignId
      }
      name
      status
    }
  }`,
});
export const GetEmailOctopusLists = (apiKey: string) => ({
  operationName: 'GetEmailOctopusLists',
  query: `query GetEmailOctopusLists($apiKey: String!) {
    getEmailOctopusLists(apiKey: $apiKey) {
      id
      list_type
      name
    }
  }`,
  variables: {
    apiKey,
  },
});
export const GetEmailOctopusFields = (apiKey: string, listId: string) => ({
  operationName: 'GetEmailOctopusFields',
  query: `query GetEmailOctopusFields($listId: String!, $apiKey: String!) {
    getEmailOctopusFields(listId: $listId, apiKey: $apiKey) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    apiKey,
    listId,
  },
});
export const CreateEmailOctopus = (input: EmailOctopusInput) => ({
  operationName: 'CreateEmailOctopusIntegration',
  query: `mutation CreateEmailOctopusIntegration($input: EmailOctopusInput!) {
    createEmailOctopusIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateEmailOctopus = (
  id: number,
  input: EmailOctopusIntegrationCampaignInput,
) => ({
  operationName: 'UpdateEmailOctopusIntegrationCampaign',
  query: `mutation UpdateEmailOctopusIntegrationCampaign($input: EmailOctopusIntegrationCampaignInput!, $updateEmailOctopusIntegrationCampaignId: Int!) {
    updateEmailOctopusIntegrationCampaign(input: $input, id: $updateEmailOctopusIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateEmailOctopusIntegrationCampaignId: id,
  },
});

export const DeleteEmailOctopusIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteEmailOctopusIntegrationCampaign',
  query: `mutation DeleteEmailOctopusIntegrationCampaign($deleteEmailOctopusIntegrationCampaignId: Int!) {
    deleteEmailOctopusIntegrationCampaign(id: $deleteEmailOctopusIntegrationCampaignId)
  }`,
  variables: {
    deleteEmailOctopusIntegrationCampaignId: id,
  },
});
