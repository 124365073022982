import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  SENDGRID_GET_FIELDS,
  SENDGRID_GET_FIELDS_SUCCESS,
  SENDGRID_GET_FIELDS_FAILURE,
  SENDGRID_GET_CONNECTIONS,
  SENDGRID_GET_CONNECTIONS_SUCCESS,
  SENDGRID_GET_CONNECTIONS_FAILURE,
  SENDGRID_GET_LIST,
  SENDGRID_GET_LIST_SUCCESS,
  SENDGRID_GET_LIST_FAILURE,
  SENDGRID_SAVE,
  SENDGRID_SAVE_SUCCESS,
  SENDGRID_SAVE_FAILURE,
  SENDGRID_DELETE,
  SENDGRID_DELETE_FAILURE,
  SENDGRID_CLEAR_STATE,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import IntegrationEnums from '@enums/IntegrationEnums';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import graphqlFetch from '@utils/graphqlFetch';
import {
  CreateSendGrid,
  DeleteSendGridIntegrationCampaign,
  GetSendGridFields,
  GetSendGridIntegrationsByAccountId,
  GetSendGridIntegrationsByCampaignId,
  GetSendGridLists,
  UpdateSendGrid,
} from './graphql';
import { SendGridIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/SendGridResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getSendGridIntegrationsByAccountId: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetSendGridIntegrationsByAccountId(),
    });
    yield put({
      type: SENDGRID_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getSendGridIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: SENDGRID_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;
  try {
    const result: {
      data: { getSendGridFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetSendGridFields(apiKey),
    });

    onSuccess && onSuccess();
    yield put({
      type: SENDGRID_GET_FIELDS_SUCCESS,
      payload: result.data.getSendGridFields,
    });
  } catch (error) {
    yield put({ type: SENDGRID_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { onSuccess } = action.payload;
  try {
    const result: {
      data: { getSendGridLists: ApiListObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetSendGridLists(action.payload.apiKey),
    });
    onSuccess && onSuccess();
    yield put({
      type: SENDGRID_GET_LIST_SUCCESS,
      payload: result.data.getSendGridLists,
    });
  } catch (error) {
    yield put({ type: SENDGRID_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateSendGrid(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateSendGrid({ ...formData, status: true }),
      });
    }
    const integrations: {
      data: {
        getSendGridIntegrationsByCampaignId: SendGridIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetSendGridIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getSendGridIntegrationsByCampaignId,
        type: IntegrationEnums.sendgrid,
      }),
    );
    yield put({ type: SENDGRID_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: SENDGRID_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteSendGridIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.sendgrid,
      }),
    );
    yield put({ type: SENDGRID_CLEAR_STATE });
  } catch (error) {
    yield put({ type: SENDGRID_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(SENDGRID_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(SENDGRID_GET_FIELDS, handleGetFields);
  yield takeLatest(SENDGRID_GET_LIST, handleGetList);
  yield takeLatest(SENDGRID_SAVE, handleSave);
  yield takeLatest(SENDGRID_DELETE, handleDelete);
}
