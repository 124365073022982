import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

// import { BrowserLanguagesOptions } from '@components/Targeting/BrowserLanguage/BrowserLanguage';
// import { Option } from '@elements/Selectbox/Selectbox';
import TimelineContent from '../TimelineContent';

import BrowserLanguageIcon from '@assets/icons/browser_language.svg';

export const renderBrowserLanguage = (
  browserLanguage: Targets['browserLanguage'],
  translate: Function,
) => {
  if (browserLanguage && !!browserLanguage.length) {
    let languages = '';

    browserLanguage.map((lang: string) => {
      languages += `${lang}, `;
    });

    return (
      <>
        <TimelineContent
          icon={
            <BrowserLanguageIcon width="16" height="16" viewBox="0 0 36 36" />
          }
          label={`${translate(
            'sections:targeting-summary-browser-language:message',
          )} ${languages}`}
        />
      </>
    );
  }
};
