import React, { FC, useState } from 'react';
import { useClickOutsideHandler } from '@utils/hooks';
import styles from './ThemeSelect.module.scss';
import { ThemeSelectProps } from './types';
import cn from 'classnames';
import ThemeItem from './ThemeItem';
import ThemeSelector from './ThemeSelector';
import { useTranslation } from 'react-i18next';

export const ThemeSelect: FC<ThemeSelectProps> = ({
  onResetTheme,
  onChangeClick,
  selectedTheme,
  onSelectTheme,
  onApplyTheme,
  menuClassName,
  modalClassName,
  isReadonlyCampaign,
  isThemeChanged,
  onClose,
  onOpen,
}) => {
  const { t } = useTranslation();
  const [showThemeList, setShowThemeList] = useState<boolean>(false);

  const modalRef = useClickOutsideHandler({
    onClose: () => {
      if (modalRef.current?.querySelector('#theme-selector') === null) {
        return;
      }
      onClose && onClose();
      setShowThemeList(false);
    },
  });

  const handleChangeClick = () => {
    if (showThemeList === false) {
      onOpen?.();
    }
    setShowThemeList(!showThemeList);
    onChangeClick && onChangeClick();
  };

  const handleClose = () => {
    setShowThemeList(false);
  };

  return (
    <div
      className={cn(styles.themeContainer, {
        'readonly readonly-opacity': isReadonlyCampaign,
      })}
    >
      <div className={styles.header}>
        <div className={styles.title}>{t('common:theme')}</div>
        {!showThemeList && (
          <div className={styles.buttons}>
            <button
              data-testid="reset_theme"
              className={cn(styles.reset, {
                '!text-[#1c86fa]': isThemeChanged,
              })}
              onClick={() => onResetTheme()}
            >
              {t('common:reset')}
            </button>
            <button
              className={styles.changeButton}
              data-testid="change_theme"
              onClick={handleChangeClick}
            >
              {t('common:change')}
            </button>
          </div>
        )}
      </div>

      <div ref={modalRef}>
        <div className={styles.currentTheme}>
          <ThemeItem
            name={selectedTheme.name}
            fontFamily={selectedTheme.fontFamily}
            image={selectedTheme.image}
            code={selectedTheme.code}
            clickable={false}
            fontColor={selectedTheme.fontColor}
            fontFamilyColor={selectedTheme.fontFamilyColor}
            onClick={handleChangeClick}
            size="md"
          />
        </div>
        {showThemeList && (
          <div id="theme-selector">
            <ThemeSelector
              onClose={handleClose}
              onSelectTheme={onSelectTheme}
              onApplyTheme={onApplyTheme}
              menuClassName={menuClassName}
              modalClassName={modalClassName}
              selectedThemeCode={selectedTheme.code}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ThemeSelect;
