import {
  CUSTOMERIO_CHANGESTATUS,
  CUSTOMERIO_CHANGESTATUS_FAILURE,
  CUSTOMERIO_CHANGESTATUS_SUCCESS,
  CUSTOMERIO_CLEAR_STATE,
  CUSTOMERIO_DELETE,
  CUSTOMERIO_DELETE_FAILURE,
  CUSTOMERIO_DELETE_SUCCESS,
  CUSTOMERIO_GET_CONNECTIONS,
  CUSTOMERIO_GET_CONNECTIONS_FAILURE,
  CUSTOMERIO_GET_CONNECTIONS_SUCCESS,
  CUSTOMERIO_GET_FIELDS,
  CUSTOMERIO_GET_FIELDS_FAILURE,
  CUSTOMERIO_GET_FIELDS_SUCCESS,
  CUSTOMERIO_GET_LIST,
  CUSTOMERIO_GET_LIST_FAILURE,
  CUSTOMERIO_GET_LIST_SUCCESS,
  CUSTOMERIO_SAVE,
  CUSTOMERIO_SAVE_FAILURE,
  CUSTOMERIO_SAVE_SUCCESS,
} from './constants';
import {
  CustomerIoConnectionsOutput,
  CustomerIoDeleteInput,
  CustomerIoDeleteOutput,
  CustomerIoFieldsInput,
  CustomerIoFieldsOutput,
  CustomerIoListsInput,
  CustomerIoListsOutput,
  CustomerIoSaveActionPayload,
  CustomerIoSaveOutput,
} from './type';

export function getCustomerIoConnections() {
  return {
    type: CUSTOMERIO_GET_CONNECTIONS,
  };
}
export function getCustomerIoConnectionsSuccess(
  payload: CustomerIoConnectionsOutput,
) {
  return {
    type: CUSTOMERIO_GET_CONNECTIONS_SUCCESS,
    payload,
  };
}
export function getCustomerIoConnectionsFailure() {
  return {
    type: CUSTOMERIO_GET_CONNECTIONS_FAILURE,
  };
}

export function getCustomerIoFields(payload: CustomerIoFieldsInput) {
  const { apiKey, listId, onSuccess } = payload;
  return {
    type: CUSTOMERIO_GET_FIELDS,
    payload: {
      apiKey,
      listId,
      onSuccess,
    },
  };
}

export function getCustomerIoFieldsSuccess(payload: CustomerIoFieldsOutput) {
  return {
    type: CUSTOMERIO_GET_FIELDS_SUCCESS,
    payload,
  };
}

export function getCustomerIoFieldsFailure() {
  return {
    type: CUSTOMERIO_GET_FIELDS_FAILURE,
  };
}

export function getCustomerIoList(payload: CustomerIoListsInput) {
  const { apiKey, onSuccess } = payload;
  return {
    type: CUSTOMERIO_GET_LIST,
    payload: {
      apiKey,
      onSuccess,
    },
  };
}

export function getCustomerIoListSuccess(payload: CustomerIoListsOutput) {
  return {
    type: CUSTOMERIO_GET_LIST_SUCCESS,
    payload,
  };
}

export function getCustomerIoListFailure() {
  return {
    type: CUSTOMERIO_GET_LIST_FAILURE,
  };
}

export function saveCustomerIo(payload: CustomerIoSaveActionPayload) {
  return {
    type: CUSTOMERIO_SAVE,
    payload,
  };
}
export function saveCustomerIoSuccess(payload: CustomerIoSaveOutput) {
  return {
    type: CUSTOMERIO_SAVE_SUCCESS,
    payload,
  };
}
export function saveCustomerIoFailure() {
  return {
    type: CUSTOMERIO_SAVE_FAILURE,
  };
}

export function deleteCustomerIo(payload: CustomerIoDeleteInput) {
  return {
    type: CUSTOMERIO_DELETE,
    payload,
  };
}

export function deleteCustomerIoSuccess(payload: CustomerIoDeleteOutput) {
  return {
    type: CUSTOMERIO_DELETE_SUCCESS,
    payload,
  };
}

export function deleteCustomerIoFailure() {
  return {
    type: CUSTOMERIO_DELETE_FAILURE,
  };
}

export function changeStatusCustomerIo(id: number) {
  return {
    type: CUSTOMERIO_CHANGESTATUS,
    payload: {
      id,
    },
  };
}
export function changeStatusCustomerIoSuccess(id: number) {
  return {
    type: CUSTOMERIO_CHANGESTATUS_SUCCESS,
    payload: {
      id,
    },
  };
}
export function changeStatusCustomerIoFailure() {
  return {
    type: CUSTOMERIO_CHANGESTATUS_FAILURE,
  };
}
export function clearCustomerIoState() {
  return {
    type: CUSTOMERIO_CLEAR_STATE,
  };
}
