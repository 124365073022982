import { Content } from '@connectors/builder/campaign';
import deepForEach from 'deep-for-each';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { get, pull } from 'lodash';

export const moveLayoutContent = (
  existingTeaser: Content,
  newTeaser: Content,
  keepNotMatchingElements?: boolean,
) => {
  const newTeaserCopy = cloneDeep(newTeaser);
  const moveContent = (
    existingTeaser: Content,
    newTeaser: Content,
    device: 'mobile' | 'desktop',
  ) => {
    const processedElements = new Set(); // A Set to track processed elements
    const existingTeaserElements = new Set();
    const newTeaserElements = new Set();
    deepForEach(existingTeaser.content[device], (value, key, parent, path) => {
      if (
        key === 'nodeName' &&
        (value === 'text' ||
          value === 'subtext' ||
          value === 'image' ||
          value === 'lottie')
      ) {
        let processed = false;
        existingTeaserElements.add(value);

        deepForEach(
          newTeaser?.content[device],
          (fValue, fKey, fParent, fPath) => {
            const elementPath = `${(fPath as string)?.substring(
              0,
              fPath?.lastIndexOf('.'),
            )}`;
            if (
              fKey === 'nodeName' &&
              (fValue === 'text' ||
                fValue === 'subtext' ||
                fValue === 'image' ||
                fValue === 'lottie')
            ) {
              newTeaserElements.add(fValue);

              if (
                !processedElements.has(elementPath) &&
                parent.nodeName === fParent.nodeName
              ) {
                processed = true;
                processedElements.add(elementPath); // Mark the element as processed

                // image migration
                if (parent.nodeName === 'image') {
                  set(
                    newTeaserCopy.content[device],
                    `${elementPath}.attributes.src`,
                    parent.attributes.src,
                  );
                  const imageStyles = {
                    ...fParent.attributes.style,
                    objectFit: parent.attributes.style?.objectFit,
                    width: parent.attributes.style?.width,
                    alignItems: parent.attributes.style?.alignItems,
                    justifyContent: parent.attributes.style?.justifyContent,
                  };
                  set(
                    newTeaserCopy.content[device],
                    `${elementPath}.attributes.style`,
                    imageStyles,
                  );
                  return;
                }
                // lottie migration
                if (parent.nodeName === 'lottie') {
                  set(
                    newTeaserCopy.content[device],
                    `${elementPath}.attributes`,
                    parent.attributes,
                  );
                  return;
                }
                // other elements migration
                set(
                  newTeaserCopy.content[device],
                  `${elementPath}.childNodes`,
                  parent.childNodes,
                );
                return;
              }
            }
          },
        );

        // if element inside existingTeaser failed to be processed we should just push the element to new teaser
        if (!processed && !keepNotMatchingElements) {
          const missingElement = get(
            existingTeaser.content[device],
            `${(path as string)?.substring(0, path?.lastIndexOf('.'))}`,
          );

          // it is a some block's childNode if it is here. we can just push
          get(
            newTeaserCopy.content[device],
            `${(path as string)
              ?.substring(0, path?.lastIndexOf('.'))
              .replace(new RegExp('\\[\\d]$'), '')}`,
          )?.push(missingElement);
        }
      }
    });

    // remove element from NewTeaser because it doesn't exist inside Existing Teaser
    if (!keepNotMatchingElements) {
      const deletableElements = new Set(
        [...newTeaserElements].filter(
          (element) => !existingTeaserElements.has(element),
        ),
      );

      for (const value of deletableElements) {
        deepForEach(
          newTeaser?.content[device],
          (fValue, fKey, fParent, fPath) => {
            if (value === fValue) {
              const elementPath = `${(fPath as string)?.substring(
                0,
                fPath?.lastIndexOf('.'),
              )}`;
              pull(
                get(
                  newTeaserCopy.content[device],
                  elementPath.replace(new RegExp('\\[\\d]$'), ''),
                ),
                get(newTeaserCopy.content[device], elementPath),
              );
            }
          },
        );
      }
    }
  };

  moveContent(existingTeaser, newTeaserCopy, 'desktop');
  moveContent(existingTeaser, newTeaserCopy, 'mobile');

  return newTeaserCopy;
};
