import type { Targets } from '@connectors/targets/reducer';
import React from 'react';

import TimelineContent from '../TimelineContent';

import ScrollingIcon from '@assets/icons/scrolling.svg';

export const renderAfterScrollingXAmount = (
  afterScrollingXAmount: Targets['afterScrollingXAmount'],
  translate: Function,
) => {
  if (afterScrollingXAmount) {
    return (
      <>
        <TimelineContent
          icon={<ScrollingIcon width="16" height="16" viewBox="0 0 36 36" />}
          behavior
          label={translate('sections:targeting-summary-after-scroll:message', {
            value: afterScrollingXAmount.value,
            unit: afterScrollingXAmount.sizeType === 'PERCENT' ? '%' : 'px',
          })}
        />
      </>
    );
  }
};
