import {
  DELETE_INTEGRATION,
  CHANGE_STATUS_INTEGRATION,
  ADD_INTEGRATION,
  UPDATE_INTEGRATION,
  DOMAIN_DELETE,
  DOMAIN_SELECT,
  SAVE_SETTINGS,
  UPDATE_AUTO_RESPONDER_REQUEST,
  DISABLE_ALL_INTEGRATIONS,
  SET_INTEGRATIONS,
} from './constants';
import {
  AddIntegrationAction,
  AddIntegrationPayload,
  ChangeStatusIntegrationAction,
  ChangeStatusIntegrationPayload,
  DeleteDomainAction,
  DeleteDomainActionPayload,
  DeleteIntegrationAction,
  DeleteIntegrationPayload,
  SaveSettingsAction,
  SaveSettingsPayload,
  SelectDomainAction,
  SelectDomainActionPayload,
  SetIntegrationAction,
  UpdateAutoResponderAction,
  UpdateAutoResponderPayload,
  UpdateIntegrationAction,
  UpdateIntegrationPayload,
} from './types';
import IntegrationEnums from '@enums/IntegrationEnums';

export function deleteIntegration(
  payload: DeleteIntegrationPayload,
): DeleteIntegrationAction {
  return {
    type: DELETE_INTEGRATION,
    payload,
  };
}
export function changeStatusIntegration(
  payload: ChangeStatusIntegrationPayload,
): ChangeStatusIntegrationAction {
  return {
    type: CHANGE_STATUS_INTEGRATION,
    payload,
  };
}

export function disableAllIntegrations() {
  return {
    type: DISABLE_ALL_INTEGRATIONS,
  };
}

export function setIntegration(payload: {
  datas: any;
  type: IntegrationEnums;
}): SetIntegrationAction {
  return {
    type: SET_INTEGRATIONS,
    payload,
  };
}

export function addIntegration(
  payload: AddIntegrationPayload,
): AddIntegrationAction {
  return {
    type: ADD_INTEGRATION,
    payload,
  };
}

export function updateIntegration(
  payload: UpdateIntegrationPayload,
): UpdateIntegrationAction {
  return {
    type: UPDATE_INTEGRATION,
    payload,
  };
}

export function deleteDomain(
  payload: DeleteDomainActionPayload,
): DeleteDomainAction {
  return {
    type: DOMAIN_DELETE,
    payload,
  };
}

export function selectDomain(
  payload: SelectDomainActionPayload,
): SelectDomainAction {
  return {
    type: DOMAIN_SELECT,
    payload,
  };
}

export function saveSettings(payload: SaveSettingsPayload): SaveSettingsAction {
  return {
    type: SAVE_SETTINGS,
    payload,
  };
}

export function updateAutoResponder(
  payload: UpdateAutoResponderPayload,
  onSuccess: () => void,
): UpdateAutoResponderAction {
  return {
    type: UPDATE_AUTO_RESPONDER_REQUEST,
    payload,
    onSuccess,
  };
}
