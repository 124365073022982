import { City, Country, Region } from '@connectors/locations/reducer';
import React from 'react';

import TimelineContent from '../TimelineContent';
import { GeoLocatedInputValues } from '@components/Targeting/Audience/Target/GeoLocated/GeoLocated';

import GeoLocatedIcon from '@assets/icons/geo_located.svg';

interface GeoLocatedProps extends GeoLocatedInputValues {
  locationState: {
    countries: Country[];
    regions: { [key: string]: Region[] };
    cities: { [key: string]: City[] };
  };
  translate: Function;
}

type LocationProp = {
  city: string;
  countryCode: string;
  regionCode: string;
};
export const renderGeoLocated = ({
  locations,
  type,
  locationState,
  translate,
}: GeoLocatedProps) => {
  const renderLocationText = (location: LocationProp) => {
    const array = [];
    if (location.countryCode) {
      const country = locationState.countries.find(
        (x) => x.code === location.countryCode,
      );
      array.push(country?.name.replaceAll(',', '::'));
    }
    if (location.regionCode) {
      const regions = locationState.regions[location.countryCode];
      if (regions) {
        const region = regions.find((x) => x.code === location.regionCode);
        array.push(region?.name.replaceAll(',', '::'));
      }
    }
    if (location.city) {
      const cities =
        locationState.cities[`${location.countryCode}_${location.regionCode}`];
      if (cities) {
        const city = cities.find((x) => x.name === location.city);
        array.push(city?.name.replaceAll(',', '::'));
      }
    }
    return array.toString().replaceAll(',', ' / ').replaceAll('::', ',');
  };

  if (locations && !!locations.length) {
    return (
      <>
        <TimelineContent
          icon={<GeoLocatedIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate('sections:targeting-summary-geo-located:message', {
            type: type === 'deny' ? 'Does Not' : '',
          })}
        >
          {locations.length !== 0 &&
            locations.map((source, index) => (
              <span
                className="flex items-start font-inter text-xs ml-2"
                key={source.city}
              >
                {index > 0 && (
                  <span className="font-semibold text-bluesmart mr-1">
                    {translate('common:or').toUpperCase()}
                  </span>
                )}
                {renderLocationText(source as LocationProp)}
              </span>
            ))}
        </TimelineContent>
      </>
    );
  }
};
