import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from '../NoVerifiedWebsiteModal.module.scss';
import PopupsmartIcon from '@assets/icons/popupsmart-rounded.svg';
import { useRouter } from 'next/router';
import LeftIconButton from '@components/LeftIconButton/LeftIconButton';
import WordpressIcon from '@assets/icons/wordpress-icon.svg';
import SandGlassIcon from '@assets/icons/sandglass.svg';
import CheckIcon from '@assets/icons/check_circle_outline.svg';
import Favicon from '@components/Favicon';
import PSButton from '@elements/PSButton';
import { Tooltip } from 'ui';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@connectors/reducers';
import type { DefaultWebsiteOptionProps } from '../types';
import type { Website } from '@connectors/websites/reducer';
import useVerifyWebsiteWindow from '@utils/useVerifyWebsiteWindow';
import { toast } from 'react-toastify';
import { getWebsites } from '@connectors/websites/actions';
import { hideModal } from '@connectors/modal/actions';
import { Trans, useTranslation } from 'react-i18next';

const WordpressWebsiteOption: FC<DefaultWebsiteOptionProps> = ({
  url,
  routerReload = true,
  onVerifyComplete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { items: websites, status: websitesStatus } = useSelector(
    (state: RootState) => state.websites,
  );
  const selectedWebsite = websites.filter(
    (website: Website) => website.url === url,
  )[0];

  const isLoading = websitesStatus === 'loading';

  const [isVerifyAttempted, setIsVerifyAttempted] = useState(false);

  const { openVerifyWindow } = useVerifyWebsiteWindow();

  const verifyHandler = (url: string) => {
    openVerifyWindow({ url, onClose: refreshHandler });
    setIsVerifyAttempted(true);
  };
  const refreshHandler = () => {
    setIsVerifyAttempted(true);
    dispatch(getWebsites());
  };

  useEffect(() => {
    //Is verify attempted is added because we only want to
    //reload if verify is attempted
    if (isVerifyAttempted && selectedWebsite?.verified) {
      toast.success('Your website is verified!');

      !routerReload && dispatch(hideModal('NoVerifiedWebsiteModal'));
      onVerifyComplete();
      routerReload &&
        setTimeout(() => {
          dispatch(hideModal('NoVerifiedWebsiteModal'));
          router.reload();
        }, 2000);
    }
  }, [selectedWebsite]);

  const handleRegisterWithWordpress = () => {
    window.open('https://wordpress.org/plugins/popupsmart/', '_blank');
  };
  return (
    <>
      <div className={styles.wordpressContentContainer}>
        <div className={styles.header}>
          <div className="flex">
            <div className={styles.icon}>
              <PopupsmartIcon width="32" height="32" viewBox="0 0 48 48" />
            </div>
            <div className={cn(styles.icon, 'ml-[-10px] bg-white')}>
              <WordpressIcon
                width="32"
                height="32"
                viewBox="0 0 18 18"
                fill="#464646"
              />
            </div>
          </div>
        </div>
        <h4 className="font-bold text-lg leading-6 mt-[15px] mb-[25px] text-center">
          <Trans i18nKey="sections:unverified-modal:wordpressAppText" />
        </h4>

        <LeftIconButton
          icon={WordpressIcon}
          onClick={handleRegisterWithWordpress}
          backgroundColor="wordpress"
          iconFill="#000000"
        >
          {t('sections:unverified-modal:wordpressAppButton')}
        </LeftIconButton>
      </div>
      <div className="mt-[30px]">
        <div className="font-[600] text-xs leading-[18px]">
          {t('sections:unverified-modal:wordpressStepOneTitle')}
        </div>
        <div className="flex flex-col text-xs leading-[16px] mt-2.5">
          <Trans i18nKey="sections:unverified-modal:wordpressStepOneDescription" />
        </div>

        <div
          className={cn(
            'rounded-md py-1 px-[7px] flex items-center mt-[25px] w-full',
            selectedWebsite?.verified && 'bg-greenlight',
            !selectedWebsite?.verified && 'bg-orangelight',
          )}
        >
          <Favicon url={selectedWebsite?.url} isSmall className="mr-3" />

          <Tooltip
            className="!flex"
            contentClassName="!left-0 !translate-x-0 !-translate-y-full !max-w-full !break-words !whitespace-normal"
            content={selectedWebsite?.url}
          >
            <span className="overflow-hidden text-ellipsis max-w-[220px] whitespace-nowrap text-sm font-normal">
              {selectedWebsite?.url}
            </span>
          </Tooltip>
          <div
            className={cn(
              styles.badge,
              selectedWebsite?.verified
                ? styles.verifiedBadge
                : styles.unverifiedBadge,
            )}
          >
            <div className={styles.status}>
              <div
                className={cn(
                  styles.icon,
                  selectedWebsite?.verified
                    ? styles.verifiedIcon
                    : styles.unverifiedIcon,
                )}
              >
                {selectedWebsite?.verified ? (
                  <CheckIcon width="14" height="14" viewBox="-1 -1 20 20" />
                ) : (
                  <SandGlassIcon width="14" height="14" viewBox="-4 -1 16 20" />
                )}
              </div>
              <div className={styles.label}>
                {selectedWebsite?.verified
                  ? t('sections:unverified-modal:verified')
                  : t('sections:unverified-modal:waitingVerification')}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-3 mt-[25px]">
          <PSButton
            type="button"
            size="sm"
            variant="primary"
            onClick={() => {
              verifyHandler(selectedWebsite?.url);
            }}
            textClassName="text-xs"
            id="no-verified-modal-verify-button"
          >
            {t('sections:unverified-modal:verifyWebsite')}
          </PSButton>
          <PSButton
            type="button"
            size="sm"
            variant="second"
            loading={isLoading}
            onClick={() => {
              refreshHandler();
            }}
            textClassName="text-xs text-[#777]"
            id="no-verified-modal-refresh-button"
          >
            {t('common:refresh')}
          </PSButton>
        </div>
      </div>
    </>
  );
};

export default WordpressWebsiteOption;
