import type { SearchInputProps } from './types';
import React, { useState, useEffect, FC } from 'react';
import cn from 'classnames';

import styles from './SearchInput.module.scss';

import CloseIcon from '../../assets/icons/CloseIcon';
import SearchIcon from '../../assets/icons/SearchIcon';

const SearchInput: FC<SearchInputProps> = ({
  wrapperClassName,
  placeholder,
  value = '',
  iconPosition = 'right',
  onChangeHandler,
  ...rest
}) => {
  const [searchInput, setSearchInput] = useState(value);
  const [inputState, setInputState] = useState<'search' | 'default'>('default');

  const _onChange = (value: string) => {
    setSearchInput(value);
    onChangeHandler(value);
  };

  useEffect(() => {
    setSearchInput(value);
  }, [value]);

  return (
    <div
      className={cn(
        styles.search_input,
        iconPosition === 'left' && styles.icon_left,
        wrapperClassName,
      )}
      onClick={(e) => {
        e.preventDefault();
      }}
      onFocus={() => {
        setInputState('search');
      }}
      onBlur={() => {
        setInputState('default');
      }}
      data-testid="search-input-wrapper"
    >
      <input
        type="text"
        placeholder={placeholder || ''}
        value={searchInput}
        onChange={({ target }) => _onChange(target.value)}
        data-testid="search-input"
        {...rest}
      />
      <div
        className={cn(styles.search_icon, {
          [styles.active]: inputState === 'search',
        })}
        //onMouseDown is used because of the onBlur on
        //above div and onClick were colliding
        onMouseDown={() => {
          inputState === 'search' && _onChange('');
        }}
        data-testid="search-input-icon"
      >
        {inputState === 'default' ? (
          <SearchIcon width="16px" height="16px" viewBox="0 0 16 16" />
        ) : (
          <CloseIcon width="16px" height="16px" viewBox="0 0 24 24" />
        )}
      </div>
    </div>
  );
};

export default SearchInput;
