export const TARGETS_TO_TARGETS_COPY = 'builder/target/TARGETS_TO_TARGETS_COPY';
export const SET_TARGETS_COPY = 'builder/target/SET_TARGETS_COPY';
export const TARGETS_COPY_TO_TARGETS = 'builder/target/TARGETS_COPY_TO_TARGETS';
export const RESET_TARGETS_COPY = 'builder/target/RESET_TARGETS_COPY';
export const UPDATE_TARGETS_COPY = 'builder/target/UPDATE_TARGETS_COPY';
export const DELETE_TARGET_TARGETS_COPY =
  'builder/target/DELETE_TARGET_TARGETS_COPY';
export const SET_TARGET_ERROR = 'builder/target/SET_TARGET_ERROR';
export const DELETE_TARGET_ERROR = 'builder/target/DELETE_TARGET_ERROR';
export const SAVE_TARGETS = 'builder/target/SAVE_TARGETS';
