const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  encryptSecretKey: process.env.NEXT_PUBLIC_ENCRYPT_SECRET_KEY,
  airbrakeProjectId: process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID,
  airbrakeProjectKey: process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY,
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  googleLoginClientId: process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID,
  cdnUrl: process.env.NEXT_PUBLIC_CDN_URL,
  bundlePath: process.env.NEXT_PUBLIC_BUNDLE_PATH,
  tokenRoleKey: process.env.NEXT_PUBLIC_TOKEN_ROLE_KEY,
  airtableApiKey: process.env.NEXT_PUBLIC_AIRTABLE_API_KEY,
  airtableAppKey: process.env.NEXT_PUBLIC_AIRTABLE_APP_KEY,
  recaptchaSitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY,
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  hubspotClientId: process.env.NEXT_PUBLIC_HUBSPOT_CLIENT_ID,
  hubspotClientSecret: process.env.NEXT_PUBLIC_HUBSPOT_CLIENT_SECRET,
  hubspotScope: process.env.NEXT_PUBLIC_HUBSPOT_SCOPE,
  campaignAutoSavingThresholdMs:
    process.env.NEXT_PUBLIC_CAMPAIGN_AUTO_SAVING_THRESHOLD_MS,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
  isDev: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development',
  isStaging: process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging',
  isProd: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production',
  jwtSecret: process.env.JWT_SECRET || 'soo_secret',
  jwtExpire: process.env.JWT_EXPIRES_IN || '1h',
};

export default config;
