import { Node } from '@connectors/builder/campaign';
import { SIZE_RATIO } from '@connectors/builder/reducer';
import { StepContent } from '@connectors/newStep/types';

export const resizePopupLayout = ({
  page: layout,
  currentSize,
  toSize = 'md',
}: {
  page: StepContent;
  currentSize: 'sm' | 'md' | 'lg';
  toSize: 'sm' | 'md' | 'lg';
}) => {
  let pageCopy = JSON.parse(JSON.stringify(layout));
  const regex = /(\d*\.?\d+)\s?(px|em|rem|vw|vh+)/gim;
  const ratio = SIZE_RATIO[currentSize][toSize];

  // layout size update
  for (const deviceType in pageCopy.content) {
    const layoutString = JSON.stringify(pageCopy.content[deviceType]);
    const newContent = layoutString.replace(
      regex,
      (_: any, size: number, unit: string) => {
        const newSize: number = size * ratio;
        return `${Number(newSize.toFixed(2))}${unit}`;
      },
    );
    pageCopy.content[deviceType] = JSON.parse(newContent);
    pageCopy.appearance.size = toSize;
  }

  return pageCopy;
};

export const resizePopupLayoutNode = ({
  node,
  currentSize,
  toSize = 'md',
}: {
  node: Node;
  currentSize: 'sm' | 'md' | 'lg';
  toSize: 'sm' | 'md' | 'lg';
}) => {
  let nodeCopy = JSON.parse(JSON.stringify(node));
  const regex = /(\d*\.?\d+)\s?(px|em|rem|vw|vh+)/gim;
  const ratio = SIZE_RATIO[currentSize][toSize];

  // layout size update
  const layoutString = JSON.stringify(nodeCopy);
  const newContent = layoutString.replace(
    regex,
    (_: any, size: number, unit: string) => {
      const newSize: number = size * ratio;
      return `${Number(newSize.toFixed(2))}${unit}`;
    },
  );
  nodeCopy = JSON.parse(newContent);
  nodeCopy.attributes.size = toSize;

  return nodeCopy;
};
