import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import type { Modal as ReducerModal } from '@connectors/modal/reducer';
import { Template } from '@connectors/templates/types';
import { hideModal } from '@connectors/modal/actions';
import { RootState } from '@connectors/reducers';
import TemplateDetailsModal from '@components/TemplateDetailsModal/TemplateDetailsModal';
import { Size, sizes } from '@components/PsModal/PsModal';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Virtual } from 'swiper';

import CloseIcon from '@assets/icons/close.svg';
import PrevIcon from '@assets/icons/prev.svg';
import NextIcon from '@assets/icons/next.svg';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import styles from './TemplateModal.module.scss';

SwiperCore.use([Keyboard]);

export interface TemplateModalProps {
  id: string;
  onClose?: () => void;
  size?: Size | string;
  radius?: string;
  className?: string;
}

const TemplateModal: FC<TemplateModalProps> = ({
  size = 600,
  radius,
  id,
  className,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [swiper, setSwiper] = useState<SwiperCore>();
  const [slideConfig, setSlideConfig] = useState({
    isBeginning: true,
    isEnd: false,
  });
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState<{
    prev: number | null;
    current: number | null;
    next: number | null;
  }>({
    prev: null,
    current: null,
    next: null,
  });

  const selectedModal = useSelector((state: RootState) =>
    state.modal.modals.find((modal: ReducerModal) => modal.id === id),
  );
  const templateId = selectedModal?.data?.templateId;

  const handleOutsideClick = (e: MouseEvent<HTMLElement>) => {
    const el = document
      .querySelector('#template-selection-slider')
      ?.querySelector(`.swiper-slide-active`);
    if (
      el?.contains(e.target as Node) ||
      (e.target as HTMLInputElement).id === 'next-slider-button' ||
      (e.target as HTMLInputElement).id === 'prev-slider-button'
    ) {
      return;
    }
    const themeSelectModal = document.querySelector('#theme-selector');
    if (themeSelectModal !== null) return;
    onClickHandler();
  };

  const escapeListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClickHandler();
    }
  }, []);

  useEffect(() => {
    if (!selectedModal) return;
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('keyup', escapeListener);
    };
  }, [selectedModal]);

  const templatesItems = useMemo(() => {
    return (selectedModal?.data?.items as Template[]) || [];
  }, [selectedModal]);

  const isShowModal = selectedModal?.id === id;

  const modalSize = sizes[size as Size] || `!w-[${size}]`;
  const borderRadius = radius ? `rounded-[${radius}]` : 'rounded-lg';

  const handleGetSelectedTemplates = async (currentTemplateId: number) => {
    const currentIndex = templatesItems.findIndex(
      (item) => item.id === currentTemplateId,
    );

    const nextItem = templatesItems?.[currentIndex + 1];
    const prevItem = templatesItems?.[currentIndex - 1];

    setSelectedTemplatesIds({
      prev: prevItem?.id ?? null,
      current: currentTemplateId,
      next: nextItem?.id ?? null,
    });
  };

  const onClickHandler = () => {
    dispatch(hideModal(id));
    onClose && onClose();
  };

  useEffect(() => {
    if (templateId) {
      handleGetSelectedTemplates(Number(templateId));
    }
  }, [templateId]);

  const initialSwiperIndex = useMemo(
    () => templatesItems.findIndex((template) => templateId === template.id),
    [templateId, templatesItems],
  );
  if (!isShowModal) return <></>;

  return (
    <>
      <div
        className={cn(
          'modal overflow-y-auto lg:overflow-y-hidden overflow-x-hidden fixed right-0 left-0 top-auto z-[999] justify-center items-center h-full !inset-0 flex transition-all',
          { hidden: !selectedModal },
          className,
        )}
        id="template-selection-slider"
        aria-hidden="true"
        onClick={(e) => handleOutsideClick(e)}
      >
        <Swiper
          onSwiper={setSwiper}
          onSlideChange={(swipe) => {
            const selectedItem = templatesItems?.[swipe?.activeIndex];
            if (selectedItem.id === selectedTemplatesIds.current) return;

            handleGetSelectedTemplates(selectedItem.id);
            setSlideConfig({
              isBeginning: swipe.isBeginning,
              isEnd: swipe.isEnd,
            });
          }}
          className="templateSlider"
          speed={400}
          keyboard={true}
          touchStartPreventDefault={false}
          initialSlide={initialSwiperIndex}
          modules={[Virtual]}
          breakpoints={{
            1200: {
              slidesPerView: 1.1,
              spaceBetween: 10,
            },
            1440: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            1920: {
              slidesPerView: 1.5,
              spaceBetween: 50,
            },
          }}
          centeredSlides
          virtual
        >
          {templatesItems?.map((template: Template, index: number) => {
            const isCurrent = template.id === selectedTemplatesIds.current;
            const isPrev = template.id === selectedTemplatesIds.prev;
            const isNext = template.id === selectedTemplatesIds.next;

            const isRenderable = isCurrent || isPrev || isNext;
            return (
              <SwiperSlide key={template.id} virtualIndex={index}>
                <div
                  className={cn(
                    `relative px-4 w-full h-auto`,
                    modalSize,
                    isPrev || isNext ? 'side' : 'current',
                  )}
                  id={`active-template-modal-${template.id}`}
                >
                  <div
                    className={cn(
                      'relative bg-white  shadow dark:bg-gray-700 overflow-hidden',
                      borderRadius,
                    )}
                  >
                    <button
                      type="button"
                      className="ps-modal-close-button absolute top-0 right-0  rounded-full text-sm p-[15px] pr-[0px] ml-auto mr-[15px] inline-flex items-center z-10  "
                      onClick={onClickHandler}
                    >
                      <div className="pointer-events-none">
                        <CloseIcon
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="#777777"
                        />
                      </div>
                    </button>

                    <TemplateDetailsModal
                      {...selectedModal?.data}
                      key={template.id}
                      renderPreview={isRenderable}
                      isCenterItem={isCurrent}
                      templateId={isRenderable ? template?.id : undefined}
                      updateStyle={isCurrent}
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

          {!(swiper?.isBeginning || slideConfig?.isBeginning) && (
            <button
              className={styles.prev}
              onClick={() => swiper?.slidePrev()}
              id="prev-slider-button"
            >
              <PrevIcon className="pointer-events-none" />
            </button>
          )}

          {!(swiper?.isEnd || slideConfig?.isEnd) && (
            <button
              className={styles.next}
              onClick={() => swiper?.slideNext()}
              id="next-slider-button"
            >
              <NextIcon className="pointer-events-none" />
            </button>
          )}
        </Swiper>
      </div>
      {selectedModal && (
        <div className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[53]" />
      )}
    </>
  );
};

export default TemplateModal;
