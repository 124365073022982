import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { LIMIT_DATE } from 'core/constants/readonly';

dayjs.extend(utc);

export const getReadonlyStatus = (createdAt: string): boolean => {
  const isBefore = dayjs.utc(createdAt).isBefore(dayjs.utc(LIMIT_DATE));

  return isBefore;
};
