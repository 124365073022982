export const GET_INVITATIONS_REQUEST = 'GET_INVITATIONS_REQUEST';
export const GET_INVITATIONS_SUCCESS = 'GET_INVITATIONS_SUCCESS';
export const GET_INVITATIONS_FAILURE = 'GET_INVITATIONS_FAILURE';

export const CREATE_INVITATION_REQUEST = 'CREATE_INVITATION_REQUEST';
export const CREATE_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS';
export const CREATE_INVITATION_FAILURE = 'CREATE_INVITATION_FAILURE';

export const DELETE_INVITATION_REQUEST = 'DELETE_INVITATION_REQUEST';
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';
export const DELETE_INVITATION_FAILURE = 'DELETE_INVITATION_FAILURE';

export const EDIT_INVITATION_REQUEST = 'EDIT_INVITATION_REQUEST';
export const EDIT_INVITATION_SUCCESS = 'EDIT_INVITATION_SUCCESS';
export const EDIT_INVITATION_FAILURE = 'EDIT_INVITATION_FAILURE';

export const CHANGE_INVITATION_ROLE_REQUEST = 'CHANGE_INVITATION_ROLE_REQUEST';
export const CHANGE_INVITATION_ROLE_SUCCESS = 'CHANGE_INVITATION_ROLE_SUCCESS';
export const CHANGE_INVITATION_ROLE_FAILURE = 'CHANGE_INVITATION_ROLE_FAILURE';

export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

export const DELETE_ACCOUNT_USER_REQUEST = 'DELETE_ACCOUNT_USER_REQUEST';
export const DELETE_ACCOUNT_USER_SUCCESS = 'DELETE_ACCOUNT_USER_SUCCESS';
export const DELETE_ACCOUNT_USER_FAILURE = 'DELETE_ACCOUNT_USER_FAILURE';

export const GET_ACCOUNTS_USERS_REQUEST = 'GET_ACCOUNTS_USERS_REQUEST';
export const GET_ACCOUNTS_USERS_SUCCESS = 'GET_ACCOUNTS_USERS_SUCCESS';
export const GET_ACCOUNTS_USERS_FAILURE = 'GET_ACCOUNTS_USERS_FAILURE';

export const CHANGE_ACCOUNT_REQUEST = 'CHANGE_ACCOUNT_REQUEST';
export const CHANGE_ACCOUNT_SUCCESS = 'CHANGE_ACCOUNT_SUCCESS';
export const CHANGE_ACCOUNT_FAILURE = 'CHANGE_ACCOUNT_FAILURE';

export const CHANGE_ACCOUNT_USER_ROLE_REQUEST =
  'CHANGE_ACCOUNT_USER_ROLE_REQUEST';
export const CHANGE_ACCOUNT_USER_ROLE_SUCCESS =
  'CHANGE_ACCOUNT_USER_ROLE_SUCCESS';
export const CHANGE_ACCOUNT_USER_ROLE_FAILURE =
  'CHANGE_ACCOUNT_USER_ROLE_FAILURE';

export const CHANGE_ACCOUNT_USER_STATUS_REQUEST =
  'CHANGE_ACCOUNT_USER_STATUS_REQUEST';
export const CHANGE_ACCOUNT_USER_STATUS_SUCCESS =
  'CHANGE_ACCOUNT_USER_STATUS_SUCCESS';
export const CHANGE_ACCOUNT_USER_STATUS_FAILURE =
  'CHANGE_ACCOUNT_USER_STATUS_FAILURE';

export const GET_PAGE_VIEW_REQUEST = 'GET_PAGE_VIEW_REQUEST';
export const GET_PAGE_VIEW_SUCCESS = 'GET_PAGE_VIEW_SUCCESS';
export const GET_PAGE_VIEW_FAILURE = 'GET_PAGE_VIEW_FAILURE';
