import {
  applyMiddleware,
  legacy_createStore as createStore,
  Middleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';

import rootReducers from '@connectors/reducers';
import sagas from '@connectors/sagas';
import config from '@utils/config';

const bindMiddleware = (middlewares: Middleware[]) => {
  if (config.isDev) {
    const composeEnhancers = composeWithDevTools({
      trace: true,
    });
    return composeEnhancers(applyMiddleware(...middlewares));
  }

  return applyMiddleware(...middlewares);
};
export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducers, bindMiddleware([sagaMiddleware]));

  sagaMiddleware.run(sagas);

  return store;
};
export const store = makeStore();
export const wrapper = createWrapper(() => store);
