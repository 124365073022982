import React, { FC, useEffect, useMemo, useState } from 'react';

import Button from '@elements/Button';
import cn from 'classnames';

import styles from './TemplateDetailsModal.module.scss';

import DesktopIcon from '@assets/icons/device-based-desktop.svg';
import DesktopDisabledIcon from '@assets/icons/device-based-desktop-disabled.svg';
import MobileIcon from '@assets/icons/device-based-mobile.svg';
import MobileDisabledIcon from '@assets/icons/device-based-mobile-disabled.svg';
import TargetingSummary from '@components/Targeting/TargetingSummary';
import PreviewLite from '@components/PreviewLite';
import DeviceTypes from '@enums/DeviceTypes';
import { Template } from '@connectors/templates/types';
import uniq from 'lodash/uniq';
import { getAllFonts } from '@utils/builder';

import Skeleton from 'react-loading-skeleton';
import { SkeletonRight } from './Skeleton';

import ThemeList from '@components/StyleSidebar/ThemeList';
import { useTemplateByIdLazyQuery } from 'src/generated/graphql';

import { Layout, LayoutStyle, Targets } from '@connectors/builder/campaign';
import { layoutTypes } from '@components/TemplateSelection/TemplateSelection';

import isEqual from 'lodash/isEqual';
import ThemeSelect from '@components/ThemeSelect/ThemeSelect';
import { sendAnalytics } from '@utils/analytics';
import StepTypes from '@enums/StepTypes';
import { TemplateDetailsModalProps } from './types';
import store from '@utils/store';

const TemplateDetailsModal: FC<TemplateDetailsModalProps> = ({
  isCenterItem = false,
  renderPreview = false,
  updateStyle = false,
  templateId,
  isShowcase,
  handleTemplateOperations,
}) => {
  const [getTemplate, { data, loading: dataLoading }] =
    useTemplateByIdLazyQuery({
      onCompleted: (data) => {
        const templateData = data?.templateById;
        let layout: Layout = templateData?.layout
          ? JSON.parse(templateData?.layout)
          : undefined;

        const haveTeaser = layout?.contents[0].type === StepTypes.TEASER;

        setActivePageId(layout?.contents?.[haveTeaser ? 1 : 0]?.id);
        setCurrentTheme(layout.style);
      },
    });

  const template: Template = {
    ...data?.templateById,
    id: data?.templateById?.id!,
    goals: data?.templateById?.goals ?? [],
    tags: data?.templateById?.tags ?? [],
    subTags: data?.templateById?.subTags ?? [],
    layout: data?.templateById?.layout
      ? JSON.parse(data?.templateById?.layout)
      : undefined,
    targets: data?.templateById?.targets
      ? JSON.parse(data?.templateById?.targets)
      : undefined,
  };

  const layout: Layout | undefined = template.layout;

  const targets: Targets | undefined = template.targets;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activePageId, setActivePageId] = useState<string | undefined>(
    layout?.contents?.[0]?.type === StepTypes.TEASER
      ? layout?.contents?.[1]?.id
      : layout?.contents?.[0]?.id,
  );
  const [activeDevice, setActiveDevice] = useState<DeviceTypes>(
    DeviceTypes.desktop,
  );
  const [currentTheme, setCurrentTheme] = useState<LayoutStyle | undefined>(
    layout?.style,
  );
  const [previewTheme, setPreviewTheme] = useState<LayoutStyle | undefined>(
    undefined,
  );
  const [lastPreview, setLastPreview] = useState<string | null>(null);
  const [isAnyThemeSelected, setIsAnyThemeSelected] = useState<boolean>(false);

  const loading = dataLoading || currentTheme === undefined;

  useEffect(() => {
    if (templateId && templateId !== data?.templateById.id) {
      getTemplate({
        variables: {
          id: templateId,
        },
      });
    }
  }, [templateId, data]);

  const activePageIndex: number = useMemo(() => {
    return layout?.contents.findIndex((item) => item.id === activePageId) || 0;
  }, [activePageId, layout]);

  const layoutType =
    layoutTypes.find((layoutItem) => layoutItem.id === template?.layoutType)
      ?.name || 'light';

  const currentPage = layout?.contents?.[activePageIndex];
  const popupData = currentPage?.content?.[activeDevice];
  const appearance = currentPage?.appearance;
  const templatesPageType = currentPage?.type;

  const pages = layout?.contents.map((content) => ({
    id: content.id,
    title: content.title,
    type: content.type,
    visibility: content.appearance.visibility,
  }));

  const fonts: string[] = uniq(getAllFonts(layout, []).fonts);

  const themeSelectorSelectedTheme = useMemo(() => {
    if (currentTheme) {
      const theme = ThemeList.find((x) => x.code === currentTheme.code);
      if (theme) {
        return theme;
      }
    }

    return ThemeList[0];
  }, [currentTheme]);

  const onClickDeviceType = (deviceType: DeviceTypes) => {
    setActiveDevice(deviceType);
  };

  const setTemplatesActivePage = (id: string) => {
    setActivePageId(id);
  };

  const setIsLoadingFalse = () => {
    setIsLoading(false);
  };
  const handleClone = () => {
    const user = !!store.get('user');

    if (isShowcase && !!user) {
      setIsLoading(true);
    }

    if (data?.templateById) {
      handleTemplateOperations &&
        handleTemplateOperations(
          data?.templateById,
          isShowcase,
          user,
          isAnyThemeSelected ? currentTheme : undefined,
          true,
          setIsLoadingFalse,
        );
    }
  };

  const handleResetTheme = () => {
    if (layout?.style) {
      setCurrentTheme(layout?.style);
      setIsAnyThemeSelected(false);
      setPreviewTheme(undefined);
    }
  };

  const clearPreview = () => {
    setPreviewTheme(undefined);
  };

  const handlePreviewTheme = (code: string) => {
    const theme = ThemeList.find((x) => x.code === code);
    if (theme) {
      setPreviewTheme(theme);
      setLastPreview(code);
    }
  };

  const handleApplyTheme = () => {
    const theme = ThemeList.find((x) => x.code === lastPreview);
    if (theme) {
      setCurrentTheme(theme);
      setIsAnyThemeSelected(true);
      setPreviewTheme(undefined);
    }
  };

  const activeTheme = previewTheme ? previewTheme : currentTheme;

  const handleSelectChoosedTheme = () => {
    if (template?.id) {
      sendAnalytics('click', {
        element: 'template_selection_choosed_theme',
        templateId: template.id,
      });
    }
    handleClone();
  };

  return (
    <div className={cn(styles.container, 'scrollbar')}>
      <div className={styles.left}>
        {loading ? (
          <>
            <div className="block sm:hidden">
              <Skeleton
                width="100%"
                height={24}
                className="!rounded-lg "
                containerClassName="block w-full max-h-[24px] mb-4"
                enableAnimation
              />

              <Skeleton
                width={86}
                height={36}
                className="!rounded-[10px]   mb-6"
                containerClassName="block h-[24px] mb-[24px]"
                enableAnimation
              />
            </div>

            <Skeleton
              width="100%"
              className="!block !rounded-[18px] h-[200px] sm:h-[350px] lg:h-[561px]"
              containerClassName="block w-full max-h-[561px]"
              enableAnimation
            />
          </>
        ) : (
          <>
            {template?.name && (
              <h3 className={cn(styles.title, 'block sm:hidden')}>
                {template?.name}
              </h3>
            )}
            <div className="grid grid-cols-[auto_1fr] items-center justify-center gap-4 mb-6 sm:hidden">
              <div className="flex items-center justify-center bg-[#F5F5F5] rounded-[10px] p-[3px] h-fit">
                <button
                  className={cn(
                    'px-[10px] py-[6px]  rounded-lg',
                    activeDevice === DeviceTypes.desktop
                      ? 'bg-white'
                      : 'text-[#999999]',
                    template?.deviceType === 2 && 'cursor-not-allowed ',
                  )}
                  onClick={() => onClickDeviceType(DeviceTypes.desktop)}
                  disabled={template?.deviceType === 2}
                >
                  {template?.deviceType === 0 || template?.deviceType === 1 ? (
                    <DesktopIcon />
                  ) : (
                    <DesktopDisabledIcon />
                  )}
                </button>
                <button
                  className={cn(
                    'px-[10px] py-[6px] ml-1 rounded-lg',
                    activeDevice === DeviceTypes.mobile
                      ? 'bg-white'
                      : 'text-[#999999]',
                    template?.deviceType === 1 && 'cursor-not-allowed ',
                  )}
                  onClick={() => onClickDeviceType(DeviceTypes.mobile)}
                  disabled={template?.deviceType === 1}
                >
                  {template?.deviceType === 0 || template?.deviceType === 2 ? (
                    <MobileIcon />
                  ) : (
                    <MobileDisabledIcon />
                  )}
                </button>
              </div>
            </div>
            <div
              className={cn(styles.preview, 'scrollbar', {
                [styles.isFullScreenPageType]: layoutType === 'fullscreen',
              })}
            >
              {!loading && renderPreview ? (
                <PreviewLite
                  className={cn(
                    'w-full',
                    layoutType !== 'light' && 'h-full',
                    activeDevice === DeviceTypes.mobile
                      ? 'max-w-[358px] text-[9px]'
                      : 'text-[12px]',
                    styles.previewLite,
                    {
                      [styles.previewLiteMobile]:
                        activeDevice === DeviceTypes.mobile,
                    },
                  )}
                  popupData={popupData}
                  themeData={updateStyle ? activeTheme : undefined}
                  pageType={templatesPageType}
                  fonts={fonts}
                  appearance={appearance}
                  currentDevice={activeDevice}
                  currentPageId={activePageId}
                />
              ) : (
                <Skeleton
                  width="100%"
                  height={561}
                  className="!rounded-md"
                  containerClassName="!max-h-[561px] !w-full"
                  enableAnimation
                />
              )}
            </div>
          </>
        )}

        <div className="flex items-center justify-center mt-4 w-[85vw] sm:w-full">
          {loading ? (
            <Skeleton
              width={90}
              height={36}
              className="!rounded-md"
              containerClassName=" max-h-[36px]"
              enableAnimation
            />
          ) : (
            <>
              {pages && !!pages.length
                ? pages
                    .filter(
                      (page) =>
                        page.type !== StepTypes.TEASER ||
                        (page.type === StepTypes.TEASER &&
                          page.visibility === true),
                    )
                    .map((page) => {
                      return (
                        <button
                          key={page.id}
                          className={cn(
                            'px-3 py-2 mx-1 text-xs leading-[18px] font-medium rounded-md border',
                            activePageId === page.id
                              ? 'border-[#1C86FA] bg-[#EAF4FF]'
                              : 'border-[#DDDDDD]',
                            {
                              '!px-[2px] !mx-[3px] !text-[8px]':
                                pages?.length > 4,
                            },
                          )}
                          onClick={() => {
                            setTemplatesActivePage(page.id);
                          }}
                        >
                          {page.title}
                        </button>
                      );
                    })
                : null}
            </>
          )}
        </div>
      </div>
      <div className={cn(styles.right, 'scrollbar')}>
        {loading ? (
          <SkeletonRight />
        ) : (
          <>
            {template?.name && (
              <h3 className={cn(styles.title, 'hidden sm:block')}>
                {template?.name}
              </h3>
            )}

            <div className="sm:grid grid-cols-[auto_1fr] items-center justify-center gap-4 mb-6 hidden">
              <div className="flex items-center justify-center bg-[#F5F5F5] rounded-[10px] p-[3px] h-fit">
                <button
                  className={cn(
                    'px-[10px] py-[6px]  rounded-lg',
                    activeDevice === DeviceTypes.desktop
                      ? 'bg-white'
                      : 'text-[#999999]',
                    template?.deviceType === 2 && 'cursor-not-allowed ',
                  )}
                  onClick={() => onClickDeviceType(DeviceTypes.desktop)}
                  disabled={template?.deviceType === 2}
                >
                  {template?.deviceType === 0 || template?.deviceType === 1 ? (
                    <DesktopIcon />
                  ) : (
                    <DesktopDisabledIcon />
                  )}
                </button>
                <button
                  className={cn(
                    'px-[10px] py-[6px] ml-1 rounded-lg',
                    activeDevice === DeviceTypes.mobile
                      ? 'bg-white'
                      : 'text-[#999999]',
                    template?.deviceType === 1 && 'cursor-not-allowed ',
                  )}
                  onClick={() => onClickDeviceType(DeviceTypes.mobile)}
                  disabled={template?.deviceType === 1}
                >
                  {template?.deviceType === 0 || template?.deviceType === 2 ? (
                    <MobileIcon />
                  ) : (
                    <MobileDisabledIcon />
                  )}
                </button>
              </div>
            </div>

            {template?.title && (
              <h4 className="font-inter text-base leading-6 font-semibold mb-[10px]">
                {template?.title}
              </h4>
            )}
            {template?.description && (
              <p className="font-inter text-xs leading-4 mb-8">
                {template?.description}
              </p>
            )}

            {isCenterItem ? (
              <>
                <ThemeSelect
                  onResetTheme={handleResetTheme}
                  onSelectTheme={handlePreviewTheme}
                  onApplyTheme={handleApplyTheme}
                  onClose={clearPreview}
                  onChangeClick={clearPreview}
                  selectedTheme={themeSelectorSelectedTheme}
                  isThemeChanged={!isEqual(layout?.style, currentTheme)}
                  menuClassName="!h-[288px]"
                  modalClassName="!w-full"
                />

                <Button
                  onClick={handleSelectChoosedTheme}
                  useIndicator={isLoading}
                  className="btn btn-primary w-full !px-[25px] !py-[9px] !rounded-md !text-sm !leading-[18px] mb-1 mt-6 h-[38px]"
                  id={`select-template-${templateId}`}
                >
                  Select Popup
                </Button>
              </>
            ) : null}

            {targets && (
              <>
                <TargetingSummary targets={targets} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export type { TemplateDetailsModalProps };
export default TemplateDetailsModal;
