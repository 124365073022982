import { ReduxAction } from 'global';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  GET_WEBSITES_REQUEST,
  GET_WEBSITES_SUCCESS,
  GET_WEBSITES_FAILURE,
  ADD_WEBSITE_REQUEST,
  ADD_WEBSITE_SUCCESS,
  ADD_WEBSITE_FAILURE,
  DELETE_WEBSITE_REQUEST,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  UPDATE_WEBSITE_REQUEST,
  UPDATE_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_FAILURE,
  GET_CMS_REQUEST,
  GET_CMS_SUCCESS,
  GET_CMS_FAILURE,
} from './constants';

import fetch from '@utils/fetch';
import graphqlFetch from '@utils/graphqlFetch';
import { Website } from './reducer';
import { WebsiteItem } from 'api-core/modules/websites/resolvers/WebsiteResolver.types';

export interface CMSResponse {
  name: string | null;
}

function* getWebsitesSaga({ payload }: ReduxAction) {
  try {
    const graphqlQuery = {
      operationName: 'Websites',
      query: `query Websites {
        websites {
          data {
            id
            url
            cms
            verified
            campaignCount
            isShopify
          }
          total
        }
      }`,
    };
    const response: {
      data: {
        websites: {
          data: Website[];
          total: number;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    const websites = response.data.websites.data;

    yield put({
      type: GET_WEBSITES_SUCCESS,
      payload: websites,
    });
    payload?.onSuccess(websites);
  } catch (err) {
    yield put({
      type: GET_WEBSITES_FAILURE,
    });
    payload?.onError();
  }
}

function* addWebsiteSaga({ payload }: ReduxAction) {
  try {
    const data: Website = yield call(fetch, `api/Domain/`, {
      method: 'POST',
      data: {
        url: payload,
      },
    });

    if (data && data.url) {
      yield put({ type: ADD_WEBSITE_SUCCESS, payload: data });
      return;
    }

    throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: ADD_WEBSITE_FAILURE });
  }
}

function* deleteWebsiteSaga({ payload }: ReduxAction) {
  const id = payload;
  try {
    yield call(fetch, `api/Domain/${id}`, {
      method: 'DELETE',
    });

    //yield call doesn't return any data, so i'll use the id
    yield put({ type: DELETE_WEBSITE_SUCCESS, payload: id });
  } catch (error) {
    yield put({ type: DELETE_WEBSITE_FAILURE });
  }
}

function* updateWebsiteSaga({ payload }: ReduxAction) {
  try {
    //Get the websites state and update it in the saga
    let websites: Website[] = yield select((state) => state.websites.items);
    websites = websites.map((website: Website) => {
      if (website.id === payload.id) {
        website.url = payload.url;
        //When the url of the website changed, verification should be false
        website.verified = false;
      }
      return website;
    });

    const graphqlQuery = {
      operationName: 'UpdateWebsite',
      query: `mutation UpdateWebsite($website: String!, $websiteId: Float!) {
                updateWebsite(website: $website, websiteId: $websiteId) {
                  id
                  url
                  cms
                  verified
                  campaignCount
                }}`,
      variables: {
        website: payload.url,
        websiteId: payload.id,
      },
    };

    const result: {
      data: {
        updateWebsite: WebsiteItem;
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    if (payload?.withGetCMS) {
      yield put({
        type: GET_CMS_SUCCESS,
        payload: result.data.updateWebsite.cms,
      });
    }

    yield put({ type: UPDATE_WEBSITE_SUCCESS, payload: websites });
  } catch (error) {
    yield put({ type: UPDATE_WEBSITE_FAILURE });
  }
}

function* getCMSSaga({ payload }: ReduxAction) {
  const domain = payload;

  try {
    const data: CMSResponse = yield call(
      fetch,
      `api/DetectCms?domain=${domain}`,
      {
        method: 'GET',
      },
    );

    if (data) {
      yield put({ type: GET_CMS_SUCCESS, payload: data.name });
      return;
    }

    throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: GET_CMS_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(GET_WEBSITES_REQUEST, getWebsitesSaga);
  yield takeLatest(ADD_WEBSITE_REQUEST, addWebsiteSaga);
  yield takeLatest(DELETE_WEBSITE_REQUEST, deleteWebsiteSaga);
  yield takeLatest(UPDATE_WEBSITE_REQUEST, updateWebsiteSaga);
  yield takeLatest(GET_CMS_REQUEST, getCMSSaga);
}
