import LinkButton from '@components/LinkButton';
import { useTranslation } from 'react-i18next';

export const ABTestingHowItsWork = () => {
  const { t } = useTranslation();
  return (
    <div className="p-5">
      <div className=" flex flex-col gap-7 ml-1">
        <p>{t('sections:ab-testing-how-its-work:listItemOne')}</p>
        <p>{t('sections:ab-testing-how-its-work:listItemTwo')}</p>
        <p>{t('sections:ab-testing-how-its-work:listItemThree')}</p>
      </div>
      <LinkButton
        className="mt-6"
        text={t('sections:ab-testing-how-its-work:documentationLinkText')}
        link="https://popupsmart.com/help/detail/how-to-create-an-a-b-testing-for-a-popup"
      />
    </div>
  );
};
