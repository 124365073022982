import React, { useRef, useEffect, FC } from 'react';
import { PsLottie, PsLottieRef } from 'ui';

import styles from './PublishErrorModal.module.scss';
import warningAnimation from '@assets/lotties/warning.json';
import { PublishErrorModalProps } from './types';
import PSButton from '@elements/PSButton';

const PublishErrorModal: FC<PublishErrorModalProps> = ({
  onClickTryAgain,
  onClickCancel,
}) => {
  const confettiRef = useRef() as PsLottieRef;
  const badgeRef = useRef() as PsLottieRef;

  useEffect(() => {
    confettiRef?.current?.play();
    badgeRef?.current?.play();

    return () => {
      confettiRef?.current?.destroy();
      badgeRef?.current?.destroy();
    };
  }, []);

  return (
    <div className={styles.container}>
      <PsLottie
        height={262}
        width={247}
        style={{
          width: 262,
          height: 247,
        }}
        loop={true}
        autoplay={false}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMin slice',
        }}
        animationData={warningAnimation}
        lottieRef={badgeRef}
      />
      <h1 className="text-xl font-semibold text-gray-900 lg:text-4xl dark:text-white">
        Something went wrong
      </h1>
      <div className="pt-6 pb-10">
        <p>You can try again or contact</p>
        <p>our Support Team for help.</p>
      </div>

      <div className="flex justify-center gap-2">
        <PSButton type="button" onClick={onClickCancel} variant="second">
          Cancel
        </PSButton>
        <PSButton type="button" onClick={onClickTryAgain}>
          Try Again
        </PSButton>
      </div>
    </div>
  );
};

export default PublishErrorModal;
