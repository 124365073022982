export const GET_AUTH_USER = 'GET_AUTH_USER';
export const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAILURE = 'GET_AUTH_USER_FAILURE';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_USER_APOLLO = 'SET_AUTH_USER_APOLLO';
export const SET_AUTH_USER_SUCCESS = 'SET_AUTH_USER_SUCCESS';
export const SET_AUTH_USER_FAILURE = 'SET_AUTH_USER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_WITH_GOOGLE_REQUEST = 'LOGIN_WITH_GOOGLE_REQUEST';

export const USER_LOGOUT = 'USER_LOGOUT';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const REGISTER_WITH_INVITE_REQUEST = 'REGISTER_WITH_INVITE_REQUEST';
export const REGISTER_WITH_INVITE_SUCCESS = 'REGISTER_WITH_INVITE_SUCCESS';
export const REGISTER_WITH_INVITE_FAILURE = 'REGISTER_WITH_INVITE_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const EDIT_EMAIL_REQUEST = 'EDIT_EMAIL_REQUEST';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_FAILURE = 'EDIT_EMAIL_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const DELETE_SURVEY_REQUEST = 'DELETE_SURVEY_REQUEST';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_FAILURE = 'DELETE_SURVEY_FAILURE';

export const ADD_TOKEN_MANUALLY_REQUEST = 'ADD_TOKEN_MANUALLY_REQUEST';
export const ADD_TOKEN_MANUALLY_SUCCESS = 'ADD_TOKEN_MANUALLY_SUCCESS';
export const ADD_TOKEN_MANUALLY_FAILURE = 'ADD_TOKEN_MANUALLY_FAILURE';

export const LOGIN_BACKOFFICE_REQUEST = 'LOGIN_BACKOFFICE_REQUEST';

export const CHANGE_SUBACCOUNT_SEEN_REQUEST = 'CHANGE_SUBACCOUNT_SEEN_REQUEST';
export const CHANGE_SUBACCOUNT_SEEN_SUCCESS = 'CHANGE_SUBACCOUNT_SEEN_SUCCESS';
export const CHANGE_SUBACCOUNT_SEEN_FAILURE = 'CHANGE_SUBACCOUNT_SEEN_FAILURE';
