import React, { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

const SkeletonRight: FC = ({}) => {
  return (
    <div>
      <Skeleton
        width="100%"
        height={24}
        className="!rounded-lg !hidden sm:!block"
        containerClassName="block w-full max-h-[24px] mb-4"
        enableAnimation
      />

      <Skeleton
        width={86}
        height={36}
        className="!rounded-[10px]  !hidden sm:!block"
        containerClassName="block h-[24px] mb-[24px]"
        enableAnimation
      />

      <Skeleton
        width="100%"
        height={46.8}
        className="!block !rounded-xl"
        containerClassName="block w-full h-[46.8px] mb-[24px]"
        enableAnimation
      />

      <Skeleton
        width="100%"
        height={24}
        className="!block !rounded-lg"
        containerClassName="block w-full h-[24px] mb-[10px]"
        enableAnimation
      />

      <Skeleton
        width="100%"
        height={24}
        className="!block !rounded-lg"
        containerClassName="block w-full h-[24px] mb-[20px]"
        enableAnimation
      />

      <Skeleton
        width="60%"
        height={124}
        className="!block !rounded-xl"
        containerClassName="block w-full h-[124px] mb-[20px]"
        enableAnimation
      />

      <Skeleton
        width="60%"
        height={68}
        className="!block !rounded-xl"
        containerClassName="block w-full h-[68px] mb-[20px]"
        enableAnimation
      />
    </div>
  );
};

export { SkeletonRight };
