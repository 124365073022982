import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { schema, typesMap } from './schema';
import { authLink, httpLink } from './links/httpLink';
import refreshLink from './links/refreshLink';

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    withScalars({ schema, typesMap }),
    refreshLink,
    authLink.concat(httpLink),
  ]),
  cache: new InMemoryCache(),
});

export default apolloClient;
