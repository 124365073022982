export const DEVICES_ANALYTICS_GET = 'DEVICES_ANALYTICS_GET';
export const DEVICES_ANALYTICS_GET_SUCCESS = 'DEVICES_ANALYTICS_GET_SUCCESS';

export const RESOLUTIONS_ANALYTICS_GET = 'RESOLUTIONS_ANALYTICS_GET';
export const RESOLUTIONS_ANALYTICS_GET_SUCCESS =
  'RESOLUTIONS_ANALYTICS_GET_SUCCESS';

export const ANALYTICS_GET_FAILURE = 'ANALYTICS_GET_FAILURE';

export const BROWSERS_ANALYTICS_GET = 'BROWSERS_ANALYTICS_GET';
export const BROWSERS_ANALYTICS_GET_SUCCESS = 'BROWSERS_ANALYTICS_GET_SUCCESS';

export const CAMPAIGNS_FOR_ANALYTICS_GET = 'CAMPAIGNS_FOR_ANALYTICS_GET';
export const CAMPAIGNS_FOR_ANALYTICS_GET_SUCCESS =
  'CAMPAIGNS_FOR_ANALYTICS_GET_SUCCESS';

export const CLEAR_ANALYTICS_CAMPAIGN = 'CLEAR_ANALYTICS_CAMPAIGN';
export const CLEAR_ANALYTICS_CAMPAIGN_SUCCESS =
  'CLEAR_ANALYTICS_CAMPAIGN_SUCCESS';
