import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  MAILERLITE_GET_FIELDS,
  MAILERLITE_GET_FIELDS_SUCCESS,
  MAILERLITE_GET_FIELDS_FAILURE,
  MAILERLITE_GET_CONNECTIONS,
  MAILERLITE_GET_CONNECTIONS_SUCCESS,
  MAILERLITE_GET_CONNECTIONS_FAILURE,
  MAILERLITE_GET_LIST,
  MAILERLITE_GET_LIST_SUCCESS,
  MAILERLITE_GET_LIST_FAILURE,
  MAILERLITE_SAVE,
  MAILERLITE_SAVE_SUCCESS,
  MAILERLITE_SAVE_FAILURE,
  MAILERLITE_DELETE,
  MAILERLITE_DELETE_FAILURE,
  MAILERLITE_CLEAR_STATE,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import IntegrationEnums from '@enums/IntegrationEnums';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import {
  CreateMailerLite,
  DeleteMailerLiteIntegrationCampaign,
  GetMailerLiteFields,
  GetMailerLiteIntegrationsByAccountId,
  GetMailerLiteIntegrationsByCampaignId,
  GetMailerLiteLists,
} from './graphql';
import graphqlFetch from '@utils/graphqlFetch';
import { MailerLiteIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/MailerLiteResolver.types';
import { UpdateMailerLite } from '../mailerlite/graphql';

function* handleGetConnections() {
  try {
    const result: {
      data: {
        getMailerLiteIntegrationsByAccountId: ApiConnectionObject[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailerLiteIntegrationsByAccountId(),
    });

    yield put({
      type: MAILERLITE_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getMailerLiteIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: MAILERLITE_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;
  try {
    const result: {
      data: { getMailerLiteFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailerLiteFields(apiKey),
    });
    onSuccess && onSuccess();
    yield put({
      type: MAILERLITE_GET_FIELDS_SUCCESS,
      payload: result.data.getMailerLiteFields,
    });
  } catch (error) {
    yield put({ type: MAILERLITE_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { onSuccess } = action.payload;
  try {
    const result: {
      data: { getMailerLiteLists: ApiListObject };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailerLiteLists(action.payload.apiKey),
    });
    onSuccess && onSuccess();
    yield put({
      type: MAILERLITE_GET_LIST_SUCCESS,
      payload: result.data.getMailerLiteLists,
    });
  } catch (error) {
    yield put({ type: MAILERLITE_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateMailerLite(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateMailerLite({ ...formData, status: true }),
      });
    }
    const integrations: {
      data: {
        getMailerLiteIntegrationsByCampaignId: MailerLiteIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetMailerLiteIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getMailerLiteIntegrationsByCampaignId,
        type: IntegrationEnums.mailerlite,
      }),
    );
    yield put({ type: MAILERLITE_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: MAILERLITE_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteMailerLiteIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.mailerlite,
      }),
    );
    yield put({ type: MAILERLITE_CLEAR_STATE });
  } catch (error) {
    yield put({ type: MAILERLITE_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(MAILERLITE_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(MAILERLITE_GET_FIELDS, handleGetFields);
  yield takeLatest(MAILERLITE_GET_LIST, handleGetList);
  yield takeLatest(MAILERLITE_SAVE, handleSave);
  yield takeLatest(MAILERLITE_DELETE, handleDelete);
}
