import cn from 'classnames';
import { FC, useState } from 'react';

export const DynamicTabIconLabel: FC<{
  icon: JSX.Element;
  text: string;
  tabId?: string;
  activeTabId?: string;
  activeClassName?: string;
  activeIcon?: JSX.Element;
  isSmall?: boolean;
}> = ({
  icon,
  text,
  tabId,
  activeTabId,
  activeClassName,
  activeIcon,
  isSmall,
}) => {
  const [isHoverIconShow, setIsHoverIconShow] = useState(false);

  return (
    <div
      className={cn(
        'flex items-center justify-center gap-[5px] py-[10px] px-[12.5px]',
        {
          [`${activeClassName}`]: tabId === activeTabId,
          [`!pt-0 !pr-[5px] !pb-[10px] !pl-[5px]`]: isSmall,
        },
      )}
      onMouseEnter={() => setIsHoverIconShow(true)}
      onMouseLeave={() => setIsHoverIconShow(false)}
    >
      {(activeIcon && tabId === activeTabId) || isHoverIconShow
        ? activeIcon
        : icon}
      <label className="cursor-pointer">{text}</label>
    </div>
  );
};
