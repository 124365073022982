import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  WEBHOOK_GET_CONNECTIONS,
  WEBHOOK_GET_CONNECTIONS_SUCCESS,
  WEBHOOK_GET_CONNECTIONS_FAILURE,
  WEBHOOK_SAVE,
  WEBHOOK_SAVE_SUCCESS,
  WEBHOOK_SAVE_FAILURE,
  WEBHOOK_DELETE,
  WEBHOOK_DELETE_FAILURE,
  WEBHOOK_CLEAR_STATE,
} from './constants';
import { ApiConnectionObject } from '../reducers';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import {
  CreateWebhookIntegration,
  DeleteWebhookIntegration,
  GetWebhookIntegrationsByAccountId,
  GetWebhookIntegrationsByCampaignId,
  UpdateWebhookIntegration,
} from './graphql';
import graphqlFetch from '@utils/graphqlFetch';
import { WebhookIntegration } from '@generated/graphql';

function* handleGetConnections() {
  try {
    const result: {
      data: { getWebhookIntegrationsByAccountId: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetWebhookIntegrationsByAccountId(),
    });

    yield put({
      type: WEBHOOK_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getWebhookIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: WEBHOOK_GET_CONNECTIONS_FAILURE });
  }
}

function* handleSave(action: ReduxAction) {
  const { connectionSettings, isEdit, id, campaignId } = action.payload;
  try {
    if (isEdit) {
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateWebhookIntegration(Number(id), {
          campaignId,
          name: connectionSettings.name,
          url: connectionSettings.url,
        }),
      });
    } else {
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateWebhookIntegration({
          campaignId,
          name: connectionSettings.name,
          url: connectionSettings.url,
        }),
      });
    }
    const integrations: {
      data: {
        getWebhookIntegrationsByCampaignId: WebhookIntegration[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetWebhookIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getWebhookIntegrationsByCampaignId,
        type: IntegrationEnums.webhook,
      }),
    );
    yield put({ type: WEBHOOK_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: WEBHOOK_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteWebhookIntegration(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.webhook,
      }),
    );
    yield put({ type: WEBHOOK_CLEAR_STATE });
  } catch (error) {
    yield put({ type: WEBHOOK_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(WEBHOOK_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(WEBHOOK_SAVE, handleSave);
  yield takeLatest(WEBHOOK_DELETE, handleDelete);
}
