import React, { FC } from 'react';
import cn from 'classnames';

import styles from './SelectPosition.module.scss';
import { PositionItem, SelectPositionProps } from './types';
import { positions } from './constants';

const SelectPosition: FC<SelectPositionProps> = (props) => {
  const {
    value,
    onChange,
    floatingBar = false,
    small = false,
    medium = false,
    large = false,
    disabledArray,
    withIcon = true,
    imagePosition = false,
  } = props;

  const handleChange = (positionItem: PositionItem, index: number) => {
    if (!disabledArray?.includes(index) && positionItem.value !== value) {
      onChange(positionItem);
    }
  };

  return (
    <div
      className={cn(
        styles.grid,
        large && styles.large,
        medium && styles.medium,
        small && styles.small,
        { [styles.imagePosition]: imagePosition },
      )}
    >
      {positions &&
        positions.map((item, index) => {
          return (
            <button
              key={item.value}
              className={cn(
                styles.item,
                item.value === value && styles.active,
                withIcon && styles.withIcon,
                disabledArray?.includes(index) && styles.disabled,
                {
                  'rounded-tl': item.value === 'top-left',
                  'rounded-tr': item.value === 'top-right',
                  'rounded-br': item.value === 'bottom-right',
                  'rounded-bl': item.value === 'bottom-left',
                },
              )}
              onClick={() => handleChange(item, index)}
              disabled={
                floatingBar &&
                item.value !== 'top-center' &&
                item.value !== 'bottom-center'
              }
            />
          );
        })}
    </div>
  );
};

export default SelectPosition;
export type { SelectPositionProps };
