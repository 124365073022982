import {
  ActiveCampaignInput,
  ActiveCampaignIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/ActiveCampaignResolver.types';

export const GetActiveCampaignIntegrationsByCampaignId = (
  campaignId: number,
) => ({
  operationName: 'GetActiveCampaignIntegrationsByCampaignId',
  query: `query GetActiveCampaignIntegrationsByCampaignId($campaignId: Int!) {
    getActiveCampaignIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      name
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      integration {
        apiKey
        id
        accountId
        name
        status
      }
      listId
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetActiveCampaignIntegrationsByAccountId = () => ({
  operationName: 'GetActiveCampaignIntegrationsByAccountId',
  query: `query GetActiveCampaignIntegrationsByAccountId {
    getActiveCampaignIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        status
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
        integrationId
      }
      apiKey
      account
    }
  }`,
});
export const GetActiveCampaignLists = (account: string, apiKey: string) => ({
  operationName: 'GetActiveCampaignLists',
  query: `query GetActiveCampaignLists($account: String!, $apiKey: String!) {
    getActiveCampaignLists(account: $account, apiKey: $apiKey) {
      id
      name
    }
  }`,
  variables: {
    account,
    apiKey,
  },
});
export const GetActiveCampaignFields = (account: string, apiKey: string) => ({
  operationName: 'GetActiveCampaignFields',
  query: `query GetActiveCampaignFields($account: String!, $apiKey: String!) {
    getActiveCampaignFields(account: $account, apiKey: $apiKey) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    account,
    apiKey,
  },
});
export const CreateActiveCampaign = (input: ActiveCampaignInput) => ({
  operationName: 'CreateActiveCampaignIntegration',
  query: `mutation CreateActiveCampaignIntegration($input: ActiveCampaignInput!) {
    createActiveCampaignIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateActiveCampaign = (
  id: number,
  input: ActiveCampaignIntegrationCampaignInput,
) => ({
  operationName: 'UpdateActiveCampaignIntegrationCampaign',
  query: `mutation UpdateActiveCampaignIntegrationCampaign($input: ActiveCampaignIntegrationCampaignInput!, $updateActiveCampaignIntegrationCampaignId: Int!) {
    updateActiveCampaignIntegrationCampaign(input: $input, id: $updateActiveCampaignIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateActiveCampaignIntegrationCampaignId: id,
  },
});

export const DeleteActiveCampaignIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteActiveCampaignIntegrationCampaign',
  query: `mutation DeleteActiveCampaignIntegrationCampaign($deleteActiveCampaignIntegrationCampaignId: Int!) {
    deleteActiveCampaignIntegrationCampaign(id: $deleteActiveCampaignIntegrationCampaignId)
  }`,
  variables: {
    deleteActiveCampaignIntegrationCampaignId: id,
  },
});
