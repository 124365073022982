import { ThemeElement } from './types';
import Funday from '@assets/images/theme_cover/fun_day.png';
import Elegant from '@assets/images/theme_cover/elegant.png';
import Simple from '@assets/images/theme_cover/simple.png';
import Dark from '@assets/images/theme_cover/dark.png';
import LightGradient from '@assets/images/theme_cover/light_gradient.png';
import Material from '@assets/images/theme_cover/material.png';
import Polaris from '@assets/images/theme_cover/polaris.png';
import Neon from '@assets/images/theme_cover/neon.png';
import Summer from '@assets/images/theme_cover/summer.png';
import Winter from '@assets/images/theme_cover/winter.png';
import ValentinesDay from '@assets/images/theme_cover/valentines_day.png';
import { NodeType } from '@model/types';

const ThemeList: ThemeElement[] = [
  {
    name: 'Fun Day',
    fontFamily: ['Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#222222',
    image: Funday.src,
    code: 'fun_day',
    data: {
      teaser: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/fun_day_bg.png`,
        backgroundColor: '#F9BF00',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/fun_day_bg.png`,
        backgroundColor: '#F9BF00',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#000000',
        borderColor: 'transparent',
        borderRadius: '6px',
        fontColor: '#ffffff',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#000000',
        borderRadius: '6px',
        fontColor: '#000000',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#000000',
        color: '#0000008c',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#222222',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#787670',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        lineHeight: '18px',
        color: '#0000008c',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#000', textColor: '#000' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#000000',
        color: '#ffffff',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#000000',
          borderColor: 'transparent',
          borderRadius: '6px',
          fontColor: '#ffffff',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Elegant',
    fontFamily: ['Oranienbaum', 'Poppins'],
    fontColor: '#ffffff',
    fontFamilyColor: '#D1D7BF',
    image: Elegant.src,
    code: 'elegant',
    data: {
      teaser: {
        background: '',
        backgroundColor: '#153843',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: '',
        backgroundColor: '#153843',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#D1D7BF',
        borderColor: 'transparent',
        fontColor: '#153843',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#D1D7BF',
        fontColor: '#D1D7BF',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: '#244650',
        accent: '#D1D7BF',
        color: '#D1D7BF',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Oranienbaum',
        fontSize: '36px',
        fontWeight: '400',
        lineHeight: '36px',
        color: '#D1D7BF',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#D1D7BF',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#7C8F87',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#D1D7BF', textColor: '#000' },
      couponCode: {
        background: '#244650',
        color: '#D1D7BF',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#D1D7BF',
      },
      countdown: {
        borderRadius: '8px',
        background: '#D1D7BF',
        color: '#153843',
        labelColor: '#D1D7BF',
        seperatorColor: '#D1D7BF',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Inter',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#000000',
          borderColor: 'transparent',
          borderRadius: '6px',
          fontColor: '#ffffff',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Simple',
    fontFamily: ['Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#777777',
    image: Simple.src,
    code: 'simple',
    data: {
      teaser: {
        background: ``,
        backgroundColor: '#ffffff',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: ``,
        backgroundColor: '#ffffff',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#222222',
        borderColor: 'transparent',
        fontColor: '#fff',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#222222',
        fontColor: '#222222',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: '#ffffff',
        accent: '#222222',
        color: '#222222',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#222222',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#787670',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#000', textColor: '#000' },
      couponCode: {
        background: 'none',
        color: '#000',
        borderRadius: '0px',
        borderStyle: 'dashed',
        borderColor: '#000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#000000',
        color: '#ffffff',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#222222',
          borderColor: 'transparent',
          fontColor: '#fff',
          borderRadius: '6px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Dark',
    fontFamily: ['Poppins'],
    fontColor: '#ffffff',
    fontFamilyColor: '#999999',
    image: Dark.src,
    code: 'dark',
    data: {
      teaser: {
        background: ``,
        backgroundColor: '#000000',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: ``,
        backgroundColor: '#000000',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#ffffff',
        borderColor: 'transparent',
        fontColor: '#000000',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#ffffff',
        fontColor: '#ffffff',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: '#121212',
        accent: '#ffffff',
        color: '#ffffff',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#ffffff',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#999999',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#ffffff',
        color: '#000000',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#ffffff', textColor: '#ffffff' },
      couponCode: {
        background: '#0000008c',
        color: '#ffffff',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#ffffff',
      },
      countdown: {
        borderRadius: '8px',
        background: '#ffffff',
        color: '#000000',
        labelColor: '#ffffff',
        seperatorColor: '#ffffff',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#000000',
          borderColor: 'transparent',
          borderRadius: '6px',
          fontColor: '#ffffff',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Light Gradient',
    fontFamily: ['Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#000000',
    image: LightGradient.src,
    code: 'light_gradient',
    data: {
      teaser: {
        background: '',
        backgroundColor:
          'linear-gradient(221.2deg, #E5FF96 1.44%, #FFD28E 51.23%, #FFFFFF 100%)',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: '',
        backgroundColor:
          'linear-gradient(221.2deg, #E5FF96 1.44%, #FFD28E 51.23%, #FFFFFF 100%)',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#000000',
        borderColor: 'transparent',
        fontColor: '#fff',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#000000',
        fontColor: '#000000',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#000000',
        color: '#0000008c',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#000000',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#000', textColor: '#000' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#000000',
        color: '#ffffff',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Inter',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Inter',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        button: {
          backgroundColor: '#000000',
          borderColor: 'transparent',
          fontColor: '#fff',
          borderRadius: '6px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Material',
    fontFamily: ['Roboto'],
    fontColor: '#000000',
    fontFamilyColor: '#000000',
    image: Material.src,
    code: 'material',
    data: {
      teaser: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/material.png`,
        backgroundColor: '#ffffff',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/material.png`,
        backgroundColor: '#ffffff',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#0B57D0',
        borderColor: 'transparent',
        fontColor: '#ffffff',
        borderRadius: '100px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#0B57D0',
        fontColor: '#0B57D0',
        borderRadius: '100px',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#0B57D0',
        color: '#000000',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Roboto',
        fontSize: '36px',
        fontWeight: '700',
        color: '#000000',
        lineHeight: '40px',
      },
      bodyText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#0B57D0', textColor: '#0B57D0' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#0B57D0',
        color: '#ffffff',
        labelColor: '#0B57D0',
        seperatorColor: '#0B57D0',
      },
      spinToWin: {
        fontFamily: 'Roboto',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Roboto',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        button: {
          backgroundColor: '#0B57D0',
          borderColor: 'transparent',
          fontColor: '#ffffff',
          borderRadius: '100px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Polaris',
    fontFamily: ['System-ui', 'Inter'],
    fontColor: '#000000',
    fontFamilyColor: '#777777',
    image: Polaris.src,
    code: 'polaris',
    data: {
      teaser: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/polaris.png`,
        backgroundColor: '#ffffff',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/polaris.png`,
        backgroundColor: '#ffffff',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#008060',
        borderColor: 'transparent',
        fontColor: '#ffffff',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#008060',
        fontColor: '#008060',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#008060',
        color: '#000000',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'System-ui',
        fontSize: '36px',
        fontWeight: '700',
        color: '#000000',
        lineHeight: '40px',
      },
      bodyText: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#008060', textColor: '#008060' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#008060',
        color: '#000000',
        labelColor: '#008060',
        seperatorColor: '#008060',
      },
      spinToWin: {
        fontFamily: 'Inter',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Inter',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        button: {
          backgroundColor: '#008060',
          borderColor: 'transparent',
          fontColor: '#ffffff',
          borderRadius: '6px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Neon',
    fontFamily: ['Merriweather', 'Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#000000',
    image: Neon.src,
    code: 'neon',
    data: {
      teaser: {
        background: ``,
        backgroundColor: '#D9FF6F',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: ``,
        backgroundColor: '#D9FF6F',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#000000',
        borderColor: 'transparent',
        fontColor: '#ffffff',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#000000',
        fontColor: '#000000',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#000000',
        color: '#000000',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Merriweather',
        fontSize: '30px',
        fontWeight: '700',
        color: '#000000',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#000000', textColor: '#000000' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#000000',
        color: '#ffffff',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
        },
        button: {
          backgroundColor: '#000000',
          borderColor: 'transparent',
          fontColor: '#ffffff',
          borderRadius: '6px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Summer',
    fontFamily: ['Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#777777',
    image: Summer.src,
    code: 'summer',
    data: {
      teaser: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/summer.png`,
        backgroundColor: '#FAEBE1',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/summer.png`,
        backgroundColor: '#FAEBE1',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#222222',
        borderColor: 'transparent',
        fontColor: '#ffffff',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#222222',
        fontColor: '#222222',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#222222',
        color: '#222222',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#222222',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#787670',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#000000', textColor: '#000000' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#000000',
        color: '#ffffff',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#222222',
          borderColor: 'transparent',
          fontColor: '#ffffff',
          borderRadius: '6px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Winter',
    fontFamily: ['Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#777777',
    image: Winter.src,
    code: 'winter',
    data: {
      teaser: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/winter.png`,
        backgroundColor: '#DDE1E0',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/winter.png`,
        backgroundColor: '#DDE1E0',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#222222',
        borderColor: 'transparent',
        fontColor: '#ffffff',
        borderRadius: '6px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#222222',
        fontColor: '#222222',
        borderRadius: '6px',
      },
      form: {
        borderRadius: '6px',
        background: 'none',
        accent: '#222222',
        color: '#222222',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#222222',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#787670',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#000000', textColor: '#000000' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '6px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '8px',
        background: '#000000',
        color: '#ffffff',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#222222',
          borderColor: 'transparent',
          fontColor: '#ffffff',
          borderRadius: '6px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
  {
    name: 'Valentines Day',
    fontFamily: ['Poppins'],
    fontColor: '#000000',
    fontFamilyColor: '#777777',
    image: ValentinesDay.src,
    code: 'valentines_day',
    data: {
      teaser: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/valentines.png`,
        backgroundColor: '#FFEEDE',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        borderRadius: '0px',
      },
      body: {
        background: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/themes/valentines.png`,
        backgroundColor: '#FFEEDE',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        overlayColor: '#000000',
        overlayOpacity: '0%',
        borderRadius: '0px',
      },
      firstButton: {
        backgroundColor: '#FF345D',
        borderColor: 'transparent',
        fontColor: '#ffffff',
        borderRadius: '100px',
      },
      secondButton: {
        backgroundColor: 'none',
        borderColor: '#FF345D',
        fontColor: '#FF345D',
        borderRadius: '100px',
      },
      form: {
        borderRadius: '100px',
        background: 'none',
        accent: '#FF345D',
        color: '#000000',
        error: '#ee0c0c',
      },
      headline: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: '700',
        color: '#222222',
        lineHeight: '36px',
      },
      bodyText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        color: '#787670',
        lineHeight: '24px',
      },
      subText: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
      },
      link: {
        fontSize: '12px',
        color: '#0000008c',
        lineHeight: '18px',
      },
      closeButton: {
        background: '#000000',
        color: '#ffffff',
        borderRadius: '50px',
        borderColor: '#000000',
        strokeWidth: '2px',
        transform: 'none',
        activeTheme: 'close-1',
      },
      social: { iconColor: '#FF345D', textColor: '#FF345D' },
      couponCode: {
        background: 'none',
        color: '#000000',
        borderRadius: '100px',
        borderStyle: 'dashed',
        borderColor: '#000000',
      },
      countdown: {
        borderRadius: '100px',
        background: '#FF345D',
        color: '#000000',
        labelColor: '#000000',
        seperatorColor: '#000000',
      },
      spinToWin: {
        fontFamily: 'Poppins',
        background: 'none',
        arrowColor: '#f5b500',
        color: '#fff',
        leafColors: {
          1: '#e13e00',
          2: '#257184',
          3: '#4c3ed2',
          4: '#8e00c6',
        },
      },
      lotteryBall: {
        fontFamily: 'Poppins',
        background: 'none',
        color: '#fff',
        ballColors: {
          1: '#FFC700',
          2: '#ED7B57',
          3: '#FFBEA9',
          4: '#C73000',
          5: '#F8E7AE',
          6: '#FF9473',
          7: '#ED7B57',
          8: '#892D11',
        },
      },
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
      shopify: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        title: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#222222',
        },
        price: {
          fontSize: '14px',
          lineHeight: '18px',
          color: '#787670',
        },
        button: {
          backgroundColor: '#FF345D',
          borderColor: 'transparent',
          fontColor: '#ffffff',
          borderRadius: '100px',
        },
        icon: {
          fontSize: '24px',
          color: '#000000',
        },
      },
    },
  },
];

export const attributeList: Partial<{ [key in NodeType]: string[] }> = {};
export default ThemeList;
