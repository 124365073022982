import {
  KlaviyoInput,
  KlaviyoIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/KlaviyoResolver.types';

export const GetKlaviyoIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetKlaviyoIntegrationsByCampaignId',
  query: `query GetKlaviyoIntegrationsByCampaignId($campaignId: Int!) {
    getKlaviyoIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
      name
      integration {
        apiKey
        id
        accountId
        name
        status
      }
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetKlaviyoIntegrationsByAccountId = () => ({
  operationName: 'GetKlaviyoIntegrationsByAccountId',
  query: `query GetKlaviyoIntegrationsByAccountId {
        getKlaviyoIntegrationsByAccountId {
          id
          accountId
          name
          status
          integrationCampaigns {
            id
            campaignId
            integrationId
            status
            integrationFields {
              id
              integrationCampaignId
              popupsmartFieldName
              integratorFieldName
              integratorFieldId
              integratorTag
              fieldType
              isCustomerEmail
            }
            listId
            name
          }
          apiKey
        }
      }`,
});
export const GetKlaviyoLists = (apiKey: string) => ({
  operationName: 'GetKlaviyoLists',
  query: `query GetKlaviyoLists($apiKey: String!) {
        getKlaviyoLists(apiKey: $apiKey) {
          id
          name
          list_type
        }
      }`,
  variables: {
    apiKey,
  },
});
export const GetKlaviyoFields = () => ({
  operationName: 'GetKlaviyoFields',
  query: `query GetKlaviyoFields {
        getKlaviyoFields {
          integratorFieldId
          integratorFieldName
          integratorTag
        }
      }`,
});
export const CreateKlaviyo = (input: KlaviyoInput) => ({
  operationName: 'CreateKlaviyoIntegration',
  query: `mutation CreateKlaviyoIntegration($input: KlaviyoInput!) {
        createKlaviyoIntegration(input: $input)
      }`,
  variables: {
    input,
  },
});

export const UpdateKlaviyo = (
  id: number,
  input: KlaviyoIntegrationCampaignInput,
) => ({
  operationName: 'UpdateKlaviyoIntegrationCampaign',
  query: `mutation UpdateKlaviyoIntegrationCampaign($input: KlaviyoIntegrationCampaignInput!, $updateKlaviyoIntegrationCampaignId: Int!) {
    updateKlaviyoIntegrationCampaign(input: $input, id: $updateKlaviyoIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateKlaviyoIntegrationCampaignId: id,
  },
});

export const DeleteKlaviyoIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteKlaviyoIntegrationCampaign',
  query: `mutation DeleteKlaviyoIntegrationCampaign($deleteKlaviyoIntegrationCampaignId: Int!) {
    deleteKlaviyoIntegrationCampaign(id: $deleteKlaviyoIntegrationCampaignId)
  }`,
  variables: {
    deleteKlaviyoIntegrationCampaignId: id,
  },
});
