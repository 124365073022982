import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';
import produce, { Draft } from 'immer';
import { LeadEmail, LeadEmailsState } from './types';

import {
  ADD_LEAD_EMAIL_REQUEST,
  ADD_LEAD_EMAIL_SUCCESS,
  ADD_LEAD_EMAIL_FAILURE,
  DELETE_LEAD_EMAIL_REQUEST,
  DELETE_LEAD_EMAIL_SUCCESS,
  DELETE_LEAD_EMAIL_FAILURE,
  VERIFY_LEAD_EMAIL_REQUEST,
  VERIFY_LEAD_EMAIL_SUCCESS,
  VERIFY_LEAD_EMAIL_FAILURE,
  GET_LEAD_EMAILS_REQUEST,
  GET_LEAD_EMAILS_SUCCESS,
  GET_LEAD_EMAILS_FAILURE,
} from './constants';
import ReduxStatus from '@enums/ReduxStatus';

export const initialState: LeadEmailsState = {
  status: ReduxStatus.initialized,
  emails: [],
};

const reducer = produce(
  (draft: Draft<LeadEmailsState>, action: ReduxAction) => {
    switch (action.type) {
      case ADD_LEAD_EMAIL_REQUEST:
      case DELETE_LEAD_EMAIL_REQUEST:
      case GET_LEAD_EMAILS_REQUEST:
      case VERIFY_LEAD_EMAIL_REQUEST:
        draft.status = ReduxStatus.loading;
        return;

      case ADD_LEAD_EMAIL_SUCCESS: {
        const newEmail: LeadEmail = {
          email: action.payload.email,
          status: action.payload.status,
          id: action.payload.id,
          campaignId: action.payload.campaignId,
        };
        draft.emails.push(newEmail);
        draft.status = ReduxStatus.success;
        return;
      }

      case DELETE_LEAD_EMAIL_SUCCESS: {
        const { emailId, onLastLeadEmailDelete } = action.payload;
        const filteredEmails = draft.emails.filter(
          (x: LeadEmail) => x.id !== emailId,
        );
        draft.emails = filteredEmails;
        draft.status = ReduxStatus.success;
        if (filteredEmails.length === 0) {
          onLastLeadEmailDelete?.();
        }
        return;
      }

      case GET_LEAD_EMAILS_SUCCESS:
        draft.emails = action.payload;
        draft.status = ReduxStatus.success;
        return;
      case VERIFY_LEAD_EMAIL_SUCCESS:
        draft.status = ReduxStatus.success;
        return;

      case ADD_LEAD_EMAIL_FAILURE:
      case DELETE_LEAD_EMAIL_FAILURE:
      case GET_LEAD_EMAILS_FAILURE:
      case VERIFY_LEAD_EMAIL_FAILURE:
        draft.status = ReduxStatus.errored;
        return;
      case RESET_STORE:
        return initialState;
      default:
        return;
    }
  },
  initialState,
);

export default reducer;
