import { Template } from '@connectors/templates/types';
import { ThemeData } from 'core';
import { NestedKeyOf } from 'global';
import {
  SET_STYLE,
  SET_THEME,
  SET_PREVIEW,
  CLEAR_PREVIEW,
  GET_CURRENT_TEMPLATE,
  GET_CURRENT_TEMPLATE_SUCCESS,
  GET_CURRENT_TEMPLATE_ERROR,
} from './constants';
import {
  GetCurrentTemplateAction,
  SetStyleAction,
  SetThemeAction,
} from './types';

export function setTheme(code: string, theme: ThemeData): SetThemeAction {
  return {
    type: SET_THEME,
    payload: { code, theme },
  };
}
export function setPreview(code: string, theme: ThemeData): SetThemeAction {
  return {
    type: SET_PREVIEW,
    payload: { code, theme },
  };
}
export function clearThemePreview() {
  return {
    type: CLEAR_PREVIEW,
  };
}

export function setStyle(
  variable: NestedKeyOf<ThemeData>,
  value: string | object,
): SetStyleAction {
  return {
    type: SET_STYLE,
    payload: { variable, value },
  };
}

export function getCurrentTemplate(id: Number): GetCurrentTemplateAction {
  return {
    type: GET_CURRENT_TEMPLATE,
    payload: {
      id,
    },
  };
}

export function getCurrentTemplateSuccess({ data }: { data: Template }) {
  return {
    type: GET_CURRENT_TEMPLATE_SUCCESS,
    payload: {
      ...data,
    },
  };
}
export function getCurrentTemplateError() {
  return {
    type: GET_CURRENT_TEMPLATE_ERROR,
  };
}
