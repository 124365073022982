import React, { FC } from 'react';
import Lottie, {
  LottieComponentProps as PsLottieComponentProps,
  LottieRef as PsLottieRef,
} from 'lottie-react';

const PsLottie: FC<PsLottieComponentProps> = ({ animationData, ...rest }) => {
  const animationDataJSON = JSON.parse(JSON.stringify(animationData));
  return <Lottie {...rest} animationData={animationDataJSON} />;
};

export default PsLottie;
export type { PsLottieRef, PsLottieComponentProps };
