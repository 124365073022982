export const hubspotServiceURl = 'api/integrations/hubspot';

export const HUBSPOT_GET_CONNECTIONS =
  'builder/integrations/HUBSPOT_GET_CONNECTIONS';
export const HUBSPOT_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/HUBSPOT_GET_CONNECTIONS_SUCCESS';
export const HUBSPOT_GET_CONNECTIONS_FAILURE =
  'builder/integrations/HUBSPOT_GET_CONNECTIONS_FAILURE';

export const HUBSPOT_SAVE_CONNECTION =
  'builder/integrations/HUBSPOT_SAVE_CONNECTION';
export const HUBSPOT_SAVE_CONNECTION_SUCCESS =
  'builder/integrations/HUBSPOT_SAVE_CONNECTION_SUCCESS';
export const HUBSPOT_SAVE_CONNECTION_FAILURE =
  'builder/integrations/HUBSPOT_SAVE_CONNECTION_FAILURE';

export const HUBSPOT_GET_FIELDS = 'builder/integrations/HUBSPOT_GET_FIELDS';
export const HUBSPOT_GET_FIELDS_SUCCESS =
  'builder/integrations/HUBSPOT_GET_FIELDS_SUCCESS';
export const HUBSPOT_GET_FIELDS_FAILURE =
  'builder/integrations/HUBSPOT_GET_FIELDS_FAILURE';

export const HUBSPOT_GET_LIST = 'builder/integrations/HUBSPOT_GET_LIST';
export const HUBSPOT_GET_LIST_SUCCESS =
  'builder/integrations/HUBSPOT_GET_LIST_SUCCESS';
export const HUBSPOT_GET_LIST_FAILURE =
  'builder/integrations/HUBSPOT_GET_LIST_FAILURE';

export const HUBSPOT_SAVE = 'builder/integrations/HUBSPOT_SAVE';
export const HUBSPOT_SAVE_SUCCESS = 'builder/integrations/HUBSPOT_SAVE_SUCCESS';
export const HUBSPOT_SAVE_FAILURE = 'builder/integrations/HUBSPOT_SAVE_FAILURE';

export const HUBSPOT_DELETE = 'builder/integrations/HUBSPOT_DELETE';
export const HUBSPOT_DELETE_SUCCESS =
  'builder/integrations/HUBSPOT_DELETE_SUCCESS';
export const HUBSPOT_DELETE_FAILURE =
  'builder/integrations/HUBSPOT_DELETE_FAILURE';

export const HUBSPOT_CHANGESTATUS = 'builder/integrations/HUBSPOT_CHANGESTATUS';
export const HUBSPOT_CHANGESTATUS_SUCCESS =
  'builder/integrations/HUBSPOT_CHANGESTATUS_SUCCESS';
export const HUBSPOT_CHANGESTATUS_FAILURE =
  'builder/integrations/HUBSPOT_CHANGESTATUS_FAILURE';

export const HUBSPOT_CLEAR_STATE = 'builder/integrations/HUBSPOT_CLEAR_STATE';
