export const mailchimpServiceURl = 'api/integrations/mailchimp';

export const MAILCHIMP_GET_CONNECTIONS =
  'builder/integrations/MAILCHIMP_GET_CONNECTIONS';
export const MAILCHIMP_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/MAILCHIMP_GET_CONNECTIONS_SUCCESS';
export const MAILCHIMP_GET_CONNECTIONS_FAILURE =
  'builder/integrations/MAILCHIMP_GET_CONNECTIONS_FAILURE';

export const MAILCHIMP_GET_FIELDS = 'builder/integrations/MAILCHIMP_GET_FIELDS';
export const MAILCHIMP_GET_FIELDS_SUCCESS =
  'builder/integrations/MAILCHIMP_GET_FIELDS_SUCCESS';
export const MAILCHIMP_GET_FIELDS_FAILURE =
  'builder/integrations/MAILCHIMP_GET_FIELDS_FAILURE';

export const MAILCHIMP_GET_LIST = 'builder/integrations/MAILCHIMP_GET_LIST';
export const MAILCHIMP_GET_LIST_SUCCESS =
  'builder/integrations/MAILCHIMP_GET_LIST_SUCCESS';
export const MAILCHIMP_GET_LIST_FAILURE =
  'builder/integrations/MAILCHIMP_GET_LIST_FAILURE';

export const MAILCHIMP_SAVE = 'builder/integrations/MAILCHIMP_SAVE';
export const MAILCHIMP_SAVE_SUCCESS =
  'builder/integrations/MAILCHIMP_SAVE_SUCCESS';
export const MAILCHIMP_SAVE_FAILURE =
  'builder/integrations/MAILCHIMP_SAVE_FAILURE';

export const MAILCHIMP_DELETE = 'builder/integrations/MAILCHIMP_DELETE';
export const MAILCHIMP_DELETE_SUCCESS =
  'builder/integrations/MAILCHIMP_DELETE_SUCCESS';
export const MAILCHIMP_DELETE_FAILURE =
  'builder/integrations/MAILCHIMP_DELETE_FAILURE';

export const MAILCHIMP_CHANGESTATUS =
  'builder/integrations/MAILCHIMP_CHANGESTATUS';
export const MAILCHIMP_CHANGESTATUS_SUCCESS =
  'builder/integrations/MAILCHIMP_CHANGESTATUS_SUCCESS';
export const MAILCHIMP_CHANGESTATUS_FAILURE =
  'builder/integrations/MAILCHIMP_CHANGESTATUS_FAILURE';

export const MAILCHIMP_CLEAR_TEST_RESULT =
  'builder/integrations/MAILCHIMP_CLEAR_TEST_RESULT';
export const MAILCHIMP_CLEAR_STATE =
  'builder/integrations/MAILCHIMP_CLEAR_STATE';
