import type { SegmentActionProps } from './types';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import styles from './SegmentAction.module.scss';

import MoreIcon from '@assets/icons/more.svg';
import EditIcon from '@assets/icons/edit.svg';
import SummaryIcon from '@assets/icons/targeting-summary.svg';
import SettingsIcon from '@assets/icons/settings_outline.svg';
import CloseIcon from '@assets/icons/close-large.svg';
import { useClickOutsideHandler } from '@utils/hooks';
import { Tooltip } from 'ui';

const Actions = [
  {
    icon: <SettingsIcon width="16" height="16" viewBox="0 0 18 18" />,
    label: 'Edit Segment',
    value: 'edit',
    testId: 'edit_segment',
  },
  {
    icon: <EditIcon width="16" height="16" viewBox="0 0 36 36" />,
    label: 'Rename',
    value: 'rename',
    testId: 'rename_segment',
  },
  {
    icon: <SummaryIcon width="16" height="16" viewBox="0 0 18 18" />,
    label: 'Summary',
    value: 'summary',
    testId: 'segment_summary',
  },
  {
    icon: <CloseIcon width="16" height="16" viewBox="0 0 16 16" />,
    label: 'Delete',
    value: 'delete',
    testId: 'delete_summary',
  },
];

const SegmentAction: FC<SegmentActionProps> = ({ id, actions }) => {
  const [isActive, setIsActive] = useState(false);

  const actionRef = useClickOutsideHandler({
    onClose: (event: Event) => {
      const dropdown = document.querySelector(`#segment-action-portal-${id}`);
      if (
        actionRef.current?.contains(event.target as globalThis.Node) ||
        dropdown?.contains(event.target as globalThis.Node)
      ) {
        return;
      }

      setIsActive(false);
    },
  });

  const dropdownActions = actions.map((action) => ({
    ...Actions.find((b) => b.value === action.value && b),
    ...action,
  }));

  const closeMenu = () => {
    setIsActive(false);
  };

  useEffect(() => {
    const segmentModal = document?.querySelector(
      `#segment-change-view`,
    ) as HTMLDivElement;

    segmentModal?.addEventListener('scroll', closeMenu);

    return () => {
      segmentModal?.removeEventListener('scroll', closeMenu);
    };
  }, []);

  const position = useMemo(() => {
    if (!isActive) return;
    const tagButton = document?.querySelector(
      `#segment-action-button-${id}`,
    ) as HTMLButtonElement;

    const tagButtonPosition = tagButton?.getBoundingClientRect();

    const buttonPositions = {
      top: (tagButtonPosition?.top ?? 0) + 42,
      left: (tagButtonPosition?.left ?? 0) - 106,
    };

    return buttonPositions;
  }, [isActive, id]);

  const list = (
    <div
      className={cn(styles.dropdown, { [styles.open]: isActive })}
      style={{ ...position }}
    >
      {dropdownActions.map((dropdownAction) => {
        const handleClick = () => {
          dropdownAction?.action();
          setIsActive(false);
        };
        if (dropdownAction.tooltip) {
          return (
            <Tooltip
              key={dropdownAction.value}
              className="!flex !w-full"
              contentClassName="!left-0 !translate-x-0 !-translate-y-full !max-w-full !break-words !whitespace-normal"
              content={dropdownAction.tooltip}
            >
              <button
                className={cn(styles.item, {
                  [styles.disable]: dropdownAction.disable,
                })}
                data-testid={dropdownAction.testId}
                onClick={handleClick}
                disabled={dropdownAction.disable}
              >
                {dropdownAction.label}
                <span className={styles.actionIcon}>{dropdownAction.icon}</span>
              </button>
            </Tooltip>
          );
        }

        return (
          <button
            className={cn(styles.item, {
              [styles.disable]: dropdownAction.disable,
            })}
            data-testid={dropdownAction.testId}
            key={dropdownAction.value}
            onClick={handleClick}
            disabled={dropdownAction.disable}
          >
            {dropdownAction.label}
            <span className={styles.actionIcon}>{dropdownAction.icon}</span>
          </button>
        );
      })}
    </div>
  );

  const container = useMemo(() => document?.querySelector('#__next'), []);

  return (
    <div ref={actionRef} className={styles.action}>
      <button
        id={`segment-action-button-${id}`}
        className={styles.button}
        onClick={() => setIsActive(!isActive)}
      >
        <MoreIcon />
      </button>
      {container
        ? createPortal(
            <div
              id={`segment-action-portal-${id}`}
              className="segment-action-portal"
            >
              {list}
            </div>,
            container,
          )
        : list}
    </div>
  );
};
export default SegmentAction;
