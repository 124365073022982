import { useCallback, useEffect, useRef, useState } from 'react';

export const useClickOutsideHandler = (
  {
    onClose,
  }: {
    onClose: (event: Event) => void;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  } = { onClose: () => {} },
) => {
  const ref = useRef<HTMLDivElement>(null);

  const escapeListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose(event);
    }
  }, []);

  const clickListener = useCallback(
    (event: MouseEvent) => {
      if (!ref?.current?.contains(event.target as Node)) {
        onClose(event);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('mousedown', clickListener);
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, []);

  return ref;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
