import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';

import {
  GET_WEBSITES_REQUEST,
  GET_WEBSITES_SUCCESS,
  GET_WEBSITES_FAILURE,
  ADD_WEBSITE_REQUEST,
  ADD_WEBSITE_SUCCESS,
  ADD_WEBSITE_FAILURE,
  DELETE_WEBSITE_REQUEST,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  UPDATE_WEBSITE_REQUEST,
  UPDATE_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_FAILURE,
  GET_CMS_REQUEST,
  GET_CMS_SUCCESS,
  GET_CMS_FAILURE,
  UPDATE_CMS,
  UPDATE_WEBSITE_VERIFY_STATUS,
} from './constants';
import { createSelector } from 'reselect';
import { RootState } from '@connectors/reducers';
import produce, { Draft } from 'immer';

export interface Website {
  id: number;
  url: string;
  verified: boolean;
  cms?: string | null;
  isShopify?: boolean;
}

export interface WebsiteState {
  items: Website[];
  status: string;
  CMS: string | null;
}

const initialState: WebsiteState = {
  items: [],
  status: 'initialized',
  CMS: null,
};

const reducer = produce((draft: Draft<WebsiteState>, action: ReduxAction) => {
  switch (action.type) {
    case GET_WEBSITES_REQUEST:
    case ADD_WEBSITE_REQUEST:
    case DELETE_WEBSITE_REQUEST:
    case GET_CMS_REQUEST:
    case UPDATE_WEBSITE_REQUEST: {
      draft.status = 'loading';
      return;
    }

    case GET_WEBSITES_SUCCESS: {
      draft.items = action.payload;
      draft.status = 'success';
      return;
    }

    case ADD_WEBSITE_SUCCESS: {
      draft.items.push(action.payload);
      draft.status = 'success';
      return;
    }

    case DELETE_WEBSITE_SUCCESS: {
      const filteredWebsites = draft.items.filter(
        (website) => website.id !== action.payload,
      );
      draft.items = filteredWebsites;
      draft.status = 'success';
      return;
    }

    case UPDATE_WEBSITE_SUCCESS: {
      const updatedWebsites = action.payload;
      draft.items = updatedWebsites;
      draft.status = 'success';
      return;
    }
    case UPDATE_CMS:
    case GET_CMS_SUCCESS: {
      draft.CMS = action.payload === '' ? 'Custom' : action.payload;
      draft.status = 'success';
      return;
    }

    case GET_WEBSITES_FAILURE: {
      draft.items = [];
      draft.status = 'errored';
      return;
    }

    case ADD_WEBSITE_FAILURE: {
      draft.status = 'errored';
      return;
    }

    case DELETE_WEBSITE_FAILURE: {
      draft.status = 'errored';
      return;
    }

    case UPDATE_WEBSITE_FAILURE: {
      draft.status = 'errored';
      return;
    }
    case GET_CMS_FAILURE: {
      draft.status = 'errored';
      return;
    }
    case UPDATE_WEBSITE_VERIFY_STATUS: {
      const { websiteId, status } = action.payload;
      const website = draft.items.find((x) => x.id === websiteId);
      if (website) {
        website.verified = status;
      }
      return;
    }
    case RESET_STORE:
      return initialState;
    default:
      return;
  }
}, initialState);
export default reducer;

const selectWebsites = (state: RootState) => state.websites || initialState;

export const makeSelectWebsites = createSelector(
  selectWebsites,
  (websitesState) => websitesState.items,
);
