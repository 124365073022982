import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import store from './store';

const config: AxiosRequestConfig<any> = {
  responseType: 'json',
  timeout: 0,
  validateStatus: (status) => status >= 200 && status < 300, // default
};

const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  const auth = store.get('user');
  const token: string | null = auth?.token || null;
  config.headers = {
    ...config.headers,
    Accept: 'application/json',
  };

  if (token && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const responseInterceptor = ({ data }: AxiosResponse<any, any>): any => {
  return data;
};

const instance = axios.create({
  ...config,
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

instance.interceptors.request.use(requestInterceptor, (error) => {
  //console.warn('request error', error)
  throw new Error(error);
});
let isRefreshing = false;
let subscribers: any[] = [];

function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb);
}

function onRefreshed(token: string) {
  subscribers.map((cb) => cb(token));
}

instance.interceptors.response.use(responseInterceptor, async (error) => {
  const errorKey = error.response.data.error;
  const headers = error.config.headers;
  const status = error.response.status;
  const auth = store.get('user');
  const token = auth?.token;
  //status===402 => payment required error code
  //status===401 && !token is the case for login/register errors
  if (
    ((status === 401 && !token) || status !== 401) &&
    !isRefreshing &&
    !headers?.toasterDisabled
  ) {
    const errorMessage = errorKey
      ? errorKey
      : status === 402
      ? "You've overreached your plan limit. Upgrade your plan first to create a new campaign."
      : 'An error occurred, please try again later or contact us.';

    toast.error(errorMessage);
    throw new Error(errorMessage);
  }

  const refreshToken = auth?.refreshToken;
  const originalRequest = error.config;

  if (status === 401 && token) {
    if (!isRefreshing) {
      try {
        isRefreshing = true;
        const res: AxiosResponse<{ token: string }> = await axios.post(
          'api/Auth/refresh-token',
          {
            token,
            refreshToken,
          },
          {
            baseURL: process.env.NEXT_PUBLIC_API_URL,
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        isRefreshing = false;
        onRefreshed(res.data.token);
        originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
        const newAuth = { ...auth, ...res.data };
        store.set('user', newAuth);
        subscribers = [];
        return Promise.resolve(instance(originalRequest));
      } catch (error) {
        store.clearTemps();
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }
  }

  return new Promise((resolve) => {
    subscribeTokenRefresh((token: string) => {
      originalRequest.headers.Authorization = `Bearer ${token}`;
      resolve(instance(originalRequest));
    });
  });
});

export default instance;

// just export like default instance for microservices
export const cdn = axios.create({
  ...config,
  baseURL: process.env.NEXT_PUBLIC_CDN_URL,
});
