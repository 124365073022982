import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  ACTIVECAMPAIGN_GET_FIELDS,
  ACTIVECAMPAIGN_GET_FIELDS_SUCCESS,
  ACTIVECAMPAIGN_GET_FIELDS_FAILURE,
  ACTIVECAMPAIGN_GET_CONNECTIONS,
  ACTIVECAMPAIGN_GET_CONNECTIONS_SUCCESS,
  ACTIVECAMPAIGN_GET_CONNECTIONS_FAILURE,
  ACTIVECAMPAIGN_GET_LIST,
  ACTIVECAMPAIGN_GET_LIST_SUCCESS,
  ACTIVECAMPAIGN_GET_LIST_FAILURE,
  ACTIVECAMPAIGN_SAVE,
  ACTIVECAMPAIGN_SAVE_SUCCESS,
  ACTIVECAMPAIGN_SAVE_FAILURE,
  ACTIVECAMPAIGN_CHANGESTATUS,
  ACTIVECAMPAIGN_CHANGESTATUS_FAILURE,
  ACTIVECAMPAIGN_DELETE,
  ACTIVECAMPAIGN_DELETE_FAILURE,
  ACTIVECAMPAIGN_CLEAR_STATE,
} from './constants';
import { ActiveCampaignState } from './type';
import produce, { Draft } from 'immer';

const initialState: ActiveCampaignState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
};

const reducer = produce(
  (draft: Draft<ActiveCampaignState>, action: ReduxAction) => {
    const { type } = action;
    switch (type) {
      case ACTIVECAMPAIGN_GET_FIELDS:
        draft.fields = { items: [], status: ReduxStatus.loading };
        return;
      case ACTIVECAMPAIGN_GET_FIELDS_SUCCESS:
        draft.fields = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case ACTIVECAMPAIGN_GET_FIELDS_FAILURE:
        draft.fields = {
          items: [],
          status: ReduxStatus.errored,
        };
        return;
      case ACTIVECAMPAIGN_GET_LIST:
        draft.list = {
          items: [],
          status: ReduxStatus.loading,
        };
        return;
      case ACTIVECAMPAIGN_GET_LIST_SUCCESS:
        draft.list = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case ACTIVECAMPAIGN_GET_LIST_FAILURE:
        draft.list = {
          items: [],
          status: ReduxStatus.errored,
        };
        return;

      case ACTIVECAMPAIGN_GET_CONNECTIONS:
        draft.connection = { items: [], status: ReduxStatus.loading };
        return;
      case ACTIVECAMPAIGN_GET_CONNECTIONS_SUCCESS:
        draft.connection = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case ACTIVECAMPAIGN_GET_CONNECTIONS_FAILURE:
        draft.connection = { items: [], status: ReduxStatus.errored };
        return;

      case ACTIVECAMPAIGN_SAVE:
        draft.status = ReduxStatus.loading;
        return;
      case ACTIVECAMPAIGN_SAVE_SUCCESS:
        draft.list = { items: [], status: ReduxStatus.initialized };
        draft.fields = { items: [], status: ReduxStatus.initialized };
        draft.status = ReduxStatus.success;
        return;
      case ACTIVECAMPAIGN_SAVE_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case ACTIVECAMPAIGN_DELETE:
        draft.status = ReduxStatus.loading;
        return;
      case ACTIVECAMPAIGN_DELETE_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case ACTIVECAMPAIGN_CHANGESTATUS:
        draft.status = ReduxStatus.loading;
        return;
      case ACTIVECAMPAIGN_CHANGESTATUS_FAILURE:
        draft.status = ReduxStatus.errored;
        return;
      case ACTIVECAMPAIGN_CLEAR_STATE:
        return initialState;

      default:
        return;
    }
  },
  initialState,
);

export default reducer;
