import React, { FC } from 'react';

type IconProps = {
  width?: string;
  height?: string;
  viewBox?: string;
};

const SearchIcon: FC<IconProps> = ({ width, height, viewBox }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox={viewBox || '0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0248 13.9748L11.7998 10.7498C12.6248 9.69981 13.0748 8.42481 13.0748 6.9998C13.0748 3.6248 10.3748 0.924805 6.9998 0.924805C3.6248 0.924805 0.924805 3.6248 0.924805 6.9998C0.924805 10.3748 3.6248 13.0748 6.9998 13.0748C8.42481 13.0748 9.69981 12.6248 10.7498 11.7998L13.9748 15.0248L15.0248 13.9748ZM2.4248 6.9998C2.4248 4.4498 4.4498 2.4248 6.9998 2.4248C9.5498 2.4248 11.5748 4.4498 11.5748 6.9998C11.5748 9.5498 9.5498 11.5748 6.9998 11.5748C4.4498 11.5748 2.4248 9.5498 2.4248 6.9998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;
