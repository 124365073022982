import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';
import SegmentIcon from '@assets/icons/target_icon.svg';

export const renderOnClickTargeting = (
  onclickTargeting: Targets['onclickTargeting'],
  translate: Function,
) => {
  if (onclickTargeting) {
    return (
      <>
        <TimelineContent
          icon={<SegmentIcon width="16" height="16" viewBox="0 0 18 18" />}
          label={translate('sections:targeting-summary-on-click:message')}
        />
      </>
    );
  }
};
