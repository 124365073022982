/* eslint-disable @typescript-eslint/ban-types */
import type { UrlBrowsing } from 'core/types';
import { ReduxAction } from 'global';

import type { ViewFrequencyInputValues } from '@components/Targeting/FrequencySettings/Target/ViewFrequency/ViewFrequency';
import type { OperatingSystemInputValues } from '@components/Targeting/Audience/Target/OperatingSystem/OperatingSystem';
import type { GeoLocatedInputValues } from '@components/Targeting/Audience/Target/GeoLocated/GeoLocated';
import type { TrafficSourceInputValues } from '@components/Targeting/Audience/Target/TrafficSource/TrafficSource';
import type { HtmlTargetingInputValues } from '@components/Targeting/Audience/Target/HtmlTargeting/HtmlTargeting';
import type { CookieTargetingInputValues } from '@components/Targeting/Audience/Target/CookieTargeting/CookieTargeting';
import type { SiteDataTargetingInputValues } from '@components/Targeting/Audience/Target/SiteDataTargeting/SiteDataTargeting';
import type { AfterXSecondsInputValues } from '@components/Targeting/UserBehavior/Target/AfterXSeconds/AfterXSeconds';
import type { AfterScrollingInputValues } from '@components/Targeting/UserBehavior/Target/AfterScrolling/AfterScrolling';
import type { OnExitIntentInputValues } from '@components/Targeting/UserBehavior/Target/OnExitIntent/OnExitIntent';
import type { InActivityModeInputValues } from '@components/Targeting/UserBehavior/Target/InActivityMode/InActivityMode';
import type { VisitorDeviceInputValues } from '@components/Targeting/Audience/Target/VisitorDevice/VisitorDevice';

import {
  CAMPAIGN_GET,
  CAMPAIGN_GET_SUCCESS,
  DOMAIN_DELETE,
  DOMAIN_DELETE_SUCCESS,
  DOMAIN_SELECT,
  DOMAIN_SELECT_SUCCESS,
  CAMPAIGN_FAILURE,
  AUTO_SAVE,
  AUTO_SAVE_SUCCESS,
  CAMPAIGN_LAYOUT_GET,
  CAMPAIGN_LAYOUT_GET_SUCCESS,
  DOMAIN_GET,
  DOMAIN_GET_SUCCESS,
  PUBLISH_SUCCESS,
  PUBLISH,
  PUBLISH_FAILURE,
  DEFAULT_PUBLISH,
  DISABLE_EDIT_MOBILE_MODAL_SUCCESS,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  SAVE_LAYOUT,
  SAVE_LAYOUT_SUCCESS,
  SEND_EMBED_CODE_REQUEST,
  SEND_EMBED_CODE_SUCCESS,
  SET_SETTINGS_REQUEST,
  SET_SETTINGS_SUCCESS,
  SET_SETTINGS_FAILURE,
  CLEAR_CAMPAIGN,
  CLEAR_CAMPAIGN_SUCCESS,
  PREVIEW_ACTIVE,
  PREVIEW_ACTIVE_SUCCESS,
  SAVE_PAGE_INDEX,
  SAVE_PAGE_INDEX_SUCCESS,
  LOCAL_UPDATE_CAMPAIGN_REQUEST,
  LOCAL_UPDATE_CAMPAIGN_SUCCESS,
  LOCAL_UPDATE_CAMPAIGN_FAILURE,
  SAVE_ACTIVE_DEVICE,
  SAVE_ACTIVE_DEVICE_SUCCESS,
  TAKE_SCREENSHOT_REQUEST,
  TAKE_SCREENSHOT_SUCCESS,
  TAKE_SCREENSHOT_FAILURE,
  UPDATE_AUTO_RESPONDER_REQUEST,
  UPDATE_AUTO_RESPONDER_SUCCESS,
  UPDATE_AUTO_RESPONDER_FAILURE,
  CAMPAIGN_DETAILS_GET,
  CAMPAIGN_DETAILS_GET_SUCCESS,
} from './constants';
import { IntegrationListObject } from '@connectors/integration/reducers';

export interface DomainObject {
  id: number;
  lastImpressionDate?: string;
  url: string;
  userId?: number;
  verified: boolean;
}
export interface TargetingState {
  displayFrequency?: ViewFrequencyInputValues;
  schedule?: Array<{ startDate: string; endDate: string }>;
  operatingSystem?: OperatingSystemInputValues;
  newOrReturningVisitors?: string;
  browserLanguage?: Array<string>;
  geoLocated?: GeoLocatedInputValues;
  trafficSource?: TrafficSourceInputValues[];
  htmlTargeting?: HtmlTargetingInputValues[];
  cookieTargeting?: CookieTargetingInputValues[];
  siteDataTargeting?: SiteDataTargetingInputValues[];
  afterXSeconds?: AfterXSecondsInputValues;
  afterScrollingXAmount?: AfterScrollingInputValues;
  onExitIntent?: OnExitIntentInputValues;
  urlBrowsing?: UrlBrowsing;
  inActivityMode?: InActivityModeInputValues;
  onClick?: string;
  visitorDevice?: VisitorDeviceInputValues;
  targetSmartMode?: boolean;
}
export interface Domain {
  id: number;
  url: string;
  verified: boolean;
  cms: string;
}

interface Campaign {
  id?: null;
  name?: '';
  domains?: Domain[];
  targets?: TargetingState;
  layout?: {};
  previewCampaign?: {};
  settings?: {};
  mobileScreenshot?: string | null;
  desktopScreenshot?: string | null;
}

export interface CampaignState extends Campaign {
  activePageIndex: number;
  activeShowSidebar: boolean;
  activeDevice: string;
  isPreviewActive: boolean;
  isSaveDisabled: boolean;
  isAutoSaving: boolean;
  isPublishing: {
    status: 'success' | 'errored' | 'loading' | 'initialized';
    isPublished: boolean;
  };
  publishVersion: number;
  isEditMobileModalDisabled?: boolean;
  integrationActiveCampaignCampaigns: IntegrationListObject[];
  integrationMailchimpCampaigns: IntegrationListObject[];
  integrationHubSpotCampaigns: IntegrationListObject[];
  integrationKlaviyoCampaigns: IntegrationListObject[];
  integrationEmailOctopusCampaigns: IntegrationListObject[];
  integrationMailerliteCampaigns: IntegrationListObject[];
  integrationDripCampaigns: IntegrationListObject[];
  integrationCustomerIoCampaigns: IntegrationListObject[];
  integrationSendgridCampaigns: IntegrationListObject[];
  integrationSendinBlueCampaigns: IntegrationListObject[];
  integrationWebhooks: IntegrationListObject[];
  status: 'success' | 'errored' | 'loading' | 'initialized';
}

import store from '@utils/store';
import { RESET_STORE } from '@connectors/actions';

const editMobileModalDisabled = store.get('editMobileModalDisabled');

const initialState: CampaignState = {
  id: null,
  name: '',
  domains: [],
  targets: {
    visitorDevice: {
      desktop: true,
      mobile: true,
    },
  },
  layout: {},
  previewCampaign: {},
  settings: {},
  activePageIndex: 0,
  activeShowSidebar: true,
  activeDevice: 'desktop',
  isPreviewActive: false,
  isSaveDisabled: true,
  isAutoSaving: false,
  isPublishing: {
    status: 'initialized',
    isPublished: false,
  },
  publishVersion: 0,
  isEditMobileModalDisabled: Boolean(editMobileModalDisabled),
  mobileScreenshot: null,
  desktopScreenshot: null,
  integrationActiveCampaignCampaigns: [],
  integrationMailchimpCampaigns: [],
  integrationHubSpotCampaigns: [],
  integrationKlaviyoCampaigns: [],
  integrationEmailOctopusCampaigns: [],
  integrationMailerliteCampaigns: [],
  integrationDripCampaigns: [],
  integrationCustomerIoCampaigns: [],
  integrationSendgridCampaigns: [],
  integrationSendinBlueCampaigns: [],
  integrationWebhooks: [],
  status: 'initialized',
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        ...action.payload,
        status: 'loading',
      };

    case CAMPAIGN_GET:
      return { ...state, status: 'loading' };
    case CAMPAIGN_GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: 'success',
      };

    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: 'success',
      };

    case CAMPAIGN_LAYOUT_GET:
      return { ...state, status: 'loading' };
    case CAMPAIGN_LAYOUT_GET_SUCCESS: {
      const { layout } = action.payload;
      return {
        ...state,
        layout,
        status: 'success',
      };
    }
    case CAMPAIGN_DETAILS_GET:
      return { ...state, status: 'loading' };
    case CAMPAIGN_DETAILS_GET_SUCCESS:
      return {
        ...state,
        previewCampaign: action.payload,
        status: 'success',
      };

    case AUTO_SAVE:
      return { ...state, isAutoSaving: true, status: 'loading' };
    case AUTO_SAVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAutoSaving: false,
        isSaveDisabled: true,
        status: 'success',
      };

    case SAVE_LAYOUT:
      return { ...state, status: 'loading' };
    case SAVE_LAYOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isSaveDisabled: false,
        status: 'success',
      };

    case SAVE_PAGE_INDEX:
      return { ...state, status: 'loading' };
    case SAVE_PAGE_INDEX_SUCCESS:
      return {
        ...state,
        activePageIndex: action.payload,
        status: 'success',
      };

    case PREVIEW_ACTIVE:
      return { ...state, status: 'loading' };

    case PREVIEW_ACTIVE_SUCCESS:
      return {
        ...state,
        isPreviewActive: action.payload,
        status: 'success',
      };

    case SAVE_ACTIVE_DEVICE:
      return { ...state, status: 'loading' };

    case SAVE_ACTIVE_DEVICE_SUCCESS:
      return {
        ...state,
        activeDevice: action.payload,
        status: 'success',
      };

    case CLEAR_CAMPAIGN:
      return { ...state, status: 'loading' };
    case CLEAR_CAMPAIGN_SUCCESS:
      return {
        ...initialState,
        isEditMobileModalDisabled: state.isEditMobileModalDisabled,
        status: 'initialized',
      };

    case PUBLISH:
      return {
        ...state,
        isPublishing: { ...state.isPublishing, status: 'loading' },
        status: 'loading',
      };

    case PUBLISH_SUCCESS:
      return {
        ...state,
        isPublishing: { status: 'success', isPublished: true },
        publishVersion: state.publishVersion + 1,
        status: 'success',
      };
    case PUBLISH_FAILURE:
      return {
        ...state,
        isPublishing: { status: 'errored', isPublished: false },
        status: 'errored',
      };

    case DEFAULT_PUBLISH:
      return {
        ...state,
        isPublishing: { status: 'initialized', isPublished: false },
        status: 'success',
      };
    case DISABLE_EDIT_MOBILE_MODAL_SUCCESS:
      return {
        ...state,
        isEditMobileModalDisabled: true,
        status: 'success',
      };

    case DOMAIN_DELETE:
      return { ...state, status: 'loading' };
    case DOMAIN_DELETE_SUCCESS:
      return {
        ...state,
        domains: action.payload.domains,
        status: 'success',
      };

    case DOMAIN_SELECT:
      return { ...state, status: 'loading' };
    case DOMAIN_SELECT_SUCCESS:
      return {
        ...state,
        domains: action.payload.domains,
        status: 'success',
      };
    case DOMAIN_GET:
    case SEND_EMBED_CODE_REQUEST:
      return { ...state, status: 'loading' };

    case DOMAIN_GET_SUCCESS:
      return {
        ...state,
        domains: action.payload,
        status: 'success',
      };
    case SEND_EMBED_CODE_SUCCESS:
      return {
        ...state,
        status: 'success',
      };

    case CAMPAIGN_FAILURE:
    case CREATE_CAMPAIGN_FAILURE:
      return { ...state, status: 'errored' };

    case SET_SETTINGS_REQUEST:
      return { ...state, status: 'loading' };
    case SET_SETTINGS_SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case SET_SETTINGS_FAILURE:
      return { ...state, status: 'errored' };

    case LOCAL_UPDATE_CAMPAIGN_REQUEST:
      return { ...state, status: 'loading' };
    case LOCAL_UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case LOCAL_UPDATE_CAMPAIGN_FAILURE:
      return { ...state, status: 'errored' };

    case TAKE_SCREENSHOT_REQUEST:
      return { ...state, status: 'loading' };

    case TAKE_SCREENSHOT_SUCCESS:
      return { ...state, ...action.payload, status: 'success' };

    case TAKE_SCREENSHOT_FAILURE:
      return { ...state, status: 'errored' };
    case UPDATE_AUTO_RESPONDER_REQUEST:
      return { ...state, status: 'loading' };

    case UPDATE_AUTO_RESPONDER_SUCCESS:
      return { ...state, ...action.payload, status: 'success' };

    case UPDATE_AUTO_RESPONDER_FAILURE:
      return { ...state, status: 'errored' };

    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
