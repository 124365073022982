import { LayoutOption } from '@components/AddNewStepSidebar/PopupLayout/types';
import { Content, Node } from '@connectors/builder/campaign';
import { PositionItem, positions } from '@elements/SelectPosition';
import Layouts from '@model/Layout';
import { resizePopupLayout, resizePopupLayoutNode } from './resizePopupLayout';
import { moveLayoutContent } from './moveLayoutContent';
import DeviceTypes from '@enums/DeviceTypes';
import ElementInitialValues from '@model/ElementInitialValues';
import { cloneDeep } from 'lodash';
import { getObject, removeProperties } from '@connectors/builder/reducer';

// should return content
export const generateTeaserLayout = (
  page: Content,
  value: LayoutOption,
  toSize: 'sm' | 'md' | 'lg',
  moveContent?: boolean,
  location?: string,
  closeButton?: Node,
  visibility?: boolean,
  keepNotMatchingElements?: boolean,
  changePosition?: boolean,
): Content => {
  const sizedTeaserLayout = resizePopupLayout({
    page: value.layout!,
    currentSize: 'md',
    toSize,
  });

  let content: Content = {
    ...sizedTeaserLayout,
    appearance: {
      ...page.appearance,
      ...(visibility !== undefined && { visibility }),
      position: location || page.appearance.position,
    },
    title: page.title,
    type: page.type,
    id: page.id,
    version: page.version,
    template: {
      id: page.template.id,
      imageUrl: page.template.imageUrl,
      name: value.key,
    },
  };

  if (moveContent) {
    content = moveLayoutContent(page, content, keepNotMatchingElements);
  }

  if (closeButton) {
    content = changeTeaserCloseButton(
      content,
      true,
      location || 'bottom-left',
      closeButton,
    );
  }

  if (changePosition && location) {
    const newPosition = positions.find((item) => item.value === location);
    const positionedTeaser = generateTeaserWithPosition(
      content,
      newPosition!,
      closeButton,
    );
    if (positionedTeaser) {
      content.content = positionedTeaser.content;
      content.appearance.position = location;
    }
  }

  return content;
};

export const generateTeaserWithPosition = (
  page: Content | null | undefined,
  newPosition: PositionItem,
  closeButton?: Node,
  migrate?: boolean,
) => {
  if (!page) return;
  let updatedPage = cloneDeep(page);
  const { name: templateName } = page.template;

  const advancedStyle: Partial<CSSStyleDeclaration> = {
    marginLeft: newPosition.css.marginLeft,
    marginRight: newPosition.css.marginRight,
    marginTop: newPosition.css.marginTop,
    marginBottom: newPosition.css.marginBottom,
  };
  const advancedContainerStyle: Partial<CSSStyleDeclaration> = {};

  const teaserItem = Layouts.teaser.find((item) => item.key === templateName);
  const isCentered = templateName?.includes('centered');

  if (migrate) {
    updatedPage = generateTeaserLayout(
      page,
      teaserItem!,
      page?.appearance.size as 'sm' | 'md' | 'lg',
      true,
      newPosition.value,
      closeButton,
    );
  }

  if (
    newPosition.value === 'center-left' ||
    newPosition.value === 'center-right'
  ) {
    const teaserItem = Layouts.teaser.find(
      (item) => item.key === `${templateName}${isCentered ? '' : '-centered'}`,
    );

    // if there is a special layout for centered change layout
    if (teaserItem) {
      if (newPosition.value === 'center-left') advancedStyle.marginLeft = '0px';
      if (newPosition.value === 'center-right')
        advancedStyle.marginRight = '0px';

      updatedPage = generateTeaserLayout(
        page,
        teaserItem!,
        page?.appearance.size as 'sm' | 'md' | 'lg',
        true,
        newPosition.value,
      );
    }
  } else if (templateName?.includes('centered')) {
    const teaserItem = Layouts.teaser.find(
      (item) => item.key === templateName.replace('-centered', ''),
    );
    updatedPage = generateTeaserLayout(
      page,
      teaserItem!,
      page?.appearance.size as 'sm' | 'md' | 'lg',
      true,
      newPosition.value,
    );
  }

  const updatedContent = updatedPage.content;

  if (templateName === 'teaser-corner-image') {
    if (newPosition.value === 'top-left') {
      advancedContainerStyle.transform = 'rotate(90deg)';
      advancedStyle.marginTop = '0px';
      advancedStyle.marginLeft = '0px';
    } else if (newPosition.value === 'top-right') {
      advancedContainerStyle.transform = 'rotate(180deg)';
      advancedStyle.marginTop = '0px';
      advancedStyle.marginRight = '0px';
    } else if (newPosition.value === 'bottom-right') {
      advancedContainerStyle.transform = 'rotate(270deg)';
      advancedStyle.marginBottom = '0px';
      advancedStyle.marginRight = '0px';
    } else if (newPosition.value === 'bottom-left') {
      advancedContainerStyle.transform = 'rotate(0deg)';
      advancedStyle.marginBottom = '0px';
      advancedStyle.marginLeft = '0px';
    }

    for (const deviceType in updatedContent) {
      const node = updatedContent[deviceType as DeviceTypes]
        .childNodes[0] as Node;

      const teaserContainerStyles = {
        ...node.attributes.style,
        ...advancedContainerStyle,
      };
      node.attributes.style = teaserContainerStyles;
    }
  }

  for (const deviceType in updatedContent) {
    const styles = {
      ...updatedContent?.[deviceType as DeviceTypes].attributes.style,
      ...newPosition.css,
      ...advancedStyle,
    };

    updatedContent[deviceType as DeviceTypes].attributes.style = styles;
  }

  if (closeButton) {
    updatedPage = changeTeaserCloseButton(
      updatedPage,
      true,
      newPosition.value,
      closeButton,
    );
  }

  updatedPage.appearance.position = newPosition.value;
  return updatedPage;
};

export const generateTeaserCloseButton = (
  teaserTemplateName: string,
  location: string,
  toSize: 'sm' | 'md' | 'lg',
  closeButton?: Node,
) => {
  let node = cloneDeep(ElementInitialValues['close-button']) as Node;

  if (
    teaserTemplateName !== 'teaser-corner-image' &&
    teaserTemplateName !== 'teaser-circle-text' &&
    teaserTemplateName !== 'teaser-circle-image' &&
    !teaserTemplateName?.includes('-centered')
  ) {
    node.attributes.style.marginTop = '-11.5px';
    node.attributes.style.marginLeft = '0px';
    node.attributes.style.marginRight = '-11.5px';
    node.attributes.style.marginBottom = '0px';
    node.attributes.style.right = '0px';
  } else if (teaserTemplateName === 'teaser-corner-image') {
    node.attributes.style.right = '0px';
    if (location === 'top-left') {
      node.attributes.style.right = 'unset';
      node.attributes.style.marginTop = `63.5px`;
      node.attributes.style.marginLeft = '63.5px';
      node.attributes.style.marginRight = '0px';
      node.attributes.style.marginBottom = '0px';
    } else if (location === 'top-right') {
      node.attributes.style.marginTop = `63.5px`;
      node.attributes.style.marginLeft = '0px';
      node.attributes.style.marginRight = '63.5px';
      node.attributes.style.marginBottom = '0px';
    } else if (location === 'bottom-right') {
      node.attributes.style.marginTop = `63.5px`;
      node.attributes.style.marginLeft = '0px';
      node.attributes.style.marginRight = '63.5px';
      node.attributes.style.marginBottom = '0px';
    } else if (location === 'bottom-left') {
      node.attributes.style.right = 'unset';
      node.attributes.style.marginTop = `63.5px`;
      node.attributes.style.marginLeft = `63.5px`;
      node.attributes.style.marginRight = `0px`;
      node.attributes.style.marginBottom = '0px';
    }
  } else if (
    teaserTemplateName === 'teaser-circle-text' ||
    teaserTemplateName === 'teaser-circle-image'
  ) {
    node.attributes.style.right = '0px';
    node.attributes.style.marginTop = '8.5px';
    node.attributes.style.marginLeft = '0px';
    node.attributes.style.marginRight = '8.5px';
    node.attributes.style.marginBottom = '0px';
  } else if (teaserTemplateName?.includes('centered')) {
    if (location === 'center-left') {
      if (teaserTemplateName === 'teaser-only-image-centered') {
        node.attributes.style.right = '0px';
        node.attributes.style.marginTop = `-11px`;
        node.attributes.style.marginLeft = '0px';
        node.attributes.style.marginRight = `-19.4px`;
        node.attributes.style.marginBottom = '0px';
      } else {
        node.attributes.style.right = '0px';
        node.attributes.style.marginTop = `-11.5px`;
        node.attributes.style.marginLeft = '0px';
        node.attributes.style.marginRight = `-11.5px`;
        node.attributes.style.marginBottom = '0px';
      }
    }
    if (location === 'center-right') {
      if (teaserTemplateName === 'teaser-only-image-centered') {
        node.attributes.style.right = 'unset';
        node.attributes.style.marginTop = `-11px`;
        node.attributes.style.marginLeft = `-19.4px`;
        node.attributes.style.marginRight = '0px';
        node.attributes.style.marginBottom = '0px';
      } else {
        node.attributes.style.right = 'unset';
        node.attributes.style.marginTop = `-11.5px`;
        node.attributes.style.marginLeft = `-11.5px`;
        node.attributes.style.marginRight = '0px';
        node.attributes.style.marginBottom = '0px';
      }
    }
  }
  node.attributes.isTeaserElement = true;
  node.attributes.showOnHover = closeButton?.attributes?.showOnHover || false;
  node = resizePopupLayoutNode({
    node,
    currentSize: 'md',
    toSize,
  });
  return node;
};

const changeTeaserCloseButton = (
  page: Content,
  value: boolean,
  location: string,
  closeButton?: Node,
  teaserTemplateName?: string,
) => {
  let updatedPage = cloneDeep(page);
  const activeTemplateName = teaserTemplateName || page?.template.name;
  const activeLocation = location || page?.appearance.position;

  // if we update based on position we should clear previous one
  if (closeButton) {
    for (const deviceType in updatedPage?.content) {
      const childNodes =
        updatedPage.content[deviceType as DeviceTypes].childNodes;
      // to remove duplicated teaser close buttons
      for (let i = 0; i < childNodes.length; i++) {
        if ((childNodes[i] as Node).nodeName === 'close-button') {
          updatedPage.content[deviceType as DeviceTypes] = removeProperties({
            theObject: updatedPage.content[deviceType as DeviceTypes],
            id: (childNodes[i] as Node)?.id,
          });
        }
      }
    }
  }
  if (value) {
    const node = generateTeaserCloseButton(
      activeTemplateName!,
      activeLocation,
      updatedPage?.appearance.size as 'sm' | 'md' | 'lg',
      closeButton,
    );
    for (const deviceType in updatedPage?.content) {
      const element = getObject(
        updatedPage.content[deviceType as DeviceTypes],
        'teaser',
      );
      element.childNodes.splice(999, 0, node);
    }
  }

  return updatedPage;
};
