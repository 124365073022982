import React, { CSSProperties, FC } from 'react';
import cn from 'classnames';

import styles from './Tooltip.module.scss';
import { ColorPalette, TooltipProps } from './types';

const Tooltip: FC<TooltipProps> = ({
  style,
  content,
  children,
  className,
  onClick,
  contentClassName,
  contentInnerClassName,
  contentStyle = {},
  status = true,
  contentButton,
  contentTitle,
  contentImage,
  colorPalette = { textColor: 'white', backgroundColor: 'black' },
  keepTooltipOnMouseOutSideRight = false,
  keepTooltip = false,
  singleLineView = false,
}) => {
  if (!status) return <>{children}</>;

  const colorPaletteCSSVariables: CSSProperties = Object.assign(
    {},
    ...Object.keys(colorPalette).map((key) => ({
      [`--${key}`]: colorPalette[key as keyof ColorPalette],
    })),
  );

  return (
    <div
      className={cn(styles.tooltip, className, {
        [styles.keepTooltip]: keepTooltip,
      })}
      style={{
        ...contentStyle,
        ...colorPaletteCSSVariables,
      }}
      onClick={onClick}
    >
      {children}
      {content && (
        <div
          className={cn(contentClassName, {
            [styles.tooltipContainer]: !singleLineView,
            [styles.tooltipContainer__singleLineView]: singleLineView,
          })}
          style={style}
        >
          {keepTooltipOnMouseOutSideRight && (
            <div className={cn(styles.tranparentDivider, styles.right)} />
          )}{' '}
          {keepTooltip && (
            <div className={cn(styles.tranparentDivider, styles.top)} />
          )}
          {contentImage && <div>{contentImage}</div>}
          <div className={cn(styles.contentInner, contentInnerClassName)}>
            {contentTitle && (
              <div className={styles.contentTitle}>{contentTitle}</div>
            )}
            {singleLineView ? (
              <span className={cn(styles.singleLineViewName, contentClassName)}>
                {content}
              </span>
            ) : (
              content
            )}

            {contentButton && (
              <button
                className={styles.contentButton}
                onClick={() => contentButton.onClick()}
              >
                {contentButton.text}
              </button>
            )}
          </div>
          {keepTooltip && (
            <div className={cn(styles.tranparentDivider, styles.bottom)} />
          )}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
