import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';

import {
  GET_SUBSCRIBERS,
  GET_SUBSCRIBERS_SUCCESS,
  GET_SUBSCRIBERS_FAILURE,
  ENRICH_SUBSCRIBERS,
  ENRICH_SUBSCRIBERS_SUCCESS,
  ENRICH_SUBSCRIBERS_FAILURE,
  CLEAN_SUBSCRIBERS,
} from './constants';

export type SubscribeItem = {
  Browser: string;
  CityName: string;
  CompanyCountry: string | null;
  CompanyCurrentEmployeeEstimate: number | null;
  CompanyFoundedYear: string | number | null;
  CompanyIndustry: string | null;
  CompanyLinkedInURL: string | null;
  CompanyLocality: string | null;
  CompanyName: string | null;
  CompanySizeRange: string | null;
  CompanyTotalEmployeeEstimate: number | null;
  CountryCode: string | null;
  CountryName: string | null;
  CreatedAt: string | null;
  Date: string | null;
  Device: string | null;
  Domain: string | null;
  FormData_X55IB_rating: string | null;
  FormData_date: string | null;
  Id: string;
  Language: string | null;
  Latitude: number | null;
  Longitude: number | null;
  OS: string | null;
  Page: string | null;
  Referer: string | null;
  RegionCode: string | null;
  RegionName: string | null;
  Resolution: string | null;
  URL: string | null;
  UTMCampaign: string | null;
  UTMContent: string | null;
  UTMMedium: string | null;
  UTMSource: string | null;
  [key: string]: string | null | number;
};

export interface SubscribersState {
  items: Array<SubscribeItem>;
  totalCount: number;
  columns: string[];
  defaultSelectedColumns: string[];
  status: string | { status?: string; message?: string; type?: string };
}

const initialState: SubscribersState = {
  items: [],
  totalCount: 0,
  columns: [],
  defaultSelectedColumns: [
    'id',
    'browser',
    'device',
    'country',
    'city',
    'date',
    'unsubscribed',
  ],
  status: 'initialized',
};

export default function reducer(
  state = initialState,
  action: ReduxAction,
): SubscribersState {
  switch (action.type) {
    case GET_SUBSCRIBERS:
    case ENRICH_SUBSCRIBERS:
      return { ...state, status: 'loading' };

    case GET_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        items: action.payload.campaignFormDataList,
        totalCount: action.payload.rowCount,
        columns: action.payload.columns,
        defaultSelectedColumns: action.payload.defaultSelectedColumns,
        status: 'success',
      };
    case ENRICH_SUBSCRIBERS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        status:
          payload > 0
            ? {
                status: 'success',
                message:
                  'Enrichment successful, now the we will refresh the page',
                type: 'enrich_success',
              }
            : {
                status: 'success',
                message: '0 data affected',
                type: 'enrich_neutral',
              },
      };
    }

    case GET_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        status: 'errored',
      };

    case ENRICH_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case RESET_STORE:
      return initialState;

    case CLEAN_SUBSCRIBERS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export const selectSubscribersData = (state: {
  subscribers: SubscribersState;
}) => state.subscribers;
