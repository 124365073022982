import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import store from './store';

const config: AxiosRequestConfig<any> = {
  responseType: 'json',
  timeout: 0,
  validateStatus: (status) => status >= 200 && status < 300, // default
};

const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  const auth = store.get('user');
  const token: string | null = auth?.token || null;
  config.headers = {
    ...config.headers,
    Accept: 'application/json',
  };

  if (token && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const responseInterceptor = ({
  data,
  config,
}: AxiosResponse<any, any>): any => {
  if (data.errors?.length > 0) {
    const errorIndex = data.errors?.findIndex(
      (error: { message: string }) =>
        error.message ===
        'Access denied! You need to be authorized to perform this action!',
    );
    if (errorIndex >= 0) {
      unauthorizedInterceptor(data.errors[errorIndex], config);
      return;
    }
    const errorMessage = data.errors[0].message;
    toast.error(errorMessage);
    throw new Error(errorMessage);
  }
  return data;
};

const instance = axios.create({
  ...config,
  baseURL: '/api/graphql',
});

instance.interceptors.request.use(requestInterceptor, (error) => {
  //console.warn('request error', error)
  throw new Error(error);
});
let isRefreshing = false;
let subscribers: any[] = [];

function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb);
}

function onRefreshed(token: string) {
  subscribers.map((cb) => cb(token));
}

async function unauthorizedInterceptor(
  error: { message: string },
  config: any,
) {
  const errorMessage = error.message;
  const headers = config.headers;
  const auth = store.get('user');
  const token = auth?.token;
  if (!token && !isRefreshing && !headers?.toasterDisabled) {
    toast.error(errorMessage);
    throw new Error(errorMessage);
  }

  const refreshToken = auth?.refreshToken;
  const originalRequest = config;
  if (token) {
    if (!isRefreshing) {
      try {
        isRefreshing = true;
        const baseUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
        const result = await fetch(`${baseUrl}/api/graphql`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            operationName: 'RefreshToken',
            query: `mutation RefreshToken($refreshToken: String!, $accessToken: String!) {
              refreshToken(refreshToken: $refreshToken, accessToken: $accessToken) {
                accessToken
                refreshToken
              }
            }`,
            variables: {
              token,
              refreshToken,
            },
          }),
        });
        const res = await result.json();
        isRefreshing = false;
        onRefreshed(res.data.token);
        originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
        const newAuth = { ...auth, ...res.data };
        store.set('user', newAuth);
        subscribers = [];
        return Promise.resolve(instance(originalRequest));
      } catch (error) {
        store.clearTemps();
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }
  }

  return new Promise((resolve) => {
    subscribeTokenRefresh((token: string) => {
      originalRequest.headers.Authorization = `Bearer ${token}`;
      resolve(instance(originalRequest));
    });
  });
}

instance.interceptors.response.use(responseInterceptor);

export default instance;

// just export like default instance for microservices
export const cdn = axios.create({
  ...config,
  baseURL: process.env.NEXT_PUBLIC_CDN_URL,
});
