export const sendloopServiceURl = 'api/integrations/sendloop';

export const SENDLOOP_GET_CONNECTIONS =
  'builder/integration/SENDLOOP_GET_CONNECTIONS';
export const SENDLOOP_GET_CONNECTIONS_SUCCESS =
  'builder/integration/SENDLOOP_GET_CONNECTIONS_SUCCESS';
export const SENDLOOP_GET_CONNECTIONS_FAILURE =
  'builder/integration/SENDLOOP_GET_CONNECTIONS_FAILURE';

export const SENDLOOP_GET_FIELDS = 'builder/integration/SENDLOOP_GET_FIELDS';
export const SENDLOOP_GET_FIELDS_SUCCESS =
  'builder/integration/SENDLOOP_GET_FIELDS_SUCCESS';
export const SENDLOOP_GET_FIELDS_FAILURE =
  'builder/integration/SENDLOOP_GET_FIELDS_FAILURE';

export const SENDLOOP_GET_LIST = 'builder/integration/SENDLOOP_GET_LIST';
export const SENDLOOP_GET_LIST_SUCCESS =
  'builder/integration/SENDLOOP_GET_LIST_SUCCESS';
export const SENDLOOP_GET_LIST_FAILURE =
  'builder/integration/SENDLOOP_GET_LIST_FAILURE';

export const SENDLOOP_SAVE = 'builder/integration/SENDLOOP_SAVE';
export const SENDLOOP_SAVE_SUCCESS =
  'builder/integration/SENDLOOP_SAVE_SUCCESS';
export const SENDLOOP_SAVE_FAILURE =
  'builder/integration/SENDLOOP_SAVE_FAILURE';

export const SENDLOOP_DELETE = 'builder/integration/SENDLOOP_DELETE';
export const SENDLOOP_DELETE_SUCCESS =
  'builder/integration/SENDLOOP_DELETE_SUCCESS';
export const SENDLOOP_DELETE_FAILURE =
  'builder/integration/SENDLOOP_DELETE_FAILURE';

export const SENDLOOP_CHANGESTATUS =
  'builder/integration/SENDLOOP_CHANGESTATUS';
export const SENDLOOP_CHANGESTATUS_SUCCESS =
  'builder/integration/SENDLOOP_CHANGESTATUS_SUCCESS';
export const SENDLOOP_CHANGESTATUS_FAILURE =
  'builder/integration/SENDLOOP_CHANGESTATUS_FAILURE';

export const SENDLOOP_TEST = 'builder/integration/SENDLOOP_TEST';
export const SENDLOOP_TEST_SUCCESS =
  'builder/integration/SENDLOOP_TEST_SUCCESS';
export const SENDLOOP_TEST_FAILURE =
  'builder/integration/SENDLOOP_TEST_FAILURE';

export const SENDLOOP_CLEAR_TEST_RESULT =
  'builder/integration/SENDLOOP_CLEAR_TEST_RESULT';
export const SENDLOOP_CLEAR_STATE = 'builder/integration/SENDLOOP_CLEAR_STATE';
