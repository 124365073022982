import type { ContainerProps } from './types';
import React, { FC } from 'react';

import styles from './Container.module.scss';

const Container: FC<ContainerProps> = ({ children }) => {
  return (
    <div data-testid="container" className={styles.container}>
      {children}
    </div>
  );
};

export default Container;
