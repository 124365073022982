import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import fetch from '@utils/fetch';
import {
  ANALYTICS_GET_FAILURE,
  BROWSERS_ANALYTICS_GET,
  BROWSERS_ANALYTICS_GET_SUCCESS,
  DEVICES_ANALYTICS_GET,
  DEVICES_ANALYTICS_GET_SUCCESS,
  RESOLUTIONS_ANALYTICS_GET,
  RESOLUTIONS_ANALYTICS_GET_SUCCESS,
  CLEAR_ANALYTICS_CAMPAIGN,
  CLEAR_ANALYTICS_CAMPAIGN_SUCCESS,
} from './constants';

import { ShortAnalytics, Browser } from './reducer';

function* handleGetDevicesAnalytics({ payload }: ReduxAction) {
  try {
    const data: ShortAnalytics = yield call(
      fetch,
      `/api/Statistic/get-devices-analytics`,
      {
        method: 'POST',
        data: { ...payload },
      },
    );
    yield put({ type: DEVICES_ANALYTICS_GET_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ANALYTICS_GET_FAILURE });
  }
}

function* handleGetResolutionsAnalytics({ payload }: ReduxAction) {
  try {
    const data: ShortAnalytics = yield call(
      fetch,
      `/api/Statistic/get-resolutions-analytics`,
      {
        method: 'POST',
        data: { ...payload },
      },
    );
    yield put({ type: RESOLUTIONS_ANALYTICS_GET_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ANALYTICS_GET_FAILURE });
  }
}

function* handleGetBrowsersAnalytics({ payload }: ReduxAction) {
  try {
    const data: Browser[] = yield call(
      fetch,
      `/api/Statistic/get-browsers-analytics`,
      {
        method: 'POST',
        data: { ...payload },
      },
    );
    yield put({ type: BROWSERS_ANALYTICS_GET_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ANALYTICS_GET_FAILURE });
  }
}

function* handleClearAnalyticsCampaign() {
  try {
    yield put({ type: CLEAR_ANALYTICS_CAMPAIGN_SUCCESS });
  } catch (error) {
    yield put({ type: ANALYTICS_GET_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(DEVICES_ANALYTICS_GET, handleGetDevicesAnalytics);
  yield takeLatest(RESOLUTIONS_ANALYTICS_GET, handleGetResolutionsAnalytics);
  yield takeLatest(BROWSERS_ANALYTICS_GET, handleGetBrowsersAnalytics);
  yield takeLatest(CLEAR_ANALYTICS_CAMPAIGN, handleClearAnalyticsCampaign);
}
