export const activeCampaignServiceURl = 'api/integrations/activeCampaign';

export const ACTIVECAMPAIGN_GET_CONNECTIONS =
  'builder/integrations/ACTIVECAMPAIGN_GET_CONNECTIONS';
export const ACTIVECAMPAIGN_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/ACTIVECAMPAIGN_GET_CONNECTIONS_SUCCESS';
export const ACTIVECAMPAIGN_GET_CONNECTIONS_FAILURE =
  'builder/integrations/ACTIVECAMPAIGN_GET_CONNECTIONS_FAILURE';

export const ACTIVECAMPAIGN_GET_FIELDS =
  'builder/integrations/ACTIVECAMPAIGN_GET_FIELDS';
export const ACTIVECAMPAIGN_GET_FIELDS_SUCCESS =
  'builder/integrations/ACTIVECAMPAIGN_GET_FIELDS_SUCCESS';
export const ACTIVECAMPAIGN_GET_FIELDS_FAILURE =
  'builder/integrations/ACTIVECAMPAIGN_GET_FIELDS_FAILURE';

export const ACTIVECAMPAIGN_GET_LIST =
  'builder/integrations/ACTIVECAMPAIGN_GET_LIST';
export const ACTIVECAMPAIGN_GET_LIST_SUCCESS =
  'builder/integrations/ACTIVECAMPAIGN_GET_LIST_SUCCESS';
export const ACTIVECAMPAIGN_GET_LIST_FAILURE =
  'builder/integrations/ACTIVECAMPAIGN_GET_LIST_FAILURE';

export const ACTIVECAMPAIGN_SAVE = 'builder/integrations/ACTIVECAMPAIGN_SAVE';
export const ACTIVECAMPAIGN_SAVE_SUCCESS =
  'builder/integrations/ACTIVECAMPAIGN_SAVE_SUCCESS';
export const ACTIVECAMPAIGN_SAVE_FAILURE =
  'builder/integrations/ACTIVECAMPAIGN_SAVE_FAILURE';

export const ACTIVECAMPAIGN_DELETE =
  'builder/integrations/ACTIVECAMPAIGN_DELETE';
export const ACTIVECAMPAIGN_DELETE_SUCCESS =
  'builder/integrations/ACTIVECAMPAIGN_DELETE_SUCCESS';
export const ACTIVECAMPAIGN_DELETE_FAILURE =
  'builder/integrations/ACTIVECAMPAIGN_DELETE_FAILURE';

export const ACTIVECAMPAIGN_CHANGESTATUS =
  'builder/integrations/ACTIVECAMPAIGN_CHANGESTATUS';
export const ACTIVECAMPAIGN_CHANGESTATUS_SUCCESS =
  'builder/integrations/ACTIVECAMPAIGN_CHANGESTATUS_SUCCESS';
export const ACTIVECAMPAIGN_CHANGESTATUS_FAILURE =
  'builder/integrations/ACTIVECAMPAIGN_CHANGESTATUS_FAILURE';
export const ACTIVECAMPAIGN_CLEAR_STATE =
  'builder/integrations/ACTIVECAMPAIGN_CLEAR_STATE';
