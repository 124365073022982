import { Option } from '@elements/PSForm';

type SmartTagOptionType = Option & {
  fallback?: string;
};

export const systemFields: SmartTagOptionType[] = [
  { value: 'ip', label: 'IP', fallback: '1111.1111.1111.1111' },
  { value: 'browser', label: 'Browser', fallback: 'chrome' },
  { value: 'device', label: 'Device', fallback: 'mobile' },
  { value: 'language', label: 'Language', fallback: 'english' },
  { value: 'os', label: 'OS', fallback: 'macos' },
  { value: 'city', label: 'City', fallback: 'istanbul' },
  { value: 'country', label: 'Country', fallback: 'Turkey' },
  { value: 'countryCode', label: 'CountryCode', fallback: '+90' },
  { value: 'region', label: 'Region', fallback: 'Northeast' },
  { value: 'regionCode', label: 'RegionCode', fallback: '24' },
  { value: 'longitude', label: 'Longitude', fallback: '35.9025' },
  { value: 'latitude', label: 'Latitude', fallback: '25.90902' },
  { value: 'date', label: 'Date', fallback: '14/03/2020' },
  { value: 'referer', label: 'Referer' },
  { value: 'utm_campaign', label: 'UTM Campaign' },
  { value: 'utm_content', label: 'UTM Content' },
  { value: 'utm_medium', label: 'UTM Medium' },
  { value: 'utm_source', label: 'UTM Source' },
];

export const shopifyCustomerFields: SmartTagOptionType[] = [
  { value: 'userId', label: 'User Id' },
  {
    value: 'customerEmail',
    label: 'Customer Email',
    fallback: 'customer@gmail.com',
  },
  { value: 'currency', label: 'Currency' },
  { value: 'customer.firstName', label: 'Customer Firstname' },
  { value: 'customer.lastName', label: 'Customer Lastname' },
  { value: 'customer.address1', label: 'Customer Address1' },
  { value: 'customer.address2', label: 'Customer Address2' },
  { value: 'customer.street', label: 'Customer Street' },
  { value: 'customer.city', label: 'Customer City' },
  { value: 'customer.province', label: 'Customer Province' },
  { value: 'customer.zip', label: 'Customer Zip' },
  { value: 'customer.country', label: 'Customer Country' },
  /*   { value: 'customer.loggedIn', label: 'Customer  loggedIn' },*/
  { value: 'customer.orders_count', label: 'Customer Orders Count' },
  { value: 'customer.totalSpent', label: 'Customer Total Spent' },
  { value: 'customer.phone', label: 'Customer Phone' },
  { value: 'customer.search', label: 'Customer Search' },
  /* { value: 'customerType', label: 'Customer Type' }, */
  {
    value: 'customer.last_order.created_at',
    label: 'Customer Last Order Created At',
  },
];

export const shopifyProductFields: SmartTagOptionType[] = [
  { value: 'product.name', label: 'Product Name' },
  { value: 'product.price', label: 'Product Price' },
  { value: 'product.sku', label: 'Product SKU' },
  { value: 'product.vendor', label: 'Product vendor' },
  {
    value: 'product.total_inventory_quantity',
    label: 'Product Total Inventory Quantity',
  },
];

export const shopifyCollectionFields: SmartTagOptionType[] = [
  { value: 'collection.title', label: 'Collection Title' },
];

export const shopifyAddToCartActionFields: SmartTagOptionType[] = [
  { value: 'addToCart[products.id]', label: 'Add To Cart Products Id' },
  { value: 'addToCart[products.sku]', label: 'Add To Cart Products Sku' },
  {
    value: 'addToCart[products.variantId]',
    label: 'Add To Cart Products Variant Id',
  },
  {
    value: 'addToCart[products.productType]',
    label: 'Add To Cart Products Product Type',
  },
  { value: 'addToCart[products.name]', label: 'Add To Cart Products Name' },
  { value: 'addToCart[products.price]', label: 'Add To Cart Products Price' },
  {
    value: 'addToCart[products.imageURL]',
    label: 'Add To Cart Products Image URL',
  },
  {
    value: 'addToCart[products.productURL]',
    label: 'Add To Cart Products Product URL',
  },
  { value: 'addToCart[products.brand]', label: 'Add To Cart Products Brand' },
];

export const shopifyTransactionFields: SmartTagOptionType[] = [
  { value: 'transactionNumber', label: 'Transaction Number' },
  { value: 'transactionId', label: 'Transaction Id' },
  { value: 'transactionAffiliation', label: 'Transaction Affiliation' },
  { value: 'transactionTotal', label: 'Transaction Total' },
  { value: 'transactionTax', label: 'Transaction Tax' },
  { value: 'transactionShipping', label: 'Transaction Shipping' },
  { value: 'transactionSubtotal', label: 'Transaction Subtotal' },
  { value: 'transaction[products.id]', label: 'Transaction Products Id' },
  { value: 'transaction[products.sku]', label: 'Transaction Products Sku' },
  {
    value: 'transaction[products.variantId]',
    label: 'Transaction Products Variant Id',
  },
  { value: 'transaction[products.name]', label: 'Transaction Products Name' },
  {
    value: 'transaction[products.productType]',
    label: 'Transaction Products Product Type',
  },
  { value: 'transaction[products.price]', label: 'Transaction Products Price' },
  {
    value: 'transaction[products.quantity]',
    label: 'Transaction Products Quantity',
  },
  {
    value: 'transaction[products.description]',
    label: 'Transaction Products Description',
  },
  {
    value: 'transaction[products.imageURL]',
    label: 'Transaction Products Image URL',
  },
  {
    value: 'transaction[products.productURL]',
    label: 'Transaction Products Product URL',
  },
];

export const containerNodeNameList = ['block', 'form', 'div'];
export const formNodeNameList = [
  'email-input',
  'text-input',
  'full-name-input',
  'long-input',
  'address-input',
  'radio-input',
  'multi-choice',
  'dropdown-input',
  'date-input',
  'phone-input',
  'yes-no',
  'opinion-scale',
  'rating',
  'notice-and-consent',
  'hidden-input',
  'address-input',
  'full-name-input',
];
