import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  SENDINBLUE_GET_FIELDS,
  SENDINBLUE_GET_FIELDS_SUCCESS,
  SENDINBLUE_GET_FIELDS_FAILURE,
  SENDINBLUE_GET_CONNECTIONS,
  SENDINBLUE_GET_CONNECTIONS_SUCCESS,
  SENDINBLUE_GET_CONNECTIONS_FAILURE,
  SENDINBLUE_GET_LIST,
  SENDINBLUE_GET_LIST_SUCCESS,
  SENDINBLUE_GET_LIST_FAILURE,
  SENDINBLUE_SAVE,
  SENDINBLUE_SAVE_SUCCESS,
  SENDINBLUE_SAVE_FAILURE,
  SENDINBLUE_DELETE,
  SENDINBLUE_DELETE_FAILURE,
  SENDINBLUE_CLEAR_STATE,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import graphqlFetch from '@utils/graphqlFetch';
import {
  CreateBrevo,
  DeleteBrevoIntegrationCampaign,
  GetBrevoFields,
  GetBrevoIntegrationsByAccountId,
  GetBrevoIntegrationsByCampaignId,
  GetBrevoLists,
  UpdateBrevo,
} from './graphql';
import { BrevoIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/BrevoResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getBrevoIntegrationsByAccountId: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetBrevoIntegrationsByAccountId(),
    });
    yield put({
      type: SENDINBLUE_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getBrevoIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: SENDINBLUE_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;
  try {
    const result: {
      data: { getBrevoFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetBrevoFields(apiKey),
    });
    onSuccess && onSuccess();
    yield put({
      type: SENDINBLUE_GET_FIELDS_SUCCESS,
      payload: result.data.getBrevoFields,
    });
  } catch (error) {
    yield put({ type: SENDINBLUE_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { onSuccess } = action.payload;
  try {
    const result: {
      data: { getBrevoLists: ApiListObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetBrevoLists(action.payload.apiKey),
    });
    onSuccess && onSuccess();
    yield put({
      type: SENDINBLUE_GET_LIST_SUCCESS,
      payload: result.data.getBrevoLists,
    });
  } catch (error) {
    yield put({ type: SENDINBLUE_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId } =
    action.payload;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (listSettings?.id) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateBrevo(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateBrevo({ ...formData, status: true }),
      });
    }

    const integrations: {
      data: {
        getBrevoIntegrationsByCampaignId: BrevoIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetBrevoIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getBrevoIntegrationsByCampaignId,
        type: IntegrationEnums.sendinBlue,
      }),
    );
    yield put({ type: SENDINBLUE_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: SENDINBLUE_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteBrevoIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.sendinBlue,
      }),
    );
    yield put({ type: SENDINBLUE_CLEAR_STATE });
  } catch (error) {
    yield put({ type: SENDINBLUE_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(SENDINBLUE_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(SENDINBLUE_GET_FIELDS, handleGetFields);
  yield takeLatest(SENDINBLUE_GET_LIST, handleGetList);
  yield takeLatest(SENDINBLUE_SAVE, handleSave);
  yield takeLatest(SENDINBLUE_DELETE, handleDelete);
}
