import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import PSButton from '@elements/PSButton';
import PSForm, { PSFormField, PSTextbox } from '@elements/PSForm';
import styles from './AddDomainModal.module.scss';
import type { AddDomainModalFormValues, AddDomainModalProps } from './types';
import {
  addWebsiteFailure,
  addWebsiteSuccess,
} from '@connectors/websites/actions';
import { hideModal as _hideModal } from '@connectors/modal/actions';
import { domainNormalizer } from '../../../../../../../packages/core/helpers/normalize';
import { selectDomain } from '@connectors/builder/settings/actions';
import { makeSelectModals } from '@connectors/modal/selectors';
import {
  useAddDomainRelationMutation,
  useCreateWebsiteMutation,
} from '@generated/graphql';
import { domainRegex } from 'core/helpers/domainRegex';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  website: yup
    .string()
    .matches(domainRegex, 'Please enter correct url')
    .required()
    .label('Website URL'),
});

const AddDomainModal: FC<AddDomainModalProps> = ({
  onClose,
  onSave,
  campaignId,
  hideCancelButton,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createWebsite, { loading: createWebsiteLoading }] =
    useCreateWebsiteMutation();

  const modalState = useSelector(makeSelectModals);

  const methods = useForm<AddDomainModalFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { website: '' },
  });

  const hideModal = () => {
    dispatch(_hideModal('addNewWebsite'));
  };
  const [addDomainRelation, { loading: addRelationLoading }] =
    useAddDomainRelationMutation();

  const onClickCancelButton = () => {
    onClose && onClose();
    hideModal();
    (modalState[0]?.data?.onCanceledAddDomainModal as () => {})?.();
    methods.reset();
  };

  const handleAddDomainRelation = (domainId: number) => {
    addDomainRelation({
      variables: {
        campaignId: Number(campaignId),
        domainId,
      },
      onCompleted: (res) => {
        dispatch(selectDomain(res?.addDomainRelation!));
        dispatch(_hideModal('addWebsite'));
        (modalState[0]?.data?.onAddedDomain as () => {})?.();
        methods.reset();
        onClose && onClose();
        hideModal();
      },
      onError: (err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 10000,
        });
      },
    });
  };

  const addWebsiteHandler = ({ website }: AddDomainModalFormValues) => {
    createWebsite({
      variables: {
        website,
      },
      onCompleted: (res) => {
        dispatch(addWebsiteSuccess(res.createWebsite));
        if (campaignId) {
          handleAddDomainRelation(res.createWebsite.id);
        } else {
          methods.reset();
          onClose && onClose();
          hideModal();
        }
      },
      onError: (err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 10000,
        });
        dispatch(addWebsiteFailure());
      },
    });

    onSave && onSave();
  };

  const isLoadingSaveButton = createWebsiteLoading || addRelationLoading;

  const isSubmitButtonDisabled = !methods.formState.isDirty;
  return (
    <div className={styles.addDomainModal}>
      <div className={styles.header}>
        {t('sections:add-domain-modal:title')}
      </div>
      <PSForm
        onSubmit={methods.handleSubmit(addWebsiteHandler)}
        className={styles.formContainer}
      >
        <PSFormField
          testid="add-website-modal-text-input"
          control={methods.control}
          component={PSTextbox}
          name="website"
          label={t('sections:add-domain-modal:urlLabel')}
          placeholder={t('sections:add-domain-modal:urlPlaceholder')}
          normalize={domainNormalizer}
        />
        <div className={styles.buttonContainer}>
          {!hideCancelButton && (
            <PSButton
              type="button"
              size="sm"
              variant="second"
              onClick={onClickCancelButton}
              id="add-website-modal-cancel-button"
            >
              {t('common:cancel')}
            </PSButton>
          )}

          <PSButton
            type="submit"
            size="sm"
            disabled={isSubmitButtonDisabled}
            loading={isLoadingSaveButton}
            id="add-website-modal-save-button"
          >
            {t('common:save')}
          </PSButton>
        </div>
      </PSForm>
    </div>
  );
};

export default AddDomainModal;
