import {
  KLAVIYO_CHANGESTATUS,
  KLAVIYO_CHANGESTATUS_FAILURE,
  KLAVIYO_CHANGESTATUS_SUCCESS,
  KLAVIYO_CLEAR_STATE,
  KLAVIYO_DELETE,
  KLAVIYO_DELETE_FAILURE,
  KLAVIYO_DELETE_SUCCESS,
  KLAVIYO_GET_CONNECTIONS,
  KLAVIYO_GET_CONNECTIONS_FAILURE,
  KLAVIYO_GET_CONNECTIONS_SUCCESS,
  KLAVIYO_GET_FIELDS,
  KLAVIYO_GET_FIELDS_FAILURE,
  KLAVIYO_GET_FIELDS_SUCCESS,
  KLAVIYO_GET_LIST,
  KLAVIYO_GET_LIST_FAILURE,
  KLAVIYO_GET_LIST_SUCCESS,
  KLAVIYO_SAVE,
  KLAVIYO_SAVE_FAILURE,
  KLAVIYO_SAVE_SUCCESS,
} from './constants';
import {
  KlaviyoConnectionsOutput,
  KlaviyoDeleteInput,
  KlaviyoFieldsOutput,
  KlaviyoListsInput,
  KlaviyoListsOutput,
  KlaviyoSaveInput,
} from './type';

export function getKlaviyoConnections() {
  return {
    type: KLAVIYO_GET_CONNECTIONS,
  };
}
export function getKlaviyoConnectionsSuccess(
  payload: KlaviyoConnectionsOutput,
) {
  return {
    type: KLAVIYO_GET_CONNECTIONS_SUCCESS,
    payload,
  };
}
export function getKlaviyoConnectionsFailure() {
  return {
    type: KLAVIYO_GET_CONNECTIONS_FAILURE,
  };
}

export function getKlaviyoFields({ onSuccess }: { onSuccess?: () => void }) {
  return {
    type: KLAVIYO_GET_FIELDS,
    payload: {
      onSuccess,
    },
  };
}

export function getKlaviyoFieldsSuccess(payload: KlaviyoFieldsOutput) {
  return {
    type: KLAVIYO_GET_FIELDS_SUCCESS,
    payload,
  };
}

export function getKlaviyoFieldsFailure() {
  return {
    type: KLAVIYO_GET_FIELDS_FAILURE,
  };
}

export function getKlaviyoList(payload: KlaviyoListsInput) {
  return {
    type: KLAVIYO_GET_LIST,
    payload,
  };
}

export function getKlaviyoListSuccess(payload: KlaviyoListsOutput) {
  return {
    type: KLAVIYO_GET_LIST_SUCCESS,
    payload,
  };
}

export function getKlaviyoListFailure() {
  return {
    type: KLAVIYO_GET_LIST_FAILURE,
  };
}

export function saveKlaviyo(payload: KlaviyoSaveInput) {
  return {
    type: KLAVIYO_SAVE,
    payload,
  };
}
export function saveKlaviyoSuccess() {
  return {
    type: KLAVIYO_SAVE_SUCCESS,
  };
}
export function saveKlaviyoFailure() {
  return {
    type: KLAVIYO_SAVE_FAILURE,
  };
}

export function deleteKlaviyo(payload: KlaviyoDeleteInput) {
  return {
    type: KLAVIYO_DELETE,
    payload,
  };
}

export function deleteKlaviyoSuccess() {
  return {
    type: KLAVIYO_DELETE_SUCCESS,
  };
}

export function deleteKlaviyoFailure() {
  return {
    type: KLAVIYO_DELETE_FAILURE,
  };
}

export function changeStatusKlaviyo(id: number) {
  return {
    type: KLAVIYO_CHANGESTATUS,
    payload: {
      id,
    },
  };
}
export function changeStatusKlaviyoSuccess() {
  return {
    type: KLAVIYO_CHANGESTATUS_SUCCESS,
  };
}
export function changeStatusKlaviyoFailure() {
  return {
    type: KLAVIYO_CHANGESTATUS_FAILURE,
  };
}
export function clearKlaviyoState() {
  return {
    type: KLAVIYO_CLEAR_STATE,
  };
}
