import { useState, useEffect } from 'react';

const useResponsivity = (returnPxValue?: 'px') => {
  const [screenWidth, setScreenWidth] = useState('large');
  const [screenWidthInPx, setScreenWidthInPx] = useState(1920);

  useEffect(() => {
    /*Check the screen size on every render.*/
    const handleResize = () => {
      const width = window.outerWidth;
      returnPxValue && setScreenWidthInPx(width);

      const newScreenWidth =
        width <= 640
          ? 'mobile'
          : width <= 768
          ? 'small'
          : width <= 1280
          ? 'medium'
          : 'large';
      setScreenWidth(newScreenWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    //Cleanup the useEffect when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (returnPxValue) {
    return screenWidthInPx;
  }
  return screenWidth;
};

export default useResponsivity;
