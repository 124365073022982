import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import FrequencyIcon from '@assets/icons/frequency.svg';

export const renderShowAgainFrequency = (
  displayFrequency: Targets['displayFrequency'],
  translate: Function,
) => {
  const result: any[] = [];

  if (displayFrequency && displayFrequency.showAgain) {
    result.push(
      <>
        {displayFrequency.showAgain.type === 'EVERY_SESSION' ? (
          <TimelineContent
            icon={<FrequencyIcon width="16" height="16" viewBox="0 0 36 36" />}
            label={translate('sections:view-frequency:optionTwo')}
          />
        ) : displayFrequency.showAgain.type === 'ON_EVERY_PAGE' ? (
          <TimelineContent
            icon={<FrequencyIcon width="16" height="16" viewBox="0 0 36 36" />}
            label={translate('sections:view-frequency:optionOne')}
          />
        ) : displayFrequency.showAgain.type === 'EVERY' &&
          displayFrequency.showAgain.options ? (
          <TimelineContent
            icon={<FrequencyIcon width="16" height="16" viewBox="0 0 36 36" />}
            label={`${translate('sections:view-frequency:optionThree')} ${
              displayFrequency.showAgain.options.value || 0
            } ${displayFrequency.showAgain.options.time?.toLowerCase()}.`}
          />
        ) : null}
      </>,
    );
  }

  return result;
};
