import { PsLottie, PsLottieRef } from 'ui';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import LivePreviewIcon from '@assets/icons/live-preview-icon.svg';
import confettiAnimation from '@assets/lotties/confetti-secondary.json';
import successAnimation from '@assets/lotties/success-new.json';
import { hideModal } from '@connectors/modal/actions';
import PSButton from '@elements/PSButton';
import PSForm, { Option, PSFormField, PSSelectbox } from '@elements/PSForm';
import { CreateCampaignModalFormValues } from '@features/campaigns/CreateCampaignModal/types';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PublishSuccessModal.module.scss';
import { PublishSuccessModalProps } from './types';
import {
  makeSelectCampaignLayout,
  makeSelectOrjCampaignDetail,
  makeSelectOrjCampaignDomains,
} from '@connectors/builder/selectors';
import FormSharingAccordion from '@components/PublishSidebar/FormSharingAccordion';
import BadgeThickIcon from '@assets/icons/badge-thick-icon.svg';
import { isElementExists } from '@utils/isElementExists';
import { useTranslation } from 'react-i18next';

const PublishSuccessModal: FC<PublishSuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const domains = useSelector(makeSelectOrjCampaignDomains);
  const campaignDetail = useSelector(makeSelectOrjCampaignDetail);
  const varintInfo =
    campaignDetail.variantInfo &&
    JSON.parse(campaignDetail.variantInfo as unknown as string);

  const layout = useSelector(makeSelectCampaignLayout);

  const [openFormSharingAccordion, setOpenFormSharingAccordion] =
    useState<boolean>(false);
  const [renderModal, setRenderModal] = useState<boolean>(false);

  const confettiRef = useRef() as PsLottieRef;
  const badgeRef = useRef() as PsLottieRef;
  const methods = useForm<CreateCampaignModalFormValues>({
    defaultValues: {
      domain: '',
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderModal(true);
    }, 1800);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    confettiRef?.current?.play();
    badgeRef?.current?.play();
    if (domains) {
      const verifiedDomain = domains?.find((item) => item.verified);
      methods.setValue('domain', verifiedDomain?.id?.toString() ?? '');
    }
    return () => {
      confettiRef?.current?.destroy();
      badgeRef?.current?.destroy();
    };
  }, []);

  const domainOptions: Option[] =
    domains?.map((x) => ({
      value: x.id?.toString() || '',
      label: x.url || '',
    })) || [];

  const onClickCancelButton = () => {
    dispatch(hideModal('PublishPublishSuccessModal'));
    onClose && onClose();
    methods.reset();
  };

  const handleDebugModeClick = () => {
    const selectedDomain = domainOptions.find(
      (domain) => domain.value === methods.getValues('domain'),
    );
    if (selectedDomain) {
      window.open(
        `http://${selectedDomain.label}?ps-preview=${campaignDetail.id}`,
        '_blank',
      );
    }
  };

  const [isFormElementExists] = useMemo(() => {
    if (!layout) return [false];
    return [isElementExists(layout, 'form')];
  }, [layout]);

  return (
    <>
      {!renderModal && (
        <PsLottie
          width="100%"
          height="100%"
          className={styles.confetti}
          loop={false}
          autoplay={false}
          animationData={confettiAnimation}
          lottieRef={confettiRef}
        />
      )}
      <div className={styles.container}>
        {!renderModal ? (
          <PsLottie
            key="badge"
            height={110}
            width={110}
            style={{
              width: 110,
              height: 110,
            }}
            loop={false}
            autoplay={true}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMin slice',
            }}
            animationData={successAnimation}
            lottieRef={badgeRef}
          />
        ) : (
          <BadgeThickIcon />
        )}
        <h1 className="text-xl font-semibold text-gray-900 lg:text-3xl dark:text-white">
          {t('sections:publish-success-modal:title')}
        </h1>
        <div className="pt-[15px]">
          {t('sections:publish-success-modal:description')}
        </div>

        <PSForm className={styles.formContainer}>
          <PSFormField
            control={methods.control}
            component={PSSelectbox}
            name="domain"
            label={t('sections:publish-success-modal:domain')}
            options={domainOptions}
            menuPosition="fixed"
            className={styles.selectbox}
            isSearchable={false}
          />
          {isFormElementExists && !varintInfo && (
            <FormSharingAccordion
              id="formSharing"
              isActive={openFormSharingAccordion}
              onClickAccordion={() =>
                setOpenFormSharingAccordion(!openFormSharingAccordion)
              }
              campaign={campaignDetail}
            />
          )}
          <div className={styles.buttonContainer}>
            <PSButton
              className={cn('btn btn-secondary', styles.button)}
              type="button"
              size="sm"
              variant="second"
              onClick={handleDebugModeClick}
            >
              <div className="flex items-center gap-[8px]">
                <LivePreviewIcon
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                />
                {t('sections:publish-success-modal:livePreview')}
              </div>
            </PSButton>
            <PSButton
              size="sm"
              className={cn('btn btn-primary', styles.button)}
              onClick={onClickCancelButton}
            >
              {t('sections:publish-success-modal:backToOverview')}
            </PSButton>
          </div>
        </PSForm>
      </div>
    </>
  );
};

export default PublishSuccessModal;
