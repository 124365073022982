import React, {
  useEffect,
  useState,
  FC,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import Link from 'next/link';

import styles from './TemplateSelection.module.scss';
import CloseIcon from '@assets/icons/close.svg';
import MultiPageIcon from '@assets/icons/multi-page.svg';
import Logo from '@assets/icons/logo.svg';
import RippleIcon from '@assets/icons/ripple-white.svg';
import RectangleIcon from '@assets/icons/rectangle-icon.svg';

import LightPopupIcon from '@assets/icons/light-popup-icon.svg';
import FullscreenPopupIcon from '@assets/icons/fullscreen-popup-icon.svg';
import FloatingPopupIcon from '@assets/icons/floating-popup-icon.svg';

import AnimatedIcon from '@assets/icons/animated-icon.svg';

import { Template, TemplateBase } from '@connectors/templates/types';
import { getCampaign } from '@connectors/campaign/actions';
import { TemplateObj } from '@components/SelectTemplateModal/SelectTemplateModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import TemplatesListView from './TemplatesListView';

import {
  makeSelectAutomationEdit,
  makeSelectCampaignCurrentPageTemplateId,
  makeSelectCampaignIntegration,
  makeSelectIsValidOrjLayout,
  makeSelectOrjCampaignDetail,
  makeSelectOrjCampaignLayout,
} from '@connectors/builder/selectors';
import {
  clearCampaignWithTemplateSuccess,
  getCampaignsCounts,
} from '@connectors/campaigns/actions';
import { hideModal, showModal } from '@connectors/modal/actions';
import { ShowcaseCheckbox, TypeSelect, SearchInput } from 'ui';
import store from '@utils/store';
import {
  TemplateByIdQuery,
  useCampaignCreateWithTemplateIdMutation,
  useDeleteAllIntegrationsInCampaignMutation,
  useTemplateByIdLazyQuery,
  useTemplateSeasonsLazyQuery,
  useTemplatesLazyQuery,
  useTemplateTagsLazyQuery,
  usePublishEmailAutomationMutation,
  TagItem,
  SubTagItem,
  SeasonCount,
} from 'src/generated/graphql';
import { ShowcaseIcons } from '@components/TemplateSelection/icons';
import { getWebsites } from '@connectors/websites/actions';
import { sendAnalytics } from '@utils/analytics';
import PlaybookIcon from '@assets/icons/playbook-icon.svg';
import {
  makeSelectSidebarCampaignCount,
  makeSelectSidebarCampaignCountStatus,
} from '@connectors/campaigns/selectors';
import ReduxStatus from '@enums/ReduxStatus';
import { addLeadEmail, getLeadEmails } from '@connectors/leadEmails/actions';
import RadioInput from '@elements/RadioInput';
import ElementsFilter from './ElementsFilter/ElementsFilter';
import Tabs, { Tab } from '@elements/Tabs';
import {
  deNormalizeElement,
  layoutElementsFilterAndGroup,
  layoutElementsGroupRevert,
  normalizeElements,
} from '@utils/element';
import { Layout, LayoutStyle } from '@connectors/builder/campaign';
import ThemeList from '@components/StyleSidebar/ThemeList';
import {
  changeCampaignTemplate,
  setEmailAutomationEdit,
} from '@connectors/builder/actions';
import { makeSelectWebsites } from '@connectors/websites/selectors';
import { toast } from 'react-toastify';
import useResponsivity from '@utils/useResponsivity';
import { isDesktop } from '@utils/deviceType';
import { useAuth } from '@utils/useAuth';
import {
  resetSelectedTemplate,
  resetTemplatesPageAndDeviceType,
} from '@connectors/templates/actions';
import ShowcaseTag from '@elements/ShowcaseTag';
import ExploreShopifyBanner from '@components/ExploreShopifyBanner';
import { motion, AnimatePresence } from 'framer-motion';
import scrollToElementById from '@utils/scrollToElementById';
import { cloneDeep } from 'lodash';
import { saveSettings } from '@connectors/builder/settings/actions';
import Selectbox from '@elements/Selectbox';

interface SelectTemplateModalProps {
  id?: string;
  onClose?: () => void;
  onCreateNewStep?: (content: TemplateObj) => void;
  isShowcase?: boolean;
  isPlaybook?: boolean;
}

type BaseEnumType = {
  id: number;
  name: 'light' | 'fullscreen' | 'floating-bar';
};

export const layoutTypes: BaseEnumType[] = [
  {
    id: 0,
    name: 'light',
  },
  {
    id: 1,
    name: 'fullscreen',
  },
  {
    id: 2,
    name: 'floating-bar',
  },
];

type LayoutType = 'light' | 'fullscreen' | 'floating-bar';

export type GroupItem = {
  name: string;
  item: Array<TemplateBase>;
  hasSelected: boolean;
};

const animatedDropdownOptions = [
  {
    label: 'Static & Animated',
    value: 'allDesigns',
  },
  {
    label: 'Static only',
    value: 'staticDesigns',
    icon: <AnimatedIcon width="16" height="16" viewBox="0 0 16 16" />,
  },
  {
    label: 'Animated only',
    value: 'animatedDesigns',
    icon: (
      <AnimatedIcon
        className={cn('rotate-zoom-animation', 'fill-black')}
        width="16"
        height="16"
        viewBox="0 0 16 16"
      />
    ),
  },
];

const TemplateSelection: FC<SelectTemplateModalProps> = ({
  id,
  onClose,
  isShowcase = false,
  isPlaybook = false,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    auth: { email },
  } = useAuth();

  const [getTemplates, { data, loading }] = useTemplatesLazyQuery();
  const [getTemplate] = useTemplateByIdLazyQuery();
  const [getTags, { data: tagsData, loading: tagsLoading }] =
    useTemplateTagsLazyQuery();
  const [getSeasons, { data: seasonsData, loading: seasonsLoading }] =
    useTemplateSeasonsLazyQuery();
  const [deleteAllIntegrationsInCampaignMutation] =
    useDeleteAllIntegrationsInCampaignMutation();
  const [createWithTemplateIdMutation] =
    useCampaignCreateWithTemplateIdMutation();
  const [publishEmailAutomationMutation] = usePublishEmailAutomationMutation();

  const [layoutType, setLayoutType] = useState<LayoutType>('light');
  const [filterTags, setFilterTags] = useState<number[]>([]);
  const [closedFilterTag, setClosedFilterTag] = useState<boolean>(false);
  const [filterSubTag, setFilterSubTag] = useState<string | null>(null);
  const [filterSeasons, setFilterSeasons] = useState<number[]>([]);
  const [filterElements, setFilterElements] = useState<string[]>([]);
  const [animatedFilter, setAnimatedFilter] = useState<string>('allDesigns');
  const [isMultiPageFilter, setIsMultiPageFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isCloseIcon, setIsCloseIcon] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateByIdQuery>();
  const [isOpenShowCaseTag] = useState<{
    [key: string]: boolean;
  }>({});

  const campaign = useSelector(makeSelectOrjCampaignDetail);
  const layout = useSelector(makeSelectOrjCampaignLayout);
  const campaignsCount = useSelector(makeSelectSidebarCampaignCount);
  const campaignsCountStatus = useSelector(
    makeSelectSidebarCampaignCountStatus,
  );
  const isValidOrjLayout = useSelector(makeSelectIsValidOrjLayout);
  const integrations = useSelector(makeSelectCampaignIntegration);
  const automationVariables = useSelector(makeSelectAutomationEdit);
  const selectedTemplateId = useSelector(
    makeSelectCampaignCurrentPageTemplateId,
  );
  const websites = useSelector(makeSelectWebsites);

  const isOnboarding =
    ((!selectedTemplateId &&
      (router.query.flow === 'register' ||
        router.query.flow === 'invite-register')) ||
      (!selectedTemplateId && campaignsCount <= 1)) &&
    !isShowcase;
  const [isAllSelected, setIsAllSelected] = useState<boolean>(isShowcase);
  const screenWidthInPx = useResponsivity('px');
  const isShopify = campaign?.domains?.[0]?.isShopify;
  useEffect(() => {
    if (isShopify === undefined) return;

    if (
      ((campaignsCountStatus === ReduxStatus.success &&
        campaignsCount > 1 &&
        (router.query.flow === 'register' ||
          router.query.flow === 'invite-register')) ||
        selectedTemplateId) &&
      !isShopify
    ) {
      setIsAllSelected(true);
    } else if (tagsData && isShopify) {
      selectShopifyTag();
    }
  }, [campaignsCountStatus, campaign?.domains, tagsData]);

  useEffect(() => {
    if (
      selectedTemplateId &&
      selectedTemplateId !== selectedTemplate?.templateById.id
    ) {
      getTemplate({
        variables: {
          id: selectedTemplateId,
        },
        onCompleted: (res) => {
          if (res.templateById) {
            setSelectedTemplate(res);
          }
        },
      });
    }
  }, [getTemplate, selectedTemplateId, selectedTemplate?.templateById.id]);

  useEffect(() => {});
  const activeFilterElements = useMemo(() => {
    if (!filterSubTag) return data?.templates?.layoutElements;
    const activeElementsSet = new Set();
    data?.templates?.data
      .filter((template) => {
        if (!filterSubTag) return true;
        return template.subTags.includes(filterSubTag);
      })
      .forEach((item) => {
        item.layoutElements.forEach((layoutElement) => {
          activeElementsSet.add(layoutElement);
        });
      });
    return data?.templates?.layoutElements.filter((element) =>
      activeElementsSet.has(element),
    );
  }, [data?.templates?.data, filterSubTag, data?.templates?.layoutElements]);

  const allPopups: TemplateBase[] =
    data?.templates?.data && Array.isArray(data?.templates?.data)
      ? data?.templates?.data
          .filter((item) => {
            if (!filterSubTag) return true;
            return item.subTags.includes(filterSubTag);
          })
          .filter((template) => {
            if (filterElements.length === 0) {
              return true;
            }
            return layoutElementsGroupRevert(filterElements).every(
              (element) => {
                if (element.includes('|')) {
                  return element
                    .split('|')
                    .some((substring) =>
                      template.layoutElements.includes(substring),
                    );
                }
                return template.layoutElements.includes(element);
              },
            );
          })
          .filter((item) => item.id !== selectedTemplateId)
      : [];

  const handleToggleTagAll = () => {
    setIsAllSelected(true);
    setFilterTags([]);
    setFilterSubTag(null);
  };

  const handleToggleTagChange = (tag: number) => {
    setIsAllSelected(false);
    setFilterSubTag(null);
    if (!filterTags.includes(tag)) {
      setFilterTags([tag]);
      setClosedFilterTag(false);
    } else {
      setClosedFilterTag(!closedFilterTag);
    }
    scrollToElementById('parent_showcase_tag');
  };

  const handleToggleSubTagChange = (tag: number, subTag?: string) => {
    setFilterTags([tag]);
    if (filterSubTag === subTag) {
      setFilterSubTag(null);
    } else {
      setIsAllSelected(false);
      setFilterSubTag(subTag || null);
    }
  };

  const handleToggleSeasonChange = (seasonId: number) => {
    if (filterSeasons.includes(seasonId)) {
      setFilterSeasons([]);
      return;
    }
    setFilterSeasons([seasonId]);
  };

  const handleClickElementFilter = (elementName: string) => {
    const current = deNormalizeElement(elementName);
    if (filterElements.includes(current)) {
      setFilterElements(
        filterElements.filter((filterElement) => filterElement !== current),
      );
      return;
    }
    setFilterElements([current, ...filterElements]);
  };

  const debouncedSearchOnchange = debounce(
    (value: string) => setSearchValue(value),
    500,
  );

  useEffect(() => {
    const user = !!store.get('user');
    if (!user) return;

    dispatch(getWebsites());
    dispatch(getCampaignsCounts());
  }, []);

  useEffect(() => {
    const subTagCount = tagsData?.templateTags
      .find((tag) => tag.id === filterTags[0])
      ?.subRelations?.subTags?.find((subTag) => subTag.name === filterSubTag)
      ?.seasonsCounts?.find(
        (seasonCount) =>
          seasonCount.name ===
          seasonsData?.templateSeasons.find(
            (season) => season.id === filterSeasons[0],
          ),
      )?.count;

    if (!subTagCount) setFilterSubTag(null);
  }, [filterSeasons]);

  const selectShopifyTag = () => {
    if (!tagsData?.templateTags) return;

    const findShopifyTagIndex = tagsData?.templateTags?.find(
      (item) => item.tag === 'Shopify',
    )?.id;

    if (findShopifyTagIndex !== undefined) {
      setIsAllSelected(false);
      setFilterSubTag(null);
      setFilterTags([findShopifyTagIndex]);
      setClosedFilterTag(false);
    }
  };

  const showTemplateDetailsModal = (templateId: number) => {
    dispatch(
      showModal({
        id: 'TemplateDetailsModal',
        data: {
          templateId,
          items: allPopups,
          isTemplateSelected: !selectedTemplateId,
          isShowcase,
          handleTemplateOperations,
        },
      }),
    );
  };

  const onSubmitSuccess = () => {
    closeTemplateDetailsModal();
    let redirectPath = `/builder/${id}/customize`;
    if (!selectedTemplateId) {
      redirectPath += '?flow=create';
    }
    router.push(redirectPath);
  };

  const closeTemplateDetailsModal = () => {
    dispatch(hideModal('TemplateDetailsModal'));
    dispatch(resetTemplatesPageAndDeviceType());
    dispatch(resetSelectedTemplate());
  };

  const createCampaignWithTemplateIdSuccess = (
    id: number,
    closeDetailsModal?: boolean,
    onComplete?: () => void,
  ) => {
    onComplete && onComplete();
    closeDetailsModal && closeTemplateDetailsModal();

    dispatch(
      addLeadEmail({
        campaignId: id,
        email,
      }),
    );

    router.push(`/builder/${id}/customize?flow=create`);

    dispatch(clearCampaignWithTemplateSuccess());
  };

  const createCampaignFromTemplate = (
    templateId: number,
    theme?: LayoutStyle,
    closeDetailsModal?: boolean,
    onComplete?: () => void,
  ) => {
    if (Number(screenWidthInPx) < 1024 && !isDesktop()) {
      toast.info('Campaigns are only editable on desktop devices.');
      return;
    }
    const domainId = websites.length > 0 && websites[0]?.id;
    if (!domainId) {
      toast.info('No website found!');
      return;
    }

    createWithTemplateIdMutation({
      variables: {
        input: {
          campaignName: 'My Beautiful Popup',
          domainId,
          templateId,
          ...(theme
            ? {
                themeElement: JSON.stringify({
                  code: theme.code,
                  data: theme.data,
                }),
              }
            : {}),
        },
      },
      onCompleted: ({ campaignCreateWithTemplateId }) => {
        createCampaignWithTemplateIdSuccess(
          campaignCreateWithTemplateId.id,
          closeDetailsModal,
          onComplete,
        );
      },
      onError: () => {
        onComplete && onComplete();
      },
    });
  };

  const changeCurrentCampaignTemplate = (
    template: TemplateByIdQuery['templateById'],
    campaignId: number,
    currentTheme?: LayoutStyle,
  ) => {
    let layout: Layout = template?.layout
      ? JSON.parse(template?.layout)
      : undefined;
    let targets = template?.targets ? JSON.parse(template?.targets) : undefined;

    const haveSubText = !!layout?.style?.data?.subText;
    let currentThemeData = currentTheme?.data;
    if (!haveSubText) {
      if (currentThemeData && !currentThemeData?.subText) {
        const theme = ThemeList.find((x) => x.code === layout.style.code);
        if (theme?.data?.subText) {
          currentThemeData = {
            ...currentThemeData,
            subText: theme?.data?.subText,
          };
        }
      }
    }

    const templateData: Template = {
      ...template,
      targets,
      layout: {
        ...layout,
        style: {
          code: currentTheme?.code || layout.style.code,
          data: currentThemeData || layout.style.data,
        },
      },
    };

    const templateHasForm = templateData?.layoutElements?.some(
      (element) => element === 'form',
    );

    if (isValidOrjLayout) {
      dispatch(
        showModal({
          id: 'ChangeLayoutModal',
          data: {
            hasEmailAutomation:
              !!automationVariables?.id &&
              automationVariables?.status &&
              !templateHasForm,
            onSubmit: () => {
              if (!campaignId) return;
              const haveAnyIntegrationInCampaign =
                Object.values(integrations as object).filter(
                  (integration) => integration.length > 0,
                ).length > 0;

              if (haveAnyIntegrationInCampaign) {
                deleteAllIntegrationsInCampaignMutation({
                  variables: {
                    campaignId,
                  },
                });
              }

              dispatch(
                changeCampaignTemplate(
                  campaignId,
                  templateData,
                  onSubmitSuccess,
                ),
              );

              if (automationVariables?.id && !templateHasForm) {
                publishEmailAutomationMutation({
                  variables: {
                    id: Number(automationVariables?.id),
                    status: false,
                  },
                });
                dispatch(
                  setEmailAutomationEdit({
                    id: Number(automationVariables?.id),
                    status: false,
                  }),
                );
              }
              if (campaign.settings?.formSharing) {
                const settings = cloneDeep(campaign!.settings);
                if (!settings) return;
                settings.formSharing = false;
                dispatch(saveSettings({ settings }));
              }
            },
          },
        }),
      );
      store.remove('accent-form-tabs-visited');
    } else {
      dispatch(
        changeCampaignTemplate(campaignId, templateData, onSubmitSuccess),
      );
      store.remove('accent-form-tabs-visited');
    }
  };

  const handleDetailTemplate = (id: number) => {
    sendAnalytics('click', {
      element: 'template_selection_previewed_template',
      templateId: id,
    });
    showTemplateDetailsModal(id);
  };

  const handleSelectTemplate = (id: number) => {
    getTemplate({
      variables: {
        id,
      },
      onCompleted: (data) => {
        const templateData = data?.templateById;
        if (templateData) {
          const user = !!store.get('user');
          handleTemplateOperations(templateData, isShowcase, user);
        }
      },
    });
  };

  const addPendingCampaignWithTemplate = (
    templateId: number,
    theme?: LayoutStyle,
  ) => {
    closeTemplateDetailsModal();
    router.push(
      `/register?callbackUrl=clone?id=${templateId}${
        theme?.code ? `|code=${theme?.code}` : ''
      }`,
    );
  };

  const handleTemplateOperations = (
    template: TemplateByIdQuery['templateById'],
    isShowcase?: boolean,
    user?: boolean,
    theme?: LayoutStyle,
    closeDetailsModal?: boolean,
    onComplete?: () => void,
  ) => {
    // onboarding creating new campaign
    if (isShowcase && !!user) {
      createCampaignFromTemplate(
        template.id,
        theme,
        closeDetailsModal,
        onComplete,
      );
      return;
    }
    // user not logged in
    if (isShowcase && !user) {
      addPendingCampaignWithTemplate(template.id, theme);
      return;
    }
    // change campaign template
    if (Number(router.query.id)) {
      changeCurrentCampaignTemplate(template, Number(router.query.id), theme);
      return;
    }
  };

  useEffect(() => {
    getTags();
    getSeasons();
    const user = !!store.get('user');
    if (!user) return;

    if (id !== 'undefined' && campaign?.id === null) {
      dispatch(getCampaign({ campaignId: id }));
    }
    if (layout?.contents) {
      setIsCloseIcon(!isCloseIcon);
    }
  }, []);
  useEffect(() => {
    if (campaign.id) {
      dispatch(getLeadEmails({ campaignId: campaign.id }));
    }
  }, [campaign.id]);

  useLayoutEffect(() => {
    const templateFilter = {
      goalId: null,
      searchTerm: searchValue,
      isMultipage: isMultiPageFilter,
      animatedFilterValue: animatedFilter,
      layoutType: layoutTypes.find((l) => l.name === layoutType)?.id,
      tags: [...filterTags],
      seasonId: filterSeasons.length > 0 ? filterSeasons[0] : null,
    };

    getTemplates({
      variables: {
        templatesInput: templateFilter,
      },
    });
    setFilterElements([]);
  }, [
    getTemplates,
    layoutType,
    isMultiPageFilter,
    animatedFilter,
    filterTags,
    filterSeasons,
    searchValue,
  ]);

  const selectedTagName = useMemo(() => {
    if (filterTags?.length) {
      const selected = tagsData?.templateTags.find(
        (item) => item.id === filterTags[0],
      );

      return selected ? selected?.tag : 'All';
    }
    return 'All';
  }, [filterTags, tagsData]);

  const isAnyFilterSelected = filterTags.length !== 0 || isAllSelected;
  const isOnboardNoFilter = isOnboarding && !isAnyFilterSelected;

  const renderOpenableShowcaseTag = (tagItem: TagItem, count: number) => {
    // eslint-disable-next-line no-unused-vars
    // const handleAdjustOpenStatusShowCaseTag = () => {
    //   setIsOpenShowcaseTag((prevState) => {
    //     const newState = Object.keys(prevState).reduce(
    //       (
    //         acc: {
    //           [key: string]: boolean;
    //         },
    //         key,
    //       ) => {
    //         acc[key] = false;
    //         return acc;
    //       },
    //       {},
    //     );

    //     newState[tagItem.id] = !prevState[tagItem.id];

    //     return newState;
    //   });
    // };

    return (
      <>
        <ShowcaseTag
          key={tagItem.id.toString()}
          id="parent_showcase_tag"
          isActive={filterTags.includes(tagItem.id) && !filterSubTag}
          // isSubTagActive={filterTags.includes(tagItem.id)}
          handleClick={(e) => {
            sendAnalytics('click', {
              element: 'template_selection_choosed_business_goals',
              tagId: tagItem.id,
              tagName: tagItem.tag,
            });
            e.preventDefault();
            e.stopPropagation();
            handleToggleTagChange(tagItem.id);
            // handleAdjustOpenStatusShowCaseTag();
          }}
          icon={tagItem.icon ? ShowcaseIcons[tagItem.icon] : undefined}
          count={count}
          hideCloseIcon
          // isParentTag={
          //   (tagItem.subRelations?.subTags &&
          //     tagItem.subRelations?.subTags?.length > 0 &&
          //     count > 0) ||
          //   false
          // }
          // isMenuOpen={!closedFilterTag}
        >
          {tagItem.tag}
        </ShowcaseTag>

        <AnimatePresence>
          {isOpenShowCaseTag[tagItem.id] && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: 0.3 }}
              style={{ overflow: 'hidden' }}
            >
              <div className="pl-[26px]">
                {!closedFilterTag &&
                  filterTags.includes(tagItem.id) &&
                  tagItem.subRelations?.subTags?.map((subTag: SubTagItem) => {
                    const count =
                      filterSeasons.length > 0
                        ? subTag?.seasonsCounts?.find(
                            (seasonCount: SeasonCount) =>
                              seasonCount.name ===
                              seasonsData?.templateSeasons.find(
                                (season) => season.id === filterSeasons[0],
                              )?.name,
                          )?.count || 0
                        : subTag.count || 0;
                    if (count === 0) return <></>;
                    return (
                      <ShowcaseTag
                        key={`${tagItem.id.toString()}-${subTag.name}`}
                        isActive={filterSubTag === subTag.name}
                        handleClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleToggleSubTagChange(tagItem.id, subTag.name!);
                        }}
                        count={count}
                        hideCloseIcon
                      >
                        {subTag.name}
                      </ShowcaseTag>
                    );
                  })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  const renderTemplatesContainer = () => {
    if (campaignsCount === 0 && !isShowcase) {
      return <div className={styles.templatesContainer} />;
    }
    return (
      <div
        className={cn(styles.templatesContainer, {
          '!bg-[#d7d7d7]': isOnboardNoFilter,
        })}
      >
        {isOnboardNoFilter ? (
          <div className="relative w-full h-full">
            <div className="absolute top-[38px] left-[-13px] z-[3]">
              <RippleIcon width="42" height="42" viewBox="0 0 100 100" />
            </div>
            <div className="flex justify-center items-center h-full">
              <div className="w-[450px] py-[50px] rounded-[12px] shadow-[0px_4px_50px_rgba(0,_0,_0,_0.15)] text-center bg-white">
                <div className="flex justify-center mb-[15px]">
                  <Logo width="36" height="36" viewBox="0 0 32 32" />
                </div>
                <span className="font-normal text-xs leading-[14px] mb-[5px]">
                  Let’s create your first campaign!
                </span>
                <div className="font-semibold text-lg leading-6">
                  Select a business goal from the sidebar
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-6 2xl:mb-8">
              <h3 className="font-inter text-xl 2xl:text-2xl leading-[30px] font-extrabold">
                {selectedTagName === 'All'
                  ? 'Choose your template'
                  : `${filterSubTag || selectedTagName} Templates`}
              </h3>
              {isShopify && selectedTagName === 'Shopify' ? (
                <ExploreShopifyBanner />
              ) : null}

              <div className="flex gap-[15px]">
                <div className="w-full mt-4 2xl:mt-6">
                  <SearchInput
                    placeholder={`Discover popup templates`}
                    onChangeHandler={(value: string) => {
                      debouncedSearchOnchange(value);
                    }}
                    className="!h-[32px]"
                  />
                </div>
                <div className="mt-4 2xl:mt-6">
                  <div className="flex gap-2">
                    <Selectbox
                      className="min-w-[200px] font-semibold"
                      id="select-animate-designs"
                      name="select-animate-designs"
                      options={animatedDropdownOptions}
                      value={animatedFilter}
                      onSelectChange={(e) => {
                        setAnimatedFilter(e.value);
                      }}
                      elementSize="xs"
                      isTypeable={false}
                    />
                    <ShowcaseCheckbox
                      id="multi-step-popup"
                      icon={
                        <MultiPageIcon
                          className={
                            isMultiPageFilter ? 'fill-black' : 'fill-gray-600'
                          }
                          width="16"
                          height="16"
                          viewBox="0 0 18 18"
                        />
                      }
                      label="Multistep"
                      isActive={isMultiPageFilter || false}
                      handleClick={() =>
                        setIsMultiPageFilter(!isMultiPageFilter)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-[35px]">
              <ElementsFilter
                elements={normalizeElements(
                  layoutElementsFilterAndGroup(activeFilterElements ?? []),
                )}
                onElementClick={handleClickElementFilter}
                activeElements={filterElements}
                loading={loading}
              />
            </div>
            <TemplatesListView
              selectedTemplate={
                !isShowcase ? selectedTemplate?.templateById : undefined
              }
              templates={allPopups}
              isLoading={loading || (!isShowcase && campaign?.id === undefined)}
              contentClassName={cn({ '!pb-0': isPlaybook })}
              handleDetailTemplate={handleDetailTemplate}
              handleSelectTemplate={handleSelectTemplate}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white w-full">
      {!isPlaybook && !isShowcase && (
        <div
          id="template-selection-navbar"
          className="sticky top-0 py-[15px] z-10 bg-white flex items-center h-auto border-b border-gray border"
        >
          <div className={styles.navigation}>
            <span className="mr-[10px]">
              <Link href="/dashboard" passHref>
                <Logo width="36" height="36" viewBox="0 0 32 32" />
              </Link>
            </span>
            <div>
              {isCloseIcon && (
                <span
                  className="w-8 ml-[10px] cursor-pointer rounded-full items-center justify-center text-black flex"
                  onClick={() => {
                    onClose && onClose();
                    router.push(`/builder/${id}/customize`);
                  }}
                >
                  <CloseIcon width="32" height="32" viewBox="0 0 36 36" />
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      <div
        className={cn(styles.playbook, {
          [styles.showcaseWrapper]: !isPlaybook,
          [styles.playbookWrapper]: isPlaybook,
        })}
      >
        <div
          className={cn({
            [styles.showcaseSidebar]: !isPlaybook,
            [styles.playbookSidebar]: isPlaybook,
            scrollbar: isPlaybook,
          })}
        >
          <div
            className={cn(styles.showcaseScrollWrapper, {
              [styles.playbookScrollWrapper]: isPlaybook,
              scrollbar: !isPlaybook,
            })}
          >
            {!loading && isOnboardNoFilter && (
              <span className="absolute right-[-42px] top-[47px] z-[2]">
                <RectangleIcon />
              </span>
            )}
            <div className={cn(styles.goalSelectorContainer, 'scrollbar')}>
              <div className={styles.goalSelector}>
                <Tabs
                  labelSize="sm"
                  headerClassName={styles.tabHeader}
                  activeTabClassName={styles.activeTabHeader}
                  headlineClassName="pt-[15px] pl-[15px] pr-[15px]"
                  defaultActiveKey="goals"
                >
                  <Tab label="Goals" id="goals">
                    <div className="p-[15px] pb-0">
                      {tagsLoading && (
                        <>
                          <div className="w-full hidden sm:block">
                            <Skeleton
                              height={26}
                              count={10}
                              baseColor="#f5f5f5"
                              highlightColor="#fefefe"
                              borderRadius={6}
                            />
                          </div>
                          <div className="container w-full sm:hidden">
                            <Skeleton
                              height={45}
                              count={1}
                              baseColor="#f5f5f5"
                              highlightColor="#fefefe"
                              borderRadius={6}
                            />
                          </div>
                        </>
                      )}
                      {(tagsData?.templateTags?.length ?? 0) > 0 && (
                        <div
                          className={cn(
                            styles.tagsContainer,
                            'flex flex-wrap lg:flex-nowrap lg:flex-col items-start gap-[2px] overflow-x-hidden overflow-y-scroll scrollbar',
                          )}
                        >
                          <ShowcaseTag
                            isActive={isAllSelected}
                            handleClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleToggleTagAll();
                            }}
                            icon={<PlaybookIcon />}
                            hideCloseIcon
                          >
                            All Templates
                          </ShowcaseTag>
                          {tagsData?.templateTags
                            .filter((tag) => tag.type === 'GOAL')
                            ?.map((tagItem) => {
                              const count =
                                filterSeasons.length > 0
                                  ? tagItem.seasonsCounts?.find(
                                      (seasonCount) =>
                                        seasonCount.name ===
                                        seasonsData?.templateSeasons.find(
                                          (season) =>
                                            season.id === filterSeasons[0],
                                        )?.name,
                                    )?.count || 0
                                  : tagItem.count || 0;

                              return (
                                <div
                                  className="w-full"
                                  key={tagItem.id.toString()}
                                >
                                  {renderOpenableShowcaseTag(tagItem, count)}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab label="Industries" id="industries">
                    <div className="p-[15px] pb-0">
                      {tagsLoading && (
                        <>
                          <div className="w-full hidden sm:block">
                            <Skeleton
                              height={26}
                              count={10}
                              baseColor="#f5f5f5"
                              highlightColor="#fefefe"
                              borderRadius={6}
                            />
                          </div>
                          <div className="container w-full sm:hidden">
                            <Skeleton
                              height={45}
                              count={1}
                              baseColor="#f5f5f5"
                              highlightColor="#fefefe"
                              borderRadius={6}
                            />
                          </div>
                        </>
                      )}
                      {(tagsData?.templateTags?.length ?? 0) > 0 && (
                        <div
                          className={cn(
                            styles.tagsContainer,
                            'flex flex-wrap lg:flex-nowrap lg:flex-col items-start gap-[2px] lg:max-h-[calc(100vh-188px)] overflow-x-hidden overflow-y-scroll scrollbar',
                          )}
                        >
                          <ShowcaseTag
                            isActive={isAllSelected}
                            handleClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleToggleTagAll();
                            }}
                            icon={<PlaybookIcon />}
                            hideCloseIcon
                            // className={!isPlaybook ? '!w-min' : ''}
                          >
                            All Templates
                          </ShowcaseTag>
                          {tagsData?.templateTags
                            ?.filter((tag) => tag.type === 'INDUSTRY')
                            ?.map((tagItem) => {
                              const count =
                                filterSeasons.length > 0
                                  ? tagItem.seasonsCounts?.find(
                                      (seasonCount) =>
                                        seasonCount.name ===
                                        seasonsData?.templateSeasons.find(
                                          (season) =>
                                            season.id === filterSeasons[0],
                                        )?.name,
                                    )?.count || 0
                                  : tagItem.count || 0;
                              return (
                                <div
                                  className="w-full"
                                  key={tagItem.id.toString()}
                                >
                                  <ShowcaseTag
                                    key={tagItem.id.toString()}
                                    isActive={
                                      filterTags.includes(tagItem.id) &&
                                      !filterSubTag
                                    }
                                    isSubTagActive={filterTags.includes(
                                      tagItem.id,
                                    )}
                                    handleClick={(e) => {
                                      sendAnalytics('click', {
                                        element:
                                          'template_selection_choosed_business_goals',
                                        tagId: tagItem.id,
                                        tagName: tagItem.tag,
                                      });
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleToggleTagChange(tagItem.id);
                                    }}
                                    icon={
                                      tagItem.icon
                                        ? ShowcaseIcons[tagItem.icon]
                                        : undefined
                                    }
                                    count={count}
                                    hideCloseIcon
                                    isParentTag={
                                      (tagItem.subRelations?.subTags &&
                                        tagItem.subRelations?.subTags?.length >
                                          0 &&
                                        count > 0) ||
                                      false
                                    }
                                    isMenuOpen={!closedFilterTag}
                                  >
                                    {tagItem.tag}
                                  </ShowcaseTag>
                                  <div className="pl-[26px]">
                                    {!closedFilterTag &&
                                      filterTags.includes(tagItem.id) &&
                                      tagItem.subRelations?.subTags?.map(
                                        (subTag) => {
                                          const count =
                                            filterSeasons.length > 0
                                              ? subTag?.seasonsCounts?.find(
                                                  (seasonCount) =>
                                                    seasonCount.name ===
                                                    seasonsData?.templateSeasons.find(
                                                      (season) =>
                                                        season.id ===
                                                        filterSeasons[0],
                                                    )?.name,
                                                )?.count || 0
                                              : subTag.count || 0;
                                          if (count === 0) return <></>;
                                          return (
                                            <ShowcaseTag
                                              key={`${tagItem.id.toString()}-${
                                                subTag.name
                                              }`}
                                              isActive={
                                                filterSubTag === subTag.name
                                              }
                                              handleClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleToggleSubTagChange(
                                                  tagItem.id,
                                                  subTag.name!,
                                                );
                                              }}
                                              count={count}
                                              hideCloseIcon
                                            >
                                              {subTag.name}
                                            </ShowcaseTag>
                                          );
                                        },
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="mt-[12px] p-[15px] border-t bg-white ">
                <h3 className="font-semibold text-[12px] leading-[14px] mb-[10px]">
                  Seasonal
                </h3>
                <div className="flex justify-start flex-col">
                  {seasonsLoading && (
                    <>
                      <div className="w-full hidden sm:block">
                        <Skeleton
                          height={30}
                          count={5}
                          baseColor="#f5f5f5"
                          highlightColor="#fefefe"
                          borderRadius={6}
                        />
                      </div>
                      <div className="container w-full sm:hidden">
                        <Skeleton
                          height={45}
                          count={1}
                          baseColor="#f5f5f5"
                          highlightColor="#fefefe"
                          borderRadius={6}
                        />
                      </div>
                    </>
                  )}
                  {seasonsData?.templateSeasons.map((seasonItem) => {
                    return (
                      <div key={seasonItem.id}>
                        <RadioInput
                          label={seasonItem.name}
                          id={seasonItem.name}
                          checked={filterSeasons.includes(seasonItem.id)}
                          labelClassName="!text-[12px] !leading-4 "
                          onClick={() =>
                            handleToggleSeasonChange(seasonItem.id)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="p-[15px] border-t bg-white sticky bottom-0">
              <h3 className="font-semibold text-[12px] leading-[14px] mb-[10px]">
                Popup Type
              </h3>
              <div className="flex items-center justify-start">
                <TypeSelect
                  value={layoutType}
                  onChange={(value) => setLayoutType(value as LayoutType)}
                  className="!w-full"
                  options={[
                    {
                      icon: (
                        <LightPopupIcon
                          width="16"
                          height="16"
                          viewBox="0 0 18 18"
                        />
                      ),
                      value: 'light',
                      label: '',
                      tooltip: 'Light popup',
                    },
                    {
                      icon: (
                        <FullscreenPopupIcon
                          width="16"
                          height="16"
                          viewBox="0 0 18 18"
                        />
                      ),
                      value: 'fullscreen',
                      label: '',
                      tooltip: 'Fullscreen',
                    },
                    {
                      icon: (
                        <FloatingPopupIcon
                          width="16"
                          height="16"
                          viewBox="0 0 18 18"
                        />
                      ),
                      value: 'floating-bar',
                      label: '',
                      tooltip: 'Floating bar',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        {renderTemplatesContainer()}
      </div>
    </div>
  );
};

export default TemplateSelection;
export type { SelectTemplateModalProps };
