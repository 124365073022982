import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import type { DefaultWebsiteOptionProps } from '../types';
import styles from '../NoVerifiedWebsiteModal.module.scss';

import Code from '@elements/Code';
import Favicon from '@components/Favicon';
import PSButton from '@elements/PSButton';
import { Tooltip } from 'ui';

import { getWebsites } from '@connectors/websites/actions';
import { hideModal } from '@connectors/modal/actions';
import type { RootState } from '@connectors/reducers';
import type { Website } from '@connectors/websites/reducer';

import SandGlassIcon from '@assets/icons/sandglass.svg';
import CheckIcon from '@assets/icons/check_circle_outline.svg';
import { useAuth } from '@utils/useAuth';
import useVerifyWebsiteWindow from '@utils/useVerifyWebsiteWindow';
import { Trans, useTranslation } from 'react-i18next';

const path = process.env.NEXT_PUBLIC_BUNDLE_PATH;

const DefaultWebsiteOption: FC<DefaultWebsiteOptionProps> = ({
  url,
  routerReload = true,
  onVerifyComplete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    auth: { accountId },
  } = useAuth();

  const script = `<script src="${path}" data-id="${accountId}" async defer></script>`;

  const { items: websites, status: websitesStatus } = useSelector(
    (state: RootState) => state.websites,
  );
  const selectedWebsite = websites.filter(
    (website: Website) => website.url === url,
  )[0];
  const isLoading = websitesStatus === 'loading';

  const [isVerifyAttempted, setIsVerifyAttempted] = useState(false);

  const { openVerifyWindow } = useVerifyWebsiteWindow();

  const verifyHandler = (url: string) => {
    openVerifyWindow({ url, onClose: refreshHandler });
    setIsVerifyAttempted(true);
  };
  const refreshHandler = () => {
    setIsVerifyAttempted(true);
    dispatch(getWebsites());
  };

  useEffect(() => {
    //Is verify attempted is added because we only want to
    //reload if verify is attempted
    if (isVerifyAttempted && selectedWebsite?.verified) {
      toast.success('Your website is verified!');

      !routerReload && dispatch(hideModal('NoVerifiedWebsiteModal'));
      onVerifyComplete();
      routerReload &&
        setTimeout(() => {
          dispatch(hideModal('NoVerifiedWebsiteModal'));
          router.reload();
        }, 2000);
    }
  }, [selectedWebsite]);

  return (
    <div>
      <div className={styles.contentContainer}>
        <div className="mb-[25px]">
          <div className="font-[600] text-xs leading-[18px]">
            {t('sections:unverified-modal:customWebsiteStepOneTitle')}
          </div>
          <div className="text-left text-xs leading-[16px] mt-2.5">
            <Trans
              i18nKey="sections:unverified-modal:customWebsiteStepOneDescription"
              shouldUnescape
            />
          </div>
        </div>
        <Code
          language="htmlbars"
          code={script}
          useCopy
          copyButtonOnLeft={true}
          preTagStylings={{ overflow: 'hidden' }}
          className="embed-code max-h-[121px] overflow-hidden !pt-[5px] !px-[5px]"
          copyButtonClassName="!pb-[25px]"
        />

        <div className="mt-[30px]">
          <div className="font-[600] text-xs leading-[18px]">
            {t('sections:unverified-modal:customWebsiteStepTwoTitle')}
          </div>
          <div className="flex flex-col text-xs leading-[16px] mt-2.5">
            <Trans i18nKey="sections:unverified-modal:customWebsiteStepTwoDescription" />
          </div>

          <div
            className={cn(
              'rounded-md py-1 px-[7px] flex items-center mt-[25px] w-full',
              selectedWebsite?.verified && 'bg-greenlight',
              !selectedWebsite?.verified && 'bg-orangelight',
            )}
          >
            <Favicon url={selectedWebsite?.url} isSmall className="mr-3" />

            <Tooltip
              className="!flex  w-[60%]"
              contentClassName="!left-0 !translate-x-0 !-translate-y-full !max-w-full !break-words !whitespace-normal"
              content={selectedWebsite?.url}
            >
              <span className="overflow-hidden text-ellipsis max-w-[220px] whitespace-nowrap text-sm font-normal">
                {selectedWebsite?.url}
              </span>
            </Tooltip>
            <div
              className={cn(
                styles.badge,
                selectedWebsite?.verified
                  ? styles.verifiedBadge
                  : styles.unverifiedBadge,
              )}
            >
              <div className={styles.status}>
                <div
                  className={cn(
                    styles.icon,
                    selectedWebsite?.verified
                      ? styles.verifiedIcon
                      : styles.unverifiedIcon,
                  )}
                >
                  {selectedWebsite?.verified ? (
                    <CheckIcon width="14" height="14" viewBox="-1 -1 20 20" />
                  ) : (
                    <SandGlassIcon
                      width="14"
                      height="14"
                      viewBox="-4 -1 16 20"
                    />
                  )}
                </div>
                <div className={styles.label}>
                  {selectedWebsite?.verified
                    ? t('sections:unverified-modal:verified')
                    : t('sections:unverified-modal:waitingVerification')}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3 mt-[30px] mb-5">
            <PSButton
              type="button"
              size="sm"
              variant="primary"
              onClick={() => {
                verifyHandler(selectedWebsite?.url);
              }}
              textClassName="text-xs"
              id="no-verified-modal-verify-button"
            >
              {t('sections:unverified-modal:verifyWebsite')}
            </PSButton>
            <PSButton
              type="button"
              size="sm"
              variant="second"
              loading={isLoading}
              onClick={() => {
                refreshHandler();
              }}
              textClassName="text-xs text-[#777]"
              id="no-verified-modal-refresh-button"
            >
              {t('common:refresh')}
            </PSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultWebsiteOption;
