export const GET_AUTOMATION = 'editor/GET_AUTOMATION';
export const GET_AUTOMATION_SUCCESS = 'editor/GET_AUTOMATION_SUCCESS';
export const GET_AUTOMATION_ERROR = 'editor/GET_AUTOMATION_ERROR';

export const GET_TRIGGERS = 'editor/GET_TRIGGERS';
export const GET_TRIGGERS_SUCCESS = 'editor/GET_TRIGGERS_SUCCESS';
export const GET_TRIGGERS_ERROR = 'editor/GET_TRIGGERS_ERROR';

export const SAVE_AUTOMATION = 'editor/SAVE_AUTOMATION';
export const SAVE_AUTOMATION_SUCCESS = 'editor/SAVE_AUTOMATION_SUCCESS';
export const SAVE_AUTOMATION_ERROR = 'editor/SAVE_AUTOMATION_ERROR';

export const ORJ_DATA_TO_DATA = 'editor/ORJ_DATA_TO_DATA';

export const UPDATE_EDITOR_SIDEBAR = 'editor/UPDATE_EDITOR_SIDEBAR';

export const UPDATE_FLOW_STEP_ATTRIBUTE = 'editor/UPDATE_FLOW_STEP_ATTRIBUTE';
export const UPDATE_TRIGGER = 'editor/UPDATE_TRIGGER';
export const UPDATE_FREQUENCY_STATUS = 'editor/UPDATE_FREQUENCY_STATUS';
export const UPDATE_ISPLAINTEXT = 'editor/UPDATE_ISPLAINTEXT';
export const UPDATE_PLAINTEXT_BODY = 'editor/UPDATE_PLAINTEXT_BODY';
export const SET_FLOW = 'editor/SET_FLOW';
export const SET_AUTOMATION_STATUS = 'editor/SET_AUTOMATION_STATUS';
export const SAVE_EMAIL_TEMPLATE = 'editor/SAVE_EMAIL_TEMPLATE';
export const UPDATE_STEPS = 'editor/UPDATE_STEPS';

export const UPDATE_SHOPIFY_FLOW_WEBSITE = 'editor/UPDATE_SHOPIFY_FLOW_WEBSITE';
export const SET_AUTOMATION_CAMPAIGN = 'editor/SET_AUTOMATION_CAMPAIGN';
export const SET_AUTOMATION_NAME = 'editor/SET_AUTOMATION_NAME';
export const SET_RELATED_CAMPAIGN = 'editor/SET_RELATED_CAMPAIGN';
export const UPDATE_EMAIL_CONTENT_ITEM_ATTRIBUTE =
  'editor/UPDATE_EMAIL_CONTENT_ITEM_ATTRIBUTE';
export const UPDATE_EMAIL_CONTENT_ITEM_ELEMENT_ATTRIBUTE =
  'editor/UPDATE_EMAIL_CONTENT_ITEM_ELEMENT_ATTRIBUTE';
export const UPDATE_EMAIL_CONTENT_ITEM_VISIBILITY =
  'editor/UPDATE_EMAIL_CONTENT_ITEM_VISIBILITY';
export const ADD_EMAIL_CONTENT_ITEM_ELEMENT =
  'editor/ADD_EMAIL_CONTENT_ITEM_ELEMENT';
export const MOVE_EMAIL_CONTENT_ITEM_ELEMENT =
  'editor/MOVE_EMAIL_CONTENT_ITEM_ELEMENT';
export const REMOVE_EMAIL_CONTENT_ITEM_ELEMENT =
  'editor/REMOVE_EMAIL_CONTENT_ITEM_ELEMENT';

export const UPDATE_PLAINTEXT_FORM_ERROR = 'editor/UPDATE_PLAINTEXT_FORM_ERROR';

export const RESET = 'editor/RESET';
