import React, { useRef, useEffect, useState } from 'react';
import { PsLottie, PsLottieRef } from 'ui';

import styles from './PublishModal.module.scss';
import { random, suffle } from '@utils/array';
import publishAnimation from '@assets/lotties/publish.json';
import infinityAnimation from '@assets/lotties/infinity.json';
import { useTranslation } from 'react-i18next';

const sentences = [
  'Do great things.',
  'Ride the waves.',
  'Be kind to yourself.',
  'Fearless, love more.',
  "Don't talk about it, be about it.",
  'You are creative.',
  "It's never too late.",
  'Express yourself.',
  'Practice makes perfect.',
  'Empower yourself.',
  'Give back.',
  'All is well.',
  'Make it count.',
  'Trust yourself.',
  'Be the change.',
  'Change your thoughts, change your world.',
  'Be a victor, not a victim.',
  'Never stop exploring.',
  'Live with less.',
  'Find away.',
  'Trust the process.',
  'Find your calm.',
  'Life happens now.',
  'Choose joy.',
  'One step at a time.',
  'Do your part.',
  'Yes, you can.',
  'Make a difference.',
  'Worry less, smile more.',
  'Never give up!',
  'Never stop dreaming.',
  'Persistence, Power, Passion!',
  'Focus on the positive.',
  'Work smarter, not harder.',
  'Be kind.',
  'Live your truth.',
  'See the good.',
  'Eat the frog.',
  'Think big, live simply.',
  'Practice makes permanent.',
  'Seize the day.',
];

const pickOne = () => random(suffle(sentences));
const PublishModal = () => {
  const { t } = useTranslation();
  const heroRef = useRef() as PsLottieRef;
  const loadingRef = useRef() as PsLottieRef;
  const adviceRef = useRef<HTMLHeadingElement>(null);

  const [advice, setAdvice] = useState(pickOne);

  useEffect(() => {
    heroRef?.current?.play();
    loadingRef?.current?.play();

    const interval = setInterval(() => {
      setAdvice(pickOne);
    }, 3000);

    return () => {
      heroRef?.current?.destroy();
      loadingRef?.current?.destroy();

      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    adviceRef?.current?.classList.remove('opacity-0');
    adviceRef?.current?.classList.add('opacity-100');

    const timeout = setTimeout(() => {
      adviceRef?.current?.classList.remove('opacity-100');
      adviceRef?.current?.classList.add('opacity-0');
    }, 2300);

    return () => {
      clearTimeout(timeout);
    };
  }, [advice]);

  return (
    <div className={styles.container}>
      <PsLottie
        height={420}
        width={260}
        style={{
          width: 420,
          height: 260,
        }}
        loop={true}
        autoplay={true}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMin slice',
        }}
        animationData={publishAnimation}
        lottieRef={heroRef}
      />
      <h1
        className="text-xl font-semibold text-gray-900 lg:text-[30px] dark:text-white pt-8 transition-all duration-700 opacity-0 text-center px-4"
        ref={adviceRef}
      >
        {advice}
      </h1>
      <PsLottie
        height={55}
        width={39}
        style={{
          width: 55,
          height: 39,
        }}
        loop={true}
        autoplay={true}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMin slice',
        }}
        animationData={infinityAnimation}
        lottieRef={loadingRef}
      />
      <p className="pt-6 pb-2">{t('sections:publish-modal:description')}</p>
    </div>
  );
};

export default PublishModal;
