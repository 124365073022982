import { ReduxAction } from 'global';
import { call, put, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';

import fetch from '@utils/fetch';
import {
  ENRICH_SUBSCRIBERS,
  ENRICH_SUBSCRIBERS_FAILURE,
  ENRICH_SUBSCRIBERS_SUCCESS,
  GET_SUBSCRIBERS,
  GET_SUBSCRIBERS_FAILURE,
  GET_SUBSCRIBERS_SUCCESS,
} from './constants';
import { SubscribeItem } from './reducer';

export interface ICampaignFormDataResponse {
  campaignFormDataList: SubscribeItem[];
  rowCount: number;
  columns: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAnyObject {}

const rowModifier = (columnsArray: string[], rowData: SubscribeItem) => {
  const newRow = { ...rowData };
  for (let i = 0; i < columnsArray.length; i++) {
    const col = columnsArray[i];
    if (rowData[col] === undefined) {
      newRow[col] = null;
    }

    if (col === 'CreatedAt') {
      //We need to format the date
      const str = rowData[col];
      newRow[col] = dayjs(str).format('MM/DD/YYYY HH:mm:ss');
    }
  }
  return newRow;
};

function* handleGetSubscribers({ payload }: ReduxAction) {
  let { id } = payload;
  id = String(id);

  try {
    const result: ICampaignFormDataResponse = yield call(
      fetch,
      `api/campaign/${id}/form-data`,
      {
        method: 'POST',
        data: payload,
      },
    );
    const items = result.campaignFormDataList;
    const columns = result.columns;
    const FormData = result.columns.filter((col: string) =>
      col.includes('FormData'),
    );

    const defaultSelectedColumns = [
      'Id',
      'CreatedAt',
      ...FormData,
      'URL',
      'Referer',
      'Language',
      'Browser',
      'OS',
      'Device',
      'Resolution',
      'CountryName',
      'RegionName',
      'CityName',
    ];
    const reOrderedColumns = Array.from(
      new Set([...defaultSelectedColumns, ...columns]),
    );

    if (result && items.length > 0 && columns) {
      for (let i = 0; i < items.length; i++) {
        let current = items[i];
        current = rowModifier(columns, current);
        items[i] = current;
      }
    }
    result.campaignFormDataList = items;

    yield put({
      type: GET_SUBSCRIBERS_SUCCESS,
      payload: { ...result, columns: reOrderedColumns, defaultSelectedColumns },
    });
  } catch (error) {
    yield put({ type: GET_SUBSCRIBERS_FAILURE });
  }
}

function* handleEnrichSubscribers({ payload }: ReduxAction) {
  const { campaignId } = payload;
  try {
    const data: IAnyObject = yield call(
      fetch,
      `api/campaign/${campaignId}/enrich-form-data`,
      {
        method: 'GET',
      },
    );

    yield put({ type: ENRICH_SUBSCRIBERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ENRICH_SUBSCRIBERS_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(GET_SUBSCRIBERS, handleGetSubscribers);
  yield takeLatest(ENRICH_SUBSCRIBERS, handleEnrichSubscribers);
}
