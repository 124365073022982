export enum EmailEditorSidebarTypes {
  step = 'step',
}

export enum EditorSidebarSteps {
  Trigger = 'trigger',
  Mail = 'mail',
  Delay = 'delay',
}

export enum MailSidebarTabs {
  general = 'general',
  content = 'content',
}

export enum TriggerCodes {
  CHECKOUT_ABANDONMENT = 'checkout_abandonment',
  THANK_YOU_EMAIL = 'thank_you_1',
  WELCOME_EMAIL = 'new_subscriber',
  SEND_DISCOUNT = 'send_discount',
  NEW_SIGNUP = 'new_signup',
  FORM_SUBMISSION = 'form_submission',
}
