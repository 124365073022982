import {
  GET_AUTH_USER,
  SET_AUTH_USER,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  USER_LOGOUT,
  LOGIN_WITH_GOOGLE_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  REGISTER_WITH_INVITE_REQUEST,
  GET_USER_REQUEST,
  EDIT_USER_REQUEST,
  DELETE_USER_REQUEST,
  DELETE_SURVEY_REQUEST,
  ADD_TOKEN_MANUALLY_REQUEST,
  LOGIN_BACKOFFICE_REQUEST,
  CHANGE_SUBACCOUNT_SEEN_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './constants';

export function login(payload: any, onSuccess = () => {}, onError = () => {}) {
  return {
    type: LOGIN_REQUEST,
    payload,
    onSuccess,
    onError,
  };
}

export function logout() {
  return {
    type: USER_LOGOUT,
  };
}

export function registerUser(
  payload: any,
  onSuccess = () => {},
  onError = () => {},
) {
  return {
    type: REGISTER_REQUEST,
    payload,
    onSuccess,
    onError,
  };
}

export function setAuthUser(payload: any) {
  return {
    type: SET_AUTH_USER,
    payload,
  };
}

export function getAuthUser() {
  return {
    type: GET_AUTH_USER,
  };
}

export function loginWithGoogle(
  payload: any,
  onSuccess = () => {},
  onError = () => {},
) {
  return {
    type: LOGIN_WITH_GOOGLE_REQUEST,
    payload,
    onSuccess,
    onError,
  };
}

export function forgotPassword(payload: any) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload,
  };
}
export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
}

export function resetPassword(payload: any) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload,
  };
}
export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}
export function resetPasswordFailure() {
  return {
    type: RESET_PASSWORD_FAILURE,
  };
}

export function registerWithInvite(
  payload: any,
  onSuccess = () => {},
  onError = () => {},
) {
  return {
    type: REGISTER_WITH_INVITE_REQUEST,
    payload,
    onSuccess,
    onError,
  };
}

export function getUser() {
  return {
    type: GET_USER_REQUEST,
  };
}

export function editUser(payload: any, successFunc: any) {
  return {
    type: EDIT_USER_REQUEST,
    payload,
    successFunc,
  };
}

export function deleteUser() {
  return {
    type: DELETE_USER_REQUEST,
  };
}

export function sendDeleteAccountSurvey(payload: any) {
  return {
    type: DELETE_SURVEY_REQUEST,
    payload,
  };
}

export function addTokenManually(payload: { token: string }) {
  return {
    type: ADD_TOKEN_MANUALLY_REQUEST,
    payload,
  };
}

export function loginBackoffice(
  payload: { token: string },
  onSuccess?: () => void,
) {
  return {
    type: LOGIN_BACKOFFICE_REQUEST,
    payload,
    onSuccess,
  };
}

export function changeSubaccountsSeen() {
  return {
    type: CHANGE_SUBACCOUNT_SEEN_REQUEST,
  };
}
