import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Editor } from '@tinymce/tinymce-react';
import ErrorIcon from '@assets/icons/error-icon.svg';
import type { PSEditorProps } from './types';

import styles from './Editor.module.scss';
import getCustomDialogs from './getCustomDialog';
import SmartTagList from '@elements/SmartTag/SmartTagList';

const PSEditor: FC<PSEditorProps> = ({
  label,
  field: { value, name, onChange },
  fieldState: { error },
  disabled,
  onChangeEvent,
  height = 300,
  hiddenToolbar = false,
  checkChangesIsValidRegexp,
  className,
  showHtmlEditorDialog = true,
}) => {
  useEffect(() => {
    if (value !== '') {
      onChangeEvent && onChangeEvent(value);
    }
  }, []);

  const [smartTagOpened, setSmartTagOpened] = useState(false);
  const editorRef = useRef<Editor['editor'] | undefined>();

  const isValueValid = (checkValue: string) => {
    if (!checkChangesIsValidRegexp) return true;
    const regex = new RegExp(checkChangesIsValidRegexp);

    return regex.test(checkValue);
  };

  const handleChangeValue = (value: string) => {
    onChange(value);
    onChangeEvent && onChangeEvent(value);
  };

  const prepareTagsButton = (editor: any) => {
    editor.ui.registry.addIcon(
      'align-left',
      `<img src="/assets/icons/text-left.svg" />`,
    );
    editor.ui.registry.addIcon(
      'align-center',
      `<img src="/assets/icons/text-center.svg" />`,
    );
    editor.ui.registry.addIcon(
      'align-right',
      `<img src="/assets/icons/text-right.svg" />`,
    );
    editor.ui.registry.addIcon('bold', `<img src="/assets/icons/bold.svg" />`);
    editor.ui.registry.addIcon(
      'italic',
      `<img src="/assets/icons/italic.svg" />`,
    );
    editor.ui.registry.addIcon(
      'underline',
      `<img src="/assets/icons/underline.svg" />`,
    );
    editor.ui.registry.addIcon(
      'strike-through',
      `<img src="/assets/icons/text-strike.svg" />`,
    );
    editor.ui.registry.addIcon(
      'link',
      `<img src="/assets/icons/add-link.svg" />`,
    );
    editor.ui.registry.addIcon(
      'line-height',
      `<img src="/assets/icons/line-height.svg" />`,
    );

    editor.ui.registry.addIcon(
      'smarttag',
      `<img src="/assets/icons/smarttag.svg" />`,
    );

    if (showHtmlEditorDialog) {
      editor.ui.registry.addIcon(
        'htmlEditorDialogIcon',
        `<img src="/assets/icons/html-toolbar-icon.svg" />`,
      );
    }

    editor.ui.registry.addIcon(
      'unsubscribeLinkButtonIcon',
      `<img src="/assets/icons/unsubscribe-mail-icon.svg" />`,
    );

    if (showHtmlEditorDialog) {
      editor.ui.registry.addButton('htmlEditorDialog', {
        icon: 'htmlEditorDialogIcon',
        onAction: () => {
          const customDialogs = getCustomDialogs({
            editor,
            isValueValid,
          });

          editor.windowManager.open(customDialogs['htmlEditorDialog']);
        },
      });
    }

    editor.ui.registry.addButton('unsubscribeLinkButton', {
      icon: 'unsubscribeLinkButtonIcon',
      text: 'Unsu...',
      onAction: () => {
        editor.execCommand('mcelink');
        const handleSetDialogData = (e: {
          dialog: {
            setData: (data: object) => void;
            getData: () => { [key in string]: { value?: string } };
          };
        }) => {
          handleDisableUnSubscribeInput();

          e.dialog.setData({
            url: {
              value: '{{UnsubscribeUrl}}',
            },
            text: e.dialog.getData()?.text ?? 'unsubscribe',
            target: '',
            title: '',
          });
          editor.off('OpenWindow', handleSetDialogData);
        };
        editor.on('OpenWindow', handleSetDialogData);
      },
    });
  };

  const handleDisableUnSubscribeInput = () => {
    const toxContainer =
      document.querySelector<HTMLDivElement>('.tox-dialog-wrap');
    if (toxContainer) {
      toxContainer.style.display = 'none';

      const urlInputBlock =
        toxContainer.querySelector('.tox-control-wrap')?.parentElement
          ?.parentElement;
      const urlInput =
        toxContainer.querySelector<HTMLInputElement>('input[type="url"]');

      const dialogHeaderTextElement = document.querySelector(
        '.tox-dialog__header > div',
      );

      if (urlInput && urlInputBlock && dialogHeaderTextElement) {
        urlInput.disabled = true;
        urlInputBlock.style.opacity = '0.6';
        toxContainer.style.removeProperty('display');
        dialogHeaderTextElement.innerHTML = 'Edit unsubscribe link';
      }
    }
  };

  const handleOnMouseUpEditor = () => {
    if (smartTagOpened) {
      editorRef.current?.execCommand('closeSmartTag');
    }
  };

  const smartTagListOnOutSideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    const editorToolbarContainer =
      document.querySelector<HTMLDivElement>('.tox-editor-header');

    const isEditorToolbarSmartTagButton =
      !!editorToolbarContainer?.contains(target as Node) &&
      target.textContent === 'Smart Tag';

    if (isEditorToolbarSmartTagButton) {
      editorRef.current?.execCommand('closeSmartTag');
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <div
        className={cn({
          [styles.inputWrapper]: true,
          [styles.inputWrapperRelative]: disabled,
        })}
      >
        {label && (
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
        )}
        <Editor
          value={value}
          tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.2.0/tinymce.min.js"
          onEditorChange={handleChangeValue}
          onMouseUp={handleOnMouseUpEditor}
          init={{
            height,
            link_title: false,
            link_target_list: false,
            menubar: false,
            statusbar: false,
            plugins: ['link'],
            inline_styles: true,
            toolbar: !hiddenToolbar
              ? 'alignleft aligncenter alignright bold italic underline strikethrough link lineheight htmlEditorDialog customSmartTagToggleButton unsubscribeLinkButton'
              : false,
            content_style: 'body {font-size: 14px;line-height: 24px; }',
            disabled,
            setup: (editor) => {
              if (editor) {
                editorRef.current = editor;
              }

              editor.on('OpenWindow', (e) => {
                const dialog = e.dialog as {
                  getData: () => { [key in string]: { value?: string } };
                };
                const dialogData = dialog.getData();

                const isOpenedWindowLinkPlugin = !!dialogData?.url;
                const isLinkUnsubscribe =
                  dialogData?.url?.value === '{{UnsubscribeUrl}}';

                if (isOpenedWindowLinkPlugin && isLinkUnsubscribe) {
                  const selection = editor.selection.getNode();

                  if (!selection.classList.contains('mceNonEditable')) {
                    selection.classList.add('mceNonEditable');
                  }

                  handleDisableUnSubscribeInput();
                }
              });

              prepareTagsButton(editor);

              editor.ui.registry.addToggleButton('customSmartTagToggleButton', {
                text: 'Smar...',
                icon: 'smarttag',
                onAction: (api) => {
                  api.setActive(!api.isActive());
                  setSmartTagOpened(api.isActive());
                  editor.addCommand('closeSmartTag', () => {
                    api.setActive(false);
                    setSmartTagOpened(api.isActive());
                  });
                },
              });
            },
            content_css: '/assets/css/tinymce-content.css',
          }}
        />
        <SmartTagList
          size="md"
          setText={(text: string) => {
            if (editorRef.current) {
              editorRef.current.insertContent(text);
              editorRef.current.execCommand('closeSmartTag');
            }
          }}
          isShow={smartTagOpened}
          setIsShow={(show) => {
            setSmartTagOpened(!show);
          }}
          customPositions={{
            top: 40,
            left: 212,
          }}
          usePortal={false}
          onClickOutside={smartTagListOnOutSideClick}
        />
      </div>

      {!!error && (
        <div className={styles.errorContainer}>
          <ErrorIcon width="18px" height="18px" className={styles.icon} />
          <p className={styles.message}>{error?.message || 'Invalid'}</p>
        </div>
      )}
    </div>
  );
};

export default PSEditor;
