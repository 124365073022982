import { TinyMCEDialogOnSubmitParams } from './types';
import type { TinyMCE } from 'tinymce';

const getCustomDialogs = (params: {
  editor: TinyMCE & { getContent: () => string };
  isValueValid: (checkValue: string) => boolean;
}) => {
  const htmlEditorDialog = {
    title: 'HTML Editor',
    size: 'large',
    body: {
      type: 'panel',
      items: [
        {
          type: 'textarea',
          name: 'htmlValue',
          label: '',
          minHeight: 500,
        },
      ],
    },
    buttons: [
      {
        type: 'cancel',
        name: 'closeButton',
        text: 'Cancel',
        height: 500,
      },
      {
        type: 'submit',
        name: 'submitButton',
        text: 'Save',
        buttonType: 'primary',
      },
    ],
    initialData: {
      htmlValue: params?.editor?.getContent() ?? '',
    },
    onSubmit: (api: TinyMCEDialogOnSubmitParams) => {
      const data = api.getData();

      const test = document.querySelector('.tox-textarea');

      const createErrorContainer = () => {
        const errorContainer = document.createElement('div');
        errorContainer.id = 'htmlEditorDialogErrorContainer';
        errorContainer.style.display = 'flex';
        errorContainer.style.gap = '3px';
        errorContainer.style.color = '#ea0f0f';
        errorContainer.style.marginTop = '10px';

        const span = document.createElement('span');
        span.innerHTML = 'Unsubscribe tag must be included. {{UnsubscribeUrl}}';
        span.style.fontWeight = '400';
        span.style.fontSize = '12px';
        span.style.lineHeight = '15px';
        span.style.display = 'flex';
        span.style.justifyContent = 'center';
        span.style.alignItems = 'center';

        const img = document.createElement('img');
        img.src = '/assets/icons/error-icon.svg';
        img.style.filter =
          'invert(21%) sepia(78%) saturate(7383%) hue-rotate(357deg) brightness(95%) contrast(95%)';

        errorContainer.appendChild(img);
        errorContainer.appendChild(span);
        return errorContainer;
      };

      const currentErrorContainer = document.getElementById(
        'htmlEditorDialogErrorContainer',
      );

      const isValidValue = params.isValueValid(data.htmlValue);

      if (!isValidValue) {
        if (test) {
          test.after(currentErrorContainer ?? createErrorContainer());
        }
        return;
      }

      params.editor.execCommand('mceSetContent', true, data.htmlValue);
      api.close();
    },
  };
  return { htmlEditorDialog };
};

export default getCustomDialogs;
