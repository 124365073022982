import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';

import {
  GET_CAMPAIGNS_SUCCESS,
  FILTER_CAMPAIGNS,
  GET_CAMPAIGNS_FAILURE,
  GET_CAMPAIGNS_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_REQUEST,
  DUPLICATE_CAMPAIGN_SUCCESS,
  DUPLICATE_CAMPAIGN_FAILURE,
  DUPLICATE_CAMPAIGN_REQUEST,
  RENAME_CAMPAIGN_SUCCESS,
  RENAME_CAMPAIGN_FAILURE,
  RENAME_CAMPAIGN_REQUEST,
  CHANGE_CAMPAIGN_STATUS_SUCCESS,
  CHANGE_CAMPAIGN_STATUS_FAILURE,
  GET_ALL_CAMPAIGNS_REQUEST,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_ALL_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN_WITH_TEMPLATE_SUCCESS,
  CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST,
  CREATE_CAMPAIGN_WITH_TEMPLATE_FAILURE,
  GET_CAMPAIGNS_ANALYTICS_SUCCESS,
  GET_CAMPAIGNS_ANALYTICS_REQUEST,
  GET_CAMPAIGNS_ANALYTICS_FAILURE,
  CLEAN_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_COUNTS,
  GET_CAMPAIGNS_COUNTS_SUCCESS,
  GET_CAMPAIGNS_COUNTS_FAILURE,
  CLEAN_CAMPAIGN_WITH_TEMPLATE_SUCCESS,
  ADD_PENDING_CAMPAIGN,
  RESET_PENDING_CAMPAIGN,
  INCREASE_CAMPAIGNS_COUNTS,
} from './constants';
import { ThemeData } from 'core';
import ReduxStatus from '@enums/ReduxStatus';
import { CampaignDailyAnalytics } from './types';

export interface DomainObject {
  id: number;
  lastImpressionDate?: string;
  url: string;
  userId?: number;
  verified: boolean;
  isShopify: boolean;
}

export interface Campaign {
  id: number;
  name: string;
  status?: boolean;
  isActive: boolean;
  publishVersion: number;
  domains: DomainObject[];
  targets?: object;
  layout?: object;
  settings?: object;
  isTemplateSelected?: boolean;
  createdAt: string;
  variantInfo: {
    variantId: number;
    abTestingId: number;
    variant: { name: string; percentage: number };
  };
}

export interface CreateCampaignWithTemplateId {
  id: number;
  name: string;
  status: 'success' | 'errored' | 'loading' | 'initialized';
}

export interface CampaignsAnalytics {
  campaignDailyAnalytics: CampaignDailyAnalytics[];
  status: ReduxStatus;
}

export type PendingThemeData = {
  code: string;
  data: ThemeData;
};
type PendingCampaign = {
  templateId: number;
  theme?: PendingThemeData;
};

export interface CampaignState {
  items: Campaign[];
  filteredCampaignData: { total: number; items: Campaign[] };
  allCampaigns: Campaign[];
  status: ReduxStatus;
  countStatus: ReduxStatus;
  //i'll put deleted campaign ids here, and use this data as
  //useEffect dependency in dashboard
  deletedCampaigns: string[];
  duplicatedCampaigns: Campaign[];
  pendingCampaign: PendingCampaign | null;
  totalCampaignCount: number;
  sidebarCampaignCount: number;
  totalPageCount: number;
  createCampaignWithTemplateId: CreateCampaignWithTemplateId;
  hasFullAccess?: boolean;
  canCreateCampaign: boolean;
  analytics: CampaignsAnalytics;
}

export const initialState: CampaignState = {
  items: [],
  filteredCampaignData: { total: 0, items: [] },
  allCampaigns: [],
  status: ReduxStatus.initialized,
  countStatus: ReduxStatus.initialized,
  deletedCampaigns: [],
  duplicatedCampaigns: [],
  pendingCampaign: null,
  totalCampaignCount: 0,
  sidebarCampaignCount: 0,
  totalPageCount: 0,
  createCampaignWithTemplateId: { id: 0, name: '', status: 'initialized' },
  hasFullAccess: false,
  canCreateCampaign: false,
  analytics: { campaignDailyAnalytics: [], status: ReduxStatus.initialized },
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case GET_CAMPAIGNS_REQUEST:
    case DELETE_CAMPAIGN_REQUEST:
    case GET_ALL_CAMPAIGNS_REQUEST:
      return {
        ...state,
        status: ReduxStatus.loading,
        countStatus: ReduxStatus.loading,
      };
    case DUPLICATE_CAMPAIGN_REQUEST:
    case RENAME_CAMPAIGN_REQUEST:
      return { ...state };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        items: [...action.payload.campaigns],
        totalCampaignCount: action.payload.total,
        sidebarCampaignCount: action.payload.total,
        hasFullAccess: action.payload.hasFullAccess,
        canCreateCampaign: action.payload.canCreateCampaign,
        status: ReduxStatus.success,
        countStatus: ReduxStatus.success,
      };
    case FILTER_CAMPAIGNS: {
      const filterSortAndPaginateCampaigns = (
        array: Campaign[],
        skip: number,
        limit: number,
        order: 'ASC' | 'DESC',
        sortBy: 'Name' | 'Status' | 'VariantInfo' | 'CreatedAt',
        searchedName: string = '',
        selectedDomainIds: string[] = [],
      ) => {
        let result: Campaign[] = [];
        const abTestingGroups: Record<number, Campaign> = {};

        array.forEach((item) => {
          const { variantInfo, createdAt } = item;
          if (variantInfo?.abTestingId) {
            const abTestingId = variantInfo.abTestingId;
            if (!abTestingGroups[abTestingId]) {
              abTestingGroups[abTestingId] = item;
            } else {
              const existingItem = abTestingGroups[abTestingId];
              if (new Date(createdAt) < new Date(existingItem.createdAt)) {
                abTestingGroups[abTestingId] = item;
              }
            }
          } else {
            result.push(item);
          }
        });

        result.push(...Object.values(abTestingGroups));

        const totalCampaignCount = result.length;

        if (selectedDomainIds.length > 0) {
          const numericDomainIds = selectedDomainIds.map((id) => Number(id));

          result = result.filter((item) => {
            const hasMatchingDomain =
              item.domains &&
              item.domains.some((domain: any) =>
                numericDomainIds.includes(domain.id),
              );

            return hasMatchingDomain;
          });
        }

        if (searchedName) {
          const searchedNameVariantIdsToFilter = array
            .filter(
              (item) =>
                item.variantInfo?.variant.name
                  .toLowerCase()
                  .includes(searchedName.toLowerCase()),
            )
            .map((item) => item.variantInfo.abTestingId);

          result = result.filter((item) => {
            const matchesVariantName =
              item.variantInfo?.abTestingId &&
              searchedNameVariantIdsToFilter.includes(
                item.variantInfo.abTestingId,
              );

            const matchesCampaignName = item.name
              .toLowerCase()
              .includes(searchedName.toLowerCase());

            return matchesVariantName || matchesCampaignName;
          });
        }

        result.sort((a, b) => {
          let valueA: string | number;
          let valueB: string | number;

          switch (sortBy) {
            case 'Name':
              valueA = a.name.toLowerCase();
              valueB = b.name.toLowerCase();
              break;
            case 'Status':
              valueA = a.isActive ? 1 : 0;
              valueB = b.isActive ? 1 : 0;
              break;
            case 'VariantInfo':
              valueA = a.variantInfo ? 1 : 0;
              valueB = b.variantInfo ? 1 : 0;
              break;
            case 'CreatedAt':
            default:
              valueA = new Date(a.createdAt).getTime();
              valueB = new Date(b.createdAt).getTime();
              break;
          }

          if (order === 'ASC') {
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          } else if (order === 'DESC') {
            return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
          }

          return 0;
        });

        const paginatedResult = result.slice(skip, skip + limit);

        return {
          total: totalCampaignCount,
          items: paginatedResult,
        };
      };

      const { skip, limit, order, sortBy, searchedName, selectedDomainIds } =
        action.payload;

      const filteredAndSortedCampaigns = filterSortAndPaginateCampaigns(
        state.items,
        skip,
        limit,
        order,
        sortBy,
        searchedName,
        selectedDomainIds,
      );

      return {
        ...state,
        filteredCampaignData: filteredAndSortedCampaigns,
      };
    }

    case GET_CAMPAIGNS_COUNTS:
      return { ...state, countStatus: ReduxStatus.loading };

    case INCREASE_CAMPAIGNS_COUNTS:
      return {
        ...state,
        totalCampaignCount: state.totalCampaignCount + 1,
        sidebarCampaignCount: state.sidebarCampaignCount + 1,
      };

    case GET_CAMPAIGNS_COUNTS_SUCCESS:
      return {
        ...state,
        sidebarCampaignCount: action.payload.total,
        countStatus: ReduxStatus.success,
      };
    case GET_ALL_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        allCampaigns: [...action.payload],
        status: ReduxStatus.success,
      };
    case CLEAN_CAMPAIGNS_REQUEST:
      return {
        ...state,
        allCampaigns: [],
        status: ReduxStatus.success,
      };

    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        items: state.items.filter((x) => x.id !== action.payload),
        totalCampaignCount: state.totalCampaignCount - 1,
        createCampaignWithTemplateId: {
          id: 0,
          name: '',
          status: 'initialized',
        },
        deletedCampaigns: [...state.deletedCampaigns, action.payload],
      };
    case DUPLICATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        items: [...state.items],
        duplicatedCampaigns: [...state.duplicatedCampaigns],
        status: ReduxStatus.success,
      };
    case RENAME_CAMPAIGN_SUCCESS: {
      const { id, name } = action.payload;
      const newCampaignsArray = [...state.items];
      const index = state.items.findIndex((x: any) => x.id === id);
      newCampaignsArray[index].name = name;

      return {
        ...state,
        items: newCampaignsArray,
        status: ReduxStatus.success,
      };
    }

    case CHANGE_CAMPAIGN_STATUS_SUCCESS: {
      const campaignId = action.payload;
      const newCampaigns = state.items.map((x: any) => {
        if (x.id === campaignId) {
          x.isActive = !x.isActive;
        }
        return x;
      });
      return { ...state, items: newCampaigns, status: ReduxStatus.success };
    }

    case ADD_PENDING_CAMPAIGN: {
      const { templateId, selectedTheme } = action.payload;

      return {
        ...state,
        pendingCampaign: {
          templateId,
          ...(selectedTheme ? { theme: selectedTheme } : {}),
        },
        status: ReduxStatus.success,
      };
    }

    case DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        status: ReduxStatus.errored,
      };
    case DUPLICATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        status: ReduxStatus.errored,
      };
    case RENAME_CAMPAIGN_FAILURE:
      return {
        ...state,
        status: ReduxStatus.errored,
      };

    case CHANGE_CAMPAIGN_STATUS_FAILURE: {
      const campaignId = action.payload;
      const newCampaigns = state.items.map((x: any) => {
        if (x.id === campaignId) {
          x.isActive = !x.isActive;
        }
        return x;
      });
      return { ...state, items: newCampaigns, status: ReduxStatus.success };
    }

    case GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        status: ReduxStatus.errored,
      };
    case GET_CAMPAIGNS_COUNTS_FAILURE:
      return {
        ...state,
        status: ReduxStatus.errored,
        countStatus: ReduxStatus.errored,
      };
    case GET_ALL_CAMPAIGNS_FAILURE:
      return {
        ...state,
        status: ReduxStatus.errored,
      };

    case CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST:
      return {
        ...state,
        createCampaignWithTemplateId: {
          id: 0,
          name: '',
          status: 'loading',
        },
      };

    case CREATE_CAMPAIGN_WITH_TEMPLATE_SUCCESS:
      return {
        ...state,
        createCampaignWithTemplateId: {
          id: action.payload.id,
          name: action.payload.name,
          status: ReduxStatus.success,
        },
      };

    case CREATE_CAMPAIGN_WITH_TEMPLATE_FAILURE:
      return {
        ...state,
        createCampaignWithTemplateId: {
          id: 0,
          name: '',
          status: 'errored',
        },
      };
    case CLEAN_CAMPAIGN_WITH_TEMPLATE_SUCCESS:
      return {
        ...state,
        createCampaignWithTemplateId: {
          ...state.createCampaignWithTemplateId,
          status: 'initialized',
        },
      };

    case RESET_PENDING_CAMPAIGN: {
      return { ...state, pendingCampaign: null, status: ReduxStatus.success };
    }

    case GET_CAMPAIGNS_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics: {
          campaignDailyAnalytics: [],
          status: ReduxStatus.loading,
        },
      };

    case GET_CAMPAIGNS_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: {
          campaignDailyAnalytics: action.payload.campaignDailyAnalytics,
          status: ReduxStatus.success,
        },
      };

    case GET_CAMPAIGNS_ANALYTICS_FAILURE:
      return {
        ...state,
        analytics: {
          campaignDailyAnalytics: [],
          status: ReduxStatus.errored,
        },
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
