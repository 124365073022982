import { takeLatest, put, call, select, delay } from 'redux-saga/effects';

import {
  GetAllSegmentAction,
  Segment,
  GetActiveSegmentAction,
  UpdateSegmentAction,
  CreateSegmentAction,
  ActiveSegment,
  SelectActiveSegmentAction,
  UpdateChangeViewVisibilityAction,
} from './types';

import {
  CREATE_SEGMENT,
  GET_ALL_SEGMENTS,
  GET_ACTIVE_SEGMENT,
  SELECT_ACTIVE_SEGMENT,
  UPDATE_SEGMENT,
  SET_SELECTED_SEGMENT,
  UPDATE_CHANGE_VIEW_VISIBILITY,
} from './constants';
import {
  createSegmentError,
  createSegmentSuccess,
  getAllSegmentsError,
  getAllSegmentsSuccess,
  getActiveSegmentError,
  getActiveSegmentSuccess,
  resetSegment,
  selectActiveSegmentError,
  setSegmentTargetsCopy,
  updateSegmentError,
  updateSegmentSuccess,
  setSelectedSegmentSuccess,
  setSelectedSegmentError,
  updateChangeViewVisibilitySuccess,
} from './actions';
import { saveTargets } from '@connectors/builder/target/actions';
import fetch from '@utils/fetch';
import graphqlFetch from '@utils/graphqlFetch';

function* handleGetAllSegment(action: GetAllSegmentAction) {
  try {
    const { id } = action.payload;
    const graphqlQuery = {
      operationName: 'getSegments',
      query: `query getSegments($campaignId: Int!) {
        getSegments(campaignId: $campaignId) {
          id
          name
          description
          icon
          color
          isPredefined
          segmentCategoryId
          segmentCategoryName
          createdAt
          targets
          campaigns {
            id
            name
          }
          isDeletable
        }
      }`,
      variables: {
        campaignId: id,
      },
    };
    const data: {
      data: {
        getSegments: Segment[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    const segments = data?.data?.getSegments;

    yield put(getAllSegmentsSuccess({ data: segments }));
  } catch (error) {
    yield put(getAllSegmentsError());
  }
}

function* handleGetActiveSegment(action: GetActiveSegmentAction) {
  const { id } = action.payload;
  const graphqlQuery = {
    operationName: 'getSegment',
    query: `query getSegment($getSegmentId: Int!) {
      getSegment(id: $getSegmentId) {
        id
        name
        description
        icon
        color
        isPredefined
        segmentCategoryId
        segmentCategoryName
        createdAt
        targets
        campaigns {
          id
          name
        }
        isDeletable
        key
      }
    }`,
    variables: {
      getSegmentId: id,
    },
  };

  try {
    const data: {
      data: {
        getSegment: {
          id: number;
          name: string;
          description: string;
          icon: string;
          color: string;
          isPredefined: boolean;
          segmentCategoryId: number;
          segmentCategoryName: string;
          createdAt: string;
          targets?: string;
          campaigns: {
            id: number;
            name: string;
          }[];
          isDeletable: boolean;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    const segment = {
      ...data.data.getSegment,
      targets: JSON.parse(data.data.getSegment.targets!),
    };

    yield put(getActiveSegmentSuccess({ data: segment }));
    yield put(setSegmentTargetsCopy(segment.targets));
  } catch (error) {
    yield put(getActiveSegmentError());
  }
}

function* handleUpdateSegment(action: UpdateSegmentAction) {
  try {
    const { id, name, description, targets, reset, onSuccess } = action.payload;
    const graphqlQuery = {
      operationName: 'updateSegment',
      query: `mutation updateSegment($segment: CreateSegment!, $updateSegmentId: Int!) {
        updateSegment(segment: $segment, id: $updateSegmentId) {
          id
          name
          description
          icon
          color
          isPredefined
          segmentCategoryId
          segmentCategoryName
          createdAt
          targets
          campaigns {
            id
            name
          }
          isDeletable
        }
      }`,
      variables: {
        segment: {
          Description: description,
          Name: name,
          Targets: JSON.stringify(targets),
        },
        updateSegmentId: id,
      },
    };

    const data: {
      data: {
        updateSegment: {
          id: number;
          name: string;
          description: string;
          icon: string;
          color: string;
          isPredefined: boolean;
          segmentCategoryId: number;
          segmentCategoryName: string;
          createdAt: string;
          targets?: string;
          campaigns: {
            id: number;
            name: string;
          }[];
          isDeletable: boolean;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    const updatedSegment = {
      ...data.data.updateSegment,
      targets: JSON.parse(data.data.updateSegment.targets!),
    };

    if (reset) {
      yield put(resetSegment());
    } else {
      yield put(updateSegmentSuccess({ data: updatedSegment }));
    }

    onSuccess();
  } catch (error) {
    yield put(updateSegmentError());
  }
}

function* handleCreateSegment(action: CreateSegmentAction) {
  try {
    const { name, description, targets, onSuccess } = action.payload;
    const data: ActiveSegment = yield call(fetch, `api/Segment`, {
      method: 'POST',
      data: {
        name,
        description,
        targets,
      },
    });

    yield put(createSegmentSuccess({ data }));
    onSuccess && onSuccess(data);
  } catch (error) {
    yield put(createSegmentError());
  }
}

function* handleSelectActiveSegment(action: SelectActiveSegmentAction) {
  try {
    const { id, handleOnSuccess } = action.payload;
    const graphqlQuery = {
      operationName: 'getSegment',
      query: `query getSegment($getSegmentId: Int!) {
        getSegment(id: $getSegmentId) {
          id
          name
          description
          icon
          color
          isPredefined
          segmentCategoryId
          segmentCategoryName
          createdAt
          targets
          campaigns {
            id
            name
          }
          isDeletable
          key
        }
      }`,
      variables: {
        getSegmentId: id,
      },
    };
    const data: {
      data: {
        getSegment: {
          id: number;
          name: string;
          description: string;
          icon: string;
          color: string;
          isPredefined: boolean;
          segmentCategoryId: number;
          segmentCategoryName: string;
          createdAt: string;
          targets?: string;
          campaigns: {
            id: number;
            name: string;
          }[];
          isDeletable: boolean;
          key: string;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    const segment = {
      ...data.data.getSegment,
      targets: JSON.parse(data.data.getSegment.targets!),
    };

    yield put(
      saveTargets({
        targets: {
          ...segment.targets,
          segmentId: segment.id,
          segmentName: segment.name,
          segmentDescription: segment.description,
          segmentColor: segment.color,
          segmentIcon: segment.icon,
          segmentKey: segment.key,
        },
        updateTargetCopy: true,
      }),
    );
    yield put(setSelectedSegmentSuccess({ data: segment }));
    yield delay(500);
    if (handleOnSuccess) handleOnSuccess();
  } catch (error) {
    yield put(selectActiveSegmentError());
  }
}

function* handleUpdateChangeViewVisibility(
  action: UpdateChangeViewVisibilityAction,
) {
  const targetId: number | undefined = yield select(
    (state) => state.builder.data?.targets?.segmentId,
  );
  try {
    const { isVisible, updateSelected } = action.payload;

    if (updateSelected !== false) {
      const id = typeof updateSelected === 'number' ? updateSelected : targetId;

      if (id) {
        yield put({ payload: { id }, type: SET_SELECTED_SEGMENT });
      }
    }

    yield put(updateChangeViewVisibilitySuccess({ isVisible }));
  } catch (error) {
    yield put(setSelectedSegmentError());
  }
}

function* handleSetSelectedSegment(action: GetActiveSegmentAction) {
  try {
    const { id } = action.payload;
    const graphqlQuery = {
      operationName: 'getSegment',
      query: `query getSegment($getSegmentId: Int!) {
        getSegment(id: $getSegmentId) {
          id
          name
          description
          icon
          color
          isPredefined
          segmentCategoryId
          segmentCategoryName
          createdAt
          targets
          campaigns {
            id
            name
          }
          isDeletable
          key
        }
      }`,
      variables: {
        getSegmentId: id,
      },
    };
    const data: {
      data: {
        getSegment: {
          id: number;
          name: string;
          description: string;
          icon: string;
          color: string;
          isPredefined: boolean;
          segmentCategoryId: number;
          segmentCategoryName: string;
          createdAt: string;
          targets?: string;
          campaigns: {
            id: number;
            name: string;
          }[];
          isDeletable: boolean;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    const segment = {
      ...data.data.getSegment,
      targets: JSON.parse(data.data.getSegment.targets!),
    };

    yield put(setSelectedSegmentSuccess({ data: segment }));
  } catch (error) {
    yield put(setSelectedSegmentError());
  }
}

export default function* sagas() {
  yield takeLatest(GET_ALL_SEGMENTS, handleGetAllSegment);
  yield takeLatest(GET_ACTIVE_SEGMENT, handleGetActiveSegment);
  yield takeLatest(UPDATE_SEGMENT, handleUpdateSegment);
  yield takeLatest(CREATE_SEGMENT, handleCreateSegment);
  yield takeLatest(SELECT_ACTIVE_SEGMENT, handleSelectActiveSegment);
  yield takeLatest(SET_SELECTED_SEGMENT, handleSetSelectedSegment);
  yield takeLatest(
    UPDATE_CHANGE_VIEW_VISIBILITY,
    handleUpdateChangeViewVisibility,
  );
}
