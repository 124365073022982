import type { UrlBrowsing, UrlBrowsingRule } from 'core/types';
import type { Targets } from '@connectors/targets/reducer';
import React from 'react';
import { getBool } from 'core/helpers/bool';

import TimelineContent from '../TimelineContent';

import LinkIcon from '@assets/icons/link.svg';

export const renderUrlBrowsing = (
  urlBrowsing: Targets['urlBrowsing'],
  translate: Function,
) => {
  if (urlBrowsing === undefined) return;

  const urlBrowsingAll = urlBrowsing['all'];

  if (!urlBrowsingAll) return;

  const {
    include = [],
    exclude = [],
    targetAll = true,
  } = urlBrowsing['all'] as UrlBrowsing;

  if (getBool(targetAll)) {
    return (
      <>
        <TimelineContent
          icon={<LinkIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate('sections:targeting-summary-url-browsing:message')}
        />
      </>
    );
  }

  const all = [include, exclude].filter((i) => i.length > 0);
  const getType = (type: UrlBrowsingRule['type']) => {
    switch (type) {
      case 'MATCHES':
        return translate('common:matches').toLowerCase();
      case 'CONTAINS':
        return translate('common:contains').toLowerCase();
      case 'STARTSWITH':
        return translate('common:startsWith').toLowerCase();
      case 'ENDSWITH':
        return translate('common:endsWith').toLowerCase();
      default:
        return type;
    }
  };
  return (
    <>
      {all.map((group, i) => {
        const visibilityText =
          i === 0 && include.length !== 0
            ? translate('sections:targeting-summary-url-browsing:viewedMessage')
            : translate(
                'sections:targeting-summary-url-browsing:hiddenMessage',
              );

        return (
          <TimelineContent
            icon={<LinkIcon width="16" height="16" viewBox="0 0 36 36" />}
            key={i}
            label={visibilityText}
          >
            {group.map((item: UrlBrowsingRule, j: number) => {
              const type = getType(item.type);
              const operator = translate(
                `common:${item.operator.toLowerCase()}`,
              ).toLowerCase();
              const subOperator = translate(
                `common:${item.children.operator.toLowerCase()}`,
              ).toLowerCase();

              return (
                <span key={j} className="flex items-start font-inter text-xs">
                  {j > 0 && (
                    <>
                      <strong>{operator}</strong>&nbsp;
                    </>
                  )}
                  {`${type} `}
                  {item.children.values.map(({ value }, k) => {
                    let str = '';
                    if (item.type === 'MATCHES') {
                      value = value.replace(/^\/?(.*)/, (_, g) => `/${g}`);
                    }

                    if (k > 0) {
                      str += ` ${subOperator} `;
                    }

                    str += `${value} `;
                    return str.trimEnd();
                  })}
                </span>
              );
            })}
          </TimelineContent>
        );
      })}
    </>
  );
};
