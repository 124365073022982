import {
  ACTIVECAMPAIGN_CHANGESTATUS,
  ACTIVECAMPAIGN_CHANGESTATUS_FAILURE,
  ACTIVECAMPAIGN_CHANGESTATUS_SUCCESS,
  ACTIVECAMPAIGN_CLEAR_STATE,
  ACTIVECAMPAIGN_DELETE,
  ACTIVECAMPAIGN_DELETE_FAILURE,
  ACTIVECAMPAIGN_GET_CONNECTIONS,
  ACTIVECAMPAIGN_GET_CONNECTIONS_FAILURE,
  ACTIVECAMPAIGN_GET_CONNECTIONS_SUCCESS,
  ACTIVECAMPAIGN_GET_FIELDS,
  ACTIVECAMPAIGN_GET_FIELDS_FAILURE,
  ACTIVECAMPAIGN_GET_FIELDS_SUCCESS,
  ACTIVECAMPAIGN_GET_LIST,
  ACTIVECAMPAIGN_GET_LIST_FAILURE,
  ACTIVECAMPAIGN_GET_LIST_SUCCESS,
  ACTIVECAMPAIGN_SAVE,
  ACTIVECAMPAIGN_SAVE_FAILURE,
  ACTIVECAMPAIGN_SAVE_SUCCESS,
} from './constants';
import {
  ActiveCampaignConnectionsOutput,
  ActiveCampaignDeleteInput,
  ActiveCampaignFieldsInput,
  ActiveCampaignFieldsOutput,
  ActiveCampaignListsInput,
  ActiveCampaignListsOutput,
  ActiveCampaignSaveActionPayload,
  ActiveCampaignSaveOutput,
} from './type';

export function getActiveCampaignConnections() {
  return {
    type: ACTIVECAMPAIGN_GET_CONNECTIONS,
  };
}
export function getActiveCampaignConnectionsSuccess(
  payload: ActiveCampaignConnectionsOutput,
) {
  return {
    type: ACTIVECAMPAIGN_GET_CONNECTIONS_SUCCESS,
    payload,
  };
}
export function getActiveCampaignConnectionsFailure() {
  return {
    type: ACTIVECAMPAIGN_GET_CONNECTIONS_FAILURE,
  };
}

export function getActiveCampaignFields(payload: ActiveCampaignFieldsInput) {
  const { apiKey, account, onSuccess } = payload;
  return {
    type: ACTIVECAMPAIGN_GET_FIELDS,
    payload: {
      apiKey,
      account,
      onSuccess,
    },
  };
}

export function getActiveCampaignFieldsSuccess(
  payload: ActiveCampaignFieldsOutput,
) {
  return {
    type: ACTIVECAMPAIGN_GET_FIELDS_SUCCESS,
    payload,
  };
}

export function getActiveCampaignFieldsFailure() {
  return {
    type: ACTIVECAMPAIGN_GET_FIELDS_FAILURE,
  };
}

export function getActiveCampaignList(payload: ActiveCampaignListsInput) {
  return {
    type: ACTIVECAMPAIGN_GET_LIST,
    payload,
  };
}

export function getActiveCampaignListSuccess(
  payload: ActiveCampaignListsOutput,
) {
  return {
    type: ACTIVECAMPAIGN_GET_LIST_SUCCESS,
    payload,
  };
}

export function getActiveCampaignListFailure() {
  return {
    type: ACTIVECAMPAIGN_GET_LIST_FAILURE,
  };
}

export function saveActiveCampaign(payload: ActiveCampaignSaveActionPayload) {
  return {
    type: ACTIVECAMPAIGN_SAVE,
    payload,
  };
}
export function saveActiveCampaignSuccess(payload: ActiveCampaignSaveOutput) {
  return {
    type: ACTIVECAMPAIGN_SAVE_SUCCESS,
    payload,
  };
}
export function saveActiveCampaignFailure() {
  return {
    type: ACTIVECAMPAIGN_SAVE_FAILURE,
  };
}

export function deleteActiveCampaign(payload: ActiveCampaignDeleteInput) {
  return {
    type: ACTIVECAMPAIGN_DELETE,
    payload,
  };
}

export function deleteActiveCampaignFailure() {
  return {
    type: ACTIVECAMPAIGN_DELETE_FAILURE,
  };
}

export function changeStatusActiveCampaign(id: number) {
  return {
    type: ACTIVECAMPAIGN_CHANGESTATUS,
    payload: {
      id,
    },
  };
}
export function changeStatusActiveCampaignSuccess(id: number) {
  return {
    type: ACTIVECAMPAIGN_CHANGESTATUS_SUCCESS,
    payload: {
      id,
    },
  };
}
export function changeStatusActiveCampaignFailure() {
  return {
    type: ACTIVECAMPAIGN_CHANGESTATUS_FAILURE,
  };
}
export function clearActiveCampaignState() {
  return {
    type: ACTIVECAMPAIGN_CLEAR_STATE,
  };
}
