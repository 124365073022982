import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  SENDGRID_GET_FIELDS,
  SENDGRID_GET_FIELDS_SUCCESS,
  SENDGRID_GET_FIELDS_FAILURE,
  SENDGRID_GET_CONNECTIONS,
  SENDGRID_GET_CONNECTIONS_SUCCESS,
  SENDGRID_GET_CONNECTIONS_FAILURE,
  SENDGRID_GET_LIST,
  SENDGRID_GET_LIST_SUCCESS,
  SENDGRID_GET_LIST_FAILURE,
  SENDGRID_SAVE,
  SENDGRID_SAVE_SUCCESS,
  SENDGRID_SAVE_FAILURE,
  SENDGRID_CHANGESTATUS,
  SENDGRID_CHANGESTATUS_FAILURE,
  SENDGRID_CHANGESTATUS_SUCCESS,
  SENDGRID_DELETE,
  SENDGRID_DELETE_FAILURE,
  SENDGRID_DELETE_SUCCESS,
  SENDGRID_CLEAR_STATE,
} from './constants';
import { SendgridState } from './type';
import produce, { Draft } from 'immer';

const initialState: SendgridState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<SendgridState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case SENDGRID_GET_FIELDS:
      draft.fields = { items: [], status: ReduxStatus.loading };
      return;
    case SENDGRID_GET_FIELDS_SUCCESS:
      draft.fields = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case SENDGRID_GET_FIELDS_FAILURE:
      draft.fields = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;
    case SENDGRID_GET_LIST:
      draft.list = {
        items: [],
        status: ReduxStatus.loading,
      };
      return;
    case SENDGRID_GET_LIST_SUCCESS:
      draft.list = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case SENDGRID_GET_LIST_FAILURE:
      draft.list = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;

    case SENDGRID_GET_CONNECTIONS:
      draft.connection = { items: [], status: ReduxStatus.loading };
      return;
    case SENDGRID_GET_CONNECTIONS_SUCCESS:
      draft.connection = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case SENDGRID_GET_CONNECTIONS_FAILURE:
      draft.connection = { items: [], status: ReduxStatus.errored };
      return;

    case SENDGRID_SAVE:
      draft.status = ReduxStatus.loading;
      return;
    case SENDGRID_SAVE_SUCCESS:
      draft.list = { items: [], status: ReduxStatus.initialized };
      draft.fields = { items: [], status: ReduxStatus.initialized };
      draft.status = ReduxStatus.success;
      return;
    case SENDGRID_SAVE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case SENDGRID_DELETE:
      draft.status = ReduxStatus.loading;
      return;
    case SENDGRID_DELETE_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case SENDGRID_DELETE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case SENDGRID_CHANGESTATUS:
      draft.status = ReduxStatus.loading;
      return;
    case SENDGRID_CHANGESTATUS_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case SENDGRID_CHANGESTATUS_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case SENDGRID_CLEAR_STATE:
      return initialState;
    default:
      return;
  }
}, initialState);

export default reducer;
