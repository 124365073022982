enum CustomizeSidebarTypes {
  tree = 'tree',
  edit = 'edit',
  editFormElement = 'edit-form-element',
  editShopifyElement = 'edit-shopify-element',
  add = 'add',
  addFormElement = 'add-form-element',
  appearance = 'appearance',
  createNewStep = 'create-new-step',
  teaserEdit = 'teaser-edit',
}

export default CustomizeSidebarTypes;
