import React, { FC } from 'react';
import cn from 'classnames';

import PSButton from '@elements/PSButton';
import Code from '@elements/Code';

import styles from '../ModalEmbedCode.module.scss';

const path = process.env.NEXT_PUBLIC_BUNDLE_PATH;

import Image from 'next/image';
import personImageOne from '@assets/extends/person-circles/1.png';
import personImageTwo from '@assets/extends/person-circles/3.png';
import personImageThree from '@assets/extends/person-circles/4.png';

interface DefaultProps {
  accountId: string;
  onClose: () => void;
}

interface GetFreeHelpWithVerificationButtonProps {
  text?: string;
  className?: string;
}

export const GetFreeHelpWithVerificationButton: FC<
  GetFreeHelpWithVerificationButtonProps
> = ({ className, text }) => {
  return (
    <a
      target="_blank"
      href="https://calendly.com/popupsmart/cs?back=1"
      rel="noreferrer"
      className={`transition-all duration-150 cursor-pointer group hover:bg-[#e0eefe] w-full flex items-center justify-center gap-2 bg-[#EAF4FF] h-9 rounded-md font-medium leading-[18px] text-xs text-[#000000] ${className} whitespace-nowrap`}
    >
      <div className="flex transition-all duration-150 drop-shadow-xs">
        <Image
          src={personImageOne}
          width={18}
          height={18}
          alt="Get free help with verification"
        />
        <Image
          className="-ml-1 "
          src={personImageTwo}
          width={18}
          height={18}
          alt="Get free help with verification"
        />
        <Image
          className="-ml-1 "
          src={personImageThree}
          width={18}
          height={18}
          alt="Get free help with verification"
        />
      </div>
      {`${text ? text : 'Get free help with verification'}`}
    </a>
  );
};

const Default: FC<DefaultProps> = ({ accountId, onClose }) => {
  const script = `<script src="${path}" data-id="${accountId}" async defer></script>`;

  return (
    <>
      <div className={styles.modalContent}>
        <div className={styles.desc}>
          <p>Embed code that works on all website platforms.</p>
        </div>
        <div className={styles.code}>
          <div className={cn('relative', styles.scale)}>
            <Code
              language="htmlbars"
              code={script}
              useCopy
              className="embed-code max-h-[121px] overflow-hidden !pt-[5px] !px-[5px]"
              copyButtonOnLeft={true}
              copyButtonClassName="!pb-[25px]"
              preTagStylings={{ overflow: 'hidden' }}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <p>
            Copy and paste the
            <b>
              {' '}
              embed code above just before the closing {`</body>`} tag&nbsp;
            </b>
            of your website template file.
          </p>
        </div>

        <div className={styles.button}>
          <PSButton
            variant={'second'}
            textClassName="text-[#777777] text-[12px]"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </PSButton>
          <GetFreeHelpWithVerificationButton />
        </div>
      </div>
    </>
  );
};

export default Default;
