export const ADD_LEAD_EMAIL_REQUEST = 'ADD_LEAD_EMAIL_REQUEST';
export const ADD_LEAD_EMAIL_SUCCESS = 'ADD_LEAD_EMAIL_SUCCESS';
export const ADD_LEAD_EMAIL_FAILURE = 'ADD_LEAD_EMAIL_FAILURE';

export const DELETE_LEAD_EMAIL_REQUEST = 'DELETE_LEAD_EMAIL_REQUEST';
export const DELETE_LEAD_EMAIL_SUCCESS = 'DELETE_LEAD_EMAIL_SUCCESS';
export const DELETE_LEAD_EMAIL_FAILURE = 'DELETE_LEAD_EMAIL_FAILURE';

export const VERIFY_LEAD_EMAIL_REQUEST = 'VERIFY_LEAD_EMAIL_REQUEST';
export const VERIFY_LEAD_EMAIL_SUCCESS = 'VERIFY_LEAD_EMAIL_SUCCESS';
export const VERIFY_LEAD_EMAIL_FAILURE = 'VERIFY_LEAD_EMAIL_FAILURE';

export const GET_LEAD_EMAILS_REQUEST = 'GET_LEAD_EMAILS_REQUEST';
export const GET_LEAD_EMAILS_SUCCESS = 'GET_LEAD_EMAILS_SUCCESS';
export const GET_LEAD_EMAILS_FAILURE = 'GET_LEAD_EMAILS_FAILURE';
