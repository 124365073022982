import { domainRegex } from 'core/helpers/domainRegex';

export const domainNormalizer = (val: string) => {
  val = val.trim();
  const result = domainRegex.test(val);

  if (result) {
    if (val.indexOf('http') === -1) val = `http://${val}`;
    const { hostname } = new URL(val);
    const wwwRegex = /^(www\.)+(-\d+)?/g;
    val = hostname.replaceAll(wwwRegex, '');
  }
  return val;
};
