import { UrlBrowsing, UrlBrowsingMultiple, UrlBrowsingRule } from 'core';
import cloneDeep from 'lodash/cloneDeep';

export const urlBrowsingMigrations = (
  urlBrowsing: UrlBrowsing | UrlBrowsingMultiple | undefined,
) => {
  if (urlBrowsing && (!urlBrowsing?.version || urlBrowsing?.version < 2)) {
    return multipleDomainMigration(urlBrowsing as UrlBrowsing);
  }
  return urlBrowsing as UrlBrowsingMultiple;
};

export const multipleDomainMigration = (
  urlBrowsingOrj: UrlBrowsing,
): UrlBrowsingMultiple => {
  let urlBrowsing: UrlBrowsing = cloneDeep(urlBrowsingOrj);
  const includeMatchesValues: UrlBrowsingRule | undefined = (
    urlBrowsing.include as Array<UrlBrowsingRule>
  ).find((rule) => rule.type === 'MATCHES');

  if (includeMatchesValues && includeMatchesValues.children.values.length > 0) {
    includeMatchesValues.children.values.forEach((rule) => {
      rule.value = `/${rule.value}`;
    });
  }
  const excludeMatchesValues: UrlBrowsingRule | undefined = (
    urlBrowsing.exclude as Array<UrlBrowsingRule>
  ).find((rule) => rule.type === 'MATCHES');

  if (excludeMatchesValues && excludeMatchesValues.children.values.length > 0) {
    excludeMatchesValues.children.values.forEach((rule) => {
      rule.value = `/${rule.value}`;
    });
  }

  return { all: { ...urlBrowsing, active: true }, version: 2 };
};
