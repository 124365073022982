import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  DRIP_GET_FIELDS,
  DRIP_GET_FIELDS_SUCCESS,
  DRIP_GET_FIELDS_FAILURE,
  DRIP_GET_CONNECTIONS,
  DRIP_GET_CONNECTIONS_SUCCESS,
  DRIP_GET_CONNECTIONS_FAILURE,
  DRIP_SAVE,
  DRIP_SAVE_SUCCESS,
  DRIP_SAVE_FAILURE,
  DRIP_DELETE,
  DRIP_CHANGESTATUS,
  DRIP_CHANGESTATUS_FAILURE,
  DRIP_CHANGESTATUS_SUCCESS,
  DRIP_DELETE_FAILURE,
  DRIP_DELETE_SUCCESS,
  DRIP_CLEAR_TEST_RESULT,
  DRIP_CLEAR_STATE,
} from './constants';
import { DripState } from './type';
import produce, { Draft } from 'immer';

const initialState: DripState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<DripState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case DRIP_GET_FIELDS:
      draft.fields = { items: [], status: ReduxStatus.loading };
      return;
    case DRIP_GET_FIELDS_SUCCESS:
      draft.fields = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case DRIP_GET_FIELDS_FAILURE:
      draft.fields = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;

    case DRIP_GET_CONNECTIONS:
      draft.connection = { items: [], status: ReduxStatus.loading };
      return;
    case DRIP_GET_CONNECTIONS_SUCCESS:
      draft.connection = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case DRIP_GET_CONNECTIONS_FAILURE:
      draft.connection = { items: [], status: ReduxStatus.errored };
      return;

    case DRIP_SAVE:
      draft.status = ReduxStatus.loading;
      return;
    case DRIP_SAVE_SUCCESS:
      draft.list = { items: [], status: ReduxStatus.initialized };
      draft.fields = { items: [], status: ReduxStatus.initialized };
      draft.status = ReduxStatus.success;
      return;
    case DRIP_SAVE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case DRIP_DELETE:
      draft.status = ReduxStatus.loading;
      return;
    case DRIP_DELETE_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case DRIP_DELETE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case DRIP_CHANGESTATUS:
      draft.status = ReduxStatus.loading;
      return;
    case DRIP_CHANGESTATUS_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case DRIP_CHANGESTATUS_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case DRIP_CLEAR_TEST_RESULT:
      draft.testResult = undefined;
      draft.status = ReduxStatus.loading;
      return;
    case DRIP_CLEAR_STATE:
      return initialState;
    default:
      return;
  }
}, initialState);

export default reducer;
