import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import ExitIntentIcon from '@assets/icons/exit_intent.svg';

export const renderOnExitIntent = (
  onExitIntent: Targets['onExitIntent'],
  translate: Function,
) => {
  const getSensitivity = () => {
    if (!onExitIntent) return '';
    switch (onExitIntent.onExitIntentDegree) {
      case 'LOW':
        return translate('common:low');

      case 'MEDIUM':
        return translate('common:medium');

      case 'HIGH':
        return translate('common:high');

      default:
        return onExitIntent.onExitIntentDegree.toLowerCase();
    }
  };
  if (onExitIntent && onExitIntent.onExitIntentDegree !== 'NONE') {
    return (
      <>
        <TimelineContent
          icon={<ExitIntentIcon width="16" height="16" viewBox="0 0 36 36" />}
          behavior
          label={translate('sections:targeting-summary-exit-intent:message', {
            sensitivity: getSensitivity(),
          })}
        />
      </>
    );
  }
};
