import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import CookieIcon from '@assets/icons/cookie.svg';

export const renderCookieTargeting = (
  cookieTargeting: Targets['cookieTargeting'],
  translate: Function,
) => {
  const getQuery = (query: string) => {
    switch (query) {
      case 'EQUALS':
        return translate('common:isEqual');
      case 'DOES_NOT_EQUALS':
        return translate('common:isNotEqual');
      case 'CONTAINS':
        return translate('common:contains');
      case 'DOES_NOT_CONTAINS':
        return translate('common:doesNotContain');
      case 'IS_SET':
        return translate('common:isSet');
      case 'IS_NOT_SET':
        return translate('common:isNotSet');
      default:
        return query;
    }
  };
  if (cookieTargeting && !!cookieTargeting.length) {
    return (
      <>
        <TimelineContent
          icon={<CookieIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate(
            'sections:targeting-summary-cookie-targeting:message',
          )}
        >
          {cookieTargeting.map((source, index) => (
            <span
              className="flex items-start font-inter text-xs"
              key={source.value}
            >
              {index > 0 && (
                <span className="font-semibold text-bluesmart mr-1">
                  {translate('common:or').toUpperCase()}
                </span>
              )}
              {translate('sections:targeting-summary-cookie-targeting:value', {
                key: source.key,
                query: getQuery(source.query),
                value: source.value,
              })}
            </span>
          ))}
        </TimelineContent>
      </>
    );
  }
};
