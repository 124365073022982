export const klaviyoServiceURl = 'api/integrations/klaviyo';

export const KLAVIYO_GET_CONNECTIONS =
  'builder/integration/KLAVIYO_GET_CONNECTIONS';
export const KLAVIYO_GET_CONNECTIONS_SUCCESS =
  'builder/integration/KLAVIYO_GET_CONNECTIONS_SUCCESS';
export const KLAVIYO_GET_CONNECTIONS_FAILURE =
  'builder/integration/KLAVIYO_GET_CONNECTIONS_FAILURE';

export const KLAVIYO_GET_FIELDS = 'builder/integration/KLAVIYO_GET_FIELDS';
export const KLAVIYO_GET_FIELDS_SUCCESS =
  'builder/integration/KLAVIYO_GET_FIELDS_SUCCESS';
export const KLAVIYO_GET_FIELDS_FAILURE =
  'builder/integration/KLAVIYO_GET_FIELDS_FAILURE';

export const KLAVIYO_GET_LIST = 'builder/integration/KLAVIYO_GET_LIST';
export const KLAVIYO_GET_LIST_SUCCESS =
  'builder/integration/KLAVIYO_GET_LIST_SUCCESS';
export const KLAVIYO_GET_LIST_FAILURE =
  'builder/integration/KLAVIYO_GET_LIST_FAILURE';

export const KLAVIYO_SAVE = 'builder/integration/KLAVIYO_SAVE';
export const KLAVIYO_SAVE_SUCCESS = 'builder/integration/KLAVIYO_SAVE_SUCCESS';
export const KLAVIYO_SAVE_FAILURE = 'builder/integration/KLAVIYO_SAVE_FAILURE';

export const KLAVIYO_DELETE = 'builder/integration/KLAVIYO_DELETE';
export const KLAVIYO_DELETE_SUCCESS =
  'builder/integration/KLAVIYO_DELETE_SUCCESS';
export const KLAVIYO_DELETE_FAILURE =
  'builder/integration/KLAVIYO_DELETE_FAILURE';

export const KLAVIYO_CHANGESTATUS = 'builder/integration/KLAVIYO_CHANGESTATUS';
export const KLAVIYO_CHANGESTATUS_SUCCESS =
  'builder/integration/KLAVIYO_CHANGESTATUS_SUCCESS';
export const KLAVIYO_CHANGESTATUS_FAILURE =
  'builder/integration/KLAVIYO_CHANGESTATUS_FAILURE';
export const KLAVIYO_CLEAR_STATE = 'builder/integration/KLAVIYO_CLEAR_STATE';
