import React, { CSSProperties, FC } from 'react';
import { LeftIconButtonProps } from './types';
import styles from './LeftIconButton.module.scss';
import tailwindConfig from '../../../tailwind.config';

const LeftIconButton: FC<LeftIconButtonProps> = ({
  children,
  onClick,
  backgroundColor,
  icon: Icon,
  iconViewBox = '0 0 18 18',
  iconFill,
}) => {
  return (
    <button
      style={
        {
          '--bgColor': tailwindConfig.theme.extend.colors[backgroundColor],
        } as CSSProperties
      }
      onClick={onClick}
      className={`${styles.buttonContainer}`}
    >
      <div className={styles.iconContainer}>
        <Icon width="18" height="18" viewBox={iconViewBox} fill={iconFill} />
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </button>
  );
};

export default LeftIconButton;
