import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import { capitalize } from 'core/helpers/string';
import TimelineContent from '../TimelineContent';

import NewReturningVisitorIcon from '@assets/icons/new_returning_visitor.svg';

export const renderNewOrReturningVisitors = (
  newOrReturningVisitors: Targets['newOrReturningVisitors'],
  translate: Function,
) => {
  if (newOrReturningVisitors) {
    return (
      <>
        <TimelineContent
          icon={
            <NewReturningVisitorIcon
              width="16"
              height="16"
              viewBox="0 0 36 36"
            />
          }
          label={`${capitalize(newOrReturningVisitors)} ${translate(
            'common:visitors',
          )}`}
        />
      </>
    );
  }
};
