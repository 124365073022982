import { formElements } from '@components/ElementEditSidebar/Elements/FormSidebar/partials/AddNewFormElement/constants';
import { Node } from '@connectors/builder/campaign';
import { getElementViewByNodeName } from '@model/ElementView';
import { NodeType } from '@model/types';
import { webSafeFonts } from 'core/constants/webSafeFonts';

//data icerisinde aradığımız element var mı kontrol ediyoruz varsa array içinde dönüyoruz yoksa false dönüyoruz
export const searchElements = (
  data: Node | string | number | null,
  name: NodeType | string,
  searchType: 'nodeName' | 'id' = 'nodeName',
) => {
  if (!data || typeof data === 'string' || typeof data === 'number')
    return false;

  const result = [];
  const stack: Node[] = [data];

  while (stack.length) {
    const node = stack.shift()!;

    if (
      searchType === 'nodeName' ? node?.nodeName === name : node.id === name
    ) {
      result.push(node);
    }

    if (node?.childNodes) {
      node.childNodes.forEach((child) => {
        if (typeof child !== 'string') stack.push(child);
      });
    }
  }

  return result.length ? result : false;
};

type findParentType = (
  data: Node | string,
  id?: string,
  parent?: Node | null,
) => null | undefined | Node;

//Find element parent
export const findParent: findParentType = (data, id, parent) => {
  if (data == null || typeof data === 'string') return null;

  if (data.id == id) return parent;

  if (data.childNodes) {
    for (let i = 0; i < data.childNodes.length; i++) {
      const child = data.childNodes[i];
      let result = findParent(child, id, data);

      if (result) return result;
    }
  }
};

//This is really any :/
export const getAllFonts = (
  theObject: any,
  fonts: Array<string>,
  attributeName = 'fontFamily',
): { result: boolean | undefined; fonts: string[] } => {
  let result;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getAllFonts(theObject[i], fonts, attributeName).result;
      if (result) {
        break;
      }
    }
  } else {
    for (const prop in theObject) {
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        const family = theObject[prop][attributeName];
        const weight = theObject[prop].fontWeight;
        if (family && !webSafeFonts.includes(family)) {
          fonts.push(weight ? `${family}:${weight} ` : family);
        }
        result = getAllFonts(theObject[prop], fonts, attributeName).result;
        if (result) {
          break;
        }
      }
    }
  }
  return { result, fonts };
};

export function flattenNodes(node: Node | string, result: Node[]) {
  if (
    typeof node !== 'string' &&
    node?.nodeName !== 'block' &&
    node?.nodeName !== 'close-button'
  ) {
    result.push(node);
  }

  if (
    typeof node !== 'string' &&
    node?.nodeName !== 'button-wrapper' &&
    node?.nodeName !== 'form' &&
    node?.nodeName !== 'shopify' &&
    node?.childNodes &&
    !!node?.childNodes?.length
  ) {
    node.childNodes.forEach((childNode) => {
      flattenNodes(childNode, result);
    });
  }
  return result;
}

export const searchTextIsMatchedFormElement = (searchText: string) => {
  if (searchText == '') return false;

  const isMatched = (text?: string) => {
    return text && text.toLowerCase().includes(searchText.toLowerCase());
  };

  const results = [...formElements].filter((elementNodeName) => {
    const elementView = getElementViewByNodeName(elementNodeName as NodeType);
    return [elementView?.nodeName, elementView?.title].some(isMatched);
  });
  return results.length > 0;
};
