export const ORJ_DATA_TO_DATA = 'builder/customize/ORJ_DATA_TO_DATA';
export const SET_CUSTOMIZE_COPY = 'builder/customize/SET_CUSTOMIZE_COPY';
export const RESET_CUSTOMIZE_COPY = 'builder/customize/RESET_CUSTOMIZE_COPY';
export const UPDATE_CAMPAIGN_PAGE_ATTRIBUTE =
  'builder/customize/customize/UPDATE_CAMPAIGN_PAGE_ATTRIBUTE';
export const UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE =
  'builder/customize/UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE';
export const UPDATE_CAMPAIGN_PAGE_CONTENT_ATTRIBUTE =
  'builder/customize/UPDATE_CAMPAIGN_PAGE_CONTENT_ATTRIBUTE';

export const ADD_CAMPAIGN_CONTENT_ELEMENT =
  'builder/customize/ADD_CAMPAIGN_CONTENT_ELEMENT';
export const CLONE_CAMPAIGN_CONTENT_ELEMENT =
  'builder/customize/CLONE_CAMPAIGN_CONTENT_ELEMENT';
export const UPDATE_CAMPAIGN_CONTENT_ELEMENT =
  'builder/customize/UPDATE_CAMPAIGN_CONTENT_ELEMENT';
export const MOVE_CAMPAIGN_CONTENT_ELEMENT =
  'builder/customize/MOVE_CAMPAIGN_CONTENT_ELEMENT';

export const UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE =
  'builder/customize/UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE';
export const REMOVE_CAMPAIGN_CONTENT_ELEMENT =
  'builder/customize/REMOVE_CAMPAIGN_CONTENT_ELEMENT';

export const UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE =
  'builder/customize/UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE';

export const UPDATE_CAMPAIGN_CURRENT_PAGE_ID =
  'builder/customize/UPDATE_CAMPAIGN_CURRENT_PAGE_ID';

export const ADD_CAMPAIGN_PAGE = 'builder/customize/ADD_CAMPAIGN_PAGE';
export const REMOVE_CAMPAIGN_PAGE = 'builder/customize/REMOVE_CAMPAIGN_PAGE';

export const UPDATE_CAMPAIGN_PREVIEW =
  'builder/customize/UPDATE_CAMPAIGN_PREVIEW';
export const UPDATE_VISITOR_DEVICE = 'builder/customize/UPDATE_VISITOR_DEVICE';

export const GET_NEW_PAGE_TEMPLATE = 'builder/customize/GET_NEW_PAGE_TEMPLATE';
export const GET_NEW_PAGE_TEMPLATE_ERROR =
  'builder/customize/GET_NEW_PAGE_TEMPLATE_ERROR';

export const REVERSE_CAMPAIGN_CHILD_ELEMENTS =
  'builder/customize/REVERSE_CAMPAIGN_CHILD_ELEMENTS';
export const UPDATE_CAMPAIGN_PAGE_FONT_FAMILY =
  'builder/customize/UPDATE_CAMPAIGN_PAGE_FONT_FAMILY';
export const UPDATE_CAMPAIGN_PAGE_COLOR =
  'builder/customize/UPDATE_CAMPAIGN_PAGE_COLOR';
export const UPDATE_CUSTOMIZE_SIDEBAR =
  'builder/customize/UPDATE_CUSTOMIZE_SIDEBAR';
export const UPDATE_INLINE_EDITOR = 'builder/customize/UPDATE_INLINE_EDITOR';

export const COPY_DESKTOP_TO_MOBILE =
  'builder/customize/COPY_DESKTOP_TO_MOBILE';

export const UPDATE_HOVER_ID = 'builder/customize/UPDATE_HOVER_ID';
export const UPDATE_PAGE_CONTENT = 'builder/customize/UPDATE_PAGE_CONTENT';
export const RESET_CAMPAIGN_TEASER_TO_ORJDATA =
  'builder/customize/RESET_CAMPAIGN_TEASER_TO_ORJDATA';
