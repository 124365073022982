import { ReduxAction } from 'global';
import { ReactElement } from 'react';

import {
  HIDE_MODAL,
  RESET_MODAL,
  SHOW_MODAL,
  UPDATE_MODAL_ON_CLOSE,
} from './constants';
import produce, { Draft } from 'immer';

export type Modal = {
  id: string;
  data?: { [key: string]: string | number | boolean | object | null };
  header?: ReactElement;
  footer?: ReactElement;
  closeable?: boolean;
  onClose?: () => void;
};

export type ModalState = {
  status: 'initialized' | 'loading' | 'add' | 'delete' | 'data' | 'clear';
  modals: Modal[];
};

export const initialState: ModalState = {
  status: 'initialized',
  modals: [],
};
const reducer = produce((draft: Draft<ModalState>, action: ReduxAction) => {
  switch (action.type) {
    case SHOW_MODAL: {
      draft.status = 'add';
      draft.modals = [
        ...draft.modals,
        {
          id: action.payload.id,
          data: action.payload.data,
          header: action.payload.header,
          footer: action.payload.footer,
          closeable: action.payload.closeable,
          onClose: action.payload.onClose,
        },
      ];
      return;
    }
    case HIDE_MODAL: {
      const filteredModals = draft.modals.filter(
        (modal: Modal) => modal.id !== action.payload,
      );
      draft.modals = filteredModals;
      return;
    }
    case UPDATE_MODAL_ON_CLOSE: {
      const { id, onClose } = action.payload;
      const modal = draft.modals.find((x) => x.id === id);
      if (modal) {
        modal.onClose = onClose;
      }
      return;
    }
    case RESET_MODAL:
      return initialState;
    default:
      return;
  }
}, initialState);

/* export default function reducer(
  state = initialState,
  action: ReduxAction,
): ModalState {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        status: 'add',
        modals: [
          ...state.modals,
          {
            id: action.payload.id,
            data: action.payload.data,
            header: action.payload.header,
            footer: action.payload.footer,
            closeable: action.payload.closeable,
            onClose: action.payload.onClose,
          },
        ],
      };
    case HIDE_MODAL: {
      let modals = state.modals;
      modals = modals.filter((modal: Modal) => modal.id !== action.payload);

      return {
        ...state,
        status: 'delete',
        modals,
      };
    }
    case RESET_MODAL:
      return initialState;
    default:
      return state;
  }
} */

export default reducer;
