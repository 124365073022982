import { WebsiteItem } from '@generated/graphql';
import {
  GET_WEBSITES_REQUEST,
  ADD_WEBSITE_REQUEST,
  DELETE_WEBSITE_REQUEST,
  GET_CMS_REQUEST,
  UPDATE_WEBSITE_REQUEST,
  UPDATE_CMS,
  UPDATE_WEBSITE_VERIFY_STATUS,
  ADD_WEBSITE_SUCCESS,
  ADD_WEBSITE_FAILURE,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  UPDATE_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_FAILURE,
} from './constants';
import { Website } from './reducer';

export function getWebsites(payload?: {
  onSuccess?: (websites: Website[]) => void;
  onError?: () => void;
}) {
  return {
    type: GET_WEBSITES_REQUEST,
    payload,
  };
}

export function addWebsite(payload: any) {
  return {
    type: ADD_WEBSITE_REQUEST,
    payload,
  };
}
export function addWebsiteSuccess(payload: WebsiteItem) {
  return {
    type: ADD_WEBSITE_SUCCESS,
    payload,
  };
}
export function addWebsiteFailure() {
  return {
    type: ADD_WEBSITE_FAILURE,
  };
}

export function deleteWebsite(payload: number) {
  return {
    type: DELETE_WEBSITE_REQUEST,
    payload,
  };
}
export function deleteWebsiteSuccess(payload: number) {
  return {
    type: DELETE_WEBSITE_SUCCESS,
    payload,
  };
}
export function deleteWebsiteFailure() {
  return {
    type: DELETE_WEBSITE_FAILURE,
  };
}
export function updateWebsite(payload: any) {
  return {
    type: UPDATE_WEBSITE_REQUEST,
    payload,
  };
}
export function updateWebsiteSuccess(payload: WebsiteItem) {
  return {
    type: UPDATE_WEBSITE_SUCCESS,
    payload,
  };
}
export function updateWebsiteFailure() {
  return {
    type: UPDATE_WEBSITE_FAILURE,
  };
}

export function getCMS(payload: any) {
  return {
    type: GET_CMS_REQUEST,
    payload,
  };
}
export function updateCMS(payload: any) {
  return {
    type: UPDATE_CMS,
    payload,
  };
}
export function updateWebsiteVerifyStatus(websiteId: number, status: boolean) {
  return {
    type: UPDATE_WEBSITE_VERIFY_STATUS,
    payload: {
      websiteId,
      status,
    },
  };
}
