import { RootState } from '@connectors/reducers';
import { CampaignState, initialState } from './reducer';
import { createSelector } from 'reselect';
import { getReadonlyStatus } from '@utils/campaign';

const selectCampings = (state: RootState) => state.campaigns || initialState;

const makeSelectIsReadonlyCampaign = ({ id }: { id: number }) => {
  return createSelector(selectCampings, (campaignsState: CampaignState) => {
    const campaign = campaignsState.items.find((x) => x.id === id);

    if (!campaign?.createdAt) return true;

    const isReadonly = getReadonlyStatus(campaign.createdAt);
    return isReadonly;
  });
};

const makeSelectselectedCampaign = createSelector(
  [
    //@ts-ignore TODO
    (state: RootState) => state?.campaigns?.items,
    (state: RootState) => state?.builder?.data?.id,
  ],
  (items, id) => {
    if (!id) return null;
    //@ts-ignore TODO
    const selectedCampaign = items.find((campaign) => campaign?.id === id);

    return selectedCampaign;
  },
);

const makeSelectfilteredCampaignData = createSelector(
  selectCampings,
  (campaignState: CampaignState) => campaignState.filteredCampaignData,
);

const makeSelectPendingCampaign = createSelector(
  selectCampings,
  (campaignState: CampaignState) => campaignState.pendingCampaign,
);

const makeSelectCreateCampaignWithTemplateIdStatus = createSelector(
  selectCampings,
  (campaignState: CampaignState) =>
    campaignState.createCampaignWithTemplateId.status,
);

const makeSelectSidebarCampaignCount = createSelector(
  selectCampings,
  (campaignState: CampaignState) => campaignState.sidebarCampaignCount,
);

const makeSelectSidebarCampaignCountStatus = createSelector(
  selectCampings,
  (campaignState: CampaignState) => campaignState.countStatus,
);

const makeSelectCampaignCount = createSelector(
  selectCampings,
  (campaignState: CampaignState) => campaignState?.items?.length || 0,
);

export {
  makeSelectIsReadonlyCampaign,
  makeSelectselectedCampaign,
  makeSelectPendingCampaign,
  makeSelectCreateCampaignWithTemplateIdStatus,
  makeSelectSidebarCampaignCount,
  makeSelectCampaignCount,
  makeSelectSidebarCampaignCountStatus,
  makeSelectfilteredCampaignData,
};
