export const isEmpty = (obj: any): boolean => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj || {}, key)) return false;
  }
  return true;
};

export const isObject = (object: unknown): boolean => {
  return object != null && typeof object === 'object';
};

export const hasOwnProperty = (object: unknown, key: string): boolean => {
  if (typeof object !== 'object') return false;

  return Object.prototype.hasOwnProperty.call(object, key);
};
