import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';
import ShopifyIcon from '@assets/icons/shopify-line.svg';
import { Item } from '../../Targeting/Audience/Target/ShopifyTargeting/types';

export const renderShopifyTargeting = (
  shopifyTargeting: Targets['shopifyTargeting'],
  translate: Function,
) => {
  const showTargeting = shopifyTargeting
    ? shopifyTargeting.filter((item) => item.status !== 'hide')
    : [];
  const hideTargeting = shopifyTargeting
    ? shopifyTargeting.filter((item) => item.status === 'hide')
    : [];
  const renderSource = (source: Item) => {
    return translate('sections:targeting-summary-shopify-targeting:value', {
      property: source.property,
      rule: source.operator.toLowerCase().replaceAll('_', ' '),
      value: source.value ?? '',
    });
  };
  if (shopifyTargeting && !!shopifyTargeting.length) {
    return (
      <>
        <TimelineContent
          icon={<ShopifyIcon width="16" height="16" />}
          label={translate(
            'sections:targeting-summary-shopify-targeting:message',
          )}
        >
          {shopifyTargeting.length > 0 && (
            <div className="mt-2 ml-2">
              <b>{translate('common:show')}</b>
              {showTargeting.map((source, index) => {
                return (
                  <span
                    className="flex items-start font-inter text-xs"
                    key={source.value}
                  >
                    {index > 0 && (
                      <span className="font-semibold text-bluesmart mr-1">
                        {translate('common:and').toUpperCase()}
                      </span>
                    )}
                    <div className="flex flex-row">{renderSource(source)}</div>
                  </span>
                );
              })}
            </div>
          )}
          {hideTargeting.length > 0 && (
            <div className="mt-2 ml-2">
              <b>{translate('common:hide')}</b>
              {hideTargeting.map((source, index) => {
                return (
                  <span
                    className="flex items-start font-inter text-xs"
                    key={source.value}
                  >
                    {index > 0 && (
                      <span className="font-semibold text-bluesmart mr-1">
                        {translate('common:or').toUpperCase()}
                      </span>
                    )}
                    <div className="flex flex-row">{renderSource(source)}</div>
                  </span>
                );
              })}
            </div>
          )}
        </TimelineContent>
      </>
    );
  }
};
