import { buildClientSchema } from 'graphql';
import { FunctionsMap, ParsingFunctionsObject } from 'apollo-link-scalars';
import { Decimal } from 'decimal.js';
import dayjs, { Dayjs } from 'dayjs';

import schemaJSON from '../generated/schema.json';

export const schema = buildClientSchema(schemaJSON as never);

const DecimalMap: ParsingFunctionsObject<Decimal | null, string> = {
  serialize: (parsed: unknown) => (parsed as Decimal).toString(),
  parseValue: (raw: unknown): Decimal | null => {
    return raw ? new Decimal(raw as string) : null;
  },
};

const DateMap: ParsingFunctionsObject<Dayjs | null, string> = {
  serialize: (parsed: unknown) => (parsed as Dayjs).format('YYYY-MM-DD'),
  parseValue: (raw: unknown): Dayjs | null => {
    return raw ? dayjs(raw as string) : null;
  },
};

const DateTimeMap: ParsingFunctionsObject<Dayjs | null, string> = {
  serialize: (parsed: unknown) => (parsed as Dayjs).toISOString(),
  parseValue: (raw: unknown): Dayjs | null => {
    return raw ? dayjs(raw as string) : null;
  },
};

export const typesMap: FunctionsMap = {
  Decimal: DecimalMap,
  Date: DateMap,
  DateTime: DateTimeMap,
};
