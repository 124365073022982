export const sendinblueServiceURl = 'api/integrations/sendinblue';

export const SENDINBLUE_GET_CONNECTIONS =
  'builder/integrations/SENDINBLUE_GET_CONNECTIONS';
export const SENDINBLUE_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/SENDINBLUE_GET_CONNECTIONS_SUCCESS';
export const SENDINBLUE_GET_CONNECTIONS_FAILURE =
  'builder/integrations/SENDINBLUE_GET_CONNECTIONS_FAILURE';

export const SENDINBLUE_GET_FIELDS =
  'builder/integrations/SENDINBLUE_GET_FIELDS';
export const SENDINBLUE_GET_FIELDS_SUCCESS =
  'builder/integrations/SENDINBLUE_GET_FIELDS_SUCCESS';
export const SENDINBLUE_GET_FIELDS_FAILURE =
  'builder/integrations/SENDINBLUE_GET_FIELDS_FAILURE';

export const SENDINBLUE_GET_LIST = 'builder/integrations/SENDINBLUE_GET_LIST';
export const SENDINBLUE_GET_LIST_SUCCESS =
  'builder/integrations/SENDINBLUE_GET_LIST_SUCCESS';
export const SENDINBLUE_GET_LIST_FAILURE =
  'builder/integrations/SENDINBLUE_GET_LIST_FAILURE';

export const SENDINBLUE_SAVE = 'builder/integrations/SENDINBLUE_SAVE';
export const SENDINBLUE_SAVE_SUCCESS =
  'builder/integrations/SENDINBLUE_SAVE_SUCCESS';
export const SENDINBLUE_SAVE_FAILURE =
  'builder/integrations/SENDINBLUE_SAVE_FAILURE';

export const SENDINBLUE_DELETE = 'builder/integrations/SENDINBLUE_DELETE';
export const SENDINBLUE_DELETE_SUCCESS =
  'builder/integrations/SENDINBLUE_DELETE_SUCCESS';
export const SENDINBLUE_DELETE_FAILURE =
  'builder/integrations/SENDINBLUE_DELETE_FAILURE';

export const SENDINBLUE_CHANGESTATUS =
  'builder/integrations/SENDINBLUE_CHANGESTATUS';
export const SENDINBLUE_CHANGESTATUS_SUCCESS =
  'builder/integrations/SENDINBLUE_CHANGESTATUS_SUCCESS';
export const SENDINBLUE_CHANGESTATUS_FAILURE =
  'builder/integrations/SENDINBLUE_CHANGESTATUS_FAILURE';
export const SENDINBLUE_CLEAR_STATE =
  'builder/integrations/SENDINBLUE_CLEAR_STATE';
