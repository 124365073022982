import produce, { Draft } from 'immer';

import type { PortalState, PortalStateAction } from './types';
import {
  ADD_PORTAL,
  UPDATE_PORTAL,
  DELETE_PORTAL,
  RESET,
  SHOW_PORTAL,
  HIDE_PORTAL,
} from './constants';

export const initialState: PortalState = {
  portals: [],
};

const reducer = produce(
  (draft: Draft<PortalState>, action: PortalStateAction) => {
    const { type } = action;
    switch (type) {
      case ADD_PORTAL: {
        const { payload } = action;
        const isPortalExists = draft.portals.find(
          (portal) => portal.elementId === payload.elementId,
        );

        if (isPortalExists) return;

        draft.portals.push({
          elementId: payload.elementId,
          show: false,
          data: payload.data || { message: '' },
        });

        return;
      }
      case UPDATE_PORTAL: {
        const { payload } = action;
        const updatePortal = draft.portals.find(
          (portal) => portal.elementId === payload.elementId,
        );

        if (updatePortal) {
          updatePortal.data = payload.data;
        }
        return;
      }

      case SHOW_PORTAL: {
        const { payload } = action;
        const updatePortal = draft.portals.find(
          (portal) => portal.elementId === payload.elementId,
        );

        if (updatePortal) {
          updatePortal.show = true;
          if (payload?.data) {
            updatePortal.data = payload.data;
          }
        }
        return;
      }

      case HIDE_PORTAL: {
        const { payload } = action;
        const updatePortal = draft.portals.find(
          (portal) => portal.elementId === payload.elementId,
        );

        if (updatePortal) {
          updatePortal.show = false;
        }
        return;
      }

      case DELETE_PORTAL: {
        const { payload } = action;
        draft.portals = draft.portals.filter(
          (portal) => portal.elementId !== payload.elementId,
        );
        return;
      }

      case RESET: {
        return initialState;
      }

      default: {
        return;
      }
    }
  },
  initialState,
);

export default reducer;
