import type { ShowcaseCheckboxProps } from './types';

import React, { FC } from 'react';
import cn from 'classnames';

import Checkbox from '../Checkbox';

import styles from './ShowcaseCheckbox.module.scss';

const ShowcaseCheckbox: FC<ShowcaseCheckboxProps> = ({
  id,
  icon,
  label,
  isActive = false,
  handleClick,
}) => {
  return (
    <button
      className={cn(styles.filterButton, {
        [styles.active]: isActive,
      })}
      onClick={handleClick}
      data-testid={id}
    >
      <span data-testid={`${id}-icon`} className={styles.icon}>
        {icon}
      </span>

      <span data-testid={`${id}-label`} className={styles.label}>
        {label}
      </span>
      <Checkbox
        className={styles.checkbox_custom}
        data-testid={`${id}-checkbox`}
        id={id}
        checked={isActive === true}
      />
    </button>
  );
};

export default ShowcaseCheckbox;
