import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  SENDINBLUE_GET_FIELDS,
  SENDINBLUE_GET_FIELDS_SUCCESS,
  SENDINBLUE_GET_FIELDS_FAILURE,
  SENDINBLUE_GET_CONNECTIONS,
  SENDINBLUE_GET_CONNECTIONS_SUCCESS,
  SENDINBLUE_GET_CONNECTIONS_FAILURE,
  SENDINBLUE_GET_LIST,
  SENDINBLUE_GET_LIST_SUCCESS,
  SENDINBLUE_GET_LIST_FAILURE,
  SENDINBLUE_SAVE,
  SENDINBLUE_SAVE_SUCCESS,
  SENDINBLUE_SAVE_FAILURE,
  SENDINBLUE_CHANGESTATUS,
  SENDINBLUE_CHANGESTATUS_FAILURE,
  SENDINBLUE_CHANGESTATUS_SUCCESS,
  SENDINBLUE_DELETE,
  SENDINBLUE_DELETE_FAILURE,
  SENDINBLUE_DELETE_SUCCESS,
  SENDINBLUE_CLEAR_STATE,
} from './constants';
import produce, { Draft } from 'immer';
import { SendinblueState } from './type';

const initialState: SendinblueState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
};

const reducer = produce(
  (draft: Draft<SendinblueState>, action: ReduxAction) => {
    const { type } = action;
    switch (type) {
      case SENDINBLUE_GET_FIELDS:
        draft.fields = { items: [], status: ReduxStatus.loading };
        return;
      case SENDINBLUE_GET_FIELDS_SUCCESS:
        draft.fields = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case SENDINBLUE_GET_FIELDS_FAILURE:
        draft.fields = {
          items: [],
          status: ReduxStatus.errored,
        };
        return;
      case SENDINBLUE_GET_LIST:
        draft.list = {
          items: [],
          status: ReduxStatus.loading,
        };
        return;
      case SENDINBLUE_GET_LIST_SUCCESS:
        draft.list = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case SENDINBLUE_GET_LIST_FAILURE:
        draft.list = {
          items: [],
          status: ReduxStatus.errored,
        };
        return;

      case SENDINBLUE_GET_CONNECTIONS:
        draft.connection = { items: [], status: ReduxStatus.loading };
        return;
      case SENDINBLUE_GET_CONNECTIONS_SUCCESS:
        draft.connection = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case SENDINBLUE_GET_CONNECTIONS_FAILURE:
        draft.connection = { items: [], status: ReduxStatus.errored };
        return;

      case SENDINBLUE_SAVE:
        draft.status = ReduxStatus.loading;
        return;
      case SENDINBLUE_SAVE_SUCCESS:
        draft.list = { items: [], status: ReduxStatus.initialized };
        draft.fields = { items: [], status: ReduxStatus.initialized };
        draft.status = ReduxStatus.success;
        return;
      case SENDINBLUE_SAVE_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case SENDINBLUE_DELETE:
        draft.status = ReduxStatus.loading;
        return;
      case SENDINBLUE_DELETE_SUCCESS:
        draft.status = ReduxStatus.success;
        return;
      case SENDINBLUE_DELETE_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case SENDINBLUE_CHANGESTATUS:
        draft.status = ReduxStatus.loading;
        return;
      case SENDINBLUE_CHANGESTATUS_SUCCESS:
        draft.status = ReduxStatus.success;
        return;
      case SENDINBLUE_CHANGESTATUS_FAILURE:
        draft.status = ReduxStatus.errored;
        return;
      case SENDINBLUE_CLEAR_STATE:
        return initialState;
      default:
        return;
    }
  },
  initialState,
);

export default reducer;
