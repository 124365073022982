import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import AfterSecondIcon from '@assets/icons/after_second.svg';

export const renderAfterXSeconds = (
  afterXSeconds: Targets['afterXSeconds'],
  translate: Function,
) => {
  if (afterXSeconds) {
    return (
      <>
        <TimelineContent
          icon={<AfterSecondIcon width="16" height="16" viewBox="0 0 36 36" />}
          behavior
          label={translate(
            'sections:targeting-summary-after-x-seconds:message',
            {
              value: afterXSeconds.seconds,
            },
          )}
        />
      </>
    );
  }
};
