import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  UNSUBSCRIBE_AUTO_RESPONDER_REQUEST,
  UNSUBSCRIBE_AUTO_RESPONDER_SUCCESS,
  UNSUBSCRIBE_AUTO_RESPONDER_FAILURE,
} from './constants';

import fetch from '@utils/fetch';

function* unsubscribeAutoResponderSaga({ payload }: ReduxAction) {
  const { id, hash } = payload;
  try {
    const data: Record<string, unknown> = yield call(
      fetch,
      `api/AutoResponder/unsubscribe/${id}/${hash}`,
      {
        method: 'GET',
      },
    );
    yield put({ type: UNSUBSCRIBE_AUTO_RESPONDER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UNSUBSCRIBE_AUTO_RESPONDER_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(
    UNSUBSCRIBE_AUTO_RESPONDER_REQUEST,
    unsubscribeAutoResponderSaga,
  );
}
