import React, { useEffect, useState } from 'react';
import YoutubeShopifyIcon from '@assets/icons/youtube-shopify.svg';
import styles from './ExploreShopifyBanner.module.scss';
import ShopifyIcon from '@assets/icons/shopify-icon-md.svg';
import CloseLargeIcon from '@assets/icons/close-large.svg';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { showModal } from '@connectors/modal/actions';

const ExploreShopifyBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const showBanner =
      localStorage.getItem('showExploreShopifyBanner') !== 'false';
    setIsVisible(showBanner);
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const showBanner = !event.target.checked;
    localStorage.setItem('showExploreShopifyBanner', showBanner.toString());
  };

  const handleCloseClick = () => {
    setIsVisible(false);
  };

  const onOpenVideoModal = () => {
    dispatch(
      showModal({
        id: 'ExploreShopifyBanner',
      }),
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container} role="alert">
      <div className="px-[21px] py-[14px] flex items-center justify-between">
        <div className="flex items-center gap-8 ">
          <div className="flex items-center">
            <ShopifyIcon />
            <h3 className="ml-[10px] font-bold">
              Explore Playbook for Shopify
            </h3>
          </div>

          <div className="flex items-center">
            <button
              className="bg-[#C8E1B7] text-[#527B36] rounded-md"
              onClick={onOpenVideoModal}
            >
              <div className="p-2 flex flex-row items-center">
                <YoutubeShopifyIcon />
                <span className="text-xs ml-[6px]">Video</span>
              </div>
            </button>

            <Link
              className={styles.shopifyButton}
              href="https://popupsmart.com/shopify-playbook/"
              target="_blank"
            >
              <span className="text-sm ml-[6px]">
                Explore Shopify Playbook ↗
              </span>
            </Link>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <label className="inline-flex items-center p-[10px] bg-[#ffffff80] rounded-md">
            <input
              type="checkbox"
              className="form-checkbox p-[10px] w-4 h-4"
              onChange={handleCheckboxChange}
            />
            <span className="ml-2 text-black text-xs leading-4">
              Don’t show me again
            </span>
          </label>

          <button
            onClick={handleCloseClick}
            className="p-2 flex items-center rounded-full bg-black"
          >
            <CloseLargeIcon className="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExploreShopifyBanner;
