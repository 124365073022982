import { ChangeEvent, FC, useEffect } from 'react';

import type { PSToggleProps } from './types';

import styles from './Toggle.module.scss';
import classNames from 'classnames';

const PSToggle: FC<PSToggleProps> = ({
  label,
  field: { name, value, onChange },
  disabled,
  size,
  prefix,
  suffix,
  hidePrefixOnMobile = false,
  onChangeEvent,
}) => {
  useEffect(() => {
    if (value && value !== '' && value !== null) {
      onChangeEvent && onChangeEvent(value);
    }
  }, []);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
    onChangeEvent && onChangeEvent(e.target.checked);
  };
  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.disabled]: disabled,
        [styles.small]: size === 'sm',
      })}
    >
      {label && <p className={styles.label}>{label}</p>}
      {prefix && (
        <p
          className={classNames(styles.prefix, {
            [styles.hidePrefixOnMobile]: hidePrefixOnMobile,
          })}
        >
          {prefix}
        </p>
      )}
      <div className={styles.switch_wrapper}>
        <input
          type="checkbox"
          id={name}
          onChange={handleChangeValue}
          disabled={disabled}
          name={name}
          value={value}
          checked={value}
        />
        <label htmlFor={name} />
      </div>
      {suffix && <p className={styles.suffix}>{suffix}</p>}
    </div>
  );
};

export default PSToggle;
