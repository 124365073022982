import React, { FC } from 'react';
import PopupsmartLogo from '@assets/icons/popupsmart-rounded.svg';
import styles from './PlatformBadge.module.scss';
import LeftIconButton from '@components/LeftIconButton';
import { PlatformBadgeProps } from './types';

const PlatformBadge: FC<PlatformBadgeProps> = ({
  cmsIcon: CmsIcon,
  title,
  button,
  buttonBackgroundColor,
  overlayBackgroundColor,
}) => {
  const handleGoToShopifyApp = () => {
    button.onClick();
  };
  return (
    <div
      className={styles.container}
      style={
        {
          '--overlayColor': overlayBackgroundColor,
        } as React.CSSProperties
      }
    >
      <div className={styles.top}>
        <div className={styles.iconContainer}>
          <div className={styles.circleIcon}>
            <PopupsmartLogo width="32" height="32" viewBox="0 0 48 48" />
          </div>
          <div className={styles.circleIcon}>
            <CmsIcon width="28" height="28" viewBox="0 0 18 18" />
          </div>
        </div>
        <h1>{title}</h1>
      </div>
      <LeftIconButton
        icon={CmsIcon}
        onClick={handleGoToShopifyApp}
        backgroundColor={buttonBackgroundColor}
      >
        {button.text}
      </LeftIconButton>
    </div>
  );
};

export default PlatformBadge;
