import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import styles from './Favicon.module.scss';
import EarthIcon from '@assets/icons/earth.svg';
import EarthUnverifiedIcon from '@assets/icons/earth-warning.svg';

interface FaviconProps {
  url?: string;
  className?: string;
  isSmall?: boolean;
  grayIcon?: boolean;
  verified?: boolean;
}

const Favicon: FC<FaviconProps> = ({
  url,
  className,
  isSmall,
  grayIcon,
  verified = true,
}) => {
  const trimUrl = useMemo(() => {
    if (!url) return '';
    return url.replace(/https?:\/\/?/gi, '');
  }, [url]);

  const siteUrl = useMemo(() => {
    let newUrl = trimUrl;
    if (trimUrl && trimUrl.charAt(0) == '/') newUrl = trimUrl.substring(1);
    return newUrl;
  }, [trimUrl]);

  return (
    <div
      className={cn(
        styles.favicon_wrapper,
        className && className,
        isSmall && styles.small,
        grayIcon && 'text-[#777777]',
      )}
    >
      {verified ? (
        <span
          className={cn('h-[22px] w-[22px]', {
            '!h-[18px]': isSmall,
            '!w-[18px]': isSmall,
          })}
        >
          <EarthIcon viewBox="0 0 16 16" />
        </span>
      ) : (
        <span
          className={cn('h-[22px] w-[22px]', {
            '!h-[18px]': isSmall,
            '!w-[18px]': isSmall,
          })}
        >
          <EarthUnverifiedIcon viewBox="0 0 24 24" />
        </span>
      )}
      <img
        className={styles.image}
        src={`https://favicon.yandex.net/favicon/${siteUrl}?size=32`}
        alt={`${siteUrl} favicon`}
      />
    </div>
  );
};

export default Favicon;
export type { FaviconProps };
