import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import fetch from '@utils/fetch';
import {
  sendloopServiceURl,
  SENDLOOP_GET_FIELDS,
  SENDLOOP_GET_FIELDS_SUCCESS,
  SENDLOOP_GET_FIELDS_FAILURE,
  SENDLOOP_GET_CONNECTIONS,
  SENDLOOP_GET_CONNECTIONS_SUCCESS,
  SENDLOOP_GET_CONNECTIONS_FAILURE,
  SENDLOOP_GET_LIST,
  SENDLOOP_GET_LIST_SUCCESS,
  SENDLOOP_GET_LIST_FAILURE,
  SENDLOOP_SAVE,
  SENDLOOP_SAVE_SUCCESS,
  SENDLOOP_SAVE_FAILURE,
  SENDLOOP_DELETE,
  SENDLOOP_DELETE_FAILURE,
  SENDLOOP_DELETE_SUCCESS,
  SENDLOOP_TEST_SUCCESS,
  SENDLOOP_TEST_FAILURE,
  SENDLOOP_TEST,
} from './constants';
import {
  ApiConnectionObject,
  ApiFieldObject,
  ApiListObject,
} from '../reducers';
import {
  addIntegration,
  deleteIntegration,
  updateIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import { IntegrationSaveResult } from '../type';

function* handleGetConnections() {
  try {
    const result: ApiConnectionObject[] = yield call(
      fetch,
      `${sendloopServiceURl}/GetAllByAccountId`,
      {
        method: 'GET',
      },
    );
    yield put({ type: SENDLOOP_GET_CONNECTIONS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SENDLOOP_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, listId, onSuccess } = action.payload;
  try {
    const result: ApiFieldObject[] = yield call(
      fetch,
      `${sendloopServiceURl}/lists/${apiKey}/${listId}/mergeFields`,
      {
        method: 'GET',
      },
    );
    onSuccess && onSuccess();
    yield put({ type: SENDLOOP_GET_FIELDS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SENDLOOP_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { apiKey, onSuccess } = action.payload;
  try {
    const result: ApiListObject[] = yield call(
      fetch,
      `${sendloopServiceURl}/lists/${apiKey}`,
      {
        method: 'GET',
      },
    );

    onSuccess && onSuccess();
    yield put({ type: SENDLOOP_GET_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SENDLOOP_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  let result = {} as IntegrationSaveResult;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
          },
        ]
      : [],
  };
  try {
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      // eslint-disable-next-line @typescript-eslint/ban-types
      const integrationCampaign: {} = yield call(
        fetch,
        `${sendloopServiceURl}/PutIntegrationCampaign/${listSettings.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(campaignFormData),
        },
      );
      yield put(
        updateIntegration({
          data: integrationCampaign,
          type: IntegrationEnums.sendLoop,
        }),
      );
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      result = yield call(fetch, `${sendloopServiceURl}`, {
        method: 'POST',
        data: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      delete result.apiKey;
      delete result.id;
      delete result.name;
      delete result.status;
      yield put(
        addIntegration({
          data: result.integrationCampaigns[0],
          type: IntegrationEnums.sendLoop,
        }),
      );
    }

    yield put({ type: SENDLOOP_SAVE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SENDLOOP_SAVE_FAILURE });
  }
}

function* handleTest(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList } = action.payload;
  let result = {};
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    listId: listSettings.listId,
    fields: fieldList,
  };
  try {
    result = yield call(fetch, `${sendloopServiceURl}/test`, {
      method: 'POST',
      data: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    yield put({ type: SENDLOOP_TEST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SENDLOOP_TEST_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(fetch, `${sendloopServiceURl}/DeleteIntegrationCampaign/${id}`, {
      method: 'DELETE',
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.sendLoop,
      }),
    );
    yield put({ type: SENDLOOP_DELETE_SUCCESS, id });
  } catch (error) {
    yield put({ type: SENDLOOP_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(SENDLOOP_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(SENDLOOP_GET_FIELDS, handleGetFields);
  yield takeLatest(SENDLOOP_GET_LIST, handleGetList);
  yield takeLatest(SENDLOOP_SAVE, handleSave);
  yield takeLatest(SENDLOOP_DELETE, handleDelete);
  yield takeLatest(SENDLOOP_TEST, handleTest);
}
