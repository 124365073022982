import { Content, Node } from '@connectors/builder/campaign';
import { StepContent } from '@connectors/newStep/types';
import deepForEach from 'deep-for-each';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { BlockElementContent } from 'popup-elements/src/block-element/types';
import { positions } from '@elements/SelectPosition';
import { generateTeaserWithPosition } from './teaserUtils';

export const migrateTeaserBlockStyles = (
  existingTeaser: Content,
  teaserLayout: StepContent | undefined,
) => {
  const existingTeaserCopy = cloneDeep(existingTeaser);
  deepForEach(existingTeaser.content.desktop, (value, key, parent, path) => {
    if (key === 'nodeName' && value === 'block') {
      if (parent.nodeName === 'block') {
        let defaultBlock = {} as BlockElementContent;
        deepForEach(teaserLayout?.content.desktop, (fValue, fKey, fParent) => {
          if (parent.id === fValue) {
            defaultBlock = fParent;
            return;
          }
        });

        if (parent.id === 'teaser') {
          const { style } = existingTeaserCopy.content.desktop.attributes;
          const {
            marginRight,
            marginLeft,
            marginTop,
            marginBottom,
            objectPosition,
            transform,
          } = style;

          set(existingTeaserCopy.content.desktop, `attributes.style`, {
            ...defaultBlock?.attributes.style,
            ...(marginRight ? { marginRight } : {}),
            ...(marginLeft ? { marginLeft } : {}),
            ...(marginTop ? { marginTop } : {}),
            ...(marginBottom ? { marginBottom } : {}),
            ...(objectPosition ? { objectPosition } : {}),
            ...(transform ? { transform } : {}),
          });
        } else {
          set(
            existingTeaserCopy.content.desktop,
            `${(path as string)?.substring(
              0,
              path?.lastIndexOf('.'),
            )}.attributes.style`,
            defaultBlock?.attributes.style,
          );
        }
      }
    }
  });
  deepForEach(existingTeaser.content.mobile, (value, key, parent, path) => {
    if (key === 'nodeName' && value === 'block') {
      if (parent.nodeName === 'block') {
        let defaultBlock = {} as BlockElementContent;
        deepForEach(teaserLayout?.content.mobile, (fValue, fKey, fParent) => {
          if (parent.id === fValue) {
            defaultBlock = fParent;
            return;
          }
        });

        if (parent.id === 'teaser') {
          const { style } = existingTeaserCopy.content.mobile.attributes;
          const {
            marginRight,
            marginLeft,
            marginTop,
            marginBottom,
            objectPosition,
            transform,
          } = style;

          set(existingTeaserCopy.content.mobile, `attributes.style`, {
            ...defaultBlock?.attributes.style,
            ...(marginRight ? { marginRight } : {}),
            ...(marginLeft ? { marginLeft } : {}),
            ...(marginTop ? { marginTop } : {}),
            ...(marginBottom ? { marginBottom } : {}),
            ...(objectPosition ? { objectPosition } : {}),
            ...(transform ? { transform } : {}),
          });
        } else {
          set(
            existingTeaserCopy.content.mobile,
            `${(path as string)?.substring(
              0,
              path?.lastIndexOf('.'),
            )}.attributes.style`,
            defaultBlock?.attributes.style,
          );
        }
      }
    }
  });

  return existingTeaserCopy;
};

export const migrateTeaserUpperBlock = (existingTeaser: Content) => {
  let isMigrated = false;
  if (
    (existingTeaser?.content.desktop.childNodes[0] as Node)?.id ===
    'teaser-container'
  ) {
    return existingTeaser;
  }

  if (isMigrated) return existingTeaser;
  const existingTeaserCopy = cloneDeep(existingTeaser);

  const desktopLayout = existingTeaserCopy.content.desktop;
  const mobileLayout = existingTeaserCopy.content.mobile;
  if (!desktopLayout) return existingTeaserCopy;
  if (!mobileLayout) return existingTeaserCopy;
  const desktopLayoutCopy = cloneDeep(desktopLayout);
  const mobileLayoutCopy = cloneDeep(mobileLayout);

  delete desktopLayoutCopy.attributes.style.marginRight;
  delete desktopLayoutCopy.attributes.style.marginLeft;
  delete desktopLayoutCopy.attributes.style.marginTop;
  delete desktopLayoutCopy.attributes.style.marginBottom;
  delete mobileLayoutCopy.attributes.style.marginRight;
  delete mobileLayoutCopy.attributes.style.marginLeft;
  delete mobileLayoutCopy.attributes.style.marginTop;
  delete mobileLayoutCopy.attributes.style.marginBottom;
  delete desktopLayoutCopy.attributes.visibility;
  delete mobileLayoutCopy.attributes.visibility;
  desktopLayoutCopy.id = 'teaser-container';
  mobileLayoutCopy.id = 'teaser-container';
  desktopLayout.childNodes = [{ ...desktopLayoutCopy }];
  mobileLayout.childNodes = [{ ...mobileLayoutCopy }];
  delete desktopLayout.attributes.style.gridTemplateColumns;
  delete mobileLayout.attributes.style.gridTemplateColumns;
  return existingTeaserCopy;
};

export const teaserMigrations = (existingTeaser: Content) => {
  let migratedTeaser = cloneDeep(existingTeaser);
  if (
    !existingTeaser.version ||
    (existingTeaser.version && existingTeaser.version < 3)
  ) {
    migratedTeaser = migrateTeaserUpperBlock(existingTeaser);

    const positionItem = positions.find(
      (position) => position.value === existingTeaser.appearance.position,
    );

    const closeButton = migratedTeaser.content.desktop.childNodes[1] as Node;
    migratedTeaser = generateTeaserWithPosition(
      migratedTeaser,
      positionItem!,
      closeButton,
      true,
    )!;

    migratedTeaser.version = 3;
  }
  return migratedTeaser;
};
