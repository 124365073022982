import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { cdn } from '@utils/fetch';
import {
  GET_CITIES_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
} from './contants';
import { City, Country, Region } from './reducer';
import { ReduxAction } from 'global';
import { AxiosResponse } from 'axios';

function* getCountries() {
  try {
    const res: AxiosResponse<Country[]> = yield call(
      cdn,
      'assets/geo/countries.json',
      {
        method: 'GET',
      },
    );

    yield put({ type: GET_COUNTRIES_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: GET_COUNTRIES_FAILURE });
  }
}

function* getRegions({ payload }: ReduxAction) {
  try {
    const res: AxiosResponse<Region[]> = yield call(
      cdn,
      `assets/geo/regions/${payload.toLowerCase()}.json`,
      {
        method: 'GET',
      },
    );

    yield put({
      type: GET_REGIONS_SUCCESS,
      payload: { countryCode: payload, regions: res.data },
    });
  } catch (error) {
    yield put({ type: GET_REGIONS_FAILURE });
  }
}

function* getCities({ payload }: ReduxAction) {
  try {
    const res: AxiosResponse<City[]> = yield call(
      cdn,
      `assets/geo/cities/${payload.countryCode.toLowerCase()}.json`,
      {
        method: 'GET',
      },
    );
    const cities = res.data.filter((x) => x.regionCode === payload.regionCode);
    yield put({
      type: GET_CITIES_SUCCESS,
      payload: {
        countryCode: payload.countryCode,
        regionCode: payload.regionCode,
        cities,
      },
    });
  } catch (error) {
    yield put({ type: GET_CITIES_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(GET_COUNTRIES_REQUEST, getCountries);
  yield takeEvery(GET_REGIONS_REQUEST, getRegions);
  yield takeEvery(GET_CITIES_REQUEST, getCities);
}
