import React from 'react';

import cn from 'classnames';

import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
}

const Loader = (props: LoaderProps) => {
  const { className } = props;
  return <div className={cn(styles.lds_dual_ring, className)} />;
};

export default Loader;
export type { LoaderProps };
