import { RESET_STORE } from '@connectors/actions';
import { RootState } from '@connectors/reducers';
import { ReduxAction } from 'global';
import { createSelector } from 'reselect';

import {
  GET_INVITATIONS_REQUEST,
  GET_INVITATIONS_SUCCESS,
  GET_INVITATIONS_FAILURE,
  CREATE_INVITATION_REQUEST,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_FAILURE,
  DELETE_INVITATION_REQUEST,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_FAILURE,
  CHANGE_INVITATION_ROLE_REQUEST,
  CHANGE_INVITATION_ROLE_SUCCESS,
  CHANGE_INVITATION_ROLE_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_USERS_REQUEST,
  GET_ACCOUNTS_USERS_SUCCESS,
  GET_ACCOUNTS_USERS_FAILURE,
  DELETE_ACCOUNT_USER_REQUEST,
  DELETE_ACCOUNT_USER_SUCCESS,
  DELETE_ACCOUNT_USER_FAILURE,
  CHANGE_ACCOUNT_REQUEST,
  CHANGE_ACCOUNT_SUCCESS,
  CHANGE_ACCOUNT_FAILURE,
  CHANGE_ACCOUNT_USER_ROLE_REQUEST,
  CHANGE_ACCOUNT_USER_ROLE_SUCCESS,
  CHANGE_ACCOUNT_USER_ROLE_FAILURE,
  CHANGE_ACCOUNT_USER_STATUS_REQUEST,
  CHANGE_ACCOUNT_USER_STATUS_SUCCESS,
  CHANGE_ACCOUNT_USER_STATUS_FAILURE,
  GET_PAGE_VIEW_REQUEST,
  GET_PAGE_VIEW_SUCCESS,
  GET_PAGE_VIEW_FAILURE,
} from './constants';

type InvitationStatus = 'WAITING' | 'SUCCESS';

export interface IAccount {
  accountId: number;
  accountName?: string;
  users?: IAccountUser[];
}
export interface IInvitation {
  id: number;
  email: string;
  //added boolean for accountUser compatibility
  status: InvitationStatus | boolean;
  role: {
    roleTypeId: number;
    campaigns: number[];
  };
  canCreateCampaign: boolean;
}

export interface IAccountUser {
  id: number;
  email: string;
  //added InvitationStatus for invitation compatibility
  status: InvitationStatus | boolean;
  role: {
    roleTypeId: number;
    campaigns: number[];
  };
  canCreateCampaign: boolean;
}

type Accounts = {
  invitations: IInvitation[];
  accounts: IAccount[];
  account: IAccount;
  pageView: number;
  planPageView: number;
  email: number;
  planEmail: number;
  autoExtendStatus: boolean;
  autoExtendPageViewStatus: boolean;
  autoExtendEmailStatus: boolean;
  usedPageView: number;
  pageViewLimitExtendTotal: number;
  usedEmail: number;
  emailLimitExtendTotal: number;
  pageViewLimitExtendPrice: number;
  emailLimitExtendPrice: number;
  respondentEmailCount: number;
  selfEmailCount: number;
  status: 'success' | 'errored' | 'loading' | 'initialized';
  accountStatsStatus: 'success' | 'errored' | 'loading';
  isBlocked: boolean;
};

const initialState: Accounts = {
  invitations: [],
  accounts: [],
  account: { users: [], accountName: '', accountId: 0 },
  pageView: 0,
  planPageView: 0,
  email: 0,
  planEmail: 0,
  autoExtendStatus: false,
  autoExtendPageViewStatus: false,
  autoExtendEmailStatus: false,
  usedPageView: 0,
  pageViewLimitExtendTotal: 0,
  usedEmail: 0,
  emailLimitExtendTotal: 0,
  pageViewLimitExtendPrice: 0,
  emailLimitExtendPrice: 0,
  respondentEmailCount: 0,
  selfEmailCount: 0,
  status: 'loading',
  accountStatsStatus: 'loading',
  isBlocked: false,
};

export default function reducer(
  state = initialState,
  action: ReduxAction,
): Accounts {
  switch (action.type) {
    case GET_INVITATIONS_REQUEST:
    case CREATE_INVITATION_REQUEST:
    case DELETE_INVITATION_REQUEST:
    case GET_ACCOUNTS_REQUEST:
    case GET_ACCOUNTS_USERS_REQUEST:
    case CHANGE_ACCOUNT_REQUEST:
    case CHANGE_INVITATION_ROLE_REQUEST:
    case CHANGE_ACCOUNT_USER_ROLE_REQUEST:
    case CHANGE_ACCOUNT_USER_STATUS_REQUEST:
    case DELETE_ACCOUNT_USER_REQUEST:
    case GET_PAGE_VIEW_REQUEST:
      return {
        ...state,
        status: 'loading',
      };

    case GET_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitations: action.payload,
        status: 'success',
      };

    case CREATE_INVITATION_SUCCESS:
      return {
        ...state,
        invitations: [...state.invitations, action.payload],
        status: 'success',
      };

    case DELETE_INVITATION_SUCCESS: {
      const filteredInvitations = state.invitations.filter(
        (x: IInvitation) => x.id !== action.payload,
      );
      return {
        ...state,
        invitations: filteredInvitations,
        status: 'success',
      };
    }

    case CHANGE_INVITATION_ROLE_SUCCESS: {
      const updatedInvitations = state.invitations.map(
        (invitation: IInvitation) => {
          const { payload } = action;

          if (invitation.id === payload.invitationId) {
            invitation.role.roleTypeId = payload.roleId;
            invitation.role.campaigns = payload.campaignIds.map((id: string) =>
              Number(id),
            );
            invitation.canCreateCampaign = payload.canCreateCampaign;
            return invitation;
          }
          return invitation;
        },
      );

      return {
        ...state,
        invitations: updatedInvitations,
        status: 'success',
      };
    }

    case CHANGE_ACCOUNT_USER_ROLE_SUCCESS: {
      const roleUpdatedUsers = state.account.users?.map(
        (accountUser: IAccountUser) => {
          const { payload } = action;

          if (accountUser.id === payload.accountUserId) {
            accountUser.role.roleTypeId = payload.roleId;
            accountUser.role.campaigns = payload.campaignIds.map((id: string) =>
              Number(id),
            );
            accountUser.canCreateCampaign = payload.canCreateCampaign;
            return accountUser;
          }
          return accountUser;
        },
      );

      return {
        ...state,
        account: { ...state.account, users: roleUpdatedUsers },
        status: 'success',
      };
    }

    case CHANGE_ACCOUNT_USER_STATUS_SUCCESS: {
      const statusUpdatedUsers = state.account.users?.map(
        (accountUser: IAccountUser) => {
          const { payload } = action;

          if (accountUser.id === payload.accountUserId) {
            accountUser.status = !accountUser.status;
            return accountUser;
          }
          return accountUser;
        },
      );

      return {
        ...state,
        account: { ...state.account, users: statusUpdatedUsers },
        status: 'success',
      };
    }

    case GET_ACCOUNTS_SUCCESS: {
      const { accounts, user } = action.payload;
      const defaultAccount = accounts.filter(
        (x: IAccount) => x.accountId === Number(user.accountId),
      )[0];

      return {
        ...state,
        account: { ...state.account, ...defaultAccount },
        accounts,
        status: 'success',
      };
    }

    case DELETE_ACCOUNT_USER_SUCCESS: {
      const filteredAccountUsers = state.account.users?.filter(
        (x: IAccountUser) => x.id !== action.payload,
      );
      return {
        ...state,
        account: { ...state.account, users: filteredAccountUsers },
        status: 'success',
      };
    }

    case GET_ACCOUNTS_USERS_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          users: action.payload,
        },
        status: 'success',
      };
    case CHANGE_ACCOUNT_SUCCESS: {
      const { accountId } = action.payload;
      const filteredAccounts = state.accounts.filter(
        (x: IAccount) => x.accountId === Number(accountId),
      );
      const newAccount = filteredAccounts[0];
      return {
        ...state,
        account: newAccount,
        // no success here wait until page views.
      };
    }

    case GET_PAGE_VIEW_SUCCESS:
      return {
        ...state,
        pageView: action.payload.pageView,
        planPageView: action.payload.planPageView,
        email: action.payload.email,
        planEmail: action.payload.planEmail,
        autoExtendStatus: action.payload.autoExtendStatus,
        autoExtendPageViewStatus: action.payload.autoExtendPageViewStatus,
        autoExtendEmailStatus: action.payload.autoExtendEmailStatus,
        usedPageView: action.payload.usedPageView,
        pageViewLimitExtendTotal: action.payload.pageViewLimitExtendTotal,
        usedEmail: action.payload.usedEmail,
        emailLimitExtendTotal: action.payload.emailLimitExtendTotal,
        pageViewLimitExtendPrice: action.payload.pageViewLimitExtendPrice,
        emailLimitExtendPrice: action.payload.emailLimitExtendPrice,
        respondentEmailCount: action.payload.respondentEmailCount,
        selfEmailCount: action.payload.selfEmailCount,
        isBlocked: action.payload.isBlocked,
        accountStatsStatus: 'success',
      };

    case GET_INVITATIONS_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case CREATE_INVITATION_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case DELETE_INVITATION_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case GET_ACCOUNTS_USERS_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case CHANGE_ACCOUNT_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case CHANGE_INVITATION_ROLE_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case CHANGE_ACCOUNT_USER_ROLE_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case CHANGE_ACCOUNT_USER_STATUS_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case DELETE_ACCOUNT_USER_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case GET_PAGE_VIEW_FAILURE:
      return {
        ...state,
        status: 'errored',
      };
    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
}

const getInvitationsSelector = (state: RootState) => state.accounts.invitations;
const getSubUsersSelector = (state: RootState) => state.accounts.account.users;

export const selectSubAccounts = createSelector(
  getInvitationsSelector,
  getSubUsersSelector,
  (
    invitations: IInvitation[] | undefined,
    users: IAccountUser[] | undefined,
  ) => {
    const result = [];
    if (invitations) result.push(...invitations);
    if (users) result.push(...users);
    return result;
  },
);
