import ButtonIcon from '@assets/icons/element/button.svg';
import FormButtonIcon from '@assets/icons/element/form-button.svg';
import FormIcon from '@assets/icons/element/form.svg';
import ShopifyIcon from '@assets/icons/element/shopify.svg';

import EmailIcon from '@assets/icons/email-icon.svg';
import ImageIcon from '@assets/icons/image-icon.svg';
import TextIcon from '@assets/icons/tree-text.svg';
import SubtextIcon from '@assets/icons/tree-subtext.svg';
import HeadlineIcon from '@assets/icons/headline.svg';
import InputIcon from '@assets/icons/tree-input.svg';
import RadioIcon from '@assets/icons/tree-radio.svg';
import MultiChoiceIcon from '@assets/icons/tree-checkbox.svg';
import DropdownIcon from '@assets/icons/tree-dropdown.svg';
import DateIcon from '@assets/icons/tree-date.svg';
import PhoneIcon from '@assets/icons/tree-phone.svg';
import YesNoIcon from '@assets/icons/tree-yes-no.svg';
import OpinionScaleIcon from '@assets/icons/tree-opinion.svg';
import RatingIcon from '@assets/icons/tree-rating.svg';
import SocialIcon from '@assets/icons/tree-social.svg';
import CouponCodeIcon from '@assets/icons/tree-coupon.svg';
import CountdownIcon from '@assets/icons/tree-countdown.svg';
import YoutubeIcon from '@assets/icons/tree-youtube.svg';
import PrivacyIcon from '@assets/icons/privacy-policy.svg';
import LottieIcon from '@assets/icons/lottie.svg';
import SpinIcon from '@assets/icons/spin.svg';
import LotteryIcon from '@assets/icons/lottery.svg';
import LongInputIcon from '@assets/icons/long-input-icon.svg';
import UserIcon from '@assets/icons/user-rect.svg';
import AdressIcon from '@assets/icons/location-line.svg';
import HiddenInputIcon from '@assets/icons/element/hidden-input.svg';
import VimeoIcon from '@assets/icons/vimeo-icon.svg';
import SettingsIcon from '@assets/icons/genaral-settings.svg';

import { ElementViewType, NodeType } from './types';

export const ElementView: ElementViewType[] = [
  {
    id: 1,
    tags: ['notvisible'],
    elements: [
      {
        id: 'form-1',
        title: 'Yes No',
        label: 'Yes/No',
        nodeName: 'yes-no',
        icon: <YesNoIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#E8F3FF',
        disabled: false,
        visible: false,
        translateKey: 'yesNoElement',
      },
      {
        id: 'form-2',
        nodeName: 'opinion-scale',
        label: 'Opinion Scale / NPS',
        title: 'Select number scale',
        icon: <OpinionScaleIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF8DB',
        disabled: false,
        visible: true,
        translateKey: 'opinionScaleElement',
      },
      {
        id: 'form-3',
        nodeName: 'rating',
        title: 'Rating',
        icon: <RatingIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF0FD',
        disabled: false,
        visible: true,
        translateKey: 'ratingElement',
      },
      {
        id: 'form-4',
        nodeName: 'form',
        title: 'Form',
        icon: <FormIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'formElement',
      },
      {
        id: 'form-5',
        nodeName: 'button-wrapper',
        title: 'Button',
        icon: <ButtonIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EDFFF3',
        disabled: false,
        visible: true,
        translateKey: 'buttonElement',
      },
      {
        id: 'form-6',
        nodeName: 'button',
        title: 'Button',
        icon: <ButtonIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EDFFF3',
        disabled: false,
        visible: false,
        translateKey: 'buttonElement',
      },
      {
        id: 'form-7',
        title: 'Short Text',
        nodeName: 'text-input',
        icon: <InputIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF0F0',
        disabled: false,
        visible: true,
        translateKey: 'shortTextElement',
      },
      {
        id: 'form-8',
        title: 'Single Choice',
        nodeName: 'radio-input',
        icon: <RadioIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F4F4F4',
        disabled: false,
        visible: true,
        translateKey: 'singleChoiceElement',
      },
      {
        id: 'form-9',
        title: 'Multiple Choice',
        nodeName: 'multi-choice',
        icon: <MultiChoiceIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF4E8',
        disabled: false,
        visible: true,
        translateKey: 'multipleChoiceElement',
      },
      {
        id: 'form-10',
        title: 'Dropdown',
        nodeName: 'dropdown-input',
        icon: <DropdownIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'dropdownElement',
      },
      {
        id: 'form-11',
        title: 'Date Input',
        label: 'Date',
        nodeName: 'date-input',
        icon: <DateIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#E7FAEB',
        disabled: false,
        visible: true,
        translateKey: 'dateElement',
      },
      {
        id: 'form-12',
        title: 'Email',
        nodeName: 'email-input',
        icon: <EmailIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F4F4F4',
        disabled: false,
        visible: true,
        translateKey: 'emailElement',
      },
      {
        id: 'form-13',
        title: 'Phone',
        label: 'Phone',
        nodeName: 'phone-input',
        icon: <PhoneIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF8DB',
        disabled: false,
        visible: true,
        translateKey: 'phoneElement',
      },
      {
        id: 'form-14',
        title: 'Notice and Consent',
        nodeName: 'notice-and-consent',
        icon: <PrivacyIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#E3F2FF',
        disabled: false,
        visible: true,
        translateKey: 'noticeAndConsentElement',
      },
      {
        id: 'form-15',
        nodeName: 'form-button',
        title: 'Submit Button',
        icon: <FormButtonIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EDFFF3',
        disabled: false,
        visible: true,
        translateKey: 'formButtonElement',
      },
      {
        id: 'form-16',
        nodeName: 'long-input',
        title: 'Long Input',
        icon: <LongInputIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EDFFF3',
        disabled: false,
        visible: true,
        translateKey: 'longInputElement',
      },
      {
        id: 'form-17',
        title: 'Full Name',
        nodeName: 'full-name-input',
        icon: <UserIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF0F0',
        disabled: false,
        visible: true,
        translateKey: 'fullNameElement',
      },
      {
        id: 'form-18',
        title: 'Address',
        nodeName: 'address-input',
        icon: <AdressIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF0F0',
        disabled: false,
        visible: true,
        translateKey: 'addressElement',
      },
      {
        id: 'form-19',
        title: 'Hidden Input',
        nodeName: 'hidden-input',
        icon: <HiddenInputIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF0F0',
        disabled: false,
        visible: true,
        translateKey: 'hiddenInputElement',
      },
      {
        id: 'form-20',
        title: 'General Settings',
        nodeName: 'block',
        icon: <SettingsIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF0F0',
        disabled: false,
        visible: true,
        translateKey: 'generalSettings',
      },
    ],
  },
  {
    id: 3,
    tags: ['all'],
    elements: [
      {
        id: 'all-1',
        nodeName: 'form',
        title: 'Form',
        icon: <FormButtonIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'formElement',
      },
    ],
  },
  {
    id: 2,
    tags: ['others', 'all'],
    elements: [
      {
        id: 'other-1',
        nodeName: 'headline',
        title: 'Headline',
        icon: <HeadlineIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EFF9FF',
        disabled: false,
        visible: true,
        translateKey: 'headlineElement',
      },
      {
        id: 'other-2',
        nodeName: 'text',
        title: 'Text',
        icon: <TextIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EFF9FF',
        disabled: false,
        visible: true,
        translateKey: 'textElement',
      },
      {
        id: 'other-3',
        nodeName: 'subtext',
        title: 'Subtext',
        icon: <SubtextIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EFF9FF',
        disabled: false,
        visible: true,
        translateKey: 'subtextElement',
      },
      {
        id: 'other-4',
        nodeName: 'image',
        title: 'Image',
        icon: <ImageIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF8DB',
        disabled: false,
        visible: true,
        translateKey: 'imageElement',
      },
      {
        id: 'form-5',
        nodeName: 'button-wrapper',
        title: 'Button',
        icon: <ButtonIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EDFFF3',
        disabled: false,
        visible: true,
        translateKey: 'buttonElement',
      },
      {
        id: 'other-6',
        nodeName: 'social',
        title: 'Social',
        icon: <SocialIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF8DB',
        disabled: false,
        visible: true,
        translateKey: 'socialElement',
      },
      {
        id: 'other-7',
        nodeName: 'coupon-code',
        title: 'Coupon Code',
        icon: <CouponCodeIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#EDFFF3',
        disabled: false,
        visible: true,
        translateKey: 'couponCodeElement',
      },
      {
        id: 'other-8',
        nodeName: 'countdown',
        title: 'Countdown',
        icon: <CountdownIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFF4E8',
        disabled: false,
        visible: true,
        translateKey: 'countdownElement',
      },
      {
        id: 'other-9',
        nodeName: 'youtube',
        title: 'YouTube',
        icon: <YoutubeIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFE3E3',
        disabled: false,
        visible: true,
        translateKey: 'youtubeElement',
      },
      {
        id: 'other-10',
        nodeName: 'vimeo',
        title: 'Vimeo',
        icon: <VimeoIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#FFE3E3',
        disabled: false,
        visible: true,
        translateKey: 'vimeoElement',
      },
      {
        id: 'other-11',
        nodeName: 'lottie',
        title: 'Lottie',
        icon: <LottieIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'lottieElement',
      },
      {
        id: 'form-12',
        nodeName: 'spin-to-win',
        title: 'Spin to win',
        icon: <SpinIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'spinElement',
      },
      {
        id: 'form-13',
        nodeName: 'lottery-ball',
        title: 'Lottery Ball',
        icon: <LotteryIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'lotteryElement',
      },
      {
        id: 'other-14',
        nodeName: 'shopify',
        title: 'Shopify',
        icon: <ShopifyIcon width={16} height={16} viewBox="0 0 24 24" />,
        backgroundColor: '#F0F6FF',
        disabled: false,
        visible: true,
        translateKey: 'shopifyElement',
      },
    ],
  },
];

export const getElementsNodeNameByTagName = (tagName: string): string[] => {
  return ElementView.filter((element) => element.tags.includes(tagName)).reduce(
    // TODO: eslint forces parentheses. prettier doesn't want parentheses.
    // eslint-disable-next-line prettier/prettier
    (acc: string[], val: (typeof ElementView)[0]) => [
      ...acc,
      ...val.elements.map((element) => element.nodeName),
    ],
    [],
  );
};

export const getElementViewByNodeName = (nodeName: NodeType) => {
  const elementView = ElementView.flatMap((view) => view.elements).find(
    (element) => element.nodeName === nodeName,
  );

  return elementView;
};
