import { useEffect, useMemo, useState } from 'react';
import { useMeQuery, MeQuery } from '@generated/graphql';
import store from '@utils/store';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectAuth } from '@connectors/auth/selectors';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { SET_AUTH_USER_APOLLO } from '@connectors/auth/constants';
import { meData } from '@connectors/auth/types';

export const useAuth = (): {
  auth: meData;
  loading: boolean;
  reload: () => Promise<void>;
  isLoggedIn: boolean;
  isLoggedInShopify: boolean;
} => {
  const dispatch = useDispatch();
  const auth = useSelector(makeSelectAuth);

  const setUser = (data: { me: meData }) => {
    if (!data?.me) return;
    handleAddMetaTag(data.me);
    const meData = cloneDeep(data.me);
    delete meData.accountPlan.__typename;
    if (me && !isEqual(meData, auth.meData)) {
      dispatch({
        type: SET_AUTH_USER_APOLLO,
        payload: meData,
      });
    }
  };

  const handleAddMetaTag = (me: MeQuery['me']) => {
    let interval: NodeJS.Timeout;
    let intervalCounter = 0;
    interval = setInterval(() => {
      if (!window?.ps) return;
      intervalCounter += 1;

      if (me.accountPlan.name && me.accountPlan.type && me.email) {
        window.ps.addMeta('planName', me.accountPlan.name);
        window.ps.addMeta('planType', me.accountPlan.type);
        window.ps.addMeta('email', me.email);
        window.ps.addMeta('isPageViewBlocked', me.isPageViewBlocked.toString());
        window.ps.addMeta('isMigrated', me.isMigrated.toString());
        window.ps.addMeta(
          'userHasShopifyWebsite',
          me.userHasShopifyWebsite.toString(),
        );
      }

      if (window.ps || intervalCounter === 10) clearInterval(interval);
    }, 1000);
  };

  const { loading, refetch } = useMeQuery({
    onCompleted: (data) => {
      setUser(data as { me: meData });
    },
  });

  const refreshMe = async () => {
    const { data } = await refetch();
    setUser(data as { me: meData });
  };

  const isLoggedIn = !!store.get('user');
  const [isLoggedInShopify, setIsLoggedInShopify] = useState(false);

  useEffect(() => {
    setIsLoggedInShopify(!!store.get('user'));
  }, []);

  const me = useMemo(() => {
    return auth.meData;
  }, [auth]);
  const result = {
    auth: me as meData,
    loading,
    reload: refreshMe,
    isLoggedIn,
    isLoggedInShopify,
  };
  return result;
};
