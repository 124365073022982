import React, { FC } from 'react';

type IconProps = {
  width?: string;
  height?: string;
  viewBox?: string;
};

const MultiPageIcon: FC<IconProps> = ({ width, height, viewBox }) => {
  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox={viewBox || '0 0 18 18'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16753_2094)">
        <path
          d="M2.37985 8.99125L7.79118 2.5425L6.60126 1.125L0 9L6.6107 16.875L7.81007 15.4487L2.3893 8.99125H2.37985ZM4.77859 9L10.1899 15.44L11.3893 16.875L18 9L11.3893 1.125L10.1899 2.55125L4.77859 9ZM15.6107 9L11.3893 14.0225L7.16789 9L11.3893 3.9775L15.6107 9Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_16753_2094">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MultiPageIcon;
