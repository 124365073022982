import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import fetch from '@utils/fetch';

import {
  GET_CAMPAIGN,
  SAVE_CAMPAIGN,
  CHANGE_CAMPAIGN_TEMPLATE,
  GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ERROR,
} from './constants';
import { GET_NEW_PAGE_TEMPLATE } from './customize/constants';
import {
  changeCampaignTemplateError,
  changeCampaignTemplateSuccess,
  getCampaign,
  getCampaignError,
  getCampaignSuccess,
  getTemplateAndUpdateCampaignTemplateError,
  getTemplateAndUpdateCampaignTemplateSuccess,
  reset,
  saveCampaignError,
  saveCampaignSuccess,
} from './actions';

import {
  ChangeCampaignTemplateAction,
  GetCampaignAction,
  GetTemplateAndUpdateCampaignTemplateAction,
  SaveCampaignAction,
  SaveTargetsAction,
} from './types';

import { addCampaignPage, getNewPageTemplateError } from './customize/actions';
import { GetNewPageTemplateAction } from './customize/types';
import {
  DOMAIN_DELETE,
  DOMAIN_DELETE_SUCCESS,
  DOMAIN_SELECT,
  DOMAIN_SELECT_SUCCESS,
  SAVE_SETTINGS,
  UPDATE_AUTO_RESPONDER_FAILURE,
  UPDATE_AUTO_RESPONDER_REQUEST,
  UPDATE_AUTO_RESPONDER_SUCCESS,
} from './settings/constants';
import {
  DeleteDomainAction,
  SaveSettingsAction,
  UpdateAutoResponderAction,
} from './settings/types';
import type { Campaign, IAutoResponder, TemplateResponse } from './campaign';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import set from 'lodash/set';
import { editableCustomizePaths } from './customize/fields';
import { SAVE_TARGETS } from './target/constants';
import {
  getAllSegments,
  getActiveSegment,
  resetSegment,
  updateSegment,
  updateChangeViewVisibility,
  setSelectedSegmentAsSmartMode,
} from '@connectors/segment/actions';
import { SegmentState, SegmentResponses } from '@connectors/segment/types';
import { saveTargets } from './target/actions';
import ReduxStatus from '@enums/ReduxStatus';
import { hideModal, showModal } from '@connectors/modal/actions';
import { GetCurrentTemplateAction } from './style/types';
import {
  getCurrentTemplateError,
  getCurrentTemplateSuccess,
} from './style/actions';
import { GET_CURRENT_TEMPLATE } from './style/constants';
import { Template } from '@connectors/templates/types';
import StepTypes from '@enums/StepTypes';
import graphqlFetch from '@utils/graphqlFetch';

export interface CampaignUnparsedValues {
  targets: string;
  layout: string;
  settings: string;
  integrationActiveCampaignCampaigns: string;
  integrationCustomerIoCampaigns: string;
  integrationDripCampaigns: string;
  integrationEmailOctopusCampaigns: string;
  integrationHubSpotCampaigns: string;
  integrationKlaviyoCampaigns: string;
  integrationMailchimpCampaigns: string;
  integrationMailerliteCampaigns: string;
  integrationSendLoopCampaigns: string;
  integrationSendgridCampaigns: string;
  integrationSendinBlueCampaigns: string;
  integrationWebhooks: string;
}

export function getGraphQLQuery(id: number) {
  return {
    operationName: 'Campaign',
    query: `query Campaign($campaignId: Int!) {
      campaign(campaignId: $campaignId) {
        id
        name
        status
        publishVersion
        autoResponderStatus
        autoResponderReplyTo
        autoResponderSenderName
        autoResponderSubject
        autoResponderMailBody
        domains {
          id
          url
          verified
          cMS
          isShopify
        }
        targets
        layout
        settings
        integrationActiveCampaignCampaigns
        integrationCustomerIoCampaigns
        integrationDripCampaigns
        integrationEmailOctopusCampaigns
        integrationHubSpotCampaigns
        integrationKlaviyoCampaigns
        integrationMailchimpCampaigns
        integrationMailerliteCampaigns
        integrationSendLoopCampaigns
        integrationSendgridCampaigns
        integrationSendinBlueCampaigns
        integrationWebhooks
        createdAt
        variantInfo
      }
    }`,
    variables: {
      campaignId: id,
    },
  };
}

export function getTemplateGraphQLQuery(id: number) {
  return {
    operationName: 'TemplateById',
    query: `query TemplateById($templateByIdId: Float!) {
      templateById(id: $templateByIdId) {
        id
        name
        imageUrl
        title
        description
        layoutType
        popupType
        goals
        sort
        deviceType
        isMultipage
        isAnimated
        tags
        subTags
        layoutElements
        targets
        layout
      }
    }`,
    variables: {
      templateByIdId: id,
    },
  };
}
export function getSegmentGraphQLQuery(id: number) {
  return {
    operationName: 'getSegment',
    query: `query getSegment($getSegmentId: Int!) {
      getSegment(id: $getSegmentId) {
        id
        name
        description
        icon
        color
        isPredefined
        segmentCategoryId
        segmentCategoryName
        createdAt
        targets
        campaigns {
          id
          name
        }
        isDeletable
        key
      }
    }`,
    variables: {
      getSegmentId: id,
    },
  };
}

export function createSegmentGraphQLQuery(data: any) {
  return {
    operationName: 'createSegment',
    query: `mutation createSegment($segment: CreateSegment!) {
      createSegment(segment: $segment) {
        id
        name
        description
        icon
        color
        isPredefined
        segmentCategoryId
        segmentCategoryName
        createdAt
        targets
        campaigns {
          id
          name
        }
        isDeletable
      }
    }`,
    variables: {
      segment: data,
    },
  };
}

export function updateCampaignGraphQLQuery(id: number, data: any) {
  const body = data;
  if (data.layout) {
    body.layout = JSON.stringify({
      contents: data.layout.contents,
      style: data.layout.style,
    });
  }
  if (data.targets) {
    body.targets = JSON.stringify(data.targets);
  }
  if (data.settings) {
    body.settings = JSON.stringify(data.settings);
  }
  return {
    operationName: 'UpdateCampaign',
    query: `mutation UpdateCampaign($campaignId: Int!, $data: CampaignUpdateRequest!) {
      updateCampaign(campaignId: $campaignId, data: $data)
    }`,
    variables: {
      campaignId: id,
      data: body,
    },
  };
}

export function parseCampaign(data: Campaign & CampaignUnparsedValues) {
  data.targets = JSON.parse(data.targets!);
  data.layout = JSON.parse(data.layout!);
  data.settings = JSON.parse(data.settings!);
  data.integrationActiveCampaignCampaigns = JSON.parse(
    data.integrationActiveCampaignCampaigns!,
  );
  data.integrationCustomerIoCampaigns = JSON.parse(
    data.integrationCustomerIoCampaigns!,
  );
  data.integrationDripCampaigns = JSON.parse(data.integrationDripCampaigns!);
  data.integrationEmailOctopusCampaigns = JSON.parse(
    data.integrationEmailOctopusCampaigns!,
  );
  data.integrationHubSpotCampaigns = JSON.parse(
    data.integrationHubSpotCampaigns!,
  );
  data.integrationKlaviyoCampaigns = JSON.parse(
    data.integrationKlaviyoCampaigns!,
  );
  data.integrationMailchimpCampaigns = JSON.parse(
    data.integrationMailchimpCampaigns!,
  );
  data.integrationMailerliteCampaigns = JSON.parse(
    data.integrationMailerliteCampaigns!,
  );
  data.integrationSendLoopCampaigns = JSON.parse(
    data.integrationSendLoopCampaigns!,
  );
  data.integrationSendgridCampaigns = JSON.parse(
    data.integrationSendgridCampaigns!,
  );
  data.integrationSendinBlueCampaigns = JSON.parse(
    data.integrationSendinBlueCampaigns!,
  );
  data.integrationWebhooks = JSON.parse(data.integrationWebhooks!);
  return data as Campaign;
}

function* handleGetCampaign(action: GetCampaignAction) {
  try {
    const { id, loadTargetsCopy, loadCustomizeCopy, getActiveTemplate } =
      action.payload;

    const result: {
      data: {
        campaign: Campaign & CampaignUnparsedValues;
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: getGraphQLQuery(id),
    });
    const campaignData: Campaign = parseCampaign(result.data.campaign);
    campaignData.id = id;
    yield put(
      getCampaignSuccess({
        data: campaignData,
        loadTargetsCopy,
        loadCustomizeCopy,
      }),
    );
    if (getActiveTemplate) {
      if (campaignData.targets?.segmentId)
        yield put(getActiveSegment({ id: campaignData.targets?.segmentId }));
      else if (campaignData.targets?.targetSmartMode)
        yield put(setSelectedSegmentAsSmartMode({ status: true }));
    }
  } catch (error) {
    yield put(getCampaignError());
  }
}

function* handleGetNewPage(action: GetNewPageTemplateAction) {
  try {
    const { pageName, templateId } = action.payload;

    const result: {
      data: {
        templateById: TemplateResponse;
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: getTemplateGraphQLQuery(templateId),
    });

    const data = result.data.templateById;

    //Update template image url
    data.layout.contents.map((content) => {
      if (!data?.imageUrl) return content;

      content.template.imageUrl = data?.imageUrl;
      return content;
    });

    yield put(
      addCampaignPage({ title: pageName, contents: data.layout.contents }),
    );
  } catch (error) {
    yield put(getNewPageTemplateError());
  }
}

function* handleSaveCampaign(action: SaveCampaignAction) {
  const orjData: Campaign = yield select((state) => state.builder.orjData);
  const customizeData: Campaign = yield select((state) => state.builder.data);
  const segment: SegmentState = yield select((state) => state.segment);
  const { active, targetsCopyData } = segment;
  const result = cloneDeep(orjData);

  if (customizeData && customizeData.id !== 0) {
    const customizeClone = cloneDeep(customizeData);
    editableCustomizePaths.forEach((path) => {
      set(result, path, get(customizeClone, path));
    });
  }

  const data = {
    layout: result.layout,
    targets: result.targets,
  };

  try {
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: updateCampaignGraphQLQuery(result.id, data),
    });

    /* yield call(fetch, `/api/Campaign/${result.id}`, {
      method: 'PUT',
      data,
    }); */

    const visitorDeviceChanged = !isEqual(
      orjData.targets?.visitorDevice,
      customizeData.targets?.visitorDevice,
    );
    const customizeDataSegmentId = customizeData.targets?.segmentId;

    if (
      active.status === ReduxStatus.success &&
      visitorDeviceChanged &&
      customizeDataSegmentId
    ) {
      const res: SegmentResponses = yield call(graphqlFetch, '', {
        method: 'POST',
        data: getSegmentGraphQLQuery(Number(customizeDataSegmentId)),
      });
      if (!res.data.getSegment) return;
      const currentSegment = res.data.getSegment;

      if (currentSegment.isPredefined || currentSegment.campaigns.length > 1) {
        const dataSegment: SegmentResponses = yield call(graphqlFetch, ``, {
          method: 'POST',
          data: createSegmentGraphQLQuery({
            Name: `${currentSegment.name}_copy`,
            Description: currentSegment.description,
            Targets: JSON.stringify(customizeData.targets),
            Key: currentSegment.key,
          }),
        });
        if (!dataSegment.data.createSegment) return;
        const { id: segmentId, targets: dataSegmentTargets } =
          dataSegment.data.createSegment;
        if (segmentId) {
          yield put(
            saveTargets({
              targets: {
                ...JSON.parse(dataSegmentTargets as string),
                segmentId: Number(segmentId),
                segmentName: dataSegment.data.createSegment.name,
                segmentDescription: dataSegment.data.createSegment.description,
                visitorDevice: customizeData?.targets?.visitorDevice,
              },
              updateTargetCopy: true,
            }),
          );
        }
      } else {
        yield put(
          updateSegment({
            id: Number(active.segment?.id),
            name: active.segment?.name!,
            description: active.segment?.description!,
            targets: customizeData.targets!,
            reset: true,
            onSuccess: () => {},
          }),
        );
      }
    } else if (
      active.segment &&
      (active.segment?.isPredefined || active.segment!.campaigns!.length > 1)
    ) {
      if (active.segment!.campaigns!.length > 1) {
        yield put(showModal({ id: 'multipleSegmentModal' }));
      } else if (active.segment!.isPredefined) {
        const dataSegment: SegmentResponses = yield call(graphqlFetch, ``, {
          method: 'POST',
          data: createSegmentGraphQLQuery({
            Name: `${active.segment!.name}_copy`,
            Description: active.segment!.description,
            Targets: JSON.stringify(targetsCopyData),
            Key: active.segment.key,
          }),
        });
        if (!dataSegment.data.createSegment) return;
        const { id: segmentId, targets: dataSegmentTargets } =
          dataSegment.data.createSegment;

        yield put(
          saveTargets({
            targets: {
              ...JSON.parse(dataSegmentTargets as string),
              segmentId: Number(segmentId),
              segmentName: dataSegment.data.createSegment.name,
              segmentDescription: dataSegment.data.createSegment.description,
            },
            updateTargetCopy: true,
          }),
        );
        yield put(resetSegment());
        yield put(
          updateChangeViewVisibility({
            isVisible: false,
            updateSelected: true,
          }),
        );
      }
    } else if (active.segment && active.segment!.id) {
      yield put(
        updateSegment({
          id: Number(active.segment!.id),
          name: active.segment?.name!,
          description: active.segment!.description!,
          targets: targetsCopyData!,
          reset: true,
          onSuccess: () => {},
        }),
      );
      if (customizeDataSegmentId === active.segment!.id) {
        yield put(
          saveTargets({
            targets: {
              ...targetsCopyData,
              segmentId: Number(active.segment!.id),
              segmentName: active.segment?.name!,
              segmentDescription: active.segment!.description!,
            },
            updateTargetCopy: true,
          }),
        );
      }
    }

    yield put(saveCampaignSuccess({ data: result }));
    yield put(hideModal('unSavedTargetChanges'));
    setTimeout(() => {
      action.payload.onSuccess && action.payload.onSuccess?.();
    }, 1000);
  } catch (error) {
    yield put(saveCampaignError());
  }
}

function* handleSaveSettings(action: SaveSettingsAction) {
  const { payload } = action;
  const { settings } = payload;
  if (!settings) return;
  const orjData: Campaign = yield select((state) => state.builder.orjData);
  const result = cloneDeep(orjData);
  result.settings = settings;
  const data = {
    settings: result.settings,
  };
  try {
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: updateCampaignGraphQLQuery(result.id, data),
    });

    yield put(saveCampaignSuccess({ data: result }));
  } catch (error) {
    yield put(saveCampaignError());
  }
}

function* handleSaveTargets(action: SaveTargetsAction) {
  const { payload } = action;
  const { targets, updateTargetCopy, onSuccess } = payload;
  if (!targets) return;
  const orjData: Campaign = yield select((state) => state.builder.data);
  const result = cloneDeep(orjData);
  result.targets = targets;
  const data = {
    targets: result.targets,
  };
  try {
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: updateCampaignGraphQLQuery(result.id, data),
    });
    yield put(saveCampaignSuccess({ data: result, updateTargetCopy }));
    yield put(getAllSegments({ id: result.id }));
    onSuccess && onSuccess();
  } catch (error) {
    yield put(saveCampaignError());
  }
}

function* handleChangeCampaignTemplate(action: ChangeCampaignTemplateAction) {
  try {
    const { onSuccess, payload } = action;
    const { campaignId, template } = payload;

    const templateData = cloneDeep(template);

    //Update template image url
    templateData.layout.contents.forEach((content) => {
      if (content.type === StepTypes.TEASER) return;
      content.template.id = template.id;
      content.template.name = templateData?.name ?? undefined;
      if (!templateData?.imageUrl) return;
      content.template.imageUrl = templateData?.imageUrl;
    });
    delete templateData.name;

    const isTemplateSelected: boolean = yield select(
      (state) =>
        state.campaigns.items?.find((item: Campaign) => item?.id === campaignId)
          ?.isTemplateSelected,
    ) || false;

    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: updateCampaignGraphQLQuery(campaignId, {
        layout: templateData.layout,
        popupType: templateData.popupType?.toString(),
        targets: templateData.targets,
        ...(!isTemplateSelected && { isTemplateSelected: true }),
      }),
    });

    yield put(
      getCampaign({
        id: campaignId,
        loadTargetsCopy: false,
        loadCustomizeCopy: true,
      }),
    );

    const { type } = yield take([GET_CAMPAIGN_SUCCESS, GET_CAMPAIGN_ERROR]);

    if (type === GET_CAMPAIGN_SUCCESS) {
      yield put(changeCampaignTemplateSuccess());
      onSuccess && onSuccess();
    }
  } catch (error) {
    yield put(changeCampaignTemplateError());
  }
}

function* handleGetTemplateAndUpdateCampaignTemplate(
  action: GetTemplateAndUpdateCampaignTemplateAction,
) {
  try {
    const { onSuccess, payload } = action;
    const { campaignId, templateId, newCampaign } = payload;
    yield put(reset());
    const result: {
      data: {
        templateById: TemplateResponse;
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: getTemplateGraphQLQuery(templateId),
    });

    const templateData = result.data.templateById;

    //Update template image url
    templateData.layout.contents.map((content) => {
      content.template.id = templateId;
      content.template.name = templateData?.name;

      if (!templateData?.imageUrl) return content;

      content.template.imageUrl = templateData?.imageUrl;
      return content;
    });

    delete templateData.name;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: updateCampaignGraphQLQuery(campaignId, {
        layout: templateData.layout,
        targets: templateData.targets,
        ...(newCampaign && { isTemplateSelected: true }),
      }),
    });

    onSuccess && onSuccess();
    yield put(getTemplateAndUpdateCampaignTemplateSuccess());
    onSuccess && onSuccess();
  } catch (error) {
    yield put(getTemplateAndUpdateCampaignTemplateError());
  }
}

function* handleDeleteDomain(action: DeleteDomainAction) {
  const { payload } = action;
  try {
    yield put({ type: DOMAIN_DELETE_SUCCESS, payload });
  } catch (error) {
    yield put(getCampaignError());
  }
}

function* handleSelectDomain(action: DeleteDomainAction) {
  const { payload } = action;
  try {
    yield put({ type: DOMAIN_SELECT_SUCCESS, payload });
  } catch (error) {
    yield put(getCampaignError());
  }
}

function* updateAutoResponder(action: UpdateAutoResponderAction) {
  const { payload, onSuccess } = action;
  const { id } = payload;
  try {
    const data: IAutoResponder = yield call(
      fetch,
      `/api/Campaign/${id}/auto-responder`,
      {
        method: 'PATCH',
        data: action.payload,
      },
    );
    yield put({ type: UPDATE_AUTO_RESPONDER_SUCCESS, payload: data });
    onSuccess?.();
  } catch (error) {
    yield put({ type: UPDATE_AUTO_RESPONDER_FAILURE });
  }
}

function* getCurrentTemplate(action: GetCurrentTemplateAction) {
  try {
    const { id } = action.payload;
    const result: {
      data: {
        templateById: Template;
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: getTemplateGraphQLQuery(id),
    });
    const data = result.data.templateById;

    yield put(getCurrentTemplateSuccess({ data }));
  } catch (err) {
    yield put(getCurrentTemplateError());
  }
}

export default function* sagas() {
  yield takeLatest(GET_CAMPAIGN, handleGetCampaign);
  yield takeLatest(GET_NEW_PAGE_TEMPLATE, handleGetNewPage);
  yield takeLatest(SAVE_CAMPAIGN, handleSaveCampaign);
  yield takeLatest(SAVE_SETTINGS, handleSaveSettings);
  yield takeLatest(SAVE_TARGETS, handleSaveTargets);
  yield takeLatest(CHANGE_CAMPAIGN_TEMPLATE, handleChangeCampaignTemplate);
  yield takeLatest(
    GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE,
    handleGetTemplateAndUpdateCampaignTemplate,
  );
  yield takeLatest(DOMAIN_DELETE, handleDeleteDomain);
  yield takeLatest(DOMAIN_SELECT, handleSelectDomain);
  yield takeLatest(UPDATE_AUTO_RESPONDER_REQUEST, updateAutoResponder);
  yield takeLatest(GET_CURRENT_TEMPLATE, getCurrentTemplate);
}
