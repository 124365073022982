import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import type { CampaignGoal, Template, TemplateTag } from './types';

import {
  GET_TEMPLATES_REQUEST,
  templateServiceURl,
  GET_CAMPAIGNGOALS_REQUEST,
  GET_TAGS_REQUEST,
} from './constants';

import fetch from '@utils/fetch';
import {
  getCampaignGoalsError,
  getCampaignGoalsSuccess,
  getTagsError,
  getTagsSuccess,
  getTemplatesError,
  getTemplatesSuccess,
} from './actions';

function* getTemplatesSaga({ payload }: ReduxAction) {
  try {
    const data: Template[] = yield call(fetch, `${templateServiceURl}/list`, {
      method: 'POST',
      data: payload,
    });

    yield put(getTemplatesSuccess({ data }));
  } catch (err) {
    yield put(getTemplatesError());
  }
}

function* getCampaignGoalsSaga() {
  try {
    const data: CampaignGoal[] = yield call(fetch, '/api/CampaignGoal', {
      method: 'GET',
    });

    yield put(getCampaignGoalsSuccess({ data }));
  } catch (err) {
    yield put(getCampaignGoalsError());
  }
}

function* getTagsSaga() {
  try {
    const data: TemplateTag[] = yield call(fetch, 'api/template/get-tags', {
      method: 'GET',
    });

    yield put(getTagsSuccess({ data }));
  } catch (err) {
    yield put(getTagsError());
  }
}

export default function* sagas() {
  yield takeLatest(GET_TEMPLATES_REQUEST, getTemplatesSaga);
  yield takeLatest(GET_CAMPAIGNGOALS_REQUEST, getCampaignGoalsSaga);
  yield takeLatest(GET_TAGS_REQUEST, getTagsSaga);
}
