import { WebhookInput } from 'api-core/modules/integrations/resolvers/WebhookResolver.types';

export const GetWebhookIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetWebhookIntegrationsByCampaignId',
  query: `query GetWebhookIntegrationsByCampaignId($campaignId: Int!) {
    getWebhookIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      name
      url
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetWebhookIntegrationsByAccountId = () => ({
  operationName: 'GetWebhookIntegrationsByAccountId',
  query: `query GetWebhookIntegrationsByAccountId {
    getWebhookIntegrationsByAccountId {
      id
      campaignId
      name
      url
    }
  }`,
});
export const CreateWebhookIntegration = (input: WebhookInput) => ({
  operationName: 'CreateWebhookIntegration',
  query: `mutation CreateWebhookIntegration($input: WebhookInput!) {
    createWebhookIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateWebhookIntegration = (id: number, input: WebhookInput) => ({
  operationName: 'UpdateWebhookIntegration',
  query: `mutation UpdateWebhookIntegration($input: WebhookInput!, $updateWebhookIntegrationId: Int!) {
    updateWebhookIntegration(input: $input, id: $updateWebhookIntegrationId)
  }`,
  variables: {
    input,
    updateWebhookIntegrationId: id,
  },
});

export const DeleteWebhookIntegration = (id: number) => ({
  operationName: 'DeleteWebhookIntegration',
  query: `mutation DeleteWebhookIntegration($deleteWebhookIntegrationId: Int!) {
    deleteWebhookIntegration(id: $deleteWebhookIntegrationId)
  }`,
  variables: {
    deleteWebhookIntegrationId: id,
  },
});
