import { randomCode, randomCodeGenerateWithPrefix } from 'core/helpers/string';
import { ElementInitialValueType } from './types';

const ElementInitialValues: ElementInitialValueType = {
  subtext: {
    id: randomCode(),
    nodeName: 'subtext',
    attributes: {
      style: {
        lineHeight: undefined,
        color: '#000000',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        height: 'fit-content',
        wordBreak: 'break-word',
      },
    },
    childNodes: ['New Subtext'],
  },
  text: {
    id: randomCode(),
    nodeName: 'text',
    attributes: {
      style: {
        lineHeight: undefined,
        color: '#000000',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        height: 'fit-content',
        wordBreak: 'break-word',
      },
      showOnHover: false,
    },
    childNodes: ['New Text'],
  },
  headline: {
    id: randomCode(),
    nodeName: 'headline',
    attributes: {
      style: {
        lineHeight: '120%',
        color: '#000000',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        height: 'fit-content',
        wordBreak: 'break-word',
      },
    },
    childNodes: ['New Text'],
  },
  image: {
    id: randomCode(),
    nodeName: 'image',
    childNodes: [],
    attributes: {
      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/empty.png`,
      alt: 'Popup Image',
      position: 'center-center',
      imageLinking: false,
      action: {
        type: undefined,
        value: '',
        pageName: '',
        countAsInteraction: false,
      },
      style: {
        display: 'flex',
        objectPosition: 'center center',
        objectFit: 'cover',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
  },
  'close-button': {
    id: randomCode(),
    nodeName: 'close-button',
    attributes: {
      action: {
        type: 'close',
        pageName: '',
        countAsInteraction: false,
      },
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        zIndex: '1',
        top: '0px',
        right: '0px',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginTop: '12px',
        marginRight: '12px',
        width: '12px',
        color: '#ffffff',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
    },
    childNodes: [],
  },
  button: {
    id: randomCode(),
    nodeName: 'button',
    attributes: {
      type: 'primary',
      action: {
        type: 'close',
        value: '',
        pageName: '',
        countAsInteraction: true,
      },
      style: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
      },
    },
    childNodes: ['<p style="text-align: center;">New Button</p>'],
  },
  'button-wrapper': {
    id: randomCode(),
    nodeName: 'button-wrapper',
    attributes: {
      style: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        gridTemplateColumns: '1fr', //Don't delete
        justifyContent: 'center',
      },
    },
    childNodes: [
      {
        id: randomCode(),
        nodeName: 'button',
        attributes: {
          type: 'primary',
          action: {
            type: 'close',
            value: '',
            pageName: '',
            countAsInteraction: true,
          },
          style: {
            marginLeft: '0px',
            marginRight: '0px',
            marginTop: '10px',
            marginBottom: '10px',
            width: '100%',
            height: 'fit-content',
          },
        },
        childNodes: ['<p style="text-align: center;">New Button</p>'],
      },
    ],
  },
  block: {
    id: randomCode(),
    nodeName: 'block',
    attributes: {
      backgroundLottie: {
        src: '',
        show: false,
      },
      position: 'center',
      style: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '100%',
        borderTopColor: '#000000',
        borderTopStyle: 'dashed',
        borderTopWidth: '1px',
        borderBottomColor: '#000000',
        borderBottomStyle: 'dashed',
        borderBottomWidth: '1px',
        borderLeftColor: '#000000',
        borderLeftStyle: 'dashed',
        borderLeftWidth: '1px',
        borderRightColor: '#000000',
        borderRightStyle: 'dashed',
        borderRightWidth: '1px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        overflow: 'visible',
      },
    },
    childNodes: [],
  },
  'text-input': {
    id: randomCode(),
    nodeName: 'text-input',
    attributes: {
      placeholder: 'Text Input',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('text_input'),
    },
    childNodes: [],
  },
  'full-name-input': {
    id: randomCode(),
    nodeName: 'full-name-input',
    attributes: {
      placeholder: 'Full Name',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('full_name_input'),
    },
    childNodes: [],
  },
  'long-input': {
    id: randomCode(),
    nodeName: 'long-input',
    attributes: {
      placeholder: 'Long Input',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('long_input'),
    },
    childNodes: [],
  },
  'address-input': {
    id: randomCode(),
    nodeName: 'address-input',
    attributes: {
      placeholder: 'Address',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('address_input'),
    },
    childNodes: [],
  },
  'radio-input': {
    id: randomCode(),
    nodeName: 'radio-input',
    attributes: {
      required: false,
      requiredMessage: 'This field is required',
      labelText: 'Enter your question here',
      uncheckedColor: '#717791',
      checkedColor: '#909090',
      uiType: 'dot',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
      style: {
        display: 'flex',
        alignItems: 'center',
        justifySelf: 'center',
        width: '100%',
        height: 'auto',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        gap: '10px',
      },
      name: randomCodeGenerateWithPrefix('radio_input'),
    },
    childNodes: [],
  },
  'multi-choice': {
    id: randomCode(),
    nodeName: 'multi-choice',
    attributes: {
      required: false,
      requiredMessage: 'This field is required',
      labelText: 'Enter your question here',
      uncheckedColor: '#717791',
      checkedColor: '#909090',
      uiType: 'checkbox',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
      style: {
        display: 'flex',
        alignItems: 'center',
        justifySelf: 'center',
        width: '100%',
        height: 'auto',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        gap: '10px',
      },
      name: randomCodeGenerateWithPrefix('multi_choice'),
    },
    childNodes: [],
  },
  'dropdown-input': {
    id: randomCode(),
    nodeName: 'dropdown-input',
    attributes: {
      placeholder: 'Select your option',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('dropdown_input'),
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    childNodes: [],
  },
  'date-input': {
    id: randomCode(),
    nodeName: 'date-input',
    attributes: {
      placeholder: 'Select date',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      format: 'local',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('date_input'),
    },
    childNodes: [],
  },
  'email-input': {
    id: randomCode(),
    nodeName: 'email-input',
    attributes: {
      placeholder: 'Email Address',
      placeholderColor: '#a9a9a9',
      validationMessage: 'Enter a valid email address',
      required: false,
      requiredMessage: 'This field is required',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('email_input'),
    },
    childNodes: [],
  },
  'phone-input': {
    id: randomCode(),
    nodeName: 'phone-input',
    attributes: {
      placeholder: 'Phone Number',
      placeholderColor: '#a9a9a9',
      required: false,
      requiredMessage: 'This field is required',
      validationMessage: 'Enter a valid phone number',
      showFlag: false,
      defaultCountry: 'us',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('phone_input'),
    },
    childNodes: [],
  },
  'hidden-input': {
    id: randomCode(),
    nodeName: 'hidden-input',
    attributes: {
      placeholder: 'Hidden Input',
      placeholderColor: '#a9a9a9',
      isPrefillActive: false,
      prefillData: [],
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
        height: 'fit-content',
      },
      name: randomCodeGenerateWithPrefix('hidden_input'),
    },
    childNodes: [],
  },
  'yes-no': {
    id: randomCode(),
    nodeName: 'yes-no',
    attributes: {
      yesText: 'Yes',
      noText: 'No',
      yesAction: {
        type: 'close',
        value: '',
        pageName: '',
        countAsInteraction: false,
      },
      noAction: {
        type: 'close',
        value: '',
        pageName: '',
        countAsInteraction: false,
      },
      yesColor: '#9F9F9F',
      noColor: '#3E3E3E',
      style: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: undefined,
        color: '#ffffff',
        width: 'auto',
        height: 'auto',
        paddingTop: '9px',
        paddingBottom: '9px',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        gap: '10px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      name: randomCodeGenerateWithPrefix('yes_no'),
    },
    childNodes: [],
  },
  'opinion-scale': {
    id: randomCode(),
    nodeName: 'opinion-scale',
    attributes: {
      indexes: '1-5',
      showLabel: false,
      leftLabel: 'Bad',
      centerLabel: 'Good',
      rightLabel: 'Awesome',
      labelColor: '#000000',
      activeTextColor: '#FFFFFF',
      activeBackgroundColor: '#909090',
      required: false,
      requiredMessage: 'This field is required',
      action: {
        type: 'none',
        value: '',
        pageName: '',
        countAsInteraction: false,
      },
      style: {
        width: '185px',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
      },
      name: randomCodeGenerateWithPrefix('opinion_scale'),
    },
    childNodes: [],
  },
  rating: {
    id: randomCode(),
    nodeName: 'rating',
    attributes: {
      type: 'Star',
      value: -1,
      iconSpace: '8px',
      required: false,
      requiredMessage: 'This field is required',
      labelText: 'Enter your label here',
      action: {
        type: 'none',
        value: '',
        pageName: '',
        countAsInteraction: false,
      },
      style: {
        width: '32px',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
      },
      name: randomCodeGenerateWithPrefix('rating'),
    },
    childNodes: [],
  },
  social: {
    id: randomCode(),
    nodeName: 'social',
    attributes: {
      type: 'follow',
      action: {
        countAsInteraction: false,
      },
      iconSize: '36px',
      style: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    childNodes: [
      {
        id: randomCode(),
        nodeName: 'social-element',
        attributes: {
          text: '',
          type: 'instagram',
          url: '#',
          shareText: 'Post',
          iconColor: '#909090',
          style: {
            background: 'transparent',
            marginLeft: '0px',
            marginRight: '0px',
          },
        },
        childNodes: [],
      },
      {
        id: randomCode(),
        nodeName: 'social-element',
        attributes: {
          text: '',
          type: 'twitter',
          url: '#',
          shareText: 'Tweet',
          iconColor: '#909090',
          style: {
            background: 'transparent',
            marginLeft: '0px',
            marginRight: '0px',
          },
        },
        childNodes: [],
      },
      {
        id: randomCode(),
        nodeName: 'social-element',
        attributes: {
          text: '',
          type: 'facebook',
          url: '#',
          shareText: 'Share',
          iconColor: '#909090',
          style: {
            background: 'transparent',
            marginLeft: '0px',
            marginRight: '0px',
          },
        },
        childNodes: [],
      },
    ],
  },
  'coupon-code': {
    id: randomCode(),
    nodeName: 'coupon-code',
    attributes: {
      type: 'fix',
      showLabel: false,
      label: 'Label',
      labelColor: '#000000',
      useGenerator: true,
      couponQty: 5,
      couponPrefix: 'PPS_',
      couponCodes: '[COUPON CODE]',
      couponCopyMessage: 'Copied to clipboard!',
      style: {
        display: 'flex',
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'center',
      },
      name: randomCodeGenerateWithPrefix('coupon_code'),
    },
    childNodes: ['[COUPON CODE]'],
  },
  countdown: {
    id: randomCode(),
    nodeName: 'countdown',
    attributes: {
      type: 'dynamic',
      timezone: 'Europe/London',
      fixedDate: new Date(),
      message: '',
      days: 2,
      hours: 12,
      minutes: 30,
      seconds: 10,
      showLabel: false,
      daysLabel: 'Days',
      hoursLabel: 'Hours',
      minutesLabel: 'Minutes',
      secondsLabel: 'Seconds',
      showDays: true,
      showHours: true,
      showMinutes: true,
      showSeconds: true,
      boxBackground: '#000000',
      labelColor: '#000000',
      numberColor: '#FFFFFF',
      seperatorColor: '#000000',
      action: {
        type: 'close',
        value: '',
        pageName: '',
      },
      style: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        width: '32px',
        height: 'auto',
        justifyContent: 'center',
      },
    },
    childNodes: [],
  },
  youtube: {
    id: randomCode(),
    nodeName: 'youtube',
    attributes: {
      url: 'https://youtu.be/iAfZQbS8ay0',
      autoPlay: false,
      isCover: false,
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    childNodes: [],
  },
  vimeo: {
    id: randomCode(),
    nodeName: 'vimeo',
    attributes: {
      url: 'https://vimeo.com/879773253',
      autoPlay: false,
      isCover: false,
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    childNodes: [],
  },
  'notice-and-consent': {
    id: randomCode(),
    nodeName: 'notice-and-consent',
    attributes: {
      required: true,
      requiredMessage: 'This field is required',
      checkedColor: '#000000',
      uncheckedColor: '#717791',
      checkboxSize: '18px',
      name: randomCodeGenerateWithPrefix('notice_and_consent'),
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        letterSpacing: '-0.01px',
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        gap: '10px',
      },
    },
    childNodes: [
      '<p>I have read and accept the <a href="/privacy-policy" rel="noopener noreferrer" target="_blank"><u>privacy policy</u></a></p>',
    ],
  },
  'form-button': {
    id: randomCode(),
    nodeName: 'button',
    attributes: {
      type: 'primary',
      action: {
        type: 'url',
        value: '',
        pageName: '',
        target: 'new-tab',
        countAsInteraction: true,
      },
      style: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '6px',
        marginBottom: '6px',
        width: '100%',
      },
    },
    childNodes: ['<p style="text-align: center;">Form Button</p>'],
  },
  lottie: {
    id: randomCode(),
    nodeName: 'lottie',
    attributes: {
      src: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/add_lottie.json`,
      autoplay: true,
      loop: true,
      style: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    childNodes: [],
  },
  'spin-to-win': {
    id: randomCode(),
    nodeName: 'spin-to-win',
    childNodes: [],
    attributes: {
      wheelOptions: {
        '#text1': {
          label: 'Text 1',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/gitf-icon.png`,
          chance: 1,
          code: 'CODE_1',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text2': {
          label: 'Text 2',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/happy-icon.png`,
          chance: 2,
          code: 'CODE_2',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text3': {
          label: 'Text 3',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/happy-2-icon.png`,
          chance: 1,
          code: 'CODE_3',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text4': {
          label: 'Text 4',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/percent-icon.png`,
          chance: 3,
          code: 'CODE_4',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text5': {
          label: 'Text 5',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/retry-icon.png`,
          chance: 1,
          code: 'CODE_5',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text6': {
          label: 'Text 6',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/sad-icon.png`,
          chance: 1,
          code: 'CODE_6',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text7': {
          label: 'Text 7',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/ticket-icon.png`,
          chance: 3,
          code: 'CODE_7',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text8': {
          label: 'Text 8',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/wow-icon.png`,
          chance: 1,
          code: 'CODE_8',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text9': {
          label: 'Text 9',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/gitf-icon.png`,
          chance: 1,
          code: 'CODE_9',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text10': {
          label: 'Text 10',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/happy-icon.png`,
          chance: 1,
          code: 'CODE_10',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text11': {
          label: 'Text 11',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/happy-2-icon.png`,
          chance: 2,
          code: 'CODE_11',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#text12': {
          label: 'Text 12',
          icon: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/percent-icon.png`,
          chance: 2,
          code: 'CODE_12',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
      },
      colors: {
        spin: ['#e13e00', '#257184', '#4c3ed2', '#8e00c6'],
        arrow: '#f5b500',
        text: '#ffffff',
      },
      center: {
        logo: `${process.env.NEXT_PUBLIC_CDN_URL}/assets/popupsmart-logo.png`,
      },
      name: randomCodeGenerateWithPrefix('spin_to_win', 5),
      style: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
  },
  'lottery-ball': {
    id: randomCode(),
    nodeName: 'lottery-ball',
    childNodes: [],
    attributes: {
      lotteryOptions: {
        '#ball1': {
          label: '5%',
          chance: 1,
          code: 'CODE_1',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball2': {
          label: 'No Luck',
          chance: 1,
          code: 'CODE_2',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball3': {
          label: '15%',
          chance: 2,
          code: 'CODE_3',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball4': {
          label: '50%',
          chance: 1,
          code: 'CODE_4',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball5': {
          label: 'No Luck',
          color: 'yellow',
          chance: 4,
          code: 'CODE_5',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball6': {
          label: '45%',
          color: 'orange',
          chance: 1,
          code: 'CODE_6',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball7': {
          label: '35%',
          chance: 2,
          code: 'CODE_7',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
        '#ball8': {
          label: '10%',
          chance: 2,
          code: 'CODE_8',
          action: {
            type: 'page',
            value: '',
            pageName: '',
          },
        },
      },
      colors: {
        ball: [
          '#FFC700',
          '#ED7B57',
          '#FFBEA9',
          '#C73000',
          '#F8E7AE',
          '#FF9473',
          '#ED7B57',
          '#892D11',
        ],
        text: '#ffffff',
      },
      name: randomCodeGenerateWithPrefix('lottery_ball', 5),
      style: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        color: '#ffffff',
      },
    },
  },
  form: {
    id: randomCode(),
    nodeName: 'form',
    attributes: {
      style: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingLeft: '0px',
        paddingRight: '0px',
      },
      name: randomCodeGenerateWithPrefix('form'),
    },
    childNodes: [],
  },
  'default-form-button': {
    id: randomCode(),
    nodeName: 'button-wrapper',
    attributes: {
      style: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        gridTemplateColumns: '1fr', //Don't delete
        justifyContent: 'center',
      },
    },
    childNodes: [
      {
        id: randomCode(),
        nodeName: 'form-button',
        attributes: {
          type: 'primary',
          action: {
            type: 'close',
            value: '',
            pageName: '',
            countAsInteraction: true,
          },
          style: {
            marginLeft: '0px',
            marginRight: '0px',
            marginTop: '10px',
            marginBottom: '10px',
            width: '100%',
            height: 'fit-content',
          },
        },
        childNodes: ['<p style="text-align: center;">Submit Button</p>'],
      },
    ],
  },
  shopify: {
    id: randomCode(),
    nodeName: 'shopify',
    attributes: {
      collectionUrl: '',
      currency: {
        value: '€',
        label: 'Euro (€)',
      },
      type: 'slide',
      style: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    childNodes: [
      {
        id: randomCode(),
        nodeName: 'button-wrapper',
        attributes: {
          style: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            gridTemplateColumns: 'max-content', //Don't delete
            justifyContent: 'center',
          },
        },
        childNodes: [
          {
            id: randomCode(),
            nodeName: 'button',
            attributes: {
              type: 'shopify',
              action: {
                type: 'go-product',
                value: '',
                pageName: '',
                countAsInteraction: true,
              },
              style: {
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '20px',
                paddingRight: '20px',
                marginLeft: '0px',
                marginRight: '0px',
                marginTop: '0px',
                marginBottom: '0px',
                textAlign: 'inherit',
                width: 'auto',
                height: 'fit-content',
              },
            },
            childNodes: ['<p style="text-align: center;">Buy now</p>'],
          },
        ],
      },
    ],
  },
};

export default ElementInitialValues;
