/* eslint-disable no-useless-escape */
import type { Unit } from '@elements/SizeInput';
import { formNodeNameList } from '@elements/SmartTag/common/StaticVariables';

export const getValue = (
  valueStr?: string,
): number | 'auto' | 'reset' | undefined => {
  if (!valueStr) return 0;
  if (valueStr === 'auto') return 'auto';

  const value = parseFloat(valueStr);
  return value;
};

export const getUnit = (valueStr?: string): Unit => {
  if (!valueStr) return 'px';
  if (valueStr === 'auto') return 'px';

  const value = parseFloat(valueStr);
  const unit = valueStr.substr(`${value}`.length).trim();
  return unit as Unit;
};

export const replaceUnit = (valueStr: string, newUnit: Unit): string => {
  const value = getValue(valueStr);

  if (value === 'auto' || newUnit === 'auto') return 'auto';
  return `${value}${newUnit}`;
};

export const replaceValue = (
  valueStr: string,
  newValue: number | 'auto',
): string => {
  const unit = getUnit(valueStr);

  if (newValue === 'auto' || unit === 'auto') return 'auto';
  return `${newValue}${unit}`;
};

export const toPx = (
  cssValue: string,
  key: keyof CSSStyleDeclaration,
  target?: HTMLElement,
  baseSize?: string,
): number | undefined => {
  const t = target || document.querySelector('[data-frame]') || document.body;
  const style = getComputedStyle(t);

  if (cssValue === 'auto') {
    return parseFloat(style[key as number]);
  }

  const supportedUnits = {
    px: (value: number) => value,
    rem: (value: number) =>
      value * parseFloat(getComputedStyle(document.documentElement).fontSize),
    em: (value: number) => value * parseFloat(baseSize || style.fontSize),
    vw: (value: number) => (value / 100) * window.innerWidth,
    vh: (value: number) => (value / 100) * window.innerHeight,
    '%': (value: number) => {
      return (value / 100) * parseFloat(style[key as number]);
    },
  };

  const pattern = new RegExp(
    `^([\-\+]?(?:\\d+(?:\\.\\d+)?))(${Object.keys(supportedUnits).join('|')})$`,
    'i',
  );

  const matches = cssValue?.trim()?.match(pattern);
  if (!matches) return undefined;

  const value = Number(matches[1]);
  const unit = matches[2].toLocaleLowerCase() as
    | 'px'
    | 'rem'
    | 'em'
    | 'vw'
    | 'vh'
    | '%';

  if (unit in supportedUnits) {
    const result = supportedUnits[unit](value);
    return result;
  }
};

export const fromPx = (
  cssValue: string,
  targetUnit: 'px' | 'rem' | 'em' | 'vw' | 'vh' | '%' | 'pc',
  key: keyof CSSStyleDeclaration,
  target?: HTMLElement,
  baseSize?: string,
): number | undefined | boolean => {
  const t = target || document.querySelector('[data-frame]') || document.body;
  const style = getComputedStyle(t);

  if (cssValue === 'auto') return false;

  const supportedUnits = {
    px: (value: number) => value,
    rem: (value: number) =>
      value / parseFloat(getComputedStyle(document.documentElement).fontSize),
    em: (value: number) => value / parseFloat(baseSize || style.fontSize),
    vw: (value: number) => (value * 100) / window.innerWidth,
    vh: (value: number) => (value * 100) / window.innerHeight,
    '%': (value: number) => {
      if (key === 'lineHeight' && baseSize)
        return (value * 100) / parseFloat(baseSize);
      if (key === 'borderRadius') {
        return value;
      }

      return (value * 100) / parseFloat(style[key as number]);
    },
    pc: (value: number) => value,
  };

  const pattern = new RegExp(
    `^([\-\+]?(?:\\d+(?:\\.\\d+)?))(${Object.keys(supportedUnits).join('|')})$`,
    'i',
  );

  const matches = cssValue?.trim()?.match(pattern);
  if (!matches) return undefined;

  const value = Number(matches[1]);
  if (targetUnit in supportedUnits) {
    return supportedUnits[targetUnit](value);
  }
};

export const unitConverter = (
  currentSize: number | 'auto',
  currentUnit: string,
  targetUnit: 'px' | 'rem' | 'em' | 'vw' | 'vh' | '%' | 'pc',
  key: keyof CSSStyleDeclaration, //for % change
  fontSize?: string,
  target?: HTMLElement,
): number | undefined | boolean => {
  let pixelValue = currentSize;
  let baseFontSize: string | number | undefined = fontSize;

  if (fontSize && !fontSize.includes('px')) {
    const pxValue = toPx(fontSize, 'fontSize');
    if (pxValue) baseFontSize = `${pxValue}px`;
  }

  if (currentUnit !== 'px' || pixelValue === 'auto') {
    const pxSize = toPx(
      pixelValue === 'auto' ? 'auto' : `${pixelValue}${currentUnit}`,
      key,
      target,
      baseFontSize,
    );
    if (pxSize) pixelValue = pxSize;
  }

  if (typeof pixelValue !== 'number') return false;

  const result = fromPx(
    `${pixelValue}px`,
    targetUnit,
    key,
    target,
    baseFontSize,
  );

  return typeof result === 'number' ? Number(result.toFixed(2)) : result;
};

// this method will allow us to get the TextNode at character index
export const getNodeAtCharPos = (parent: HTMLElement, pos: number) => {
  const walker = document.createTreeWalker(parent, NodeFilter.SHOW_TEXT);
  let chars = 0;
  let nodeLength = 0;

  while (chars < pos && walker.nextNode()) {
    nodeLength = walker?.currentNode?.textContent?.length || 0;
    chars += nodeLength;
  }

  return {
    node: walker.currentNode,
    index: Math.min(pos - (chars - nodeLength), nodeLength),
  };
};

export const setCaret = (item: HTMLElement, pos: number) => {
  if (pos < 0) return;

  const nodeAtPos = getNodeAtCharPos(item, pos);
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(nodeAtPos.node, nodeAtPos.index);
  sel?.removeAllRanges();
  sel?.addRange(range);
};

export const isEditableElement = (element?: HTMLElement | null): boolean => {
  if (!element) return false;
  if (!element?.hasAttribute('data-editable')) return false;

  if (
    [...formNodeNameList, 'form-button', 'button'].includes(
      element?.getAttribute('data-element') ?? '',
    )
  )
    return false;

  if (element?.querySelector('[data-editable-text="true"]')) return true;
  return false;
};

export const isElementWithActions = (element?: HTMLElement | null): boolean => {
  if (!element) return false;
  const elementName = element?.getAttribute('data-element');
  if (elementName !== null) {
    if (['close-button', 'form-button'].includes(elementName)) return false;
  }
  return true;
};

export const getEditableElement = (element?: HTMLElement | null) => {
  if (!element) return null;
  if (!element?.hasAttribute('data-editable')) return null;
  if (element?.getAttribute('data-editable') === 'false') return null;

  if (
    [
      ...formNodeNameList,
      'lottie',
      'image',
      'youtube',
      'form-button',
      'button-wrapper',
      'form',
      'countdown',
      'coupon-code',
      'shopify',
      'button',
    ].includes(element?.getAttribute('data-element') ?? '')
  )
    return null;

  if (element?.hasAttribute('data-editable-text')) return element;
  else if (element?.querySelector('[data-editable-text]'))
    return element?.querySelector('[data-editable-text]') as HTMLElement;
};

//like -webkit-fill-available
export const getDynamicHeight = (
  height: number | string | undefined,
  marginTop: number | string | undefined,
  marginBottom: number | string | undefined,
) => {
  let h = height;
  let mt = marginTop;
  let mb = marginBottom;
  if (!height || height === 'auto') h = '100%';
  if (!marginTop || marginTop === 'auto') mt = '0px';
  if (!marginBottom || marginBottom === 'auto') mb = '0px';

  return `calc(${h} - ${mt} - ${mb})`;
};

export const getCorrectSize = (
  value: number | string | 'auto',
  unit?: Unit,
): string => {
  const val =
    value === 'auto' || unit === 'auto'
      ? 'auto'
      : `${value === 'manual' ? '90%' : value}${unit ? unit : ''}`;
  return val;
};

export const getResponsiveStyle = (
  style: Partial<CSSStyleDeclaration>,
  baseFontSize = '16px',
) => {
  let newStyle = { ...style };
  for (const [key, value] of Object.entries(style)) {
    if (
      value &&
      value !== null &&
      value.toString().includes('px') &&
      (key.includes('margin') ||
        key.includes('padding') ||
        key.toLowerCase().includes('width') ||
        key.toLowerCase().includes('height') ||
        key.toLowerCase().includes('fontsize') ||
        key.toLowerCase().includes('lineHeight'))
    ) {
      let newValue = fromPx(
        value.toString(),
        'em',
        key as keyof CSSStyleDeclaration,
        undefined,
        baseFontSize,
      );
      if (newValue && typeof newValue === 'number') {
        newValue = Number(newValue.toFixed(2));
        newStyle = { ...newStyle, [key]: `${newValue}em` };
      }
    }
  }
  return newStyle;
};

const semicolonRgx = /\s*(!important)?\s*\s*;/g;
export const CSSToImportant = (value: string | undefined) => {
  return value?.replaceAll(semicolonRgx, '!important;');
};

export const normalizeElements = (elements: string[]) =>
  elements.map((x: string) =>
    (x.charAt(0).toUpperCase() + x.slice(1)).replaceAll('-', ' '),
  );

export const deNormalizeElement = (element: string) => {
  let current = element.charAt(0).toLocaleLowerCase() + element.slice(1);
  return current.replaceAll(' ', '-');
};

export const layoutElementsFilterAndGroup = (elements: string[]) => {
  const allowedElements = [
    'countdown',
    'coupon-code',
    'date-input',
    'email-input',
    'text-input',
    'image',
    'lottery-ball',
    'multi-choice',
    'notice-and-consent',
    'opinion-scale',
    'phone-input',
    'radio-input',
    'rating',
    'shopify',
    'social',
    'spin-to-win',
    'youtube',
  ];

  const sortedList: Array<string | null> = [
    'email',
    'phone',
    'coupon-code',
    'gamification',
    'countdown',
    'shopify-product-recommendation',
    'social',
    'opinion-scale-/-NPS',
    'rating',
    'full-name',
  ];

  let current = elements
    .filter((x) => allowedElements.includes(x))
    .map((x) => {
      switch (x) {
        case 'text-input':
          return 'full-name';
        case 'date-input':
          return 'date';
        case 'email-input':
          return 'email';
        case 'phone-input':
          return 'phone';
        case 'radio-input':
          return 'radio';
        case 'opinion-scale':
          return 'opinion-scale-/-NPS';
        case 'spin-to-win':
          return 'gamification';
        case 'lottery-ball':
          return 'gamification';
        case 'shopify':
          return 'shopify-product-recommendation';
        default:
          return x;
      }
    });

  const result: string[] = [];
  const filtered = current.filter(
    (value, index, array) => array.indexOf(value) === index,
  );
  sortedList.forEach((x) => {
    if (x && filtered.indexOf(x) !== -1) result.push(x);
  });
  filtered.forEach((x) => {
    if (sortedList.indexOf(x) === -1) result.push(x);
  });
  return result;
};

export const layoutElementsGroupRevert = (elements: string[]) => {
  return elements
    .map((x) => {
      switch (x) {
        case 'full-name':
          return 'text-input';
        case 'date':
          return 'date-input';
        case 'email':
          return 'email-input';
        case 'phone':
          return 'phone-input';
        case 'radio':
          return 'radio-input';
        case 'opinion-scale-/-NPS':
          return 'opinion-scale';
        case 'gamification':
          return 'spin-to-win|lottery-ball';
        case 'shopify-product-recommendation':
          return 'shopify';
        default:
          return x;
      }
    })
    .sort((a, b) => a.localeCompare(b));
};
