import React from 'react';
import cn from 'classnames';

import styles from './Timeline.module.scss';

interface TimelineProps {
  label: string | React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  alert?: boolean;
  end?: boolean;
  behavior?: boolean;
}

const TimelineContent = (props: TimelineProps) => {
  const { children, icon, label, alert, end, behavior } = props;

  return (
    <div
      className={cn(
        styles.content,
        end && styles.last,
        behavior && styles.behavior,
      )}
    >
      <div className={styles.container}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={cn(styles.label, alert && '!text-red')}>{label}</div>
      </div>
      {children && <div className={styles.subContent}>{children}</div>}
    </div>
  );
};

export default TimelineContent;
