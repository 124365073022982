import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  MAILERLITE_GET_FIELDS,
  MAILERLITE_GET_FIELDS_SUCCESS,
  MAILERLITE_GET_FIELDS_FAILURE,
  MAILERLITE_GET_CONNECTIONS,
  MAILERLITE_GET_CONNECTIONS_SUCCESS,
  MAILERLITE_GET_CONNECTIONS_FAILURE,
  MAILERLITE_GET_LIST,
  MAILERLITE_GET_LIST_SUCCESS,
  MAILERLITE_GET_LIST_FAILURE,
  MAILERLITE_SAVE,
  MAILERLITE_SAVE_SUCCESS,
  MAILERLITE_SAVE_FAILURE,
  MAILERLITE_CHANGESTATUS,
  MAILERLITE_CHANGESTATUS_FAILURE,
  MAILERLITE_CHANGESTATUS_SUCCESS,
  MAILERLITE_DELETE,
  MAILERLITE_DELETE_FAILURE,
  MAILERLITE_DELETE_SUCCESS,
  MAILERLITE_CLEAR_STATE,
} from './constants';
import { MailerliteState } from './type';
import produce, { Draft } from 'immer';

const initialState: MailerliteState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  testResult: {
    result: {
      isSent: false,
      sentData: [],
      message: '',
      messageTitle: '',
      errorLogs: '',
    },
    status: ReduxStatus.initialized,
  },
  status: ReduxStatus.initialized,
};

const reducer = produce(
  (draft: Draft<MailerliteState>, action: ReduxAction) => {
    const { type } = action;
    switch (type) {
      case MAILERLITE_GET_FIELDS:
        draft.fields = { items: [], status: ReduxStatus.loading };
        return;
      case MAILERLITE_GET_FIELDS_SUCCESS:
        draft.fields = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case MAILERLITE_GET_FIELDS_FAILURE:
        draft.fields = {
          items: [],
          status: ReduxStatus.errored,
        };
        return;
      case MAILERLITE_GET_LIST:
        draft.list = {
          items: [],
          status: ReduxStatus.loading,
        };
        return;
      case MAILERLITE_GET_LIST_SUCCESS:
        draft.list = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case MAILERLITE_GET_LIST_FAILURE:
        draft.list = {
          items: [],
          status: ReduxStatus.errored,
        };
        return;

      case MAILERLITE_GET_CONNECTIONS:
        draft.connection = { items: [], status: ReduxStatus.loading };
        return;
      case MAILERLITE_GET_CONNECTIONS_SUCCESS:
        draft.connection = {
          items: action.payload,
          status: ReduxStatus.success,
        };
        return;
      case MAILERLITE_GET_CONNECTIONS_FAILURE:
        draft.connection = { items: [], status: ReduxStatus.errored };
        return;

      case MAILERLITE_SAVE:
        draft.status = ReduxStatus.loading;
        return;
      case MAILERLITE_SAVE_SUCCESS:
        draft.list = { items: [], status: ReduxStatus.initialized };
        draft.fields = { items: [], status: ReduxStatus.initialized };
        draft.status = ReduxStatus.success;
        return;
      case MAILERLITE_SAVE_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case MAILERLITE_DELETE:
        draft.status = ReduxStatus.loading;
        return;
      case MAILERLITE_DELETE_SUCCESS:
        draft.status = ReduxStatus.success;
        return;
      case MAILERLITE_DELETE_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case MAILERLITE_CHANGESTATUS:
        draft.status = ReduxStatus.loading;
        return;
      case MAILERLITE_CHANGESTATUS_SUCCESS:
        draft.status = ReduxStatus.success;
        return;
      case MAILERLITE_CHANGESTATUS_FAILURE:
        draft.status = ReduxStatus.errored;
        return;

      case MAILERLITE_CLEAR_STATE:
        return initialState;

      default:
        return;
    }
  },
  initialState,
);

export default reducer;
