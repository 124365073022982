import { Targets } from '../campaign';
import { SaveTargetsAction, SaveTargetsPayload, TargetsKey } from '../types';
import {
  DELETE_TARGET_ERROR,
  DELETE_TARGET_TARGETS_COPY,
  RESET_TARGETS_COPY,
  SAVE_TARGETS,
  SET_TARGETS_COPY,
  SET_TARGET_ERROR,
  TARGETS_COPY_TO_TARGETS,
  TARGETS_TO_TARGETS_COPY,
  UPDATE_TARGETS_COPY,
} from './constants';

export function targetsToTargetCopy() {
  return {
    type: TARGETS_TO_TARGETS_COPY,
  };
}

export function setTargetsCopy(targets: Targets) {
  return {
    type: SET_TARGETS_COPY,
    payload: {
      targets,
    },
  };
}

export function targetsCopyToTargets() {
  return {
    type: TARGETS_COPY_TO_TARGETS,
  };
}

export function resetTargetsCopy() {
  return {
    type: RESET_TARGETS_COPY,
  };
}

export function updateTargetsCopy(data: any) {
  return {
    type: UPDATE_TARGETS_COPY,
    payload: { data },
  };
}
export function deleteTargetTargetsCopy(target: TargetsKey) {
  return {
    type: DELETE_TARGET_TARGETS_COPY,
    payload: { target },
  };
}
export function setTargetError(error: { [key: string]: boolean }) {
  return {
    type: SET_TARGET_ERROR,
    payload: { error },
  };
}

export function deleteTargetError(targetName: string) {
  return {
    type: DELETE_TARGET_ERROR,
    payload: { targetName },
  };
}

export function saveTargets(payload: SaveTargetsPayload): SaveTargetsAction {
  return {
    type: SAVE_TARGETS,
    payload,
  };
}
