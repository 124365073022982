import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  WEBHOOK_GET_CONNECTIONS,
  WEBHOOK_GET_CONNECTIONS_SUCCESS,
  WEBHOOK_GET_CONNECTIONS_FAILURE,
  WEBHOOK_SAVE,
  WEBHOOK_SAVE_SUCCESS,
  WEBHOOK_SAVE_FAILURE,
  WEBHOOK_DELETE,
  WEBHOOK_DELETE_FAILURE,
  WEBHOOK_DELETE_SUCCESS,
  WEBHOOK_CLEAR_STATE,
} from './constants';
import { WebhookState } from './type';
import produce, { Draft } from 'immer';

const initialState: WebhookState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  testResult: {
    result: {
      isSent: false,
      sentData: [],
      message: '',
      messageTitle: '',
      errorLogs: '',
    },
    status: ReduxStatus.initialized,
  },
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<WebhookState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case WEBHOOK_GET_CONNECTIONS:
      draft.connection = { items: [], status: ReduxStatus.loading };
      return;
    case WEBHOOK_GET_CONNECTIONS_SUCCESS:
      draft.connection = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case WEBHOOK_GET_CONNECTIONS_FAILURE:
      draft.connection = { items: [], status: ReduxStatus.errored };
      return;
    case WEBHOOK_SAVE:
      draft.status = ReduxStatus.loading;
      return;
    case WEBHOOK_SAVE_SUCCESS:
      draft.list = { items: [], status: ReduxStatus.initialized };
      draft.fields = { items: [], status: ReduxStatus.initialized };
      draft.status = ReduxStatus.success;
      return;
    case WEBHOOK_SAVE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case WEBHOOK_DELETE:
      draft.status = ReduxStatus.loading;
      return;
    case WEBHOOK_DELETE_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case WEBHOOK_DELETE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case WEBHOOK_CLEAR_STATE:
      return initialState;
    default:
      return;
  }
}, initialState);

export default reducer;
