import { Modify } from 'global';
import React, {
  useState,
  useEffect,
  ButtonHTMLAttributes,
  MouseEvent as ReactMouseEvent,
} from 'react';

import cn from 'classnames';

import Loader from '@elements/Loader';

interface ModifiedButtonProps {
  onClick?: (event: ReactMouseEvent<HTMLButtonElement>) => Promise<void> | void;
}

interface ButtonProps
  extends Modify<ButtonHTMLAttributes<HTMLButtonElement>, ModifiedButtonProps> {
  icon?: JSX.Element;
  useIndicator?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    icon,
    useIndicator,
    type = 'button',
    onClick,
    children,
    className,
    ...rest
  } = props;

  const [disabled, setDisable] = useState(props.disabled || false);

  let timeout: NodeJS.Timeout;
  const onClickHandler = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setDisable(true);

    const promise = new Promise((resolve) => {
      timeout = setTimeout(async () => {
        if (onClick) {
          await onClick(event);
        }

        resolve(true);
      }, 0);
    });

    promise.then(() => {
      event.stopPropagation();
      setDisable(false);
    });
  };

  useEffect(
    () => () => {
      clearTimeout(timeout);
    },
    [],
  );

  return (
    <button
      {...rest}
      type={type}
      className={cn('btn', className)}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {useIndicator ? null : icon}
      {useIndicator ? <Loader /> : children}
    </button>
  );
};

export default Button;
export type { ButtonProps };
