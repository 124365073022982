import React, {
  CSSProperties,
  FC,
  InputHTMLAttributes,
  ReactNode,
  useMemo,
} from 'react';
import cn from 'classnames';
import HelpInfoIcon from '@assets/icons/help-info-icon.svg';
import { Tooltip } from 'ui';

import { randomId } from 'core/helpers/string';

import styles from './Switch.module.scss';
import { TooltipProps } from 'ui/src/Tooltip/types';

export interface SwitchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  labelParanthesesTextWeight?: React.CSSProperties['fontWeight'];
  reverse?: boolean;
  className?: string;
  tooltip?: string;
  tooltipStyle?: Partial<CSSProperties>;
  elementSize?: 'xs' | 'sm' | 'md';
  color?: string;
  contentButton?: TooltipProps['contentButton'];
  keepTooltip?: boolean;
  tooltipIcon?: ReactNode;
}

const Switch: FC<SwitchInputProps> = (props) => {
  const {
    label,
    labelParanthesesTextWeight,
    className,
    reverse = false,
    id = `switch_${randomId()}`,
    elementSize = 'md',
    onChange,
    disabled,
    tooltip,
    tooltipStyle = {},
    color = '#777777',
    contentButton,
    keepTooltip = false,
    tooltipIcon,
    labelClassName,
    ...rest
  } = props;

  const MemoizedLabel = useMemo(
    () => <Label label={label} fontWeight={labelParanthesesTextWeight} />,
    [label, labelParanthesesTextWeight],
  );

  return (
    <div
      className={cn(
        styles.wrapper,
        reverse && styles.reverse,
        disabled && styles.disabled,
        elementSize === 'sm' && styles.small,
        elementSize === 'xs' && styles.extraSmall,
        className && className,
      )}
    >
      {label && (
        <p className={cn(styles.label, labelClassName)}>
          {MemoizedLabel}
          {tooltip && !tooltipIcon && (
            <Tooltip
              style={{
                zIndex: '99999',
                position: 'absolute',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                height: 'max-content',
                width: '240px',
                whiteSpace: 'normal',
                top: 'calc(100% + 8px)',
                transform: 'translateX(-50%)',
                ...tooltipStyle,
              }}
              content={tooltip}
              keepTooltip={false}
              contentStyle={{ position: 'initial' }}
            >
              <HelpInfoIcon
                className="ml-1 translate-y-1 text-gray-700"
                width="18"
                height="18"
                viewBox="0 0 20 20"
              />
            </Tooltip>
          )}
          {tooltipIcon && contentButton && (
            <Tooltip
              contentButton={contentButton}
              keepTooltip={keepTooltip}
              content={tooltip}
            >
              {tooltipIcon}
            </Tooltip>
          )}
        </p>
      )}
      <div
        style={
          {
            '--color': color,
          } as CSSProperties
        }
        className={styles.switch_wrapper}
      >
        <input
          type="checkbox"
          id={id}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={id} />
      </div>
    </div>
  );
};

export default Switch;

const Label: FC<{
  label?: string;
  fontWeight?: React.CSSProperties['fontWeight'];
}> = ({ label, fontWeight }) => {
  let paranthesesText = '';
  let labelText = label ?? '';
  if (fontWeight && label) {
    paranthesesText = new RegExp(/\(([^)]+)\)/g).exec(label)?.[0] ?? '';
    labelText = labelText.replace(paranthesesText, '');
  }
  return (
    <span className={styles.labelContent}>
      {labelText}
      {fontWeight && (
        <span style={{ fontWeight: fontWeight }}>{paranthesesText}</span>
      )}
    </span>
  );
};
