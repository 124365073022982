import { RESET_STORE } from '@connectors/actions';
import ReduxStatus from '@enums/ReduxStatus';
import { ReduxAction } from 'global';
import produce, { Draft } from 'immer';

import {
  GET_CAMPAIGNGOALS_FAILURE,
  GET_CAMPAIGNGOALS_REQUEST,
  GET_CAMPAIGNGOALS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TEMPLATES_FAILURE,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  RESET_SELECTED,
  RESET_TEMPLATES_SELECTED_PAGE_AND_DEVICE_TYPE,
  SET_SELECTED_TEMPLATE_THEME,
  SET_SELECTED_TEMPLATES,
  UPDATE_TEMPLATES_SELECTED_DEVICE_TYPE,
  UPDATE_TEMPLATES_SELECTED_PAGE_ID,
} from './constants';
import type { TemplateState } from './types';
import DeviceTypes from '@enums/DeviceTypes';

const initialState: TemplateState = {
  selected: {
    template: null,
    status: ReduxStatus.initialized,
  },
  items: [],
  campaignGoals: { items: [], status: ReduxStatus.initialized },
  tags: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
  total: 0,
};

const reducer = produce((draft: Draft<TemplateState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case GET_TEMPLATES_REQUEST: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case GET_TEMPLATES_SUCCESS: {
      draft.status = ReduxStatus.success;
      draft.items = action.payload?.data || [];
      draft.total = action.payload?.total || 0;
      return;
    }
    case GET_TEMPLATES_FAILURE: {
      draft.status = ReduxStatus.errored;
      return;
    }

    case UPDATE_TEMPLATES_SELECTED_DEVICE_TYPE: {
      const { payload } = action;
      const { deviceType } = payload;
      if (
        draft.selected.template &&
        Object.values(DeviceTypes).includes(deviceType)
      )
        draft.selected.template.currentDeviceType = deviceType;
      return;
    }
    case UPDATE_TEMPLATES_SELECTED_PAGE_ID: {
      const { payload } = action;
      const { pageId } = payload;
      if (!draft.selected.template) return;

      draft.selected.template.currentPageId = pageId;
      return;
    }
    case SET_SELECTED_TEMPLATE_THEME: {
      const { payload } = action;
      const { code, theme } = payload;
      if (!draft.selected.template || !draft.selected.template.layout) return;
      draft.selected.template.layout.style = {
        code,
        data: theme,
      };
      return;
    }
    case SET_SELECTED_TEMPLATES: {
      const { payload } = action;
      const { template } = payload;
      draft.selected.template = template;
      return;
    }
    case RESET_TEMPLATES_SELECTED_PAGE_AND_DEVICE_TYPE: {
      if (!draft.selected.template) return;

      const pageId =
        draft.selected.template.layout?.contents &&
        draft.selected.template.layout?.contents?.length >= 0
          ? draft.selected.template.layout?.contents[0].id
          : undefined;

      draft.selected.template.currentDeviceType =
        draft.selected.template?.deviceType === 2
          ? DeviceTypes.mobile
          : DeviceTypes.desktop;
      draft.selected.template.currentPageId = pageId;
      return;
    }
    case GET_CAMPAIGNGOALS_REQUEST: {
      draft.campaignGoals = { items: [], status: ReduxStatus.loading };
      return;
    }
    case GET_CAMPAIGNGOALS_SUCCESS: {
      draft.campaignGoals = {
        items: action.payload.data,
        status: ReduxStatus.success,
      };
      return;
    }
    case GET_CAMPAIGNGOALS_FAILURE: {
      draft.campaignGoals = { items: [], status: ReduxStatus.errored };
      return;
    }
    case GET_TAGS_REQUEST: {
      draft.tags = { items: [], status: ReduxStatus.loading };
      return;
    }
    case GET_TAGS_SUCCESS: {
      draft.tags = { items: action.payload.data, status: ReduxStatus.success };
      return;
    }
    case GET_TAGS_FAILURE: {
      draft.tags = { items: [], status: ReduxStatus.errored };
      return;
    }

    case RESET_STORE: {
      return initialState;
    }
    case RESET_SELECTED: {
      // @ts-ignore TODO
      draft.selected = initialState.selected;
      return;
    }
    default: {
      return;
    }
  }
}, initialState);

export default reducer;
