export const GET_WEBSITES_REQUEST = 'GET_WEBSITES_REQUEST';
export const GET_WEBSITES_SUCCESS = 'GET_WEBSITES_SUCCESS';
export const GET_WEBSITES_FAILURE = 'GET_WEBSITE_FAILURE';

export const DELETE_WEBSITE_REQUEST = 'DELETE_WEBSITE_REQUEST';
export const DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS';
export const DELETE_WEBSITE_FAILURE = 'DELETE_WEBSITE_FAILURE';

export const ADD_WEBSITE_REQUEST = 'ADD_WEBSITE_REQUEST';
export const ADD_WEBSITE_SUCCESS = 'ADD_WEBSITE_SUCCESS';
export const ADD_WEBSITE_FAILURE = 'ADD_WEBSITE_FAILURE';

export const UPDATE_WEBSITE_REQUEST = 'UPDATE_WEBSITE_REQUEST';
export const UPDATE_WEBSITE_SUCCESS = 'UPDATE_WEBSITE_SUCCESS';
export const UPDATE_WEBSITE_FAILURE = 'UPDATE_WEBSITE_FAILURE';

export const GET_CMS_REQUEST = 'GET_CMS_REQUEST';
export const GET_CMS_SUCCESS = 'GET_CMS_SUCCESS';
export const UPDATE_CMS = 'UPDATE_CMS';
export const GET_CMS_FAILURE = 'GET_CMS_FAILURE';
export const UPDATE_WEBSITE_VERIFY_STATUS =
  'websites/UPDATE_WEBSITE_VERIFY_STATUS';
