export const LOCAL_UPDATE_CAMPAING = 'builder/settings/LOCAL_UPDATE_CAMPAING';
export const DELETE_INTEGRATION = 'builder/settings/DELETE_INTEGRATION';
export const CHANGE_STATUS_INTEGRATION =
  'builder/settings/CHANGE_STATUS_INTEGRATION';
export const DISABLE_ALL_INTEGRATIONS =
  'builder/settings/DISABLE_ALL_INTEGRATIONS';
export const SET_INTEGRATIONS = 'builder/settings/SET_INTEGRATIONS';
export const ADD_INTEGRATION = 'builder/settings/ADD_INTEGRATION';
export const UPDATE_INTEGRATION = 'builder/settings/UPDATE_INTEGRATION';
export const DOMAIN_DELETE = 'builder/settings/DOMAIN_DELETE';
export const DOMAIN_DELETE_SUCCESS = 'builder/settings/DOMAIN_DELETE_SUCCESS';
export const DOMAIN_SELECT = 'builder/settings/DOMAIN_SELECT';
export const DOMAIN_SELECT_SUCCESS = 'builder/settings/DOMAIN_SELECT_SUCCESS';
export const SAVE_SETTINGS = 'builder/settings/SAVE_SETTINGS';
export const UPDATE_AUTO_RESPONDER_REQUEST =
  'builder/settings/UPDATE_AUTO_RESPONDER_REQUEST';
export const UPDATE_AUTO_RESPONDER_SUCCESS =
  'builder/settings/UPDATE_AUTO_RESPONDER_SUCCESS';
export const UPDATE_AUTO_RESPONDER_FAILURE =
  'builder/settings/UPDATE_AUTO_RESPONDER_FAILURE';
