import React from 'react';
import cn from 'classnames';

import styles from './Timeline.module.scss';

export interface TimelineProps {
  children: React.ReactNode;
  className?: string;
}

const Timeline = (props: TimelineProps) => {
  const { className, children } = props;

  return (
    <div className={cn(styles.timeline, 'scrollbar', className && className)}>
      {children}
    </div>
  );
};

export default Timeline;
