import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import store from '@utils/store';

export const httpLink = createHttpLink({
  uri: '/api/graphql',
});

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const auth = store.get('user');
  const token = auth?.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
