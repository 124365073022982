export const RESET = 'segment/RESET';

export const GET_ALL_SEGMENTS = 'segment/GET_ALL_SEGMENTS';
export const GET_ALL_SEGMENTS_SUCCESS = 'segment/GET_ALL_SEGMENTS_SUCCESS';
export const GET_ALL_SEGMENTS_ERROR = 'segment/GET_ALL_SEGMENTS_ERROR';

export const GET_ACTIVE_SEGMENT = 'segment/GET_ACTIVE_SEGMENT';
export const GET_ACTIVE_SEGMENT_SUCCESS = 'segment/GET_ACTIVE_SEGMENT_SUCCESS';
export const GET_ACTIVE_SEGMENT_ERROR = 'segment/GET_ACTIVE_SEGMENT_ERROR';

export const SET_SEGMENT_TARGETS_COPY = 'segment/SET_SEGMENT_TARGETS_COPY';

export const SEGMENT_TARGETS_COPY_TO_SEGMENT_TARGETS =
  'segment/SEGMENT_TARGETS_COPY_TO_SEGMENT_TARGETS';

export const SEGMENT_TARGETS_TO_SEGMENT_TARGETS_COPY =
  'segment/SEGMENT_TARGETS_TO_SEGMENT_TARGETS_COPY';

export const RESET_SEGMENT_TARGETS_COPY = 'segment/RESET_SEGMENT_TARGETS_COPY';
export const RESET_ACTIVE_SEGMENT = 'segment/RESET_ACTIVE_SEGMENT';

export const UPDATE_SEGMENT_TARGETS_COPY =
  'segment/UPDATE_SEGMENT_TARGETS_COPY';

export const DELETE_TARGET_SEGMENT_TARGETS_COPY =
  'segment/DELETE_TARGET_SEGMENT_TARGETS_COPY';

export const SET_SEGMENT_TARGET_ERROR = 'segment/SET_SEGMENT_TARGET_ERROR';
export const DELETE_SEGMENT_TARGET_ERROR =
  'segment/DELETE_SEGMENT_TARGET_ERROR';

export const DELETE_SEGMENT_SUCCESS = 'segment/DELETE_SEGMENT_SUCCESS';
export const DELETE_SEGMENT_ERROR = 'segment/DELETE_SEGMENT_ERROR';

export const UPDATE_SEGMENT_NAME = 'segment/UPDATE_SEGMENT_NAME';
export const UPDATE_SEGMENT_NAME_SUCCESS =
  'segment/UPDATE_SEGMENT_NAME_SUCCESS';
export const UPDATE_SEGMENT_NAME_ERROR = 'segment/UPDATE_SEGMENT_NAME_ERROR';

export const UPDATE_SEGMENT = 'segment/UPDATE_SEGMENT';
export const UPDATE_SEGMENT_SUCCESS = 'segment/UPDATE_SEGMENT_SUCCESS';
export const UPDATE_SEGMENT_ERROR = 'segment/UPDATE_SEGMENT_ERROR';

export const CREATE_SEGMENT = 'segment/CREATE_SEGMENT';
export const CREATE_SEGMENT_SUCCESS = 'segment/CREATE_SEGMENT_SUCCESS';
export const CREATE_SEGMENT_ERROR = 'segment/CREATE_SEGMENT_ERROR';

export const SELECT_ACTIVE_SEGMENT = 'segment/SELECT_ACTIVE_SEGMENT';
export const SELECT_ACTIVE_SEGMENT_ERROR =
  'segment/SELECT_ACTIVE_SEGMENT_ERROR';

export const UPDATE_CHANGE_VIEW_VISIBILITY =
  'segment/UPDATE_CHANGE_VIEW_VISIBILITY';
export const UPDATE_CHANGE_VIEW_VISIBILITY_SUCCESS =
  'segment/UPDATE_CHANGE_VIEW_VISIBILITY_SUCCESS';

export const SET_SELECTED_SEGMENT = 'segment/SET_SELECTED_SEGMENT';
export const SET_SELECTED_SEGMENT_SUCCESS =
  'segment/SET_SELECTED_SEGMENT_SUCCESS';
export const SET_SELECTED_SEGMENT_ERROR = 'segment/SET_SELECTED_SEGMENT_ERROR';

export const SET_SELECTED_SEGMENT_AS_SMART_MODE =
  'segment/SET_SELECTED_SEGMENT_AS_SMART_MODE';
export const RESET_SELECTED_SEGMENT = 'segment/RESET_SELECTED_SEGMENT';
