import React, { useRef, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import styles from './ChangeLayoutModal.module.scss';
import changeLayoutAnimation from '@assets/lotties/change-layout.json';
import Button from '@elements/Button';
import { hideModal } from '@connectors/modal/actions';
import { PsLottie, PsLottieRef } from 'ui';
import { ChangeLayoutModalProps } from './types';

const ChangeLayoutModal: FC<ChangeLayoutModalProps> = ({
  hasEmailAutomation = false,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const heroRef = useRef() as PsLottieRef;

  useEffect(() => {
    heroRef?.current?.play();

    return () => {
      heroRef?.current?.destroy();
    };
  }, []);

  const onClickCancelButton = () => {
    dispatch(hideModal('ChangeLayoutModal'));
    onCancel && onCancel();
  };

  const _onSubmit = () => {
    dispatch(hideModal('ChangeLayoutModal'));
    onSubmit && onSubmit();
  };

  return (
    <div className={styles.container}>
      <PsLottie
        height={150}
        width={150}
        style={{
          width: 150,
          height: 150,
        }}
        loop={true}
        autoplay={true}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMin slice',
        }}
        animationData={changeLayoutAnimation}
        lottieRef={heroRef}
      />
      <h1 className={styles.h1}>Your content will be lost!</h1>
      <div className="mt-3 max-w-[18rem] text-center text-[14px]">
        Your all customization and content{' '}
        <span className="underline font-semibold">will be lost</span> when you
        change the template.
      </div>
      {hasEmailAutomation && (
        <div className="mt-6 max-w-[16rem] text-center  text-[14px]">
          Also <span className="underline font-semibold">email automation</span>{' '}
          will disconnect from the popup campaign.
        </div>
      )}
      <div className="mt-6 max-w-[16rem] text-center  text-[14px]">
        Are you sure you want to change?
      </div>
      <div className="mt-8  flex justify-between">
        <Button
          className="btn btn-secondary !px-[15px] !py-[10px]"
          onClick={onClickCancelButton}
        >
          No
        </Button>
        <Button
          className="btn btn-primary ml-4 !px-[15px] !py-[10px]"
          onClick={() => _onSubmit()}
        >
          Yes, change
        </Button>
      </div>
    </div>
  );
};
export type { ChangeLayoutModalProps };
export default ChangeLayoutModal;
