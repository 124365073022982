import type { RootState } from '@connectors/reducers';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Timeline from '@components/Timeline';
import {
  renderAfterScrollingXAmount,
  renderAfterXSeconds,
  renderBrowserLanguage,
  renderCookieTargeting,
  renderGeoLocated,
  renderHtmlTargeting,
  renderInActivityMode,
  renderNewOrReturningVisitors,
  renderOnExitIntent,
  renderOperatingSystem,
  renderSchedule,
  renderTrafficSource,
  renderUrlBrowsing,
  renderVisitorDevices,
  renderShowAgainFrequency,
  renderStopDisplayFrequency,
} from '@components/Timeline/Generator';
import { renderSiteDataTargeting } from '@components/Timeline/Generator/SiteDataTargeting';

import { GeoLocatedInputValues } from '../Audience/Target/GeoLocated/GeoLocated';
import { Targets } from '@connectors/builder/campaign';
import { hasOwnProperty } from '@utils/object';

import SmartIcon from '@assets/icons/smart.svg';
import BoltIcon from '@assets/icons/bolt_black.svg';

import styles from './TargetingSummary.module.scss';
import { Icons } from '@components/SegmentsChangeView/SegmentCard/SegmentCard';
import { renderOnClickTargeting } from '@components/Timeline/Generator/OnClickTargeting';
import { renderShopifyTargeting } from '@components/Timeline/Generator/ShopifyTargeting';
import { useTranslation } from 'react-i18next';

interface TargetingSummaryProps {
  targets: Targets | undefined;
  nameVisible?: boolean;
  titleVisible?: boolean;
  nameClassName?: string;
}

const TargetingSummary: FC<TargetingSummaryProps> = ({
  targets,
  nameVisible = true,
  titleVisible = true,
  nameClassName = '',
}) => {
  const { t } = useTranslation();
  const locations = useSelector((state: RootState) => state.locations);

  const hasBehavior =
    hasOwnProperty(targets, 'afterScrollingXAmount') ||
    hasOwnProperty(targets, 'inActivityMode') ||
    hasOwnProperty(targets, 'afterXSeconds') ||
    hasOwnProperty(targets, 'onExitIntent') ||
    hasOwnProperty(targets, 'onclickTargeting');

  const hasAudience =
    hasOwnProperty(targets, 'visitorDevice') ||
    hasOwnProperty(targets, 'urlBrowsing') ||
    hasOwnProperty(targets, 'operatingSystem') ||
    hasOwnProperty(targets, 'newOrReturningVisitors') ||
    hasOwnProperty(targets, 'schedule') ||
    hasOwnProperty(targets, 'browserLanguage') ||
    hasOwnProperty(targets, 'urlBrowsing') ||
    hasOwnProperty(targets, 'trafficSource') ||
    hasOwnProperty(targets, 'cookieTargeting') ||
    hasOwnProperty(targets, 'htmlTargeting') ||
    hasOwnProperty(targets, 'geoLocated') ||
    hasOwnProperty(targets, 'siteDataTargeting');

  return (
    <>
      {targets && !targets?.targetSmartMode ? (
        <>
          {titleVisible ? (
            <h5 className="font-inter text-sm leading-5 font-semibold mt-5 mb-2">
              {t('sections:targeting-summary:title')}
            </h5>
          ) : (
            <div className="mt-5 " />
          )}
          {nameVisible ? (
            <div className="flex items-center">
              <div
                className={styles.icon}
                style={{ backgroundColor: targets?.segmentColor }}
              >
                {targets?.segmentIcon ? (
                  React.cloneElement(Icons[targets.segmentIcon], {
                    width: '10px',
                    height: '10px',
                  })
                ) : (
                  <BoltIcon width="10" height="10" viewBox="0 0 18 18" />
                )}
              </div>
              {targets.segmentName && (
                <h4 className={cn(styles.title, nameClassName)}>
                  {targets.segmentName}
                </h4>
              )}
            </div>
          ) : null}

          {hasAudience && (
            <>
              <h6 className="font-inter text-[10px] leading-[16px] font-medium text-[#777777] mb-[10px] mt-[15px]">
                {t('common:audience').toUpperCase()}
              </h6>
              {renderVisitorDevices(targets.visitorDevice, t)}
              {renderNewOrReturningVisitors(targets.newOrReturningVisitors, t)}
              {renderOperatingSystem(targets.operatingSystem, t)}
              {renderSchedule(targets.schedule, t)}
              {renderBrowserLanguage(targets.browserLanguage, t)}
              {renderUrlBrowsing(targets.urlBrowsing, t)}
              {renderTrafficSource(targets.trafficSource, t)}
              {renderCookieTargeting(targets.cookieTargeting, t)}
              {renderHtmlTargeting(targets.htmlTargeting, t)}
              {renderGeoLocated({
                ...(targets?.geoLocated as GeoLocatedInputValues),
                locationState: locations,
                translate: t,
              })}
              {renderSiteDataTargeting(targets.siteDataTargeting, t)}
              {renderShopifyTargeting(targets.shopifyTargeting, t)}
            </>
          )}

          {hasBehavior && (
            <>
              <h6 className="font-inter text-[10px] leading-[16px] font-medium text-[#777777] mb-[10px] mt-[15px]">
                {t('common:userBehavior').toUpperCase()}
              </h6>
              {renderAfterScrollingXAmount(targets.afterScrollingXAmount, t)}
              {renderInActivityMode(targets.inActivityMode, t)}
              {renderAfterXSeconds(targets.afterXSeconds, t)}
              {renderOnExitIntent(targets.onExitIntent, t)}
              {renderOnClickTargeting(targets.onclickTargeting, t)}
            </>
          )}
          <Timeline>
            <h6 className="font-inter text-[10px] leading-[16px] font-medium text-[#777777] mb-[10px] mt-[15px]">
              {t('common:frequency')}
            </h6>
            {renderShowAgainFrequency(targets.displayFrequency, t)}
            {targets?.displayFrequency?.stopShowing?.type === 'NEVER' &&
              renderStopDisplayFrequency(targets.displayFrequency, t)}

            {targets?.displayFrequency?.stopShowing?.type !== 'NEVER' && (
              <>{renderStopDisplayFrequency(targets.displayFrequency, t)}</>
            )}
          </Timeline>
        </>
      ) : (
        <>
          <div className="flex items-center mt-5">
            <div className={cn(styles.smartModeIcon)}>
              <SmartIcon width="14" height="14" viewBox="0 0 18 18" />
            </div>
            <h4 className={styles.titleSmartMode}>
              {t('sections:smart-mode-card:title')}
            </h4>
          </div>
          <div>
            <h6 className="font-inter text-[10px] leading-[16px] font-medium text-[#777777] ml-[30px] mb-[10px] mt-[2px]">
              {t('sections:targeting-summary-smart-mode:message')}
            </h6>
          </div>
        </>
      )}
    </>
  );
};

export default TargetingSummary;
export type { TargetingSummaryProps };
