import React, { FC } from 'react';

type IconProps = {
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
};

const AnimatedIcon: FC<IconProps> = ({ width, height, viewBox, className }) => {
  return (
    <svg
      className={className}
      width={width || '24'}
      height={height || '24'}
      viewBox={viewBox || '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11751_6716)">
        <path
          d="M6.04588 2.68672C7.31921 1.41339 9.01921 0.813388 10.6859 0.886721C9.77255 -0.0266122 8.37921 -0.259946 7.22588 0.313388C6.64588 0.600055 6.09921 0.986721 5.61921 1.47339C3.72588 3.36672 3.31921 6.19339 4.40588 8.48672C3.92588 6.46672 4.47255 4.26006 6.04588 2.68672Z"
          fill="currentColor"
        />
        <path
          d="M10.3741 2.85995C12.1808 2.85995 13.8008 3.63328 14.9274 4.86662C14.9274 3.57995 14.1074 2.42662 12.8874 2.01995C12.2741 1.81328 11.6141 1.69995 10.9274 1.69995C8.24742 1.69995 5.96076 3.40662 5.10742 5.79995C6.19409 4.03328 8.14742 2.85995 10.3674 2.85995H10.3741Z"
          fill="currentColor"
        />
        <path
          d="M13.3125 6.04661C14.5859 7.31994 15.1859 9.01995 15.1125 10.6866C16.0259 9.77328 16.2592 8.37994 15.6792 7.22661C15.3925 6.64661 15.0059 6.09995 14.5192 5.61995C12.6259 3.72661 9.79919 3.31995 7.50586 4.40661C9.52586 3.92661 11.7325 4.47328 13.3059 6.04661H13.3125Z"
          fill="currentColor"
        />
        <path
          d="M13.1392 10.3734C13.1392 12.18 12.3659 13.8 11.1326 14.9267C12.4192 14.9267 13.5726 14.1067 13.9792 12.8867C14.1859 12.2734 14.2992 11.6134 14.2992 10.9267C14.2992 8.24669 12.5926 5.96002 10.1992 5.10669C11.9659 6.19336 13.1392 8.14669 13.1392 10.3667V10.3734Z"
          fill="currentColor"
        />
        <path
          d="M9.9525 13.3134C8.67917 14.5867 6.97917 15.1867 5.3125 15.1134C6.22583 16.0267 7.61917 16.26 8.7725 15.68C9.3525 15.3934 9.89917 15.0067 10.3792 14.52C12.2725 12.6267 12.6792 9.80005 11.5925 7.50671C12.0725 9.52671 11.5258 11.7334 9.9525 13.3067V13.3134Z"
          fill="currentColor"
        />
        <path
          d="M5.62755 13.14C3.82089 13.14 2.20089 12.3666 1.07422 11.1333C1.07422 12.42 1.89422 13.5733 3.11422 13.9799C3.72755 14.1866 4.38755 14.3 5.07422 14.3C7.75422 14.3 10.0409 12.5933 10.8942 10.2C9.80755 11.9666 7.85422 13.14 5.63422 13.14H5.62755Z"
          fill="currentColor"
        />
        <path
          d="M2.68684 9.95335C1.41351 8.68002 0.81351 6.98002 0.886843 5.31335C-0.0264901 6.22669 -0.259824 7.62002 0.31351 8.77335C0.600177 9.35335 0.986843 9.90002 1.47351 10.38C3.36684 12.2734 6.19351 12.68 8.48684 11.5934C6.46684 12.0734 4.26018 11.5267 2.68684 9.95335Z"
          fill="currentColor"
        />
        <path
          d="M2.85922 5.6267C2.85922 3.82003 3.63255 2.20003 4.86589 1.07336C3.57922 1.07336 2.42589 1.89336 2.01922 3.11336C1.81255 3.7267 1.69922 4.3867 1.69922 5.07336C1.69922 7.75336 3.40589 10.04 5.79922 10.8934C4.03255 9.8067 2.85922 7.85336 2.85922 5.63336V5.6267Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11751_6716">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnimatedIcon;
