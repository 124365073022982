export const SET_TARGETING = 'SET_TARGETING';
export const SET_TARGETING_SUCCESS = 'SET_TARGETING_SUCCESS';

export const UPDATE_TARGETING = 'UPDATE_TARGETING';
export const UPDATE_TARGETING_SUCCESS = 'UPDATE_TARGETING_SUCCESS';

export const DELETE_TARGETING = 'DELETE_TARGETING';
export const DELETE_TARGETING_SUCCESS = 'DELETE_TARGETING_SUCCESS';

export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_ERROR_SUCCESS = 'UPDATE_ERROR_SUCCESS';

export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_ERROR_SUCCESS = 'DELETE_ERROR_SUCCESS';

export const TARGETING_FAILURE = 'UPDATE_AUTO_RESPONDER_FAILURE';
