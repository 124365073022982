import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import type { NoVerifiedWebsiteModalProps, cmsType } from './types';
import styles from './NoVerifiedWebsiteModal.module.scss';

import Tabs, { Tab } from '@elements/Tabs';
import { TabIconLabel } from '@elements/Tabs/TabIconLabel';
import DefaultWebsiteOption from './Options/DefaultWebsiteOption';
import { Website, makeSelectWebsites } from '@connectors/websites/reducer';
import WordpressWebsiteOption from './Options/WordpressWebsiteOption';
import { DynamicTabIconLabel } from '@elements/Tabs/DynamicTabIconLabel';
import GTMWebsiteOption from './Options/GTMWebsiteOption';
import ShopifyWebsiteOption from './Options/ShopifyWebsiteOption';
import VerifyModalBottom from './VerifyModalBottom/VerifyModalBottom';
import WordpressIcon from '@assets/icons/wordpress-icon.svg';
import ShopifyIcon from '@assets/icons/shopify-line.svg';
import GTMBlackIcon from '@assets/icons/google-tag-manager-black-icon.svg';
import GTMGrayIcon from '@assets/icons/google-tag-manager-grey-icon.svg';
import WorldIcon from '@assets/icons/world-2.svg';
import { useTranslation } from 'react-i18next';

const NoVerifiedWebsiteModal: FC<NoVerifiedWebsiteModalProps> = ({
  url,
  routerReload = true,
  onVerified,
}) => {
  const { t } = useTranslation();
  const websites = useSelector(makeSelectWebsites);
  const selectedWebsite = websites.find(
    (website: Website) => website.url === url,
  );
  const bottomData = {
    custom: {
      areaIconOne: <WorldIcon width="18" height="18" viewBox="0 0 18 18" />,
      areaTextOne: t('sections:unverified-modal:footerHelpButtonCustomWebsite'),
      areaLinkOne:
        'https://popupsmart.com/help/detail/how-to-embed-the-code-on-your-site',
    },
    wordpress: {
      areaIconOne: <WordpressIcon />,
      areaTextOne: t('sections:unverified-modal:footerHelpButtonWordpress'),
      areaLinkOne: 'https://popupsmart.com/wordpress-popup/',
    },
    shopify: {
      areaIconOne: <ShopifyIcon />,
      areaTextOne: t('sections:unverified-modal:footerHelpButtonShopify'),
      areaLinkOne: 'https://popupsmart.com/shopify-popup/',
    },
    gtm: {
      areaIconOne: <GTMBlackIcon />,
      areaTextOne: t('sections:unverified-modal:footerHelpButtonGTM'),
      areaLinkOne:
        'https://popupsmart.com/help/getting-started/how-to-embed-popup-to-google-tag-manager/',
    },
  };
  const websiteCMS =
    (websites
      ?.find((website) => website?.url === url)
      ?.cms?.toLocaleLowerCase() as cmsType) || 'custom';
  const [activeTab, setActiveTab] = useState<cmsType>(websiteCMS);

  const getDefaultTab = (websiteCMS: string) => {
    switch (websiteCMS) {
      case 'wordpress':
        return 'wordpress';
      case 'shopify':
        return 'shopify';
      case 'godaddy website builder':
        return 'gtm';
      default:
        return 'custom';
    }
  };

  const handleVerifyComplete = () => {
    if (!selectedWebsite) return;
    onVerified && onVerified(true);
  };
  return (
    <div>
      <div className={styles.container}>
        <div className="text-[18px] font-bold leading-xl font-inter mb-0">
          {t('sections:unverified-modal:titlePartOne')}{' '}
          <span className="text-orange">
            {t('sections:unverified-modal:titlePartTwo')}
          </span>
        </div>
        <div className="text-xs leading-5 mt-[5px] mb-[25px]">
          {activeTab === 'shopify'
            ? t('sections:unverified-modal:ShopifyDescription')
            : activeTab === 'wordpress'
            ? t('sections:unverified-modal:WordpressDescription')
            : t('sections:unverified-modal:description')}
        </div>
        <Tabs
          defaultActiveKey={getDefaultTab(websiteCMS)}
          labelSize="sm"
          headerClassName="!mb-[25px]"
          onClickEvent={(id) => setActiveTab(id as cmsType)}
        >
          <Tab id="custom" label={t('common:customWebsite')}>
            <DefaultWebsiteOption
              url={url}
              routerReload={routerReload}
              onVerifyComplete={handleVerifyComplete}
            />
          </Tab>
          <Tab
            id="shopify"
            label={
              <TabIconLabel
                text={t('common:shopify')}
                icon={<ShopifyIcon />}
                tabId="shopify"
              />
            }
          >
            <ShopifyWebsiteOption />
          </Tab>
          <Tab
            id="gtm"
            label={
              <DynamicTabIconLabel
                text={t('common:gtm')}
                icon={<GTMGrayIcon />}
                activeIcon={<GTMBlackIcon />}
                tabId="gtm"
              />
            }
          >
            <GTMWebsiteOption
              url={url}
              routerReload={routerReload}
              onVerifyComplete={handleVerifyComplete}
            />
          </Tab>

          <Tab
            id="wordpress"
            label={
              <TabIconLabel
                text={t('common:wordpress')}
                icon={
                  <WordpressIcon height="16" width="16" viewBox="0 0 18 18" />
                }
                tabId="wordpress"
              />
            }
          >
            <WordpressWebsiteOption
              url={url}
              routerReload={routerReload}
              onVerifyComplete={handleVerifyComplete}
            />
          </Tab>
        </Tabs>
      </div>
      <VerifyModalBottom url={url} {...bottomData[activeTab]} />
    </div>
  );
};

export default NoVerifiedWebsiteModal;
