import React, { FC, useEffect } from 'react';
import cn from 'classnames';

import styles from './Radio.module.scss';
import ErrorIcon from '@assets/icons/error-icon.svg';
import type { PSRadioProps } from './types';

const Radio: FC<PSRadioProps> = ({
  label,
  field: { value, name, onChange },
  fieldState: { error },
  disabled,
  className,
  selectedOption,
  id,
  onChangeEvent,
  styleType = 'default',
}) => {
  useEffect(() => {
    if (value !== '') {
      onChangeEvent && onChangeEvent(value);
    }
  }, []);

  const handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChange(event.target.value);
    if (onChangeEvent) onChangeEvent(event.target.value);
  };

  return (
    <div
      className={cn(styles.radio, className, {
        [styles.disabled]: disabled,
        [styles.targeting_style]: styleType === 'targeting',
      })}
    >
      <input
        className={cn(!!error && styles.error)}
        id={id}
        type="radio"
        disabled={disabled}
        onChange={handleChangeValue}
        name={name}
        value={selectedOption}
        checked={selectedOption === value}
      />
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      {error && (
        <div className={styles.error_div}>
          <ErrorIcon
            width="18"
            height="18"
            viewBox="0 0 32 32"
            color="#EA0F0F"
          />
          <p>{error?.message || 'Invalid'}</p>
        </div>
      )}
    </div>
  );
};

export default Radio;
