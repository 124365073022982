import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  MAILCHIMP_GET_FIELDS,
  MAILCHIMP_GET_FIELDS_SUCCESS,
  MAILCHIMP_GET_FIELDS_FAILURE,
  MAILCHIMP_GET_CONNECTIONS,
  MAILCHIMP_GET_CONNECTIONS_SUCCESS,
  MAILCHIMP_GET_CONNECTIONS_FAILURE,
  MAILCHIMP_GET_LIST,
  MAILCHIMP_GET_LIST_SUCCESS,
  MAILCHIMP_GET_LIST_FAILURE,
  MAILCHIMP_SAVE,
  MAILCHIMP_SAVE_SUCCESS,
  MAILCHIMP_SAVE_FAILURE,
  MAILCHIMP_CHANGESTATUS,
  MAILCHIMP_CHANGESTATUS_FAILURE,
  MAILCHIMP_CHANGESTATUS_SUCCESS,
  MAILCHIMP_DELETE,
  MAILCHIMP_DELETE_FAILURE,
  MAILCHIMP_DELETE_SUCCESS,
  MAILCHIMP_CLEAR_TEST_RESULT,
  MAILCHIMP_CLEAR_STATE,
} from './constants';
import { MailchimpState } from './type';
import produce, { Draft } from 'immer';

const initialState: MailchimpState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<MailchimpState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case MAILCHIMP_GET_FIELDS:
      draft.fields = { items: [], status: ReduxStatus.loading };
      return;
    case MAILCHIMP_GET_FIELDS_SUCCESS:
      draft.fields = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case MAILCHIMP_GET_FIELDS_FAILURE:
      draft.fields = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;
    case MAILCHIMP_GET_LIST:
      draft.list = {
        items: [],
        status: ReduxStatus.loading,
      };
      return;
    case MAILCHIMP_GET_LIST_SUCCESS:
      draft.list = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case MAILCHIMP_GET_LIST_FAILURE:
      draft.list = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;

    case MAILCHIMP_GET_CONNECTIONS:
      draft.connection = { items: [], status: ReduxStatus.loading };
      return;
    case MAILCHIMP_GET_CONNECTIONS_SUCCESS:
      draft.connection = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case MAILCHIMP_GET_CONNECTIONS_FAILURE:
      draft.connection = { items: [], status: ReduxStatus.errored };
      return;

    case MAILCHIMP_SAVE:
      draft.status = ReduxStatus.loading;
      return;
    case MAILCHIMP_SAVE_SUCCESS:
      draft.list = { items: [], status: ReduxStatus.initialized };
      draft.fields = { items: [], status: ReduxStatus.initialized };
      draft.status = ReduxStatus.success;
      return;
    case MAILCHIMP_SAVE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case MAILCHIMP_DELETE:
      draft.status = ReduxStatus.loading;
      return;
    case MAILCHIMP_DELETE_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case MAILCHIMP_DELETE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case MAILCHIMP_CHANGESTATUS:
      draft.status = ReduxStatus.loading;
      return;
    case MAILCHIMP_CHANGESTATUS_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case MAILCHIMP_CHANGESTATUS_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case MAILCHIMP_CLEAR_TEST_RESULT:
      draft.testResult = undefined;
      draft.status = ReduxStatus.loading;
      return;
    case MAILCHIMP_CLEAR_STATE:
      return initialState;
    default:
      return;
  }
}, initialState);

export default reducer;
