export const GET_TEMPLATES_REQUEST = 'templates/GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'templates/GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'templates/GET_TEMPLATES_FAILURE';

export const UPDATE_TEMPLATES_SELECTED_PAGE_ID =
  'templates/UPDATE_TEMPLATES_SELECTED_PAGE_ID';

export const UPDATE_TEMPLATES_SELECTED_DEVICE_TYPE =
  'templates/UPDATE_TEMPLATES_SELECTED_DEVICE_TYPE';

export const RESET_TEMPLATES_SELECTED_PAGE_AND_DEVICE_TYPE =
  'templates/RESET_TEMPLATES_SELECTED_PAGE_AND_DEVICE_TYPE';

export const GET_CAMPAIGNGOALS_REQUEST = 'templates/GET_CAMPAIGNGOALS_REQUEST';
export const GET_CAMPAIGNGOALS_SUCCESS = 'templates/GET_CAMPAIGNGOALS_SUCCESS';
export const GET_CAMPAIGNGOALS_FAILURE = 'templates/GET_CAMPAIGNGOALS_FAILURE';

export const GET_TAGS_REQUEST = 'templates/GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'templates/GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'templates/GET_TAGS_FAILURE';

export const RESET_SELECTED = 'templates/RESET_SELECTED';

export const templateServiceURl = 'api/popups';

export const SET_SELECTED_TEMPLATE_THEME =
  'templates/SET_SELECTED_TEMPLATE_THEME';

export const SET_SELECTED_TEMPLATES = 'templates/SET_SELECTED_TEMPLATES';
