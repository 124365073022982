export const randomColor = (): string => {
  const colorCode = Math.floor(Math.random() * (0xffffff + 1))
    .toString(16)
    .padStart(6, '0');
  return `#${colorCode}`;
};

export const getDonutColor = (index: number): string => {
  const colors = ['#1C86FA', '#4ACEA6', '#E8C73D', '#F4B582', '#DDA7A7'];
  return index > colors.length - 1 ? randomColor() : colors[index];
};

export const hex2rgb = (color: string): string | null => {
  const match = color?.match(/\w\w/g);
  if (!match) return null;

  const rgbCode = match.map((x) => +`0x${x}`);
  return `rgb(${rgbCode.join(', ')})`;
};
export const hex2rgbArray = (color: string): number[] | null => {
  const match = color?.match(/\w\w/g);
  if (!match) return null;

  const rgbCode = match.map((x) => +`0x${x}`);
  return rgbCode;
};

export const rgb2hex = (color: string) => {
  const match = color?.match(/\d+/g);
  if (!match) return null;
  const hex = match.map((x) => (+x).toString(16).padStart(2, ''));
  const hexCode = `#${hex.join('')}`;
  return hexCode;
};

export const getGradientString = (
  value: string,
  defaultValue: string | undefined,
) => {
  if (!value.includes('linear-gradient'))
    return defaultValue || 'linear-gradient(90deg, #fff 0%, #000 100% )';

  return value;
};

export const getSolidColor = (
  color: string | undefined,
  defaultValue: string | undefined,
) => {
  if (!color) return defaultValue || '#000000';
  if (color.includes('linear-gradient')) return defaultValue || '#000000';
  if (color?.includes('url')) return defaultValue || '#000000';
  if (CSS.supports && !CSS.supports('color', color))
    return defaultValue || '#000000';

  return color;
};

export const getBackgroundImage = (
  color: string | undefined,
  defaultValue: string | undefined,
) => {
  if (!color) return defaultValue;

  if (!color?.includes('url')) return defaultValue;

  const matches = color.match(/\((.*?)\)/);
  if (!matches) return defaultValue;

  const image = matches[1]?.replace(/('|")/g, '');
  return image;
};
