import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  KLAVIYO_GET_FIELDS,
  KLAVIYO_GET_CONNECTIONS,
  KLAVIYO_GET_LIST,
  KLAVIYO_SAVE,
  KLAVIYO_DELETE,
} from './constants';
import { ApiFieldObject } from '../reducers';
import {
  clearKlaviyoState,
  deleteKlaviyoFailure,
  getKlaviyoConnectionsFailure,
  getKlaviyoConnectionsSuccess,
  getKlaviyoFieldsFailure,
  getKlaviyoFieldsSuccess,
  getKlaviyoListFailure,
  getKlaviyoListSuccess,
  saveKlaviyoFailure,
  saveKlaviyoSuccess,
} from './action';

import {
  GetKlaviyoIntegrationsByAccountId,
  GetKlaviyoLists,
  GetKlaviyoFields,
  CreateKlaviyo,
  GetKlaviyoIntegrationsByCampaignId,
  UpdateKlaviyo,
  DeleteKlaviyoIntegrationCampaign,
} from './graphql';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import { KlaviyoConnectionsOutput, KlaviyoListsOutput } from './type';
import graphqlFetch from '@utils/graphqlFetch';
import { KlaviyoIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/KlaviyoResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getKlaviyoIntegrationsByAccountId: KlaviyoConnectionsOutput };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetKlaviyoIntegrationsByAccountId(),
    });

    yield put(
      getKlaviyoConnectionsSuccess(
        result.data.getKlaviyoIntegrationsByAccountId,
      ),
    );
  } catch (error) {
    yield put(getKlaviyoConnectionsFailure());
  }
}

function* handleGetFields(action: ReduxAction) {
  const { onSuccess } = action.payload;
  try {
    const result: {
      data: { getKlaviyoFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetKlaviyoFields(),
    });
    onSuccess && onSuccess();
    yield put(getKlaviyoFieldsSuccess(result.data.getKlaviyoFields));
  } catch (error) {
    yield put(getKlaviyoFieldsFailure());
  }
}
function* handleGetList(action: ReduxAction) {
  const { onSuccess } = action.payload;
  try {
    const result: {
      data: { getKlaviyoLists: KlaviyoListsOutput };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetKlaviyoLists(action.payload.apiKey),
    });

    onSuccess && onSuccess();
    yield put(getKlaviyoListSuccess(result.data.getKlaviyoLists));
  } catch (error) {
    yield put(getKlaviyoListFailure());
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId } =
    action.payload;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (listSettings?.id) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      // eslint-disable-next-line @typescript-eslint/ban-types
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateKlaviyo(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateKlaviyo({ ...formData, status: true }),
      });
    }

    const integrations: {
      data: {
        getKlaviyoIntegrationsByCampaignId: KlaviyoIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetKlaviyoIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getKlaviyoIntegrationsByCampaignId,
        type: IntegrationEnums.klaviyo,
      }),
    );
    yield put(saveKlaviyoSuccess());
  } catch (error) {
    yield put(saveKlaviyoFailure());
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteKlaviyoIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.klaviyo,
      }),
    );
    yield put(clearKlaviyoState());
  } catch (error) {
    yield put(deleteKlaviyoFailure());
  }
}

export default function* sagas() {
  yield takeLatest(KLAVIYO_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(KLAVIYO_GET_FIELDS, handleGetFields);
  yield takeLatest(KLAVIYO_GET_LIST, handleGetList);
  yield takeLatest(KLAVIYO_SAVE, handleSave);
  yield takeLatest(KLAVIYO_DELETE, handleDelete);
}
