import type { CheckboxInputProps } from './types';
import React, { forwardRef, ChangeEvent } from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

const Checkbox = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (props: CheckboxInputProps, ref) => {
    const {
      label,
      id,
      onChange,
      error,
      disabled = false,
      className,
      elementSize,
      labelClassName,
      styleType = 'default',
      ...rest
    } = props;
    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;

      onChange && onChange(event);
    };
    return (
      <div
        className={cn(
          styles.checkbox,
          disabled && styles.disabled,
          !!error && styles.checkbox_error,
          className && className,
          {
            [styles.small]: elementSize === 'sm',
            [styles.targeting_style]: styleType === 'targeting',
          },
        )}
      >
        <input
          className={cn(!!error && styles.error)}
          id={id}
          type="checkbox"
          disabled={disabled}
          onChange={onChangeInput}
          data-testid={id}
          ref={ref}
          {...rest}
        />
        {label && (
          <label
            htmlFor={id}
            data-testid={`${id}-label`}
            className={cn(
              styles.label,
              labelClassName,
              disabled && styles.disabled,
            )}
          >
            {label}
          </label>
        )}
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';

export default Checkbox;
