import React, { FC } from 'react';
import classNames from 'classnames';
import { PsLottie, Tooltip } from 'ui';
import styles from './ShopifyAppExtensionModal.module.scss';
import ShopifyIcon from '@assets/icons/shopify-icon.svg';
import { ShopifyAppExtensionModalProps } from './types';
import ModalIconContainer from '@components/ModalIconContainer/ModalIconContainer';
import PopupsmartIcon from '@assets/icons/popupsmart-rounded.svg';
import PSButton from '@elements/PSButton';
import { useShopifyAppExtensionStatusMutation } from '@generated/graphql';
import CheckIcon from '@assets/icons/check_circle_outline.svg';
import ErrorIcon from '@assets/icons/error-icon.svg';
import HelpIcon from '@assets/icons/help-info-icon.svg';
import loading from '@assets/lotties/loading.json';
import ShopifyAppExtensionStepTwo from '@assets/images/shopify-app-extension-step-two.png';
import ShopifyAppExtensionStepThree from '@assets/images/shopify-app-extension-step-three.png';
import PsModal from '@components/PsModal';
import { useDispatch } from 'react-redux';
import { showModal, updateModalOnClose } from '@connectors/modal/actions';

const ShopifyAppExtensionModal: FC<ShopifyAppExtensionModalProps> = ({
  website,
  onClickOkayButton,
  onVerified,
  redirectUrl = '',
}) => {
  const dispatch = useDispatch();
  const [shopifyAppExtensionStatus] = useShopifyAppExtensionStatusMutation();
  const [verifyStatus, setVerifyStatus] = React.useState<
    'loading' | 'success' | 'error' | 'idle'
  >('idle');
  const handleCloseModal = (status: boolean) => {
    if (onVerified) {
      dispatch(
        updateModalOnClose({
          id: 'shopifyAppExtensionModal',
          onClose: () => onVerified(status),
        }),
      );
    }
  };
  const handleClickRefreshButton = () => {
    setVerifyStatus('loading');
    shopifyAppExtensionStatus({
      variables: { domain: website || '' },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.shopifyAppExtensionStatus.status) {
          setVerifyStatus('success');
          handleCloseModal(true);
        } else {
          setVerifyStatus('error');
          handleCloseModal(false);
        }
      },
      onError: () => {
        setVerifyStatus('error');
        handleCloseModal(false);
      },
    });
  };
  const handleClickOkayButton = () => {
    onClickOkayButton && onClickOkayButton();
    onVerified && onVerified(true);
  };
  const renderVerifyStatus = () => {
    switch (verifyStatus) {
      case 'loading':
        return (
          <>
            <div className={styles.icon}>
              <PsLottie
                style={{
                  width: 18,
                  height: 18,
                }}
                loop={true}
                autoplay={true}
                rendererSettings={{
                  preserveAspectRatio: 'xMidYMin slice',
                }}
                animationData={loading}
              />
            </div>
            <div className={styles.label}>Waiting for verification</div>
          </>
        );
      case 'success':
        return (
          <>
            <div className={styles.icon}>
              <CheckIcon width="20" height="20" viewBox="-1 -1 20 20" />
            </div>
            <div className={styles.label}>
              The Popupsmart app is activated on your Shopify.
            </div>
          </>
        );
      case 'error':
        return (
          <>
            <div className={styles.icon}>
              <ErrorIcon width="20" height="20" viewBox="-1 -1 20 20" />
            </div>
            <div className={styles.label}>
              An error occurred during the verification process.
            </div>
          </>
        );
    }
  };
  return (
    <div className={styles.container}>
      <ModalIconContainer
        className={styles.iconContainer}
        leftIcon={<PopupsmartIcon width="32" height="32" viewBox="0 0 48 48" />}
        rightIcon={<ShopifyIcon width="32" height="32" viewBox="0 0  18 18" />}
      />
      <span className={styles.title}>Verify Popupsmart app</span>
      <span className={styles.subTitle}>
        Please follow the steps below to verify your website.
      </span>
      <div className={styles.steps}>
        <div className={styles.stepContainer}>
          <div className={styles.stepNumber}>1</div>
          <div className={styles.stepText}>
            Go to{' '}
            <a href={redirectUrl} target="_blank" rel="noreferrer">
              &quot;Theme settings in Shopify&quot;
            </a>
          </div>
        </div>
        <div className={styles.stepContainer}>
          <div className={styles.stepNumber}>2</div>
          <div className={styles.stepText}>Enable the Popupsmart App.</div>
          <div className={styles.hint}>
            <Tooltip
              content={
                <img
                  src={ShopifyAppExtensionStepTwo.src}
                  width="65px"
                  height="34px"
                />
              }
              style={{ width: 65, height: 33, padding: 0, cursor: 'pointer' }}
            >
              <HelpIcon
                width="18"
                height="18"
                viewBox="0 0 20 20"
                onClick={() =>
                  dispatch(showModal({ id: 'shopifyAppExtensionStepTwoModal' }))
                }
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.stepContainer}>
          <div className={styles.stepNumber}>3</div>
          <div className={styles.stepText}>
            Save changes and return to this page.
          </div>
          <div className={styles.hint}>
            <Tooltip
              content={
                <img
                  src={ShopifyAppExtensionStepThree.src}
                  width="60px"
                  height="34px"
                />
              }
              style={{ width: 65, height: 33, padding: 0, cursor: 'pointer' }}
            >
              <HelpIcon
                width="18"
                height="18"
                viewBox="0 0 20 20"
                onClick={() =>
                  dispatch(
                    showModal({ id: 'shopifyAppExtensionStepThreeModal' }),
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.stepContainer}>
          <div className={styles.stepNumber}>4</div>
          <div className={styles.stepText}>
            Click the &ldquo;Refresh&ldquo; button.
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.status, {
          [styles.idle]: verifyStatus === 'idle',
          [styles.loading]: verifyStatus === 'loading',
          [styles.success]: verifyStatus === 'success',
          [styles.error]: verifyStatus === 'error',
        })}
      >
        {renderVerifyStatus()}
      </div>
      <div className={styles.buttonContainer}>
        {verifyStatus !== 'success' ? (
          <PSButton
            variant="outline"
            size="sm"
            type="button"
            onClick={handleClickRefreshButton}
          >
            Refresh
          </PSButton>
        ) : (
          <PSButton size="sm" type="button" onClick={handleClickOkayButton}>
            Okay
          </PSButton>
        )}
      </div>

      <PsModal
        id="shopifyAppExtensionStepTwoModal"
        closeable={true}
        size="max"
        closeButtonClassName="!m-0 !p-0 !-top-9 !-right-9"
        wrapperClassName="overflow-visible"
      >
        <img src={ShopifyAppExtensionStepTwo.src} width="100%" height="auto" />
      </PsModal>
      <PsModal
        id="shopifyAppExtensionStepThreeModal"
        closeable={true}
        size="max"
        closeButtonClassName="!m-0 !p-0 !-top-9 !-right-9"
        wrapperClassName="overflow-visible"
      >
        <img
          src={ShopifyAppExtensionStepThree.src}
          width="100%"
          height="auto"
        />
      </PsModal>
    </div>
  );
};
export default ShopifyAppExtensionModal;
