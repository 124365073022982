import { RootState } from '@connectors/reducers';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import memoize from 'proxy-memoize';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import { UrlBrowsing, UrlBrowsingMultiple } from 'core';

const selectSegment = (state: RootState) => state.segment || initialState;

const selectActiveSegmentTargets = (state: RootState) =>
  state.segment.active.segment?.targets;

const selectSegmentTargetsCopy = (state: RootState) =>
  state.segment?.targetsCopyData;

const makeSelectSegmentsFetchStatus = createSelector(
  selectSegment,
  (segmentState) => segmentState.status,
);

const makeSelectSegmentTargetsCopy = createSelector(
  selectSegmentTargetsCopy,
  (targetsCopy) => targetsCopy,
);

const makeSelectActiveSegmentTarget = createSelector(
  selectActiveSegmentTargets,
  (segmentTargets) => segmentTargets,
);

const makeSelectSegments = createSelector(
  selectSegment,
  (segmentState) => segmentState.items,
);

const makeSelectActiveSegmentsFetchStatus = createSelector(
  selectSegment,
  (segmentState) => segmentState.active.status,
);

const makeSelectActiveSegments = createSelector(
  selectSegment,
  (segmentState) => segmentState.active.segment,
);

const makeSelectSegmentTargetingDisplayFrequency = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.displayFrequency,
);

const makeSelectSegmentTargetingSchedule = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.schedule,
);

const makeSelectSegmentTargetingOperatingSystem = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.operatingSystem,
);

const makeSelectSegmentTargetingNewOrReturningVisitors = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.newOrReturningVisitors,
);

const makeSelectSegmentTargetingBrowserLanguage = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.browserLanguage,
);

const makeSelectSegmentTargetingGeoLocated = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.geoLocated,
);

const makeSelectSegmentTargetingTrafficSource = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.trafficSource,
);

const makeSelectSegmentTargetingHtmlTargeting = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.htmlTargeting,
);

const makeSelectSegmentTargetingCookieTargeting = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.cookieTargeting,
);

const makeSelectSegmentTargetingSiteDataTargeting = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.siteDataTargeting,
);

const makeSelectSegmentTargetingAfterXSeconds = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.afterXSeconds,
);

const makeSelectSegmentTargetingAfterScrollingXAmount = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.afterScrollingXAmount,
);

const makeSelectSegmentTargetingOnExitIntent = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.onExitIntent,
);

const makeSelectSegmentTargetingUrlBrowsing = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) =>
    (segmentTargetsCopy?.urlBrowsing as UrlBrowsingMultiple)?.[
      'all'
    ] as UrlBrowsing,
);

const makeSelectSegmentTargetingInActivityMode = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.inActivityMode,
);

const makeSelectSegmentTargetingOnClick = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.onClick,
);

const makeSelectSegmentTargetingAdBlockDetection = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.AdBlockDetection,
);

const makeSelectSegmentTargetingVisitorDevice = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.visitorDevice,
);

const makeSelectSegmentTargetingOnclickTargeting = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.onclickTargeting,
);

const makeSelectSegmentTargetingTargetSmartMode = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.targetSmartMode,
);

const makeSelectSegmentTargetsCopyIsValid = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.isValid,
);

const makeSelectSegmentTargetCopyIsChanged = memoize((state: RootState) => {
  const { active, targetsCopyData } = state.segment;
  const activeSegment = active.segment;
  if (!(activeSegment && activeSegment.targets && targetsCopyData))
    return false;
  const tempData = { targets: {} };
  const tempOrjData = { targets: {} };
  set(
    tempData.targets,
    'targets',
    get({ targets: targetsCopyData }, 'targets'),
  );
  set(tempOrjData.targets, 'targets', get(activeSegment, 'targets'));
  return !isEqual(JSON.stringify(tempData), JSON.stringify(tempOrjData));
});

const makeSelectIsChangeViewVisible = createSelector(
  selectSegment,
  (segment) => segment.isChangeViewVisible,
);

const makeSelectSelected = createSelector(
  selectSegment,
  (segment) => segment.selected,
);

const makeSelectSelectedIsSmartMode = createSelector(
  selectSegment,
  (segment) => segment.selected.isSmartMode,
);

const makeSelectSelectedSegmentId = createSelector(
  selectSegment,
  (segment) => segment.selected.segment?.id,
);

const makeSelectSelectedTarget = createSelector(selectSegment, (segment) => {
  const selectedSegment = segment.selected.segment;

  if (!selectedSegment) return null;

  return {
    ...selectedSegment.targets,
    segmentId: selectedSegment.id,
    segmentName: selectedSegment.name,
    segmentDescription: selectedSegment.description,
    segmentColor: selectedSegment.color,
    segmentIcon: selectedSegment.icon,
  };
});

const makeSelectSegmentTargetingShopifyTargeting = createSelector(
  selectSegmentTargetsCopy,
  (segmentTargetsCopy) => segmentTargetsCopy?.shopifyTargeting,
);

export {
  makeSelectActiveSegmentTarget,
  makeSelectSegments,
  makeSelectSegmentTargetsCopy,
  makeSelectSegmentsFetchStatus,
  makeSelectActiveSegmentsFetchStatus,
  makeSelectActiveSegments,
  makeSelectSegmentTargetingDisplayFrequency,
  makeSelectSegmentTargetingAdBlockDetection,
  makeSelectSegmentTargetingAfterScrollingXAmount,
  makeSelectSegmentTargetingAfterXSeconds,
  makeSelectSegmentTargetingBrowserLanguage,
  makeSelectSegmentTargetingCookieTargeting,
  makeSelectSegmentTargetingGeoLocated,
  makeSelectSegmentTargetingHtmlTargeting,
  makeSelectSegmentTargetingInActivityMode,
  makeSelectSegmentTargetingNewOrReturningVisitors,
  makeSelectSegmentTargetingOnClick,
  makeSelectSegmentTargetingOnExitIntent,
  makeSelectSegmentTargetingOnclickTargeting,
  makeSelectSegmentTargetingOperatingSystem,
  makeSelectSegmentTargetingSchedule,
  makeSelectSegmentTargetingSiteDataTargeting,
  makeSelectSegmentTargetingTargetSmartMode,
  makeSelectSegmentTargetingTrafficSource,
  makeSelectSegmentTargetingUrlBrowsing,
  makeSelectSegmentTargetingVisitorDevice,
  makeSelectSegmentTargetsCopyIsValid,
  makeSelectIsChangeViewVisible,
  makeSelectSelectedIsSmartMode,
  makeSelectSelected,
  makeSelectSelectedSegmentId,
  makeSelectSelectedTarget,
  makeSelectSegmentTargetCopyIsChanged,
  makeSelectSegmentTargetingShopifyTargeting,
};
