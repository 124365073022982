import React, { FC } from 'react';

type IconProps = {
  width?: string;
  height?: string;
  viewBox?: string;
};

const DesktopIcon: FC<IconProps> = ({ width, height, viewBox }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox={viewBox || '0 0 16 16'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3333 12.8924C14.0667 12.8924 14.6667 12.2924 14.6667 11.559V3.11458C14.6667 2.38125 14.0667 1.78125 13.3333 1.78125H2.66667C1.93333 1.78125 1.33333 2.38125 1.33333 3.11458V11.559C1.33333 12.2924 1.93333 12.8924 2.66667 12.8924H0V14.2257H16V12.8924H13.3333ZM2.66667 3.11458H13.3333V11.559H2.66667V3.11458Z" />
    </svg>
  );
};

export default DesktopIcon;
