const scrollToElementById = (id: string): void => {
  const element: HTMLElement | null = document.getElementById(id);
  if (element) {
    if ('scrollIntoViewIfNeeded' in element) {
      element.scrollIntoView(true);
    } else {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        // inline: 'nearest',
      });
    }
  }
};

export default scrollToElementById;
