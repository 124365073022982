import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';

import ErrorIcon from '@assets/icons/error-icon.svg';
import { CheckboxProps } from './types';
import styles from './Checkbox.module.scss';

const PSCheckbox: FC<CheckboxProps> = ({
  label,
  placeholder,
  labelClassName = '',
  checkBoxClassName = '',
  inputWrapperClassName = '',
  field: { value, name, onChange },
  fieldState: { error },
  disabled,
  onChangeEvent,
}) => {
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
    onChangeEvent && onChangeEvent(e.target.checked);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.inputWrapper} ${inputWrapperClassName}`}>
        <input
          type="checkbox"
          id={name}
          className={classNames(
            {
              [styles.input]: true,
              [styles.error]: !!error,
            },
            checkBoxClassName,
          )}
          data-testid={name}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onChange={handleChangeValue}
          checked={value}
        />
        {label && (
          <label
            className={classNames(
              styles.label,
              value && styles.checkedLabel,
              labelClassName,
            )}
            htmlFor={name}
          >
            {label}
          </label>
        )}
      </div>
      <div className={styles.errorContainer} data-testid="textfield-error">
        {!!error && (
          <>
            <ErrorIcon width="18px" height="18px" className={styles.icon} />
            <p className={styles.message}>{error?.message || 'Invalid'}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default PSCheckbox;
