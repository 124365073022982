import { FC, useMemo, useState } from 'react';
import styles from './popup-share-url.module.scss';
import cn from 'classnames';
import Input from '@elements/Input';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from '@assets/icons/duplicate-icon.svg';
import type { PopupShareUrlProps } from './types';
import { encryptAESGCM } from 'core/helpers/encrypt';
import { useTranslation } from 'react-i18next';

const PopupShareUrl: FC<PopupShareUrlProps> = ({ campaign, accountId }) => {
  const { t } = useTranslation();
  const [copyUrl, setCopyUrl] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const handlCopyUrl = () => {
    setCopyUrl(true);

    setTimeout(() => {
      setCopyUrl(false);
    }, 750);
  };
  useMemo(async () => {
    if (!accountId || !campaign) return '';
    const encryptedCampaignId = await encryptAESGCM(
      campaign.id?.toString() || '',
    );
    setUrl(
      `${
        process.env.NEXT_PUBLIC_FORM_DOMAIN || 'https://form.popupsmart.com'
      }/${accountId || ''}/${encryptedCampaignId}`,
    );
  }, []);
  return (
    <div className={styles.popupShareUrl}>
      <div
        className={cn('text-[12px]', 'mb-2', {
          'text-[#444444]': campaign?.settings?.formSharing,
          'text-gray-500': !campaign?.settings?.formSharing,
        })}
      >
        {t('sections:form-sharing-accordion:description')}
      </div>
      <Input
        className="w-full mb-[10px]"
        id="popup-url"
        name="popup-url"
        elementSize="sm"
        value={url}
        readOnly
        disabled={!campaign?.settings?.formSharing}
        disabledIcon={false}
      />
      <div className={cn(styles.buttonWrapper)}>
        <CopyToClipboard text={url} onCopy={handlCopyUrl}>
          <button
            type="button"
            disabled={copyUrl || !campaign?.settings?.formSharing}
            className={cn([
              { 'cursor-not-allowed': !campaign?.settings?.formSharing },
            ])}
          >
            {copyUrl ? (
              'Copied'
            ) : (
              <>
                <div
                  className={cn(styles.icon, [
                    { 'text-gray-500': !campaign?.settings?.formSharing },
                  ])}
                >
                  <CopyIcon width="14px" height="14px" viewBox="0 0 17 17" />
                </div>
                <div
                  className={cn(styles.text, [
                    { 'text-gray-500': !campaign?.settings?.formSharing },
                  ])}
                >
                  {t('common:copyToClipboard')}
                </div>
              </>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default PopupShareUrl;
