import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import cn from 'classnames';

import { Appearance, LayoutStyle, Node } from '@connectors/builder/campaign';
import Elements from '@components/Preview/Elements';
import { getResponsiveStyle } from '@utils/element';

import styles from './PreviewLite.module.scss';

import { getThemeVariables } from 'core/functions/theme';
import { hexToRGB } from 'core/helpers/string';

import DeviceTypes from '@enums/DeviceTypes';
import LayoutTypes from '@enums/LayoutTypes';

interface PreviewLiteProps {
  className?: string;
  popupData?: Node;
  themeData?: LayoutStyle;
  pageType?: string;
  fonts?: string[];
  appearance: Appearance | null | undefined;
  currentDevice: DeviceTypes;
  currentPageId?: string;
}

const PreviewLite: FC<PreviewLiteProps> = ({
  className,
  popupData,
  themeData,
  fonts,
  appearance,
  currentDevice,
  currentPageId,
  pageType,
}) => {
  const themeFontSizes = {
    headline: themeData?.data.headline.fontSize,
    body: themeData?.data.bodyText.fontSize,
    element: themeData?.data.element?.fontSize,
  };
  const isFullScreenPageType = pageType === LayoutTypes.fullscreen;
  const isLightingPageType = pageType === LayoutTypes.light;

  const customStyle = useMemo(() => {
    const { customCss } = appearance || {};

    return customCss;
  }, [appearance]);

  useEffect(() => {
    if (fonts?.length) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const webFontLoader = require('webfontloader');
      webFontLoader.load({
        google: {
          families: fonts,
        },
      });
    }
  }, [fonts]);

  const renderPopup = (node: Node | string): ReactNode => {
    if (node === null || typeof node === 'string' || typeof node === 'number') {
      return node as string | number;
    }
    const { id, nodeName, attributes, childNodes } = node as Node;
    let children: ReactNode[] | undefined;
    if (childNodes?.length > 0) {
      children = childNodes.map((childNode) => renderPopup(childNode));
    }

    const component = Elements[nodeName as keyof typeof Elements] || nodeName;

    let baseFontSize = themeFontSizes.element;
    if (nodeName === 'headline') {
      baseFontSize = themeFontSizes.headline;
    }
    if (nodeName === 'text') {
      baseFontSize = themeFontSizes.body;
    }
    const responsiveStyle =
      attributes?.style && getResponsiveStyle(attributes?.style, baseFontSize);

    const element = React.createElement(
      component,
      {
        id,
        nodeName,
        attributes: {
          ...attributes,
          style: responsiveStyle,
          'data-element': nodeName,
          'data-element-id': id,
          'data-editable': false,
          'data-editable-text': false,
          'data-page-id':
            id === 'canvas' ? currentPageId?.split('-')[0] : undefined,
          'data-device-type':
            id === 'canvas' || id === 'teaser' ? currentDevice : undefined,
        },
        pageType,
        isTemplatePreview: true,
      },
      children,
    );

    return element;
  };

  const backgroundColor = themeData?.data.body.backgroundColor;
  const isBackgroundColorNone = backgroundColor === 'none';
  const canvasBackgroundColor = backgroundColor?.includes('gradient')
    ? backgroundColor
    : isBackgroundColorNone
    ? 'transparent'
    : hexToRGB(backgroundColor || '#FFF', 1);

  const teaserBackgroundValue = themeData?.data?.teaser?.backgroundColor;
  const isTeaserBackgroundColorNone = teaserBackgroundValue === 'none';
  const teaserBackgroundColor = teaserBackgroundValue?.includes('gradient')
    ? `${teaserBackgroundValue}`
    : isTeaserBackgroundColorNone
    ? 'transparent'
    : hexToRGB(teaserBackgroundValue || '#FFF', 1);

  if (!popupData) return null;

  const overlayStyle = {
    backgroundColor: 'var(--bodyOverlayColor)' || 'transparent',
    opacity: 'var(--bodyOverlayOpacity)',
  };

  return (
    <>
      <style
        id="preview-custom-css"
        dangerouslySetInnerHTML={{ __html: customStyle || '' }}
      />
      <style jsx global>{`
        :root {
          ${themeData && themeData.data && getThemeVariables(themeData.data)}
        }
        ${teaserBackgroundColor || themeData?.data?.teaser?.background
          ? `
          .${styles.mockup} [data-element-id='teaser'] {
            background: url(${themeData?.data?.teaser?.background}),
              ${teaserBackgroundColor};
  
            background-position: var(--teaserBackgroundPosition);
            background-size: var(--teaserObjectFit);
            background-repeat: var(--teaserBackgroundRepeat);
            border-radius:  ${themeData?.data?.teaser?.borderRadius};
            overflow: hidden;
          }
          `
          : ''}

        [data-element-id='canvas'] {
          background: url(${themeData?.data.body.background}),
            ${canvasBackgroundColor};
          background-position: var(--bodyBackgroundPosition);
          background-size: var(--bodyObjectFit);
          background-repeat: var(--bodyBackgroundRepeat);
          border-radius: var(--bodyBorderRadius);

          box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
        }
        a {
          color: var(--linkColor);
        }
      `}</style>
      <div
        className={cn(styles.preview, className, {
          [styles.mobileView]: currentDevice === DeviceTypes.mobile,
          [styles.isFullScreenPageType]: isFullScreenPageType,
          [styles.isLightingPageType]: isLightingPageType,
        })}
      >
        <div className={styles.mockup}>
          {renderPopup(popupData)}
          <div
            id="mobile-preview-overlay"
            className={cn(styles.overlay, styles.mobileOverlay)}
            style={overlayStyle}
          />
        </div>
        <div
          id="desktop-preview-overlay"
          className={cn(styles.overlay, styles.desktopOverlay)}
          style={overlayStyle}
        />
      </div>
    </>
  );
};
export type { PreviewLiteProps };
export default PreviewLite;
