import { combineReducers } from 'redux';

import activeCampaign from './activeCampaign/reducer';
import customerIo from './customerIo/reducer';
import drip from './drip/reducer';
import emailOctopus from './emailOctopus/reducer';
import hubspot from './hubSpot/reducer';
import klaviyo from './klaviyo/reducer';
import mailchimp from './mailchimp/reducer';
import mailerlite from './mailerlite/reducer';
import sendgrid from './sendgrid/reducer';
import sendinblue from './sendinblue/reducer';
import sendloop from './sendloop/reducer';
import shopify from './shopify/reducer';
import webhook from './webhook/reducer';

export default combineReducers({
  activeCampaign,
  customerIo,
  drip,
  emailOctopus,
  hubspot,
  klaviyo,
  mailchimp,
  mailerlite,
  sendgrid,
  sendinblue,
  sendloop,
  shopify,
  webhook,
});

export interface ApiConnectionObject {
  id: string;
  name: string;
  token?: string;
  refreshToken?: string;
  tokenExpireDate?: string;
  apiKey: string;
  account?: string;
  siteId?: string;
  password?: string;
  url?: string;
  status?: boolean;
}

export interface ApiListObject {
  id: string;
  listId: string;
  name: string;
}
export interface ApiFieldObject {
  id: number;
  integrationCampaignId: number;
  popupsmartFieldName: string;
  integratorFieldName: string;
  integratorFieldId: string;
  integratorTag: string;
  fieldType: FieldType;
  isCustomerEmail: boolean;
  staticValue: string;
  nodeName: string;
}
export interface ApiTestResultObject {
  isSent: boolean;
  sentData: ApiTestResultFieldObject[];
  message: string;
  messageTitle: string;
  errorLogs: string;
}
export interface ApiTestResultFieldObject {
  integratorFieldName: string;
  integratorFieldId: string;
  integratorTag: string;
  staticValue: string;
  popupsmartFieldName: string;
  value: string;
  nodeName: string;
}
export interface IntegrationListObject {
  id: number;
  listId: string;
  name: string;
  status: boolean;
  integrationFields: ApiFieldObject[];
  integration: ApiConnectionObject;
  url?: string;
}
export enum FieldType {
  FormField,
  SystemField,
  JSApiField,
  TextValue,
}

export interface ApiSaveObject {
  id?: number;
  campaignId: number;
  connectionSettings: ApiConnectionObject;
  list: ApiListObject;
  fields: ApiFieldObject[];
}
