import React, { useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import DeleteIcon from '@assets/icons/x-icon.svg';
import styles from './TagInput.module.scss';
import { TagInputProps } from './types';

const TagInput: React.FC<TagInputProps> = ({
  field: { value = [], name, onChange },
}) => {
  const [tagInputValue, setTagInputValue] = useState('');
  const [tagsArray, setTagsArray] = useState<string[]>(value);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const tagText = tagInputValue.trim();

      if (tagText !== '') {
        setTagsArray((prevTags) => [...prevTags, tagText]);
        setTagInputValue('');
      }
    }

    if (event.key === 'Backspace' && tagInputValue === '') {
      setTagsArray((prevTags) => prevTags.slice(0, prevTags.length - 1));
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTagInputValue(event.target.value);
  };

  const handleDelete = (deleteIndex: number) => () => {
    setTagsArray((tags) =>
      tags.filter((_tag, tagIndex) => tagIndex !== deleteIndex),
    );
  };

  const handleOnFocus = (event: ChangeEvent<HTMLInputElement>) => {
    const parentContainer = event.target.closest(`.${styles.container}`);
    if (parentContainer) {
      parentContainer.classList.add(styles.focusedInput);
    }
  };

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const parentContainer = event.target.closest(`.${styles.container}`);
    if (parentContainer) {
      parentContainer.classList.remove(styles.focusedInput);
    }
  };

  useEffect(() => {
    onChange(tagsArray);
  }, [tagsArray]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.tags}>
          {tagsArray.map((tag, index) => (
            <div className={styles.tag} key={index}>
              <span>{tag}</span>
              <button
                onClick={handleDelete(index)}
                type="button"
                className={styles.deleteButton}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}
          <input type="hidden" name={name} value={tagsArray} />
          <input
            className={`${styles.input} ${
              tagsArray.length > 0 ? styles.disablePlaceHolder : ''
            }`}
            type="text"
            value={tagInputValue}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onChange={handleChange}
            placeholder="Create tags"
          />
        </div>
      </div>
    </div>
  );
};

export default TagInput;
