import ReduxStatus from '@enums/ReduxStatus';
import { ReduxAction } from 'global';
import produce, { Draft } from 'immer';
import isEmpty from 'lodash/isEmpty';

import type {
  DeleteSegmentSuccessAction,
  GetAllSegmentSuccessAction,
  GetActiveSegmentSuccessAction,
  SegmentState,
  UpdateSegmentNameSuccessAction,
  UpdateSegmentSuccessAction,
  SetSelectedSegmentSuccessAction,
  SetSelectedSegmentAsSmartModeAction,
  UpdateChangeViewVisibilitySuccessAction,
} from './types';

import {
  GET_ALL_SEGMENTS,
  GET_ALL_SEGMENTS_SUCCESS,
  GET_ALL_SEGMENTS_ERROR,
  GET_ACTIVE_SEGMENT_ERROR,
  GET_ACTIVE_SEGMENT_SUCCESS,
  GET_ACTIVE_SEGMENT,
  UPDATE_SEGMENT_NAME,
  UPDATE_SEGMENT_NAME_SUCCESS,
  UPDATE_SEGMENT_NAME_ERROR,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_ERROR,
  UPDATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_ERROR,
  RESET,
  DELETE_SEGMENT_TARGET_ERROR,
  SET_SEGMENT_TARGET_ERROR,
  DELETE_TARGET_SEGMENT_TARGETS_COPY,
  UPDATE_SEGMENT_TARGETS_COPY,
  RESET_SEGMENT_TARGETS_COPY,
  SEGMENT_TARGETS_COPY_TO_SEGMENT_TARGETS,
  SET_SEGMENT_TARGETS_COPY,
  SELECT_ACTIVE_SEGMENT,
  SELECT_ACTIVE_SEGMENT_ERROR,
  RESET_ACTIVE_SEGMENT,
  SEGMENT_TARGETS_TO_SEGMENT_TARGETS_COPY,
  DELETE_SEGMENT_ERROR,
  DELETE_SEGMENT_SUCCESS,
  SET_SELECTED_SEGMENT_ERROR,
  SET_SELECTED_SEGMENT,
  SET_SELECTED_SEGMENT_SUCCESS,
  RESET_SELECTED_SEGMENT,
  UPDATE_CHANGE_VIEW_VISIBILITY_SUCCESS,
  SET_SELECTED_SEGMENT_AS_SMART_MODE,
} from './constants';

import { RESET_STORE } from '@connectors/actions';
import { TargetsKey } from '@connectors/builder/types';
import { urlBrowsingMigrations } from '@utils/urlBrowsingMigrations';

export const initialState: SegmentState = {
  active: {
    segment: null,
    status: ReduxStatus.initialized,
  },
  selected: {
    segment: null,
    isSmartMode: false,
    status: ReduxStatus.initialized,
  },
  items: [],
  isChangeViewVisible: false,
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<SegmentState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case GET_ALL_SEGMENTS: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case GET_ALL_SEGMENTS_SUCCESS: {
      const { payload } = action as GetAllSegmentSuccessAction;
      const { data } = payload;

      draft.items = data;
      draft.status = ReduxStatus.success;
      return;
    }
    case GET_ALL_SEGMENTS_ERROR: {
      draft.status = ReduxStatus.errored;
      return;
    }
    case GET_ACTIVE_SEGMENT: {
      draft.active.status = ReduxStatus.loading;
      return;
    }
    case GET_ACTIVE_SEGMENT_SUCCESS: {
      const { payload } = action as GetActiveSegmentSuccessAction;
      const { data } = payload;

      if (
        data.targets.operatingSystem?.android &&
        data.targets.operatingSystem?.chromium &&
        data.targets.operatingSystem?.ios &&
        data.targets.operatingSystem?.linux &&
        data.targets.operatingSystem?.macOs &&
        data.targets.operatingSystem?.windows &&
        !data.targets.operatingSystem?.isSelected
      ) {
        data.targets.operatingSystem.isSelected = false;
      } else if (data.targets.operatingSystem) {
        data.targets.operatingSystem.isSelected = true;
      }
      draft.active.segment = data;
      draft.active.status = ReduxStatus.success;
      return;
    }
    case GET_ACTIVE_SEGMENT_ERROR: {
      draft.active.status = ReduxStatus.errored;
      return;
    }
    case SET_SEGMENT_TARGETS_COPY: {
      const { payload } = action;
      const { targets } = payload;
      draft.targetsCopyData = targets;

      // urlBrowsing v2 migration
      if (draft.targetsCopyData && draft.targetsCopyData.urlBrowsing) {
        draft.targetsCopyData = {
          ...draft.targetsCopyData,
          urlBrowsing: {
            ...urlBrowsingMigrations(draft.targetsCopyData?.urlBrowsing),
          },
        };
      }

      return;
    }
    case SEGMENT_TARGETS_TO_SEGMENT_TARGETS_COPY: {
      const { active } = draft;

      if (active.segment) draft.targetsCopyData = draft.active.segment?.targets;
      return;
    }
    case SEGMENT_TARGETS_COPY_TO_SEGMENT_TARGETS: {
      const { targetsCopyData } = draft;
      if (!draft.active.segment) return;
      if (!targetsCopyData) return;

      draft.active.segment.targets = targetsCopyData;
      return;
    }
    case RESET_SEGMENT_TARGETS_COPY: {
      if (draft.targetsCopyData) delete draft.targetsCopyData;
      return;
    }
    case RESET_ACTIVE_SEGMENT: {
      if (draft.active.segment) draft.active.segment = null;
      return;
    }
    case UPDATE_SEGMENT_TARGETS_COPY: {
      const { payload } = action;
      const { data } = payload;
      draft.targetsCopyData = { ...draft.targetsCopyData, ...data };
      return;
    }
    case DELETE_TARGET_SEGMENT_TARGETS_COPY: {
      const { payload } = action;
      const { target }: { target: TargetsKey } = payload;
      if (!draft.targetsCopyData || !target) return;
      delete draft.targetsCopyData[target];
      return;
    }
    case SET_SEGMENT_TARGET_ERROR: {
      const { payload } = action;
      const { error } = payload;
      if (!draft.targetsCopyData || !error) return;
      draft.targetsCopyData.isValid = {
        ...draft.targetsCopyData.isValid,
        ...error,
      };
      return;
    }
    case DELETE_SEGMENT_TARGET_ERROR: {
      const { payload } = action;
      const { targetName } = payload;
      if (!draft.targetsCopyData?.isValid || !targetName) return;
      delete draft.targetsCopyData.isValid[targetName as TargetsKey];
      if (isEmpty(draft.targetsCopyData.isValid))
        delete draft.targetsCopyData.isValid;
      return;
    }
    case UPDATE_SEGMENT_NAME: {
      draft.active.status = ReduxStatus.loading;
      return;
    }
    case UPDATE_SEGMENT_NAME_SUCCESS: {
      const { payload } = action as UpdateSegmentNameSuccessAction;
      const { data, isActive } = payload;

      if (isActive) {
        draft.active.segment = data;
        draft.active.status = ReduxStatus.success;
      }

      const itemIndex = draft.items.findIndex((item) => item.id === data.id);
      if (itemIndex >= 0) {
        draft.items[itemIndex] = {
          ...draft.items[itemIndex],
          name: data.name,
          description: data.description,
        };
      }

      return;
    }
    case UPDATE_SEGMENT_NAME_ERROR: {
      draft.active.status = ReduxStatus.errored;
      return;
    }
    case UPDATE_SEGMENT: {
      draft.active.status = ReduxStatus.loading;
      return;
    }
    case UPDATE_SEGMENT_SUCCESS: {
      const { payload } = action as UpdateSegmentSuccessAction;
      const { data } = payload;

      draft.active.segment = data;
      draft.active.status = ReduxStatus.success;
      return;
    }
    case UPDATE_SEGMENT_ERROR: {
      draft.active.status = ReduxStatus.errored;
      return;
    }
    case CREATE_SEGMENT: {
      draft.active.status = ReduxStatus.loading;
      return;
    }
    case CREATE_SEGMENT_SUCCESS: {
      draft.active.status = ReduxStatus.success;
      return;
    }
    case CREATE_SEGMENT_ERROR: {
      draft.active.status = ReduxStatus.errored;
      return;
    }
    case SELECT_ACTIVE_SEGMENT: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case SELECT_ACTIVE_SEGMENT_ERROR: {
      draft.status = ReduxStatus.errored;
      return;
    }
    case DELETE_SEGMENT_SUCCESS: {
      const { payload } = action as DeleteSegmentSuccessAction;
      const { id } = payload;

      draft.items = draft.items.filter((item) => item.id !== id);
      draft.status = ReduxStatus.success;
      return;
    }
    case DELETE_SEGMENT_ERROR: {
      draft.status = ReduxStatus.errored;
      return;
    }
    case SET_SELECTED_SEGMENT: {
      draft.selected.status = ReduxStatus.loading;
      return;
    }
    case SET_SELECTED_SEGMENT_SUCCESS: {
      const { payload } = action as SetSelectedSegmentSuccessAction;
      const { data } = payload;

      if (
        data.targets.operatingSystem?.android &&
        data.targets.operatingSystem?.chromium &&
        data.targets.operatingSystem?.ios &&
        data.targets.operatingSystem?.linux &&
        data.targets.operatingSystem?.macOs &&
        data.targets.operatingSystem?.windows &&
        !data.targets.operatingSystem?.isSelected
      ) {
        data.targets.operatingSystem.isSelected = false;
      } else if (data.targets.operatingSystem) {
        data.targets.operatingSystem.isSelected = true;
      }
      draft.selected.segment = data;
      draft.selected.isSmartMode = false;

      // urlBrowsing v2 migration
      if (draft.selected.segment.targets.urlBrowsing) {
        draft.selected.segment.targets = {
          ...draft.selected.segment.targets,
          urlBrowsing: {
            ...urlBrowsingMigrations(
              draft.selected.segment.targets.urlBrowsing,
            ),
          },
        };
      }

      draft.selected.status = ReduxStatus.success;
      return;
    }
    case SET_SELECTED_SEGMENT_ERROR: {
      draft.selected.status = ReduxStatus.errored;
      return;
    }
    case SET_SELECTED_SEGMENT_AS_SMART_MODE: {
      const { payload } = action as SetSelectedSegmentAsSmartModeAction;
      const { status } = payload;
      draft.selected.isSmartMode = status;
      return;
    }
    case RESET_SELECTED_SEGMENT: {
      draft.selected = initialState.selected;
      return;
    }
    case UPDATE_CHANGE_VIEW_VISIBILITY_SUCCESS: {
      const { payload } = action as UpdateChangeViewVisibilitySuccessAction;
      const { isVisible } = payload;

      draft.isChangeViewVisible = isVisible;
      return;
    }
    //#endregion
    case RESET:
    case RESET_STORE: {
      return initialState;
    }
    default: {
      return;
    }
  }
}, initialState);

export default reducer;
