import { Provider } from 'react-redux';
import { wrapper } from 'src/store';

//TODO - unknown type
const withRedux = (PageComponent: any) => {
  const WithRedux = ({ ...rest }) => {
    const { store, props } = wrapper.useWrappedStore(rest);
    return (
      <Provider store={store}>
        <PageComponent {...props} />
      </Provider>
    );
  };
  return WithRedux;
};

export default withRedux;
