import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';

import {
  REDIRECT_STRIPE_REQUEST,
  REDIRECT_STRIPE_SUCCESS,
  REDIRECT_STRIPE_FAILURE,
  CREATE_PORTAL_REQUEST,
  CREATE_PORTAL_SUCCESS,
  CREATE_PORTAL_FAILURE,
} from './constants';

const initialState: any = {
  status: 'initialized',
  url: undefined,
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case REDIRECT_STRIPE_REQUEST:
      return { ...state, status: 'loading' };
    case CREATE_PORTAL_REQUEST:
      return { ...state, status: 'loading' };

    case REDIRECT_STRIPE_SUCCESS:
      return { ...action.payload, status: 'success' };
    case CREATE_PORTAL_SUCCESS:
      return { ...action.payload, status: 'success' };

    case REDIRECT_STRIPE_FAILURE:
      return { ...state, status: 'errored' };
    case CREATE_PORTAL_FAILURE:
      return { ...state, status: 'errored' };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
