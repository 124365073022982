import {
  DripInput,
  DripIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/DripResolver.types';

export const GetDripIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetDripIntegrationsByCampaignId',
  query: `query GetDripIntegrationsByCampaignId($campaignId: Int!) {
    getDripIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integration {
        apiKey
        id
        accountId
        name
        status
      }
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetDripIntegrationsByAccountId = () => ({
  operationName: 'GetDripIntegrationsByAccountId',
  query: `query GetDripIntegrationsByAccountId {
    getDripIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
      }
      apiKey
      account
    }
  }`,
});

export const GetDripFields = (
  account: string,
  apiKey: string,
  password: string,
) => ({
  operationName: 'GetDripFields',
  query: `query GetDripFields($account: String!, $apiKey: String!, $password: String!) {
    getDripFields(account: $account, apiKey: $apiKey, password: $password) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    account,
    apiKey,
    password,
  },
});
export const CreateDrip = (input: DripInput) => ({
  operationName: 'CreateDripIntegration',
  query: `mutation CreateDripIntegration($input: DripInput!) {
        createDripIntegration(input: $input)
      }`,
  variables: {
    input,
  },
});

export const UpdateDrip = (
  id: number,
  input: DripIntegrationCampaignInput,
) => ({
  operationName: 'UpdateDripIntegrationCampaign',
  query: `mutation UpdateDripIntegrationCampaign($input: DripIntegrationCampaignInput!, $updateDripIntegrationCampaignId: Int!) {
    updateDripIntegrationCampaign(input: $input, id: $updateDripIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateDripIntegrationCampaignId: id,
  },
});

export const DeleteDripIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteDripIntegrationCampaign',
  query: `mutation DeleteDripIntegrationCampaign($deleteDripIntegrationCampaignId: Int!) {
    deleteDripIntegrationCampaign(id: $deleteDripIntegrationCampaignId)
  }`,
  variables: {
    deleteDripIntegrationCampaignId: id,
  },
});
