import React, {
  ChangeEvent,
  FC,
  FocusEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import Select, {
  components,
  GroupBase,
  MenuProps,
  MultiValue,
  SingleValue,
  StylesConfig,
  ValueContainerProps,
} from 'react-select';
import cn from 'classnames';
import ErrorIcon from '@assets/icons/error-icon.svg';
import type { CustomOptionProps, PSMultiSelectboxProps, Option } from './types';

import styles from './MultiSelectbox.module.scss';
import clone from 'lodash/clone';

//Value container inspired from:
//https://codesandbox.io/s/react-select-custom-multi-value-messages-7o2gn?file=/src/MySelect.tsx
const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps<Option, boolean>) => {
  const itemName = 'item';

  const { getValue } = props;
  //cloneDeep breaks the app, use clone
  const newChildren: any = clone(children);
  const value = getValue();
  const selectedCount = value.length;
  if (newChildren) {
    newChildren[0] = 'Select';
  }
  if (selectedCount === 1 && value[0].value === 'select_all') {
    newChildren[0] = `All selected`;
  } else if (selectedCount > 0) {
    newChildren[0] = `${selectedCount} ${itemName}s selected`;
  }

  return (
    <components.ValueContainer {...props}>
      {newChildren}
    </components.ValueContainer>
  );
};

const PSMultiSelectbox: FC<PSMultiSelectboxProps> = ({
  id,
  label,
  placeholder,
  options,
  field: { value, name, onChange },
  fieldState: { error },
  menuPosition = 'absolute',
  maxMenuHeight,
  disabled,
  className,
  elementSize = 'md',
  onChangeEvent,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    if (value && value !== '' && value !== null) {
      onChangeEvent && onChangeEvent(value);
    }
  }, []);

  const customStyle: StylesConfig<Option, boolean, GroupBase<Option>> = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#F5F7F8' : 'white',
      color: state.isSelected ? '#000' : '#717791',
      ':hover': {
        backgroundColor: '#F5F7F8',
      },
    }),
  };
  //TODO: define type
  const handleChange = (event: MultiValue<Option> | SingleValue<Option>) => {
    onChange(event);
    onChangeEvent && onChangeEvent(event);
  };

  const onBlurSelect: FocusEventHandler<HTMLInputElement> = (): void => {
    setStatus('blur');
  };

  const onFocusSelect: FocusEventHandler<HTMLInputElement> = (): void => {
    setStatus('focus');
  };

  const onClickSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      handleChange(options);
    } else {
      handleChange([]);
    }
    setSelectAll(!selectAll);
  };

  const onClickClear = (props: MenuProps<Option>) => {
    props.clearValue();
    setSelectAll(false);
  };
  const Option = (props: CustomOptionProps): JSX.Element => {
    return (
      <components.Option {...props}>
        <div className={styles.multipleSelectOption}>
          <div className={styles.checkboxWrapper}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              id={props.value}
            />
          </div>

          <label className={styles.label} htmlFor={props.value}>
            {props.label}
          </label>
        </div>
      </components.Option>
    );
  };

  const Menu = (props: MenuProps<Option>) => {
    return (
      <components.Menu {...props}>
        <div className={styles.menuListContainer}>
          <div className={styles.selectAllContainer}>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={value.length === options.length}
                onChange={onClickSelectAll}
                id="selectAll"
              />
              <label className={styles.label} htmlFor="selectAll">
                Select All
              </label>
            </div>
          </div>
          <hr className={styles.divider} />
          {props.children}
          <div className={styles.footer}>
            <hr className={styles.divider} />
            <div className={styles.buttonContainer}>
              <div
                className={styles.clearAll}
                onClick={() => onClickClear(props)}
              >
                Clear All
              </div>
              <div
                className={styles.close}
                onClick={() => setIsMenuOpen(false)}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </components.Menu>
    );
  };
  const selectRef = useRef(null);
  const [shouldBePlacedOnTopInstead, setShouldBePlacedOnTopInstead] =
    useState(false);

  useEffect(() => {
    const element = selectRef.current as unknown as {
      inputRef: HTMLInputElement;
    };
    if (!maxMenuHeight) return;
    if (selectRef?.current) {
      const rect = element.inputRef.getBoundingClientRect();
      if (rect.bottom + maxMenuHeight + 150 > window.innerHeight) {
        setShouldBePlacedOnTopInstead(true);
      } else {
        setShouldBePlacedOnTopInstead(false);
      }
    }
  }, [isMenuOpen, maxMenuHeight]);

  return (
    <div
      className={cn(styles.selectbox_wrapper, className, {
        [styles.label_select]: label,
        [styles.disabled]: disabled,
        [styles.small]: elementSize === 'sm',
      })}
    >
      <Select<Option, boolean>
        ref={selectRef}
        instanceId="select"
        classNamePrefix="pps-select"
        name={name}
        placeholder={label || placeholder}
        className={cn(styles.select, {
          [styles.menu_open]: isMenuOpen,
          [styles.error]: !!error,
          [styles.multiWithCheckbox]: true,
        })}
        options={options}
        styles={customStyle}
        menuPlacement={shouldBePlacedOnTopInstead ? 'top' : 'auto'}
        menuPosition={menuPosition}
        onBlur={onBlurSelect}
        onFocus={onFocusSelect}
        onChange={handleChange}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        menuIsOpen={isMenuOpen}
        isDisabled={disabled}
        value={value}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        components={{
          Option,
          ValueContainer: CustomValueContainer,
          Menu,
        }}
        {...(maxMenuHeight && { maxMenuHeight })}
      />
      {label && (
        <label
          htmlFor={id}
          className={cn(styles.label, {
            [styles.focus]: status === 'focus',
            [styles.label_top]:
              placeholder !== '' ||
              (value && value !== null) ||
              (value && Array.isArray(value)
                ? value.length > 0
                : value !== undefined) ||
              (value !== '' && value !== null),
          })}
        >
          {label}
        </label>
      )}

      {!!error && (
        <div className={styles.errorContainer}>
          <ErrorIcon width="18px" height="18px" className={styles.icon} />
          <p className={styles.message}>{error?.message || 'Invalid'}</p>
        </div>
      )}
    </div>
  );
};

export default PSMultiSelectbox;
