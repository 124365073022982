import { TemplatesListViewProps } from './types';
import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';
import { TemplateThumbnail } from 'ui';
import cn from 'classnames';

import styles from './TemplatesListView.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import SadFaceIcon from '@assets/icons/sad_face.svg';

const TemplatesListView: FC<TemplatesListViewProps> = ({
  selectedTemplate,
  templates,
  isLoading,
  handleDetailTemplate,
  handleSelectTemplate,
  contentClassName,
  isIconsHidden = false,
}) => {
  return (
    <>
      <div className={cn(styles.wrapper, contentClassName)}>
        {isLoading && (
          <div className={styles.gridWrapper}>
            {Array.from(Array(8).keys()).map((index) => {
              return (
                <div className={styles.card} key={index}>
                  <div className="w-full">
                    <Skeleton
                      height={155}
                      count={1}
                      baseColor="#f5f5f5"
                      highlightColor="#fefefe"
                      borderRadius={6}
                      className="mb-[11px] !h-full sm:aspect-[3/2]"
                    />
                    <Skeleton
                      height={20}
                      count={1}
                      baseColor="#f5f5f5"
                      highlightColor="#fefefe"
                      borderRadius={6}
                      className="mb-[2px]"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!isLoading && templates?.length <= 0 && (
          <div className="relative col-span-full">
            <div className="flex flex-col items-center w-100 border border-grayborder rounded-lg py-[60px]">
              <SadFaceIcon />
              <div className="text-lg leading-6 font-semibold mt-4">Oops</div>
              <div className="text-[#777777] text-xs leading-xl mt-[5px]">
                No results found.
              </div>
            </div>
          </div>
        )}

        {!isLoading && templates?.length > 0 && (
          <div className={styles.gridWrapper}>
            {selectedTemplate ? (
              <TemplateThumbnail
                key={selectedTemplate.id}
                template={selectedTemplate}
                isSelected={true}
              />
            ) : null}

            {templates && !!templates?.length
              ? templates?.map((template) => (
                  <TemplateThumbnail
                    key={template.id}
                    handleDetailTemplate={handleDetailTemplate}
                    handleSelectTemplate={handleSelectTemplate}
                    template={template}
                    isIconsHidden={isIconsHidden}
                  />
                ))
              : null}
          </div>
        )}
      </div>
    </>
  );
};

export default TemplatesListView;
