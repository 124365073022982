import { RootState } from '@connectors/reducers';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectModal = (state: RootState) => state.modal || initialState;

const makeSelectModalStatus = createSelector(
  selectModal,
  (modalState) => modalState.status,
);
const makeSelectModals = createSelector(
  selectModal,
  (modalState) => modalState.modals,
);

const makeSelectModalById = (id: string) =>
  createSelector(selectModal, (modalState) =>
    modalState.modals.find((modal) => modal.id === id),
  );

export { makeSelectModalStatus, makeSelectModals, makeSelectModalById };
