export const mailerliteServiceURl = 'api/integrations/mailerlite';

export const MAILERLITE_GET_CONNECTIONS =
  'builder/integrations/MAILERLITE_GET_CONNECTIONS';
export const MAILERLITE_GET_CONNECTIONS_SUCCESS =
  'builder/integrations/MAILERLITE_GET_CONNECTIONS_SUCCESS';
export const MAILERLITE_GET_CONNECTIONS_FAILURE =
  'builder/integrations/MAILERLITE_GET_CONNECTIONS_FAILURE';

export const MAILERLITE_GET_FIELDS =
  'builder/integrations/MAILERLITE_GET_FIELDS';
export const MAILERLITE_GET_FIELDS_SUCCESS =
  'builder/integrations/MAILERLITE_GET_FIELDS_SUCCESS';
export const MAILERLITE_GET_FIELDS_FAILURE =
  'builder/integrations/MAILERLITE_GET_FIELDS_FAILURE';

export const MAILERLITE_GET_LIST = 'builder/integrations/MAILERLITE_GET_LIST';
export const MAILERLITE_GET_LIST_SUCCESS =
  'builder/integrations/MAILERLITE_GET_LIST_SUCCESS';
export const MAILERLITE_GET_LIST_FAILURE =
  'builder/integrations/MAILERLITE_GET_LIST_FAILURE';

export const MAILERLITE_SAVE = 'builder/integrations/MAILERLITE_SAVE';
export const MAILERLITE_SAVE_SUCCESS =
  'builder/integrations/MAILERLITE_SAVE_SUCCESS';
export const MAILERLITE_SAVE_FAILURE =
  'builder/integrations/MAILERLITE_SAVE_FAILURE';

export const MAILERLITE_DELETE = 'builder/integrations/MAILERLITE_DELETE';
export const MAILERLITE_DELETE_SUCCESS =
  'builder/integrations/MAILERLITE_DELETE_SUCCESS';
export const MAILERLITE_DELETE_FAILURE =
  'builder/integrations/MAILERLITE_DELETE_FAILURE';

export const MAILERLITE_CHANGESTATUS =
  'builder/integrations/MAILERLITE_CHANGESTATUS';
export const MAILERLITE_CHANGESTATUS_SUCCESS =
  'builder/integrations/MAILERLITE_CHANGESTATUS_SUCCESS';
export const MAILERLITE_CHANGESTATUS_FAILURE =
  'builder/integrations/MAILERLITE_CHANGESTATUS_FAILURE';

export const MAILERLITE_CLEAR_STATE =
  'builder/integrations/MAILERLITE_CLEAR_STATE';
