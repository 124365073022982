import type { Targets } from '@connectors/targets/reducer';
import React from 'react';

import TimelineContent from '../TimelineContent';

import DeviceIcon from '@assets/icons/device.svg';

export const renderVisitorDevices = (
  visitorDevice: Targets['visitorDevice'],
  translate: Function,
) => {
  if (!visitorDevice?.desktop && !visitorDevice?.mobile) {
    return (
      <>
        <TimelineContent
          icon={<DeviceIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate(
            'sections:targeting-summary-visitor-device:noSelectedDeviceMessage',
          )}
          alert
        />
      </>
    );
  }

  return (
    <>
      <TimelineContent
        icon={<DeviceIcon width="16" height="16" viewBox="0 0 36 36" />}
        label={
          <>
            {`${translate(
              'sections:targeting-summary-visitor-device:message',
            )} ${
              visitorDevice?.desktop ? `${translate('common:desktop')}, ` : ''
            } ${
              visitorDevice?.mobile ? `${translate('common:mobile')}, ` : ''
            }`}
          </>
        }
      />
    </>
  );
};
