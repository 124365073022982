import React, { FC } from 'react';
import { FormProps } from './types';

const PSForm: FC<FormProps> = ({ onSubmit, children, className }) => {
  return (
    <form onSubmit={onSubmit} className={className} noValidate>
      {children}
    </form>
  );
};

export default PSForm;
