import {
  HubSpotInput,
  HubSpotIntegrationCampaignInput,
  TokenInput,
} from 'api-core/modules/integrations/resolvers/HubSpotResolver.types';

export const SaveHubSpotConnection = (input: TokenInput) => ({
  operationName: 'SaveHubSpotConnection',
  query: `mutation SaveHubSpotConnection($input: TokenInput!) {
    saveHubSpotConnection(input: $input) {
      accountId
      apiKey
      id
      name
      refreshToken
      status
      token
      tokenExpireDate
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
        name
      }
    }
  }`,
  variables: {
    input,
  },
});

export const GetHubSpotIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetHubSpotIntegrationsByCampaignId',
  query: `query GetHubSpotIntegrationsByCampaignId($campaignId: Int!) {
    getHubSpotIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
      name
      integration {
        apiKey
        id
        accountId
        name
        status
      }
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetHubSpotIntegrationsByAccountId = () => ({
  operationName: 'GetHubSpotIntegrationsByAccountId',
  query: `query GetHubSpotIntegrationsByAccountId {
        getHubSpotIntegrationsByAccountId {
          id
          accountId
          name
          status
          integrationCampaigns {
            id
            campaignId
            integrationId
            status
            integrationFields {
              id
              integrationCampaignId
              popupsmartFieldName
              integratorFieldName
              integratorFieldId
              integratorTag
              fieldType
              isCustomerEmail
            }
            listId
            name
          }
          apiKey
          token
        }
      }`,
});
export const GetHubSpotLists = (apiKey: string, getHubSpotListsId: number) => ({
  operationName: 'GetHubSpotLists',
  query: `query GetHubSpotLists($apiKey: String!, $getHubSpotListsId: Int!) {
    getHubSpotLists(apiKey: $apiKey, id: $getHubSpotListsId) {
      id
      listId
      name
      listType
    }
  }`,
  variables: {
    apiKey,
    getHubSpotListsId,
  },
});
export const GetHubSpotFields = () => ({
  operationName: 'GetHubSpotFields',
  query: `query GetHubSpotFields($getHubSpotFieldsId: Int) {
    getHubSpotFields(id: $getHubSpotFieldsId) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
});
export const CreateHubSpot = (input: HubSpotInput) => ({
  operationName: 'CreateHubSpotIntegration',
  query: `mutation CreateHubSpotIntegration($input: HubSpotInput!) {
        createHubSpotIntegration(input: $input)
      }`,
  variables: {
    input,
  },
});

export const UpdateHubSpot = (
  id: number,
  input: HubSpotIntegrationCampaignInput,
) => ({
  operationName: 'UpdateHubSpotIntegrationCampaign',
  query: `mutation UpdateHubSpotIntegrationCampaign($input: HubSpotIntegrationCampaignInput!, $updateHubSpotIntegrationCampaignId: Int!) {
    updateHubSpotIntegrationCampaign(input: $input, id: $updateHubSpotIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateHubSpotIntegrationCampaignId: id,
  },
});

export const DeleteHubSpotIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteHubSpotIntegrationCampaign',
  query: `mutation DeleteHubSpotIntegrationCampaign($deleteHubSpotIntegrationCampaignId: Int!) {
    deleteHubSpotIntegrationCampaign(id: $deleteHubSpotIntegrationCampaignId)
  }`,
  variables: {
    deleteHubSpotIntegrationCampaignId: id,
  },
});
