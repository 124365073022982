import { useEffect, FC, CSSProperties } from 'react';
import Select, {
  DropdownIndicatorProps,
  GroupBase,
  OnChangeValue,
  StylesConfig,
  components,
} from 'react-select';
import cn from 'classnames';

import DropdownIndicatorIcon from '@assets/icons/input-dropdown.svg';
import ErrorIcon from '@assets/icons/error-icon.svg';
import type { Option, PSSelectboxProps } from './types';

import styles from './Selectbox.module.scss';
import Skeleton from 'react-loading-skeleton';

const PSSelectbox: FC<PSSelectboxProps> = ({
  id,
  label,
  placeholder,
  options,
  field: { value, name, onChange },
  fieldState: { error },
  menuPosition = 'absolute',
  disabled,
  className,
  isIntegration,
  elementSize = 'md',
  styleType = 'default',
  selectComponent,
  onChangeEvent,
  noOptionsMessage,
  isSearchable,
  initialRenderOnChangeEvent = true,
  dropdownWidth = 'unset',
  noCloseMenuOnScroll,
  loading = false,
  minHeight = '48px',
}) => {
  useEffect(() => {
    if (initialRenderOnChangeEvent && value && value !== '' && value !== null) {
      onChangeEvent && onChangeEvent(value);
    }
  }, []);

  const defaultCustomStyle: StylesConfig<Option> = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#F5F7F8' : 'white',
      color: state.isSelected ? '#000' : '#717791',
      ':hover': {
        backgroundColor: '#F5F7F8',
      },
    }),
    control: (provided) => ({
      ...provided,
      minHeight: minHeight,
    }),
  };
  const integrationCustomStyle: StylesConfig<Option> = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#F5F7F8' : 'white',
      color: state.isSelected ? '#000' : '#717791',
      ':hover': {
        backgroundColor: '#F5F7F8',
      },
    }),
    groupHeading: (base) => ({
      ...base,
      color: '#000',
      fontSize: '12px',
      fontWeight: 500,
    }),
  };

  const selectStyles = isIntegration
    ? integrationCustomStyle
    : defaultCustomStyle;

  const handleChange = (event: OnChangeValue<Option, false>) => {
    onChange(event?.value);
    onChangeEvent && onChangeEvent(event?.value);
  };

  const closeMenu = (e: Event): boolean => {
    if (noCloseMenuOnScroll) return false;

    if (
      e.target &&
      !(e.target as HTMLElement)?.classList?.contains('pps-select__menu-list')
    )
      return true;

    return false;
  };

  const arrangedOptions: Option[] = [];
  options.forEach((option: Option) => {
    if (option?.value !== undefined) {
      arrangedOptions.push(option);
    }
    if (option?.options) {
      arrangedOptions.push(...option.options);
    }
  });

  const DropdownIndicator = (
    props: DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <div
          className={`${styles.dropdownIndicatorContainer} ${
            props.selectProps.menuIsOpen
              ? styles.dropdownIndicatorOpenAnimation
              : ''
          }`}
        >
          <DropdownIndicatorIcon width="16" height="8" viewBox="0 0 14 6" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const loadingSkeleton = () => {
    return (
      <div className="flex flex-col">
        <Skeleton
          height={18}
          count={1}
          baseColor="#f5f5f5"
          highlightColor="#fefefe"
          borderRadius={6}
          width={65}
        />
        <Skeleton
          height={38}
          count={1}
          baseColor="#f5f5f5"
          highlightColor="#fefefe"
          borderRadius={6}
          width={400}
        />
      </div>
    );
  };
  return (
    <div
      className={cn(styles.selectbox_wrapper, className, {
        [styles.label_select]: label,
        [styles.disabled]: disabled,
        [styles.small]: elementSize === 'sm',
        [styles.medium]: elementSize === 'md',
        [styles.targeting_style]: styleType === 'targeting',
      })}
      style={
        {
          '--dropdownWidth': dropdownWidth,
        } as CSSProperties
      }
    >
      {loading ? (
        loadingSkeleton()
      ) : (
        <>
          {label && (
            <label className={styles.label} htmlFor={name}>
              {label}
            </label>
          )}
          <Select<Option>
            id={id}
            instanceId="select"
            classNamePrefix="pps-select"
            name={name}
            placeholder={label || placeholder}
            className={cn(styles.select, {
              [styles.error]: !!error,
            })}
            options={options}
            styles={selectStyles}
            menuPlacement="auto"
            menuPosition={menuPosition}
            onChange={handleChange}
            isDisabled={disabled}
            value={
              arrangedOptions.find(
                (option: Option) => option.value === value,
              ) || null
            }
            defaultValue={arrangedOptions.find(
              (option: Option) => option.value === value,
            )}
            // @ts-ignore
            components={{ selectComponent, DropdownIndicator }}
            noOptionsMessage={() => noOptionsMessage || 'No options'}
            closeMenuOnSelect={true}
            closeMenuOnScroll={closeMenu}
            isSearchable={isSearchable}
          />
        </>
      )}
      {!!error && (
        <div
          className={`${styles.errorContainer} selectBoxErrorContainer`}
          data-testid="textfield-error"
        >
          <>
            <ErrorIcon width="18px" height="18px" className={styles.icon} />
            <p className={styles.message}>{error?.message || 'Invalid'}</p>
          </>
        </div>
      )}
    </div>
  );
};

export default PSSelectbox;
