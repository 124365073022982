import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REDIRECT_STRIPE_REQUEST,
  REDIRECT_STRIPE_SUCCESS,
  REDIRECT_STRIPE_FAILURE,
  CREATE_PORTAL_REQUEST,
  CREATE_PORTAL_SUCCESS,
  CREATE_PORTAL_FAILURE,
} from './constants';

import fetch from '@utils/fetch';

interface IStripeResponse {
  url?: string;
}

function* redirectStripeSaga({ payload }: ReduxAction) {
  const priceId = payload;
  try {
    const data: IStripeResponse = yield call(
      fetch,
      `/api/Stripe/CreateSessionId/${priceId}`,
      {
        method: 'GET',
        data: payload,
      },
    );

    if (data.url) {
      yield put({ type: REDIRECT_STRIPE_SUCCESS, payload: data });
    } else {
      throw new Error(`Couldn't redirect to stripe.`);
    }
  } catch (error) {
    yield put({ type: REDIRECT_STRIPE_FAILURE, payload: error });
  }
}

function* createPortalSaga() {
  try {
    const data: IStripeResponse = yield call(
      fetch,
      `/api/Stripe/CreatePortalLink`,
      {
        method: 'GET',
      },
    );

    if (data.url) {
      yield put({ type: CREATE_PORTAL_SUCCESS, payload: data });
    } else {
      throw new Error(`Couldn't redirect to stripe.`);
    }
  } catch (error) {
    yield put({ type: CREATE_PORTAL_FAILURE, payload: error });
  }
}

export default function* sagas() {
  yield takeLatest(REDIRECT_STRIPE_REQUEST, redirectStripeSaga);
  yield takeLatest(CREATE_PORTAL_REQUEST, createPortalSaga);
}
