/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import Loading from '@components/Loading';
import { useRouter } from 'next/router';

import { useRouter as useRouterHooks } from './next';

const LoadingContext = createContext<{
  loading: boolean;
  showLoading: () => void;
  hideLoading: (skipRouting?: boolean) => void;
}>({
  loading: false,
  showLoading: () => {},
  hideLoading: () => {},
});

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export const LoadingProvider: FC<Props> = ({ children }) => {
  const router = useRouter();
  const isRouting = useRouterHooks(router);

  const [loading, setLoading] = useState(false);
  const showLoading = () => !loading && setLoading(true);
  const hideLoading = () => !isRouting && setLoading(false);

  const value = { loading, showLoading, hideLoading };

  return (
    <LoadingContext.Provider value={value}>
      {loading && <Loading />}
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }

  return context;
}
