import { fork } from 'redux-saga/effects';

import { default as analyticsSagas } from './analytics/saga';
import { default as authSagas } from './auth/saga';
import { default as campaignSagas } from './campaign/saga';
import { default as campaignsSagas } from './campaigns/saga';
import { default as fontsSagas } from './fonts/saga';
import { default as websitesSagas } from './websites/saga';
import { default as stripeSagas } from './stripe/saga';
import { default as integrationSagas } from './integration/sagas';
import { default as accountsSagas } from './accounts/saga';
import { default as leadEmailsSagas } from './leadEmails/saga';
import { default as templatesSagas } from './templates/saga';
import { default as subscribersSagas } from './subscribers/saga';
import { default as autoResponderSagas } from './autoResponder/saga';
import { default as locationsSagas } from './locations/saga';
import { default as productsSagas } from './products/saga';
import { default as targetsSagas } from './targets/saga';
import { default as builderSagas } from './builder/saga';
import { default as segmentSagas } from './segment/saga';

export default function* all() {
  yield fork(analyticsSagas);
  yield fork(authSagas);
  yield fork(campaignSagas);
  yield fork(campaignsSagas);
  yield fork(fontsSagas);
  yield fork(websitesSagas);
  yield fork(stripeSagas);
  yield fork(integrationSagas);
  yield fork(accountsSagas);
  yield fork(leadEmailsSagas);
  yield fork(templatesSagas);
  yield fork(subscribersSagas);
  yield fork(autoResponderSagas);
  yield fork(locationsSagas);
  yield fork(productsSagas);
  yield fork(targetsSagas);
  yield fork(builderSagas);
  yield fork(segmentSagas);
}
