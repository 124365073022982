import { fork } from 'redux-saga/effects';

import { default as activeCampaignSagas } from './activeCampaign/saga';
import { default as customerIoSagas } from './customerIo/saga';
import { default as dripSagas } from './drip/saga';
import { default as emailOctopusSagas } from './emailOctopus/saga';
import { default as hubspotSagas } from './hubSpot/saga';
import { default as klaviyoSagas } from './klaviyo/saga';
import { default as mailchimpSagas } from './mailchimp/saga';
import { default as mailerliteSagas } from './mailerlite/saga';
import { default as sendGridSagas } from './sendgrid/saga';
import { default as sendinblueSagas } from './sendinblue/saga';
import { default as sendloopSagas } from './sendloop/saga';
import { default as webhookSagas } from './webhook/saga';

export default function* all() {
  yield fork(activeCampaignSagas);
  yield fork(customerIoSagas);
  yield fork(dripSagas);
  yield fork(emailOctopusSagas);
  yield fork(hubspotSagas);
  yield fork(klaviyoSagas);
  yield fork(mailchimpSagas);
  yield fork(mailerliteSagas);
  yield fork(sendGridSagas);
  yield fork(sendinblueSagas);
  yield fork(sendloopSagas);
  yield fork(webhookSagas);
}
