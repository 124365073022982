import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import TrafficSourceIcon from '@assets/icons/traffic_source.svg';

export const renderTrafficSource = (
  trafficSource: Targets['trafficSource'],
  translate: Function,
) => {
  const getContains = (contains: string) => {
    switch (contains) {
      case 'CONTAINS':
        return translate('common:contains');
      case 'DOES_NOT_CONTAIN':
        return translate('common:doesNotContain');
      default:
        return contains;
    }
  };
  if (trafficSource && !!trafficSource.length) {
    return (
      <>
        <TimelineContent
          icon={
            <TrafficSourceIcon width="16" height="16" viewBox="0 0 36 36" />
          }
          label={`${translate(
            'sections:targeting-summary-traffic-source:message',
          )}`}
        >
          {trafficSource.map((source, index) => (
            <span className="flex items-start font-inter text-xs" key={index}>
              {index > 0 && (
                <span className="font-semibold text-bluesmart mr-1">
                  {translate('common:or').toUpperCase()}
                </span>
              )}
              {` ${getContains(source.contains)}: ${source.path}`}
            </span>
          ))}
        </TimelineContent>
      </>
    );
  }
};
