import { containerNodeNameList, formNodeNameList } from './StaticVariables';

//this any because not defined type yet
export const fieldListGenerator = (list: any[]) => {
  let fields: any[] = [];
  const elementNodeList = [...formNodeNameList, 'coupon-code'];

  list?.forEach((element) => {
    if (element.content?.desktop.childNodes) {
      fields = [
        ...fields,
        ...fieldListGenerator(element.content.desktop.childNodes),
      ];
    } else if (
      element.nodeName &&
      containerNodeNameList.indexOf(element.nodeName) > -1
    ) {
      fields = [...fields, ...fieldListGenerator(element.childNodes)];
    } else if (
      element.attributes &&
      element.attributes.name &&
      elementNodeList.indexOf(element.nodeName) > -1
    ) {
      fields.push({
        label: element.attributes.name,
        value: element.attributes.name,
        nodeName: element.nodeName,
      });
    }
  });
  return fields;
};
