import { FC } from 'react';
import styles from './themeItem.module.scss';
import { ThemeItemProps } from './types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ThemeItem: FC<ThemeItemProps> = ({
  name,
  fontFamily,
  fontColor,
  fontFamilyColor,
  image,
  code,
  clickable = true,
  size = 'sm',
  onClick,
}) => {
  const { t } = useTranslation();
  const handleClickTheme = () => {
    onClick && onClick(code);
  };

  return (
    <div
      className={cn(
        styles.themeItemContainer,
        size === 'md' ? styles.mediumSize : '',
      )}
      onClick={handleClickTheme}
    >
      <div
        className={styles.theme}
        style={{
          backgroundImage: `url('${image}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
        }}
      >
        <div
          className={styles.name}
          style={{ fontFamily: fontFamily[0], color: fontColor }}
        >
          {name}
        </div>
        <div className={styles.fontFamily} style={{ color: fontFamilyColor }}>
          {fontFamily.join(', ')}
        </div>
      </div>
      {clickable && (
        <div className={styles.selectThemeButtonWrapper}>
          <button className={styles.selectTheme}>{t('common:preview')}</button>
        </div>
      )}
    </div>
  );
};

export default ThemeItem;
