import { FC } from 'react';
import styles from './VerifyModalBottom.module.scss';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '@connectors/modal/actions';
import { VerifyModalBottomProps } from './types';
import SupportIcon from '@assets/icons/support.svg';
import EmailIcon from '@assets/icons/email-icon-verify.svg';
import { useTranslation } from 'react-i18next';

const VerifyModalBottom: FC<VerifyModalBottomProps> = ({
  url,
  areaIconOne,
  areaTextOne,
  areaLinkOne,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickSupport = () => {
    window.lc?.showMessenger?.();
  };

  const sendCodeToDeveloperHandler = () => {
    dispatch(hideModal('NoVerifiedWebsiteModal'));
    dispatch(
      showModal({
        id: 'SendCodeToDeveloperModal',
        data: { fromNoVerifiedWebsiteModal: true, url: url || null },
      }),
    );
  };

  return (
    <div className={styles.bottomContainer}>
      <div className="flex w-[100%] sm:w-[30%] gap-[10px]">
        <span className="w-[18px]">{areaIconOne}</span>
        <a
          href={areaLinkOne}
          className="text-xs leading-[18px] hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          {areaTextOne}
        </a>
      </div>

      <button
        onClick={handleClickSupport}
        className="flex w-[100%] sm:w-[30%] text-left hover:underline gap-[10px]"
      >
        <span className="w-[18px]">
          <SupportIcon />
        </span>
        <a className="text-xs leading-[18px]">
          {t('sections:unverified-modal:footerLiveSupport')}
        </a>
      </button>
      <button
        onClick={sendCodeToDeveloperHandler}
        className="flex w-[100%] sm:w-[30%] text-left hover:underline gap-[10px]"
      >
        <span className="w-[18px]">
          <EmailIcon width="18" height="18" viewBox="0 0 18 18" />
        </span>
        <a className="text-xs leading-[18px]">
          {t('sections:unverified-modal:footerSendCode')}
        </a>
      </button>
    </div>
  );
};

export default VerifyModalBottom;
