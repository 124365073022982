export const GOOGLE_FONTS_GET = 'GOOGLE_FONTS_GET';
export const GOOGLE_FONTS_GET_SUCCESS = 'GOOGLE_FONTS_GET_SUCCESS';

export const ADD_FONT_TO_SELECTBOX = 'ADD_FONT_TO_SELECTBOX';
export const ADD_FONT_TO_SELECTBOX_SUCCESS = 'ADD_FONT_TO_SELECTBOX_SUCCESS';

export const FONTS_MODAL_SHOW = 'FONTS_MODAL_SHOW';
export const FONTS_MODAL_SHOW_SUCCESS = 'FONTS_MODAL_SHOW_SUCCESS';

export const FONTS_MODAL_HIDE = 'FONTS_MODAL_HIDE';
export const FONTS_MODAL_HIDE_SUCCESS = 'FONTS_MODAL_HIDE_SUCCESS';

export const FONTS_FAILURE = 'FONTS_FAILURE';
