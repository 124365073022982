import { FC } from 'react';

import ArrowIcon from '@assets/icons/down-icon.svg';
import styles from './Accordion.module.scss';
import { PSAccordionProps } from './types';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

const PSAccordion: FC<PSAccordionProps> = ({
  id,
  title,
  titleTemplate,
  children,
  isActive,
  arrowOnTop = false,
  arrowHiddenOnMobile = false,
  className = '',
  contentClassName,
  size = 'lg',
  onClick,
  closable = true,
}) => {
  const handleOnClick = () => {
    if (closable) {
      isActive ? onClick(null) : onClick(id);
    }
  };
  return (
    <div
      className={cn(
        {
          [styles.accordion]: true,
          [styles.active]: isActive,
          [styles.small]: size === 'sm',
          [styles.medium]: size === 'md',
          [styles.large]: size === 'lg',
        },
        className,
      )}
      id={id}
    >
      <div
        className={cn(
          styles.header,
          {
            [styles.arrowOnTop]: arrowOnTop,
          },
          'cursor-pointer',
        )}
        onClick={handleOnClick}
      >
        {titleTemplate ? (
          titleTemplate
        ) : (
          <div className={styles.title}>{title}</div>
        )}
        {closable && (
          <div
            className={cn(styles.arrow, {
              [styles.arrowOnTop]: arrowOnTop,
              [styles.arrowHiddenOnMobile]: arrowHiddenOnMobile,
            })}
          >
            <ArrowIcon />
          </div>
        )}
      </div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            className={cn(
              'scrollbar',
              {
                [styles.content]: true,
                'active-content': isActive,
              },
              contentClassName,
            )}
            initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
            animate={{ height: 'auto', opacity: 1, overflow: 'unset' }}
            exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
            transition={{ duration: 0.3 }}
          >
            {isActive && children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PSAccordion;
