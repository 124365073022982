import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from './useAuth';

const useVerifyWebsiteWindow = () => {
  const waitForShowVerifyBadgeMs = 1700;

  const {
    auth: { accountId },
  } = useAuth();

  const [state, setState] = useState<{
    url: string;
    onClose: () => void;
  } | null>(null);

  useEffect(() => {
    if (!state) return;
    const { url, onClose } = state;

    const { availWidth } = window.screen;
    const { innerWidth, innerHeight } = window;

    const offset = (availWidth > 1440 ? 0.1 : 0.12) * innerHeight;
    const width = Math.min(innerWidth * 0.7, availWidth * 0.7);
    const height = Math.round((width / 16) * 9) - Math.round(offset);
    const left = Math.round((innerWidth - width) / 2);
    const top = Math.round((innerHeight - height) / 2) + Math.round(offset);

    const features = [
      `width=${width}`,
      `height=${height}`,
      `left=${left}`,
      `top=${top}`,
      'toolbar=no',
      'location=no',
      'directories=no',
      'status=no',
      'menubar=no',
    ].join(',');

    const verifyWindow = window.open(
      `http://${url}?ps-verify=${accountId}`,
      '_blank',
      features,
    );

    let timeoutId: ReturnType<typeof setTimeout>;

    type EventData = 'domain-verified' | 'domain-notverified';

    const handleCloseWindow = (eventMessage: EventData) => {
      timeoutId = setTimeout(() => {
        verifyWindow?.close();
        onClose();
        if (eventMessage === 'domain-notverified') {
          toast.error('Your website is not verified!');
        }
      }, waitForShowVerifyBadgeMs);
    };

    const handleMessage = (
      event: Omit<MessageEvent, 'data'> & { data: EventData },
    ) => {
      const isMessageFromUserDomain = event.origin.includes(url);
      const eventMessage = event.data;
      const isVerificationCompleted =
        eventMessage === 'domain-verified' ||
        eventMessage === 'domain-notverified';

      if (isVerificationCompleted && isMessageFromUserDomain) {
        handleCloseWindow(eventMessage);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      clearTimeout(timeoutId);
    };
  }, [state]);

  return {
    openVerifyWindow: ({
      url,
      onClose,
    }: {
      url: string;
      onClose: () => void;
    }) => {
      setState({ url, onClose });
    },
  };
};

export default useVerifyWebsiteWindow;
