import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  HUBSPOT_GET_FIELDS,
  HUBSPOT_GET_FIELDS_SUCCESS,
  HUBSPOT_GET_FIELDS_FAILURE,
  HUBSPOT_GET_CONNECTIONS,
  HUBSPOT_GET_CONNECTIONS_SUCCESS,
  HUBSPOT_GET_CONNECTIONS_FAILURE,
  HUBSPOT_GET_LIST,
  HUBSPOT_GET_LIST_SUCCESS,
  HUBSPOT_GET_LIST_FAILURE,
  HUBSPOT_SAVE,
  HUBSPOT_SAVE_SUCCESS,
  HUBSPOT_SAVE_FAILURE,
  HUBSPOT_DELETE,
  HUBSPOT_DELETE_FAILURE,
  HUBSPOT_CLEAR_STATE,
  HUBSPOT_SAVE_CONNECTION_SUCCESS,
  HUBSPOT_SAVE_CONNECTION_FAILURE,
  HUBSPOT_SAVE_CONNECTION,
} from './constants';
import { ApiConnectionObject, ApiFieldObject } from '../reducers';
import IntegrationEnums from '@enums/IntegrationEnums';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import graphqlFetch from '@utils/graphqlFetch';
import {
  CreateHubSpot,
  DeleteHubSpotIntegrationCampaign,
  GetHubSpotFields,
  GetHubSpotIntegrationsByAccountId,
  GetHubSpotIntegrationsByCampaignId,
  GetHubSpotLists,
  SaveHubSpotConnection,
  UpdateHubSpot,
} from './graphql';
import { HubSpotIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/HubSpotResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getHubSpotIntegrationsByAccountId: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetHubSpotIntegrationsByAccountId(),
    });

    yield put({
      type: HUBSPOT_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getHubSpotIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: HUBSPOT_GET_CONNECTIONS_FAILURE });
  }
}

function* handleSaveConnection(action: ReduxAction) {
  try {
    const { code, onSuccess } = action.payload;
    const data = {
      code,
      grantType: 0,
    };
    const result: {
      data: { saveHubSpotConnection: ApiConnectionObject };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: SaveHubSpotConnection(data),
    });

    yield put({
      type: HUBSPOT_SAVE_CONNECTION_SUCCESS,
      payload: result.data.saveHubSpotConnection,
    });
    onSuccess && onSuccess(result.data.saveHubSpotConnection.id);
  } catch (error) {
    yield put({ type: HUBSPOT_SAVE_CONNECTION_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { onSuccess } = action.payload;
  try {
    const result: {
      data: { getHubSpotFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetHubSpotFields(),
    });
    onSuccess && onSuccess();
    yield put({
      type: HUBSPOT_GET_FIELDS_SUCCESS,
      payload: result.data.getHubSpotFields,
    });
  } catch (error) {
    yield put({ type: HUBSPOT_GET_FIELDS_FAILURE });
  }
}
function* handleGetList(action: ReduxAction) {
  const { token, id, onSuccess } = action.payload;

  try {
    const result: {
      data: { getHubSpotLists: ApiConnectionObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetHubSpotLists(token, Number(id)),
    });

    onSuccess && onSuccess();
    yield put({
      type: HUBSPOT_GET_LIST_SUCCESS,
      payload: result.data.getHubSpotLists,
    });
  } catch (error) {
    yield put({ type: HUBSPOT_GET_LIST_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  const formData = {
    id: connectionSettings.id,
    name: connectionSettings.name,
    apiKey: connectionSettings.apiKey,
    token: connectionSettings.token,
    integrationCampaigns: fieldList
      ? [
          {
            name: listSettings.name,
            listId: listSettings.listId,
            campaignId,
            status: true,
            integrationId: connectionSettings.id,
            integrationFields: fieldList,
            id: 0,
          },
        ]
      : [],
  };
  try {
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateHubSpot(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateHubSpot({ ...formData, status: true }),
      });
    }
    const integrations: {
      data: {
        getHubSpotIntegrationsByCampaignId: HubSpotIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetHubSpotIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getHubSpotIntegrationsByCampaignId,
        type: IntegrationEnums.hubspot,
      }),
    );
    yield put({ type: HUBSPOT_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: HUBSPOT_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteHubSpotIntegrationCampaign(id),
    });

    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.hubspot,
      }),
    );
    yield put({ type: HUBSPOT_CLEAR_STATE });
  } catch (error) {
    yield put({ type: HUBSPOT_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(HUBSPOT_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(HUBSPOT_SAVE_CONNECTION, handleSaveConnection);
  yield takeLatest(HUBSPOT_GET_FIELDS, handleGetFields);
  yield takeLatest(HUBSPOT_GET_LIST, handleGetList);
  yield takeLatest(HUBSPOT_SAVE, handleSave);
  yield takeLatest(HUBSPOT_DELETE, handleDelete);
}
