import { cdn } from '@utils/fetch';
import { AxiosResponse } from 'axios';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_FEATURES_FAILURE,
  GET_FEATURES_REQUEST,
  GET_FEATURES_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
} from './constants';
import { GetProductsAction, Products } from './types';

function* getProductsSaga(action: GetProductsAction) {
  const { onSuccess = () => {}, onError = () => {} } = action.payload || {};
  try {
    const res: AxiosResponse<Products> = yield call(
      cdn,
      'assets/billing.json?v=7',
      {
        method: 'GET',
      },
    );

    yield put({
      type: GET_PRODUCTS_SUCCESS,
      payload: res.data,
    });
    onSuccess();
  } catch (error) {
    yield put({ type: GET_PRODUCTS_FAILURE });
    onError();
  }
}
function* getFeaturesSaga() {
  try {
    const res: AxiosResponse<Products> = yield call(
      cdn,
      'assets/plan-features.json',
      {
        method: 'GET',
      },
    );

    yield put({
      type: GET_FEATURES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    yield put({ type: GET_FEATURES_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(GET_PRODUCTS_REQUEST, getProductsSaga);
  yield takeLatest(GET_FEATURES_REQUEST, getFeaturesSaga);
}
