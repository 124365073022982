'use client';
import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';
import React, { useEffect } from 'react';
import { useAuth } from '@utils/useAuth';
import config from '@utils/config';

export function PostHogProvider({ children }: React.PropsWithChildren) {
  const { auth: user } = useAuth();

  useEffect(() => {
    if (window && config.isProd) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      });

      if (user?.id) {
        posthog.identify(`${user.id}`, {
          email: user.email,
          name: user.name,
          accountId: user?.accountId,
          plan: user?.accountPlan.id,
        });
      } else {
        posthog.reset();
      }
    }
  }, [user]);

  return <Provider client={posthog}>{children}</Provider>;
}
