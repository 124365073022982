import { ReduxAction } from 'global';
import ReduxStatus from '@enums/ReduxStatus';
import {
  HUBSPOT_GET_FIELDS,
  HUBSPOT_GET_FIELDS_SUCCESS,
  HUBSPOT_GET_FIELDS_FAILURE,
  HUBSPOT_GET_CONNECTIONS,
  HUBSPOT_GET_CONNECTIONS_SUCCESS,
  HUBSPOT_GET_CONNECTIONS_FAILURE,
  HUBSPOT_GET_LIST,
  HUBSPOT_GET_LIST_SUCCESS,
  HUBSPOT_GET_LIST_FAILURE,
  HUBSPOT_SAVE,
  HUBSPOT_SAVE_SUCCESS,
  HUBSPOT_SAVE_FAILURE,
  HUBSPOT_CHANGESTATUS,
  HUBSPOT_CHANGESTATUS_FAILURE,
  HUBSPOT_CHANGESTATUS_SUCCESS,
  HUBSPOT_DELETE,
  HUBSPOT_DELETE_FAILURE,
  HUBSPOT_DELETE_SUCCESS,
  HUBSPOT_CLEAR_STATE,
  HUBSPOT_SAVE_CONNECTION,
  HUBSPOT_SAVE_CONNECTION_SUCCESS,
  HUBSPOT_SAVE_CONNECTION_FAILURE,
} from './constants';
import { HubspotState } from './type';
import produce, { Draft } from 'immer';

const initialState: HubspotState = {
  connection: { items: [], status: ReduxStatus.initialized },
  list: { items: [], status: ReduxStatus.initialized },
  fields: { items: [], status: ReduxStatus.initialized },
  testResult: {
    result: {
      isSent: false,
      sentData: [],
      message: '',
      messageTitle: '',
      errorLogs: '',
    },
    status: ReduxStatus.initialized,
  },
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<HubspotState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case HUBSPOT_GET_FIELDS:
      draft.fields = { items: [], status: ReduxStatus.loading };
      return;
    case HUBSPOT_GET_FIELDS_SUCCESS:
      draft.fields = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case HUBSPOT_GET_FIELDS_FAILURE:
      draft.fields = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;
    case HUBSPOT_GET_LIST:
      draft.list = {
        items: [],
        status: ReduxStatus.loading,
      };
      return;
    case HUBSPOT_GET_LIST_SUCCESS:
      draft.list = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case HUBSPOT_GET_LIST_FAILURE:
      draft.list = {
        items: [],
        status: ReduxStatus.errored,
      };
      return;

    case HUBSPOT_GET_CONNECTIONS:
      draft.connection = { items: [], status: ReduxStatus.loading };
      return;
    case HUBSPOT_GET_CONNECTIONS_SUCCESS:
      draft.connection = {
        items: action.payload,
        status: ReduxStatus.success,
      };
      return;
    case HUBSPOT_GET_CONNECTIONS_FAILURE:
      draft.connection = { items: [], status: ReduxStatus.errored };
      return;

    case HUBSPOT_SAVE_CONNECTION:
      if (!draft.connection) return;
      draft.connection.status = ReduxStatus.loading;
      return;
    case HUBSPOT_SAVE_CONNECTION_SUCCESS: {
      if (!draft.connection) return;
      const items = !draft.connection.items ? [] : draft.connection.items;
      items.push(action.payload);
      draft.connection.items = items;
      draft.connection.status = ReduxStatus.success;
      return;
    }
    case HUBSPOT_SAVE_CONNECTION_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case HUBSPOT_SAVE:
      draft.status = ReduxStatus.loading;
      return;
    case HUBSPOT_SAVE_SUCCESS:
      draft.list = { items: [], status: ReduxStatus.initialized };
      draft.fields = { items: [], status: ReduxStatus.initialized };
      draft.status = ReduxStatus.success;
      return;
    case HUBSPOT_SAVE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case HUBSPOT_DELETE:
      draft.status = ReduxStatus.loading;
      return;
    case HUBSPOT_DELETE_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case HUBSPOT_DELETE_FAILURE:
      draft.status = ReduxStatus.errored;
      return;

    case HUBSPOT_CHANGESTATUS:
      draft.status = ReduxStatus.loading;
      return;
    case HUBSPOT_CHANGESTATUS_SUCCESS:
      draft.status = ReduxStatus.success;
      return;
    case HUBSPOT_CHANGESTATUS_FAILURE:
      draft.status = ReduxStatus.errored;
      return;
    case HUBSPOT_CLEAR_STATE:
      return initialState;

    default:
      return;
  }
}, initialState);

export default reducer;
