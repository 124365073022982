import React, { forwardRef, InputHTMLAttributes, ChangeEvent } from 'react';
import { FieldErrors } from 'react-hook-form';
import cn from 'classnames';

import styles from './RadioInput.module.scss';

interface RadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: FieldErrors;
  labelClassName?: string;
  styleType?: 'default' | 'targeting';
}

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (props: RadioInputProps, ref) => {
    const {
      label,
      id,
      onChange,
      error,
      disabled,
      className,
      labelClassName = '',
      styleType = 'default',
      checked,
      ...rest
    } = props;

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };

    return (
      <div
        className={cn(styles.radio, className, {
          [styles.disabled]: disabled,
          [styles.targeting_style]: styleType === 'targeting',
        })}
      >
        <input
          className={cn(!!error && styles.error)}
          ref={ref}
          id={id}
          type="radio"
          name={id}
          disabled={disabled}
          onChange={onChangeInput}
          {...(checked !== undefined ? { checked } : {})}
          {...rest}
        />
        {label && (
          <label htmlFor={id} className={cn(styles.label, labelClassName)}>
            {label}
          </label>
        )}
      </div>
    );
  },
);

RadioInput.displayName = 'RadioInput';

export default RadioInput;
