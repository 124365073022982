type BrowserEventType = 'pageview' | 'click';

interface BrowserEventBase<
  TEventType extends BrowserEventType,
  TPayload extends object,
> {
  type: TEventType;
  payload: TPayload & {
    accountId?: number;
    templateId?: number;
    tagId?: number;
    tagName?: string;
  };
}
type LeftMenuTargetClick =
  | 'playbook'
  | 'customize'
  | 'style'
  | 'segment'
  | 'publish'
  | 'settings_page'
  | 'analytics_page'
  | 'leads_page';

type StyleTargetClick =
  | 'theme_engine_change'
  | 'advanced_settings'
  | 'body_style'
  | 'text_size'
  | 'form_style'
  | 'button_style'
  | 'shopify_style'
  | 'close_style';

type BuilderClickTarget =
  | 'publish'
  | 'first_time_publish'
  | 'segment_edit'
  | 'segment_change'
  | 'step_settings'
  | 'new_step'
  | 'new_element'
  | `left_menu_${LeftMenuTargetClick}`
  | `style_${StyleTargetClick}`;

type DashboardClickTarget =
  | 'analytics'
  | 'leads'
  | 'new_campaign'
  | 'help'
  | 'changelog'
  | 'embed_code'
  | 'upgrade';

type TemplateSelectionClickTarget =
  | 'choosed_theme'
  | 'previewed_template'
  | 'choosed_business_goals';

type ProfileMenuClickTarget =
  | 'personal_data'
  | 'website'
  | 'invoices'
  | 'billing'
  | 'subaccounts'
  | 'help';

export type ClickTarget =
  | `builder_${BuilderClickTarget}`
  | `dashboard_${DashboardClickTarget}`
  | `profile_menu_${ProfileMenuClickTarget}`
  | `template_selection_${TemplateSelectionClickTarget}`;

type BrowserEvent =
  | BrowserEventBase<'pageview', { url: string }>
  | BrowserEventBase<'click', { url?: string; element: ClickTarget }>;

export const sendAnalytics = <TEventType extends BrowserEventType>(
  type: TEventType,
  payload: Extract<BrowserEvent, { type: TEventType }>['payload'],
) => {
  if (!payload?.url) {
    payload.url = window.location.href;
  }
  if (window?._cio) {
    window._cio.track(type, payload);
  }
};
