import React from 'react';
import cn from 'classnames';

import styles from './LinkButton.module.scss';

import Icon from '@assets/icons/down-arrow-icon.svg';

interface LinkButtonProps {
  link: string;
  text?: string;
  className?: string;
}

const LinkButton = (props: LinkButtonProps) => {
  const { link, text, className, ...rest } = props;
  return (
    <a
      className={cn('btn btn-link', styles.link, className && className)}
      target="_blank"
      rel="noreferrer"
      data-testid="link-button"
      href={link}
      {...rest}
    >
      <Icon />
      {text}
    </a>
  );
};

export default LinkButton;
export type { LinkButtonProps };
