import { ReduxAction } from 'global';
import type {
  IntegrationShopifyCampaign,
  GetAllShopifyIntegrationInCampaignQuery,
} from 'src/generated/graphql';

import {
  UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS,
  CLEAR_SHOPIFY_INTEGRATIONS,
  UPDATE_SHOPIFY_INTEGRATIONS_CAMPAIGN,
  DELETE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS,
  UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN,
  ADD_SHOPIFY_INTEGRATION_CAMPAIGN,
} from './constants';

import produce, { Draft } from 'immer';

type ShopifyIntegrationState = {
  integrations:
    | GetAllShopifyIntegrationInCampaignQuery['getAllShopifyIntegrationInCampaign']
    | null;
};

const initialState: ShopifyIntegrationState = {
  integrations: null,
};

const getIntegrationIndexByIntegrationId = (
  draft: Draft<ShopifyIntegrationState>,
  integrationId: number,
) => {
  if (!draft?.integrations) return;

  const updateIntegrationIndex = draft.integrations.findIndex(
    (integration) => integration.id === integrationId,
  );
  return updateIntegrationIndex;
};

const reducer = produce(
  (draft: Draft<ShopifyIntegrationState>, action: ReduxAction) => {
    const { type } = action;
    switch (type) {
      case UPDATE_SHOPIFY_INTEGRATIONS_CAMPAIGN: {
        draft.integrations = action.payload;
        return;
      }
      case ADD_SHOPIFY_INTEGRATION_CAMPAIGN: {
        if (!draft?.integrations) return;

        const integrationShopifyCampaign =
          action.payload as IntegrationShopifyCampaign;

        draft.integrations.push(integrationShopifyCampaign);
        return;
      }

      case UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN: {
        if (!draft?.integrations) return;

        const shopifyIntegrationCampaign =
          action.payload as IntegrationShopifyCampaign;

        const updateIntegrationIndex = getIntegrationIndexByIntegrationId(
          draft,
          shopifyIntegrationCampaign.id,
        );

        draft.integrations[updateIntegrationIndex as number] =
          shopifyIntegrationCampaign;
        return;
      }

      case UPDATE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS: {
        if (!draft?.integrations) return;

        const integrationId = action.payload.integrationId;
        const status = action.payload.status;

        const updateIntegrationIndex = getIntegrationIndexByIntegrationId(
          draft,
          integrationId,
        );
        draft.integrations[updateIntegrationIndex as number].status = status;
        return;
      }

      case DELETE_SHOPIFY_INTEGRATION_CAMPAIGN_STATUS: {
        if (!draft?.integrations) return;

        const integrationId = action.payload.integrationId;

        draft.integrations = draft.integrations.filter(
          (integration) => integration.id !== integrationId,
        );
        return;
      }

      case CLEAR_SHOPIFY_INTEGRATIONS:
        return initialState;
      default:
        return;
    }
  },
  initialState,
);

export default reducer;
