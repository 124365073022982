import type { FieldError } from 'react-hook-form';
import React, {
  useEffect,
  useState,
  forwardRef,
  InputHTMLAttributes,
  ChangeEvent,
  ForwardedRef,
} from 'react';
import cn from 'classnames';

import HelpInfoIcon from '@assets/icons/help-info-icon.svg';
import InfoOutline from '@assets/icons/info-outline.svg';

import { Tooltip } from 'ui';

import styles from './Input.module.scss';

import ErrorIcon from '@assets/icons/error-icon.svg';
import DisabledIcon from '@assets/icons/disabled-icon.svg';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelTop?: string;
  error?: FieldError;
  noTopLabel?: boolean;
  yesTopLabel?: boolean;
  indicator?: string;
  tooltip?: string;
  elementSize?: 'sm' | 'md';
  inputRef?: ForwardedRef<HTMLInputElement>;
  styleType?: 'default' | 'targeting';
  iconType?: 'question' | 'info';
  errorClassName?: string;
  disabledIcon?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const {
      placeholder,
      label,
      labelTop,
      name,
      onChange,
      error,
      disabled,
      disabledIcon = true,
      value,
      className,
      noTopLabel,
      yesTopLabel,
      elementSize = 'md',
      inputRef,
      indicator,
      styleType = 'default',
      tooltip,
      iconType = 'question',
      errorClassName,
      ...rest
    } = props;

    const [inputValue, setInputValue] = useState<any>(value);

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      const e = { ...event };
      if ((rest?.max || rest?.max === 0) && e?.target?.value > rest.max) {
        (e.target.value as number | string) = rest.max;
      }
      if ((rest?.min || rest?.min === 0) && e?.target?.value < rest.min) {
        (e.target.value as number | string) = rest.min;
      }

      onChange && onChange(e);

      setInputValue(e.target.value);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <div
        className={cn(
          'flex flex-col',
          styleType === 'targeting' && !!error && styles.inside_error,
        )}
      >
        {!!labelTop && (
          <p className={styles.top_label}>
            {labelTop}
            {tooltip && (
              <Tooltip
                style={{
                  zIndex: '99999',
                  position: 'absolute',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                  height: 'max-content',
                  width: '240px',
                  whiteSpace: 'normal',
                  left: '58px',
                }}
                content={tooltip}
              >
                {iconType === 'info' && (
                  <InfoOutline
                    className="ml-1 translate-y-1 text-gray-700"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  />
                )}
                {iconType === 'question' && (
                  <HelpInfoIcon
                    className="ml-1 translate-y-1 text-gray-700"
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                  />
                )}
              </Tooltip>
            )}
          </p>
        )}
        <div
          className={cn(
            styles.input_wrapper,
            elementSize === 'sm' && styles.small,
            styleType === 'targeting' && styles.targeting_style,

            className,
          )}
        >
          <input
            className={cn('input', !!error && styles.error)}
            data-testid="textfield-input"
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            onChange={onChangeInput}
            ref={inputRef ? inputRef : ref}
            value={inputValue}
            autoComplete="off"
            {...rest}
          />
          {indicator && (
            <div className={styles.input_indicator}>{indicator}</div>
          )}

          {label && !noTopLabel && (
            <label
              className={cn(
                //couldn't find a solution to the autofill problem
                //thus i had to use yesTopLabel prop
                (placeholder ||
                  (typeof inputValue !== 'string' && inputValue !== '') ||
                  (typeof inputValue !== 'number' && inputValue !== '') ||
                  yesTopLabel) &&
                  styles.label_top,
              )}
              data-testid="textfield-label"
              htmlFor={name}
            >
              {label}
            </label>
          )}
          {disabled && disabledIcon && (
            <DisabledIcon
              className={styles.disabled_svg}
              data-testid="textfield-disabled-icon"
              width="24"
              height="24"
              color="#D2DAE3"
            />
          )}
        </div>
        {!!error && (
          <div
            className={cn(styles.error_div, errorClassName)}
            data-testid="textfield-error"
          >
            <ErrorIcon
              width="18"
              height="18"
              viewBox="0 0 18 18"
              color="#EA0F0F"
            />
            <p>{error?.message || 'Invalid'}</p>
          </div>
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export default Input;
