import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import SiteVariantIcon from '@assets/icons/site_variant.svg';

export const renderStopDisplayFrequency = (
  displayFrequency: Targets['displayFrequency'],
  translate: Function,
) => {
  const result: any[] = [];

  if (displayFrequency && displayFrequency.stopShowing) {
    if (displayFrequency.stopShowing.type === 'NEVER') {
      result.push(
        <TimelineContent
          icon={<SiteVariantIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate(
            'sections:targeting-summary-stop-displaying:optionOne',
          )}
        />,
      );
    }
    if (
      displayFrequency.stopShowing.type === 'STOP_SHOWING' &&
      !displayFrequency.stopShowing.options
    ) {
      result.push(
        <TimelineContent
          icon={<SiteVariantIcon width="16" height="16" viewBox="0 0 36 36" />}
          label={translate(
            'sections:targeting-summary-stop-displaying:optionTwo',
          )}
        />,
      );
    }
    if (
      displayFrequency.stopShowing.type === 'STOP_SHOWING' &&
      displayFrequency.stopShowing.options
    ) {
      const checkMultiple = [];
      if (displayFrequency.stopShowing.options.signedUpOrClicked) {
        checkMultiple.push(
          translate(
            'sections:targeting-summary-stop-displaying:signedUpOrClickedMessage',
          ),
        );
      }
      if (displayFrequency.stopShowing.options.clickClose) {
        checkMultiple.push(
          translate(
            'sections:targeting-summary-stop-displaying:clickCloseMessage',
          ),
        );
      }
      if (displayFrequency.stopShowing.options.seenCampaign) {
        checkMultiple.push(
          translate(
            'sections:targeting-summary-stop-displaying:seenCampaignMessage',
            {
              value:
                displayFrequency.stopShowing.options.seenCampaignValue || 0,
            },
          ),
        );
      }
      if (checkMultiple.length) {
        const item = (
          <TimelineContent
            icon={
              <SiteVariantIcon width="16" height="16" viewBox="0 0 36 36" />
            }
            label="Stop showing the popup:"
          >
            {checkMultiple.map((item, index) => {
              return (
                <span key={index} className="font-inter text-xs ml-2">
                  {index !== 0 && (
                    <>
                      <span className="block font-semibold text-bluesmart mr-1">
                        {translate('common:or').toUpperCase()}
                      </span>{' '}
                    </>
                  )}
                  {item}
                </span>
              );
            })}
          </TimelineContent>
        );
        result.push(item);
      }
    }
  }

  return result;
};
