export const RESET = 'builder/RESET';

export const GET_CAMPAIGN = 'builder/GET_CAMPAIGN';
export const GET_CAMPAIGN_SUCCESS = 'builder/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_ERROR = 'builder/GET_CAMPAIGN_ERROR';

export const UPDATE_CAMPAIGN_PAGE_ATTRIBUTE =
  'builder/UPDATE_CAMPAIGN_PAGE_ATTRIBUTE';
export const UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE =
  'builder/UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE';
export const UPDATE_CAMPAIGN_ALL_PAGE_CANVAS_ELEMENT_ATTRIBUTE =
  'builder/UPDATE_CAMPAIGN_ALL_PAGE_CANVAS_ELEMENT_ATTRIBUTE';
export const UPDATE_CAMPAIGN_TEASER_CANVAS_ELEMENT_ATTRIBUTE =
  'builder/UPDATE_CAMPAIGN_TEASER_CANVAS_ELEMENT_ATTRIBUTE';

export const ADD_CAMPAIGN_CONTENT_ELEMENT =
  'builder/ADD_CAMPAIGN_CONTENT_ELEMENT';
export const UPDATE_CAMPAIGN_CONTENT_ELEMENT =
  'builder/UPDATE_CAMPAIGN_CONTENT_ELEMENT';

export const UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE =
  'builder/UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE';
export const REMOVE_CAMPAIGN_CONTENT_ELEMENT =
  'builder/REMOVE_CAMPAIGN_CONTENT_ELEMENT';

export const UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE =
  'builder/UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE';

export const UPDATE_CAMPAIGN_CURRENT_PAGE_ID =
  'builder/UPDATE_CAMPAIGN_CURRENT_PAGE_ID';

export const ADD_CAMPAIGN_PAGE = 'builder/ADD_CAMPAIGN_PAGE';
export const REMOVE_CAMPAIGN_PAGE = 'builder/REMOVE_CAMPAIGN_PAGE';

export const UPDATE_CAMPAIGN_PREVIEW = 'builder/UPDATE_CAMPAIGN_PREVIEW';
export const UPDATE_VISITOR_DEVICE = 'builder/UPDATE_VISITOR_DEVICE';

export const GET_NEW_PAGE_TEMPLATE = 'builder/GET_NEW_PAGE_TEMPLATE';
export const GET_NEW_PAGE_TEMPLATE_ERROR =
  'builder/GET_NEW_PAGE_TEMPLATE_ERROR';

export const REVERSE_CAMPAIGN_CHILD_ELEMENTS =
  'builder/REVERSE_CAMPAIGN_CHILD_ELEMENTS';
export const UPDATE_CAMPAIGN_PAGE_FONT_FAMILY =
  'builder/UPDATE_CAMPAIGN_PAGE_FONT_FAMILY';
export const UPDATE_CAMPAIGN_PAGE_COLOR = 'builder/UPDATE_CAMPAIGN_PAGE_COLOR';
export const UPDATE_INLINE_EDITOR = 'builder/UPDATE_INLINE_EDITOR';

//Target
export const SAVE_CAMPAIGN = 'builder/SAVE_CAMPAIGN';
export const SAVE_CAMPAIGN_SUCCESS = 'builder/SAVE_CAMPAIGN_SUCCESS';
export const SAVE_CAMPAIGN_ERROR = 'builder/SAVE_CAMPAIGN_ERROR';

//Update campaign template
export const CHANGE_CAMPAIGN_TEMPLATE = 'builder/CHANGE_CAMPAIGN_TEMPLATE';
export const CHANGE_CAMPAIGN_TEMPLATE_SUCCESS =
  'builder/CHANGE_CAMPAIGN_TEMPLATE_SUCCESS';
export const CHANGE_CAMPAIGN_TEMPLATE_ERROR =
  'builder/CHANGE_CAMPAIGN_TEMPLATE_ERROR';

//Get template/:id && update campaign template
export const GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE =
  'builder/GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE';

export const GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_SUCCESS =
  'builder/GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_SUCCESS';

export const GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_ERROR =
  'builder/GET_TEMPLATE_UPDATE_CAMPAIGN_TEMPLATE_ERROR';

export const INCREASE_PUBLISH_VERSION = 'builder/INCREASE_PUBLISH_VERSION';

export const UPDATE_PUBLISH_LOADING_STATUS =
  'builder/UPDATE_PUBLISH_LOADING_STATUS';

export const UPDATE_HIGHLIGHT_MODE = 'builder/UPDATE_HIGHLIGHT_MODE';

export const TOGGLE_CANVAS_DRAG_DROP_STATUS =
  'builder/TOGGLE_CANVAS_DRAG_DROP_STATUS';
export const UPDATE_CAMPAIGN_DOMAIN_VERIFIED_STATUS =
  'builder/UPDATE_CAMPAIGN_DOMAIN_VERIFIED_STATUS';

export const SET_EMAIL_AUTOMATION_EDIT = 'builder/EDIT';
export const SET_TOTAL_CAMPAIGN_COUNT = 'builder/SET_TOTAL_CAMPAIGN_COUNT';

export const REORDER_PAGE_STEP = 'builder/REORDER_PAGE_STEP';
