import {
  MailChimpInput,
  MailChimpIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/MailChimpResolver.types';

export const GetMailChimpIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetMailChimpIntegrationsByCampaignId',
  query: `query GetMailChimpIntegrationsByCampaignId($campaignId: Int!) {
    getMailChimpIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      name
      integration {
        apiKey
        id
        accountId
        name
        status
      }
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetMailChimpIntegrationsByAccountId = () => ({
  operationName: 'GetMailChimpIntegrationsByAccountId',
  query: `query GetMailChimpIntegrationsByAccountId {
    getMailChimpIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
      }
      apiKey
    }
  }`,
});
export const GetMailChimpLists = (apiKey: string) => ({
  operationName: 'GetMailChimpLists',
  query: `query GetMailChimpLists($apiKey: String!) {
    getMailChimpLists(apiKey: $apiKey) {
      id
      name
    }
  }`,
  variables: {
    apiKey,
  },
});
export const GetMailChimpFields = (apiKey: string, listId: string) => ({
  operationName: 'GetMailChimpFields',
  query: `query GetMailChimpFields($listId: String!, $apiKey: String!) {
    getMailChimpFields(listId: $listId, apiKey: $apiKey) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    apiKey,
    listId,
  },
});
export const CreateMailChimp = (input: MailChimpInput) => ({
  operationName: 'CreateMailChimpIntegration',
  query: `mutation CreateMailChimpIntegration($input: MailChimpInput!) {
    createMailChimpIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateMailChimp = (
  id: number,
  input: MailChimpIntegrationCampaignInput,
) => ({
  operationName: 'UpdateMailChimpIntegrationCampaign',
  query: `mutation UpdateMailChimpIntegrationCampaign($input: MailChimpIntegrationCampaignInput!, $updateMailChimpIntegrationCampaignId: Int!) {
    updateMailChimpIntegrationCampaign(input: $input, id: $updateMailChimpIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateMailChimpIntegrationCampaignId: id,
  },
});

export const DeleteMailChimpIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteMailChimpIntegrationCampaign',
  query: `mutation DeleteMailChimpIntegrationCampaign($deleteMailChimpIntegrationCampaignId: Int!) {
    deleteMailChimpIntegrationCampaign(id: $deleteMailChimpIntegrationCampaignId)
  }`,
  variables: {
    deleteMailChimpIntegrationCampaignId: id,
  },
});
