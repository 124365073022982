export const isNullOrWhitespace = (input: string) => {
  if (typeof input === 'undefined' || input == null) return true;
  return input.replace(/\s/g, '').length < 1;
};

export const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const randomId = (): string => Math.random().toString(16).slice(2);
export const randomCode = (): string =>
  (Math.random() + 1).toString(36).substring(7).toUpperCase();

export const randomString = (length: number = 7): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const randomCodeGenerateWithPrefix = (
  prefix: string,
  length: number = 7,
): string => {
  const code = (Math.random() + 1).toString(36).substring(length).toUpperCase();
  return `${prefix}_${code}`;
};

export const capitalize = (
  string: string | number | null | undefined,
): string => {
  if (!string) return '';

  const str = typeof string !== 'string' ? string?.toString() : string;
  const words = str.split(' ');
  return words
    .map((word) => {
      return `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`;
    })
    .join(' ');
};

export const removeHTMLTags = (input: string) => {
  if (!input) return '';
  return input.replace(/<[^>]*>/g, '');
};

//Inspiration: https://stackoverflow.com/questions/13070054/convert-rgb-strings-to-hex-in-javascript
const regex = /^#([0-9a-f]{3}){1,2}$/i;
export const rgbToHex = (colorCode: string) => {
  if (regex.test(colorCode)) return colorCode;

  let rgb = colorCode.trim();
  rgb = rgb.split('(')[1].split(')')[0];
  const rgbArray = rgb.split(',');
  const hexArray = rgbArray.map((rgbIndex: string) => {
    //For each array element
    rgbIndex = parseInt(rgbIndex, 10).toString(16); //Convert to a base16 string
    return rgbIndex.length == 1 ? `0${rgbIndex}` : rgbIndex; //Add zero if we get only one character
  });
  let hex = hexArray.join('');
  hex = `#${hex}`;

  return hex;
};

export const parseCustomDuration = (duration: string) => {
  //dayjs unit types
  const units = [
    'd', //day
    'w', //week
    'M', //month
    'Q', //quarter
    'y', //year
    'h', //hour
    'm', //minute
    's', //second
    'ms', //milisecond
  ];
  const match = duration.match(/^(\d+)([a-zA-Z]+)$/);
  if (match && match[1] && match[2]) {
    const amount = match[1];
    const unit = match[2];

    if (!units.includes(unit)) throw new Error(`Invalid time unit: ${unit}`);
    return {
      amount: parseInt(amount, 10),
      unit,
    };
  }
  throw new Error(`Invalid time: ${duration}`);
};
