import {
  SendGridInput,
  SendGridIntegrationCampaignInput,
} from 'api-core/modules/integrations/resolvers/SendGridResolver.types';

export const GetSendGridIntegrationsByCampaignId = (campaignId: number) => ({
  operationName: 'GetSendGridIntegrationsByCampaignId',
  query: `query GetSendGridIntegrationsByCampaignId($campaignId: Int!) {
    getSendGridIntegrationsByCampaignId(campaignId: $campaignId) {
      id
      campaignId
      integrationId
      status
      integration {
        apiKey
        id
        accountId
        name
        status
      }
      integrationFields {
        id
        integrationCampaignId
        popupsmartFieldName
        integratorFieldName
        integratorFieldId
        integratorTag
        fieldType
        isCustomerEmail
      }
      listId
      name
    }
  }`,
  variables: {
    campaignId,
  },
});

export const GetSendGridIntegrationsByAccountId = () => ({
  operationName: 'GetSendGridIntegrationsByAccountId',
  query: `query GetSendGridIntegrationsByAccountId {
    getSendGridIntegrationsByAccountId {
      id
      accountId
      name
      status
      integrationCampaigns {
        id
        campaignId
        integrationId
        status
        integration {
          apiKey
          id
          accountId
          name
          status
        }
        integrationFields {
          id
          integrationCampaignId
          popupsmartFieldName
          integratorFieldName
          integratorFieldId
          integratorTag
          fieldType
          isCustomerEmail
        }
        listId
        name
      }
      apiKey
    }
  }`,
});
export const GetSendGridLists = (apiKey: string) => ({
  operationName: 'GetSendGridLists',
  query: `query GetSendGridLists($apiKey: String!) {
    getSendGridLists(apiKey: $apiKey) {
      id
      name
    }
  }`,
  variables: {
    apiKey,
  },
});
export const GetSendGridFields = (apiKey: string) => ({
  operationName: 'GetSendGridFields',
  query: `query GetSendGridFields($apiKey: String!) {
    getSendGridFields(apiKey: $apiKey) {
      integratorFieldId
      integratorFieldName
      integratorTag
    }
  }`,
  variables: {
    apiKey,
  },
});
export const CreateSendGrid = (input: SendGridInput) => ({
  operationName: 'CreateSendGridIntegration',
  query: `mutation CreateSendGridIntegration($input: SendGridInput!) {
    createSendGridIntegration(input: $input)
  }`,
  variables: {
    input,
  },
});

export const UpdateSendGrid = (
  id: number,
  input: SendGridIntegrationCampaignInput,
) => ({
  operationName: 'UpdateSendGridIntegrationCampaign',
  query: `mutation UpdateSendGridIntegrationCampaign($input: SendGridIntegrationCampaignInput!, $updateSendGridIntegrationCampaignId: Int!) {
    updateSendGridIntegrationCampaign(input: $input, id: $updateSendGridIntegrationCampaignId)
  }`,
  variables: {
    input,
    updateSendGridIntegrationCampaignId: id,
  },
});

export const DeleteSendGridIntegrationCampaign = (id: number) => ({
  operationName: 'DeleteSendGridIntegrationCampaign',
  query: `mutation DeleteSendGridIntegrationCampaign($deleteSendGridIntegrationCampaignId: Int!) {
    deleteSendGridIntegrationCampaign(id: $deleteSendGridIntegrationCampaignId)
  }`,
  variables: {
    deleteSendGridIntegrationCampaignId: id,
  },
});
