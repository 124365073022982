import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  DRIP_GET_FIELDS,
  DRIP_GET_FIELDS_SUCCESS,
  DRIP_GET_FIELDS_FAILURE,
  DRIP_GET_CONNECTIONS,
  DRIP_GET_CONNECTIONS_SUCCESS,
  DRIP_GET_CONNECTIONS_FAILURE,
  DRIP_SAVE,
  DRIP_SAVE_SUCCESS,
  DRIP_SAVE_FAILURE,
  DRIP_DELETE_FAILURE,
  DRIP_DELETE,
  DRIP_CLEAR_STATE,
} from './constants';
import { ApiFieldObject } from '../reducers';
import {
  deleteIntegration,
  setIntegration,
} from '@connectors/builder/settings/actions';
import IntegrationEnums from '@enums/IntegrationEnums';
import {
  CreateDrip,
  DeleteDripIntegrationCampaign,
  GetDripFields,
  GetDripIntegrationsByAccountId,
  GetDripIntegrationsByCampaignId,
  UpdateDrip,
} from './graphql';
import { DripConnectionsOutput } from './type';
import graphqlFetch from '@utils/graphqlFetch';
import { DripIntegrationCampaignResponse } from 'api-core/modules/integrations/resolvers/DripResolver.types';

function* handleGetConnections() {
  try {
    const result: {
      data: { getDripIntegrationsByAccountId: DripConnectionsOutput };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetDripIntegrationsByAccountId(),
    });
    yield put({
      type: DRIP_GET_CONNECTIONS_SUCCESS,
      payload: result.data.getDripIntegrationsByAccountId,
    });
  } catch (error) {
    yield put({ type: DRIP_GET_CONNECTIONS_FAILURE });
  }
}

function* handleGetFields(action: ReduxAction) {
  const { apiKey, account, password, onSuccess } = action.payload;
  try {
    const result: {
      data: { getDripFields: ApiFieldObject[] };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetDripFields(account, apiKey, password ?? ''),
    });
    yield put({
      type: DRIP_GET_FIELDS_SUCCESS,
      payload: result.data.getDripFields,
    });
    onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: DRIP_GET_FIELDS_FAILURE });
  }
}
function* handleSave(action: ReduxAction) {
  const { connectionSettings, listSettings, fieldList, campaignId, isEdit } =
    action.payload;
  try {
    const formData = {
      id: connectionSettings.id,
      name: connectionSettings.name,
      apiKey: connectionSettings.apiKey,
      password: connectionSettings.password,
      account: connectionSettings.account,
      integrationCampaigns: fieldList
        ? [
            {
              name: listSettings.name,
              campaignId,
              status: true,
              integrationId: connectionSettings.id,
              integrationFields: fieldList,
              id: 0,
            },
          ]
        : [],
    };
    if (isEdit) {
      const campaignFormData = {
        name: listSettings.name,
        campaignId,
        status: true,
        id: listSettings.id,
        listId: listSettings.listId,
        integrationId: connectionSettings.id,
        integrationFields: fieldList,
      };

      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: UpdateDrip(listSettings.id, campaignFormData),
      });
    } else {
      formData.integrationCampaigns.forEach((element) => {
        element.integrationFields.forEach((field: ApiFieldObject) => {
          field.id = 0;
        });
      });
      yield call(graphqlFetch, ``, {
        method: 'POST',
        data: CreateDrip({ ...formData, status: true }),
      });
    }
    const integrations: {
      data: {
        getDripIntegrationsByCampaignId: DripIntegrationCampaignResponse[];
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: GetDripIntegrationsByCampaignId(campaignId),
    });
    yield put(
      setIntegration({
        datas: integrations.data.getDripIntegrationsByCampaignId,
        type: IntegrationEnums.drip,
      }),
    );
    yield put({ type: DRIP_SAVE_SUCCESS });
  } catch (error) {
    yield put({ type: DRIP_SAVE_FAILURE });
  }
}

function* handleDelete(action: ReduxAction) {
  try {
    const { id } = action.payload;
    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: DeleteDripIntegrationCampaign(id),
    });
    yield put(
      deleteIntegration({
        id,
        type: IntegrationEnums.drip,
      }),
    );
    yield put({ type: DRIP_CLEAR_STATE });
  } catch (error) {
    yield put({ type: DRIP_DELETE_FAILURE });
  }
}

export default function* sagas() {
  yield takeLatest(DRIP_GET_CONNECTIONS, handleGetConnections);
  yield takeLatest(DRIP_GET_FIELDS, handleGetFields);
  yield takeLatest(DRIP_SAVE, handleSave);
  yield takeLatest(DRIP_DELETE, handleDelete);
}
