import { ReduxAction } from 'global';

import {
  UNSUBSCRIBE_AUTO_RESPONDER_REQUEST,
  UNSUBSCRIBE_AUTO_RESPONDER_SUCCESS,
  UNSUBSCRIBE_AUTO_RESPONDER_FAILURE,
} from './constants';

export interface LeadEmail {
  id: number;
  email: 'string';
  status: 'string';
}

const initialState: any = {
  status: 'initialized',
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case UNSUBSCRIBE_AUTO_RESPONDER_REQUEST:
      return { ...state, status: 'loading' };

    case UNSUBSCRIBE_AUTO_RESPONDER_SUCCESS:
      return { ...state, status: 'success' };

    case UNSUBSCRIBE_AUTO_RESPONDER_FAILURE:
      return { ...state, status: 'errored' };
    default:
      return state;
  }
}
