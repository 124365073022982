import { ReduxAction } from 'global';
import type { ProductsState } from './types';
import {
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_FEATURES_REQUEST,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
} from './constants';
import ReduxStatus from '@enums/ReduxStatus';
import { RESET_STORE } from '@connectors/actions';

const initialState: ProductsState = {
  status: ReduxStatus.initialized,
  products: {
    data: {
      Monthly: [
        {
          planId: 0,
          version: 2,
          priceId: '',
          title: '',
          price: 0,
          impressionCount: 0,
          domainCount: '',
          popupCount: '',
          text: '',
          name: 'Basic',
          saveText: {
            amount: 0,
            old: 0,
            new: 0,
          },
        },
      ],
      Yearly: [
        {
          planId: 0,
          version: 2,
          priceId: '',
          title: '',
          price: 0,
          impressionCount: 0,
          domainCount: '',
          popupCount: '',
          text: '',
          name: 'Basic',
          saveText: {
            amount: 0,
            old: 0,
            new: 0,
          },
        },
      ],
      CoefficiencyRates: {
        Monthly: {
          Basic: 0,
          Professional: 0,
          Expert: 0,
        },
        Yearly: {
          Basic: 0,
          Professional: 0,
          Expert: 0,
        },
        Lifetime: {
          Basic: 0,
          Professional: 0,
          Expert: 0,
        },
      },
      Lifetime: [
        {
          planId: 0,
          version: 2,
          priceId: '',
          title: '',
          price: 0,
          impressionCount: 0,
          domainCount: '',
          popupCount: '',
          text: '',
          name: 'Basic',
          saveText: {
            amount: 0,
            old: 0,
            new: 0,
          },
        },
      ],
    },
    status: ReduxStatus.initialized,
  },
  features: {
    data: [],
    status: ReduxStatus.initialized,
  },
};

export default function reducer(
  state: ProductsState = initialState,
  action: ReduxAction,
) {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          status:
            state.products.status !== ReduxStatus.success
              ? ReduxStatus.loading
              : ReduxStatus.success,
        },
        status:
          state.status !== ReduxStatus.success
            ? ReduxStatus.loading
            : ReduxStatus.success,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: { data: action.payload, status: ReduxStatus.success },
        status: ReduxStatus.success,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        products: { ...state.products, status: ReduxStatus.errored },
        status: ReduxStatus.errored,
      };
    case GET_FEATURES_REQUEST:
      return {
        ...state,
        features: { ...state.features, status: ReduxStatus.loading },
      };
    case GET_FEATURES_SUCCESS:
      return {
        ...state,
        features: {
          data: action.payload.features,
          status: ReduxStatus.success,
        },
        status: ReduxStatus.loading,
      };
    case GET_FEATURES_FAILURE:
      return {
        ...state,
        features: { ...state.features, status: ReduxStatus.errored },
        status: ReduxStatus.errored,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
