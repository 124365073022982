import ReduxStatus from '@enums/ReduxStatus';
import { ReduxAction } from 'global';
import produce, { Draft } from 'immer';
import { RESET, SET_STEP_LAYOUT, UPDATE_STEP_POSITION } from './constants';

import type {
  StepContent,
  SetStepLayoutAction,
  NewStateState,
  UpdateStepPositionAction,
} from './types';

import { RESET_STORE } from '@connectors/actions';

export const initialState: NewStateState = {
  status: ReduxStatus.initialized,
};

const reducer = produce((draft: Draft<NewStateState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case SET_STEP_LAYOUT: {
      const { payload } = action as SetStepLayoutAction;
      const { content } = payload;

      (draft.layout as StepContent) = content;
      draft.status = ReduxStatus.loading;
      return;
    }
    case UPDATE_STEP_POSITION: {
      const { payload } = action as UpdateStepPositionAction;
      const { position, styles } = payload;
      const { layout } = draft;

      if (!layout) return;

      layout.appearance.position = position;

      ['desktop', 'mobile'].forEach((device) => {
        const data = layout?.content?.[device as 'desktop' | 'mobile'];
        if (!data) return;

        const newCssRules = Object.assign({}, data.attributes.style, styles);
        data.attributes.style = newCssRules;
      });

      return;
    }
    //#endregion
    case RESET:
    case RESET_STORE: {
      return initialState;
    }
    default: {
      return;
    }
  }
}, initialState);

export default reducer;
