import { RESET_STORE } from '@connectors/actions';
import { ReduxAction } from 'global';

import { SET_TOOLBAR_STATUS } from './constants';

export type QuillState = {
  isToolbarMount?: boolean;
};

const initialState: QuillState = {
  isToolbarMount: false,
};

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case SET_TOOLBAR_STATUS:
      return {
        ...state,
        isToolbarMount: action.payload,
      };
    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
}
