export const fromPxConverter = (
  cssValue: string,
  targetUnit: 'em' | '%',
  baseSize = 16,
) => {
  if (cssValue === 'auto') return false;

  const supportedUnits = {
    em: (value: number) => value / baseSize,
    '%': (value: number) => {
      return (value * 100) / baseSize;
    },
  };

  const pattern = new RegExp(`^([-+]?(?:\\d+(?:\\.\\d+)?))(px|%)$`, 'i');
  const matches = cssValue?.trim()?.match(pattern);
  if (!matches) return undefined;

  const value = Number(matches[1]);

  if (targetUnit in supportedUnits) {
    return supportedUnits[targetUnit](value);
  }
};

export const getResponsiveValue = (
  value: string,
  key: string,
  baseSize: string | undefined,
) => {
  let baseFontSize = 16;
  if (baseSize) {
    baseFontSize = parseFloat(baseSize.replace('px', ''));
  }
  let newValue: boolean | number | string | undefined = false;
  if (value.includes('px')) {
    if (key.toLocaleLowerCase().includes('fontsize')) {
      newValue = fromPxConverter(value, 'em');

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}em`;
      } else newValue = undefined;
    }

    if (key.toLocaleLowerCase().includes('lineheight')) {
      //line-height convert %
      newValue = fromPxConverter(value, '%', baseFontSize);

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}%`;
      } else newValue = undefined;
    }

    if (key.toLocaleLowerCase().includes('padding')) {
      newValue = fromPxConverter(value, 'em', baseFontSize);

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}em`;
      } else newValue = undefined;
    }

    if (key === 'IconWidth') {
      newValue = fromPxConverter(value, 'em', baseFontSize);

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}em`;
      } else newValue = undefined;
    }

    if (key === 'IconHeight') {
      newValue = fromPxConverter(value, 'em', baseFontSize);

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}em`;
      } else newValue = undefined;
    }
    if (key === 'MarkerWidth') {
      newValue = fromPxConverter(value, 'em', baseFontSize);

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}em`;
      } else newValue = undefined;
    }

    if (key === 'MarkerHeight') {
      newValue = fromPxConverter(value, 'em', baseFontSize);

      if (typeof newValue === 'number') {
        newValue = `${Number(newValue.toFixed(2))}em`;
      } else newValue = undefined;
    }
  }

  return typeof newValue === 'string' ? newValue : value;
};
