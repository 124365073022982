import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import store from '@utils/store';

let isRefreshing = false;
let pendingRequests: ((value: void | PromiseLike<void>) => void)[] = [];
const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewToken = async (auth: { refreshToken: string; token: string }) => {
  const refreshToken = auth.refreshToken;
  const oldAccessToken = auth.token;
  const baseUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
  try {
    const result = await fetch(`${baseUrl}/api/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oldAccessToken}`,
      },
      body: JSON.stringify({
        operationName: 'RefreshToken',
        query: `mutation RefreshToken($refreshToken: String!, $accessToken: String!) {
          refreshToken(refreshToken: $refreshToken, accessToken: $accessToken) {
            accessToken
            refreshToken
          }
        }`,
        variables: {
          refreshToken,
          accessToken: oldAccessToken,
        },
      }),
    });

    const data = await result.json();
    if (data.token) {
      const newAuth = { ...auth, ...data };
      store.set('user', newAuth);
      return newAuth;
    }
    // If we can't refresh the token, we log the user out
    store.clearTemps();
  } catch (e) {
    store.clearTemps();
    throw e;
  }
};

const refreshLink = onError(({ graphQLErrors, operation, forward }) => {
  const auth = store.get('user');
  if (!auth) {
    return undefined;
  }
  if (
    graphQLErrors?.find(
      (error) =>
        error.message ===
        'Access denied! You need to be authorized to perform this action!',
    )
  ) {
    let forward$;

    if (!isRefreshing) {
      isRefreshing = true;
      forward$ = fromPromise(
        getNewToken(auth)
          .then(({ token }) => {
            operation.setContext({
              headers: { Authorization: `Bearer ${token}` },
            });
            // Store the new tokens for your auth link
            resolvePendingRequests();
            return token;
          })
          .catch(() => {
            pendingRequests = [];
            // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
            window.location.href = '/login';
            return;
          })
          .finally(() => {
            isRefreshing = false;
          }),
      ).filter((value) => Boolean(value));
    } else {
      forward$ = fromPromise(
        new Promise<void>((resolve) => {
          pendingRequests.push(() => resolve());
        }),
      );
    }
    return forward$.flatMap(() => forward(operation));
  }

  return undefined;
});

export default refreshLink;
