import React, {
  FC,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '@connectors/modal/actions';
import CloseIcon from '@assets/icons/close.svg';
import styles from './PsModal.module.scss';
import { makeSelectModalById } from '@connectors/modal/selectors';

type ModalType = 'normal' | 'mini' | 'verify' | 'long' | 'integration';

export type Size =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | '75%'
  | 'full'
  | 'min'
  | 'max'
  | 'max-fit'
  | 'fit'
  | 'prose'
  | 'fullWidth'
  | 400
  | 600
  | 450
  | 500
  | 700
  | 720
  | 550
  | 650
  | 900;

export const sizes: {
  [key in Size]: string;
} = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  '75%': 'max-w-[75%]',
  full: 'max-w-full',
  min: 'min-w-min',
  max: 'max-w-max',
  'max-fit': 'max-w-fit',
  fit: '!w-fit',
  prose: 'max-w-prose',
  fullWidth: '!w-full',
  400: '!w-[432px]',
  450: '!w-[482px]',
  500: '!w-[532px]',
  550: '!w-[550px]',
  600: '!w-[632px]',
  650: '!w-[682px]',
  700: '!w-[700px]',
  720: '!w-[752px]',
  900: '!w-[932px]',
};

export interface ModalProps {
  id: string;
  onClose?: () => void;
  size?: Size | string;
  type?: ModalType;
  footer?: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  radius?: string;
  children: ReactElement;
  className?: string;
  bodyClassName?: string;
  closeable?: boolean;
  scroll?: boolean;
  outsideClickClose?: boolean;
  escClose?: boolean;
  closeButtonClassName?: string;
  wrapperClassName?: string;
  closeButtonColor?: 'light';
}

const PsModal: FC<ModalProps> = ({
  size = 600,
  type = 'normal',
  children,
  radius,
  id,
  className,
  bodyClassName,
  onClose,
  closeable = true,
  scroll = true,
  outsideClickClose = true,
  escClose = true,
  closeButtonClassName,
  closeButtonColor = '#777777',
  wrapperClassName,
}) => {
  const dispatch = useDispatch();
  const selectedModal = useSelector(makeSelectModalById(id));

  const isShowModal = selectedModal?.id === id;
  const top = selectedModal?.header && React.cloneElement(selectedModal.header);

  const body = isShowModal && (
    <div
      className={cn(
        'modal-body dark:text-white',
        type === 'long' && 'long',
        type === 'integration' ? 'max-h-96' : 'max-h-160',
        scroll ? 'overflow-y-auto' : '',
        bodyClassName,
      )}
    >
      {React.cloneElement(children, { ...selectedModal?.data })}
    </div>
  );

  const bottom =
    selectedModal?.footer && React.cloneElement(selectedModal.footer);

  const modalSize = sizes[size as Size] || `!w-[${size}]`;
  const borderRadius = radius ? radius : '8px';
  const modalRef = useRef<HTMLDivElement | null>(null);

  const onClickHandler = () => {
    if (closeable) {
      dispatch(hideModal(id));
      onClose && onClose();
      selectedModal?.onClose && selectedModal?.onClose();
    }
  };

  const handleOutsideClick = (e: MouseEvent<HTMLElement>) => {
    if (modalRef.current?.contains(e.target as Node)) {
      return;
    }
    if (!outsideClickClose) return;
    onClickHandler();
  };

  const escapeListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClickHandler();
    }
  };

  useEffect(() => {
    if (!selectedModal) return;
    if (!escClose) return;
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('keyup', escapeListener);
    };
  }, [selectedModal]);

  return (
    <>
      <div
        className={cn(
          'modal overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-auto z-[999] justify-center items-center h-full !inset-0 flex transition-all',
          { hidden: !selectedModal },
          className,
        )}
        aria-hidden="true"
        id={selectedModal && 'active-ps-modal'}
        onClick={handleOutsideClick}
      >
        <div className={cn(`relative px-4 w-full h-auto`, modalSize)}>
          <div
            style={{ borderRadius }}
            className={cn(
              'relative bg-white  shadow dark:bg-gray-700 overflow-hidden',
              wrapperClassName,
            )}
            ref={modalRef}
          >
            {closeable && (
              <button
                type="button"
                className={cn(
                  'ps-modal-close-button absolute top-0 right-0  rounded-full text-sm p-[15px] pr-[0px] ml-auto mr-[15px] inline-flex items-center z-10',
                  {
                    [styles.miniCloseButton]: type === 'mini',
                  },
                  closeButtonClassName,
                )}
                onClick={onClickHandler}
              >
                <div className="pointer-events-none">
                  <CloseIcon
                    width={type === 'mini' ? '28' : '36'}
                    height={type === 'mini' ? '28' : '36'}
                    viewBox="0 0 36 36"
                    className={cn({
                      [styles.light]: closeButtonColor === 'light',
                    })}
                  />
                </div>
              </button>
            )}
            {top}
            {body}
            {bottom}
          </div>
        </div>
      </div>
      {selectedModal && (
        <div className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[53]" />
      )}
    </>
  );
};

export default PsModal;
