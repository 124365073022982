import type { ElementsType } from './types';
import dynamic from 'next/dynamic';

const Elements: ElementsType = {
  'close-button': dynamic(() => import('@popupElements/CloseButton')),
  block: dynamic(() => import('@popupElements/Block')),
  'form-block': dynamic(() => import('@popupElements/Form')),
  headline: dynamic(() => import('@popupElements/Headline')),
  text: dynamic(() => import('@popupElements/Text')),
  subtext: dynamic(() => import('@popupElements/Subtext')),
  button: dynamic(() => import('@popupElements/Button')),
  'button-wrapper': dynamic(() => import('@popupElements/ButtonWrapper')),
  'form-button': dynamic(() => import('@popupElements/Button')),
  'text-input': dynamic(() => import('@popupElements/TextInput')),
  'hidden-input': dynamic(() => import('@popupElements/HiddenInput')),
  'long-input': dynamic(() => import('@popupElements/LongInput')),
  'full-name-input': dynamic(() => import('@popupElements/TextInput')),
  'address-input': dynamic(() => import('@popupElements/LongInput')),
  image: dynamic(() => import('@popupElements/Image')),
  'email-input': dynamic(() => import('@popupElements/EmailInput')),
  'dropdown-input': dynamic(() => import('@popupElements/DropdownInput')),
  'date-input': dynamic(() => import('@popupElements/DateInput')),
  'multi-choice': dynamic(() => import('@popupElements/MultiChoice')),
  'radio-input': dynamic(() => import('@popupElements/RadioInput')),
  'phone-input': dynamic(() => import('@popupElements/Phone')),
  'yes-no': dynamic(() => import('@popupElements/YesNo')),
  social: dynamic(() => import('@popupElements/Social/Social')),
  'social-element': dynamic(
    () => import('@popupElements/Social/SocialChildren'),
  ),
  rating: dynamic(() => import('@popupElements/Rating')),
  'coupon-code': dynamic(() => import('@popupElements/CouponCode')),
  countdown: dynamic(() => import('@popupElements/Countdown')),
  'opinion-scale': dynamic(() => import('@popupElements/OpinionScale')),
  youtube: dynamic(() => import('@popupElements/Youtube')),
  vimeo: dynamic(() => import('@popupElements/Vimeo')),
  'notice-and-consent': dynamic(
    () => import('@popupElements/NoticeAndConsent'),
  ),
  lottie: dynamic(() => import('@popupElements/Lottie')),
  'spin-to-win': dynamic(() => import('@popupElements/SpinToWin')),
  'lottery-ball': dynamic(() => import('@popupElements/LotteryBall')),
  form: dynamic(() => import('@popupElements/Form')),
  shopify: dynamic(() => import('@popupElements/Shopify')),
  'default-form-button': dynamic(() => import('@popupElements/Button')),
};

export default Elements;
