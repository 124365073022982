import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDrawer } from '@connectors/drawer/reducer';

import CloseIcon from '@assets/icons/close.svg';
import styles from './Drawer.module.scss';
import type { DrawerProps } from './types';
import classNames from 'classnames';
import { hideDrawer } from '@connectors/drawer/actions';
import { RootState } from '@connectors/reducers';
import { useClickOutsideHandler } from '@utils/hooks';

const Drawer: FC<DrawerProps> = ({ id, children }) => {
  const dispatch = useDispatch();
  const selectedDrawer = useSelector((state: RootState) =>
    selectDrawer(state, id),
  );

  const onCloseClick = () => {
    dispatch(hideDrawer(id));
  };

  const drawerRef = useClickOutsideHandler({
    onClose: () => {
      const isOpenDrawerButton = document.querySelector(`#open-drawer-${id}`);
      if (isOpenDrawerButton != null) {
        dispatch(hideDrawer(id));
      }
    },
  });

  return (
    <div
      ref={drawerRef}
      className={classNames({
        [styles.drawer]: true,
        [styles.open]: !!selectedDrawer,
      })}
      id={selectedDrawer != null ? `open-drawer-${id}` : ''}
    >
      <div className={styles.header}>
        <div className={styles.title}>{selectedDrawer?.header}</div>
        <button type="button" onClick={onCloseClick} className={styles.close}>
          <CloseIcon width="24" height="24" viewBox="0 0 36 36" />
        </button>
      </div>
      <div className={styles.content}>
        {children && React.cloneElement(children, selectedDrawer?.data)}
      </div>
    </div>
  );
};

export default Drawer;
