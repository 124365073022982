import type { ShowcaseTagProps } from './types';

import React, { FC } from 'react';
import cn from 'classnames';

import styles from './ShowcaseTag.module.scss';

import CloseCircle from 'ui/assets/icons/CloseCircle';
import DownIcon from '@assets/icons/down-icon-2.svg';

const ShowcaseTag: FC<ShowcaseTagProps> = ({
  id,
  children,
  icon,
  isActive = false,
  isSubTagActive = false,
  handleClick,
  handleClickRemove,
  hideCloseIcon = false,
  className = '',
  isParentTag = false,
  isMenuOpen = false,
  count,
}) => {
  return (
    <div id={id} className="w-full">
      <button
        className={cn(styles.tagButton, className, {
          [styles.activeTag]: isActive,
        })}
        onClick={handleClick}
        data-testid="showcase-tag"
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        <span className="truncate">{children}</span>
        {isParentTag && (
          <span
            className={cn('ml-[6px]', {
              'rotate-180': (isActive || isSubTagActive) && isMenuOpen,
            })}
          >
            <DownIcon width="16" height="16" viewBox="0 0 18 18" />
          </span>
        )}
        {count && count > 0 ? (
          <span
            className={cn(styles.count, {
              [styles.activeCount]: isActive,
            })}
          >
            {count}
          </span>
        ) : (
          <></>
        )}

        {isActive && !hideCloseIcon && (
          <a
            className={styles.close}
            onClick={handleClickRemove}
            data-testid="showcase-tag-remove"
          >
            <CloseCircle width="16" height="16" viewBox="0 0 24 24" />
          </a>
        )}
      </button>
    </div>
  );
};

export default ShowcaseTag;
