import { FC } from 'react';
import cn from 'classnames';

import styles from './ModalIconContainer.module.scss';
import type { ModalIconContainerProps } from './types';

const CreateCampaignModal: FC<ModalIconContainerProps> = ({
  leftIcon,
  rightIcon,
  className,
  size = 'md',
}) => {
  return (
    <div
      className={cn(styles.iconWrapper, className && className, {
        [styles.small]: size === 'sm',
        [styles.medium]: size === 'md',
        [styles.large]: size === 'lg',
      })}
    >
      {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
      {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
    </div>
  );
};

export default CreateCampaignModal;
