import { hexToRGB } from '../helpers/string';
import { getResponsiveValue } from './style';

export const getThemeVariables = (data: object) => {
  if (!data || data === null) return '';
  const result: string[] = [];
  for (const x in data) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = data[x];
    if (typeof value === 'string') {
      result.push(`--${x}:${value};`);
    } else if (typeof value === 'object') {
      for (const y in value) {
        const subValue = value[y];
        const variable = y.charAt(0).toUpperCase() + y.slice(1);
        if (typeof subValue === 'string') {
          const responsiveValue = getResponsiveValue(
            subValue,
            variable,
            value['fontSize'],
          );
          result.push(`--${x}${variable}:${responsiveValue};`);
          if (`${x}${variable}` === 'formColor') {
            result.push(`--formTransparentColor:${hexToRGB(subValue, 0.4)};`);
            result.push(`--formHoverColor:${hexToRGB(subValue, 0.7)};`);
          }
          if (`${x}${variable}` === 'formAccent') {
            result.push(`--formBoxShadowColor:${hexToRGB(subValue, 0.1)};`);
          }
          if (`${x}${variable}` === 'formError') {
            result.push(
              `--formErrorBoxShadowColor:${hexToRGB(subValue, 0.1)};`,
            );
          }
        } else {
          const returnedValues = getThemeVariables(subValue);
          result.push(returnedValues.replaceAll('--', `--${x}${variable}`));
        }
      }
    }
  }
  return result.join(`\n`);
};

const Theme = { getThemeVariables };
export { Theme };
