import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import styles from './SendCodeToDeveloperModal.module.scss';

import type {
  SendCodeToDeveloperModalFormValues,
  SendCodeToDeveloperModalProps,
} from './types';
import PSForm, { PSFormField, PSTextbox } from '@elements/PSForm';
import PSButton from '@elements/PSButton';

import { showModal, hideModal } from '@connectors/modal/actions';
import { sendEmbedCode } from '@connectors/campaign/actions';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('This field is required!')
    .trim(),
});

const SendCodeToDeveloper: FC<SendCodeToDeveloperModalProps> = ({
  fromNoVerifiedWebsiteModal,
  url,
}) => {
  const dispatch = useDispatch();

  const methods = useForm<SendCodeToDeveloperModalFormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onClickCancel = () => {
    dispatch(hideModal('SendCodeToDeveloperModal'));

    fromNoVerifiedWebsiteModal &&
      dispatch(
        showModal({
          id: 'NoVerifiedWebsiteModal',
          data: { url: url || '' },
        }),
      );
  };
  const handleOnSubmit = (values: SendCodeToDeveloperModalFormValues) => {
    const { email } = values;
    if (email) {
      dispatch(sendEmbedCode(email));
      methods.reset();
      dispatch(hideModal('SendCodeToDeveloperModal'));
      fromNoVerifiedWebsiteModal &&
        dispatch(
          showModal({
            id: 'NoVerifiedWebsiteModal',
            data: { url: url || '' },
          }),
        );
    }
  };

  const isSubmitButtonDisabled = !methods.formState.isDirty;

  return (
    <>
      <div className={styles.container}>
        <div className="flex flex-row px-10 py-5 border-b border-[#eaeaea]">
          <div className="flex items-center text-[16px] sm:text-xl font-semibold leading-6">
            Enter your developer’s email
          </div>
        </div>
        <PSForm
          onSubmit={methods.handleSubmit(handleOnSubmit)}
          className="w-full"
        >
          <div className="px-10 mt-[30px]">
            <PSFormField
              control={methods.control}
              name="email"
              component={PSTextbox}
              placeholder="Your developer’s email"
            />
          </div>
          <div className="flex flex-row justify-end gap-[12px] px-10 pb-10">
            <PSButton
              type="button"
              variant="second"
              onClick={onClickCancel}
              size="sm"
            >
              Cancel
            </PSButton>
            <PSButton type="submit" size="sm" disabled={isSubmitButtonDisabled}>
              Save
            </PSButton>
          </div>
        </PSForm>
      </div>
    </>
  );
};

export default SendCodeToDeveloper;
