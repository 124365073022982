import type { Targets } from '@connectors/targets/reducer';

import React from 'react';

import TimelineContent from '../TimelineContent';

import ActivityModeIcon from '@assets/icons/activity_mode.svg';

export const renderInActivityMode = (
  inActivityMode: Targets['inActivityMode'],
  translate: Function,
) => {
  if (inActivityMode) {
    const { value } = inActivityMode;

    if (value && Number(value) > 0) {
      return (
        <>
          <TimelineContent
            icon={
              <ActivityModeIcon width="16" height="16" viewBox="0 0 36 36" />
            }
            behavior
            label={translate(
              'sections:targeting-summary-inactivity-mode:message',
              { value },
            )}
          />
        </>
      );
    }
  } else {
    return <></>;
  }
};
